import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const domainList = createAsyncThunk(
    "get/domainList",
    async (params) => {
        const res = await DataService.domain_list(params);
        const resp = res.data;
        return resp;
    }
);

export const domainUpdate = createAsyncThunk("domain/update", async (data) => {
    const res = await DataService.save_domain(data);
    return res.data;
});

export const domainCreate = createAsyncThunk("domain/create", async (data) => {
    const res = await DataService.save_domain(data);
    return res.data;
});


export const projectsList = createAsyncThunk(
    "get/projectsList",
    async (params) => {
        const res = await DataService.projects_list(params);
        const resp = res.data;
        return resp;
    }
);

export const assignProjectDomain = createAsyncThunk(
    "assign/projectDomain",
    async (data) => {
        const res = await DataService.assign_project_domain(data);
        const resp = res.data;
        return resp;
    }
);

export const projectDomains = createAsyncThunk("projectDomain", async (data) => {
    const res = await DataService.projects_list_domain(data);
    const resp = res.data;
    return resp;
});

const domainSlice = createSlice({
    name: "domain",
    initialState: {
        domains: [],
        apiResponse: {},
        count: 0,
        row_count: 0,
        status: STATUSES.IDLE,
        error: '',
        responseStatus: '',
        projects: [],
        projects_count: 0,
        projects_status: STATUSES.IDLE,
        projects_error: '',
        project_DomainList: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            // GET DOMAIN LIST
            .addCase(domainList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(domainList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.error = !!action.payload.data;
                state.domains = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.count = action.payload.data.count;
                state.domain_list = action.payload;
            })
            .addCase(domainList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // DOMAIN UPDATE
            .addCase(domainUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(domainUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_domain_Update`;
                state.domain_update = action.payload;
            })
            .addCase(domainUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // DOMAIN CREATE
            .addCase(domainCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(domainCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_domain_create`;
                state.domain_create = action.payload;
                state.error = ""
            })
            .addCase(domainCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //  PROJECT LIST
            .addCase(projectsList.pending, (state, action) => {
                state.projects_status = STATUSES.LOADING;
            })
            .addCase(projectsList.fulfilled, (state, action) => {
                state.projects_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.projects_error = !!action.payload.data;
                state.projects = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.projects_count = action.payload.data.count;
            })
            .addCase(projectsList.rejected, (state, action) => {
                state.projects_status = STATUSES.FAILURE;
                state.projects_error = action.error
            })

            // Assign PROJECT-DOMAIN
            .addCase(assignProjectDomain.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(assignProjectDomain.fulfilled, (state, action) => {
                state.responseStatus = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.error = !!action.payload.data;
                state.apiResponse = !action.payload ? {} : action.payload;
            })
            .addCase(assignProjectDomain.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // // SAVE DOMAIN
            // .addCase(saveDomain.pending, (state, action) => {
            //     state.status = STATUSES.LOADING;
            // })
            // .addCase(saveDomain.fulfilled, (state, action) => {
            //     state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            //     state.error = !action.payload.data ? false : true ;
            //     state.apiResponse = !action.payload ? {} : action.payload;
            // })
            // .addCase(saveDomain.rejected, (state, action) => {
            //     state.status = STATUSES.FAILURE;
            //     state.error = action.error
            // })

            // DELETE DOMAIN
            .addCase(projectDomains.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(projectDomains.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_project_DomainList`;
                state.error = !action.payload.data ? false : true;
                state.project_DomainList = action.payload;
            })
            .addCase(projectDomains.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
    },
});


export default domainSlice.reducer;