import { memo, useEffect, useState } from "react"
import { RemoveCircleOutline } from "@mui/icons-material";
import SkillsHiring from "./SkillsHiring";
import dataService from "../../../redux/services/data.service";
import { toast } from "react-toastify";

const SpecialisationSkillsComp = ({ skills, formValues, removeSkill, index, handleSkillChange, showSkillData }) => {

    let industry = formValues.industry?.label ? formValues.industry.value : formValues.industry;
    let department = formValues.department?.label ? formValues.department.value : formValues.department;
    let specialistation_id = skills?.value

    const [skillDropDown, setSkillDropDown] = useState([])

    const functionSpec = async () => {
        const response = await dataService.skill_list_by_clientIndDepartmentSpecil(`{"filter":{"industry_id":{"value":${industry},"operation":"eq"},"department_id":{"value":${department},"operation":"eq"},"specialization_id":{"value":${specialistation_id},"operation":"eq"}},"no_limit":True}`);
        const result = response?.data
        if (result.status === 200) {
            let unique = result.data.rows.filter((skill, idx) => {
                return idx === result.data.rows.findIndex(o => skill.skill_id === o.skill_id)
            })
            setSkillDropDown(...skillDropDown, unique)
        } else {
            setSkillDropDown([])
            toast.error(response.data.message)
        }
    }

    const removeSkillsFunction = (id) => {
        removeSkill(id)
    }

    useEffect(() => {
        functionSpec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }}>
            <div className="formInput" style={{ flexBasis: '30%' }}>
                <label>  Technology  ({skills?.label})</label>
                <input
                    name={"skill"}
                    type="text"
                    placeholder="Skill"
                    value={skills?.label}
                    readOnly
                />
            </div>

            <SkillsHiring
                skills={skills}
                industry={industry}
                department={department}
                specialistation_id={specialistation_id}
                formValues={formValues}
                skill_List={skillDropDown}
                index={index}
                handleSkillChange={handleSkillChange}
                showSkillData={showSkillData}

            />

            <div className="formInput" style={{ flexBasis: '34%', marginTop: '3%' }}>
                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }}
                    onClick={() => removeSkillsFunction(skills.value)}
                />
            </div>
        </div>


    </>
}
export default memo(SpecialisationSkillsComp)