import React, { useEffect, useState } from 'react'
import './departments.scss'
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { toast, ToastContainer } from 'react-toastify';
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { domainList } from "../../redux/features/domainSlice"
import { getDepartmentList, getDepartmentDomains } from "../../redux/features/departmentSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
// import { Tooltip } from "@mui/material";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useNavigate } from 'react-router-dom';
// import { RefreshButton } from '../../components/common-function/RefreshButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ArrowCircleLeft } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Draggable from 'react-draggable';
import { Paper, Skeleton, Switch } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import CustomeTooltip from '../../components/common-function/CustomeTooltip';
import { useRef } from 'react';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';

const filterableColumns = ['name', 'code', 'departmentDomainList', 'status'];

const PaperComponent = (props) => {
  let [activeDrags, setActiveDrags] = useState(0)
  const onStart = () => {
    setActiveDrags(++activeDrags)
  }

  const onStop = () => {
    setActiveDrags(--activeDrags)
  }

  let dragHandlers = {onStart: onStart, onStop: onStop}

  return (
    <Draggable
      bounds="parent"
      {...dragHandlers}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


const { REACT_APP_ENV } = process.env;

const DepartmentDomain = ({ skeletonBase, handleOnRowScrollEnd }) => {

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { domains } = useSelector(state => state.domain)
  const { departmentList, departmentDomainsList, count, status } = useSelector(state => state.department)
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [gridRows, setGridRows] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [setDefaultValue] = useState("")
  const [limit, setLimit] = useState(25);
  const [reload, setReload] = useState(false);
  const [changeStatus, setChangeStatus] = useState('');
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [archiveId, setArchiveId] = useState(0);
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [departmentsData, setDepartmentsData] = useState({})
  const whereClause = { ...params, order_by:{column:'-status'}, "skip": skip, "limit": pageSize || 25 }
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [filterQuery, setFilterQuery] = useState(`{"filter":{"status":{"value":"True", "operation":"eq"}},"order_by":{"column":"-status"},"skip":0, "limit":25}`)

  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let handleSortActive = useRef(false);
  let isAnyChange = useRef({});
  let editID = useRef(null)
  //   const handleClickEdit = (id) => {
  //     flag = 1;
  //     dispatch(HiringModeEdit(id))
  // }

  // const onFilterChange = (props) =>
  //     {
  //         if(props.items.length > actionColumn.length)
  //             return;

  //         if(props.items.length === 0)
  //         {
  //             setFilterModel(props)
  //             setGridRows([]);
  //             let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`);
  //             setFilterQuery(str);
  //             return;
  //         }

  //         if(filterModal?.items?.length < props?.items?.length){
  //             if(props.items.length > 1)
  //             {
  //                 let selectedCols = filterModal.items.map(item=>item.columnField);
  //                 let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
  //                 let newProps ={...props};
  //                 newProps.items[newProps.items.length-1].columnField = remainCols[0];
  //                 let index = props.items.findIndex(item=>item.columnField === 'status')
  //                 if(newProps.items.some(item=>item.columnField==='status')){
  //                     newProps.items[index].value = undefined;
  //                     newProps.items[index].operatorValue = 'is';
  //                 }
  //                 setFilterModel(props)
  //             }
  //             else
  //                 setFilterModel(props)
  //             return;
  //         }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

  //             if(!(filterModal?.items?.length > props?.items?.length)){

  //                 // if same col is selected then do not let it select - starts
  //                 let selectedCols = props.items.map(item=>item.columnField);
  //                 let prevLen = selectedCols.length;

  //                 if((new Set(selectedCols)).size != prevLen){
  //                     toast.warn("Please select distinct column fields!")
  //                     return;
  //                 }
  //                 // if same col is selected then do not let it select - ends

  //                 setFilterModel(props)

  //                 let filterModalCols = [];
  //                 let propsCols = [];
  //                 filterModal.items.map(item=>filterModalCols.push(item.columnField));
  //                 props.items.map(item=>propsCols.push(item.columnField));

  //                 for(let i=0; i<filterModalCols.length; i++)
  //                 {
  //                     if(filterModalCols[i] != propsCols[i])
  //                         return;
  //                 }

  //             }

  //             setFilterModel(props)

  //             let filterItems = props.items;
  //             let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS[0].replace("'","").split(",").map(item=>item.replaceAll('"',"").trim())};
  //             let operators = {
  //             contains:'icontains',
  //             equals: 'ieq',
  //             is: 'eq',
  //             startsWith: 'istartsWith',
  //             endsWith: 'iendsWith'
  //             }

  //             if(filterItems?.length)
  //             {
  //                 let valContainers = filterItems.filter(item=>item?.value)
  //                 if(valContainers?.length)
  //                 {
  //                     let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
  //                     // if no changes on filter open starts
  //                       props.items.map((elem, index) => {

  //                         if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
  //                             isAnyChange.current[index] = true; 
  //                         else
  //                             isAnyChange.current[index] = false; 
  //                     })
  //                     // no change (! reverse by not operator)
  //                     if(!(Object.values(isAnyChange.current).every(item=>item===false))){
  //                         setGridRows([]);
  //                     }

  //                     // if no changes on filter open ends
  //                     setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
  //                 }
  //             }
  //             return;
  //         }
  //     }

  const onFilterChange = (props) => {

    handleSortActive.current = true;
    if (props.items.length > departmentDomainColumns.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" } };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'startswith',
        endsWith: 'endswith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          // setReload(true)
          flag.current = 1;
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  let flag = useRef(0);

  const handleSort = (sortingCol) => {
  
    flag.current = 1;
    stopOnRowScrollEnd.current = true;

    handleSortActive.current = true;

    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }


  const handleClickArchive = (data) => {

    setReload(true)
    setArchiveOpen(false)
    let addvalues = {
      setting_display_name: data.setting_display_name,
      setting_key: data.setting_key,
      is_json_value: data.is_json_value,
      value: data.key_value ? typeof (data.key_value) === "string" ? data.key_value : JSON.stringify(data.key_value) : data.setting_value,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }

    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
  }

  const modalDetail = {
    title: "Department-Domains Details",
    editModalTitle: "Assign Domains",
    addModalTitle: "Add Domains",
    formInputs: [
      { id: 1, name: "name", label: "Department Name", placeholder: "Enter Name", type: "textWithLabel", required: true, disabled: false },
      {
        id: 3, name: "status", label: "Status", placeholder: "Enter For Date", type: "status",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      {
        id: 2, name: "domain", label: "Domains", placeholder: "Enter Domains", type: "multiSelect",
        options: domains.map(e => ({ id: e.id, label: e.name, value: e.id })),
        required: true, disabled: false
      },
    ],
  }

  const departmentDomainColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Department Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 275,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              <CustomeTooltip
                title={params?.row?.department?.name || params.row?.name || "N/A"}
                data={params?.row?.department?.name || params.row?.name|| "N/A"}
              />
            </div>
        )
      }
    },
    {
      field: "code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Department Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 300,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              <CustomeTooltip
                title={params?.row?.department?.code || params?.row?.code || "N/A"}
                data={params?.row?.department?.code || params?.row?.code || "N/A"}
              />
            </div>
        )
      }
    },
    {
      field: "departmentDomainsList",
      headerName: "Domains",
      width: 480,
      hideable: false,
      filterable: false,
      sortable: false,
      type: 'singleSelect',
      valueOptions: domains,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.domains && params.row.domains.length ?
              params.row.domains.map((domain, index) => {
                return (
                  <p key={index} style={{ marginTop: "0px" }}>
                    {index === 0 && domain.name} {index === 0 && params.row.domains.length > 1 && ","}
                    {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                  </p>
                )
              })
              : "N/A"
            }
            {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}

          </div>
        );
      },
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,

    //   type: 'singleSelect',
    //   valueOptions: [
    //     { label: "Active", value: "True" },
    //     { label: "Inactive", value: "False" },
    //   ],
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`} >
    //         <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
              <div className="actionButton">
                <UcActionComponent
                  deleteIconName="ArchiveIcon"
                  moduleName="hiring-mode"
                  rowid={params?.row?.id}
                  addMoreData=""
                  editLinkUrl=""
                  viewLinkUrl=""
                  isDelete={false}
                  editButton={false}
                  viewButton={false}
                  additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
                  extraButton={() => onAdditionalAction(params.row, 'assign_domain')}
                />
              </div>
            </div>
        );
      },
    },
  ];

  // Submit Assignment Form
  const onSubmit = async (data) => {
    const finalData = { ...departmentsData, ...data }
    const postData = {
      department: finalData.department_id,
      "domain": finalData?.domain ? finalData.domain.map(e => { return e.id }) : departmentsData.domain.map(e => e.id),
      "status": true,
      created_by: userId
    };
    const res = await dataService.assign_department_domain(postData);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      setReload(true);
      dispatch(getDepartmentDomains());
      dispatch(getDepartmentList(whereClause));
      onCloseModal();
    }
    else {
      toast.error(res.data.message)
    }
  }

  // Additional Action (Assign Domains)
  const onAdditionalAction = async (data, key) => {

    if (key === "assign_domain") {
      setDepartmentsData(
        {
          ...delete (departmentsData.id),
          name: data.name,
          domain: data.domains,
          department_id: data.id,
          status: data.status,
          created_by: userId
        });
      setUpdating(true)
      setEditable(true);
      setOpen(true);
    }
  }
  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setDepartmentsData({});
  }
  
  // if (reload === true && flag.current === 0 && status === STATUSES.SUCCESS && departmentDomainsList.message) {
  //   if (departmentDomainsList.status === 200) {
      
  //       if (clearGridListing.current) {
          
  //         setGridRows([...departmentDomainsList.data.rows].map((dept, index) => ({
  //           ...dept,
  //           // eslint-disable-next-line
  //           domains: [...departmentDomainsList.data.rows].map(deptDomain => {
  //             if (dept.id === deptDomain.department.id) {
  //               const domain = deptDomain.domain;
  //               return domain;
  //             }
  //           }).filter(e => e !== undefined && e)
  //         })));
  //         clearGridListing.current = false;
  //         setSkeletonRows([])
  //         SET_MAX_ROW_LENGTH(departmentDomainsList.data.count);
  //       }
  //       else {

  //         setGridRows((pre) => [...pre, ...departmentDomainsList.data.rows].map((dept, index) => ({
  //           ...dept,
  //           // eslint-disable-next-line
  //           domains: [...departmentDomainsList.data.rows].map(deptDomain => {
  //             if (dept.id === deptDomain.department.id) {
  //               const domain = deptDomain.domain;
  //               return domain;
  //             }
  //           }).filter(e => e !== undefined && e)
  //         })));
  //         SET_MAX_ROW_LENGTH(departmentDomainsList.data.count);
  //         setSkeletonRows([])
  //       }
  //   } else {
  //     toast.error(departmentDomainsList.message)

  //   }

  //   setReload(false)
  // }


  if(reload === true && status === `${STATUSES.SUCCESS}_department_list` && departmentList.length>=0)
  {
    if (clearGridListing.current) {
        setGridRows(departmentList.map(item=>{
          let obj = {...item, domains:[]}; 
          let values = departmentDomainsList?.data?.rows.filter(ditem=>ditem?.department_id === item?.id).map(item=>({...item.domain, name:item.domain.name}));
          if(values.length>0)
          {
            obj = {...obj, domains: values}
          }
          return obj;
        }));
        clearGridListing.current = false;
        flag.current = 0;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(departmentList.length);
        setReload(false);
    }
    else{
      setGridRows((pre) => [...pre, ...departmentList.map(item=>{
        let obj = {...item, domains:[]}; 
        let values = departmentDomainsList?.data?.rows.filter(ditem=>ditem?.department_id === item?.id).map(item=>({...item.domain, name:item.domain.name}));
        if(values.length>0)
        {
          obj = {...obj, domains: values}
        }
        return obj;
      })]);
      SET_MAX_ROW_LENGTH(departmentList.length);
      setSkeletonRows([])
      setReload(false);
    }
    stopOnRowScrollEnd.current = false;

  }


  useEffect(() => {
    setReload(true)
    dispatch(getDepartmentDomains())
    setTimeout(()=>
    {
      dispatch(getDepartmentList(handleSortActive.current ? JSON.parse(filterQuery) : whereClause))
    },400)
    dispatch(domainList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, params, filterQuery])

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top-headings new-rtms-heading new-design-icon">
          <div style={{ fontSize: "20px" }}>
            Department Domain
          </div>
          {/* <h3 ><i><ArrowCircleLeft className='new-back-icon'/> </i> Department-Domain </h3> */}
          <div className='datatable'>
            {/* <RefreshButton api={getDepartmentList} filter={whereClause} style={{ marginTop: 0 }} />
            <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new ">
                <ArrowBackIcon />
              </button>
            </Tooltip> */}
          </div>
        </div>


        <ToastContainer />
        {/* <DynamicTable
              moduleName="holidays"
              tableColumns={departmentDomainColumns}
              tableRows={
                gridRows.length>0 ? gridRows.map((dept, index) => ({
                  ...dept,
                  // eslint-disable-next-line
                  domains: departmentDomainsList.map(deptDomain => {
                    if (dept.id === deptDomain.department.id) {
                      const domain = deptDomain.domain;
                      return domain;
                    }
                  }).filter(e => e !== undefined && e)
                }))
                || []} 

              currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
              setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
              setFieldName={setFieldName} setDefaultValue={setDefaultValue}
              paginationMode={"server"}
              rowCount={count}
              loading={status === STATUSES.LOADING}
              columnVisibilityModel={{
                id: false,
              }}

              additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
              onAdditionalAction={onAdditionalAction}
            // onViewDetail={onView}
            // onEdit={handleEdit}
            // deleteRow={deleteHoliday} 
            limit={limit}
             setLimit={setLimit}
            /> */}

        <DataGridPro
          className='datagrid'
          filterMode='server'
          loading={!!reload}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          sx={{
            height: 700,
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#438bf969",
            },
            ".actionButton": {
              display: 'none'
            },
            [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                ".actionButton": {
                  display: 'block'
                }
              }
            }
          }}


          rows={gridRows.length > 0 ? gridRows.concat(skeletonRows) : []
          }
          columns={departmentDomainColumns}
          filterModel={filterModal}
          getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          components={{
            ColumnMenuIcon: MoreHorizIcon,
            NoRowsOverlay: CustomNoRowsOverlay
          }}
          componentsProps={{
            row: {
              oncontextmenu: handleContextMenu
            }
          }}
          onFilterModelChange={onFilterChange}
          onRowsScrollEparand={handleOnRowScrollEnd}
          hideFooter
        />
        {/* { */}
        <RegularizeModal
          modalDetail={modalDetail}
          open={open}
          onOpen={onOpenModal}
          onClose={onCloseModal}
          // onCancel={onCancel}
          onSubmit={onSubmit}
          hideBackdrop
          fullWidth
          maxWidth
          updating={updating}
          editData={departmentsData}
          editable={editable}
          formLoading={formLoading}
          setFormLoading={setFormLoading}
          additionalActions={modalDetail.additionalActions}
          // onAdditionalAction={() => {return;}}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        />
        {/* } */}

        {/* <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} setting`}
          handleArchive={handleClickArchive}
          id={archiveId}
        /> */}
      </div>
    </div>
  )
}

export default DepartmentDomain