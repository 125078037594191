import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogTitle } from '@mui/material'
import React from 'react'
import Select from 'react-select';
import LoaderForm from '../../common-function/LoaderForm';

function AssignPopup({ approverList, handleClose, handleChange, submitData, open, fieldType, loader }) {

    const onSubmit = (e) => {
        e.preventDefault();
        if (fieldType === 'assign-hr') {
            submitData('assign-hr')
        } else if (fieldType === 'assign-manager') {
            submitData('assign-manager')
        }
    }
    return (
        <div>
            {loader === 'submit' && <LoaderForm />}
            <Dialog className="candidate-screening-modal-box"
                hideBackdrop
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>

                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                    {fieldType === 'assign-manager' ? 'Sent to Manager' :
                        fieldType === 'assign-hr' ? 'Sent to Hr' :
                            fieldType === 'assign-approver' ? 'Assign approver' :
                                'Assign'}</DialogTitle>
                <form style={{ margin: "20px 20px 20px 20px" }}>
                    <div className="add-new-user-form">
                        {fieldType === 'assign-manager' &&
                            <div className='formInput' style={{ flexBasis: "100%" }}>
                                <label>Select Manager </label>
                                <Select
                                    className="select"
                                    placeholder="Select Manager"
                                    isSearchable={true}
                                    onChange={handleChange}
                                    name="approver"
                                    options={Array.isArray(approverList) ? approverList.map(item => ({ label: item.display_name, value: item.id, name: "approver" })) : []}
                                />
                            </div>
                        }

                        {fieldType === 'assign-hr' &&
                            <div className='formInput' style={{ flexBasis: "100%" }}>
                                <label>Select Hr Manager</label>
                                <Select
                                    className="select"
                                    placeholder="Select hr"
                                    isSearchable={true}
                                    onChange={handleChange}
                                    name="approver"
                                    options={Array.isArray(approverList) ? approverList.map(item => ({ label: item.display_name + "(" + item.email + ")", value: item.id, name: "approver" })) : []}
                                />
                            </div>
                        }
                        {/* <div className='formInput' style={{ flexBasis: "100%" }}>
                        <label>Comment </label>
                        <textarea
                            className="select"
                            placeholder="Comment"
                            onChange={handleChange}
                            name="approver_comment"
                        />
                    </div> */}

                        <div className="formInput">
                            <button
                                className={loader === 'submit' ? "submit-btn-disabled " : "btn btn-submit"}
                                disabled={loader === 'submit' ? true : false}
                                onClick={onSubmit}>Submit</button>
                        </div>
                    </div>
                    <br />
                    <br />
                </form>
            </Dialog>
        </div>
    )
}

export default AssignPopup