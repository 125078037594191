import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Stack, TablePagination, Tooltip, Skeleton, Switch, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPurchesdpackage, purchesdpackageCreat, purchesdpackageEdit, purchesdpackageUpdate,getCurrencyList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import ArchiveIcon from '@mui/icons-material/Archive';
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField, SingleDatePickerInputField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import Select from 'react-select';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

const skeletonBase = {name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', price:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',valid_upto:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', currency__currency_name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', description:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', };

const filterableColumns = ['name', 'price', 'valid_upto', 'currency__currency_name', 'description', 'status'];


const initialFormData = Object.freeze({
    name: "",
    price: "",
    currency: "",
    valid_upto: "",
    description: "",
    status: true,
});


const formErrData = Object.freeze({
    nameErr: "Name is required",
    priceErr: "Price is required",
    currencyErr: "Currency is required",
    valid_uptoErr: "Valid upto date is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required",
    priceValidErr: "Please enter valid price"
});

const PaperComponent = (props) => {
    return (
        <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}

const Purchespakage = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, purchasedPackeges_List, purchasedPackeges_Create, purchasedPackeges_Edit, purchasedPackeges_Update } = useSelector(state => state.master)
    const { currency_List } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    // grid states starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});
    // grid states ends

 

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.description === "" || formValues.name === "" || formValues.status === "" || formValues.currency === "" || formValues.price === "" || formValues.price < 0 || formValues.valid_upto === "" || formValues.currency === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.description !== "" && formValues.name !== "" && formValues.status !== "" && formValues.price >= 0 && formValues.valid_upto !== "" && formValues.currency !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId =rtm_user?.user?.data.id
            let addvalues = {
                ...formValues, created_by: userId,
                valid_upto: formValues.valid_upto,
                currency:formValues?.currency?.label ? formValues.currency.value : formValues.currency,
            }
           
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(purchesdpackageUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(purchesdpackageCreat(addvalues))
            }

            setErrStatus(false)
            setReload(true)
            handleClose()
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_purchasedPackeges_Edit` && purchasedPackeges_Edit && purchasedPackeges_Edit.data) {
        let editData = purchasedPackeges_Edit.data
        setFormValues({
            name: editData.name,
            description: editData.description,
            status: editData.status,
            price: editData.price,
            valid_upto: editData.valid_upto,
            currency: {
                label: editData.currency.currency_name,
                value: editData.currency.id,
                name: "currency"
            }
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(purchesdpackageEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
            price: data.price,
            valid_upto: data.valid_upto,
            currency: data.currency_id
        }
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(purchesdpackageUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getPurchesdpackage(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getPurchesdpackage(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(purchasedPackeges_List && purchasedPackeges_List.data && purchasedPackeges_List.data.rows && purchasedPackeges_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const handleSort = (sortingCol) => {
        setReload(true);
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['limit'] = gridRows.length;
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }

        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
    }

    const actionColumn = [
        {
            field: "name",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :

                    <div>
                        {params.row.name}
                    </div>
                );
            },
        },
        {
            field: "price",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Price</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("price")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.price === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :

                    <div>
                        {params.row.price}
                    </div>
                );
            },
        },
        {
            field: "currency__currency_name",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Currency</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("currency__currency_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.currency__currency_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :

                    <div>
                        {params.row.currency__currency_name}
                    </div>
                );
            },
        },
        {
            field: "valid_upto",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Valid Upto</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("valid_upto")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.valid_upto === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :

                    <div>
                        {params.row.valid_upto}
                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Description</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("description")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :

                    <div>
                        {params.row.description ? params.row.description : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'},{label:'InActive', value:'False'}],
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> :

                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                    <div className="actionButton">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="purches_package"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} 
                        />
                    </div>
                </div>
                );
            },
        },
    ];
    const onFilterChange = (props) =>
    {
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            isAnyChange.current = {};
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    if(!newProps.items[index].value)
                    {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }

                setFilterModel(newProps)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts

                    if(props.items.length < Object.keys(isAnyChange.current).length){
                        let lenDiff = Object.keys(isAnyChange.current).length - props.items.length;
                        let copyObj = {...isAnyChange.current};
                        for(let i=lenDiff; i>=0; i--)
                        {
                            delete copyObj[i];
                        }
                        isAnyChange.current = copyObj;
                    } 
                    props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    
                    // no change (! reverse by not operator)
                    if(Object.values(isAnyChange.current).every(item=>item===false) && (props.items.length === Object.keys(isAnyChange.current).length) && (props.items.length === filterModal.items.length))
                        return;
                    else{
                        setGridRows([]);
                        setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                    }
                    // if no changes on filter open ends

                    
                }
            }
            return;
        }
    }

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };


    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

    if (reload === true && status === `${STATUSES.SUCCESS}_purchasedPackeges_Update` && purchasedPackeges_Update && purchasedPackeges_Update.message) {
        if (purchasedPackeges_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(purchasedPackeges_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(purchasedPackeges_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_purchasedPackeges_Create` && purchasedPackeges_Create && purchasedPackeges_Create.message) {
        if (purchasedPackeges_Create.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(purchasedPackeges_Create.message)
        } else {
            toast.error(purchasedPackeges_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_purchasedPackeges_list` && purchasedPackeges_List && purchasedPackeges_List.message) {
        if (purchasedPackeges_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...purchasedPackeges_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(purchasedPackeges_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...purchasedPackeges_List.data.rows]);
                SET_MAX_ROW_LENGTH(purchasedPackeges_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(purchasedPackeges_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }
    
    useEffect(()=>
    {
        dispatch(getCurrencyList(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`))
    },[]);

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getPurchesdpackage(filterQuery))
    }, [filterQuery])

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Purchase Packages</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getPurchesdpackage} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>

                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={ skeletonRows.length == 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                              },
                              [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                  ".actionButton": {
                                    display: 'block'
                                  }
                                }
                              }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                          }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Purchase Type</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div style={{width:'100%'}}>
                                    <InputFields
                                        label={"Name "}
                                        name="name"
                                        defaultValue={formValues.name}
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.nameErr}
                                    />
                                </div>
                                <div style={{width:'100%'}}>
                                    <InputFields
                                            label={"Price"}
                                            name="price"
                                            defaultValue={formValues.price}
                                            type="number"
                                            placeholder="Price"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.priceErr}
                                    />
                                    {errStatus === true && (formValues.price < 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.priceValidErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Select Currency</label>
                                    <Select
                                        label={"Select Currency"}
                                        classNamePrefix="select"
                                        placeholder={'Select Currency'}
                                        onChange={handleChange}
                                        defaultValue={formValues.currency}
                                        isSearchable={true}
                                        name={'currency'}
                                        options={
                                            currency_List?.data?.rows.map((option) => {
                                                return {
                                                    label: option.currency_name,
                                                    value: option.id,
                                                    name: "currency"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.currency === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.currencyErr}</p>}
                                </div>
                                <SingleDatePickerInputField
                                    label={'Valid Upto'}
                                    placeholder="Select"
                                    defaultValue={formValues.valid_upto}
                                    name={"valid_upto"}
                                    onChange={(e) => {
                                        handleChange({
                                            name: "valid_upto",
                                            value: e
                                        })
                                    }}
                                    format={'yyyy-MM-dd'}
                                    errStatus={errStatus}
                                    formErr={formErr.valid_uptoErr}
                                />
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className ={reload === true ? 'btn-disable':'submit-modal'} style={{float:'right'}} 
                            disabled={reload === true ? true :false } onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Purchase Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                    <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>
            
                </Menu>
            </div>
        </div >
    )
}

export default Purchespakage;