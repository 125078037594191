import { useEffect, useRef, useState } from "react";
import "./manageuser.scss";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useDispatch, useSelector } from "react-redux";
import { employementColumns } from "../../components/datatable-coulms/tableCoulmns";
import { getUserType, updateEmployeType } from "../../redux/features/userTypeSlice";
import dataService from "../../redux/services/data.service";
import { Button, Dialog, Stack, TablePagination, DialogContent, Skeleton, Switch, Tooltip, Menu, MenuItem } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Edit } from "@mui/icons-material";
import AddManageUser from "./AddUser";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";


let flag = 1;

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SubAllManageUser = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {
  const dispatch = useDispatch();

  const { data, status, error, updateEmpdata,createEmpdata } = useSelector(state => state.userType)
  const { data: list } = data

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  // const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [showProjectForm, setShowProjectForm] = useState(false)
  const [editableID, setEditId] = useState()

  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');

  let ishandleClickArchiveActive = useRef(false);

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])


  


  if (reload === true && status === STATUSES.SUCCESS && data && data.message) {
    if (data.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...data.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(data.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...data.data.rows]);
        SET_MAX_ROW_LENGTH(data.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(data.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

    if (reload=true && status === `${STATUSES.SUCCESS}_emptype_create` && createEmpdata && createEmpdata.message) {
      if (createEmpdata.status === 200) {
          toast.success(createEmpdata.message)
          setGridRows([]);
          let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:25}));
          setFilterQuery(str)
      } else {
          toast.error(createEmpdata.message)
      }
      setReload(false)
  }






  const handleClose = () => {
    flag = 0
    setShowProjectForm(false);
    setContextMenu(null);
  }

  const handleClickEdit = (id) => {
    flag=1
   
    setEditId(id)
    setShowProjectForm(true)
  }

  const handleOpen = () => {
    setShowProjectForm(true);
    setEditId('')
  }
  let actionColumn = [
    {
      field: "employment_type",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Employment Type Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("employment_type")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 400,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.employment_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              <CustomeTooltip
                title={params?.row?.employment_type || "N/A"}
                data={params?.row?.employment_type || "N/A"}
              />
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
              <div className="actionButton">
                <UcActionComponent moduleName="users"
                  rowid={params.row.id}
                  editLinkUrl=""
                  viewLinkUrl=""
                  editButton={handleClickEdit}
                  viewButton={false}
                
                   />
              </div>
            </div>
        );
      },  
    },
  ];

 

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);
    switch (actionType) {
      case 'edit':
        handleClickEdit(row.id)
        break;
    }
    setContextMenu(null);
  }



  const handleArchive = (id) => {
    ishandleClickArchiveActive.current = true;
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }
  const handleClickArchive = (data) => {
    if(ishandleClickArchiveActive.current === false){
        return;
    }
    setArchiveOpen(false)
    let addvalues = {
      employment_type: data.employment_type,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }
     clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    flag=0;
  
    ishandleClickArchiveActive.current = false;
    dispatch(updateEmployeType({ ID, addvalues }))
  }




  if (flag===0 && status === `${STATUSES.SUCCESS}_emptype_update` && updateEmpdata && updateEmpdata.message) {
   
    if (updateEmpdata.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      setGridRows([]);
       toast.success(updateEmpdata.message)
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(updateEmpdata.message)
    }
    flag=1;
  }

  




  return (
    <div className="new">
      <div className="newContainer">
        <div className="datatable">
          
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>All Employment Type</p>
            </div>
            <div>
              <Stack direction="row">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                <RefreshButton api={getUserType} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
              </Stack>
            </div>
          </div>

          <ToastContainer />
          <div className="custom-pagination-class">
            {/* <CustomPagination /> */}
          </div>
          <DataGridPro
            className="datagrid"
            filterMode="server"
            loading={!!reload}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            rows={gridRows?.concat(skeletonRows)}
            columns={actionColumn}
            filterModel={filterModal}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooter
          />
        </div>


        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} hiring mode`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />
        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={showProjectForm}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <Button
            edge="start"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">
            {editableID ? 'Edit Employment Type' : 'Add New Employment Type'}
            {/* Add New Employment Type */}
          </DialogTitle>

          <DialogContent>
            {editableID ? <AddManageUser showProjectForm={showProjectForm} id={editableID} handleclose={handleClose} /> : <AddManageUser showProjectForm={showProjectForm} handleclose={handleClose} />}
          </DialogContent>
        </Dialog>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >

          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          {/* <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Delete</MenuItem> */}

        </Menu>
      </div>

    </div>
  )
}

const AllManageUser = DataGridProLazyLoadHOC(SubAllManageUser, { filterColumns: DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()), getDispatcherAsyncThunk: getUserType, gridColumns: ['employment_type', 'status'] });

export default AllManageUser