import TablePaginationActions from '../../components/TablePaginationActions'
import Filter from '../../components/Filter'
import UcActionComponent from '../../components/common-function/UcActionComponent'
import Select from 'react-select';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay'
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import GetLocalstorage from '../../components/common-function/GetLocalstorage'
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal'
import { useState, useEffect, useRef } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Stack, TablePagination, Skeleton, Switch, Menu, MenuItem, fabClasses } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { STATUSES } from "../../redux/common-status/constant";
import { Close, Edit } from '@mui/icons-material'
import { ThirdPartyCredentialHeadCreate, ThirdPartyCredentialHeadUpdate, allcredentialHeads, getThirdPartyCredentialHead } from '../../redux/features/cedentialHeadSlice'
import { getThirdPartyDomainList } from '../../redux/features/thirdPartySlice'
import { RefreshButton } from '../../components/common-function/RefreshButton'
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import "./master.scss"

const skeletonBase = {third_party:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', credential_head:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['third_party', 'credential_head', 'status'];

const PaperComponent = (props) => {
    return (
        <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}

const ThirdPartyCredentialHead = () => {

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let userId = rtm_user?.user?.data && rtm_user.user.data.id

    const dispatch = useDispatch();
    const { status, error, thirdPartyCredentialsList, credentialHeadList, thirdPartyCredentials_update, thirdPartyCredentials_create } = useSelector(state => state.credentialHead)
    const { thirdParty_Domain } = useSelector(state => state.thirdParty)

    const [reload, setReload] = useState(false)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":25,"order_by":{"column":"-status"},"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);

    const [filterThirdPartyList, setFilterThirdPartyList] = useState([]);
    const [filterHeadList, setFilterHeadList] = useState([]);

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [openSingle, setOpenSingle] = useState(false)
    const [formData, setFormData] = useState({
        credential_head: "",
        third_party: "",
        status: true,
    })
    const [archiveId, setArchiveId] = useState(0)
    const [changeStatus, setChangeStatus] = useState('');
    const [archiveOpen, setArchiveOpen] = useState(false)

    // grid states and ref starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});
    // grid states and ref ends

    //  Open Modal
    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
        setFormData({
            credential_head: "",
            third_party: "",
            status: true,
        })
        setContextMenu(null)
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyCredentialHead(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyCredentialHead(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(thirdPartyCredentialsList && thirdPartyCredentialsList.data && thirdPartyCredentialsList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const selectValueThirdPartyInLable = (id) => {
        let data = thirdParty_Domain?.data.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "third_party" })
        })
        return newObject
    }

    const selectValueCredentialInLable = (id) => {
        let data = credentialHeadList?.data?.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.head_name, name: "credential_head" })
        })
        return newObject
    }

    const onEditBtn = (data) => {
        onOpenModal()
        setFormData({
            credential_head: selectValueCredentialInLable(data.credential_head_id),
            third_party: selectValueThirdPartyInLable(data.third_party_id),
            created_by: data.created_by,
            status: data.status,
            id: data.id
        })
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormData({ ...formData, [name]: name === 'status' ? checked : value });
    }

    const onSubmit = (e) => {
        e.preventDefault()

        // validation
        if (!formData.third_party) {
            return toast.error('Third party is required')
        } else if (!formData.credential_head) {
            return toast.error('Credetial head is required')
        } else if (formData.status === "") {
            return toast.error('Status is required')
        }
        clearGridListing.current = true;

        let request;
        if (formData.id > 0) {
            request = ({
                ...formData,
                status: formData.status,
                credential_head:  formData?.credential_head?.value ? formData.credential_head.value : formData.credential_head,
                third_party:formData?.third_party?.value ? formData.third_party.value : formData.third_party,
                updated_by: userId
            })
        } else {
            request = ({
                ...formData,
                status: formData.status,
                credential_head: formData.credential_head,
                third_party: formData.third_party,
                created_by: userId
            })
        }
        if(formData.id > 0)
        {
            let copyRows = [...gridRows];
            editID.current = copyRows.find(item=>item.id === formData.id );
            let index = copyRows.findIndex(item=>item.id === formData.id ); 
            copyRows.splice(index, 1, {...skeletonBase, id:formData.id });
            setGridRows(copyRows);
            dispatch(ThirdPartyCredentialHeadUpdate({ID:formData.id, addvalues: request}));

        }else
        { 
            gridTotalRows.current = gridRows.length;
            dispatch(ThirdPartyCredentialHeadCreate(request));
        }
        setReload(true)
    }


    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }


    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            credential_head:  data.credential_head_id,
            third_party: data.third_party_id,
            updated_by: userId,
            status: !data.status,
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(ThirdPartyCredentialHeadUpdate({ ID, addvalues }))
    }

    // filter
    const onFilterChange = (props) =>
    {
        if(props.items.length > ThirdPartyColumns.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            isAnyChange.current = {};
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.find(item=>item.columnField==='status')){
                    if(!newProps.items[index].value)
                    {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }

                setFilterModel(newProps)
            }
            else{
                setFilterModel(props)
            }
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts

                    if(props.items.length < Object.keys(isAnyChange.current).length){
                        let lenDiff = Object.keys(isAnyChange.current).length - props.items.length;
                        let copyObj = {...isAnyChange.current};
                        for(let i=lenDiff; i>=0; i--)
                        {
                            delete copyObj[i];
                        }
                        isAnyChange.current = copyObj;
                    } 
                    props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(Object.values(isAnyChange.current).every(item=>item===false) && (props.items.length === Object.keys(isAnyChange.current).length) && (props.items.length === filterModal.items.length))
                        return;
                    else{
                        setGridRows([]);
                        setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                    }
                    // if no changes on filter open ends

                    
                }
            }
            return;
        }
    }

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }

        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
        setGridRows([]);
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const ThirdPartyColumns = [
        {
            field: "third_party",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Third Party</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("third_party__name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            type:'singleSelect',
            valueOptions:thirdParty_Domain?.data?.rows.map(item=>({label:item.name, value:String(item.id)})),
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.third_party === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :
                    <div>
                        {params.row.third_party__name}
                    </div>
                );
            },
        },
        {
            field: "credential_head",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Credential Head</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("credential_head__head_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            type:'singleSelect',
            valueOptions:credentialHeadList?.data?.rows.map(item=>({label:item.head_name, value:String(item.id)})),
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.credential_head === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :
                    <div>
                        {params.row.credential_head__head_name}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName=""
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            editButton={() => onEditBtn(params.row)}
                            isDelete={false}
                            viewButton={false}
                            deleteButton={false} 
                        />
                    </div>
                </div>
                );
            },
        },
    ]

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };


    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
        
        switch(actionType)
        {
            case 'edit':    
                    onEditBtn(row)
                    break;
        }

        setContextMenu(null);

    }
    

    if (status === `${STATUSES.SUCCESS}_credentialHeadList` && filterHeadList && filterHeadList.length === 0 && credentialHeadList && credentialHeadList.data) {
        let array = []
        credentialHeadList?.data?.rows.map(e => { return array.push({ value: e.id, label: e.head_name, name: "credential_head" }) })
        array.length > 0 && setFilterHeadList(array)
        array.length > 0 && setOptions({ ...options, credential_head_id: array })
    }

    if (filterThirdPartyList.length === 0 && thirdParty_Domain && thirdParty_Domain.data) {
        let array = []
        thirdParty_Domain?.data?.rows.map(e => { return array.push({ value: e.id, label: e.name, name: "third_party" }) })
        array.length > 0 && setFilterThirdPartyList(array)
        array.length > 0 && setOptions({ ...options, third_party_id: array })
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdPartyCredentialsList` && thirdPartyCredentialsList.message) {
        if (thirdPartyCredentialsList.status === 200) {
            if(clearGridListing.current){
                setGridRows([...thirdPartyCredentialsList.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(thirdPartyCredentialsList.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...thirdPartyCredentialsList.data.rows]);
                SET_MAX_ROW_LENGTH(thirdPartyCredentialsList.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(thirdPartyCredentialsList.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdPartyCredentials_update` && thirdPartyCredentials_update.message) {
        if (thirdPartyCredentials_update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            onCloseModal()
            toast.success(thirdPartyCredentials_update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(thirdPartyCredentials_update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdPartyCredentials_create` && thirdPartyCredentials_create && thirdPartyCredentials_create.message) {
        if (thirdPartyCredentials_create.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(thirdPartyCredentials_create.message)
            onCloseModal()
        } else {
            toast.error(thirdPartyCredentials_create.message)
        }
        setReload(false)
    }

    useEffect(() => {
        dispatch(allcredentialHeads())
        dispatch(getThirdPartyDomainList())
    }, [])

    useEffect(()=>
    {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getThirdPartyCredentialHead(filterQuery))

    },[filterQuery]);

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Third Party Credential</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={onOpenModal}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getThirdPartyCredentialHead} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={ skeletonRows.length == 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                    display: 'block'
                                    }
                                }
                                }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={ThirdPartyColumns}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                        }}  
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                    
                </div>

                {/* model for add /edit  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="child-modal-description"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">
                        {formData.id ? "Edit Third Party Credential" : "Add Third Party Credential"}
                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form" style={{flexDirection:'column'}}>
                                <div className="formInput">
                                    <label>
                                        Third Party*
                                    </label>
                                    <Select
                                        className="basic-single"
                                        placeholder="Select Third Party"
                                        defaultValue={formData.third_party}
                                        isSearchable={true}
                                        name={"third_party"}
                                        onChange={(e) => handleChange(e)}
                                        options={filterThirdPartyList}
                                    />
                                </div>

                                <div className="formInput">
                                    <label>
                                        Credential Head*
                                    </label>
                                    <Select
                                        className="basic-single"
                                        placeholder="Select Credential Head"
                                        defaultValue={formData.credential_head}
                                        isSearchable={true}
                                        name={"credentails_head"}
                                        onChange={(e) => handleChange(e)}
                                        options={filterHeadList}
                                    />
                                </div>
                            </div>

                            <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formData.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            <button className={reload === true ? 'btn-disable' : 'submit-modal'} disabled={reload === true ? true : false} style={{ marginTop: "40px", float:'right' }} onClick={onSubmit}>Submit</button>
                        </form>
                    </DialogContent>
                </Dialog>

                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} setting`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={onCloseModal}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        onCloseModal();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
            
                </Menu>

            </div>
        </div >
    )
}
export default ThirdPartyCredentialHead