import * as React from 'react';
import "./jirastatus.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Tooltip, Stack, IconButton, Skeleton, Switch, MenuItem, Menu } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useDispatch, useSelector } from "react-redux";
import { getStatusCategoryList, jiraStatusCreate, jiraStatusList, jiraStatusUpdate, statusSyncByJira } from "../../redux/features/jiraStatusSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DataService from "../../redux/services/data.service";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Sync, Close, HeightTwoTone, Edit, TroubleshootOutlined } from '@mui/icons-material'
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import Select from 'react-select';
import { DataGridPro, gridClasses, getGridStringOperators } from '@mui/x-data-grid-pro';
import DataGridProLazyLoadHOC from '../../components/common-function/DataGridProLazyLoadHOC';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import CustomeTooltip from '../../components/common-function/CustomeTooltip';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import StatusInputValue from '../../components/common-function/StatusOperator';
import PaperComponent from '../../components/common-function/PaperComponent';
import { getMasterStatusCategory } from '../../redux/features/masterSlice';

const initialFormData = Object.freeze({
    status_name: "",
    description: "",
    category: "",
    workflow_status_category: "",
    status: true,
    jira_id: null,
    icon_url: null,
    is_jira_status: true,
    status: true,
    short_code: ""
});


let flag = 1;


const formErrData = Object.freeze({
    status_nameErr: "Status name is required",
    descriptionErr: "Description is required",
    categoryErr: "Status category is required",
    shortErr: "Short code is required",
    statusErr: "Status is required"
});








const SubJiraStatus = ({ onFilterChange, sort,handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu,setSort }) => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const location = useLocation();
    const modalOpen = location.pathname && location.pathname === "/all-jira/add" ? true : false;
    const [open, setOpen] = useState(modalOpen);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { jira_status_list, status, error, jira_status_create, jira_status_update, jira_status_archive, syncJiraStatus, category_List } = useSelector(state => state.jira_status)
    const { master_status_category_list } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [syncLoading, setSyncLoading] = useState(false)
    const [limit, setLimit] = useState(25);
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        //  dispatch(getStatusCategoryList())
         dispatch(getMasterStatusCategory());
        // eslint-disable-next-line
    }, [])

   

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            toast.error(error)
            // errorHandling(error, navigate)
        }
        // eslint-disable-next-line
    }, [error])


    if (reload === true && status === STATUSES.SUCCESS && jira_status_list && jira_status_list.message) {
        if (jira_status_list.status === 200) {
            if (clearGridListing.current) {
                sort ? setGridRows([...jira_status_list.data.rows]):setGridRows([...jira_status_list.data.rows].sort(item=>item.status ? -1:1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(jira_status_list.data.count);
            }
            else {
               sort? setGridRows((pre) => [...pre, ...jira_status_list.data.rows]):setGridRows((pre) => [...pre, ...jira_status_list.data.rows].sort(item=>item.status ? -1:1));
                SET_MAX_ROW_LENGTH(jira_status_list.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(jira_status_list.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_update` && jira_status_update && jira_status_update.message) {
        if (jira_status_update.status === 200) {
            toast.success(jira_status_update.message)
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current?.id === item?.id)
                    return { ...jira_status_update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            setReload(false)
        } else {
            toast.error(jira_status_update.message)
        }
    }


    if (syncLoading === true && status === `${STATUSES.SUCCESS}_syncJiraStatus` && syncJiraStatus && syncJiraStatus.message) {
        toast(syncJiraStatus.message)
        setSyncLoading(false)
        setTimeout(() => {
            dispatch(jiraStatusList(filterQuery))
        }, 500);
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => { if (ID) { setID(0); setFormValues(initialFormData); } setOpen(false); setFormValues(initialFormData); setErrStatus(false); }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })

    }

    const submitData = (e) => {
        e.preventDefault()

        if (!!!formValues.status_name  ||  !!!formValues.description || !!!formValues.short_code || !!!formValues.workflow_status_category ) {
            setErrStatus(true)
            return;
        }

        if (formValues.status_name!==""  && formValues.description!=="" && formValues.short_code!=="" && formValues.workflow_status_category!==""  ) {
            let shortLength = formValues?.short_code?.length
            if (shortLength > 6) {
                setErrStatus(true)
                return
            }
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = {
                ...formValues,
                name: formValues.status_name,
                workflow_status_category: formValues.workflow_status_category.value ? formValues.workflow_status_category.value : formValues.workflow_status_category.label === null ? null : formValues.workflow_status_category,
                created_by: userId
            }
            delete addvalues.status_name;
            setErrStatus(false)

            setReload(true)
            handleClose()
            if (ID) {
                dispatch(jiraStatusUpdate({ ID, addvalues }))
            } else {
                dispatch(jiraStatusCreate(addvalues))
            }
            setSort(false)
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_create` && jira_status_create && jira_status_create.message) {
        setReload(false)
        if (jira_status_create.status === 200) {
            toast.success(jira_status_create.message)
            setTimeout(() => {
                dispatch(jiraStatusList(filterQuery))
            }, 500)
        } else {
            toast.error(jira_status_create.message)
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_archive` && jira_status_archive && jira_status_archive.message) {
        toast.success(jira_status_archive.message)
        setReload(false)
        setArchiveOpen(false)
        setTimeout(() => {
            dispatch(jiraStatusList(filterQuery))
        }, 500)
    }


    const handleClickEdit = async (id) => {
        const res = await DataService.jira_status_edit(id)
        if (res && res.data && res.data.data) {
            let editData = res.data.data;
            setFormValues({
                status_name: editData.name,
                description: editData.description,
                category: editData.category,
                workflow_status_category: { label: editData?.workflow_status_category && editData?.workflow_status_category?.status_category_name, value: editData && editData.workflow_status_category_id },
                short_code: editData.short_code,
                status: editData.status,
                jira_id: null,
                icon_url: null
            })
            setID(editData.id)
            editID.current={...editData}
            setOpen(true)

        }

    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }



    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        setReload(true)
        let addvalues = {
            category: data.category,
            workflow_status_category: data.workflow_status_category_id,
            short_code: data.short_code,
            name: data.name,
            description: data.description,
            jira_id: data.jira_id,
            icon_url: data.icon_url,
            status: !data.status,
        }
        let ID = data.id;
        editID.current={...data}
        setReload(true)
        dispatch(jiraStatusUpdate({ ID, addvalues }))
    }

    const syncStatus = () => {
        setSyncLoading(true)
        dispatch(statusSyncByJira())

    }



    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );
    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
    
    let actionColumn = [
        {
            field: "name",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
            width: 400,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
                        <div>
                            <CustomeTooltip
                                title={params?.row?.name || "N/A"}
                                data={params?.row?.name || "N/A"}
                            />
                        </div>
                );
            },
        },
        {
            field: "short_code",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Short Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
            width: 400,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
                        <div
                            style={{
                                backgroundColor: params.row.workflow_status_category?.background_color_code,
                                color: params.row.workflow_status_category?.foreground_color_code,
                                padding:"5px",
                                borderRadius:"5px"
                            }}>
                            <CustomeTooltip
                                title={params?.row?.short_code || "N/A"}
                                data={params?.row?.short_code || "N/A"}
                            />
                        </div>
                );
            },
        },
        {
            field: "workflow_status_category_id",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Category</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
            width: 300,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {

                return (
                    params?.row?.workflow_status_category?.status_category_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
                        <div>
                            <CustomeTooltip
                                title={params?.row?.workflow_status_category?.status_category_name || "N/A"}
                                data={params?.row?.workflow_status_category?.status_category_name || "N/A"}
                            />
                        </div>
                );
            },
        },
        {
            field: "description",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
            width: 300,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {

                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
                        <div>
                            <CustomeTooltip
                                title={params?.row?.description || "N/A"}
                                data={params?.row?.description || "N/A"}
                            />
                        </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            sortable: false,
            pinnable: false,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="hiring-mode"
                                    rowid={params?.row?.id}
                                    addMoreData={{ jira_id: params?.row?.jira_id }}
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    // isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)}
                                />
                            </div>
                        </div>
                );
            },
        },
    ];

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>Status List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={jiraStatusList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort} />
                                <IconButton color="primary" aria-label="Sync Project" onClick={() => syncStatus()}>
                                    <Tooltip title="Sync">
                                        <SyncAltIcon />
                                    </Tooltip>
                                </IconButton>
                            </Stack>
                        </div>
                    </div>
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooterRowCount={true}
                    />
                </div>

                <Dialog
                    fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Status</DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput" style={{ flexBasis: "32%" }}>
                                    <label >  Status Name </label>
                                    <input
                                        name="status_name"
                                        defaultValue={formValues.status_name}
                                        type="text"
                                        placeholder="Status Name"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && !!!formValues.status_name  && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.status_nameErr}</p>}
                                </div>

                                <div className="formInput" style={{ flexBasis: "32%" }}>
                                    <label > Short Code </label>
                                    <input
                                        name="short_code"
                                        defaultValue={formValues.short_code}
                                        type="text"
                                        placeholder="Short Code"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && !!!formValues.short_code  && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.shortErr}</p>}
                                    {errStatus === true && formValues && formValues?.short_code?.length > 6 && <p style={{ color: "#96332c", fontSize: 13 }}>Please enter only max 5 to 6 limit short code</p>}

                                </div>

                                <div className="formInput" style={{
                                    flexBasis: "32%"
                                }}>
                                    < label >
                                        Status Category h
                                    </label>
                                    <Select
                                        styles={{ border: "2px solid black" }}
                                        className="select-a"
                                        classNamePrefix="select"
                                        defaultValue={formValues.workflow_status_category}
                                        isSearchable={true}
                                        name={"workflow_status_category"}
                                        onChange={(e) => handleChange(e)}
                                        options={
                                            master_status_category_list && master_status_category_list?.data?.rows?.map((option) => {
                                                return { label: option.status_category_name, value: option.id, name: "workflow_status_category" }
                                            })
                                        }
                                    />

                                    {errStatus === true && formValues && !!!formValues.workflow_status_category  && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.categoryErr}</p>}
                                </div>
                            </div>
                            <div className="add-new-user-form">
                                <div className="formInputDescription">
                                    <label >  Description </label>

                                    <textarea defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>

                                    {errStatus === true && formValues && !!!formValues.description  && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.descriptionErr}</p>}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "15px" }}>
                        <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>

                        <button className="close-modal" onClick={handleClose}>{CONSTANTS.COMMON_BUTTONS.CLOSE}</button>
                    </DialogActions>
                </Dialog>


                {/* for delete popup */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are You Sure To ${changeStatus} Jira Status`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                 <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   
                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                </Menu >
            </div >
        </div >
    )
}

const JiraStatus = DataGridProLazyLoadHOC(SubJiraStatus, { filterColumns: [], getDispatcherAsyncThunk: jiraStatusList, gridColumns: ['name', 'short_code', 'workflow_status_category_id', 'description', 'status'] });
//DefaultFilterColumns.HIRING_MODE_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())
export default JiraStatus
