import axios from "axios";

const { REACT_APP_API_BASEURL, REACT_APP_ENV } = process.env;



const rtms = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
export default axios.create({
    baseURL: REACT_APP_API_BASEURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": rtms ? rtms.token : '',
    }
});
