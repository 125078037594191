import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./resource.scss";
import { resourceViewById } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from "../../components/common-function/RefreshButton";

const ResourceView = () => {
    const dispatch = useDispatch()
    // const location = useLocation()
    const { resourceid } = useParams()

    const { view_data, status, error } = useSelector(state => state.resource)

    // useEffect(() => {
    //     if (location && location.state && location.state.resourceid) {
    //         const resourceid = location.state.resourceid
    //         dispatch(resourceViewById(resourceid))
    //     }
    // }, [dispatch, location])

    useEffect(() => {
        if (resourceid) {
            dispatch(resourceViewById(resourceid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    return (
        <div className="resource">
            <div className="resourceContainer">
                <div className="top-headings">
                    <h3>Resources View</h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <RefreshButton api={resourceViewById} filter={resourceid} style={{ marginTop: 5 }} />
                    </div>
                </div>
                {status === STATUSES.SUCCESS ?
                    <div className="view-details-row">
                        <div className="detailItem">
                            <span className="itemKey">Name :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.display_name ? view_data.data.display_name : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Date of Birth :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.date_of_birth ? view_data.data.date_of_birth : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Email :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.email ? view_data.data.email : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Contact Number :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.contact_no ? view_data.data.contact_no : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Gender :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.gender}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Address :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.address}</span>
                        </div>

                        <h5 className="title">Department Info</h5>
                        <div className="detailItem"></div>
                        <div className="detailItem"></div>

                        <div className="detailItem">
                            <span className="itemKey">Department Name:</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.department && view_data.data.department.name ? view_data.data.department.name : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Date of Joining  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.doj ? view_data.data.doj : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Employee Code :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.emp_code ? view_data.data.emp_code : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Jira Account  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.jira_account ? view_data.data.jira_account : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Manager  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.reporting_manager_name ? view_data.data.reporting_manager_name : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Is Interviewer  :</span>
                            <span className="itemKey2">{view_data && view_data.data && view_data.data.is_interviewer === true ? "Yes" : "No"}</span>
                        </div>

                        {
                            view_data && view_data.data && view_data && view_data.data.is_interviewer === true ?
                                <div className="detailItem" style={{ float: "left" }}>
                                    <span className="itemKey" style={{ float: "left" }}>Technology  :</span>
                                    <span className="itemKey2">
                                        {view_data.data && view_data.data.technology && view_data.data.technology.length > 0 ? view_data.data.technology.map((e, index) => `${index ? ', ' : ''}${(e.skill_name).trim()}`) : "N/A"}
                                    </span>
                                </div> : ""
                        }

                    </div>
                    :
                    status === STATUSES.FAILURE ?
                        <div>{error.message}</div>
                        :
                        <LoaderForm />
                }
            </div>
        </div >
    )

}

export default ResourceView