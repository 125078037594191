import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const countryList = createAsyncThunk("country/list", async () => {
    const res = await DataService.country_list();
    return res.data;
}
);

export const getCountryWiseSate = createAsyncThunk("country-wise-state/list", async (filter) => {
    const res = await DataService.get_country_wise_state_list(filter);
    return res.data;
}
);


const countrySlice = createSlice({
    name: "country_list",
    initialState: {
        data: [],
        status: 'idle',
        error: '',
        country_list: [],
        countryStateList: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(countryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(countryList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.data = action.payload;
                state.country_list = action.payload;
            })
            .addCase(countryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get state wise country list

            .addCase(getCountryWiseSate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCountryWiseSate.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.countryStateList = action.payload;
            })
            .addCase(getCountryWiseSate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});


export default countrySlice.reducer;