import "./master.scss";
import CONSTANTS from "../../components/constant/constantComponents";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import Select from 'react-select';
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IndustryDepartmentCreate,
    IndustryDepartmentEdit,
    IndustryDepartmentUpdate,
    getIndustryDepartmentList,
    getIndustryList,
    getAllIndustryList,
} from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../redux/features/departmentSlice"
import { STATUSES } from "../../redux/common-status/constant";
import { Close, Edit } from "@mui/icons-material";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DataGridPro, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";

const initialFormData = Object.freeze({
    industry: "",
    department: "",
    status: true,
});

const formErrData = Object.freeze({
    industryErr: "Industry is required",
    departmentErr: "department is required",
    statusErr: "Status is required",
});

let flag = 1;

const getDropdownEditData = (id, name, data, lebel_key) => {
    if (id) {
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x[lebel_key], name })
        })
        return newObject
    }
}
function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} {...props} />
        </Draggable>
    );
}

const SubIndustryDepartment = (props) => {

    const {onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu,  clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu} = props;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const [formErr] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const {
        status,
        industry_department_List,
        industry_department_Create,
        industry_department_Edit,
        industry_department_Update,
        industry_List,
        industryOptions,
    } = useSelector((state) => state.master);
    const { departmemtOptionList } = useSelector(state => state.department)
    const [ID, setID] = useState("");
    // const [reload, setReload] = useState(false);
    // const [filterQuery, setFilterQuery] = useState(
    //     `{"filter": {"status": {"value": "True", "operation": "eq" } },"order_by":{"column":"-id"},"skip":0, "limit":25}`
    // );
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });

    const getUnique = (arr, index) => {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) {
            setID(0);
            setFormValues(initialFormData);
        }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false);
        setContextMenu(null);
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    };


    const handleChangeOnDepartment = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "department": evt });
        } else {
            setFormValues({ ...formValues, "department": "" });
        }
    }

    const submitData = (e) => {
        e.preventDefault();

        let departmentArray = []
        if (formValues.department && formValues.department.length > 0) {
            formValues.department.map(e => departmentArray.push(Number(e.value)))
        }


        if (
            formValues.industry === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }

        if (
            formValues.industry !== "" &&
            formValues.status !== ""
        ) {
            
            let addvalues;


            if (typeof formValues.industry === "object") {
                addvalues = { ...formValues, industry: formValues.industry.value, department: departmentArray };
            } else {
                addvalues = { ...formValues, department: departmentArray };
            }

            clearGridListing.current = true;

            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(IndustryDepartmentUpdate({ addvalues }));
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(IndustryDepartmentCreate(addvalues));
            }

            setErrStatus(false);
            setReload(true);
            handleClose();
        }
    };

    const handleClickEdit = (data) => {
        let industry_id = data?.id;
        flag=1;
        dispatch(IndustryDepartmentEdit(industry_id));
    };

const filterOperators = getGridSingleSelectOperators().filter(
    (operator) => operator.value === 'is' ,
  );

    const actionColumn = [
        {
            field: "industry_id",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Industry</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("industry_id")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 400,
            type: "singleSelect", 
            valueOptions: industryOptions,
            sortable:false,
            pinnable:false,
            filterOperators,
            renderCell: (params) => (
                params.row.industry_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
                <div>
                    <CustomeTooltip
                        title={params?.row?.industry?.industry_name ? params.row.industry.industry_name : "N/A"}
                        data={params?.row?.industry?.industry_name ? params.row.industry.industry_name : "N/A"}
                    />
                </div>
            ),

        },
        {
            field: "department_id",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Departments</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("department_id")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 800,
            type: "singleSelect",
            valueOptions: departmemtOptionList,
            sortable:false,
            pinnable:false,
            filterOperators,
            renderCell: (params) => {
                return params.row.department_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={800}/> :
                <div>
                    <CustomeTooltip
                        title={params.row.department && params.row.department.length > 0 ? getUnique(params.row.department, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                        data={params.row.department && params.row.department.length > 0 ? getUnique(params.row.department, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                    />
                </div>;
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.action === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={120}/> :
                    <div className="actionButton">
                        <UcActionComponent
                            moduleName="industry_department"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            editButton={() => handleClickEdit(params.row.industry)}
                            viewButton={false}
                        />
                    </div>
                );
            },
        },
    ];

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'edit':    
                handleClickEdit(row.industry)
                break;
                
            default:
                return;
        }

        setContextMenu(null);

    }

    if (flag === 1 && status === `${STATUSES.SUCCESS}` && industry_department_Edit && industry_department_Edit.data) {
        if (industry_department_Edit.status === 200) 
        {
            let editData =industry_department_Edit?.data?.rows.length > 0 && industry_department_Edit.data.rows[0];
            let departmentArray = []
            if (editData?.department) {
                editData.department.length > 0 && editData.department.map(e => {
                    return departmentArray.push({
                        label: e.name,
                        value: e.id
                    })
                })
            }
            setFormValues({
                industry: getDropdownEditData(editData.industry.id ?? null, "industry", industry_List.data.rows ?? [], "industry_name"),
                department: editData?.department?.map((department) => getDropdownEditData(department.id ?? null, "department", departmemtOptionList, "label")),
                status: editData.status,
                id: editData?.id,
            });
            setID(editData.id);
            setOpen(true);
            setReload(false);
        }
        else
        {
            toast.error(industry_department_Edit.message)
        }
        flag = 0;
    }

    if (reload === true && status === STATUSES.SUCCESS && industry_department_List && industry_department_List.message) {
        if (industry_department_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...industry_department_List.data.rows].map((item, ind)=>({...item, id:ind+1})));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(industry_department_List.data.row_count);
            }
            else{
                setGridRows([...industry_department_List.data.rows].map((item, ind)=>({...item, id:ind+1})));
                SET_MAX_ROW_LENGTH(industry_department_List.data.row_count);
                setSkeletonRows([])
            }
        } else {
            toast.error(industry_department_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_department_Update` && industry_department_Update && industry_department_Update.message) {
        if (industry_department_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(industry_department_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(industry_department_Update.message)
        }
        setReload(false);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_department_Create` && industry_department_Create && industry_department_Create.message) {
        if (industry_department_Create.status === 200) {
            toast.success(industry_department_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit: JSON.parse(filterQuery).skip ===0 ? 25 : JSON.parse(filterQuery).skip }));
            setFilterQuery(str)
        } else {
            toast.error(industry_department_Create.message);
        }
        setReload(false);
    }

    let i = 0;

    useEffect(() => {
        dispatch(getIndustryList(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
        dispatch(getDepartmentList())
        dispatch(getAllIndustryList())
    }, []);
    
    return (
        
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Industry - Department List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getIndustryDepartmentList} filter={filterQuery} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                ".actionButton": {
                                    display: 'block'
                                }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}
                    >
                        <Close />
                    </Button>
                    <DialogTitle
                        style={{
                            background: "#2196f3",
                            color: "#fff",
                            marginBottom: "20px",
                            cursor: 'move'
                        }}
                        id="draggable-dialog-title"
                    >
                    Industry - Department
                    </DialogTitle>

                    <DialogContent style={{ height: "400px" }}>
                        <form>

                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChange}
                                        defaultValue={formValues.industry}
                                        // value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                           industry_List?.data?.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>

                                {
                                    formValues.industry ?
                                        <div className="formInput">
                                            <label >
                                                Department
                                            </label>
                                            <Select
                                                isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Department"
                                                defaultValue={formValues.department}
                                                isSearchable={true}
                                                name={"department"}
                                                options={
                                                  departmemtOptionList?.map((option) => {
                                                        return { label: option.label, value: option.value, name: "department" }
                                                    })
                                                }
                                                onChange={handleChangeOnDepartment}
                                            />
                                            {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }
                            </div>
                            <button  className="btn btn-submit" onClick={submitData} style={{float:'right',backgroundColor:"rgba(33, 150, 243, 1)"}}>
                                {ID
                                    ? CONSTANTS.COMMON_BUTTONS.UPDATE
                                    : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>

                
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                </Menu>                   

            </div>
        </div>
    );
};

const  IndustryDepartment = DataGridProLazyLoadHOC(SubIndustryDepartment, {defaultFilter:{filter: {status: {value: "True", operation: "eq" } },order_by:{column:"-id"},skip:0, limit:25}, getDispatcherAsyncThunk:getIndustryDepartmentList, gridColumns:['industry_id', 'department_id', 'action'], maxfilterableColumns:2});

export default IndustryDepartment;
