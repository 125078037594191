import "./sprint.scss"
import { toast, ToastContainer } from "react-toastify";
import { FormControl, IconButton, Skeleton, Stack, TablePagination, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSprintList, getSprintListByJira } from "../../redux/features/sprintSlice";
import { project_List } from "../../redux/features/taskSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import Select from 'react-select';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const getUnique = (arr, index) => {

    const unique = arr
        .map(e => e[index])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}

const SubSprintList = (props) => {

    let { onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu } = props;

    const dispatch = useDispatch();
    // const navigate = useNavigate()
    const [projectId, setProjectId] = useState(0)
    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    // const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"}, "coulmns":[${DefaultFilterColumns.SPRINT_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [loader, setLoader] = useState(false)
    const { sprintList, error, status, syncSprintList } = useSelector(state => state.sprint)
    const { projectList } = useSelector(state => state.task)
    const [projectWiseList, setProjectWiseList] = useState(false)
    // const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    useEffect(() => {
        // dispatch(getSprintList(filterQuery))
        dispatch(project_List())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //error handling
    // useEffect(() => {
    //     if (status === STATUSES.FAILURE && error) {
    //         toast.error(error.message)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [error])

 

    const handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setProjectId({ [name]: value });
        } else {
            setProjectId(0)
        }
    }
    const syncSprintByProject = () => {
        setLoader(true)
        dispatch(getSprintListByJira(projectId['project']))
    }

    if (loader === true && status === STATUSES.SUCCESS && syncSprintList && syncSprintList.message) {
        setLoader(false)
        if (syncSprintList.message === 'Success') {
            clearGridListing.current = true;
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:25}));
            setFilterQuery(str);
            toast.success(syncSprintList.message)
            // toast("Sync Data Successfully !!!");
            // dispatch(getSprintList(filterQuery))
            
        } else {
            toast(syncSprintList.message)
        }
    }

    const sprintColumn = [
        { 
            field: "id",
            headerName: "ID", 
            width: 70, 
            filterable: false,
            sortable:false,
            pinnable:false
        },
        { 
            field: "sprint_name", 
            headerName:  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Sprint Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("sprint_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>, 
            width: 230,
            sortable:false,
            pinnable:false,
            renderCell: params => (
                params.row.sprint_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={230}/> :
                params.row.sprint_name
            )
        },
        { 
            field: "description", 
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Description</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("description")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>, 
            width: 230, 
            sortable:false,
            pinnable:false,
            renderCell: params => (
                params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={230}/> :
                params.row.description
            )
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> :
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "is_jira_status",
            headerName: "Is Jira Status",
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.is_jira_status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> :
                    <div className={`cellWithStatus ${params.row.is_jira_status}`}>
                        {params.row.is_jira_status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
    ]

    if (projectWiseList === false && projectList && projectList.status) {
        let array = []
        if (projectList.status === 200) {
            projectList?.data && projectList.data.rows.map((option) => {
                return array.push({ label: option.project.project_name, value: option.project.id })
            })
        }
        array.length > 0 ? setProjectWiseList(getUnique(array, 'value')) : setProjectWiseList(array)
    }

    if (reload === true && status === STATUSES.SUCCESS && sprintList.message) {
        if (sprintList.status === 200) {
            if(clearGridListing.current){
                setGridRows([...sprintList.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(sprintList.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...sprintList.data.rows]);
                SET_MAX_ROW_LENGTH(sprintList.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(sprintList.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    return (
        <>
            <div className="datatable">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                    <div style={{ fontSize: "20px" }}>
                        <p>Sprint List</p>
                    </div>
                    <div>
                        <Stack direction="row">
                            <FormControl sx={{ m: 1, minWidth: 150 }}>

                                <Select
                                    className="task-dropdown"
                                    id="demo-customized-select-native"
                                    classNamePrefix="select"
                                    placeholder="Select Project"
                                    defaultValue={projectId}
                                    isSearchable={true}
                                    isClearable={true}
                                    name={"project"}
                                    options={
                                        projectWiseList === false ? [] : projectWiseList.map((option) => {
                                            return { label: option.label, value: option.value, name: "project" }
                                        })
                                    }
                                    onChange={handleChange}
                                />
                            </FormControl>
                            {projectId && (projectId['project'] === '0' || projectId === 0) ?

                                <IconButton disabled color="primary" aria-label="Reload Refresh">
                                    <Tooltip title="Sprint">
                                        <SyncAltIcon />
                                    </Tooltip>
                                </IconButton>
                                :
                                <IconButton onClick={() => syncSprintByProject()} color="primary" aria-label="Reload Refresh">
                                    <Tooltip title="Sprint">
                                        <SyncAltIcon />
                                    </Tooltip>
                                </IconButton>
                            }
                            <RefreshButton api={getSprintList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </Stack>
                    </div>
                </div>
                <ToastContainer />
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                ".actionButton": {
                                    display: 'block'
                                }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={sprintColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            // onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
            </div>

        </>
    )
}

const SprintList = DataGridProLazyLoadHOC(SubSprintList, { filterColumns: DefaultFilterColumns.SPRINT_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()), getDispatcherAsyncThunk: getSprintList, gridColumns: ['sprint_name', 'description', 'status', 'is_jira_status'] });

export default SprintList