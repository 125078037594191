import './manage-credential-heads/manageCredential.scss'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import dataService from '../../redux/services/data.service';
import { useNavigate } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import { Button, Stack, Dialog, Tooltip, Menu, MenuItem, Skeleton, Switch, DialogTitle, DialogContent } from "@mui/material";
import LoaderForm from '../../components/common-function/LoaderForm';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { allResources, allcredentialHeads, getAllCredentials } from '../../redux/features/cedentialHeadSlice';
import Filter from '../../components/Filter';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from "../../redux/common-status/constant";
import { Add, Close, Edit } from '@mui/icons-material';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DataGridProLazyLoadHOC from '../../components/common-function/DataGridProLazyLoadHOC';
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import AddResourceCredential from './AddResourceCredential';


// const initialFormData = Object.freeze({
//   credentails_head_id: "",
//   resource_id: "",
//   is_global:"",
//   status: true,
// });

let flag = 1;


const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


const SubAllResourceCredential = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { allCredentialsList, resourceList, credentialHeadList, allCredentials_list, thirdPartyCredentials_update, thirdPartyCredentials_create, status, error } = useSelector(state => state.credentialHead)
  const { data: list } = allCredentialsList;

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [errStatus, setErrStatus] = useState(false);


  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25)
  const [limit, setLimit] = useState(25)

  const [filterResourceList, setFilterResourceList] = useState([]);
  const [filterHeadList, setFilterHeadList] = useState([]);

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showEdit, setShowedit] = useState({ active: false, id: null });
  const [ID, setID] = useState("");
  const [checkStatus, setCheckstaus] = useState(false);
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');

  let editId = useRef(0);

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setShowLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    // dispatch(getAllCredentials(filterQuery));
    dispatch(allResources())
    dispatch(allcredentialHeads())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (status === `${STATUSES.SUCCESS}_resourceList` && filterResourceList && filterResourceList.length === 0 && resourceList && resourceList.data) {
    setShowLoader(false)
    let array = []
    resourceList.data && resourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
    array.length > 0 && setFilterResourceList(array)
    array.length > 0 && setOptions({ ...options, resource_id: array })
  }

  // if (status === `${STATUSES.SUCCESS}_credentialHeadList` && filterHeadList && filterHeadList.length === 0 && credentialHeadList && credentialHeadList.data) {
  //   setShowLoader(false)
  //   let array = []
  //   credentialHeadList.data && credentialHeadList.data.rows.map(e => { return array.push({ value: e.id, label: e.head_name }) })
  //   array.length > 0 && setFilterHeadList(array)
  //   array.length > 0 && setOptions({ ...options, credentails_head_id: array })
  // }
  if (reload === true && status === STATUSES.SUCCESS && allCredentials_list.data) {
    if (allCredentials_list.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...allCredentials_list.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(allCredentials_list.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...allCredentials_list.data.rows]);
        SET_MAX_ROW_LENGTH(allCredentials_list.data.count);
        setSkeletonRows([])
      
      }
    } else {
      toast.error(allCredentials_list.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
    clearGridListing.current = true;
  }

  if (reload === true && status === `${STATUSES.SUCCESS}_thirdPartyCredentials_update` && thirdPartyCredentials_update && thirdPartyCredentials_update.message) {
    if (thirdPartyCredentials_update.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      toast.success(thirdPartyCredentials_update.message)
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(thirdPartyCredentials_update.message)
    }
    setReload(false)
  }

  if (reload === true && status === `${STATUSES.SUCCESS}_thirdPartyCredentials_create` && thirdPartyCredentials_create && thirdPartyCredentials_create.message) {
    if (thirdPartyCredentials_create.status === 200) {
      toast.success(thirdPartyCredentials_create.message)
      setGridRows([]);
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
      setFilterQuery(str)
    } else {
      toast.error(thirdPartyCredentials_create.message)
    }
    setReload(false)
  }

  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)
    if (open === true) {
      setReload(true)
      const response = await dataService.deleteResourceCredential(id, request)
      if (response && response.data) {
        toast.success(response.data.message);
        setOpen(false)
        if (response && response.data && response.data.status === 200) {
          setCheckstaus(true)
        }

        dispatch(getAllCredentials(filterQuery));
      }
    }
  }
  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item?.id === selectedRow);

    switch (actionType) {
      case 'status':
        handleArchive(row);
        break;

      case 'edit':
        onEdit(row?.id)
        break;

    }

    setContextMenu(null);

  }

  const handleClickOpen = (type, id) => {
    if (type !== true)
      editId.current = type;

    setDeleteId(id)
    if (type === true) {
      handleDelete(editId.current);

    }
    setOpen(true);
  }



  const handleClose = () => {
    setOpen(false);
    setShowProjectForm(false)
    setShowedit({ active: false, id: null })
    setContextMenu(null);
    setArchiveOpen(false)
  }
  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClickArchive = async (data) => {
    
    setArchiveOpen(false)

    let getResponse = await dataService.resourceCredentialById(data.id);
    let addvalues = {
      credentails_head: data.credentails_head_id,
      resource: data.resource_id,
      is_global: data.is_global,
      status: !data.status,
      value:getResponse.data.data.value,
      updated_by: GetLocalstorage.userDetail().id
    }

    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    setReload(true)
    // dispatch(HiringModeUpdate({ ID, addvalues }))
    let res = await dataService.updateResourceCredential(ID, addvalues)

    setTimeout(()=>
    {
      let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
      setFilterQuery(str)
      toast.success(res.data.message)

    },200)
  }
  const onEdit = async (id) => {
    // navigate(`/edit-resource-credential/${id}`, { state: "resource" })
    setShowedit({ active: true, id });

  }

  // const onAddBtn = () => {
  //   navigate('/add-resource-credential', { state: "resource" })
  // }
  const handleOpen = () => {
    setShowProjectForm(true);
    setShowedit(true);
    // navigate('/add-resource-credential', { state: "resource" })
  }

  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "credentails_head_id",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Credentail Head</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("credentails_head_id")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 400,
      type: 'singleSelect',
      valueOptions: credentialHeadList?.data?.rows?.map(item => ({ label: item.head_name, value: item.id })),
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.credentails_head === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              {/* {params.row.credentails_head} */}
              {params.row.credentails_head__head_name ? params.row.credentails_head__head_name : "-"}

            </div>
        );
      },
    },
    {
      field: "resource_id",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Resource Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("resource_id")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 400,
      sortable: false,
      pinnable: false,
      // headerName: "Resource Name",
      // width: 280,
      type: 'singleSelect',
      valueOptions: resourceList?.data?.rows?.map(item => ({ label: item.display_name, value: item.id })),
      renderCell: (params) => {
        return (
          params.row.resource_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              {params.row.resource__name ? params.row.resource__name : "-"}
            </div>
        );
      },
    },
    {
      field: "is_global",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Is Global</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("is_global")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 400,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.is_global === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div className={`cellWithStatus ${params.row.is_global}`}>
              {params.row.is_global === true ? 'true' : 'false'}
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 160,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          // <div className={`cellWithStatus ${params.row.status}`}>
          //   {params.row.status === true ? 'Active' : 'Inactive'}
          // </div>
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
              <div className="actionButton">
                <UcActionComponent
                  deleteIconName="ArchiveIcon"
                  moduleName="resource-credential"
                  rowid={params?.row?.id}
                  addMoreData=""
                  editLinkUrl=""
                  viewLinkUrl={`/view-credential/${params.row.id}`}
                  isDelete={true}
                  editButton={onEdit}
                  viewButton={false}
                  deleteButton={() => handleClickOpen(params.row)}
                />
              </div>
            </div>
        );
      },
    },
  ];


  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={onFilterChange} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status" || col.field === "is_global") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [actionColumn]
  );

  return (
    <div className="new">
      <div className="newContainer">
        <div className="datatable">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>All Credential</p>
            </div>
            <div>
              <Stack direction="row">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                <RefreshButton api={getAllCredentials} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
              </Stack>
            </div>
          </div>


          <DataGridPro
            className="datagrid"
            filterMode="server"
            loading={!!reload}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            rows={gridRows?.concat(skeletonRows)}
            // rows={gridRows||}
            columns={actionColumn}
            filterModel={filterModal}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooter
          />
        </div>


        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={showProjectForm || showEdit.active}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <Button
            edge="start"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{showEdit.active ? 'Edit' : 'Add'} Resource Credential </DialogTitle>

          <DialogContent>
            <AddResourceCredential showProjectForm={showProjectForm} ID={showEdit?.id} setShowProjectForm={setShowProjectForm} setShowedit={setShowedit}/>
          </DialogContent>
        </Dialog>


        {/* for delete popup */}
        <DeleteDialogModal
          open={archiveOpen}
          onClose={handleClose}
          // heading={'Delete ?'}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} resource credential`}
          handleArchive={handleClickArchive}
          id={archiveId}
          
          // moduleName={'AllResourceCredential'}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >

          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

        </Menu>
      </div>

    </div >
  )
}
const AllResourceCredential = DataGridProLazyLoadHOC(SubAllResourceCredential,
  { filterColumns: DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS.map(item => item.replaceAll('"', "").trim()), defaultFilter: { skip: 0, limit: 25, order_by: { column: "-status" } }, getDispatcherAsyncThunk: getAllCredentials, gridColumns: ['credentails_head_id', 'resource_id', 'is_global', 'status'] });


export default AllResourceCredential;