import "./report.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { resourceList } from "../../redux/features/resourceSlice";
import {
  attandanceReport,
  csvAttandanceReport,
  defaultAttendenceReportColumn,
} from "../../redux/features/calendarSlice";
import {
  FormControl,
  TextField,
  Button,
  Tooltip,
  List,
  ListItem,
  Stack,
  Skeleton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { ArrowBack, SystemUpdateAltTwoTone } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import MoreFilters from "../../components/moreFilters/MoreFIlters";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LinearProgress from '@mui/material/LinearProgress';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';


const { REACT_APP_API_BASEURL } = process.env;
const default_attemdemce_reports_Columns =
  GetLocalstorage.get_default_attendence_report_column();
const skeletonBase = {
  punchin_date: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  emp_code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  effective_working_hours: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  expected_punch_in_date_time: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  expected_punch_out_date_time: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  expected_working_hours: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  working_hours: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  break_hours: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  punch_out_time: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'
};



const ReportOfAttendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.resource);
  const { status, error, attendaceReportsList, defaultAttendenceReportColumns,attendaceReportsCsv } = useSelector((state) => state.calender);
  const [resourceId, setResourceId] = useState("select resources");
  const [resourceLabel, setResourceLabel] = useState("");
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState();
  const [startMaxDate, setStartMaxDate] = useState([null]);
  const [fromDate, setFromDate] = useState([null]);
  const [toDate, setToDate] = useState([null]);
  const [minToDate, setMinToDate] = useState([null]);
  const [downliadFile, setDownloadFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(

    default_attemdemce_reports_Columns?.all_attendencereport_column
      ? default_attemdemce_reports_Columns.all_attendencereport_column
      : {
        resource_id: false,
        working_hours: false,
      }
  );

  const [gridRows, setGridRows] = useState([]);
  let clearGridListing = useRef(false);
  let stopOnRowScrollEnd = useRef(false);
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [sort, setSort] = useState(false)
  const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [summary, setSummary] = useState({})
  const [rowCount, setRowCount] = useState(0)


  const setKeyForList = (obj) => {
    let data;
    if (default_attemdemce_reports_Columns) {
      data = {
        all_attendencereport_column: obj,
        reported_attendence_report_column:
          default_attemdemce_reports_Columns.reported_attendence_report_column,
        all_projectlist_column:
          default_attemdemce_reports_Columns.all_projectlist_column,
        task_column: default_attemdemce_reports_Columns.task_column,
      };
    } else {
      data = { all_attendencereport_column: obj };
    }
    if (defaultAttendenceReportColumns.reported_attendence_report_column) {
      data["reported_attendence_report_column"] =
        defaultAttendenceReportColumns.reported_attendence_report_column;
    }
    if (defaultAttendenceReportColumns.all_projectlist_column) {
      data["all_projectlist_column"] =
        defaultAttendenceReportColumns.all_projectlist_column;
    }
    if (defaultAttendenceReportColumns.task_column) {
      data["task_column"] = defaultAttendenceReportColumns.task_column;
    }
    dispatch(defaultAttendenceReportColumn(data));
  };

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message);
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      resourceList(
        `{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`
      )
    );
  }, []);

  const handleChange = (evt) => {
    if (evt) {
      const { name, value, label } = evt.target || evt;
      if (name === "month") {
        setLoader(true);
        const d = new Date();
        let year = d.getFullYear();
        daysInMonth(value + 1, year);
      }
      if (name === "resourceId") {
        setResourceLabel(label)
        setResourceId(value);
      }
    } else {
      setResourceId("select resources");
      setResourceLabel("");
    }
  };
  if (loader === true && status === `${STATUSES.SUCCESS}_attendaceReportsList` && attendaceReportsList?.data) {
    setLoader(false);
    let reportRows = attendaceReportsList.data.rows;
    let reportCount = attendaceReportsList.data.count;
    let reportSummary = attendaceReportsList.data.summary;
    setSummary(reportSummary)
    let reportList = [];
    // let summaryExtra1 = {
    //   id: -1,
    // };
    // let summaryExtra2 = {
    //   id: -2,
    // };
    // let summaryExtra3 = {
    //   id: -5,
    //   punch_out_time: "No Data Found!",
    // };
    // let summaryHeader = {
    //   id: -6,
    //   punchin_date: "No Of Days",
    //   name: "Total Break Hours",
    //   break_hours: "Total Effective Working Hours",
    //   effective_working_hours: "Total Working Hours",
    //   working_hours: "Total Expected Working Hours",
    // };

    // let summaryBody = {
    //   id: -4,
    //   punchin_date: reportSummary.no_of_days,
    //   name: reportSummary.total_break_hours,
    //   break_hours: reportSummary.total_effective_working_hours,
    //   effective_working_hours: reportSummary.total_working_hours,
    //   working_hours: reportSummary.total_expected_working_hours,
    // };
    if (reportRows && reportRows.length > 0) {
      reportRows.map((e, i) => {
        return reportList.push({
          id: i,
          break_hours: e.break_hours,
          created_on: e.created_on,
          date: e.date,
          effective_working_hours: e.effective_working_hours,
          emp_code: e.emp_code,
          expected_punch_in_date_time: e.expected_punch_in_date_time,
          expected_punch_out_date_time: e.expected_punch_out_date_time,
          expected_working_hours: e.expected_working_hours,
          name: e.name,
          punch_out_date: e.punch_out_date,
          punch_out_time: e.punch_out_time,
          punchin_date: e.punchin_date,
          punchin_time: e.punchin_time,
          resource_id: e.resource_id,
          working_hours: e.working_hours,
        });
      });
      setSkeletonRows([])
      setGridRows(reportList)
    } else {
      setGridRows([])
      setSkeletonRows([])

    }

    // if (reportList && reportList.length > 0) {
    //   reportList.push(summaryExtra1);
    //   reportList.push(summaryExtra2);
    //   reportList.push(summaryHeader);
    //   reportList.push(summaryBody);
    //   setGridRows(reportList);
    // } else {
    //   reportList.push(summaryExtra1);
    //   reportList.push(summaryExtra3);
    //   reportList.push(summaryExtra2);
    //   reportList.push(summaryHeader);
    //   reportList.push(summaryBody);
    //   setGridRows(reportList);
    // }
    if (reportCount > 0) {
      setRowCount(reportCount);
    }
  }

  const setYearAndMonth = (newValue) => {
    const d = new Date(newValue);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    daysInMonth(month, year);
    setValue(newValue);
  };

  const daysInMonth = (month, year) => {
    let monthDays = new Date(year, month, 0).getDate();
    setMinToDate(`${year}-${month}-1`);
    setStartMaxDate(`${year}-${month}-${monthDays}`);
    setFromDate(`${year}-${month}-1`);
    setToDate(`${year}-${month}-${monthDays}`);
    return new Date(year, month, 0).getDate();
  };

  const setFilterData = () => {
    daysInMonth(moment().format("MM"), moment().format("YYYY"));
    let monthDays = new Date(
      moment().format("YYYY"),
      moment().format("MM"),
      0
    ).getDate();
    let startMonth = `${moment().format("YYYY")}-${moment().format("MM")}-1`;
    let endMonth = `${moment().format("YYYY")}-${moment().format(
      "MM"
    )}-${monthDays}`;

    let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    return filter
  }


  const setToDateFunction = (newValue) => {
    setToDate(newValue);
  };

  const downloadCsvFile = () => {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(
        fromDate
      ).format("YYYY-MM-DD")}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format(
        "YYYY-MM-DD"
      )}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`;
    }
    setDownloadFile(true);
    dispatch(csvAttandanceReport(filter));
  };
  const handleClick = (url) => {
    setDownloadFile(false);
    window.open(url);
    dispatch(csvAttandanceReport('type'));
  };

  if (downliadFile === true) {
    let api_url =
    attendaceReportsCsv?.data?.download_api_url;
    if (REACT_APP_API_BASEURL && api_url) {
      let url = REACT_APP_API_BASEURL + api_url;
      if (url) {
        handleClick(url.replace("api//", "api/"));
      }
    }
  }

  // advance search filter fields
  const FilterComponents = () => {
    return (
      <div>
        <List>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month", "year"]}
                label='Year and Month'
                minDate={dayjs("2012-03-01")}

                value={value}
                onChange={(newValue) => {
                  setYearAndMonth(newValue);
                }}
                format='YYYY-MM'
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField disabled={true} {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <FormControl style={{ width: "96%" }}>
              <Autocomplete
                id='controllable-states-demo'
                value={resourceLabel ? resourceLabel : ""}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={
                  data &&
                    data?.data ?
                    data?.data?.rows.map((e, key) => {
                      return {
                        key: e.id,
                        name: "resourceId",
                        value: e.id,
                        label: e.name + "(" + e.email + ")",
                      };
                    })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label='Select resources' />
                )}

              />
            </FormControl>
          </ListItem>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='From  Date'
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue["$d"]);
                }}
                format='DD-MM-YYYY'
                maxDate={new Date()}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                minDate={fromDate}
                label='To Date'
                value={toDate}
                onChange={(newValue) => {
                  setToDateFunction(newValue);
                }}
                maxDate={new Date()}
                format='DD-MM-YYYY'
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ListItem>
        </List>
      </div>
    );
  }

  const handleFilterSearch = (setOpen) => {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${fromDate.toString().includes("GMT") ? moment(fromDate).format("YYYY-MM-DD") : fromDate}","${toDate.toString().includes("GMT") ? moment(toDate).format("YYYY-MM-DD") : toDate}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${fromDate.toString().includes("GMT") ? moment(fromDate).format("YYYY-MM-DD") : fromDate}","${toDate.toString().includes("GMT") ? moment(toDate).format("YYYY-MM-DD") : toDate}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    }
    setGridRows([])
    setLoader(true);
    setFilterQuery(filter);
    //dispatch(attandanceReport(filter));
    setOpen(false)

  }

  // advance search - resetHandler
  const handleFilterReset = () => {
    setResourceId("select resources")
    setResourceLabel("");
    setValue();
    setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    setToDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
    setLoader(true);
    let filter = `{"filter":{"punchin_date":{"value":["2023-10-1","2023-10-31"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    setFilterQuery(filter);
    dispatch(attandanceReport(filter));

  }

  const handleSort = (sortingCol) => {
    setSkeletonRows(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    setSort(true)
    setGridRows([]);

  }

  useEffect(() => {
    if (filterQuery !== "") {
      dispatch(attandanceReport(filterQuery))
      setLoader(true);
    } else {
      setFilterQuery(setFilterData())
    }
    // eslint-disable-next-line
  }, [filterQuery]);


  const loadServerRows = () => {
    setLoader(true)
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = 0;
    updatedFilter.limit += 25
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < rowCount) {
      loadServerRows();
    }
  };

  const CustomFooter = () => {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", textAlign:'center' }}>No Of Days</TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign:'center' }}>Total Break Hours</TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign:'center' }}>Total Effective Working Hours</TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign:'center' }} >Total Working Hours</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className="table-body-row-cell" align="center">{summary?.no_of_days}</TableCell>
              <TableCell className="table-body-row-cell" align="center">{summary?.total_break_hours}</TableCell>
              <TableCell className="table-body-row-cell" align="center">{summary?.total_effective_working_hours}</TableCell>
              <TableCell className="table-body-row-cell" align="center">{summary?.total_working_hours}</TableCell>
            </TableRow>
          </TableBody>

        </Table>

      </div>
    )
  }

  const AttendanceReportColumns = [
    {
      field: "punchin_date",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Date</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !loader && handleSort("punchin_date")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
      type: 'date',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.punchin_date === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={params.row.punchin_date === 'No Of Days' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
              {params.row.punchin_date}
            </div>
        );
      },
    },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !loader && handleSort("resource")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
      width: 210,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={params.row.name === 'Total Break Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
              {params.row.name}
            </div>
        );
      }
    },
    {
      field: "break_hours",
      headerName: 'Break Hours',
      sortable: false,
      filterable: false,
      width: 180,
      renderCell: (params) => {
        return (
          params.row.break_hours === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={params.row.break_hours === 'Total Effective Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
              {params.row.break_hours}
            </div>
        );
      }
    },
    {
      field: "effective_working_hours",
      headerName: 'Actual Working Hours',
      sortable: false,
      filterable: false,
      width: 240,
      renderCell: (params) => {
        return (
          params.row.effective_working_hours === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={params.row.effective_working_hours === 'Total Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
              {params.row.effective_working_hours}
            </div>
        );
      }
    },
    {
      field: "working_hours",
      headerName: 'Total Working Hours',
      sortable: false,
      filterable: false,
      width: 180,
      renderCell: (params) => {
        return (
          params.row.working_hours === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={params.row.working_hours === 'Total Expected Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
              {params.row.working_hours}
            </div>
        );
      }
    },
    {
      field: "expected_working_hours",
      headerName: 'Expected Working Hours',
      sortable: false,
      filterable: false,
      width: 240,
      renderCell: (params) => {
        return (
          params.row.expected_working_hours === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.expected_working_hours}
            </div>
        )
      }
    },
    {
      field: "emp_code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Emp Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !loader && handleSort("resource__emp_code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.emp_code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.emp_code}
            </div>
        )
      }
    },
    {
      field: "punchin_time",
      headerName: "Punch In Time",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.punchin_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.punchin_time}
            </div>
        )
      }
    },
    {
      field: "punch_out_time",
      headerName: "Punch Out Time",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.punch_out_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.punch_out_time}
            </div>
        )
      }
    },
    {
      field: "expected_punch_in_date_time",
      headerName: "Expected Punch-In Date-Time",
      sortable: false,
      filterable: false,
      width: 280,
      renderCell: (params) => {
        return (
          params.row.expected_punch_in_date_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.expected_punch_in_date_time}
            </div>
        )
      }
    },
    {
      field: "expected_punch_out_date_time",
      headerName: "Expected Punch-Out Date-Time",
      sortable: false,
      filterable: false,
      width: 280,
      renderCell: (params) => {
        return (
          params.row.expected_punch_out_date_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div className={`cellWithStatus`}>
              {params.row.expected_punch_out_date_time}
            </div>
        )
      }
    },
  ]

  return (
    <div className='report'>
      <div className='reportContainer'>
        <div className='datatable'>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px", marginTop: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>Daily Attendance Report</p>
            </div>
            <div>
              <Stack direction="row">
                <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={status === `${STATUSES.SUCCESS}_attendaceReportsList` ? false : true} inlineStyle={{ width: '60px' }} />
                {/* <Button variant="outlined" startIcon={<FilterAltTwoTone />} >filter</Button> */}
                <Button style={{ height: 37, marginLeft: 10 }} variant="outlined" startIcon={<SystemUpdateAltTwoTone />} onClick={() => downloadCsvFile()}> CSV </Button>
                <RefreshButton api={attandanceReport} filter={filterQuery} style={{ marginTop: 0 }} setReload={setLoader} clearGridListing={clearGridListing} setSort={setSort} />
                <Tooltip title={"Back"}>
                  <Button variant="outlined" style={{ height: 37 }} onClick={() => navigate(-1)}> <ArrowBack /> </Button>

                </Tooltip>
              </Stack>
            </div>
          </div>
          <ToastContainer />
          <DataGridPro
            className='datagrid'
            loading={!!loader || status === STATUSES.LOADING}
            rows={gridRows?.concat(skeletonRows) || []}
            columnVisibilityModel={
              defaultAttendenceReportColumns?.all_attendencereport_column
                ? defaultAttendenceReportColumns.all_attendencereport_column
                : columnVisibilityModel
            }
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibilityModel(newModel);
              setKeyForList(newModel);
            }}
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            columns={AttendanceReportColumns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
              Footer: CustomFooter,

            }}
            onRowsScrollEnd={handleOnRowScrollEnd}
            rowThreshold={.7}
            //onFilterModelChange={onFilterChange}
            //pageSize={pageSize === 100 ? pageSize : pageSize + 4}
            hideFooterPagination={true}
          />

        </div>
      </div>
    </div>
  );
};

export default ReportOfAttendance;