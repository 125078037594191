import "./DynamicTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UcActionComponent from "../common-function/UcActionComponent";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "../TablePaginationActions"
import DeleteDialogModal from "../common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../CustomEmptyOverlay";
import GetLocalstorage from "../common-function/GetLocalstorage";
// import { DataGridPro } from "@mui/x-data-grid-pro";
const DynamicTable = (
  { tableColumns, tableRows, onViewDetail, onEdit, deleteRow, archiveRow,
    pageSize, currentPage, params,
    setPageSize, setCurrentPage, setSkip, setParams,
    paginationMode,
    editLink,
    setspecialFilter,
    filterModel,
    additionalAction, onAdditionalAction,
    loading, rowCount,
    onSelectionChange, checkboxSelection,
    setFieldName,
    setDefaultValue, limit, setLimit,
    getRowHeight,
    ...restProps
  }) => {

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [updateStatus, setUpdateStatus] = useState("")

  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

  const navigate = useNavigate();
  const handleDelete = (id) => {
    setDeleteId(id)
    if (id.status === true) {
      setUpdateStatus("InActive")
    } else {
      setUpdateStatus("Active")
    }
    setOpen(true);
  };

  const confirmedDelete = (id) => {
    deleteRow(id);
    handleClose()
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleEdit = (data) => {
   
    editLink && navigate(editLink, { state: data.id })
    if (onEdit) {
      onEdit(data)
    }
    // navigate('/add-new-shift',{state:data})
  };

  const handleViewDetail = (data) => {
    onViewDetail(data);
  }

  const handleSelectionChange = (selectionArray) => {
    if (onSelectionChange) {
      onSelectionChange(selectionArray)
    }
  }

  const handleFilter = (filterParamsArray, r) => {
    if (filterParamsArray.items.length) {
      setFilterClosingFields(() => ({ columnField: filterParamsArray.items[0].columnField, operatorValue: filterParamsArray.items[0].operatorValue }))
    }

    if (filterParamsArray.items.length == 0 ? true : filterParamsArray.items.length && filterClosingFields.columnField === filterParamsArray.items[0].columnField && filterClosingFields.operatorValue === filterParamsArray.items[0].operatorValue && filterParamsArray.items[0].value === undefined && r.reason === "upsertFilterItem") {
      setReload(true);
      setTimeout(() => {
        setReload(false)
        setCurrentPage(0)
        setParams({})
        if (setDefaultValue) {
          setDefaultValue("")
        }
        setPageSize(pageSize)
      }, 0);
    }

    if (paginationMode === "server") {
      let filterArray = [];
      filterParamsArray.items.forEach(element => {
        if (setFieldName) {
          setFieldName(element.columnField)
        }
        filterArray = [...filterArray, {
          "key": element.columnField, "value": element.value || "",
          "operation": element.operatorValue === "contains" ? "icontains"
            : element.operatorValue === "equals" ? "ieq"
              : element.operatorValue === "startsWith" ? "istartswith"
                : element.operatorValue === "endsWith" ? "iendswith"
                  : element.operatorValue === "is" ? "eq"
                    : "ieq"

        }]
      })
      let filter = filterArray[0]
      if (filter?.value) {
        if (setspecialFilter) {
          setspecialFilter(false);
        }
        if (filter.key === "is_jira_project_text") {
          setSkip(0);
          setParams({ filter: { is_jira_project: { value: ("yes".includes(filter.value.toLowerCase())) ? "True" : "no".includes(filter.value.toLowerCase()) ? "False" : "", operation: "ieq" } } });
          return;
        }
        else
          if (filter.key === "status_text") {
            setSkip(0);
            setParams({ filter: { status: { value: ("active".includes(filter.value.toLowerCase())) ? "True" : "inactive".includes(filter.value.toLowerCase()) ? "False" : "", operation: "ieq" } } });
            return;
          }
          else
            if (filter.key === "projectsResources") {
              setSkip(0);
              setspecialFilter(true);
              setParams({ filter: { resource: { value: parseInt(filter.value), operation: "eq" } } });
              return;
            }
            else
              if (filter.key === "created_on" || filter.key === "updated_on") {
                setSkip(0);
                setspecialFilter(true);
                setParams({ filter: { [filter.key]: { value: filter.value, operation: "icontains" } } });
                return;
              }
              else
                if (filter.key === "status_value") {
                  setSkip(0);
                  setParams({ filter: { status: { value: filter.value, operation: "eq" } } });
                  return;
                }
                else
                  setSkip(0);
        setParams({
          filter: {
            [filter.key]: {
              value:
                String(filter.value), operation: filter.operation
            }
          }
        });
        // }
      }
      else {
        // params != {} && setParams({})
      }
    }
  }
  const handleSort = (sort) => {
    if (paginationMode === "server") {

      if (sort.length) {
        setParams({
          ...params, order_by: {
            "column": sort[0].sort === "desc" ?
              `-${sort[0].field === "status_value" ? "status" : sort[0].field}` :
              (sort[0].field === "status_value") ? "status" : sort[0].field
          }
        })
      }
      else {
        setParams({})
      }
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: "90",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="dynamic_table"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={!!deleteRow}
              editButton={onEdit ? ()=>handleEdit(params.row) : false}
              viewButton={onViewDetail ? handleViewDetail : false}
              deleteButton={() => handleDelete(params.row)}
              additionalAction={additionalAction}
              extraButton={onAdditionalAction}
              deleteIconName="ArchiveIcon"
            />
          </div>
        );
      },
    },

  ];

  //custom pagination
  const handlePagination = (page) => {
    setCurrentPage(page)
    setSkip(page * pageSize)
  }

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(0)
    setSkip(0)
    setPageSize(newPageSize.target.value)
    setLimit(newPageSize.target.value)
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={rowCount || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }
  return (
    <div className="datatable">
      <div className="custom-pagination-class">
        <CustomPagination />
      </div>
      {
        !reload &&
        <DataGrid
          className="datagrid"
          rows={(!loading && tableRows && tableRows.length) ? tableRows : []}
          loading={loading}
          columns={tableColumns?.concat(actionColumn)}
          filterMode="server"
          onFilterModelChange={(filter, r) => handleFilter(filter, r)}
          onSortModelChange={(sort) => handleSort(sort)}
          pageSize={pageSize}
          onSelectionModelChange={handleSelectionChange}
          checkboxSelection={checkboxSelection}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          filterModel={filterModel}
          experimentalFeatures={{ lazyLoading: true }}
          style={{ fontSize: 16 }}
          components={{
            Pagination: CustomPagination,
            NoRowsOverlay: CustomNoRowsOverlay
          }}
          getRowHeight={getRowHeight}
          {...restProps}
          hideFooterPagination={true}
        />
      }

      {/* <DataGridPro
        className="datagrid"
        filterMode="server"
        loading={!!reload}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        sx={{
          height: 700,
          "& .MuiDataGrid-row:hover": 
          {
            backgroundColor: "#438bf969",
          },
          ".actionButton": {
            display: 'none'
          },
          [`& .${gridClasses.row}`]: {
            "&:hover, &.Mui-hovered": {
              ".actionButton": {
                  display: 'block'
              }
              }
          }
        }}
        // rows={gridRows?.concat(skeletonRows)}
        columns= {actionColumn}
        filterModel={filterModel}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
        components={{
          ColumnMenuIcon:
          MoreHorizIcon,
          NoRowsOverlay: CustomNoRowsOverlay
        }}
        componentsProps={{
          row: {
            oncontextmenu: handleContextMenu
          }
        }}
        onFilterModelChange={onFilterChange}
        onRowsScrollEnd={handleOnRowScrollEnd}
        hideFooter
      /> */}

      <DeleteDialogModal
        open={open}
        onClose={handleClose}
        heading={updateStatus}
        paragraph={`Are You Sure To ${updateStatus}`}
        handleArchive={confirmedDelete}
        id={deleteId}
      />

    </div>
  );
};

export default DynamicTable;