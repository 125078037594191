import { InputMultiSelectField, InputSelectField } from "../../common-function/InputFields";
import LoaderForm from "../../../components/common-function/LoaderForm";

import { memo } from "react";
import SpecialisationSkillsComp from "./SpecialisationSkillsComp";

const StepFormOne = ({ client_List, handleChangeSelect, submitData, formValues, errStatus, formErr,
    industry_List, department_List, specialisation_List, reload, addMoreTechnology,
    removeSkill, specialisationSkills, handleSkillChange, showSkillData }) => {

    const submitFormData = (e) => {
        submitData(e, null)
    }

    return <>
        {(reload === true || reload === 'submit') ? <LoaderForm /> :
            <form onSubmit={(e) => submitFormData(e, null)}>
                <h4>Step 1 JOB CONFIGURATION</h4>
                <br />
                <div className="add-new-user-form">
                    <div className="formInput">
                        <InputSelectField
                            label={"Client"}
                            name={"client"}
                            placeholder={"Client"}
                            onChange={handleChangeSelect}
                            defaultValue={formValues.client}
                            errStatus={errStatus}
                            formErr={formErr.clientErr}
                            optionList={client_List && client_List.length > 0 && client_List.map((option) => {
                                return {
                                    label: option.name,
                                    value: option.id,
                                    name: 'client',
                                    is_internal: option.is_internal ,
                                    id: option.id
                                }
                            })
                            }
                            optionLevel={['label']}
                            optionValue={'id'}
                        />
                    </div>
                    <div className="formInput">
                        {formValues.client &&
                            <InputSelectField
                                label={"Industry "}
                                name={"industry"}
                                placeholder={"Industry"}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.industry}
                                errStatus={errStatus}
                                formErr={formErr.industryErr}
                                optionList={industry_List}
                                optionLevel={['industry_name']}
                                optionValue={'id'}
                            />
                        }
                    </div>

                    <div className="formInput">
                        {formValues.industry &&
                            <InputSelectField
                                label={"Department"}
                                name={"department"}
                                placeholder={"Department"}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.department}
                                errStatus={errStatus}
                                formErr={formErr.departmentErr}
                                optionList={department_List || []}
                                optionLevel={['name']}
                                optionValue={'id'}
                            />
                        }
                    </div>


                    <div className="formInput">
                        {
                            formValues.department &&
                            <InputMultiSelectField
                                divstyle={{ flexBasis: '100%' }}
                                label={"Technology /Specialisation"}
                                name={"specialistation_id"}
                                placeholder={"Technology /Specialisation"}
                                onRemove={addMoreTechnology}
                                onSelect={addMoreTechnology}
                                defaultValue={formValues.specialistation_id}
                                errStatus={errStatus}
                                formErr={formErr.specialisationErr}
                                optionList={
                                    specialisation_List?.map((option) => {
                                        return {
                                            label: option.label,
                                            value: option.value,
                                            name: "specialistation_id"
                                        }
                                    })
                                }
                                optionLevel={['label']}
                                optionValue={'value'}
                            />
                        }
                    </div>

                    {specialisationSkills && specialisationSkills.length > 0 && specialisationSkills.map((item, index) => {
                        return (
                            <SpecialisationSkillsComp
                                key={index}
                                skills={item}
                                formValues={formValues}
                                removeSkill={removeSkill}
                                index={index}
                                handleSkillChange={handleSkillChange}
                                showSkillData={showSkillData}
                            />
                        )
                    })}

                </div>

                {/* <button className="btn btn-submit">Save as Draft</button> */}
            </form>
        }
    </>
}
export default memo(StepFormOne)