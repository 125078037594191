import { AccountTree, Add, Attractions } from '@mui/icons-material'
import React from 'react'
import ProcessSpinner from '../loader/ProcessSpinner'
import './Button.css'
import { Tooltip } from "@mui/material";

const Button = ({ title, onClick, loading, spinnerSize, className, disabled, type, preAddIcon, tooltip }) => {

  return (
    tooltip ?
      <Tooltip title={tooltip}>
        <button
          className={className || `btn-with-loader`}
          onClick={onClick}
          disabled={disabled}
          type={type || "button"}
        >

          <span style={{ display: "unset" }}>
            {loading ? <ProcessSpinner size={spinnerSize || "sm"} /> : ""}
            {preAddIcon && tooltip === 'Add Workflow Mapping' ? <Attractions /> : tooltip === 'Add Workflow' ? <AccountTree/> : <Add />}{title || "Submit"}
          </span>

        </button>
      </Tooltip>
      : <button
        className={className || `btn-with-loader`}
        onClick={onClick}
        disabled={disabled}
        type={type || "button"}
      >

        <span style={{ display: "unset" }}>
          {loading ? <ProcessSpinner size={spinnerSize || "sm"} /> : ""}
          {preAddIcon && <Add />}{title || "Submit"}
        </span>

      </button>
  )
}

export default Button