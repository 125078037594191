import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Menu, MenuItem, Stack, TablePagination, Tooltip ,Switch, Skeleton} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { IndustryColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { industryCreate, industryEdit, industryUpdate, getIndustryList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useRef } from "react";
import { DataGridPro,gridClasses,getGridStringOperators } from "@mui/x-data-grid-pro";
import StatusInputValue from "../../components/common-function/StatusOperator";

const initialFormData = Object.freeze({
    industry_name: "",
    industry_type: "",
    description: "",
    status: true,

});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    industry_typeErr: "Industry key is required",
    industry_nameErr: "Industry name is required",
    statusErr: "Status is required"
});

const PaperComponent = (props) => {
    return (
        <Draggable
        bounds="body"
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}



const skeletonBase = {industry_name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', industry_type:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',description:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};
const filterableColumns = ["industry_name","industry_type","description","status"];

const Industry = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, industry_List, industry_Create, industry_Edit, industry_Update, industry_Archive } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INDUSTRY_LIST_COLUMNS}]}`)
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [colSortingPerform, setColSortingPerform] = useState([]);

    //
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    let editID = useRef(null);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    let isAnyChange = useRef({});
    let stopOnRowScrollEnd = useRef(false);
    const [sort,setSort]=useState(false)

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getIndustryList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [filterQuery])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false)
        setContextMenu(null);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_Update` && industry_Update && industry_Update.message) {
        if (industry_Update.status === 200) {
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...industry_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            handleClose();
            toast.success(industry_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(industry_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_Create` && industry_Create && industry_Create.message) {
        if (industry_Create.status === 200) {
            handleClose()
            toast.success(industry_Create.message)
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(industry_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industry_Archive` && industry_Archive && industry_Archive.message) {
        if (industry_Archive.status === 200) {
            handleClose()
            toast.success(industry_Archive.message)
            setTimeout(() => {
                dispatch(getIndustryList(filterQuery))
            }, 100);
        } else {
            toast.error(industry_Archive.message)
        }
        setReload(false)
    }


    if (reload === true && status === STATUSES.SUCCESS && industry_List && industry_List.message) {
        if (industry_List.status === 200) {
            if(clearGridListing.current){
                if(sort){
                    setGridRows([...industry_List.data.rows]);
                }else{
                    setGridRows([...industry_List.data.rows].sort(item=>item.status ? -1:1));
                }
               
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(industry_List.data.count);
            }
            else{
                if(sort){
                    setGridRows((pre)=>[...pre, ...industry_List.data.rows]);
                }else{
                    setGridRows((pre)=>[...pre, ...industry_List.data.rows].sort(item=>item.status ? -1:1));
                }
                
                SET_MAX_ROW_LENGTH(industry_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(industry_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleChange = (evt) => {
        const { name, value ,checked} = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })

    }


    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };


    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        // updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        updatedFilter.limit=25
        setFilterQuery(JSON.stringify(updatedFilter));
    }


    const submitData = (e) => {
        e.preventDefault()

        if (!!formValues.industry_type  || !!formValues.industry_name  || !!formValues.description || !!formValues.status ) {
            setErrStatus(true)
        }
        if (!!formValues.industry_type   && !!formValues.industry_name   && !!formValues.description  && !!formValues.status  ) {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(industryUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                setGridRows([]);
                dispatch(industryCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && industry_Edit && industry_Edit.data) {
        let editData = industry_Edit.data
        setFormValues({
            industry_name: editData.industry_name,
            industry_type: editData.industry_type,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(industryEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }

    }


    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            industry_name: data.industry_name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(industryUpdate({ ID, addvalues }))
    }


    const handleSort = (sortingCol) => {
        setSort(true)
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);
        
    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            // case 'status':
            //     handleArchive(row);
            //     break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }


   


    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );


    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
    const actionColumn = [
        {
            field: "industry_name",
            filterOperators,
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Industry Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("industry_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1rem', color:'#BCC2CE'}} /></button></div>,
            width: 320,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {

                return (

                    params.row.industry_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.industry_name}
                    </div>
                );
            },
        },
        {
            field: "industry_type",
            filterOperators,
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Industry Type</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("industry_type")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1rem', color:'#BCC2CE'}} /></button></div>,
            width: 360,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.industry_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360}/> :
                    <div>
                        {params.row.industry_type}
                    </div>
                );
            },
        },
        {
            field: "description",
            filterOperators,
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Description</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("description")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :
                    
                    <div >
                        { params.row.description ? 

                        typeof (params.row.description) === "string" ? params.row.description : JSON.stringify(params.row.description)
                        : params.row.description
                        }
                    </div>    
                                    
                );
                
            },
        },
        {
            field: "status",
            headerName: "Setting Status",
            width: 160,
            sortable:false,
            pinnable:false,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (

                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> 
                    
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="core-settings"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)} />
                        </div>
                    </div>
                );
            },
        },
       
    ];

    const onFilterChange = (props) =>
    {
        setSort(false)
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INDUSTRY_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"}, columns:DefaultFilterColumns.INDUSTRY_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartswith',
            endsWith: 'iendswith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }



    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>Indusytry List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getIndustryList} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort} />
                            </Stack>
                        </div>
                    </div>

                    <div className="custom-pagination-class">
                        {/* <CustomPagination /> */}
                    </div>
                        <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                              },
                              [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                  ".actionButton": {
                                    display: 'block'
                                  }
                                }
                              }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                          }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter={true}
                    />


                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Industry</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Industry"}
                                    name="industry_name"
                                    defaultValue={formValues.industry_name}
                                    type="text"
                                    placeholder="Industry Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.industry_nameErr}
                                />
                                <InputFields
                                    label={"Industry Type"}
                                    name="industry_type"
                                    defaultValue={formValues.industry_type}
                                    type="text"
                                    placeholder="Industry Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.industry_typeErr}
                                />

                               

                                <InputTextareaField
                                    label={"Description"}
                                    defaultValue={formValues.description}
                                    name="description"
                                    onChange={handleChange}
                                    rows="3"
                                    placeholder="Description"
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

<div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <Button variant="contained" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Industry`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                   

                </Menu>
            </div>
        </div >
    )
}

export default Industry