import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const getScreeningTest = createAsyncThunk("screen-test/list", async (data) => {
    const res = await DataService.get_screen_test(data);
    return res.data;
});

export const saveCandidateScheduleData = createAsyncThunk("save/schedule", async (data) => {
    const res = await DataService.save_candidate_schedule_data(data);
    return res.data;
});

export const completeCandidateExamPost = createAsyncThunk("exam/candidate-complete", async (data) => {
    const res = await DataService.exam_post_submit(data);
    const resp = res.data;
    return resp;
});

export const getStatusScheduleExam = createAsyncThunk("schedule-status/exam", async (data) => {
    const res = await DataService.get_exam_schedule_status(data.exam_schedule_id, data.schedule_status);
    const resp = res.data;
    return resp;
});

const screenTestStory = createSlice({
    name: "screenTestStory",
    initialState: {
        screeningTest_List: [],
        candidateSchedule_Save: [],
        examResultData: [],
        exam_status: STATUSES.IDLE,
        statusScheduleExam: [],

        status: STATUSES.IDLE,
        screen_status: STATUSES.IDLE,

        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///all get Screening Test list
            .addCase(getScreeningTest.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.screen_status = STATUSES.LOADING;

            })
            .addCase(getScreeningTest.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningTest_List`;
                state.screen_status = `${STATUSES.SUCCESS}_screeningTest_List`;
                state.screeningTest_List = action.payload;
                state.error = ""

            })
            .addCase(getScreeningTest.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.screen_status = STATUSES.FAILURE
                state.error = action.error
                state.screeningTest_List = [];
            })

            .addCase(saveCandidateScheduleData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.screen_status = STATUSES.LOADING;
            })
            .addCase(saveCandidateScheduleData.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_candidateSchedule_Save`;
                state.screen_status = `${STATUSES.SUCCESS}_candidateSchedule_Save`;
                state.candidateSchedule_Save = action.payload;
                state.error = ""
            })
            .addCase(saveCandidateScheduleData.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.screen_status = STATUSES.FAILURE
                state.error = action.error
                state.candidateSchedule_Save = [];
            })

            //get exam result data update 
            .addCase(completeCandidateExamPost.pending, (state, action) => {
                state.exam_status = STATUSES.LOADING;
            })
            .addCase(completeCandidateExamPost.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_examResultData`;
                    state.exam_status = `${STATUSES.SUCCESS}_examResultData`;

                    state.examResultData = action.payload;
                } else {
                    state.exam_status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(completeCandidateExamPost.rejected, (state, action) => {
                state.exam_status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get status exam schedule
            .addCase(getStatusScheduleExam.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getStatusScheduleExam.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_statusScheduleExam`;
                    state.statusScheduleExam = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getStatusScheduleExam.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

    },
});

export default screenTestStory.reducer;