import { Add, Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Menu, MenuItem, Radio, RadioGroup, Skeleton, Stack, Switch, TablePagination, Tooltip } from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import Paper from '@mui/material/Paper';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from '../../components/common-function/LoaderForm'
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { createProjectRoleMaster, getProjectRoleMaster, updateProjectRoleMaster } from "../../redux/features/projectSlice";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import dataService from "../../redux/services/data.service";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import Draggable from "react-draggable";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { Edit } from "@mui/icons-material";

let flag = 1;

function PaperComponent(props) {
    return (
        <Draggable 
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

const SubManagingProjectRoles = ({onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu,  clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu}) => {

    // const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`)
    // const [formValues, setFormValues] = useState(initialFormData)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [currentPage, setCurrentPage] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false)
    const [archiveId, setArchiveId] = useState(0)

    const [changeStatus, setChangeStatus] = useState('');
    const [openSingle, setOpenSingle] = useState(false)
    const [formData, setFormData] = useState({
        role_name: "",
        status: true,
    })

    const dispatch = useDispatch()

    const { project_status, status,projects_roles, projectRoleMaster_Create, project_role_master_update, projectRoleMaster_Edit } = useSelector(state => state.projects)

    const onEditBtn = (data) => {
        onOpenModal()
        setFormData({
            role_name: data?.role_name,
            role_key: data?.role_key,
            status: data?.status,
            id: data?.id
        })
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormData({ ...formData, [name]: name === 'status' ? checked : value });
    }


    const onSubmit = async (e) => {
        e.preventDefault()
        // validation
        if (!formData.role_name) {
            return toast.error('Role Name is required')
        } else if (formData.status === "") {
            return toast.error('Status is required')
        }
        // setReload(true)
        flag = 1;
        let request;
        if (formData.id > 0) {
            request = ({
                ...formData,
                status: formData.status,
                role_name: formData?.role_name.trim() !== "" && formData.role_name,
                
            })
        } else {
            request = ({
                ...formData,
                status: formData.status,
                role_name: formData.role_name.trim() !== "" && formData.role_name,
            })
        }

        const response = formData.id > 0 ?
            await dataService.project_role_master_update(formData.id, request)
            : dispatch(createProjectRoleMaster(request));
       
        onCloseModal();

        // if (response?.data?.status === 200) {
        //     toast.success(response?.data?.message)
        //     dispatch(getProjectRoleMaster(filterQuery))
        // } else {
        //     toast.error(response?.data?.message)
        // }
        // setTimeout(() => {
        //     setReload(false)
        // }, 500)
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const ProjectRolesColumn = [
        {
            field: "role_name",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Role Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("role_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color:'#BCC2CE' }} /></button></div>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.role_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> : 
                    <div>
                        <CustomeTooltip title={params.row.role_name || "N/A"} data={params.row.role_name || "N/A"} />
                    </div>
                );
            },
        },
        {
            field: "role_key",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Role Slug</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("role_key")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.role_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> : 
                    <div>
                        <CustomeTooltip 
                            title={params?.row?.role_key || "N/A"}
                            data={params?.row?.role_key || "N/A"}
                        />
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            type: 'singleSelect',
            valueOptions: [{label: 'Active', value: "True"},
            {label: 'InActive', value: "False"}
            ],
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> : 

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <Switch  name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)}/>
                        <div className="actionButton">
                            <UcActionComponent
                            deleteIconName=""
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            editButton={() => onEditBtn(params.row)}
                            isDelete={false}
                            viewButton={false}
                            deleteButton={false}
                        />
                        </div>
                    </div>
                );
            },
        },
        
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: "120",
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName=""
        //                     moduleName="third-party"
        //                     rowid={params.row.id}
        //                     addMoreData=""
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     editButton={() => onEditBtn(params.row)}
        //                     isDelete={false}
        //                     viewButton={false}
        //                     deleteButton={false}
        //                 />
        //             </div>
        //         );
        //     },
        // },
    ]


    //  Open Modal
    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
        setFormData({
            role_name: "",
            role_key: "",
            status: true,
            id: ""
        })
        setContextMenu(null)
    }



    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getProjectRoleMaster(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getProjectRoleMaster(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(projects_roles && projects_roles.count ? projects_roles.count : 0)}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }


    // const onFilterChange = (filterModel, r) => {

    //     if(filterModel.items.length)
    //     {
    //         setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
    //     }

    //     if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
    //     {
    //         setReload(true);
    //         setTimeout(()=>
    //         {
    //             setReload(false)
    //             setCurrentPage(0)
    //             //setPageSize(10)
    //             setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`)
    //             dispatch(getProjectRoleMaster(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`))
    //         },0);
    //     }

    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                 filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
    //         const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(getProjectRoleMaster(filterData))
    //     }
    // }

    

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    onEditBtn(row)
                    break;

        }

        setContextMenu(null);

    }

    const handleClickArchive = (data) => {
        flag = 1;
        setArchiveOpen(false)
        let addvalues = {
            id: data.id,
            role_key: data.role_key,
            role_name: data.role_name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        // setReload(true)
        dispatch(updateProjectRoleMaster({ ID, addvalues }))
    }

    if(flag === 1 && status === `${STATUSES.SUCCESS}project_role_master_edit` && projectRoleMaster_Edit.message) {
        if(projectRoleMaster_Edit.status === 200) {
            let editData =projectRoleMaster_Edit.data
            setFormData({
                role_name: editData.role_name,
                status: editData.status,
                id: editData.id
            })
            setArchiveId(editData.id);
            setOpenSingle(true);
        }
        flag = 0
    }

    if(flag===1 && status === `${STATUSES.SUCCESS}_project_role_master_update` && project_role_master_update && project_role_master_update.message) {
        if(project_role_master_update.status === 200 ) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip: 0, limit: gridRows.length}));
            setFilterQuery(str);
            toast.success(project_role_master_update.message)
        } else{
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows(copyLoadedRows);
            toast.error(project_role_master_update.message)
        }
        // setReload(false);
        flag = 0;
    }


// projectRoleMaster_Create
    if(flag === 1 && status === `${STATUSES.SUCCESS}_projectrolemaster_Create` && projectRoleMaster_Create.message) {
        if(projectRoleMaster_Create.status === 200) {
            toast.success(projectRoleMaster_Create.message);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip: 0, limit: gridRows.length}));
            setFilterQuery(str)
            setGridRows([]);
        } else{
            toast.error(projectRoleMaster_Create.message)
        }
        flag = 0
        setReload(false);
    }

    if(reload === true && project_status === STATUSES.SUCCESS && projects_roles && projects_roles.message) {
        if(projects_roles.status === 200){
            if(clearGridListing.current) {
                // toast.success(projects_roles.message)
                setGridRows([...projects_roles.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(projects_roles.data.count)
            } else{
                setGridRows((pre) => [...pre, ...projects_roles.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(projects_roles.data.count);
                setSkeletonRows([])
            }
        } else{
            toast.error(projects_roles.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }


    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'20px'}}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Manage Project Roles</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={onOpenModal}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getProjectRoleMaster} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                    {/* <div className="top-headings">
                        <h3>Manage Project Roles</h3>
                        <div>
                            <Tooltip title="Add New Project Role">
                                <button onClick={onOpenModal} className="btn-add-new">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getProjectRoleMaster} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div> */}
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {(project_status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    {
                        // !reload &&
                        // <DataGrid
                        //     className="datagrid"
                        //     columnVisibilityModel={columnVisibilityModel}
                        //     onColumnVisibilityModelChange={(newModel) =>
                        //         setColumnVisibilityModel(newModel)
                        //     }
                        //     rows={projects_roles?.rows ? projects_roles.rows : []}
                        //     columns={ProjectRolesColumn}
                        //     pageSize={pageSize}
                        //     style={{ fontSize: 16 }}
                        //     onFilterModelChange={onFilterChange}
                        //     components={{
                        //         Pagination: CustomPagination,
                        //         NoRowsOverlay: CustomNoRowsOverlay
                        //     }}
                        //     hideFooterPagination={true}
                        // />

                        <DataGridPro
                            className="datagrid"
                            filterMode="server"
                            loading={!!reload}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            sx={{
                                height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                    }
                            }
                            }}
                            rows={gridRows?.concat(skeletonRows)}
                            columns={ProjectRolesColumn}
                            filterModel={filterModal}
                            getRowClassName={(params) => params?.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                            components={{
                                ColumnMenuIcon: MoreHorizIcon,
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            componentsProps={{
                                row:{
                                    onContextMenu: handleContextMenu
                                }
                            }}
                            onFilterModelChange={onFilterChange}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooter
                            hideFooterPagination={true}
    
                        />
                    }
                </div>

                {/* model for add /edit  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                    // aria-describedby="child-modal-description"
                    // sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: "move" }} id="draggable-dialog-title">
                        {formData.id ? "Edit Project Role" : "Add Project Role"}

                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">

                                    <label >
                                        Role Name*
                                    </label>
                                    <input
                                        name="role_name"
                                        type="text"
                                        defaultValue={formData.role_name}
                                        placeholder="Type Role Name"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>

                                {formData.id ? <div className="formInput">
                                    <label >
                                        Role Slug*
                                    </label>
                                    <input
                                        readOnly
                                        name="role_key"
                                        type="text"
                                        defaultValue={formData.role_key}
                                        placeholder="Type Role Slug"
                                        onChange={(e) => handleChange(e)}
                                    />

                                </div> : ""}
                                <div className="formInput">
                                    {/* <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status*</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl> */}
                                    <div>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formData.status} onChange={(e) => handleChange(e)}/>
                                        <p>Active</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <button className={reload === true ? 'btn-disable' : 'submit-modal'} disabled={reload === true ? true : false} style={{ marginTop: "40px" }} onClick={onSubmit}>Submit</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} hiring mode`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={onCloseModal}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.menuX } : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                onCloseModal();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}} />Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')}
                    sx={{color:'rgba(220,0,0,.8)'}}> <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>
                </Menu>
            </div>
        </div >
    )
}


// export default ManagingProjectRoles;

const  ManagingProjectRoles = DataGridProLazyLoadHOC(SubManagingProjectRoles, {filterColumns: DefaultFilterColumns.PROJECT_ROLE_COLUMN.map(item=>item.replaceAll('"',"").trim()), getDispatcherAsyncThunk:getProjectRoleMaster, gridColumns:['role_name', 'role_key', 'status']});

export default ManagingProjectRoles;