import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getAgentCallReport = createAsyncThunk("agent-call-peformance-report/get", async (data) => {
    const res = await dataService.agent_call_performance_report(data);
    return res.data;
});

export const getAgentWiseResource = createAsyncThunk("agent-resource/list", async (data) => {
    const res = await dataService.get_agent_wise_resource(data);
    return res.data;
})


export const getHourlyCallReport=createAsyncThunk("hourly-call-report/list",async (data) =>{
    const res= await dataService.get_hourly_call_report(data)
    return res.data
})


const agentCallPerformanceReportSlice = createSlice({
    name: "agentCallPerformanceReportSlice",
    initialState: {
        agentCallReportList: [],
        agentResourceList: [],
        HourlyCallReportList:[],
        status: STATUSES.IDLE,
        error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get all agent Call performance report for list
            .addCase(getAgentCallReport.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_agentCallReportList`;
            })
            .addCase(getAgentCallReport.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_agentCallReportList`;
                    state.agentCallReportList = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getAgentCallReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //agent wise resorces for filter
            .addCase(getAgentWiseResource.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAgentWiseResource.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_agentResourceList`;
                state.agentResourceList = action.payload;
            })
            .addCase(getAgentWiseResource.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //Hourly call report
           .addCase(getHourlyCallReport.pending,(state,action)=>{
             state.status=STATUSES.LOADING;
            })
            .addCase(getHourlyCallReport.fulfilled,(state,action)=>{
                state.status=`${STATUSES.SUCCESS}_hourlyCallslist`
                state.HourlyCallReportList=action.payload;
            })
           .addCase(getHourlyCallReport.rejected,(state,action)=>{
                 state.status=STATUSES.FAILURE;
                 state.error=action.error
           })
    },
});


export default agentCallPerformanceReportSlice.reducer;