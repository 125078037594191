import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Stack, Switch, Tooltip, Checkbox, Skeleton, Menu, MenuItem, } from "@mui/material";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LinearProgress from '@mui/material/LinearProgress';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UrgencyCreate, UrgencyEdit, UrgencyUpdate, getUrgencyList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { useRef } from "react";
import { DataGridPro ,gridClasses,getGridStringOperators} from "@mui/x-data-grid-pro";
import { Edit } from "@mui/icons-material";
import PaperComponent from "../../components/common-function/PaperComponent";
import StatusInputValue from "../../components/common-function/StatusOperator";
const initialFormData = Object.freeze({
    urgency_type: "",
    status: true,
});

const skeletonBase = {urgency_type:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', setting_key:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',setting_value:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};


const filterableColumns = ['urgency_type','status'];

const formErrData = Object.freeze({
    urgencyTypeErr: "Urgency is required",
    statusErr: "Status is required"
});




const Urgency = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, urgencytype_List, urgencytype_Create, urgencytype_Edit, urgencytype_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.URGENCY_LIST_COLUMNS}]}`)
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [gridRows, setGridRows] = useState([]);
    let clearGridListing = useRef(false);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    let isAnyChange = useRef({});
    let stopOnRowScrollEnd = useRef(false);
    let editID = useRef(null);
    let gridTotalRows = useRef(null);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [sort,setSort]=useState(false)
    


    //grid states
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getUrgencyList(filterQuery))
    }, [filterQuery])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)

            setReload(false)
        }
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_urgencytype_Update` && urgencytype_Update && urgencytype_Update.message) {
        if (urgencytype_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            // setFilterQuery(str)
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...urgencytype_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            toast.success(urgencytype_Update.message)
            // dispatch(getUrgencyList(filterQuery))
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(urgencytype_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_urgencytype_Create` && urgencytype_Create && urgencytype_Create.message) {
        if (urgencytype_Create.status === 200) {
            toast.success(urgencytype_Create.message)
            // dispatch(getUrgencyList(filterQuery))
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(urgencytype_Create.message)
        }
        setReload(false)
        
    }
  
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {
        const { name, value,checked } = evt.target || evt;
        if (name === 'status' ) {
            setFormValues({ ...formValues, [name]: checked }) 
        } 
        else {
            setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
        }
    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.urgency_type === "" || formValues.status === "") {
            setErrStatus(true)
        }
        clearGridListing.current = true;

        if (formValues.urgency_type !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(UrgencyUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                setGridRows([]);
                dispatch(UrgencyCreate(addvalues))
            }

            setErrStatus(false)
            setSort(false)
            setReload(true)
            handleClose()
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && urgencytype_Edit && urgencytype_Edit.data) {
        let editData = urgencytype_Edit.data
        setFormValues({
            urgency_type: editData.urgency_type,
            urgency_type_key: editData.urgency_type_key,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }


    if (reload === true && status === STATUSES.SUCCESS && urgencytype_List && urgencytype_List.message) {
        if (urgencytype_List.status === 200) {
            if(clearGridListing.current){
               sort? setGridRows([...urgencytype_List.data.rows]): setGridRows([...urgencytype_List.data.rows]?.sort(item=>item.status? -1:1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(urgencytype_List.data.count);
            }
            else{
               sort ? setGridRows((pre)=>[...pre, ...urgencytype_List.data.rows]):setGridRows((pre)=>[...pre, ...urgencytype_List.data.rows]?.sort(item=>item.status? -1:1));
                SET_MAX_ROW_LENGTH(urgencytype_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(urgencytype_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleClickEdit = (id) => {
        dispatch(UrgencyEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }
    
    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            urgency_type: data.urgency_type,
            urgency_type_key: data.urgency_type_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(UrgencyUpdate({ ID, addvalues }))
    }


    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
        
        switch(actionType)
        {
            // case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':    
                    handleClickEdit(row?.id)
                    break;

        }

        setContextMenu(null);

    }
  
   const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setSort(true)
        setGridRows([]);
        
    }



    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith' ].includes(value),
    );

    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];


    const actionColumn = [
        {
            field: "urgency_type",
            headerName: "Urgency Type",
            filterOperators,
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Urgency Type</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("urgency_type")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable :false,
            renderCell: (params) => {
                    return (

                        params.row.urgency_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
    
                        <div>          
                            {params.row.urgency_type}
                        </div>
                    );
                
            },
        },
        // {
        //     field: "urgency_type_key",
        //     headerName: "Urgency Type Slug",
        //     width: 280,
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 {params.row.urgency_type_key}

        //             </div>
        //         );
        //     },
        // },
        {
            field: "status",
            filterOperators: statusOnlyOperators,
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Status</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("status")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1rem', color:'#BCC2CE'}} /></button></div>,
            width: 160,
            sortable:false,
            pinnable:false,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            renderCell: (params) => {
                return (

                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> 
                    
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="core-settings"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)} />
                        </div>
                    </div>
                );
            },
        },
    ]
 


    const onFilterChange = (props) =>
    {
        setSort(false)
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.URGENCY_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"}, columns:DefaultFilterColumns.URGENCY_LIST_COLUMNS[0].replace("'","").split(",").map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }



    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>Urgency Type List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getUrgencyList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort}/>
                            </Stack>
                        </div>
                    </div>
                    <div className="custom-pagination-class">
               
                    </div>
                
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        hideFooterRowCount={true}
                    />

                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    // aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" ,cursor: 'move'}} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Urgency </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Urgency Type"}
                                    name="urgency_type"
                                    defaultValue={formValues.urgency_type}
                                    type="text"
                                    placeholder="Urgency-Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.urgencyTypeErr}
                                />
                                {formValues.id ? <div className="formInput">
                                    <label >
                                        Urgency Slug*
                                    </label>
                                    <input
                                        readOnly
                                        label={"Urgency Slug"}
                                        name="urgency_slug"
                                        defaultValue={formValues.urgency_type_key}
                                        type="text"
                                        placeholder="Urgency-Slug"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""}

                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Urgency`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

<Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                   
            
                </Menu>
            </div>
        </div >
    )
}

export default Urgency;