import "./profile.scss";
import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import api from "../../redux/APIS/api";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Avatar, Badge, Box, Button, Card, CardActions, CardContent, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Skeleton, Snackbar, Stack, Tooltip, Typography, styled } from "@mui/material";
import Dropzone from 'react-dropzone';
import { imageUpload, resourceUpdateById } from "../../redux/features/resourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { toast, ToastContainer } from "react-toastify";
import { Close } from "@mui/icons-material";
import { SingleDatePickerInputField } from "../../components/common-function/InputFields";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import DialogBox from "./DialogBox";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { green, orange, red } from "@mui/material/colors";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import AnniversaryIcon from "./anniversaryIcon";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import CloseIcon from '@mui/icons-material/Close';
import { attandanceList } from "../../redux/features/calendarSlice";
const { REACT_APP_API_BASEURL } = process.env;

const initialFormData = Object.freeze({
  date_of_birth: null,
  date_of_marriage: null,

})

const Profile = () => {
  const navigate = useNavigate()
  const { Id } = useParams()
  const [user, setUser] = useState({});
  const [formValues, setFormValues] = useState(initialFormData)
  const { status, error, profilePhoto, updateResource } = useSelector(state => state.resource);

  const [snakbarData, setSnakbarData] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = snakbarData;

  const dispatch = useDispatch();

  const loadProfile = async () => {
    setLoader(true);
    if (Id > 0) {
      const res = await api.get(`/resources/main/${Id}`)
        .then().catch(error => console.log(error))
      if (res?.data && res.data.data) {
        setUser({});
        setLoader(false);
      }
    } else {
      const res = await api.get('/user')
        .then().catch(error => console.log(error))
      if (res?.data && res.data.data) {
        setUser(res.data.data);
        setFormValues({
          date_of_birth: res?.data?.data?.date_of_birth,
          date_of_marriage: res?.data?.data?.date_of_marriage,
        })
        setLoader(false);
      }
    }
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileErr, setFileErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [invalidExtension, setInvalidExtension] = useState("");
  const [openModel, setOpenModel] = useState(false)
  const [formatedDates, setFormatedDates] = useState({
    marriage_anniversary: "",
    work_anniversary: "",
  })
  const [openDialog, setOpenDialog] = React.useState(false);
  // const punchInOutStatusState=useSelector(state => state.dashboardCard.punInOutStatus );
  const punchInOutStatusState = useSelector(state => state.calender?.attendanceData?.data?.rows[0]?.is_punchout_enable);

  useEffect(() => {
    dispatch(attandanceList())
  }, [])

  const onFileChange = (event) => {
    setInvalidExtension("")
    let file = event[0];
    const fileType = file.name.split(".")[1];
    let image_extension = ["jpg", "jpeg", "png"]
    if (image_extension.includes(fileType.toLowerCase())) {
      if (file?.size >= 20480 && file?.size <= 1048576) {
        setFileErr("");
        setSelectedImage(file);
        const getFormData = (obj) => {
          const formData = new FormData();
          Object.keys(obj).forEach(key => formData.append(key, obj[key]));
          return formData;
        }
        let obj = {
          id: user.id,
          file: file,
          display_name: file.name.split(".")[0],
          upload_type: 'resource'
        }
        dispatch(imageUpload(getFormData(obj)));
        setSelectedImage(null);
        setLoader(true);
      } else {
        setSelectedImage(null);
        setFileErr("Image size should be 20kb to 1 mb")
      }

    } else {
      setSelectedImage(null);
      setFileErr("You can only upload jpg, jpeg, png file")
    }
  }

  const handleModelClose = () => {
    setOpenModel(false)
  }

  if (loader === true && status === STATUSES.SUCCESS && profilePhoto && profilePhoto.message) {
    if (profilePhoto.status === 200) {
      loadProfile();
      setLoader(false);
      toast.success(profilePhoto.message);
    }
    if (profilePhoto.status === 400) {
      setLoader(false);
      setInvalidExtension(profilePhoto.message);
      toast.error(profilePhoto.message);
    }
  }

  if (loader === true && status === STATUSES.FAILURE && error && error.message) {
    setLoader(false)
    toast.error(error.message);
  }

  if (loader === true && status === STATUSES.SUCCESS && updateResource && updateResource.message) {
    if (updateResource.status === 200) {
      handleModelClose()
      loadProfile()
      toast.success(updateResource.message);
      setLoader(false)
    } else {
      handleModelClose()
      toast.error(updateResource.message);
      setLoader(false)
    }

  }

  useEffect(() => {
    loadProfile()
  }, [])


  const handleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setFormValues({ ...formValues, [name]: value })
  }

  const sumbmitData = (event) => {
    event.preventDefault()
    if (!formValues.date_of_birth) {
      return
    }
    let techArray = user?.technologies
    const addData = {
      display_name: user?.display_name,
      email: user?.email,
      name: user?.name,
      password: user?.password,
      status: user?.status,
      user: user?.user_id,
      date_of_birth: formValues?.date_of_birth,
      contact_no: user?.contact_no,
      is_interviewer: user?.is_interviewer,
      technologies: techArray,
    }
    if (formValues.date_of_marriage) {
      addData["date_of_marriage"] = formValues.date_of_marriage
    }
    dispatch(resourceUpdateById({ id: user?.id, addvalues: addData }))
    setLoader(true)
  }

  const formatDates = () => {
    const userDOJ = new Date(user.doj);
    const userDOM = new Date(user.date_of_marriage);

    const currentYear = new Date().getFullYear();
    const arrangeDate = (date, event) => {
      if (!date) {
        return ""
      }
      const anniversaryDate = new Date(date);
      anniversaryDate.setFullYear(currentYear);
      const formattedWorkAnniversary = anniversaryDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
      const [month, day, year] = formattedWorkAnniversary.split(' ');
      const rearrangedDate = `${day} ${month} ${year}`;
      return rearrangedDate
    }
    const joiningDate = arrangeDate(userDOJ, "work_anniversary")
    const marriageDate = arrangeDate(userDOM, "marriage_anniversary")

    setFormatedDates({
      marriage_anniversary: marriageDate,
      work_anniversary: joiningDate,
    })

  }

  useEffect(() => {
    if (user?.id) {
      formatDates()
    }
  }, [user, user.id])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: '15px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: punchInOutStatusState ? '#14ed00' : '#ff0000',
      color: punchInOutStatusState ? '#14ed00' : '#ff0000',
      height: '30px',
      cursor: '',
      width: '30px',
      borderRadius: '50%',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    // '.MuiBadge-badge:hover': {
    //   background: '#ff0000',
    //   color: '#ff0000',
    //   cursor: ''
    // },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


  const openSnaker = (newData) => {
    setSnakbarData({ ...newData, open: true });
  };

  const handleCloseSnaker = () => {
    setSnakbarData({ ...snakbarData, open: false });
    // setFileErr("")
    // setInvalidExtension("")
  };

  useEffect(() => {
    if (fileErr !== "" || invalidExtension !== "") {
      openSnaker({ ...snakbarData, open: true });
    }
  }, [fileErr, invalidExtension])



  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [mobileView, setMobileView] = useState(null)

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  useEffect(() => {
    if (screenSize.width < 985) {
      setMobileView(true)
    } else {
      setMobileView(false)
    }
  }, [screenSize])

  return (
    <div className="single">
      <div className="singleContainer">
        <ToastContainer />
        <Card>
          <Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
              <Grid md={mobileView ? 12 : 5} sm={12} sx={12}>
                <Item variant="outlined">
                  {loader === true ?
                    <Stack mt={2} direction={'row'} justifyContent={'center'}>
                      <Skeleton variant="circular" width={200} height={200} />
                    </Stack> : <Stack animation="wave" sx={{ animationDuration: '0.4s', }} mt={2} direction={'row'} justifyContent={'center'}>
                      {user.avatar_path ?
                        <>
                        <Tooltip title="Drag Your Picture For Upload New Profile." followCursor>
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot">
                            <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles="1">
                              {({ getRootProps, getInputProps }) => (
                                <section style={{ cursor: 'copy' }}>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {selectedImage && selectedImage !== null ? selectedImage.name : <>
                                      <Avatar
                                        alt="Remy Sharp"
                                        src={REACT_APP_API_BASEURL + user?.avatar_path?.split("").slice(1).join("")}
                                        sx={{ width: 200, height: 200 }}
                                      />
                                    </>}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </StyledBadge>
                        </Tooltip>
                        </>
                        : <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles="1">
                              {({ getRootProps, getInputProps }) => (
                                <section style={{ cursor: 'pointer' }}>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {selectedImage && selectedImage !== null ? selectedImage.name : <>
                                      <Avatar sx={{ bgcolor: punchInOutStatusState ? '#14ed00' : '#ff0000' }}>
                                        <AddAPhotoIcon />
                                      </Avatar>
                                    </>}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          }
                        >
                          <Avatar sx={{ width: 200, height: 200, fontSize: '55px', fontWeight: '600' }} alt={user.display_name} src="/static/images/avatar/2.jpg" />
                        </Badge>
                      }
                    </Stack>
                  }


                  {loader === true ? <CardContent>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                      }}
                    >
                      {Array(6).fill({}).map(() => {
                        return (
                          <>
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '0px 8px 0px 16px' }}>
                                <Skeleton width={'100%'} variant="text" sx={{ animationDuration: '0.4s', fontSize: '1rem', margin: '15px' }} />
                              </ListItemAvatar>
                              <Skeleton width={'100%'} variant="text" sx={{ animationDuration: '0.4s', fontSize: '1rem', margin: '15px' }} />
                            </ListItem>
                          </>
                        )
                      })}
                    </List>
                  </CardContent>
                    : <CardContent>
                      {user.display_name && <Typography color={'#1B3764'} variant="h4">{user.display_name}</Typography>}
                      {user?.department && user.department.name && <Typography variant="subtitle1">{user.department.name}</Typography>}
                      <Item variant="outlined">
                        <Stack direction={'row'}>
                          {/* <Stack p={'16px'}><AlternateEmailIcon sx={{ color: '#438BF9FF', height: '24px', width: '24px', fontSize: '30px' }} /></Stack>
                    <Stack p={'16px'}>
                      {user.email && <Typography >{user.email}</Typography>}
                      <Divider />
                    </Stack> */}
                          <List
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                            }}
                          >
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '7px 0px 0px 16px' }}>
                                <AlternateEmailIcon sx={{ color: '#438BF9FF', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                              <ListItemText primary={user?.email ? user?.email : 'N/A'} /*secondary="Jan 9, 2014"*/ />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '7px 0px 0px 16px' }}>
                                <WifiCalling3OutlinedIcon sx={{ color: '#438BF9FF', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                              <ListItemText primary={user?.contact_no ? user?.contact_no : 'N/A'} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '7px 0px 0px 16px' }}>
                                <LocationOnOutlinedIcon sx={{ color: '#438BF9FF', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                              <ListItemText primary={user?.address ? user?.address : 'N/A' + " " + user?.city?.city_name ? user?.city?.city_name : 'N/A' + " " + user?.state?.name ? user?.state?.name : 'N/A' + " " + user?.country?.name ? user?.country?.name : 'N/A'} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '7px 0px 0px 16px' }}>
                                <CakeIcon sx={{ color: '#438BF9FF', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                              <ListItemText primary={user?.date_of_birth ? user?.date_of_birth : 'N/A'} />
                              <ListItemAvatar sx={{ display: 'flex', justifyContent: 'end' }}>
                                <BorderColorRoundedIcon onClick={() => setOpenModel(true)} sx={{ color: '#1B3764', cursor: 'pointer', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '7px 0px 0px 5px' }}>
                                <AnniversaryIcon />
                              </ListItemAvatar>
                              <ListItemText primary={user?.date_of_marriage ? user?.date_of_marriage : 'N/A'} />
                              <ListItemAvatar sx={{ display: 'flex', justifyContent: 'end' }}>
                                <BorderColorRoundedIcon onClick={() => setOpenModel(true)} sx={{ color: '#1B3764', cursor: 'pointer', height: '24px', width: '24px', fontSize: '30px' }} />
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        </Stack>
                      </Item>
                    </CardContent>
                  }
                </Item>
              </Grid>
              <Grid mt={1.4} md={mobileView ? 12 : 7} sm={12} sx={12}>
                <Stack ml={1.4} direction={"row"}>
                  {loader === true ? <Skeleton width={'10%'} variant="rounded" sx={{ animationDuration: '0.4s', margin: '15px' }} /> :
                    <Typography fontSize={'24px'} fontWeight={"600"} color={'#1B3764'} variant="h2">Official Data</Typography>}
                </Stack>
                <Item variant="outlined" sx={{ boxShadow: 'none', margin: '0px' }}>
                  {loader === true ? <CardContent>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                      }}
                    >
                      {Array(8).fill({}).map(() => {
                        return (
                          <>
                            <ListItem>
                              <ListItemAvatar sx={{ padding: '0px 8px 0px 16px' }}>
                                <Skeleton width={'100%'} variant="text" sx={{ animationDuration: '0.4s', fontSize: '1rem', margin: '15px' }} />
                              </ListItemAvatar>
                              <Skeleton width={'100%'} variant="text" sx={{ animationDuration: '0.4s', fontSize: '1rem', margin: '15px' }} />
                            </ListItem>
                          </>
                        )
                      })}
                    </List>
                  </CardContent>
                    : <CardContent>
                      <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                        }}
                      >
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Employee Code</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.emp_code ? user?.emp_code : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Employment Type</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.employment_type?.employment_type ? user?.employment_type?.employment_type : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Domain</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.domain?.name ? user?.domain?.name : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Manager</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.reporting_manager_name ? user?.reporting_manager_name : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Jira Account</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.jira_account ? user?.jira_account : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Work Anniversary</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.doj ? user?.doj : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Shift Name</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{user?.shift?.shift_name ? user?.shift?.shift_name : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Shift Time</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              <Typography fontSize={'18px'} >{[user?.shift?.timing_display] ? [user?.shift?.timing_display] : 'N/A'}</Typography>
                            </ListItem>
                          </>
                        </Stack>
                        <Stack m={'16px'} direction={'row'} justifyContent={'space-around'}>
                          <>
                            <ListItem sx={{ width: '50%', margin: '2px' }}>
                              <Typography fontSize={'20px'} color={'#438BF9FF'} fontWeight={'500'} >Technology</Typography>
                            </ListItem>
                            <ListItem sx={{ margin: '2px' }}>
                              {user?.technology?.length > 0 ? user.technology.map((e, index) => (
                                <Chip
                                  key={index}
                                  color={index % 2 == 0 ? "primary" : "success"}
                                  disabled={false}
                                  size="small"
                                  variant="elevated"
                                  sx={{ margin: '4px' }}
                                  label={`${index ? ' ' : ''}${(e.skill_name).trim()}`}
                                />
                              )
                              ) :
                                <Typography fontSize={'18px'} >N/A</Typography>
                              }
                            </ListItem>
                          </>
                        </Stack>
                      </List>
                    </CardContent>}
                </Item>
              </Grid>
            </Grid>
          </Box>
          <CardActions>
            {loader === true ? <Skeleton width={'11%'} height={'30px'} variant="rounded" sx={{ animationDuration: '0.4s', margin: '15px' }} /> :
              <Button variant="contained" startIcon={<KeyOutlinedIcon />} onClick={() => setOpenDialog(true)} size="small">Change Password</Button>}
          </CardActions>
        </Card>
        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={openModel}
          onClose={handleModelClose}
          aria-labelledby="child-modal-title"
        >
          <Button
            edge="start"
            color="inherit"
            onClick={handleModelClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Edit Personal Details</DialogTitle>

          <DialogContent>
            <form>
              <div className="add-new-user-form">
                <SingleDatePickerInputField
                  label={"Date Of Birth"}
                  name="date_of_birth"
                  defaultValue={formValues?.date_of_birth}
                  onChange={(e) => {
                    handleChange({
                      name: "date_of_birth",
                      value: e
                    })
                  }}
                />

                <SingleDatePickerInputField
                  label={"Date of Marriage"}
                  name="date_of_marriage"
                  defaultValue={formValues?.date_of_marriage}
                  onChange={(e) => {
                    handleChange({
                      name: "date_of_marriage",
                      value: e
                    })
                  }}
                />

              </div>
              <button className="submit-modal" onClick={sumbmitData} >Update</button>
            </form>
          </DialogContent>
        </Dialog>

        <DialogBox setOpenDialog={setOpenDialog} openDialog={openDialog} />
        <Snackbar
          anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
          open={open}
          autoHideDuration={4000}
          onClose={handleCloseSnaker}
        >
          <Alert onClose={handleCloseSnaker} variant="filled" severity="error" sx={{ width: '100%' }}>
            {fileErr + ' ' + invalidExtension}
          </Alert>
        </Snackbar>
        {/* {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
        {invalidExtension !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{invalidExtension}</p>} */}
        {/* <div className="top">
          {loader === true && <LoaderForm />}
          <div className="left">

            <div className="item">

              {user.avatar_path ?
                <img src={REACT_APP_API_BASEURL + user.avatar_path.split("").slice(1).join("")}
                  alt="" className="itemImg" />
                :
                <img src="https://images.unsplash.com/photo-1504376379689-8d54347b26c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80"
                  alt="" className="itemImg" />
              }
              <div className="file-dropzone-tabs">
                <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles="1">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>{selectedImage && selectedImage !== null ? selectedImage.name : <>Drag 'n' drop photo here, or click to upload photo <br />(&#128073;Only jpg/jpeg/png and size 20KB to 1MB file accepted.)</>}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
              {invalidExtension !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{invalidExtension}</p>}

            </div>
            <div className="profile-change-password">
              <Button variant="contained" startIcon={<KeyOutlinedIcon/>} onClick={()=> setOpenDialog(true)}>Change Password</Button>
              <DialogBox setOpenDialog={setOpenDialog} openDialog={openDialog}/>
            </div>
          </div>
          <div className="right">
            <Tooltip title="Back">
              <button className="editButton" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            </Tooltip>
            <div>
              <h1 className="title">Official Data</h1>
              <div className="details">
                {user.display_name &&
                  <div className="detailItem">
                    <span className="itemKey">Name :</span>
                    <span className="itemKey2">{user.display_name}</span>
                  </div>
                }
                {user.email &&
                  <div className="detailItem">
                    <span className="itemKey">Email :</span>
                    <span className="itemKey2">{user.email}</span>
                  </div>
                }
                {user.contact_no &&
                  <div className="detailItem">
                    <span className="itemKey">Contact no. :</span>
                    <span className="itemKey2">{user.contact_no}</span>
                  </div>
                }

                {user?.tms_role && user.tms_role.role_name &&
                  <div className="detailItem">
                    <span className="itemKey">Role :</span>
                    <span className="itemKey2">{user.tms_role.role_name}</span>
                  </div>
                }
                {user?.department && user.department.name &&
                  <div className="detailItem">
                    <span className="itemKey">Department :</span>
                    <span className="itemKey2">{user.department.name}</span>
                  </div>
                }
                {user.address &&
                  <div className="detailItem">
                    <span className="itemKey">Address :</span>
                    <span className="itemKey2"> {user.address}</span>
                  </div>
                }

                {user?.city && user.city.city_name &&
                  <div className="detailItem">
                    <span className="itemKey">City :</span>
                    <span className="itemKey2">{user.city.city_name}</span>
                  </div>
                }
                {user?.state && user.state.name &&
                  <div className="detailItem">
                    <span className="itemKey">State :</span>
                    <span className="itemKey2">{user.state.name}</span>
                  </div>
                }
                {user?.country && user.country.name &&
                  <div className="detailItem">
                    <span className="itemKey">Country :</span>
                    <span className="itemKey2">{user.country.name}</span>
                  </div>
                }

                {user.jira_account &&
                  <div className="detailItem">
                    <span className="itemKey">Jira account :</span>
                    <span className="itemKey2"> {user.jira_account}</span>
                  </div>
                }

                {user.emp_code &&
                  <div className="detailItem">
                    <span className="itemKey">Employee Code :</span>
                    <span className="itemKey2">{user.emp_code}</span>
                  </div>
                }

                {user.reporting_manager_name &&
                  <div className="detailItem">
                    <span className="itemKey">Manager :</span>
                    <span className="itemKey2">{user.reporting_manager_name}</span>
                  </div>
                }

              {user?.domain?.name &&
                <div className="detailItem">
                  <span className="itemKey">Domain:</span>
                  <span className="itemKey2">{user?.domain?.name}</span>
                </div>
              }


                {user.employment_type &&
                  <div className="detailItem">
                    <span className="itemKey">Employment Type :</span>
                    <span className="itemKey2">{user.employment_type.employment_type}</span>
                  </div>
                }

                {user.shift &&
                  <div className="detailItem">
                    <span className="itemKey">Shift Name :</span>
                    <span className="itemKey2">{[user.shift.shift_name]}</span>
                  </div>
                }
                {user.shift &&
                  <div className="detailItem">
                    <span className="itemKey">Shift Time :</span>
                    <span className="itemKey2">{[user.shift.timing_display]}</span>
                  </div>
                }

                <div className="detailItem">
                  <span className="itemKey">Is Interviewer :</span>
                  <span className="itemKey2">{user.is_interviewer === false ? "No" : "Yes"}</span>
                </div>

                {
                  user?.technology &&
                  <div className="detailItem">
                    <span className="itemKey" style={{ float: "left" }}>Technology :</span>
                    {user.technology.length > 0 ? user.technology.map((e, index) => (
                      <span className="itemKey2" key={index} >
                        {`${index ? ', ' : ''}${(e.skill_name).trim()}`}
                      </span>
                    )
                    ) : "N/A"}
                  </div>
                }

                {user.doj &&
                  <div className="detailItem">
                    <span className="itemKey">Work anniversary :</span>
                    <span className="itemKey2"> {user.doj}</span>
                  </div>
                }

              </div>
            </div>

            <div>
              <h1 className="title">Personal Data</h1>
              <Tooltip title="Edit">
                <button className="editButton" onClick={() => setOpenModel(true)}><EditIcon /></button>
              </Tooltip>
              <div className="details">

                {user.date_of_birth &&
                  <div className="detailItem">
                    <span className="itemKey">Date of Birth :</span>
                    <span className="itemKey2"> {user.date_of_birth}</span>
                  </div>
                }

                {user.date_of_marriage &&
                  <div className="detailItem">
                    <span className="itemKey">Marriage Anniversary :</span>
                    <span className="itemKey2">{user.date_of_marriage} </span>
                  </div>
                }


              </div>
              <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"md"}
                open={openModel}
                onClose={handleModelClose}
                aria-labelledby="child-modal-title"
              >
                <Button
                  edge="start"
                  color="inherit"
                  onClick={handleModelClose}
                  aria-label="close"
                  style={{ position: "absolute", top: "10px", right: "10px" }}>
                  <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Edit Personal Details</DialogTitle>

                <DialogContent>
                  <form>
                    <div className="add-new-user-form">
                      <SingleDatePickerInputField
                        label={"Date Of Birth"}
                        name="date_of_birth"
                        defaultValue={formValues?.date_of_birth}
                        onChange={(e) => {
                          handleChange({
                            name: "date_of_birth",
                            value: e
                          })
                        }}
                      />

                      <SingleDatePickerInputField
                        label={"Date of Marriage"}
                        name="date_of_marriage"
                        defaultValue={formValues?.date_of_marriage}
                        onChange={(e) => {
                          handleChange({
                            name: "date_of_marriage",
                            value: e
                          })
                        }}
                      />

                    </div>
                    <button className="submit-modal" onClick={sumbmitData} >Update</button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div> */}

      </div>
    </div >
  )
}

export default Profile