import React from 'react'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function CustomeTooltip({ title, data }) {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#d8d9dc',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 350,
            fontSize: 14,
            border: '1px solid #dadde9',
        },
    }));

    return (
        <HtmlTooltip title={title} >
            <p> {data} </p>
        </HtmlTooltip>
    )
}
