import "./master.scss"
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable'
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, IconButton, Skeleton, Stack, Switch, TablePagination, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoreSpecificationCreate, MoreSpecificationEdit, MoreSpecificationUpdate, getMoreSpecificationList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { Close } from "@mui/icons-material";
import { InputFields } from "../../components/common-function/InputFields";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { useRef } from "react";
import { DataGridPro,gridClasses } from "@mui/x-data-grid-pro";

const initialFormData = Object.freeze({
    specification_name: "",
    status: true,
});

const formErrData = Object.freeze({
    specification_nameErr: "Specification type is required",
    statusErr: "Status is required"
});

const skeletonBase = { specification_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', specification_name_key: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',  status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['specification_name', 'specification_name_key','status'];                

const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const MoreSpecification = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, more_specification_List, more_specification_Create, more_specification_Edit, more_specification_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MORE_SPECIFICATION_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    // grid states and ref starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.specification_name === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.specification_name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(MoreSpecificationUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(MoreSpecificationCreate(addvalues))
            }
        }
    }


    const handleClickEdit = (id) => {
        dispatch(MoreSpecificationEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            specification_name: data.specification_name,
            specification_name_key: data.specification_name_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(MoreSpecificationUpdate({ ID, addvalues }))
    }

    // sorting
    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] ? 25 : newFilterData['skip'];
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;        
    }


    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };



    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };

    const actionColumn = [
        {
            field: "specification_name",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Specification Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("specification_name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 320,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {

                return (

                    params.row.specification_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.specification_name}
                    </div>
                );
            },
        },
        {
            field: "specification_name_key",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Specification Name Key</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("specification_name_key")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 360,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.specification_name_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360}/> :
                    <div>
                        {params.row.specification_name_key}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
            </Stack>,
            width: 160,
            sortable:false,
            pinnable:false,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            renderCell: (params) => {
                return (

                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> 
                    
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                // deleteIconName="ArchiveIcon"
                                moduleName="core-settings"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                // isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                // deleteButton={() => handleArchive(params.row)} 
                                />
                        </div>
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (props) =>
    {
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MORE_SPECIFICATION_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"}, columns:DefaultFilterColumns.MORE_SPECIFICATION_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_more_specification_Update` && more_specification_Update && more_specification_Update.message) {
        if (more_specification_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(more_specification_Update.message)
            // dispatch(getMoreSpecificationList(filterQuery))
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(more_specification_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_more_specification_Create` && more_specification_Create && more_specification_Create.message) {
        if (more_specification_Create.status === 200) {
            toast.success(more_specification_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
            // dispatch(getMoreSpecificationList(filterQuery))
        } else {
            toast.error(more_specification_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && more_specification_List && more_specification_List.message) {
        if (more_specification_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...more_specification_List.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(more_specification_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...more_specification_List.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(more_specification_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(more_specification_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && more_specification_Edit && more_specification_Edit.data) {
        let editData = more_specification_Edit.data
        setFormValues({
            specification_name: editData.specification_name,
            specification_name_key: editData.specification_name_key,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getMoreSpecificationList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [filterQuery])


    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>More Specification List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getMoreSpecificationList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                            </Stack>
                        </div>
                    </div>

                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={ skeletonRows.length === 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: ()=>(<MoreHorizIcon sx={{color:"#171c26ba"}}/>),
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"

                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} More Specification</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"More Specification"}
                                    name="specification_name"
                                    defaultValue={formValues.specification_name}
                                    type="text"
                                    placeholder="More Specification Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.specification_nameErr}
                                />
                                {formValues.id ? <div className="formInput">
                                    <label >
                                        More Specification Slug*
                                    </label>
                                    <input
                                        readOnly
                                        label={"More Specification Slug"}
                                        name="specification_name_key"
                                        defaultValue={formValues.specification_name_key}
                                        type="text"
                                        placeholder="More-Specification-Slug"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""}

                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} More Specification`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default MoreSpecification;