import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getResumeAnalysisReport = createAsyncThunk("resume-analysis-report/get", async (data) => {
    const res = await dataService.resume_analysis_report(data);
    return res.data;
});

const resumeAnalysisReportSlice = createSlice({
    name: "resumeAnalysisReportSlice",
    initialState: {
        resumeAnalysisReportList: [],
        status: STATUSES.IDLE,
        error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get all resume analysis report for list
            .addCase(getResumeAnalysisReport.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_resumeAnalysisReportList`;
            })
            .addCase(getResumeAnalysisReport.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_resumeAnalysisReportList`;
                    state.resumeAnalysisReportList = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getResumeAnalysisReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

    },
});


export default resumeAnalysisReportSlice.reducer;