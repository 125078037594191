import React from 'react'
import "./advancelisting.scss";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const AdvanceListing = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="home">
            <div className="homeContainer">
                <div className="advancelisting-row">
                    <div className="advance-listing-colum-1">
                        <div className="advance-listing-1-flex">
                            <div className="advance-listing-1-flex-colum">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />} label="Attendance Regularization" />
                                </FormGroup>
                            </div>
                            <div className="advance-listing-2-flex-colum">
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    Newest <KeyboardArrowDownIcon />
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>

                            </div>
                        </div>

                        <TextField
                            hiddenLabel
                            placeholder="Search"
                            size="small"
                            fullWidth
                        />

                        <ul>
                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"}>
                                    <div className="list-selector-row">
                                        <div className="list-selector-colum-1">
                                            <span className="profile-name">SA</span>
                                        </div>
                                        <div className="list-selector-colum-2">
                                            <h6>Sai Abhishek <span>3 days ago</span></h6>
                                            <p>Attendance regularization - 09-Nov-2022</p>
                                            <p className="ellips-data-p">As par my schedule login time 08:00 PM & and all the best</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="advance-listing-colum-2">
                        <div className="advance-listing-colum-2-top">
                            <div className="advance-listing-colum-2-top-1">
                                <span className="profile-name">SA</span>
                            </div>
                            <div className="advance-listing-colum-2-top-2">
                                <h6>Sai Abhishek <span>3 days ago</span></h6>
                                <p>Attendance regularization - 09-Nov-2022</p>
                            </div>
                        </div>

                        <div className="advance-listing-colum-2-body">
                            <div className="date-display-type-row">
                                <div className="date-display-type-colum-1">
                                    <h6 className="month-type">Nov</h6>
                                    <h4 className="days-type">9</h4>
                                    <p>Wednesday</p>
                                </div>
                                <div className="date-display-type-colum-2">
                                    <p>Attendance Regularization request</p>
                                </div>
                            </div>

                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Actual Punch-In</StyledTableCell>
                                            <StyledTableCell >Actual Punch Out</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow >

                                            <StyledTableCell>09:00 AM</StyledTableCell>
                                            <StyledTableCell>07:15 PM</StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow >

                                            <StyledTableCell>10:00 AM</StyledTableCell>
                                            <StyledTableCell>08:00 PM</StyledTableCell>

                                        </StyledTableRow>

                                        <StyledTableRow >

                                            <StyledTableCell>09:00 AM</StyledTableCell>
                                            <StyledTableCell>07:15 PM</StyledTableCell>

                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className="advance-listing-body-comment-row">
                                <div className="advance-listing-boly-comment-short-name">
                                    <span>SA</span>
                                </div>

                                <div className="advance-listing-boly-comment-details">
                                    <h6>Sai Abhishek</h6>
                                    <p>As per my schedule login time 09:00 AM and logout time 05:00 PM But in RTMS arrival showing with late login kindly regualize my attendance request</p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-comment-section-row">
                            <div className="bottom-comment-section-form">
                                <TextField
                                    hiddenLabel
                                    placeholder="Comment"
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className="bottom-comment-section-form-buttons">
                                <button className="btn btn-message"><SendIcon /></button>
                                <button className="btn btn-approve">Approve</button>
                                <button className="btn btn-reject">Reject</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdvanceListing