import React, { useState } from 'react'
import './Workflows.scss'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { workFlowList, workflowStatusList, workflowStatusUpdate } from "../../redux/features/workflowSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import CustomButton from '../../components/button/CustomButton';
import LoaderForm from '../../components/common-function/LoaderForm';
import moment from 'moment';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import { Skeleton, Switch, Stack, Menu, MenuItem, Button } from "@mui/material";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import { DataGridPro, getGridSingleSelectOperators, getGridStringOperators, gridClasses } from '@mui/x-data-grid-pro';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import { Edit } from '@mui/icons-material';

const { REACT_APP_ENV } = process.env;

const SubWorkflowListComponent = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { workflows, status, status_List, work_flow_update } = useSelector(state => state.workflow)
  const [pageSize, setPageSize] = useState(25);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);
  const [openAssignment, setOpenAssignment] = useState(false)
  const [updatingAssignment, setUpdatingAssignment] = useState(false);
  const [workflowData, setWorkflowData] = useState({})
  const [editFormData, setEditFormData] = useState({})
  const [relatedStatusOption, setRelatedStatusOption] = useState([])
  const [status_RelatedStatusMapping, setStatus_RelatedStatusMapping] = useState([])
  const [finalStatusMapping, setFinalStatusMapping] = useState([])
  const [formData, setFormData] = useState({ ...workflowData } || {})
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [setRelatedFields] = useState(false)
  const whereClause = { ...params, "order_by": { "column": "-id" }, "skip": skip, "limit": pageSize || 25, "columns": DefaultFilterColumns.WORKFLOW_LIST_COLUMNS }
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
  );
  const filterOperatorsForSelect = getGridSingleSelectOperators().filter(({ value }) =>
    ['is'].includes(value),
  );

  const workflowColumns = [
    { field: "id", headerName: "ID", width: 70, filterOperators },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Workflow Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      sortable: false,
      pinnable: false,
      filterOperators,
      width: 275,
      renderCell: (params) => {
        return (
          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              {params.row.name ? params.row.name : "-"}
            </div>
        );
      },
    },
    {
      field: "code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      sortable: false,
      pinnable: false,
      filterOperators,
      width: 275,
      renderCell: (params) => {
        return (
          params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              {params.row.code ? params.row.code : "-"}
            </div>
        );
      },
    },
    {
      field: "description",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      sortable: false,
      pinnable: false,
      filterOperators,
      width: 275,
      renderCell: (params) => {
        return (
          params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div>
              {params.row.description ? params.row.description : "-"}
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 160,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      sortable: false,
      filterOperators: filterOperatorsForSelect,
      pinnable: false,
      renderCell: (params) => {
        return (
          // <div className={`cellWithStatus ${params.row.status}`} >
          //   <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          // </div>
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => deleteWorkflow(params.row)} />
              <div className="actionButton">
                <UcActionComponent
                  deleteIconName="ArchiveIcon"
                  moduleName="dynamic_table"
                  rowid={params?.row?.id}
                  addMoreData={params.row}
                  editLinkUrl=""
                  viewLinkUrl=""
                  additionalAction={[{ id: 1, key: "mapping", title: "Edit Mapping" }]}

                  // onAdditionalAction={onAdditionalAction}
                  // onViewDetail={onView}
                  // onEdit={handleEdit}
                  //deleteButton={deleteWorkflow}
                  //deleteRow={deleteWorkflow}
                  //isDelete={!!deleteWorkflow}
                  editButton={() => handleEdit(params.row)}
                  viewButton={onView}
                  extraButton={onAdditionalAction}
                />
              </div>
            </div>
        );
      },
    },
  ];

  const handleArchive = (id) => {
    if (id.status === true) {
    } else {
    }
  }
  const modalDetail = {
    title: "Workflows Details",
    editModalTitle: "Edit Workflow",
    addModalTitle: "Add Workflow",
    formInputs: [
      { id: 1, name: "name", label: "Workflow Name", placeholder: "Enter Workflow Name", type: "text", required: true, disabled: false },
      {
        id: 12, name: "status", label: "Status", placeholder: "Enter For Date", type: "select",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      { id: 3, name: "description", label: "Description", placeholder: "Enter description", type: "textarea", required: false, disabled: false },
    ],
  }


  const onSubmit = async (data) => {
    const finalData = workflowData.id ? { ...workflowData, ...data, updated_by: userId } : { ...data, created_by: userId }
    const created_updated_by = workflowData.id ? "updated_by" : "created_by"
    const res = await dataService.save_workflow({
      id: finalData.id,
      name: data.name,
      code: finalData.id ? finalData.code : data.name.replace(/ /g, "-").toLowerCase(),
      description: finalData.description,
      status: finalData.status,
      [created_updated_by]: userId
    });
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      setWorkflowData({})
      onCloseModal();
      // dispatch(workFlowList(whereClause));
      reloadListData(workFlowList ,JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 }));


    }
    else {
      toast.error(res.data.message)
    }
  }


  // Edit Shift
  const handleEdit = (data) => {
    setWorkflowData(
      {
        ...workflowData,
        id: data?.id,
        name: data?.name,
        code: data?.code,
        description: data?.description,
        status: data?.status,
        created_by: userId
      });
    setUpdating(true)
    setEditable(true);
    setOpen(true);
  }

  // Delete Workflow
  const deleteWorkflow = async (data) => {
    let addvalues = {
      code: data.code,
      description: data.description,
      name: data.name,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id,
      id: data.id
    }


    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    setReload(true)
    dispatch(workflowStatusUpdate({ ID, addvalues }))
  }

  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  AddHoliday Modal
  const onAddWorkflow = () => {
    setWorkflowData({});
    setOpen(true)
    setEditable(true);
  }

  //  Close Modal
  const onCloseModal = () => {
    setWorkflowData({});
    setUpdating(false);
    setOpen(false)
    setEditable(false);
    setContextMenu(null);
  }

  //  Close Modal
  const onCloseModalAssignment = () => {
    setOpenAssignment(false)
    setUpdatingAssignment(false);
    setEditFormData({});
    setWorkflowData({});
  }

  const handleChange = (evt) => {
    let eventValue = {}
    if (evt.type && evt.type === "date") {
      eventValue = { name: evt.name, type: evt.type, value: evt.format ? moment(evt.value).format(evt.format) : evt.value }
    }
    const { name, value, type, checked } = (evt && evt.target) ? evt.target : eventValue;
    const finalValue = type === "checkbox" ? checked : type === "select" ? value.value : value
    if (name === "workflow_status") {
      setStatus_RelatedStatusMapping(
        finalValue.map((option, index) => {
          return ({
            from_status: option,
            // eslint-disable-next-line
            relatedStatusOptions: finalValue.filter((e, indx) => {
              if (index !== indx) { return e }
            })
          })
        })
      )
      setFinalStatusMapping(
        finalValue.map((option, index) => {
          return ({
            from_status: option.id,
            related_status: []
          })
        })
      )
      setRelatedStatusOption(finalValue);
    }
    setFormData(updatingAssignment ? { ...workflowData, ...formData, [name]: finalValue } : { ...formData, [name]: finalValue })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalData = workflowData.id ? { ...workflowData, ...formData } : formData
    const created_updated_by = workflowData.id ? "updated_by" : "created_by"
    const postData = {
      id: finalData.id,
      "workflow": finalData.workflow?.value || editFormData.workflow_id,
      workflow_status: finalData?.workflow_status ? finalData.workflow_status.map(e => { return e.id }) : editFormData.workflow_status,
      status_mapping: finalStatusMapping.map(e => ({
        "workflow": e.workflow,
        "workflow_status": e.workflow_status,
        "is_initial": finalData.default_status?.value === e.workflow_status,
        "related_status": e.related_status,
        "created_by": e.created_by
      })),
      "status": (finalData?.status?.value === true || finalData?.status?.value === false) ? finalData.status.value : editFormData.status,
      [created_updated_by]: userId
    };
    if (!postData?.workflow_status || !postData?.workflow) {
      toast.error("Enter the mandatory details!")
      return;
    }

    // return;
    const res = await dataService.assign_project_workflow(postData);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      dispatch(workflowStatusList(whereClause))
      onCloseModalAssignment();
    }
    else {
      toast.error(res.data.message)
    }
    setFormData({})
  }


  const onView = (data) => {
    navigate("/project-workflows-mapping", { state: { ...data, is_editing: false, is_viewing: true } })
  }

  const onAdditionalAction = async (data, key) => {
    if (key === "mapping") {
      navigate("/edit-project-workflows-mapping", { state: { ...data, is_editing: true } })
    }
  }

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item?.id === selectedRow);
    switch (actionType) {
      case 'status':
        handleArchive(row);
        break;

      case 'edit':
        handleEdit(row);
        break;
      default:
        break
    }
    setContextMenu(null);
  }

  const handleClickArchive = (data) => {
    let addvalues = {
      name: data.name,
      code: data.code,
      description: data.description,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }

    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    setReload(true)
    dispatch(workflowStatusUpdate({ ID, addvalues }))
  }


  if (reload === true && status === `${STATUSES.SUCCESS}_workflow_update` && work_flow_update && work_flow_update.message) {
    if (work_flow_update.status === 200) {
      let str = JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length });
      setFilterQuery(str)
      toast.success(work_flow_update.message)
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(work_flow_update.message)
    }
    setReload(false)
  }

  //listing
  if (reload === true && status === STATUSES.SUCCESS && workflows.message) {
    if (workflows.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...workflows.data.rows].sort(item => item.status ? -1 : 1));
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(workflows.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...workflows.data.rows].sort(item => item.status ? -1 : 1));
        SET_MAX_ROW_LENGTH(workflows.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(workflows.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;

  }
  const reloadListData = (api, filter) => {
    setReload && setReload(true);
    clearGridListing && (clearGridListing.current = true);
    dispatch(api(filter))
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px", marginTop: "40px" }}>
        <div style={{ fontSize: "20px" }}>
          <p style={{ marginLeft: "10px" }}>All Workflows</p>
        </div>
        <div>
          <Stack direction="row">
            <Button variant="outlined" onClick={() => navigate("/project-workflows-mapping", { state: { is_editing: false } })} style={{ marginRight: '6px' }}>Add Mapping</Button>
            <Button variant="outlined" onClick={onAddWorkflow}>Create<BorderColorTwoToneIcon /></Button>
            <RefreshButton api={workFlowList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
          </Stack>
        </div>
      </div>
      {status === STATUSES.LOADING && <LoaderForm />}
      {
        !openAssignment ?
          <>
            {
              <RegularizeModal
                modalDetail={modalDetail}
                open={open}
                onOpen={onOpenModal}
                onClose={onCloseModal}
                onSubmit={onSubmit}
                hideBackdrop
                fullWidth
                maxWidth
                updating={updating}
                editData={workflowData}
                editable={editable}
                formLoading={formLoading}
                setFormLoading={setFormLoading}
                additionalActions={modalDetail.additionalActions}
              />
            }

            <DataGridPro
              className="datagrid"
              filterMode="server"
              loading={!!reload}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              sx={{
                height: 700,
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#438bf969",
                },
                ".actionButton": {
                  display: 'none'
                },
                [`& .${gridClasses.row}`]: {
                  "&:hover, &.Mui-hovered": {
                    ".actionButton": {
                      display: 'block'
                    }
                  }
                }
              }}
              rows={gridRows?.concat(skeletonRows)}
              columns={workflowColumns}
              filterModel={filterModal}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
              }
              components={{
                ColumnMenuIcon: MoreHorizIcon,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                }
              }}
              onFilterModelChange={onFilterChange}
              onRowsScrollEnd={handleOnRowScrollEnd}
              hideFooter
            />
          </>

          :
          <div>
            <div>
              <div>
                <form className="workflow-form" style={{ marginTop: "0", paddingBottom: "50px" }} onSubmit={handleSubmit}>
                  <div className="formInput" >
                    <label className='label-heading'>
                      {"Workflow*"}
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={"Select"}
                      required
                      isSearchable={true}
                      name={"workflow"}
                      options={workflows.length && workflows.map(e => ({ id: e.id, label: e.name, value: e.id, name: "workflow" }))}
                      onChange={(evt) => { handleChange({ target: { name: "workflow", value: evt } }) }}
                    />

                  </div>

                  <div className="formInput" >
                    <label className='label-heading'>
                      {"Workflow Status"}
                    </label>

                    <Multiselect
                      isObject={true}
                      onRemove={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                      onSelect={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                      displayValue={"label"}
                      // required
                      placeholder={"Select Workflow-Status"}
                      selectedValues={workflowData?.workflow_status}
                      // eslint-disable-next-line
                      options={status_List && status_List.map(e => {
                        if (!e.is_jira_project) {
                          return { id: e.id, label: e.name, value: e.id, name: "workflow_status" }
                        }
                      })}
                    />
                  </div>

                  <div className="formInput" >
                    <label className='label-heading'>
                      {"Default Workflow Status*"}
                    </label>
                    <Select
                      className="basic-single"
                      placeholder="Select"
                      isSearchable={true}
                      required
                      name={"default_status"}
                      defaultValue={workflowData.default_status || ""}
                      options={(relatedStatusOption.length && relatedStatusOption) || {}}
                      onChange={(evt) => { handleChange({ target: { name: "default_status", value: evt } }) }}
                    />
                  </div>
                  <div className="formInput" >
                    <label className='label-heading'>
                      {"Status*"}
                    </label>
                    <Select
                      className="basic-single"
                      placeholder="Select"
                      isSearchable={true}
                      required
                      name={"status"}
                      defaultValue={
                        (workflowData?.status === true || workflowData?.status === false) ?
                          { label: workflowData?.status ? "Active" : "Inactive", value: workflowData?.status } : ""}
                      options={
                        [
                          { id: 1, name: "Active", label: "Active", value: true },
                          { id: 2, name: "Inactive", label: "Inactive", value: false }
                        ]
                      }
                      onChange={(evt) => { setRelatedFields(true); handleChange({ target: { name: "status", value: evt } }) }}
                    />
                  </div>

                  <div className="formInput" ></div>

                  <div className="formInput" ></div>
                  <>
                    <div className="formInput" >
                      <label className='label-heading'>
                        {"From Status"}
                      </label>
                    </div>
                    <div className="formInput" >
                      <label className='label-heading'>
                        {"Related Status"}
                      </label>
                    </div>
                    {
                      // relatedFields &&
                      status_RelatedStatusMapping && status_RelatedStatusMapping.length &&
                      status_RelatedStatusMapping.map((element, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="formInput">
                              <Select
                                className="basic-single"
                                placeholder="Select"
                                disabled
                                required
                                name={"status"}
                                defaultValue={{ label: element.from_status.label }}
                                options={
                                  [
                                    { label: element.from_status.label },
                                  ]
                                }
                              />
                            </div>
                            <div className="formInput" >
                              <Multiselect
                                isObject={true}
                                displayValue={"label"}
                                placeholder={"Select Related-Status"}
                                options={element.relatedStatusOptions}
                              />

                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </>


                  <CustomButton
                    loading={false}
                    type={""}
                  />
                </form>
                <DeleteDialogModal
                  open={onOpenModal}
                  onClose={onCloseModal}
                  heading={'Delete ?'}
                  paragraph={'Are you sure to remove this credential'}
                  handleArchive={handleClickArchive}
                  moduleName={'AllResourceCredential'}
                />
              </div>
            </div>
          </div>
      }
      <Menu
        open={contextMenu !== null}
        onClose={onCloseModal}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              onCloseModal();
            }
          }
        }}
      >

        <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
      </Menu>
    </>

  )
}

const WorkflowListComponent = DataGridProLazyLoadHOC(SubWorkflowListComponent, { filterColumns: DefaultFilterColumns.WORKFLOW_LIST_COLUMNS, getDispatcherAsyncThunk: workFlowList, gridColumns: ['name', 'code', 'description', 'status'] });


export default WorkflowListComponent
