import React, { useEffect, useState } from "react";
import "./roles.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../redux/services/data.service";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Switch, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputFields } from "../../components/common-function/InputFields";
import { useDispatch } from "react-redux";
import { createRole, updateRole } from "../../redux/features/roleSlice";

const formErrData = Object.freeze({
    nameErr: 'Role name is required',
    keyErr: "Role key is required",
    descErr: "Description is required",
    statusErr: "Status is required",
    is_for_clientErr: "Is for Client is required"
});
const initialFormData = Object.freeze({
    role_name: "",
    role_key: "",
    role_description: "",
    status: true,
    is_for_client: false,

});

function AddRole({ title, ID, activePopupForm, setReload, flag }) {

    let { id } = useParams()
    id = ID!==undefined ? ID : id;
    const navigate = useNavigate();
    const [showloader, setShowLoader] = useState(false)
    const [formData, setFormData] = useState(initialFormData)
    const [formErrors, setFormErrors] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target;
        setFormData({ ...formData, [name]: name === 'status' || name === 'is_for_client' ? checked : value });
    }

    const loadDataById = async () => {
        setShowLoader(true)
        const response = await dataService.roleById(id).catch(error => {
            setShowLoader(false)
            return error;
        })
        if (response && response.data && response.data.data) {
            setFormData(response.data.data)
            setShowLoader(false)
        } else {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        id && loadDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const onSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, };
        setShowLoader(true)
        if (!formData.role_name || !formData.role_key || !formData.role_description || formData.status === "" || formData.is_for_client === "") {
            setErrStatus(true)
        }
        if (formData.role_name !== "" && formData.role_key !== "" && formData.role_description !== "" && formData.status !== "" && formData.is_for_client !== "") {
            setErrStatus(false)
            setFormErrors("")
            setReload && setReload(true);

            // const response = !id ? await dataService.addRole(request).catch(error => {
            //     setShowLoader(false)
            //     return error;
            // }) : await dataService.updateRole(id, request);
            flag = 1; 
            !id ? dispatch(createRole(request)).catch(error => {
                setShowLoader(false)
                return error;
            }) : dispatch(updateRole({ID:id, addvalues:request}));

            // if (response && response.data && response.data.status === 200) {
            //     toast.success(response.data.message);
            //     setTimeout(() => {
            //         navigate('/roles')
            //     }, 1000)
            // } else {
            //     toast.error(response.data.message)
            // }
        }
        setTimeout(()=>{
            setShowLoader(false)
        },500)
    }

    if(!!activePopupForm){
        return <>
            {
                showloader ? <LoaderForm />
                : <form onSubmit={onSubmit}>
                    <div className="add-new-user-form">
                        <div style={{width:'100%'}}>
                            <InputFields
                                label={'Role Name'}
                                name="role_name"
                                defaultValue={formData.role_name}
                                type="text"
                                placeholder="Role name"
                                onChange={handleChange}
                                errStatus={errStatus}
                                formValues={formData}
                                formErr={formErrors.nameErr}
                            />
                        </div>

                        <div style={{width:'100%'}}>
                            <InputFields
                                label={'Role Key'}
                                name="role_key"
                                defaultValue={formData.role_key}
                                type="text"
                                placeholder="Role key"
                                onChange={handleChange}
                                errStatus={errStatus}
                                formValues={formData}
                                formErr={formErrors.keyErr}
                            />
                        </div>

                        <div style={{width:'100%'}}>
                            <InputFields
                                label={'Description'}
                                name="role_description"
                                defaultValue={formData.role_description}
                                type="text"
                                placeholder="Description"
                                onChange={handleChange}
                                errStatus={errStatus}
                                formValues={formData}
                                formErr={formErrors.descErr}
                            />
                        </div>

                        <div className="formInput">
                            <FormControl>
                                {/* <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="status"
                                    value={formData.status}
                                    onChange={(e) => { handleChange(e) }}
                                >
                                    <FormControlLabel value={"true"} control={<Radio />} label="Active" />
                                    <FormControlLabel value={"false"} control={<Radio />} label="Inactive" />
                                </RadioGroup> */}
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight: "10px", marginTop: "6px", fontWeight: "500"}}>Status</label>
                                    <p>InActive</p>
                                    <Switch name="status" checked={formData.status} onChange={handleChange}/>
                                    <p>Active</p>
                                </div>

                                {errStatus === true && formData && formErrors && formErrors.statusErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                            </FormControl>
                        </div>
                        <div className="formInput">
                            <FormControl>
                                {/* <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="is_for_client"
                                    value={formData.is_for_client}
                                    onChange={(e) => { handleChange(e) }}
                                >
                                    <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                </RadioGroup> */}
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight: "10px", marginTop: "6px", fontWeight: "500"}}>Is For Client</label>
                                    <p>InActive</p>
                                    <Switch name="is_for_client" checked={formData.is_for_client} onChange={handleChange}/>
                                    <p>Active</p>
                                </div>
                                {errStatus === true && formData && formErrors && formErrors.is_for_clientErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.is_for_client === "" ? formErrors.is_for_clientErr : ""}</p>}

                            </FormControl>
                        </div>
                        <div className="formInput"></div>
                    </div>
                    <button className ={showloader === true ? 'btn-disable':'submit-modal'} disabled={showloader === "submit" ? true :false } style={{float:'right'}}>Submit</button>
                </form>
            }
        </>
    }

    return (
        <div className="new">
            <div className="newContainer">
                <div className="top-headings">
                    <h3>{title} </h3>
                    <Tooltip title="Back">
                        <Link to={"/roles"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                <ToastContainer />
                {showloader ? <LoaderForm />
                    : <form onSubmit={onSubmit}>
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <InputFields
                                    label={'Role Name'}
                                    name="role_name"
                                    defaultValue={formData.role_name}
                                    type="text"
                                    placeholder="Role name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.nameErr}
                                />
                            </div>

                            <div className="formInput">
                                <InputFields
                                    label={'Role Key'}
                                    name="role_key"
                                    defaultValue={formData.role_key}
                                    type="text"
                                    placeholder="Role key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.keyErr}
                                />
                            </div>

                            <div className="formInput">
                                <InputFields
                                    label={'Description'}
                                    name="role_description"
                                    defaultValue={formData.role_description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formValues={formData}
                                    formErr={formErrors.descErr}
                                />
                            </div>

                            <div className="formInput">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => { handleChange(e) }}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio />} label="Active" />
                                        <FormControlLabel value={"false"} control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                    {errStatus === true && formData && formErrors && formErrors.statusErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                </FormControl>
                            </div>
                            <div className="formInput">
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is For Client</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_for_client"
                                        value={formData.is_for_client}
                                        onChange={(e) => { handleChange(e) }}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {errStatus === true && formData && formErrors && formErrors.is_for_clientErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formData.is_for_client === "" ? formErrors.is_for_clientErr : ""}</p>}

                                </FormControl>
                            </div>
                            <div className="formInput"></div>
                        </div>
                        <button className ={showloader === true ? 'btn-disable':'submit-modal'} disabled={showloader === "submit" ? true :false }>Submit</button>
                    </form>
                }
            </div>
        </div>
    )
}
export default AddRole