import { SvgIcon } from "@mui/material";

export default function AnniversaryIcon() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="25pt" height="25pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#438BF9FF" stroke="none">
                <path d="M3647 5015 c-88 -33 -167 -117 -197 -209 -20 -61 -9 -181 23 -241 16
-32 93 -119 219 -248 l193 -197 55 0 54 0 198 198 c231 232 252 265 252 392 0
124 -60 227 -166 285 -42 23 -68 30 -134 33 -72 4 -88 1 -142 -23 l-61 -27
-48 23 c-59 29 -187 37 -246 14z m176 -147 c12 -6 38 -24 57 -40 46 -37 79
-36 130 7 50 41 74 49 139 43 62 -6 103 -36 131 -94 28 -56 22 -121 -15 -174
-13 -19 -92 -102 -174 -184 l-150 -150 -163 168 c-90 93 -168 180 -175 195 -7
14 -12 49 -13 77 0 59 26 105 79 141 38 26 116 31 154 11z"/>
                <path d="M2595 4370 c-73 -11 -139 -43 -193 -94 l-47 -44 -125 -3 c-118 -4
-129 -6 -192 -37 -74 -36 -139 -101 -175 -173 -34 -69 -43 -155 -43 -434 l0
-261 -70 -11 c-149 -23 -209 -108 -210 -295 0 -111 15 -168 57 -217 47 -56 83
-73 177 -86 l79 -10 22 -65 c36 -106 87 -191 164 -272 29 -31 35 -46 36 -83
l1 -45 -126 0 c-69 0 -143 -5 -165 -11 -51 -14 -100 -65 -114 -117 -11 -39
-14 -42 -47 -42 l-35 0 27 46 c24 41 27 59 32 162 7 166 -7 222 -68 283 -53
53 -88 65 -170 57 -70 -7 -126 -46 -156 -111 -21 -47 -24 -65 -24 -190 0 -141
9 -188 47 -229 15 -17 14 -18 -30 -18 l-47 0 0 253 c0 241 -1 254 -20 275 -14
15 -33 22 -57 22 -31 0 -45 -10 -114 -79 -69 -69 -79 -83 -79 -114 0 -60 71
-102 108 -65 9 9 12 -22 12 -139 l0 -151 -178 -4 c-192 -3 -209 -8 -249 -66
-16 -24 -18 -57 -21 -350 l-3 -322 -90 0 c-103 0 -139 -13 -169 -63 -19 -30
-20 -52 -20 -328 l0 -296 -38 -39 c-32 -34 -46 -63 -68 -142 -2 -8 -25 -12
-67 -12 -109 0 -147 -47 -147 -180 0 -96 16 -137 63 -165 32 -20 67 -20 2377
-20 2224 0 2347 1 2373 18 62 40 62 37 67 435 l5 364 38 13 c22 7 50 24 64 37
51 48 57 71 61 251 5 201 -2 265 -31 309 -36 53 -80 76 -158 84 -69 7 -70 7
-95 51 l-26 44 75 75 c162 160 258 351 293 579 22 143 15 696 -10 805 -79 340
-337 630 -660 740 -120 41 -195 53 -331 53 -218 1 -396 -56 -580 -184 l-70
-48 -5 277 c-5 255 -7 280 -25 312 -12 19 -36 43 -55 55 -33 19 -52 20 -380
21 -190 1 -367 -2 -395 -6z m703 -367 c2 -205 -2 -247 -23 -214 -14 24 -130
91 -156 91 -43 0 -71 -33 -67 -78 3 -32 10 -42 66 -81 69 -48 123 -113 143
-174 11 -33 10 -42 -4 -65 -34 -53 -85 -158 -106 -221 -47 -138 -52 -182 -58
-514 l-5 -318 -50 -39 c-197 -156 -466 -183 -698 -69 -163 81 -295 246 -346
434 -21 77 -32 551 -16 673 26 192 131 305 332 361 99 27 220 41 352 41 95 0
110 2 128 20 27 27 26 81 -1 106 -19 17 -37 19 -196 18 l-174 -1 7 46 c15 105
85 182 184 202 19 4 181 6 360 5 l325 -1 3 -222z m-1022 25 c-3 -29 -6 -62 -6
-74 0 -16 -10 -24 -42 -34 -60 -17 -146 -62 -201 -104 -26 -20 -50 -36 -54
-36 -11 0 6 126 23 169 18 47 83 107 129 120 16 5 59 10 94 10 l64 1 -7 -52z
m2043 -259 c300 -76 536 -313 623 -624 22 -76 23 -97 22 -435 0 -336 -1 -359
-22 -435 -44 -158 -125 -296 -240 -409 -65 -63 -64 -63 -121 -14 l-32 28 73
72 c162 159 238 340 238 566 0 118 -13 142 -74 142 -59 0 -72 -29 -79 -167 -8
-180 -61 -307 -179 -425 -107 -107 -276 -178 -423 -178 -138 0 -295 64 -400
161 -82 78 -131 152 -169 259 l-31 85 -3 304 -4 303 54 -8 c29 -5 118 -9 198
-9 120 1 159 5 225 23 89 26 196 76 268 128 26 19 51 34 55 34 4 0 24 -12 43
-26 72 -54 204 -112 327 -144 40 -10 42 -12 42 -49 0 -54 31 -91 76 -91 56 0
74 27 74 110 0 117 -26 145 -157 176 -97 22 -205 69 -272 119 -79 58 -94 65
-139 65 -32 0 -53 -10 -108 -51 -180 -134 -376 -176 -625 -133 -93 16 -98 16
-134 -2 -22 -11 -46 -34 -57 -54 -18 -33 -19 -57 -15 -370 3 -309 5 -341 26
-415 37 -132 93 -227 197 -333 l91 -92 -43 -36 c-53 -43 -57 -41 -145 54 -104
113 -182 261 -215 411 -10 44 -17 150 -21 322 -8 378 10 515 93 683 181 366
589 555 983 455z m-2499 -755 l0 -157 -42 7 c-70 11 -83 31 -86 139 -3 101 7
133 48 154 14 7 37 13 53 13 l27 0 0 -156z m-333 -574 c16 -32 17 -213 2 -243
-23 -42 -57 -49 -89 -17 -18 18 -20 33 -20 139 0 97 3 123 16 136 25 24 76 16
91 -15z m903 -300 c47 -16 97 -30 113 -30 15 0 27 -4 27 -9 0 -12 -82 -141
-89 -141 -3 0 -36 48 -73 105 -37 58 -66 105 -65 105 1 0 40 -14 87 -30z m512
-75 c-37 -57 -70 -104 -73 -105 -8 0 -89 128 -89 141 0 5 12 9 28 9 15 0 63
13 107 29 44 16 83 29 87 30 4 1 -23 -46 -60 -104z m-633 -120 c92 -144 122
-175 171 -175 31 0 75 26 99 57 l20 28 1 -76 c0 -90 19 -119 75 -119 53 0 75
36 75 124 l1 71 20 -28 c24 -31 68 -57 99 -57 48 0 78 30 171 175 l94 145 41
0 c39 0 42 -2 60 -42 35 -78 113 -187 194 -272 44 -46 80 -86 80 -90 0 -3 -11
-24 -25 -46 -33 -52 -63 -127 -86 -215 -17 -64 -19 -116 -19 -627 l0 -558
-315 0 -315 0 0 559 c0 550 0 559 -21 585 -27 34 -79 36 -109 4 -20 -21 -20
-32 -20 -511 l0 -489 -35 31 c-34 29 -41 31 -115 31 -79 0 -80 0 -86 27 -9 45
-35 101 -55 119 -18 16 -19 38 -19 328 0 291 -1 313 -20 343 -30 50 -66 63
-169 63 l-90 0 -3 324 c-3 311 -4 326 -24 353 -23 31 -54 49 -97 58 -15 3 -25
10 -22 15 4 6 78 10 178 10 l172 0 94 -145z m-449 -325 l0 -290 -100 0 -100 0
-38 42 c-65 72 -148 94 -236 63 -32 -11 -44 -11 -78 1 -22 8 -57 14 -77 14
-52 0 -134 -42 -166 -84 l-27 -36 -124 0 -124 0 0 80 0 80 230 0 c246 0 271 4
290 49 12 30 1 73 -22 88 -7 4 -121 10 -253 13 l-240 5 -3 27 -3 27 240 3
c232 3 241 4 262 25 27 27 29 75 4 103 -17 18 -33 20 -259 22 l-241 3 -3 28
-3 27 535 0 536 0 0 -290z m1976 23 c40 -195 52 -203 312 -203 264 0 272 5
311 209 l23 121 28 -20 c49 -35 116 -105 153 -160 33 -49 35 -54 21 -75 -26
-40 -34 -85 -23 -134 6 -31 7 -62 1 -89 -6 -26 -6 -58 0 -84 5 -26 5 -58 0
-85 -17 -78 11 -147 76 -188 l31 -20 1 -337 0 -338 -24 0 -25 0 -3 314 c-3
292 -4 315 -22 335 -26 29 -86 29 -112 0 -18 -20 -19 -43 -22 -335 l-3 -314
-394 0 -394 0 -3 314 c-3 292 -4 315 -22 335 -26 29 -86 29 -112 0 -18 -20
-19 -43 -22 -335 l-3 -314 -40 0 -39 0 0 548 c0 608 0 604 67 742 27 57 56 95
120 159 46 45 87 81 90 77 3 -3 16 -58 29 -123z m259 101 c33 -3 98 -1 144 6
45 6 85 10 86 8 2 -2 -4 -39 -13 -83 l-17 -80 -147 -3 c-146 -3 -148 -2 -153
20 -34 150 -34 152 8 145 17 -4 59 -9 92 -13z m839 -320 c3 -9 6 -89 6 -179 0
-187 -3 -195 -74 -195 -60 0 -72 18 -41 60 32 43 32 71 -1 109 l-26 31 26 31
c32 38 33 69 2 106 -34 41 -24 53 44 53 44 0 59 -4 64 -16z m-3644 -149 c19
-14 45 -25 58 -25 14 0 40 11 58 25 43 33 77 32 103 -1 37 -47 28 -67 -69
-167 l-90 -92 -90 91 c-74 75 -90 96 -90 122 0 36 33 72 65 72 12 0 36 -11 55
-25z m-250 -137 c11 -24 73 -97 138 -164 125 -129 150 -144 207 -119 47 19
259 243 274 288 l13 37 234 0 234 0 0 -250 c0 -231 -1 -250 -17 -250 -10 -1
-43 -9 -73 -20 -54 -18 -56 -18 -115 2 -67 22 -119 20 -179 -8 -34 -17 -37
-16 -78 2 -54 24 -140 24 -191 0 -37 -18 -39 -18 -80 0 -53 24 -150 25 -194 1
-31 -17 -35 -16 -95 4 -70 23 -122 21 -182 -8 -34 -16 -38 -16 -72 0 -60 29
-112 31 -179 9 -59 -20 -61 -20 -115 -2 -40 14 -56 24 -58 39 -3 21 -1 21 237
21 228 0 242 1 261 20 22 22 26 60 10 90 -18 33 -66 40 -291 40 l-219 0 0 30
0 30 237 0 c229 0 237 1 260 22 28 26 30 67 4 99 -19 24 -21 24 -260 27 l-241
3 0 50 0 49 255 0 255 0 20 -42z m-518 -620 c12 -9 15 -22 11 -40 -5 -27 -8
-28 -64 -28 -62 0 -72 10 -46 42 28 36 72 47 99 26z m266 6 c22 -6 28 -27 16
-58 -5 -12 -21 -16 -65 -16 -32 0 -59 3 -59 8 0 16 33 54 54 62 27 11 26 11
54 4z m281 -3 c13 -7 16 -18 13 -40 -4 -31 -5 -31 -63 -31 -51 0 -59 3 -59 18
0 42 68 74 109 53z m275 -4 c13 -9 16 -20 11 -40 -7 -26 -10 -27 -66 -27 -61
0 -71 9 -46 41 28 37 71 48 101 26z m264 7 c19 -5 23 -12 20 -38 -3 -31 -4
-31 -65 -34 -35 -2 -63 1 -63 5 0 16 33 55 54 63 27 11 26 11 54 4z m284 -6
c12 -9 15 -22 11 -40 -5 -27 -8 -28 -64 -28 -62 0 -72 10 -46 42 28 36 72 47
99 26z m301 -15 c9 -9 17 -24 17 -35 0 -16 -9 -18 -80 -18 -44 0 -80 3 -80 8
0 15 33 54 54 62 26 11 65 4 89 -17z m267 -233 l0 -30 -1135 0 -1135 0 0 30 0
30 1135 0 1135 0 0 -30z"/>
                <path d="M2263 3290 c-33 -13 -43 -42 -43 -124 0 -69 2 -76 26 -95 34 -26 68
-27 94 -1 26 26 33 59 28 126 -6 76 -51 115 -105 94z"/>
                <path d="M2949 3289 c-37 -14 -52 -56 -47 -135 3 -55 8 -70 27 -85 30 -25 62
-24 95 2 24 19 26 26 26 99 0 60 -4 82 -17 98 -23 26 -51 33 -84 21z"/>
                <path d="M2625 3210 c-35 -13 -47 -37 -52 -100 -6 -58 -8 -61 -35 -67 -55 -11
-77 -84 -38 -123 16 -16 33 -20 86 -20 62 0 68 2 100 34 l34 34 0 104 c0 87
-3 107 -17 120 -24 21 -54 28 -78 18z"/>
                <path d="M2343 2758 c-49 -23 -46 -85 8 -164 106 -155 336 -185 489 -64 41 32
96 112 101 145 6 39 -10 72 -41 85 -38 16 -71 3 -96 -37 -51 -81 -104 -116
-179 -116 -61 0 -103 20 -141 68 -78 97 -93 106 -141 83z"/>
                <path d="M3742 2807 c-18 -19 -22 -35 -22 -93 0 -84 20 -114 75 -114 51 0 75
36 75 115 0 48 -5 68 -21 89 -27 34 -77 36 -107 3z"/>
                <path d="M4369 2811 c-21 -17 -24 -27 -24 -94 0 -89 17 -117 72 -117 56 0 73
27 73 115 0 62 -4 79 -20 95 -25 25 -71 26 -101 1z"/>
                <path d="M4045 2685 c-21 -20 -25 -34 -25 -80 0 -70 21 -112 65 -131 52 -21
92 -18 120 11 34 33 32 70 -5 105 -16 15 -30 33 -30 39 0 24 -24 60 -47 70
-36 16 -51 14 -78 -14z"/>
                <path d="M3870 2353 c-74 -27 -63 -118 26 -205 55 -56 116 -82 201 -86 81 -5
127 7 194 49 85 53 144 172 108 215 -39 47 -93 36 -136 -28 -41 -62 -85 -88
-147 -88 -57 0 -103 23 -132 66 -49 71 -77 90 -114 77z"/>
            </g>
        </svg >
    );
}
