import { Dialog, DialogContent } from '@mui/material'
import React from 'react'

const Confirmation = ({open, handleClose, handleCancel, onConfirm, dialogTitle, dialogQuestion }) => {
  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">

    <DialogContent>
      <div className="popup-confirming">
        <h3>{ dialogTitle}</h3>
        <p>{ dialogQuestion}</p>
        <button className="yes" onClick={onConfirm}>Yes</button>
        { handleCancel &&
            <button className="no" onClick={handleCancel} autoFocus>
                Cancel
            </button>
        }
      </div>
    </DialogContent>

  </Dialog>
  )
}

export default Confirmation