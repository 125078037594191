import React from 'react'
import "./error404.scss";
import { Link } from "react-router-dom";





const Error404 = () => {

    return (

        <section className="page_404">
            <div className="four_zero_four_bg">
                <h1 className="text-center ">Error <span>404</span></h1>
            </div>

            <div className="contant_box_404">
                <h3 className="h2">
                    Look like you're lost
                </h3>

                <p>The page you are looking for not avaible!</p>

                <Link to={"/"} className="link_404">Go to Dashboard</Link>
            </div>
        </section>
    )
}

export default Error404
