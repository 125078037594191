import './emailTemplate.scss';
import { AttachFile, Close } from '@mui/icons-material'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack, Tooltip, Paper, TextField, FormControlLabel, Radio } from '@mui/material'
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getCategoryListEmail, sendEmail } from '../../redux/features/emailSlice';
import Select from "react-select";
import LocalStorageDataService  from "../common-function/GetLocalstorage";
import LoaderForm from '../common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';
import { ZoomCreate } from '../zoom-create/ZoomCreate';
import { createZoomMeeting } from '../../redux/features/zoomSlice';
import CreatableSelect from 'react-select/creatable';
import { interviewerListOption, interviewerTechnolgyListOption, resourceListOption, skilOption } from '../../redux/features/optionsSlice';
import moment from 'moment';
import { DefaultFilterColumns } from '../common-function/DefaultFilterColumns';
import { styled } from '@mui/material/styles';
import { getInterviewScheduleStatusList, interviewScheduleStatusCreate, getIndustryList, getIndustryDepartmentList, getIndustryDepSpecList, getIndsDepSpecSkill, getMeetingProvider } from '../../redux/features/masterSlice';
import dataService from '../../redux/services/data.service';
import AlReadyInterviewSchedule from './AlReadyInterviewSchedule';
import { getAllClientResourceList } from '../../redux/features/clientResourceSlice';
import { getCommentId } from '../../redux/features/taskSlice';
import { resourceList } from '../../redux/features/resourceSlice';

const statusToBeShowInterview = {
    Done: true,
    Close: true,
    Interrupted: true,
    Cancelled: true,
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));
// const initialFormData = Object.freeze({

// });
const formErrData = Object.freeze({
    toErr: 'To is required',
    subjectErr: "Subject is required",
    bodyErr: "Body is required",
    meetingyErr: "Meeting url is required",
    statusErr: 'Interview status url is required',
    meetingProviderErr: 'Meeting Provider is required',
});

function timeToMins(time) {
    let b = time.split(':');
    return b[0] * 60 + +b[1];
}

// Convert minutes to a time in format hh:mm
// Returned value is in range 00  to 24 hrs
function timeFromMins(mins) {
    function z(n) { return (n < 10 ? '0' : '') + n; }
    let h = (mins / 60 | 0) % 24;
    let m = mins % 60;
    return z(h) + ':' + z(m);
}

// Add two times in hh:mm format
function addTimes(t0, t1) {
    return timeFromMins(timeToMins(t0) + timeToMins(t1));
}



const EmailTemplet = ({ setShowEmailModel, to_email, project_id, data, story_id, module, shiftTime, interviewer_status, interviewerFormValues, interviewUpdateApi, setInterviewReload }) => {
    const initialFormData = Object.freeze({
        mail_type: "html",
        to: to_email || [],
        cc: [""],
        subject: module === 'interviewer' ? 'Create new interview schedule' : "",
        body: module === 'interviewer' ? 'Create new interview schedule' : "",
        from: "jobs@unicodesystems.in",
        epic_id: data?.epic_id,
        story_id: story_id,
    });

    const user = LocalStorageDataService.userDetail()

    const { resources, interviewers_shift, interviewers, interviewers_tech } = useSelector(state => state.dropdownOptions)
    const { clientresource_AllList } = useSelector(state => state.clientResourceSlice)
    const { industry_List, indsDepSpecSkill_List, meetingprovider_list } = useSelector(state => state.master)
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [updatedSkillsList, setupdatedSkillsList] = useState([])
    // const { status, error, send_email, categoryListEmail, emailTemplateMaster, subject, body } = useSelector(state => state.emailSlice)
    const { status, error, send_email } = useSelector(state => state.emailSlice)
    const { create_zoom_meeting, } = useSelector(state => state.zoomSlice)
    const { interview_schedule_option_List, industry_department_List } = useSelector(state => state.master)

    const [reload, setReload] = useState(false)
    const [validateErr, setValidateErr] = useState("")
    const [bodyData, setBodyData] = useState(module === 'interviewer' ? 'Create new interview schedule' : "")

    const [showSubmitBtn, setShowSubmitBtn] = useState(true)
    // const [emailTemplatesId, setEmailTemplatesId] = useState(0)
    // const [categoryId, setCategoryId] = useState(0)
    // const [subjectData, setSubjectData] = useState("")

    const [showZoomModel, setShowZoomModel] = useState(false)
    // file uploading states
    const MAX_COUNT = 10;
    const uploadInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileErr, setFileErr] = useState("")
    const [copyBodyData, setCopyBodyData] = useState(false);
    const [blankBody, setBlankBody] = useState(false)
    const [ccEmail, setCCEmail] = useState([""])
    const [showInterviewSchedule, setShowInterviewSchedule] = useState(false)
    const [selectedTimezone,] = useState({
        "value": "Asia/Kolkata",
        "label": "(GMT 5:30) Chennai, Kolkata, Mumbai, New Delhi",
        "offset": 5.5, "abbrev": "IST",
        "altName": "India Standard Time"
    })

    const [dateAndTime, setDateAndTime] = useState("")
    const [selectInterviewer, setSelectInterviewer] = useState("")
    const [meetingUrl, setMeetingUrl] = useState("")
    const [avlStatus, setAvlStatus] = useState(false)
    const [scheduledTime, setScheduledTime] = useState("")
    //const [slotModal, setSlotsModal] = useState(false)
    const [slotArray, setSlotArray] = useState([])
    const [flag, setFlag] = useState(false)
    const [selectDueration, setSelectDueration] = useState({ value: 30, label: 30 })
    const [selectedSlotsIs, setSelectedSlotsIs] = useState([])
    const [singleSlot, setSingleSlot] = useState("")
    const [bookedSlots, setBookedSlots] = useState([])
    // const [showShiftTime, setShowShiftTime] = useState("")
    const [, setShowShiftTime] = useState("")

    const [selectTech, setSelectTech] = useState([])

    const [alreadyScheduleMessage, setAlreadyScheduleMessage] = useState(false)
    const [showCheckboxAndMsgBoth, setshowCheckboxAndMsgBoth] = useState(false)
    const [interviewStatus, setInterviewStatus] = useState(module === 'interviewer' ? { value: interviewer_status[0].id, label: interviewer_status[0].status_name } : "")
    const [mangageAvalList, setMangageAvalList] = useState([])
    const [selectedSlotsForSubmit, setSelectedSlotsForSubmit] = useState([])
    const [slotLoading, setSlotLoading] = useState(false)
    const [selectedGapSlotsIs, setSelectedGapSlotsIs] = useState([])
    const [zoomMeetinStatus, setZoomMeetinStatus] = useState(false)
    const [googleMeetinStatus, setGoogleMeetinStatus] = useState(false)
    const [skypeMeetingStatus, setSkypeMeetingStatus] = useState(false)
    const [otherMeetingStatus, setOtherMeetingStatus] = useState(false)
    const [inPersonStatus, setInPersonStatus] = useState(false)
    const [meetingProvider, setMeetingProvider] = useState('')

    const [selectedSingleGapSlots, setSelectedSingleGapSlotsIs] = useState([])
    const [selectedGapSlotsForSubmit, setSelectedGapSlotsForSubmit] = useState([])
    const [alReadyScheduleData, setAlreadyScheduleData] = useState([])

    const [isInternalInterviewer, setIsInternalInterviewer] = useState(false)
    const [isClientInterviewer, setIsClientInterviewer] = useState(false)
    const [clientInterviewer, setClientInterviewer] = useState("")
    const [isRandomInterviewer, setIsRandomInterviewer] = useState(false)
    const [selectInterviewerKey, setSelectInterviewerKey] = useState(0);
    const [caneledInterivewSchedule,setCaneledInterivewSchedule]=useState([])

    const { resouceListInterviewer } = useSelector(state => state.resource)

    const roleWiseFilter = `{"filter":{"is_interviewer":{"value":True,"operation": "eq"}}, "order_by":{"column":"-id"},"no_limit":True,"columns":["id","name","email","is_interviewer","reporting_manager"]}`
    // const [filterQuery, setFilterQuery] = useState(roleWiseFilter)




    const nothing = () => { 
        return;
    }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        // eslint-disable-next-line
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length > MAX_COUNT) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of ${MAX_COUNT} files`)
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }

    const onFileChange = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        handleUploadFiles(chosenFiles);
    }

    function deleteFile(e) {
        const s = uploadedFiles.filter((item, index) => index !== e);
        setUploadedFiles(s);
    }

    if (copyBodyData === false && create_zoom_meeting && create_zoom_meeting.message) {
        setCopyBodyData(true)
        setBodyData("")
        // setMeetingUrl("")
        if (create_zoom_meeting.status === 200) {
            if (create_zoom_meeting?.data) {
                setBlankBody(true)
                let body_data = bodyData + "<br/>" + create_zoom_meeting.data.html_zoom_details
                setBodyData(body_data)
                let url = create_zoom_meeting.data?.join_url
                setMeetingUrl(url)
                setZoomMeetinStatus(true)
                setGoogleMeetinStatus(false)
            }
        } else {
            toast.error(create_zoom_meeting.message)
        }
    }
    useEffect(() => {
        const skillsList = indsDepSpecSkill_List?.data?.rows.map((options) => options.skill) ?? []
        setupdatedSkillsList(skillsList)
    }, [indsDepSpecSkill_List])

    useEffect(() => {
        if (module === 'interviewer' && data) {
            setShowShiftTime(shiftTime)
            // setSelectInterviewer({ value: data.interviewer_id, label: data.interviewer && data.interviewer.display_name })
            setSelectInterviewer({ value: data.interviewer_id, label: data.interviewer__display_name })
            setShowInterviewSchedule(true)
        }
        dispatch(getCategoryListEmail(project_id));
        dispatch(resourceListOption())
        dispatch(getInterviewScheduleStatusList(`{"filter":{"status":{"value":"True", "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":"True"}`))
        dispatch(interviewerListOption({ "filter": { "is_interviewer": { "value": "True", "operation": "eq" }, "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "name" }, "no_limit": "True", "columns": DefaultFilterColumns.RESOURCE_ROLES_DROUPDOWN_COLUMNS }))
        dispatch(skilOption())
        dispatch(getIndsDepSpecSkill(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getMeetingProvider(`{"filter":{"status":{"value":"True", "operation":"eq"}} ,"no_limit":"True"}`))

        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false);
        setFormValues(initialFormData);
        setBlankBody(false)
        setBodyData("")
        // setSubjectData("")
        setShowEmailModel(false)
        let ID = '--1';
        dispatch(createZoomMeeting({ ID }))
        setMangageAvalList([])
        setSlotArray([])
        setAlreadyScheduleData([])
    }

    if (reload === "submit" && send_email && send_email.message) {
        setReload(false)
        if (send_email.status === 200) {
            toast.success(send_email.message)
            dispatch(getCommentId(story_id));
            handleClose()

        } else {
            toast.error(send_email.message)
        }
        setShowSubmitBtn(true)
    }


    const inputHandler = (evt) => {
        let newContent = evt.editor.getData();
        setBodyData(newContent)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === "to") {
            let array = value.split(",");
            setFormValues({ ...formValues, [name]: array });
        }
        else if (name === "industry") {
            const queary = `{"filter":{"industry_id":{"value":${value ?? ""},"operation": "eq"},"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`
            dispatch(getIndustryDepartmentList(queary))
            setSelectTech([])
            setFormValues({ ...formValues, [name]: evt, department: "" })

        }
        else if (name === "department") {
            let queary = `{"filter":{"industry_id":{"value":${formValues.industry.value ?? ""},"operation": "eq"} ,"department_id":{"value":${value ?? ""},"operation": "eq"} ,"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`
            dispatch(getIndustryDepSpecList(queary))
            setFormValues({ ...formValues, [name]: evt })
            setSelectTech([])
        }
        else {
            setFormValues({ ...formValues, [name]: value });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (formValues.to === "" || formValues.to[0] === "" || bodyData === "" || formValues.subject === "") {
            setErrStatus(true)
        }
        const candidate_id = module === 'interviewer' ? [data.candidate_id] : data?.candidate_info && data.candidate_info.length > 0 && data.candidate_info.map((ele) => {
            return (ele.id)
        })
        if (formValues.to !== "" && formValues.subject !== "" && bodyData !== "") {
            let addvalues = { ...formValues, body: bodyData, cc: ccEmail }
            let flag = true

            if (showInterviewSchedule === true) {
                addvalues['is_interview_gap_allowed'] = false;
                if (selectedSlotsForSubmit && selectedSlotsForSubmit.length > 0) {
                    let length = selectedSlotsForSubmit.length - 1
                    addvalues['scheduled_time'] = convertTime12to24IsChange(selectedSlotsForSubmit[0])
                    addvalues['scheduled_end_time'] = convertTime12to24IsChange(selectedSlotsForSubmit[length])
                    //addvalues['booked_slots'] = [{ scheduled_time: convertTime12to24Is(selectedSlotsIs[0]), scheduled_end_time: convertTime12to24Is(selectedSlotsIs[length]) }]
                }
                if (selectedSlotsForSubmit && selectedSlotsForSubmit.length === 0) {
                    if (scheduledTime) {
                        flag = true
                        let default_slot = selectDefaultSlotsChange(scheduledTime)
                        let length = default_slot.length - 1
                        addvalues['scheduled_time'] = default_slot[0]
                        addvalues['scheduled_end_time'] = default_slot[length]
                        // addvalues['booked_slots'] = [{ scheduled_time: default_slot[0], scheduled_end_time: default_slot[length] }]
                    } else {
                        flag = false; toast.error('Time is required', {
                            position: toast.POSITION.TOP_CENTER
                        })
                    }
                }
                let date = moment(new Date(dateAndTime)).format('YYYY-MM-DD')
                let time = moment(new Date(dateAndTime)).format('HH:mm:ss')

                if (selectedGapSlotsForSubmit && selectedGapSlotsForSubmit.length > 0 && mangageAvalList.data.is_interview_gap_allowed === true) {
                    let length = selectedGapSlotsForSubmit.length - 1
                    addvalues['is_interview_gap_allowed'] = true
                    addvalues['gap_data'] = {
                        "date": date,
                        'scheduled_time': convertTime12to24IsChange(selectedGapSlotsForSubmit[0]),
                        'scheduled_end_time': convertTime12to24IsChange(selectedGapSlotsForSubmit[length])
                    }
                }


                addvalues['candidate_id'] = candidate_id[0]
                addvalues['interview_status'] = interviewStatus.value
                addvalues['interviewer_id'] = [selectInterviewer.value]
                addvalues['is_interview_schedule'] = true
                addvalues['date'] = date
                addvalues['time'] = time
                addvalues['time_stamp'] = selectedTimezone
                addvalues['meeting_url'] = meetingUrl
            }

            if (googleMeetinStatus === true) {
                delete addvalues['meeting_url']
            }
            addvalues['live_meeting_google_meet'] = googleMeetinStatus
            addvalues['live_meeting_zoom'] = googleMeetinStatus === true ? false : zoomMeetinStatus
            addvalues['live_meeting_skype'] = skypeMeetingStatus
            addvalues['live_meeting_other'] = otherMeetingStatus
            addvalues['live_meeting_in_person'] = inPersonStatus

            delete addvalues['industry']
            delete addvalues['department']

            const formData = new FormData();
            uploadedFiles.forEach((file, i) => {
                formData.append(`file`, file, file.name);
            });

            if (selectTech.length > 0) {
                let skill_ids = []
                selectTech.map(e => {
                    return skill_ids.push(e.value)
                })
                addvalues['skill_ids'] = skill_ids
            }

            formData.append("data", JSON.stringify(addvalues));
            setErrStatus(false)
            setValidateErr("")
            if (flag === true) {
                if (module === 'interviewer') {
                    let interviewer_data = {
                        scheduled_date: addvalues.date,
                        scheduled_time: addvalues.scheduled_time,
                        status_id: addvalues.interview_status,
                        status_key: interviewer_status.length > 0 ? interviewer_status[0].status_key : "",
                        meeting_url: addvalues.meeting_url,
                        cancel_create_new: true,
                        comment: interviewerFormValues.comment,
                        comment_by: interviewerFormValues.comment_by,
                        live_meeting_google_meet: addvalues.live_meeting_google_meet,
                        live_meeting_zoom: addvalues.live_meeting_zoom,
                        live_meeting_other: addvalues.live_meeting_other

                    }
                    setOpen(false);
                    setFormValues(initialFormData);
                    setBlankBody(false)
                    setBodyData("")
                    setShowEmailModel(false)
                    setMangageAvalList([])
                    setSlotArray([])
                    setInterviewReload(true)
                    dispatch(interviewUpdateApi({ ID: interviewerFormValues.obj.id, addvalues: interviewer_data }))

                } else {
                    setShowSubmitBtn(false)
                    dispatch(sendEmail(formData))
                    setReload("submit")
                }

            }

        }
    }

    if (reload === "submit" && status === STATUSES.FAILURE && error && error.message) {
        setReload(false)
        toast.error(error.message);
        setShowSubmitBtn(true)
    }
    const handleChangeOnCCEmail = (evt) => {
        let mailCCArray = []
        if (evt.length > 0) {
            evt.map(e => {
                return mailCCArray.push(e.value)
            })
            setCCEmail(mailCCArray)
        } else {
            setCCEmail([""])
        }
    }

    const handleChangeOnInterviwer = (evt) => {
        //let interviewArr = []
        checkInterviewStatus()
        if (evt) {
            // evt.map(e => {
            //     return interviewArr.push(e.value)
            // })
            setSelectInterviewer(evt)
            setSelectInterviewerKey(selectInterviewerKey + 1)
            let filterUser = interviewers_shift.filter(x => x.id === evt.value)
            if (filterUser.length > 0) {
                let shift_time = filterUser[0].shift__start_time ? `${filterUser[0].shift__start_time}  - ${filterUser[0].shift__end_time}` : 'Default Time (09:30-19:15)';
                setShowShiftTime(shift_time)
            } else {
                setShowShiftTime("")
            }
        } else {
            setSelectInterviewer("")
            setShowShiftTime("")
            setDateAndTime("")
        }
        setAvlStatus(false)
        setSlotArray([])

    }

    const handleCheckbox = async (evt) => {
        let { name, type, value, checked } = evt.target || evt;
        // eslint-disable-next-line
        if (checked === false) {
            setSelectInterviewer("")
            setDateAndTime("")
            setAvlStatus(false)
            setSlotArray([])
            setGoogleMeetinStatus(false)
        }
        // value = type === "checkbox" ? checked : value;
        setSelectTech([])
        const request = await dataService.interview_list(`{"filter":{"story_id":{"value":${story_id}, "operation":"eq"}},"order_by":{"column":"-id"}}`)
        const response = await request.data
        if (response.data?.rows && response.data.rows.length > 0) {
            let filterList = response.data.rows.filter(x => x.scheduled_time)

            if (name === 'is_interview_schedule' && checked === true && filterList.length === 0) {
                setAlreadyScheduleMessage(false)
                setShowInterviewSchedule(true)
                setshowCheckboxAndMsgBoth(false)
            } else if (name === 'is_interview_schedule' && checked === true && filterList.length && statusToBeShowInterview[filterList[0]?.interview_status?.status_name]) {
                setAlreadyScheduleMessage(false)
                setShowInterviewSchedule(true)
                setshowCheckboxAndMsgBoth(true)
                setAlreadyScheduleData(filterList)
            }
            else {

                setAlreadyScheduleData(filterList)
                setAlreadyScheduleMessage(true)
                setShowInterviewSchedule(false)
                setshowCheckboxAndMsgBoth(false)
            }
        }

        if (!response.data || (response.status === 400) || (response.data?.rows && response.data.rows.length === 0)) {
            setAlreadyScheduleMessage(false)
            setShowInterviewSchedule(!showInterviewSchedule)

        }


    }

    const updateDateTime = (evt) => {
        const { value } = evt.target || evt;
        setSlotArray([])
        setDateAndTime(value)
        setAvlStatus(false)
        setMangageAvalList([])
        if (isClientInterviewer) {
            setAvlStatus(true)
        }
    }

    // check availability section 
    const checkAvailability = async (e) => {
        e.preventDefault()
        if (selectInterviewer?.value && dateAndTime) {
            let filter = { "interviewer_id": selectInterviewer.value, "schedule_date": dateAndTime }
            setAvlStatus(true)
            //setFlag(true)
            setSlotLoading(true)
            const res = await dataService.manage_availability(JSON.stringify(filter));
            const resp = res.data;
            setMangageAvalList(resp)
            resp?.data && alreadyBookedSlots(resp)

            const calculateTimeSlots = (startTime, endTime, scheduleDate, defaultSlotTime) => {
                const array2 = [];
                const array3 = [];

                const todayDate = moment(scheduleDate).format("MM-DD-YYYY");
                const startDate = new Date(`${todayDate} ${startTime}`);
                const endDate = new Date(`${todayDate} ${endTime}`);
                let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

                let hh = Math.floor(timeDiff / 1000 / 60 / 60);
                hh = ('0' + hh).slice(-2);

                timeDiff -= hh * 1000 * 60 * 60;
                let mm = Math.floor(timeDiff / 1000 / 60);
                mm = ('0' + mm).slice(-2);

                let minuteDiff = Number(hh) * 60 + Number(mm);

                let hours = startTime.split(':')[0];
                let minutes = startTime.split(':')[1];

                for (let i = 0; i <= minuteDiff; i += defaultSlotTime) {
                    const momentInTime = moment(scheduleDate)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i, 'minutes')
                        .format('LT');

                    array2.push(momentInTime);

                    const momentInRangeTime = moment(scheduleDate)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                        .format('LT');

                    array3.push(momentInTime + "~" + momentInRangeTime);
                }

                array3.pop();

                return { slots: array2, slotsRange: array3, availability_from: startTime, availability_to: endTime };
            };

            if (user?.tms_role?.role_key === "agent") {
                if (resp.data && resp.data.default_availability_from_settings.length > 0) {
                    const defaultSlotTime = Number(resp.data.default_interview_blocktime_in_mins) || 10;
                    // eslint-disable-next-line
                    let slotArray = [];

                    slotArray.push(calculateTimeSlots("00:00:00", "24:00:00", resp.data.schedule_date, defaultSlotTime));

                    setSlotArray(slotArray);
                }
            } else {
                if (resp.data && resp.data.default_availability_from_settings.length > 0) {
                    const defaultSlotTime = Number(resp.data.default_interview_blocktime_in_mins) || 10;
                    // eslint-disable-next-line
                    let slotArray = [];

                    resp.data.default_availability_from_settings.forEach((e) => {
                        const startTime = e.availability_from;
                        const endTime = e.availability_to;
                        slotArray.push(calculateTimeSlots(startTime, endTime, resp.data.schedule_date, defaultSlotTime));
                    });

                    setSlotArray(slotArray);
                }
            }
            //dispatch(getMangageAvaList(JSON.stringify(filter)))
            setSlotLoading(false)
        } else {
            toast.warning('Select Interviewer and date !')
        }

    }

    // all ready Booked slots section (red)
    const alreadyBookedSlots = (resp) => {
        let array3 = []
        let array4 = []
        let array5 = []

        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        resp.data?.interview_schedules && resp.data.interview_schedules.map((e) => {
            let startTime = e.scheduled_time
            let endTime = e.scheduled_end_time
            let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date
            let startDate = new Date(`${todayDate} ${startTime}`);
            let endDate = new Date(`${todayDate} ${endTime}`);
            let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
            let hh = Math.floor(timeDiff / 1000 / 60 / 60);
            hh = ('0' + hh).slice(-2)

            timeDiff -= hh * 1000 * 60 * 60;
            let mm = Math.floor(timeDiff / 1000 / 60);
            mm = ('0' + mm).slice(-2)

            timeDiff -= mm * 1000 * 60;

            let minuteDiff = Number(hh) * 60 + Number(mm);
            let hourFromMinutes = startTime;
            let hours = hourFromMinutes.split(':')[0];
            let minutes = hourFromMinutes.split(':')[1];
            //let hourUpdate = hours + ":" + minutes

            let i = 0;
            if (e.is_interview_gap_allowed === false) {
                for (i; i <= minuteDiff; i += defaultSlotTime) {
                    // let correctTime = addTimes(hourUpdate, `00:${i}`)

                    let momentInTime = moment(todayDate)
                        .add(hours, "hours")
                        .add(Number(minutes) + i, "minutes")
                        .format("LT");
                    array3.push(momentInTime);

                    let momentInRangeTime = moment(resp.data.schedule_date)
                        .add(hours, "hours")
                        .add(Number(minutes) + i + defaultSlotTime, "minutes")
                        .format("LT");

                    array4.push(momentInTime + "~" + momentInRangeTime);
                }
                array4.pop();
            } else if (e.is_interview_gap_allowed === true) {
                let gap_start_time = e.scheduled_time;
                let gap_end_time = e.scheduled_end_time;
                let newGapSlots = selectedGapSlotsChange(
                    gap_start_time,
                    gap_end_time,
                    resp
                );
                array5.push(newGapSlots[0]);
            }
            // const lastIndex = array4 && array4.length - 1
            // lastIndex > 0 && array5.push(array4[lastIndex])
            return array3
        })
        setSelectedGapSlotsIs(array5)
        setBookedSlots(array4)
    }

    //all slot in range format section
    const selectedSlotsForBooking = (startTime, endTime, resp) => {
        let array3 = []
        let array4 = []
        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        // resp.data && resp.data.interview_schedules && resp.data.interview_schedules.map((e) => {
        // let startTime = e.scheduled_time
        // let endTime = e.scheduled_end_time
        let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        
        // let startTime = "11:40:00"
        // let endTime = "11:50:00"

        let startDate = new Date(`${todayDate} ${startTime}`);
        let endDate = new Date(`${todayDate} ${endTime}`);
        let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        let hh = Math.floor(timeDiff / 1000 / 60 / 60);
        hh = ('0' + hh).slice(-2)

        timeDiff -= hh * 1000 * 60 * 60;
        let mm = Math.floor(timeDiff / 1000 / 60);
        mm = ('0' + mm).slice(-2)

        timeDiff -= mm * 1000 * 60;

        let minuteDiff = Number(hh) * 60 + Number(mm);
        let hourFromMinutes = startTime;
        let hours = hourFromMinutes.split(':')[0];
        let minutes = hourFromMinutes.split(':')[1];
        //let hourUpdate = hours + ":" + minutes

        let i = 0
        for (i; i <= minuteDiff; i += defaultSlotTime) {
            let momentInTime = moment(todayDate)
                .add(hours, 'hours')
                .add(Number(minutes) + i, 'minutes')
                .format('LT');
            array3.push(momentInTime)

            let momentInRangeTime = moment(resp.data.schedule_date)
                .add(hours, 'hours')
                .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                .format('LT');

            array4.push(momentInTime + "~" + momentInRangeTime)
        }
        array4.pop()
        return array4
    }

    const selectedGapSlotsChange = (startTime, endTime, resp) => {
        let array3 = []
        let array4 = []
        let defaultSlotTime = resp.data.default_interview_blocktime_in_mins ? Number(resp.data.default_interview_blocktime_in_mins) : 10
        // eslint-disable-next-line
        // resp.data && resp.data.interview_schedules && resp.data.interview_schedules.map((e) => {
        // let startTime = e.scheduled_time
        // let endTime = e.scheduled_end_time
        let todayDate = moment(resp.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        
        // let startTime = "11:40:00"
        // let endTime = "11:50:00"

        let startDate = new Date(`${todayDate} ${startTime}`);
        let endDate = new Date(`${todayDate} ${endTime}`);
        let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        let hh = Math.floor(timeDiff / 1000 / 60 / 60);
        hh = ('0' + hh).slice(-2)

        timeDiff -= hh * 1000 * 60 * 60;
        let mm = Math.floor(timeDiff / 1000 / 60);
        mm = ('0' + mm).slice(-2)

        timeDiff -= mm * 1000 * 60;

        let minuteDiff = Number(hh) * 60 + Number(mm);
        let hourFromMinutes = startTime;
        let hours = hourFromMinutes.split(':')[0];
        let minutes = hourFromMinutes.split(':')[1];
        //let hourUpdate = hours + ":" + minutes

        let i = 0
        for (i; i <= minuteDiff; i += defaultSlotTime) {
            let momentInTime = moment(todayDate)
                .add(hours, 'hours')
                .add(Number(minutes) + i, 'minutes')
                .format('LT');
            array3.push(momentInTime)

            let momentInRangeTime = moment(resp.data.schedule_date)
                .add(hours, 'hours')
                .add(Number(minutes) + i + defaultSlotTime, 'minutes')
                .format('LT');

            array4.push(momentInTime + "~" + momentInRangeTime)
        }
        array4.pop()
        return array4
    }

    if (mangageAvalList.status === 200 && mangageAvalList.data && mangageAvalList.data.default_availability_from_settings && flag === true) {
        setFlag(false)
        let array = []
        if (mangageAvalList.data.default_availability_from_settings.length > 0) {
            //setFlag(false)
            // eslint-disable-next-line
            mangageAvalList.data.default_availability_from_settings.map((e, index) => {
                //   array.push(e.availability_from + '00:10:00')
                let array2 = []
                // let startTime = e.availability_from
                // let endTime = e.availability_to
                let startTime = e.availability_from
                let endTime = e.availability_to

                let todayDate = moment(mangageAvalList.data.schedule_date).format("MM-DD-YYYY"); //Instead of today date, We can pass whatever date        

                let startDate = new Date(`${todayDate} ${startTime}`);
                let endDate = new Date(`${todayDate} ${endTime}`);
                let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

                let hh = Math.floor(timeDiff / 1000 / 60 / 60);
                hh = ('0' + hh).slice(-2)

                timeDiff -= hh * 1000 * 60 * 60;
                let mm = Math.floor(timeDiff / 1000 / 60);
                mm = ('0' + mm).slice(-2)

                timeDiff -= mm * 1000 * 60;

                let minuteDiff = Number(hh) * 60 + Number(mm)
                let hourFromMinutes = e.availability_from;
                let hours = hourFromMinutes.split(':')[0];
                let minutes = hourFromMinutes.split(':')[1];
                let i;
                for (i = 0; i <= minuteDiff; i += 10) {
                    let momentInTime = moment(mangageAvalList.data.schedule_date)
                        .add(hours, 'hours')
                        .add(Number(minutes) + i, 'minutes')
                        .format('LT');

                    array2.push(momentInTime)
                }
                array2.length > 0 && array.push({ "slots": array2, "availability_from": startTime, "availability_to": endTime })
                //return array


            })
            setSlotArray(array)
        }
        alreadyBookedSlots()

    }
    if (mangageAvalList.status === 400 && flag === true) {
        setFlag(false)
    }

    // change duration section 
    const onChangeDueration = (evt) => {
        setSelectedSlotsIs([])
        setSelectedSlotsForSubmit([])
        setSelectedSingleGapSlotsIs([])
        setSelectedGapSlotsForSubmit([])

        if (evt) {
            setSelectDueration(evt)
            if (singleSlot) {
                selectSlotsChange(singleSlot.slot, singleSlot.data, evt)
            }
        } else {
            setSelectDueration("")
        }

    }

    // change time section 
    const chanageScheduledTime = (evt) => {
        if (evt) {
            const { value } = evt.target || evt

            let filterUser = interviewers_shift.filter(x => x.id === selectInterviewer.value)
            const defaultSelectedDueration = selectDueration.value

            if (filterUser.length > 0) {
                let start = filterUser[0].shift__start_time ? filterUser[0].shift__start_time : '09:30:00'
                let end = filterUser[0].shift__end_time ? filterUser[0].shift__end_time : '19:15:00'
                let aa1 = start.split(":");
                let aa2 = end.split(":");
                let aa3 = value.split(":");
                let aa4 = addTimes(value, `00:${defaultSelectedDueration}`).split(":");
                let flag = true

                let dd1 = Number(aa1[0])
                let dd11 = Number(aa1[1])
                let dd2 = Number(aa2[0])
                let dd21 = Number(aa2[1])
                let dd3 = Number(aa3[0])
                let dd31 = Number(aa3[1])
                let dd4 = Number(aa4[0])
                let dd41 = Number(aa4[1])
                if (dd1 > dd3) {
                    toast.warning("Check your shift time");
                    flag = false
                } else if (dd4 > dd2) {
                    toast.warning("Check your shift time");
                    flag = false
                } else if (dd1 === dd3 && dd11 > dd31) {
                    toast.warning("Check your shift time with interview duration");
                    flag = false
                } else if (dd4 >= dd2 && dd21 < dd41) {
                    toast.warning(`Check your shift end time  with interview duration is ${addTimes(value, `00:${defaultSelectedDueration}`)}`);
                    flag = false
                } else {
                    flag = true
                    setScheduledTime(value)

                }
                if (flag === true) {
                    setScheduledTime(value)
                }

            } else {
                setScheduledTime(value)

            }

        } else {
            setScheduledTime("")
        }


    }


    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }

    const convertTime12to24IsChange = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:00`;
    }


    const convertTime12to24Is = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:00`;
    }

    const checkBookedSlot = (itm) => {
        let is_ckecked = bookedSlots.length > 0 && bookedSlots.find(x => x === itm) ? true : false;
        return is_ckecked;
    }

    const selectSlotsChange = (itm, e, evt) => {

        let newItem = itm.split('~');
        let notAllowForSelect = selectedGapSlotsIs && selectedGapSlotsIs.length > 0 && selectedGapSlotsIs.filter(x => x === itm)

        setSingleSlot({ slot: newItem[0], data: e })
        let defaultSelectedDueration = evt ? evt.value : selectDueration.value
        if (checkBookedSlot(newItem[0]) === true) {
            toast.warning("Already interview scheduled for this time!", {
                position: toast.POSITION.TOP_CENTER
            })
            setSelectedSlotsIs([])
            setSelectedSlotsForSubmit([])
            setSelectedSingleGapSlotsIs([])
            setSelectedGapSlotsForSubmit([])

        } else if (notAllowForSelect.length > 0) {
            toast.warning(`This is Buffer time so this block is not available for schedule`, {
                position: toast.POSITION.TOP_CENTER
            })
            setSelectedSlotsIs([])
            setSelectedSlotsForSubmit([])
            setSelectedSingleGapSlotsIs([])
            setSelectedGapSlotsForSubmit([])

        }
        else
            if (defaultSelectedDueration) {
                let time = convertTime12to24(newItem[0])
                let newDateObj = addTimes(time, `00:${defaultSelectedDueration}`)
                let newTimeString = convertTime12to24Is(newDateObj)
                let hours = newTimeString.split(':')[0];
                let minutes = newTimeString.split(':')[1]
                let momentInTime = moment(mangageAvalList.data.schedule_date)
                    .add(hours, 'hours')
                    .add((minutes), 'minutes')
                    .format('LT');

                const filterSlotArray = e.slots?.filter(x => x === momentInTime)
                if (filterSlotArray.length > 0) {
                    const lastIndex = e.slots?.findIndex(x => x === momentInTime)
                    const firstIndex = e.slots?.findIndex(x => x === newItem[0])
                    const activeSlots = e.slots?.slice(firstIndex, lastIndex + 1)
                    let length = activeSlots.length - 1

                    let scheduled_start_time = convertTime12to24IsChange(activeSlots[0])
                    let scheduled_end_time = convertTime12to24IsChange(activeSlots[length])


                    const activeSlotsForGap = e.slots?.slice(lastIndex, lastIndex + 2)
                    let gapLength = activeSlotsForGap.length - 1

                    let gap_start_time = convertTime12to24IsChange(activeSlotsForGap[0])
                    let gap_end_time = convertTime12to24IsChange(activeSlotsForGap[gapLength])

                    let newGapSlots = []
                    if (mangageAvalList && mangageAvalList.data.is_interview_gap_allowed === true) {
                        newGapSlots = selectedGapSlotsChange(gap_start_time, gap_end_time, mangageAvalList)
                    }

                    let newActiveSlots = selectedSlotsForBooking(scheduled_start_time, scheduled_end_time, mangageAvalList)
                    if (bookedSlots.length > 0) {
                        let intersection = bookedSlots.filter(function (e) {
                            return newActiveSlots.indexOf(e) > -1;
                        });

                        let interSectionGap = bookedSlots.filter(e => (String(e) === String(newGapSlots[0])))

                        if (interSectionGap.length > 0) {
                            setSelectedSlotsIs([])
                            setSelectedSlotsForSubmit([])
                            setSelectedSingleGapSlotsIs([])
                            setSelectedGapSlotsForSubmit([])

                            return toast.warning(`Required duration not available`, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        } else {
                            setSelectedSlotsIs(newActiveSlots)
                            setSelectedSlotsForSubmit(activeSlots)
                            setSelectedSingleGapSlotsIs(newGapSlots)
                            setSelectedGapSlotsForSubmit(activeSlotsForGap)
                        }

                        if (intersection.length > 0) {
                            setSelectedSlotsIs([])
                            setSelectedSlotsForSubmit([])
                            setSelectedSingleGapSlotsIs([])
                            setSelectedGapSlotsForSubmit([])



                            toast.warning(`Required duration not available`, {
                                position: toast.POSITION.TOP_CENTER
                            })
                        } else {
                            setSelectedSlotsIs(newActiveSlots)
                            setSelectedSlotsForSubmit(activeSlots)
                            setSelectedSingleGapSlotsIs(newGapSlots)
                            setSelectedGapSlotsForSubmit(activeSlotsForGap)
                        }
                    } else {
                        setSelectedSlotsIs(newActiveSlots)
                        setSelectedSlotsForSubmit(activeSlots)
                        setSelectedSingleGapSlotsIs(newGapSlots)
                        setSelectedGapSlotsForSubmit(activeSlotsForGap)
                    }

                } else {
                    toast.warning(`Validate with min-max date from ${itm} to ${momentInTime}`, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setSelectedSlotsIs([])
                    setSelectedSlotsForSubmit([])
                    setSelectedSingleGapSlotsIs([])
                    setSelectedGapSlotsForSubmit([])
                }

            } else {
                toast.warning("Please select interview duration!", {
                    position: toast.POSITION.TOP_CENTER
                })
                setSelectedSlotsIs([])
                setSelectedSlotsForSubmit([])
                setSelectedSingleGapSlotsIs([])
                setSelectedGapSlotsForSubmit([])
            }
    }

    const selectDefaultSlotsChange = (itm) => {
        let defaultSelectedDueration = selectDueration.value
        let array = []

        if (defaultSelectedDueration) {

            let hours = itm.split(':')[0];
            let minutes = itm.split(':')[1]
            for (let i = 0; i <= defaultSelectedDueration; i += 10) {
                let momentInTime = moment(dateAndTime)
                    .add(hours, 'hours')
                    .add(Number(minutes) + i, 'minutes')
                    .format('HH:mm:ss');
                array.push(momentInTime)
            }
        }
        return array
    }
    const handleDepartmentChange = (evt) => {
        const { industry } = formValues
        const speclist = indsDepSpecSkill_List?.data?.rows.map((options) => {
            if (evt?.value === options?.department_id && options.industry_id === industry.value) {
                return options?.skill
            } else {
                return [];
            }
        }).flat()

        setupdatedSkillsList(speclist || [])
    }

    // change skill section
    const handleChangeOnTech = (evt) => {
        setSelectInterviewer("")
        setIsRandomInterviewer(false)
        setSelectInterviewerKey(0)
        let techArray = []
        if (evt && evt.length > 0) {
            evt.map(e => {
                return techArray.push(e.value)
            })
            dispatch(interviewerTechnolgyListOption({ "filter": { "technology_id": { "value": techArray, "operation": "in" } }, "no_limit": "True", "columns": ["resource_id", "resource__name", "resource__email"] }))
        } else {
            setSelectInterviewer("")
            setDateAndTime("")
        }
        setSelectTech(evt)
        setAvlStatus(false)
        setSlotArray([])
    }

    const handleIsRandomInterviewer = (evt) => {
        const { checked } = evt.target
        setIsRandomInterviewer(prev => !prev)
        setSelectInterviewer("")
        setSelectInterviewerKey(0)
        setDateAndTime("")
        setShowShiftTime("")
        if (checked) {
            dispatch(resourceList(roleWiseFilter))
        }
    }

    let selected_slots_is = false;
    let booked_slots_is = false;
    let gap_slots_is = false;
    let gap_single_slots_is = false

    //check interview status (set)section
    const checkInterviewStatus = () => {
        let flag = false
        // eslint-disable-next-line
        interview_schedule_option_List && interview_schedule_option_List.length > 0 && interview_schedule_option_List.map((ele) => {
            if (ele.status_key === 'pending') {
                flag = true
                setInterviewStatus({ value: ele.id, label: ele.status_name })
            }
        })
        if (flag === false) {
            let request = {
                status_name: "Pending",
                description: "Pending",
                status: true
            }
            dispatch(interviewScheduleStatusCreate(request))
            dispatch(getInterviewScheduleStatusList(`{"filter":{"status":{"value":"True", "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":"True"}`))
        }
    }

    const handleMeetingUrl = (name) => {

        if (typeof name === 'object') {
            setMeetingProvider(name)
            name = name.value
        }

        switch (name) {
            case 'close':
                if (meetingUrl === '') {
                    setZoomMeetinStatus(!zoomMeetinStatus)
                    setMeetingProvider('')
                }
                break;

            case 'zoom':
                module !== 'interviewer' && setShowZoomModel(true)
                setZoomMeetinStatus(!zoomMeetinStatus)
                setGoogleMeetinStatus(false)
                setOtherMeetingStatus(false)
                setSkypeMeetingStatus(false)
                setInPersonStatus(false)
                break;

            case 'google-meet':
                showInterviewSchedule === false && !googleMeetinStatus === true && handleCheckbox({ name: 'is_interview_schedule', type: 'checkbox', value: true, checked: true })
                setGoogleMeetinStatus(!googleMeetinStatus)
                setZoomMeetinStatus(false)
                setSkypeMeetingStatus(false)
                setOtherMeetingStatus(false)
                setInPersonStatus(false)
                break;

            case 'skype':
                showInterviewSchedule === false && !skypeMeetingStatus === true && handleCheckbox({ name: 'is_interview_schedule', type: 'checkbox', value: true, checked: true })
                setSkypeMeetingStatus(!skypeMeetingStatus)
                setGoogleMeetinStatus(false)
                setZoomMeetinStatus(false)
                setOtherMeetingStatus(false)
                setInPersonStatus(false)
                break;

            case 'in-person':
                showInterviewSchedule === false && !inPersonStatus === true && handleCheckbox({ name: 'is_interview_schedule', type: 'checkbox', value: true, checked: true })
                setInPersonStatus(!inPersonStatus)
                setOtherMeetingStatus(false)
                setZoomMeetinStatus(false)
                setGoogleMeetinStatus(false)
                setSkypeMeetingStatus(false)
                setMeetingUrl('');
                break;

            case 'other':
                showInterviewSchedule === false && !otherMeetingStatus === true && handleCheckbox({ name: 'is_interview_schedule', type: 'checkbox', value: true, checked: true })
                setOtherMeetingStatus(!otherMeetingStatus)
                setZoomMeetinStatus(false)
                setGoogleMeetinStatus(false)
                setInPersonStatus(false)
                setSkypeMeetingStatus(false)
                break;

            default:
                return;
        }

    }

    const handleInterviewerChange = (evt) => {
        const { value } = evt.target
        if (value === 'internalInterviewer') {

            if (!isInternalInterviewer) {
                dispatch(getIndustryList(`{ "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}`))
            }
            setIsInternalInterviewer(!isInternalInterviewer)
            setIsClientInterviewer(false)
            setSelectTech([])
            setSelectInterviewer("")
            setDateAndTime("")
            setScheduledTime("")
            setSelectDueration({ value: 30, label: 30 })
            setAvlStatus(false)
        }
        if (value === 'clientInterviewer') {
            dispatch(getAllClientResourceList(`{"filter":{"status":{"value":"True","operation":"eq"},"tms_role__role_key":{"value":"client-interviewer","operation":"icontains"}},"no_limit":True}`))
            setIsInternalInterviewer(false)
            setIsClientInterviewer(!isClientInterviewer)
            setSelectTech([])
            setSelectInterviewer("")
            setDateAndTime("")
            setScheduledTime("")
            setSelectDueration({ value: 30, label: 30 })
            setAvlStatus(false)
        }
    }
    return (
        <div>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{ '& .MuiDialog-paper': { zIndex: "0" } }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                {module === 'interviewer' ?

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Mail ({data?.candidate__candidate_name})

                        <Tooltip title="Create Zoom Meeting">
                            <button onClick={() => setShowZoomModel(true)} className="btn-add-zoom">Create Zoom Meeting</button>
                        </Tooltip>
                        {showZoomModel && <ZoomCreate title={data?.summary} setShowZoomModel={setShowZoomModel} story_id={story_id} handleMeetingUrl={handleMeetingUrl} />
                        }

                    </DialogTitle>
                    :

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>{data?.summary}
                        <Tooltip title="Create Zoom Meeting">
                            <button onClick={() => setShowZoomModel(true)} className="btn-add-zoom">Create Zoom Meeting</button>
                        </Tooltip>
                        {showZoomModel && <ZoomCreate title={data?.summary} setShowZoomModel={setShowZoomModel} story_id={story_id} handleMeetingUrl={handleMeetingUrl} />
                        }
                    </DialogTitle>
                }

                {(reload === "submit") && <LoaderForm />}
                <DialogContent>
                    <form className="email-form-template">
                        <div className="add-new-user-form">

                            {/* <div className="formInput">
                                <label >
                                    Category
                                </label>
                                <Select
                                    className="task-dropdown"
                                    classNamePrefix="select"
                                    placeholder="Select Category"
                                    isSearchable={true}
                                    isClearable
                                    options={
                                        categoryListEmail && categoryListEmail.data && categoryListEmail.data.template_masters.map((option) => {
                                            return { label: option.template_type_name, value: option.id, name: "category" }
                                        })
                                    }
                                    onChange={categoryChange}
                                />
                            </div> */}
                        </div>
                        {/* {
                            emailTemplatesId > 0 &&
                            <> */}
                        {module !== 'interviewer' && <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    To
                                </label>
                                <input
                                    name="to"
                                    defaultValue={to_email}
                                    type="text"
                                    placeholder="To"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && (formValues.to === "" || formValues.to[0] === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.toErr}</p>}
                                {errStatus === true && validateErr && validateErr.to !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr?.to}</p>}

                            </div>
                            <div className="formInput" style={{ marginLeft: "20px" }}>
                                <label >  CC  </label>
                                <CreatableSelect
                                    className="select"
                                    classNamePrefix="select"
                                    placeholder="Select CC"
                                    isSearchable={true}
                                    isClearable
                                    isMulti
                                    name="cc"
                                    defaultValue={formValues.cc}
                                    options={
                                        resources && resources.length > 0 ? resources.map((option, i) => {
                                            return ({ label: option.email, value: option.email, name: "cc", key: i })
                                        }) : []
                                    }
                                    onChange={handleChangeOnCCEmail}
                                />
                            </div>
                        </div>}
                        {module !== 'interviewer' && <div className="add-new-user-form">
                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                <label >
                                    Subject
                                </label>
                                <input
                                    name="subject"
                                    defaultValue={formValues.subject}
                                    type="text"
                                    placeholder="Subject"
                                    onChange={handleChange}
                                />
                                {errStatus === true && formValues && formValues.subject === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.subjectErr}</p>}
                            </div>
                        </div>}
                        {module !== 'interviewer' && <div className="add-new-user-form">
                            <div className="formInputDescription">
                                <label >  Body  </label>
                                {
                                    (bodyData && blankBody === true) &&
                                    <CKEditor
                                        name={"body"}
                                        initData={bodyData}
                                        onChange={(e) => inputHandler(e)}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                    />
                                }
                                {
                                    (blankBody === false || !bodyData) &&
                                    <CKEditor
                                        name={"body"}
                                        onChange={(e) => inputHandler(e)}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                    />
                                }
                                {errStatus === true && formValues && bodyData === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.bodyErr}</p>}
                            </div>
                            <div className='add-new-user-form'>
                                <div className="uploaded-files-list">
                                    {uploadedFiles.length > 0 && uploadedFiles.map((file, i) => {
                                        return <div key={i}>
                                            <ul>
                                                <li>
                                                    {file.name}
                                                    <button onClick={() => deleteFile(i)}>
                                                        <Close />
                                                    </button>
                                                </li>
                                            </ul>

                                        </div>
                                    })
                                    }
                                </div>

                                <div className="file-attchment-icon-btn">
                                    <input
                                        id='fileUpload'
                                        ref={uploadInputRef}
                                        type='file'
                                        multiple
                                        accept='application/pdf, image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        style={{ display: "none" }}
                                        onChange={onFileChange}
                                        name="file"
                                    />

                                    <Tooltip title="Attach File">
                                        <span>
                                            <AttachFile
                                                className={`attach_con`}
                                                onClick={() => uploadInputRef.current?.click()}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                {errStatus === true && fileErr && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                            </div>
                        </div>}

                        {/* Schedule Interview section start */}
                        {/* {(zoomMeetinStatus === false || module === 'interviewer' )&& */}

                        {/* } */}

                        {
                            // already interview schedule section
                            (alreadyScheduleMessage === true || showCheckboxAndMsgBoth) &&
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                {!showCheckboxAndMsgBoth && <b style={{ fontSize: "14px", color: 'red' }}>&nbsp; Already schedule Interview for this candidate </b>}
                                <br /> <br />
                                <AlReadyInterviewSchedule viewData={alReadyScheduleData} />
                            </div>
                        }

                        {
                            // (googleMeetinStatus === true || otherMeetingStatus === true || zoomMeetinStatus === true) &&
                            //     <div className="add-new-user-form" style={{ marginTop: "10px" }}>
                            //         <div className="formInput" style={{ flexBasis: "100%" }}>
                            //             <label >
                            //                 Meeting URL
                            //             </label>
                            //             <input
                            //                 name="meeting url"
                            //                 defaultValue={meetingUrl}
                            //                 type="text"
                            //                 disabled={module !== 'interviewer' && zoomMeetinStatus}
                            //                 placeholder="Meeting URL"
                            //                 // formErr={formErr.meetingyErr}
                            //                 onChange={(evt) => setMeetingUrl(evt.target.value)}
                            //             />
                            //             {errStatus === true && meetingUrl === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.meetingyErr}</p>}
                            //             {/* {(meetingUrl || googleMeetinStatus === true) ? <></> : <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.meetingyErr}</p>} */}
                            //         </div>
                            //     </div>
                        }


                        {(module !== 'interviewer' && alreadyScheduleMessage === false) && <div className='add-new-user-form'>
                            <div className="formInputCheckBox">
                                <Checkbox
                                    className="checkbox"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    name={"is_interview_schedule"}
                                    checked={showInterviewSchedule === true}
                                    onChange={handleCheckbox}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30, marginLeft: "-10px" } }}
                                />
                                <label>
                                    Schedule Interview
                                    <b style={{ fontSize: "11px" }}> &nbsp; (Check the checkbox,if you want to schedule Interview) </b>
                                </label>
                            </div>
                        </div>}

                        {
                            showInterviewSchedule === true ?
                                <div className='' style={{ marginBottom: "200px" }}>

                                    <div className="formInput" style={{ margin: '10px 0', flexBasis: "100%", display: 'flex', alignItems: 'flex-start' }}>



                                        {/* <label style={{ marginRight: '10px' }} >
                                            Live Meeting :
                                        </label>
                                        <FormControlLabel value={'zoom'} onChange={(evt) => handleMeetingUrl(evt, 'zoom')} control={<Radio checked={zoomMeetinStatus === true ? 'zoom' : false} />} label="Zoom Meeting" />
                                        <FormControlLabel value={'google'} onChange={(evt) => handleMeetingUrl(evt, 'google')} control={<Radio checked={googleMeetinStatus === true ? 'google' : false} />} label="Google Meet" />
                                        <FormControlLabel value={'other'} onChange={(evt) => handleMeetingUrl(evt, 'other')} control={<Radio checked={otherMeetingStatus === true ? 'other' : false} />} label="Other" /> */}
                                        <div style={{ width: '20%' }}>
                                            <label style={{ marginRight: '10px' }} >
                                                Meeting Provider :
                                            </label>
                                            <Select
                                                // className="task-dropdown"
                                                classNamePrefix="select"
                                                placeholder="Select Meeting Provider"
                                                // defaultValue={projectId}
                                                isSearchable={true}
                                                name={"meeting_provider"}
                                                value={meetingProvider}
                                                options={(meetingprovider_list?.data?.rows.length && meetingprovider_list.data.rows.map(item => (
                                                    {
                                                        label: item.meeting_provider_name,
                                                        value: item.meeting_provider_key
                                                    }
                                                ))) || []}
                                                onChange={handleMeetingUrl}
                                            />
                                            {errStatus === true && meetingProvider === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.meetingProviderErr}</p>}
                                        </div>

                                        {
                                            (otherMeetingStatus === true || zoomMeetinStatus === true || skypeMeetingStatus === true) &&
                                            <div className="formInput" style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingLeft: '50px' }}>
                                                <label style={{ marginRight: '10px', marginBottom: '3px' }} >
                                                    URL :
                                                </label>
                                                <input
                                                    name="meeting url"
                                                    defaultValue={meetingUrl}
                                                    type="text"
                                                    disabled={module !== 'interviewer' && zoomMeetinStatus}
                                                    placeholder="Meeting URL"
                                                    style={{ padding: "8px 10px", border: "1px solid rgba(0,0,0,.25)", borderRadius: '4px' }}
                                                    // formErr={formErr.meetingyErr}
                                                    onChange={(evt) => setMeetingUrl(evt.target.value)}
                                                />
                                                {errStatus === true && meetingUrl === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.meetingyErr}</p>}
                                            </div>
                                        }


                                    </div>

                                    <div className='add-new-user-form'>
                                        <h4> Schedule Interview</h4>
                                    </div>
                                    <div className='add-new-user-form row' style={{ display: "flex" }}>
                                        {module !== 'interviewer' &&
                                            <>
                                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                                    <label style={{ marginRight: '10px' }} >
                                                        Interviewer :
                                                    </label>
                                                    <FormControlLabel value={'internalInterviewer'} onChange={(evt) => handleInterviewerChange(evt)} control={<Radio checked={isInternalInterviewer === true ? 'internalInterviewer' : false} />} label="Internal Interviewer" />
                                                    <FormControlLabel value={'clientInterviewer'} onChange={(evt) => handleInterviewerChange(evt)} control={<Radio checked={isClientInterviewer === true ? 'clientInterviewer' : false} />} label="Client Interviewer" />
                                                </div>
                                                {
                                                    isInternalInterviewer && (
                                                        <div className='col' style={{ flexBasis: '25%' }}>
                                                            <div className="formInput">
                                                                <label >
                                                                    Select Industry
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    divstyle={{ flexBasis: '100%' }}
                                                                    placeholder={'Select Industry'}
                                                                    onChange={handleChange}
                                                                    defaultValue={formValues.industry}
                                                                    value={formValues.industry}
                                                                    isSearchable={true}
                                                                    name={'industry'}
                                                                    options={
                                                                        industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                                            return {
                                                                                label: option.industry_name,
                                                                                value: option.id,
                                                                                name: 'industry'
                                                                            }
                                                                        })
                                                                    }
                                                                />

                                                            </div>
                                                            {
                                                                formValues.industry && (

                                                                    <div className="formInput">
                                                                        <label >
                                                                            Department
                                                                        </label>
                                                                        <Select
                                                                            // isMulti
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select Department"
                                                                            defaultValue={formValues.department}
                                                                            value={formValues.department}
                                                                            isSearchable={true}
                                                                            name={"department"}
                                                                            options={
                                                                                industry_department_List?.data?.rows[0]?.department.map((option) => {
                                                                                    return { label: option.name, value: option.id, name: "department" }
                                                                                })
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleDepartmentChange(e)
                                                                                handleChange(e)
                                                                            }}
                                                                        />
                                                                        {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                formValues.department && (

                                                                    <div className="formInput">
                                                                        <label>Select Skills</label>
                                                                        <CreatableSelect
                                                                            className="select-a"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select Skills"
                                                                            isSearchable={true}
                                                                            isClearable
                                                                            isMulti
                                                                            name="technology_id"
                                                                            defaultValue={selectTech}
                                                                            // options={updatedSkillsList}
                                                                            onChange={handleChangeOnTech}
                                                                            options={
                                                                                updatedSkillsList.length !== 0 ? updatedSkillsList.map((option) => {
                                                                                    return { label: option.skill_name, value: option.id, name: "technology_id" }
                                                                                }) : []
                                                                            }

                                                                        />

                                                                    </div>

                                                                    // <InputMultiSelectField
                                                                    //     label={'Select Skills'}
                                                                    //     placeholder="Select Skills"
                                                                    //     defaultValue={selectTech}
                                                                    //     name={"technology_id"}
                                                                    //     onRemove={handleChangeOnTech}
                                                                    //     onSelect={handleChangeOnTech}
                                                                    //     optionList?.data?.rows[0]?.specialization_data ?? []}
                                                                    //     optionLevel={['name']}
                                                                    //     optionValue={"id"}
                                                                    //     divstyle={{ flex: '100%' }}
                                                                    // />
                                                                )
                                                            }

                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                        {((selectTech && selectTech.length > 0) || module === 'interviewer') &&
                                            <>
                                                <div className='col ml-2' style={{ flexBasis: '25%', marginLeft: '10px', }}>
                                                    <div className="formInput">
                                                        <label style={{ color: isRandomInterviewer && "lightgray" }}>Select Interviewer </label>
                                                        <CreatableSelect
                                                            className="select-a"
                                                            classNamePrefix="select"
                                                            placeholder="Select Interviwer"
                                                            isSearchable={true}
                                                            isClearable
                                                            isDisabled={isRandomInterviewer}
                                                            isValidNewOption={() => false}
                                                            //isMulti
                                                            name="interviewer_id"
                                                            defaultValue={selectInterviewer}
                                                            options={
                                                                interviewers_tech && interviewers_tech.length > 0 ? interviewers_tech.map((option, i) => {
                                                                    return ({ label: `${option.resource__name}(${option.resource__email})`, value: option.resource_id, name: "interviewer_id", key: i })
                                                                }) : []
                                                            }
                                                            onChange={handleChangeOnInterviwer}
                                                            key={selectInterviewerKey}
                                                        />
                                                    </div>
                                                    {module !== 'interviewer' && interviewers && interviewers.length === 0 && <p style={{ color: 'red' }}>Interviewer not available for this skills</p>}
                                                    {
                                                        (

                                                            <>

                                                                <div className="formInput" style={{ marginTop: "37px" }}>
                                                                    <div className="formInputCheckBox">
                                                                        <Checkbox
                                                                            className="checkbox"
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            name={"is_interview_schedule"}
                                                                            checked={isRandomInterviewer}
                                                                            onChange={handleIsRandomInterviewer}
                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30, marginLeft: "-10px" } }}
                                                                        />
                                                                        <label>
                                                                            Random Interviewer
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    isRandomInterviewer && (

                                                                        <div className="formInput">
                                                                            <label>Select Random Interviewer </label>
                                                                            <CreatableSelect
                                                                                className="select-a"
                                                                                classNamePrefix="select"
                                                                                placeholder="Select Interviwer"
                                                                                isSearchable={true}
                                                                                isClearable
                                                                                isValidNewOption={() => false}
                                                                                // isDisabled={module === 'interviewer' ? true : false}
                                                                                //isMulti
                                                                                name="interviewer_id"
                                                                                defaultValue={selectInterviewer}
                                                                                options={
                                                                                    resouceListInterviewer ? resouceListInterviewer?.map((option, i) => {
                                                                                        return ({ label: `${option.name}(${option.email})`, value: option.id, name: "interviewer_id", key: i })
                                                                                    }) : []
                                                                                }
                                                                                onChange={handleChangeOnInterviwer}
                                                                            />
                                                                        </div>

                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                {selectInterviewer?.value &&
                                                    <div className='col' style={{ flexBasis: '18%', marginLeft: '10px', marginTop: '4px' }}>
                                                        <div className="formInput-date">
                                                            {/* <div className="datepicker-input-custom"> */}

                                                            <label>Date</label><br />
                                                            <FormControl>
                                                                <TextField
                                                                    id="interviewdate"
                                                                    type="date"
                                                                    name={'scheduled_date'}
                                                                    onChange={(evt) => updateDateTime(evt)}
                                                                    value={dateAndTime}
                                                                    sx={{ width: 220 }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                                />

                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                }
                                                {avlStatus === false && selectInterviewer && selectInterviewer.value && dateAndTime && !isClientInterviewer &&
                                                    <div className='col'>
                                                        <div className="formInput" style={module === 'interviewer' ? { marginLeft: 10, marginTop: 5 } : {}}>
                                                            <FormControl >
                                                                <button style={{ width: '100%', marginTop: ' 22px' }} className="submit-modal" onClick={checkAvailability}>
                                                                    Show Availability
                                                                </button>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                }
                                            </>

                                        }

                                        {(!!isClientInterviewer) &&
                                            <div className='col ml-2' style={{ flexBasis: '25%', marginLeft: '10px', }}>
                                                <div className="formInput">
                                                    <label>Select Client Interviewer</label>
                                                    <CreatableSelect
                                                        className="select-a"
                                                        classNamePrefix="select"
                                                        placeholder="Select Client Interviwer"
                                                        isSearchable={true}
                                                        isClearable
                                                        isDisabled={module === 'interviewer'}
                                                        //isMulti
                                                        name="interviewer_id"
                                                        defaultValue={clientInterviewer}
                                                        options={
                                                            clientresource_AllList && clientresource_AllList?.data?.rows.length > 0 ? clientresource_AllList.data?.rows?.map((option, i) => {
                                                                return ({ label: `${option.name}`, value: option.resource_id, name: "interviewer_id", key: i })
                                                            }) : []
                                                        }
                                                        onChange={handleChangeOnInterviwer}
                                                    />
                                                </div>
                                                {module !== 'interviewer' && clientresource_AllList && clientresource_AllList?.data?.rows?.length === 0 && <p style={{ color: 'red' }}>Interviewer not available</p>}

                                            </div>
                                        }

                                        {isClientInterviewer && selectInterviewer &&
                                            <div className='col' style={{ flexBasis: '18%', marginLeft: '10px', marginTop: '4px' }}>
                                                <div className="formInput-date">
                                                    {/* <div className="datepicker-input-custom"> */}

                                                    <label>Date</label><br />
                                                    <FormControl>
                                                        <TextField
                                                            id="interviewdate"
                                                            type="date"
                                                            name={'scheduled_date'}
                                                            onChange={(evt) => updateDateTime(evt)}
                                                            value={dateAndTime}
                                                            sx={{ width: 220 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                        />

                                                    </FormControl>


                                                    {/* <FormControl>
                                                    <DatePicker
                                                        className='select'
                                                        format="dd-MM-yyyy HH:mm"
                                                        showMeridian
                                                        // value={dateAndTime}
                                                        onChange={(evt) => updateDateTime(evt)}
                                                        onClean={(evt) => { updateDateTime([]) }}
                                                        disabledDate={date => isBefore(date, new Date())}
                                                        placement="topStart"
                                                        placeholder="dd-mm-yyyy hh:mm"
                                                        style={{
                                                            paddingRight: "0px", marginTop: "2px", marginLeft: "4px"
                                                        }}
                                                    />
                                                </FormControl> */}
                                                </div>
                                            </div>
                                        }
                                        {/* {selectInterviewer && selectInterviewer.value && */}
                                        {/* // <div className='col' style={{ flexBasis: '18%', marginLeft: '10px', marginTop: '4px' }}>
                                            //     <div className="formInput-date">
                                            //         {/* <div className="datepicker-input-custom"> */}

                                        {/* //         <label>Date</label><br />
                                            //         <FormControl>
                                            //             <TextField */}
                                        {/* //                 id="interviewdate"
                                            //                 type="date"
                                            //                 name={'scheduled_date'}
                                            //                 onChange={(evt) => updateDateTime(evt)}
                                            //                 value={dateAndTime}
                                            //                 sx={{ width: 220 }}
                                            //                 InputLabelProps={{ */}
                                        {/* //                     shrink: true,
                                            //                 }}
                                            //                 inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                            //             />

                                            //         </FormControl> */}


                                        {/* <FormControl>
                                                    <DatePicker
                                                        className='select'
                                                        format="dd-MM-yyyy HH:mm"
                                                        showMeridian
                                                        // value={dateAndTime}
                                                        onChange={(evt) => updateDateTime(evt)}
                                                        onClean={(evt) => { updateDateTime([]) }}
                                                        disabledDate={date => isBefore(date, new Date())}
                                                        placement="topStart"
                                                        placeholder="dd-mm-yyyy hh:mm"
                                                        style={{
                                                            paddingRight: "0px", marginTop: "2px", marginLeft: "4px"
                                                        }}
                                                    />
                                                </FormControl> */}
                                        {/* //     </div>
                                            // </div> */}
                                        {/* // } */}
                                    </div>
                                    {avlStatus === true && slotLoading === true && <LoaderForm />}
                                    {avlStatus === true && mangageAvalList && mangageAvalList.status &&
                                        <div className='add-new-user-form row' >
                                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                                {mangageAvalList.status === 400 ? <label style={{ color: 'red' }}>{mangageAvalList.message}</label> : slotArray.length === 0 && <label style={{ fontSize: 'medium', fontWeight: 'bold' }}>Slots are not available</label>}
                                            </div>
                                        </div>
                                    }

                                    {((avlStatus === true && slotLoading === false) || (isClientInterviewer && avlStatus && (dateAndTime !== "" && selectInterviewer === ""))) &&
                                        <div className='add-new-user-form row' style={{ display: "flex" }}>
                                            {((avlStatus === true && mangageAvalList && mangageAvalList.data && slotArray && slotArray.length === 0) || (avlStatus === true && mangageAvalList && !mangageAvalList.data) || (isClientInterviewer && avlStatus && (dateAndTime !== "" && selectInterviewer === ""))) &&
                                                <div className='col' style={{ flexBasis: '16%', marginRight: "10px" }}>
                                                    <div className="formInput-date">
                                                        {/* <div className="datepicker-input-custom"> */}
                                                        <label>Time</label><br />
                                                        <FormControl>
                                                            <TextField
                                                                id="interviewtime"
                                                                type="time"
                                                                name={'scheduled_time'}
                                                                onChange={(evt) => chanageScheduledTime(evt)}
                                                                value={scheduledTime}
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />

                                                        </FormControl>
                                                    </div>
                                                </div>
                                            }
                                            <div className='col' style={{ flexBasis: '20%', marginTop: "-5px" }}>
                                                <div className="formInput">
                                                    <label>Interview Duration</label>
                                                    <CreatableSelect
                                                        className="select-a"
                                                        classNamePrefix="select"
                                                        placeholder="Select Duration"
                                                        isSearchable={true}
                                                        isClearable
                                                        //isMulti
                                                        name="interviewer_id"
                                                        defaultValue={selectDueration}
                                                        options={[
                                                            { value: 10, label: 10 },
                                                            { value: 20, label: 20 },
                                                            { value: 30, label: 30 },
                                                            { value: 40, label: 40 },
                                                            { value: 50, label: 50 },
                                                            { value: 60, label: 60 }
                                                        ]}
                                                        onChange={onChangeDueration}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col' style={{ width: '20%', marginLeft: '14px' }}>
                                                {/* <div className="formInput">
                                                    <label>Interview Status</label>
                                                    <CreatableSelect
                                                        className="select-a"
                                                        classNamePrefix="select"
                                                        placeholder="Select Status"
                                                        isSearchable={true}
                                                        isClearable
                                                        //isMulti
                                                        name="interviewer_id"
                                                        isDisabled={module === 'interviewer' ? true : false}
                                                        defaultValue={interviewStatus}
                                                        options={interview_schedule_option_List}
                                                        onChange={(evt) => { evt.target ? setInterviewStatus(evt.target) : setInterviewStatus(evt) }}
                                                    />
                                                    {errStatus === true && interviewStatus && interviewStatus.value === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}

                                                </div> */}
                                            </div>
                                            {/* <div className='col' style={{ width: '20%', marginLeft: '14px' }}>
                                                <div className="formInput">
                                                    <label>Time Zone</label>
                                                    <TimezoneSelect
                                                        value={selectedTimezone}
                                                        onChange={setSelectedTimezone}
                                                    />
                                                </div>
                                            </div> */}


                                        </div>
                                    }
                                    {avlStatus === true && slotLoading === false && mangageAvalList && mangageAvalList.data &&
                                        <div className='add-new-user-form row' >
                                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                                {mangageAvalList.status === 400 ? <label style={{ color: 'red' }}>{mangageAvalList.message}</label> : <label style={{ fontSize: 'medium', fontWeight: 'bold' }}>Available Slots</label>}<br />

                                                <FormControl>
                                                    {mangageAvalList?.data &&
                                                        < Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                            {
                                                                slotArray && slotArray.length > 0 && slotArray.map((e, i) => {
                                                                    return (
                                                                        <div key={i} style={{ flexBasis: "100%" }}>
                                                                            <div style={{ width: "150px" }} className='add-new-user-form row'>
                                                                                <Item style={{ color: 'blue', margin: 5, border: "1px solid #171819" }} >
                                                                                    {e.availability_from && e.availability_from.split(":")[0] + ":" + e.availability_from.split(":")[1]}~
                                                                                    {e.availability_to && e.availability_to.split(":")[0] + ":" + e.availability_to.split(":")[1]}
                                                                                </Item>
                                                                            </div>
                                                                            <div key={i} className='add-new-user-form row' style={{ marginLeft: 155, marginTop: -46 }}>
                                                                                {e.slotsRange && e.slotsRange.length > 0 && e.slotsRange.map((itm, idx) => {
                                                                                    selected_slots_is = selectedSlotsIs.find(x => x === itm)
                                                                                    booked_slots_is = bookedSlots.find(x => x === itm)
                                                                                    gap_slots_is = selectedGapSlotsIs.find(x => x === itm)
                                                                                    gap_single_slots_is = selectedSingleGapSlots.find(x => x === itm)

                                                                                    return (
                                                                                        <div key={idx} style={(booked_slots_is || selected_slots_is || gap_slots_is || gap_single_slots_is) ? { margin: 5, cursor: "not-allowed" } : { margin: 5, cursor: 'pointer' }}
                                                                                            onClick={() => (booked_slots_is === itm || gap_slots_is === itm || gap_single_slots_is === itm) ? nothing() : selectSlotsChange(itm, e)}>
                                                                                            <Item style={
                                                                                                booked_slots_is ? { backgroundColor: 'red', color: '#fff', width: "150px" }
                                                                                                    : selected_slots_is ? { backgroundColor: '#2b70ed', color: '#fff', width: "150px" }
                                                                                                        : gap_slots_is ? { backgroundColor: '#484545', color: '#fff', width: "150px" }
                                                                                                            : gap_single_slots_is ? { backgroundColor: '#484545', color: '#fff', width: "150px" }
                                                                                                                : { backgroundColor: 'green', color: '#fff', width: "150px" }

                                                                                            }>{itm}</Item>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }
                                                                                <br />

                                                                            </div>
                                                                            {/* <br /> */}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {/* <br /> */}
                                                            {mangageAvalList.data.length === 0 &&
                                                                <Item>{'No schedule found for this date !'}</Item>
                                                            }

                                                        </Stack>
                                                    }
                                                </FormControl>
                                            </div>
                                        </div>

                                    }
                                </div> : ""
                        }

                        {
                            ((showInterviewSchedule === true && selectedTimezone && selectedTimezone.value && selectDueration && selectInterviewer && isClientInterviewer ? true : dateAndTime && (inPersonStatus === true ? true : meetingUrl || googleMeetinStatus === true) && showSubmitBtn) || (showInterviewSchedule === false && showSubmitBtn)) && <DialogActions>
                                <button className="submit-modal-email" onClick={onSubmit}>{module === 'interviewer' ? 'Select Date and Time' : 'Send'}</button>
                            </DialogActions>
                        }
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default EmailTemplet