import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./manageshift.scss";
import LoaderForm from "../../components/common-function/LoaderForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from "@mui/material";
import dataService from "../../redux/services/data.service";
import { Refresh } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";

const ViewShift = () => {

    const { id } = useParams()
    // const id = useLocation().state;
    const [view_data, setData] = useState({})
    const [loader, setLoader] = useState(false)

    const loadDataById = async () => {
        setLoader(true)
        const response = await dataService.shiftBYId(id).catch(error => {
            setLoader(false)
            toast.error(error.message)
            return error;
        });
        if (response && response.data && response.data.data) {
            setData(response.data.data)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refresh = () => {
        loadDataById()
    }

    return (
        <div className="resource">
            <div className="resourceContainer">
                <ToastContainer />
                <div className="top-headings">
                    <h3>Shift View</h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <button onClick={() => refresh()} className="btn-add-new">
                            <Tooltip title="Reload"><Refresh /></Tooltip>
                        </button>
                    </div>
                </div>
                {loader === true ? <LoaderForm />
                    :
                    loader === false ?
                        <div className="view-details-row">
                            <div className="detailItem">
                                <span className="itemKey">Shift Name :</span>
                                <span className="itemKey2">{view_data && view_data.shift_name ? view_data.shift_name : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Shift Key :</span>
                                <span className="itemKey2">{view_data && view_data.shift_key ? view_data.shift_key : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Start Time :</span>
                                <span className="itemKey2">{view_data && view_data.start_time ? view_data.start_time : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">End Time :</span>
                                <span className="itemKey2">{view_data && view_data.end_time ? view_data.end_time : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Shift Id :</span>
                                <span className="itemKey2">{view_data && view_data.id ? view_data.id : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Status :</span>
                                <span className="itemKey2">{view_data && view_data.status ? "Active" : "Inactive"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Timing Display :</span>
                                <span className="itemKey2">{view_data && view_data.duration ? parseInt(view_data.duration / 60) ? parseInt(view_data.duration / 60) + " Hours "
                                    // + view_data.duration%60 + "Minutes" 
                                    : "" : ""}
                                    {(view_data && view_data.duration && parseInt(view_data.duration % 60)) ? parseInt(view_data.duration % 60) + " Minutes " : ""}
                                </span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Time Zone :</span>
                                <span className="itemKey2">{view_data && view_data.time_zone ? view_data.time_zone : "N/A"}</span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Description :</span>
                                <span className="itemKey2">{view_data && view_data.description ? view_data.description : "N/A"}</span>
                            </div>

                        </div>
                        :
                        <div>Oops something is wrong.. </div>
                }
            </div>
        </div >
    )

}

export default ViewShift