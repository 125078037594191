import React, { useEffect, useRef, useState } from 'react'
import './holiday.scss'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { holidaysListNew, saveHolidayDate } from "../../redux/features/holidaySlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import { Button, Dialog, DialogContent, DialogTitle, Menu, MenuItem, Paper, Skeleton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { BorderColorTwoTone, Close, Edit, MoreHoriz, UnfoldMore } from '@mui/icons-material';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import api from '../../redux/APIS/api';
import { InputFields, InputTextareaField, ViewField } from '../../components/common-function/InputFields';
import CONSTANTS from '../../components/constant/constantComponents';
import Draggable from 'react-draggable';

const { REACT_APP_ENV } = process.env;


const initialFormData = Object.freeze({
  name: "",
  description: "",
  status: "",
});

const formErrData = Object.freeze({
  nameErr: "Holiday name is required",
  descriptionErr: "Description is required",
  statusErr: "Status is required"
});

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Holidays = () => {
  // hello
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;
  const userRole = rtm_user?.user?.data?.tms_role.role_key || null;

  const { holidays_List, status, holidays_Create } = useSelector(state => state.holiday)

  const [limit, setLimit] = useState(25)
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);

  const [holidaysData, setHolidaysData] = useState({})

  const [reload, setReload] = useState(false)
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  // grid states starts
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });

  const skeletonBase = { name: '_skeleton_', description: '_skeleton_', status: '_skeleton_' };
  const filterableColumns = ['name', 'description', 'status'];

  const [loading, setLoading] = useState(false)
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();

  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let editID = useRef(null);
  let isAnyChange = useRef({});
  // grid states ends
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');
  const [formValues, setFormValues] = useState(initialFormData)
  const [formErr] = useState(formErrData)
  const [errStatus, setErrStatus] = useState(false);
  const [activeViewMode, setActiveViewMode] = useState(false)
  const [ID, setID] = useState("");


  const holidaysColumns = [
    { field: "id", headerName: "ID", width: 70, sortable: false, pinnable: false, },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("name")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
      width: 275,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params?.row?.name === "_skeleton_" ? <Skeleton width={"300px"} /> :
            <Typography onClick={() => onView(params.row)} style={{ textDecoration: 'underline', color: '#1B3764', cursor: 'pointer' }}>{params?.row?.name}</Typography>
        )
      },
    },
    {
      field: "description",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("description")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
      width: 400,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.description === "_skeleton_" ? <Skeleton width={"300px"} /> :
            <Tooltip describeChild title={params?.row?.description}>
              <p> {params?.row?.description} </p>
            </Tooltip>
        )
      }
    },
    {
      field: "status",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
      width: 300, type: 'singleSelect', valueOptions: [{ label: "Active", value: 'True' }, { label: "In-Active", value: 'False' }],
      sortable: false,
      pinnable: false,
      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: "True" },
        { label: "Inactive", value: "False" },
      ],
      renderCell: (params) => {
        return (
          params?.row?.status === "_skeleton_" ? <Skeleton width={"300px"} />
            : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch
                  checked={params.row.status === true ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }}
                  onChange={() => handleArchive(params.row)} />
              </Stack>

              <div className="actionButton">
                <UcActionComponent
                  moduleName="dynamic_table"
                  rowid={params.row.id}
                  addMoreData={params.row}
                  editLinkUrl=""
                  viewLinkUrl=""
                  isDelete={false}
                  editButton={handleEdit}
                  viewButton={false}
                  deleteButton={false}
                  // additionalAction={[{ id: 1, key: "assign_domain", title: "Manage Domain" }]} extraButton={onAdditionalAction}
                  deleteIconName="ArchiveIcon"
                />
              </div>
            </div>

        );
      },
    },
  ];

  const handleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleStatus = (evt) => {
    const { checked } = evt.target || evt;
    setFormValues({ ...formValues, 'status': checked })
  }


  const onSubmit = async (e) => {
    e.preventDefault()

    if (formValues.name === "" || formValues.status === "" || formValues.descriptionn === "") {
      setErrStatus(true)
    }

    if (formValues.name !== "" && formValues.status !== "" && formValues.descriptionn !== "") {
      const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
      let userId = rtm_user?.user?.data.id
      const addvalues = ID ? { ...formValues, updated_by: userId } : { ...formValues, created_by: userId }
      setErrStatus(false)
      setReload('submit')
      const res = await dataService.save_holiday(addvalues);
      if (res.data && res.data.status === 200) {
        toast.success(res.data.message)
        let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
        clearGridListing.current = true;
        setFilterQuery(str)
        setReload(true)
        setLoading(true)
        onCloseModal()
      } else {
        setFormLoading(false)
        toast.error(res.data.message)
      }
    }
  }

  const onView = (data) => {
    setHolidaysData({ ...data });
    setFormValues({ ...data })
    setActiveViewMode(true)
    setOpen(true)
  }

  // Edit Shift
  const handleEdit = (data) => {
    setHolidaysData({ ...holidaysData, ...data, updatedBy: 1 });
    setFormValues({ ...formValues, ...data, updatedBy: 1 })
    setID(data?.id)
    setOpen(true);
  }

  const handleClickArchive = async (data) => {
    let addvalues = {
      description: data.description,
      name: data.name,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id,
      id: data.id
    }
    let res = await api.put('/mst/holiday-list/' + data.id, addvalues)

    setArchiveOpen(false)
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      clearGridListing.current = true;
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      setReload(true)
      setLoading(true)
    } else {
      setFormLoading(false)
      toast.error(res.data.message)
    }
    setReload(false)
  }

  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  AddHoliday Modal
  const onAddHoliday = () => {
    setOpen(true)
    setEditable(true);
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setHolidaysData({});
    setFormValues(initialFormData)
    setActiveViewMode(false)
    if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    setErrStatus(false);
    setContextMenu(null);
  }

  useEffect(() => {
    setLoading(true);
    setReload(true);
    dispatch(holidaysListNew(filterQuery))
  }, [filterQuery]);

  //lazyloading 
  const loadServerRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      loadServerRows(params.viewportPageSize);
    }
  };

  // right click section
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);
    switch (actionType) {
      case 'edit':
        handleEdit(row)
        break;
    }
    setContextMenu(null);
  }

  // filter section
  const onFilterChange = (props) => {
    if (props.items.length > holidaysColumns.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" } };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }

  // sorting section
  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }

  // handle load data response
  if (loading === true && status === `${STATUSES.SUCCESS}_holidays_List`) {
    if (holidays_List.status === 200) {
      SET_MAX_ROW_LENGTH(holidays_List.data.count);
      if (clearGridListing.current === true) {
        setGridRows([...holidays_List.data.rows]);
        setSkeletonRows([])
      } else {
        setGridRows((pre) => [...pre, ...holidays_List.data.rows]);
        setSkeletonRows([])
      }
    } else {
      toast.error(holidays_List.message)
    }
    setLoading(false)
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClose = () => {
    setContextMenu(null);
    setFormLoading(false);
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setHolidaysData({});
  }



  return (
    <div className="jiraStatus">
      <div className="jiraStatusContainer">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px", marginTop: "20px" }}>
          <div style={{ fontSize: "20px" }}>
            <p>Holidays</p>
          </div>
          <div>
            <Stack direction="row">
              {(userRole === 'admin' || userRole === 'super-admin' || userRole === "hr") &&
                <Button variant="outlined" onClick={onAddHoliday}>Create<BorderColorTwoTone /></Button>}
              <RefreshButton api={holidaysListNew} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
            </Stack>
          </div>
        </div>

        <ToastContainer />

        <DataGridPro
          className="datagrid"
          filterMode="server"
          // loading={!!reload}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          sx={{
            height: 700,
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#438bf969",
            },
            ".actionButton": {
              display: 'none'
            },
            [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                ".actionButton": {
                  display: 'block'
                }
              }
            }
          }}
          rows={gridRows?.concat(skeletonRows)}
          columns={holidaysColumns}
          filterModel={filterModal}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
          components={{
            ColumnMenuIcon: MoreHoriz,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
            }
          }}
          onFilterModelChange={onFilterChange}
          onRowsScrollEnd={handleOnRowScrollEnd}
          hideFooter
        />

        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >
          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
        </Menu>

      </div>

      {/* add/edit/view */}
      <Dialog
        hideBackdrop
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={onCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <Button
          edge="start"
          color="inherit"
          onClick={onCloseModal}
          aria-label="close"
          style={{ position: "absolute", top: "10px", right: "10px" }}>
          <Close />
        </Button>

        <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
          {ID ? 'Edit' : activeViewMode === true ? "View" : 'Add New'} Holiday
        </DialogTitle>
        {/* view form*/}
        {activeViewMode === true ?
          <DialogContent>
            <div>
              <form className="regulazation-form">
                <div style={{ marginBottom: "50px" }}>
                  <ViewField
                    label={'Holiday Name'}
                    key={'name'}
                    type={1}
                    value={holidaysData.name}
                  />
                  <ViewField
                    label={'Description'}
                    key={'editor'}
                    fieldType={'editor'}
                    type={1}
                    value={holidaysData.description}
                  />
                  <ViewField
                    label={'Status'}
                    key={'status'}
                    type={1}
                    value={holidaysData.status}
                  />
                </div>
              </form>
            </div>
          </DialogContent>
          :
          <DialogContent>
            <form>
              <div className="add-new-user-form">
                <div className='formInput' style={{ flexBasis: "100%" }}>
                  <InputFields
                    label={"Holiday Name"}
                    name="name"
                    defaultValue={formValues.name}
                    type="text"
                    placeholder="Name"
                    onChange={handleChange}
                    errStatus={errStatus}
                    formErr={formErr.nameErr}
                  />
                </div>

                <InputTextareaField
                  label={"Description"}
                  defaultValue={formValues.description}
                  name="description"
                  onChange={handleChange}
                  rows="3"
                  placeholder="Description"
                  errStatus={errStatus}
                  formErr={formErr.descriptionErr}
                />

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography className={`cellWithStatus false`}>
                    Inactive
                  </Typography>
                  <Switch
                    checked={formValues.status === true ? true : false}
                    inputProps={{ 'aria-label': 'ant design' }}
                    onChange={handleStatus}
                  />
                  <Typography className={`cellWithStatus true`}>
                    Active
                  </Typography>
                </Stack>

              </div>
              <button className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                disabled={reload === "submit" ? true : false} onClick={onSubmit}>
                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
              </button>
            </form>
          </DialogContent>
        }
      </Dialog>

      <DeleteDialogModal
        open={archiveOpen}
        onClose={setArchiveOpen}
        heading={changeStatus}
        paragraph={`Are you sure to ${changeStatus} holiday`}
        handleArchive={handleClickArchive}
        id={archiveId}
      />


    </div>
  )
}

export default Holidays