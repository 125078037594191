import React, { useMemo, useEffect, useState, useRef } from "react";
import "./resource.scss";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { resourceColumns } from "../../components/datatable-coulms/tableCoulmns";
import { Link, useNavigate, } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Close, Edit } from "@mui/icons-material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { resourceList, resourceStatusUpdateById, resourceSyncByJira, allRoleList, allDepartmentList, allEmpTypeList, allResourcesList, defaultresourcelistColumn } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Menu, MenuItem, Skeleton, Switch, TablePagination, Tooltip, gridClasses } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import Sync from '@mui/icons-material/Sync';
import { toast, ToastContainer } from "react-toastify";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro } from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import moment from "moment";
const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role

const default_resource_list_Columns = LocalStorageDataService.get_default_resource_list_column()

const skeletonBase = {
  emp_code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  display_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', shift: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', contact_no: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  jira_account: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', department: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', tms_role: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  employment_type: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', reporting_manager: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',
  email: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'
};

const filterableColumns = ['emp_code', 'name', 'display_name', 'shift', 'contact_no', 'jira_account', 'department', 'tms_role', 'email', 'reporting_manager', 'status'];


const ResourcesList = () => {
  const navigate = useNavigate()
  const { REACT_APP_ENV } = process.env;
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  const { data, status, error, statusUpdateResource, syncResource, roleList, departmentList, empTypeList, activeResourceFilter, defaultresourcelistColumns } = useSelector(state => state.resource)
  const { data: list } = data;
  const [syncLoading, setSyncLoading] = useState(false)
  const [limit, setLimit] = useState(25);
  const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin' || tmsRole.role_key === 'hr') ? `{"skip":0, "limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.RESOURCE_LIST_COLUMNS}]}` : `{"filter":{"id":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}}`
  const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
  const [filterRoleQuery, setFilterRoleQuery] = useState([])
  const [filterDepartmentQuery, setFilterDepartmentQuery] = useState([])
  const [filterEmpTypeQuery, setFilterEmpTypeuery] = useState([])
  const [filterActiveResourcesQuery, setFilterActiveResourcesQuery] = useState([])
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  //columns visibility
  // const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, tms_role_name: false });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_resource_list_Columns && default_resource_list_Columns.all_resource_column ? default_resource_list_Columns.all_resource_column : {
    department_id: false,
    user_id: false,
    id: false,
    shift: false,
  });

  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [changeStatus, setChangeStatus] = useState('');
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)

  //// grid states and ref starts
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let gridTotalRows = useRef(null);
  let editID = useRef(null);
  let isAnyChange = useRef({});
  // grid states and ref ends

  const setKeyForList = (obj) => {
    let data;
    if (default_resource_list_Columns) {
      data = { 'all_resource_column': obj, 'reported_resource_column': default_resource_list_Columns.reported_resource_column, 'resourcelist_column': default_resource_list_Columns.resourcelist_column, 'task_column': default_resource_list_Columns.task_column }
    } else {
      data = { 'all_resource_column': obj }
    }
    if (defaultresourcelistColumns.reported_resource_column) {
      data['reported_resource_column'] = defaultresourcelistColumns.reported_resource_column
    }
    if (defaultresourcelistColumns.resourcelist_column) {
      data['resourcelist_column'] = defaultresourcelistColumns.resourcelist_column
    }
    if (defaultresourcelistColumns.task_column) {
      data['task_column'] = defaultresourcelistColumns.task_column
    }
    dispatch(defaultresourcelistColumn(data))
  }
  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setSyncLoading(false)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    dispatch(allRoleList())
    dispatch(allDepartmentList())
    dispatch(allEmpTypeList())
    dispatch(allResourcesList())
    // dispatch(resourceList(roleWiseFilter))

    if (localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`) !== null) {
      dispatch(resourceList(JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`))))
    } else {
      dispatch(resourceList(roleWiseFilter))
    }
    // eslint-disable-next-line
  }, [])


  window.addEventListener("beforeunload", function (event) {
    if (localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`) !== null) {
      localStorage.removeItem(`${REACT_APP_ENV}_resourcefilter`);
    }
  });

  // const handleDelete = (id) => {
  //   setDeleteId(id)
  //   if (id && id.status === true) {
  //     setUpdateStatus("InActive")
  //   } else {
  //     setUpdateStatus("Active")
  //   }
  //   setOpen(true)
  // }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_statusUpdateResource` && statusUpdateResource && statusUpdateResource.status) {
    setSyncLoading(false)
    if (statusUpdateResource.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      // setFilterQuery(str)
      toast.success(statusUpdateResource.message)
      // dispatch(resourceList(roleWiseFilter))
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(statusUpdateResource.message)
    }
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_syncResource` && syncResource && syncResource.message) {
    setSyncLoading(false)
    if (syncResource.status === 200) {
      toast.success(syncResource.message)
    } else {
      toast.error(syncResource.message)
    }
    setTimeout(() => {
      dispatch(resourceList(roleWiseFilter))
    }, 500);
  }

  if (status === `${STATUSES.SUCCESS}_roleList` && filterRoleQuery && filterRoleQuery.length === 0 && roleList && roleList.data && roleList.data.rows) {
    let array = []
    roleList.data && roleList.data.rows.map(e => { return array.push({ value: e.id, label: e.role_name, id: e.id }) })
    array.length > 0 && setFilterRoleQuery(array)
    array.length > 0 && setOptions({ ...options, tms_role: array })
  }


  if (status === `${STATUSES.SUCCESS}_departmentList` && filterDepartmentQuery && filterDepartmentQuery.length === 0 && departmentList && departmentList.data && departmentList.data.rows) {
    let array = []
    departmentList.data && departmentList.data.rows.map(e => { return array.push({ label: e.name, value: e.id, id: e.id }) })
    array.length > 0 && setFilterDepartmentQuery(array)
    array.length > 0 && setOptions({ ...options, department: array })
  }

  if (status === `${STATUSES.SUCCESS}_empTypeList` && filterEmpTypeQuery && filterEmpTypeQuery.length === 0 && empTypeList && empTypeList.data && empTypeList.data.rows) {
    let array = []
    empTypeList.data && empTypeList.data.rows.map(e => { return array.push({ value: e.id, label: e.employment_type, id: e.id }) })
    array.length > 0 && setFilterEmpTypeuery(array)
    array.length > 0 && setOptions({ ...options, employment_type: array })
  }

  if (status === `${STATUSES.SUCCESS}_resourceList` && filterActiveResourcesQuery && filterActiveResourcesQuery.length === 0 && activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows) {
    let array = []
    activeResourceFilter.data && activeResourceFilter.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name + "(" + e.email + ")", id: e.id }) })
    array.length > 0 && setFilterActiveResourcesQuery(array)
    array.length > 0 && setOptions({ ...options, reporting_manager: array })
  }

  const handleClickArchive = (data) => {
    let id = data.id;
    const request = {
      status: !data.status,
    };
    setSyncLoading(true);

    if (archiveOpen === true) {
      // Update the row status in the state without making an API call yet
      let updatedRows = gridRows.map((row) =>
        row.id === id ? { ...row, status: request.status } : row
      );
      setGridRows(updatedRows);

      // Now make the API call to update the actual data in the backend
      dispatch(resourceStatusUpdateById({ id, request }));
      setArchiveOpen(false);
    }
  };


  const syncResources = () => {
    setSyncLoading(true)
    dispatch(resourceSyncByJira())
  }

  const actionColumn = [
    {
      field: "emp_code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Emp. Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("emp_code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.emp_code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.emp_code}
            </div>
        );
      },
    },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.name}
            </div>
        );
      },
    },
    {
      field: "display_name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Display Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("display_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.display_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.display_name}
            </div>
        );
      },
    },
    {
      field: "email",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Email</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("email")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 300,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.email === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.email}
            </div>
        );
      },
    },
    {
      field: "is_interviewer",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Is Interviewer</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("is_interviewer")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 175,
      sortable: false,
      type: 'singleSelect',
      valueOptions: [{ label: 'Yes', value: 'True' }, { label: 'No', value: 'False' }],
      renderCell: (params) => {
        return (
          params.row.is_interviewer === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.is_interviewer ? 'Yes' : 'No'}
            </div>
        );
      }
    },
    {
      field: "shift",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Shift</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("shift")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 300,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.shift === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.shift?.shift_name ? params.row.shift.shift_name : params.row.shift} &nbsp;
              {
                params.row.shift ?
                  (params.row.shift_start_time ? "(" + moment("10/10/2022 " + params.row.shift_start_time).format('LT') : "") + " - " + (params.row.shift_end_time ? moment("10/10/2022 " + params.row.shift_end_time).format('LT') : "") + ")" : ""
              }
            </div>
        );
      },
    },
    {
      field: "contact_no",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Contact No.</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("contact_no")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.contact_no === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.contact_no}
            </div>
        );
      }
    },
    {
      field: "jira_account",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Jira Account</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("jira_account")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.jira_account === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.jira_account}
            </div>
        );
      }
    },
    {
      field: "department",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Department</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("department")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      valueOptions: filterDepartmentQuery,
      type: 'singleSelect',
      renderCell: (params) => {
        return (
          params.row.department === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.department && params.row.department.name ? params.row.department.name : params.row.department_name}
            </div>
        );
      },
    },
    {
      field: "tms_role",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Role</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("tms_role")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 200,
      sortable: false,
      valueOptions: filterRoleQuery,
      type: 'singleSelect',
      renderCell: (params) => {
        return (
          params.row.tms_role === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.tms_role && params.row.tms_role.role_name ? params.row.tms_role.role_name : params.row.tms_role_name}
            </div>
        );
      },
    },
    {
      field: "employment_type",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Employment Type</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("employment_type")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 250,
      type: 'singleSelect',
      valueOptions: filterEmpTypeQuery,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.employment_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.employment_type && params.row.employment_type.employment_type ? params.row.employment_type.employment_type : params.row.employment_type_name}
            </div>
        );
      },
    },
    {
      field: "reporting_manager",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Manager</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("reporting_manager")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 250,
      sortable: false,
      type: 'singleSelect',
      valueOptions: filterActiveResourcesQuery,
      renderCell: (params) => {
        return (
          params.row.reporting_manager === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360} /> :
            <div className="cellWithStatus">
              {params.row.reporting_manager_name}
            </div>
        );
      },
    },

    {
      field: "status",
      sortable: false,
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 160,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      renderCell: (params) => {
        return (
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160} />

            :
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
            </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="resource"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl={`edit/${params.row.id}`}
              viewLinkUrl={`view/${params.row.id}`}
              isDelete={true}
              resfilterQuery={filterQuery}
              deleteIconName="ArchiveIcon"
              editButton={false}
              viewButton={false}
              deleteButton={handleArchive}
              activeInactive={"resourceList"}
              status={params.row.status}
            />
          </div>
        );
      },
    },
  ];

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const onFilterChange = (props) => {
    if (props.items.length > actionColumn.length)
      return;
    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.RESOURCE_LIST_COLUMNS}]}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.RESOURCE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }



  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={(e) => handleFilterChange(e)} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
    }),
    // eslint-disable-next-line
    [actionColumn]
  );


  const handleClose = () => {
    setArchiveOpen(false)
    setContextMenu(null);
  }


  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = 25;
    updatedFilter.order_by["column"] = "-id"
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip']
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {
      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }
    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }

  if (reload === true && status === STATUSES.SUCCESS && list && list.message) {
    if (list.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...list.rows].sort(item=>item.status ? -1 : 1));
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(list.count);
      }
      else {
        setGridRows((pre) => [...pre, ...list.rows].sort(item=>item.status ? -1 : 1));
        SET_MAX_ROW_LENGTH(list.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(list.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };


  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);

    switch (actionType) {
      case 'status':
        handleArchive(row);
        break;

      case 'edit':
        navigate(`edit/${row.id}`)
        break;
      case 'view':
        navigate(`view/${row.id}`)
        break;

    }

    setContextMenu(null);

  }

  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setReload(true);
    setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    dispatch(resourceList(filterQuery))
  }, [filterQuery])

  return (
    <div className="resource">
      <div className="resourceContainer">
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>{CONSTANTS.RESOURCE.LIST}</h3>
            <div>
              <Tooltip title="Jira Status ">
                <button className="btn-add-new" onClick={() => syncResources()} >
                  <Sync />
                </button>
              </Tooltip>
              <Tooltip title="Add new Resource">
                <Link to="add" className="btn-add-new">
                  <AddIcon />
                </Link>
              </Tooltip>
              <RefreshButton api={resourceList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 
            </div>
          </div>
          {(syncLoading === true || status === STATUSES.LOADING) && <LoaderForm />}
          {/* {
            !reload &&
            <DataGrid
              columnVisibilityModel={defaultresourcelistColumns && defaultresourcelistColumns.all_resource_column ? defaultresourcelistColumns.all_resource_column : columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
              }
              className="datagrid"
              rows={list && list.rows ? list.rows : []}
              columns={resourceColumns.concat(columns)}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              onSortModelChange={(sort) => handleSort(sort)}
              hideFooterPagination={true}
            />
          } */}
          <DataGridPro
            className="datagrid"
            filterMode="server"
            loading={ skeletonRows.length === 0 && !!reload}
            columnVisibilityModel={defaultresourcelistColumns && defaultresourcelistColumns.all_resource_column ? defaultresourcelistColumns.all_resource_column : columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
            }
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            rows={gridRows?.concat(skeletonRows)}
            columns={actionColumn}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            // filterModel={filterModal}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooter
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
          />
        </div>


        {/* for delete popup */}
        <DeleteDialogModal
          open={archiveOpen}
          onClose={handleClose}
          heading={changeStatus}
          paragraph={`Are You Sure To ${changeStatus} this Resource`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >
          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>
          <MenuItem onClick={() => handleMenuAction('view')} sx={{ color: 'green' }} > <RemoveRedEyeIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> View</MenuItem>

        </Menu>
      </div>

    </div>
  )
}

export default ResourcesList