
import "./ReviseSalary.scss"
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobBudgetReviseCreate, jobBudgetReviseList } from "../../../redux/features/jobPostSlice";
import { STATUSES } from "../../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from "@mui/icons-material";
import { InputFields } from "../../common-function/InputFields";

const initialFormData = Object.freeze({
    reason_for_cancel: "",
});

const formErrData = Object.freeze({
    reason_for_cancelErr: "Reason for cancle revise budget is required"
});

const CancelBudget = ({ open, setOpen, jobData }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({ ...initialFormData, client_budget_from: jobData.client_budget_from, client_budget_to: jobData.client_budget_to })
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, revised_Salary_Create } = useSelector(state => state.jobpost)
    const [reload, setReload] = useState(false)

    React.useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_revised_Salary_Create` && revised_Salary_Create && revised_Salary_Create.message) {
        if (revised_Salary_Create.status === 200) {
            toast.success(revised_Salary_Create.message)
            setOpen(false);
            setFormValues(initialFormData);
        } else {
            toast.error(revised_Salary_Create.message)
        }
        setReload(false)
    }

    const handleClose = () => {
        setOpen(false);
        setErrStatus(false);
        setFormValues(initialFormData);
    };

    const handleChangeText = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.reason_for_cancel === "") {
            setErrStatus(true)
        }

        if (formValues.reason_for_cancel !== "") {
            setErrStatus(false)
            setReload(true)

            let addvalues = {
                job: jobData.id,
                approval_status: 6,
                reason_for_cancel: formValues.reason_for_cancel
            }

            dispatch(jobBudgetReviseCreate(addvalues))
            setTimeout(() => {
                dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${jobData.id},"operation": "eq"}},"skip":0,"limit":25}`))
            }, 1000);
        }

    }

    return (

        <Dialog
            hideBackdrop
            // fullWidth
            // maxWidth={"l"}
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Button
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Cancle Revise Salary Budget</DialogTitle>
            <DialogContent>
                <form>
                    <div className="add-new-user-form" style={{ display: "block" }}>

                        <InputFields
                            label={'Reason for Cancel'}
                            name="reason_for_cancel"
                            defaultValue={formValues.reason_for_cancel}
                            type="text"
                            placeholder="Reason for Cancel"
                            onChange={handleChangeText}
                            errStatus={errStatus}
                            formValues={formValues.reason_for_cancel}
                            formErr={formErr.reason_for_cancelErr}
                        />
                    </div>
                    <Button style={{ minWidth: '17%' }} className="submit-modal" onClick={submitData}>Submit</Button>
                </form>
            </DialogContent>
        </Dialog>

    )
}

export default CancelBudget