export const userInputs = [
  {
    id: 1,
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: 2,
    label: "Name and surname",
    type: "text",
    placeholder: "John Doe",
  },
  {
    id: 3,
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: 4,
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: 5,
    label: "Password",
    type: "password",
  },
  {
    id: 6,
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: 7,
    label: "Country",
    type: "text",
    placeholder: "USA",
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

export const editInput = [
  {
    id: 1,
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Jane",
  },
  {
    id: 7,
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  {
    id: 2,
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "john.doe@gmail.com",
    disabled: ""
  },
  {
    id: 3,
    name: "phone",
    label: "Phone",
    type: "text",
    placeholder: "260 975 502 085",
  },
  {
    id: 4,
    name: "address",
    label: "Address",
    type: "text",
    placeholder: "256 Collect House, Buteko Avenue , Ndola",
  },
  {
    id: 5,
    name: "city",
    label: "City",
    type: "text",
    placeholder: "Zambia",
  },
  {
    id: 6,
    name: "country",
    label: "Country",
    type: "text",
    placeholder: "Zambia",
  },
];

// form fileds for user type
export const userTypeInputs = [
  {
    id: 1,
    label: "User Type",
    type: "text",
    placeholder: "Type Name",
    name: "type_name"
  },
  {
    id: 2,
    label: "User Type Key",
    type: "text",
    placeholder: "Type Key",
    name: "type_key"
  }
]

// form fileds for department
export const departmentInputs = [
  {
    id: 1,
    label: "Department Name",
    type: "text",
    placeholder: "Department Name",
    name: "name"
  },
  {
    id: 2,
    label: "Code",
    type: "text",
    placeholder: "Code",
    name: "code"
  },
  {
    id: 3,
    label: "Description",
    type: "text",
    placeholder: "description",
    name: "description"
  },
]

// form fileds for role
export const roleInputs = [
  {
    id: 1,
    label: "Role Name",
    type: "text",
    placeholder: "Name",
    name: "role_name"
  },
  {
    id: 2,
    label: "Role Key",
    type: "text",
    placeholder: "Key",
    name: "role_key"
  },
  {
    id: 3,
    label: "Role Description",
    type: "text",
    placeholder: "Description",
    name: "role_description",
  },
]

// form fileds for credentical heads
export const credentialHeadInputs = [
  {
    id: 1,
    label: "Head Name",
    type: "text",
    placeholder: "Head Name",
    name: "head_name"
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
    name: "description",
  },
]