import React, { useEffect, useRef, useState } from "react";
import "./departments.scss"
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartmentList, getDepartmentList, DepartmentUpdate, DepartmentCreate, getDepartmentById } from '../../redux/features/departmentSlice';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Button, DialogTitle, Tooltip } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Dialog, DialogContent, Menu, MenuItem, Skeleton, Stack, Switch } from "@mui/material";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Edit } from "@mui/icons-material";
import ArchiveIcon from '@mui/icons-material/Archive';
import { InputFields } from "../../components/common-function/InputFields";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { Close } from "@mui/icons-material";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";


const formErrData = Object.freeze({
  nameErr: 'Department name is required',
  codeErr: "Code is required",
  descErr: "Description is required",
  statusErr: "Status is required",
});

const initialFormData = Object.freeze({
  name: "",
  code: "",
  description: "",
  status: true,
  created_by: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().id,
});

const skeletonBase = { setting_display_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', setting_key: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', setting_value: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['setting_display_name', 'setting_key', 'key_value', 'status'];

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


function AllDepartments() {
  const dispatch = useDispatch();

  const { departmentAllList, status, error, department_Create, department_Update, department_ById } = useSelector(state => state.department)
  // const { data: list } = data

  const [open, setOpen] = useState(false);
  const [limit, _] = useState(25);
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`)
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [reload, setReload] = useState(false);
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');

  const [showloader, setShowLoader] = useState(false)
  const [formValues, setFormValues] = useState(initialFormData)
  const [formErrors, setFormErrors] = useState(formErrData);
  const [errStatus, setErrStatus] = useState(false);
  const [validateErr, setValidateErr] = useState("")
  const [ID, setID] = useState("");

  // grid states and ref starts
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let gridTotalRows = useRef(null);
  let editID = useRef(null);
  let isAnyChange = useRef({});
  // grid states and ref ends



  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (ID) { setID(0); setFormValues(initialFormData) }
    setOpen(false);
    setFormValues(initialFormData);;
    setErrStatus(false);
    setContextMenu(null);
  }


  const departmentColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Department Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 360,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {

        return (

          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div>
              {params.row.name}
            </div>
        );
      },
    },
    {
      field: "code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 360,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div>
              {params.row.code}
            </div>
        );
      },
    },
    {
      field: "description",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 360,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div>
              {params.row.description}
            </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 160,
      sortable: false,
      pinnable: false,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      renderCell: (params) => {
        return (
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160} />
            :
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
            </div>
        );
      },
    },

  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="department"
              rowid={params.row.id}
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={false}
              editButton={handleClickEdit}
              viewButton={false}
              deleteButton={false}
            />
          </div>
        );
      },
    },
  ];



  const handleChange = (evt) => {
    const { name, value, checked } = evt.target;
    setFormValues({
      ...formValues,
      [name]: name === 'status' ? checked : value,
    });

  }

  const validate = (name, code, description) => {
    const errors = {}
    //for blank space and special character
    if (!name) {
      errors.name = "Required"
    } else if (!/^[^0-9][a-zA-Z0-9_ ]+$/.test(name)) {
      errors.name = 'Invalid Department name'
    } else {
      errors.name = true
    }
    // for blank space and special character
    if (!code) {
      errors.code = 'Required'
    } else if (!/^[a-zA-Z0-9]*$/.test(code)) {
      errors.code = 'Invalid Code'
    } else {
      errors.code = true
    }
    //for blank space and special character
    if (!description) {
      errors.description = 'Required'
    } else if (!/^[^\s].+[^\s]$/.test(description)) {
      errors.description = 'Invalid Description'
    } else {
      errors.description = true
    }

    return errors;
  }

  const handleClickEdit = (id) => {
    dispatch(getDepartmentById(id))
    setReload('edit_data')
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    const addvalues = formValues
    if (!formValues.name || !formValues.code || !formValues.description || formValues.status === "") {
      setErrStatus(true)
      // toast.error("Please fill in all fields before submitting")
    }
    if (formValues.name !== "" && formValues.code !== "" && formValues.description !== "" && formValues.status !== "") {
      setErrStatus(false)
      setFormErrors("")
      const isValidate = validate(formValues.name, formValues.code, formValues.description)
      setShowLoader(true)
      let isValid = Object.values(isValidate).every((item) => {
        return item === true
      })
      if (isValid === true) {
        setValidateErr("")
        if (ID) {
          let copyRows = [...gridRows];
          editID.current = copyRows.find(item => item.id === ID);
          let index = copyRows.findIndex(item => item.id === ID);
          copyRows.splice(index, 1, { ...skeletonBase, id: ID });
          setGridRows(copyRows);
          dispatch(DepartmentUpdate({ ID, addvalues }))
          setReload("update_department")
        } else {
          gridTotalRows.current = gridRows.length;
          dispatch(DepartmentCreate(addvalues))
          setReload("create_department")
        }
      } else {
        setValidateErr(isValidate)
        setErrStatus(true)
      }
    }
    setTimeout(() => {
      setShowLoader(false)
    }, 500)
  }

  if (reload === "create_department" && status === `${STATUSES.SUCCESS}_department_Create` && department_Create && department_Create.message) {
    if (department_Create.status === 200) {
      toast.success(department_Create.message)
      setGridRows([]);
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: JSON.parse(filterQuery).skip ===0 ? 25 : JSON.parse(filterQuery).skip  }));
      setFilterQuery(str)
      handleClose()
    } else {
      toast.error(department_Create.message)
    }
    setReload(false)
  }

  if (reload === "update_department" && status === `${STATUSES.SUCCESS}_department_Update` && department_Update && department_Update.message) {
    if (department_Update.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      toast.success(department_Update.message)
      handleClose()
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(department_Update.message)
    }
    setReload(false)
  }

  const onFilterChange = (props) => {
    if (props.items.length > actionColumn.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: 25, order_by: { column: "-status" }, columns: DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };

      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }
  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }


  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClickArchive = (data) => {
    setReload(true)
    setArchiveOpen(false)
    let addvalues = {
      name: data.name,
      code: data.code,
      description: data.description,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }

    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    setReload("update_department")
    dispatch(DepartmentUpdate({ ID, addvalues }))
  }

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);

    switch (actionType) {
      case 'status':
        handleArchive(row);
        break;

      case 'edit':
        handleClickEdit(row.id)
        break;

    }

    setContextMenu(null);

  }


  if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_department_ById` && department_ById && department_ById.data) {
    let editData = department_ById.data
    setFormValues({
      name: editData.name,
      code: editData.code,
      description: editData.description,
      status: editData.status,
      id: editData?.id,
    })
    setID(editData.id)
    setOpen(true)
    setReload(false)
  }

  if (reload === true && status === `${STATUSES.SUCCESS}_department_list` && departmentAllList && departmentAllList?.message) {
    if (departmentAllList.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...departmentAllList.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(departmentAllList.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...departmentAllList.data.rows]);
        SET_MAX_ROW_LENGTH(departmentAllList.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(departmentAllList.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    setReload(true);
    dispatch(getAllDepartmentList(filterQuery))
  }, [filterQuery])


  return (

    <div className="new">
      <div className="newContainer">
        <div className="datatable">
          <div className="top-headings">
            <h3>All Departments</h3>
            <div>
              <Tooltip title="Add New Department ">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
              </Tooltip>
            </div>
          </div>
          <ToastContainer />
          <div className="custom-pagination-class">
          </div>
          <DataGridPro
            className="datagrid"
            filterMode="server"
            loading={!!reload}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            rows={gridRows?.concat(skeletonRows)}
            columns={departmentColumns.concat(actionColumn)}
            filterModel={filterModal}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooter
          />

        </div>
        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <Button
            edge="start"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Department </DialogTitle>

          <DialogContent>
            <form onSubmit={onSubmit}>
              <div className="add-new-user-form">
                <div className="formInput">
                  <InputFields
                    label={'Department Name'}
                    name="name"
                    defaultValue={formValues.name}
                    type="text"
                    placeholder="Department name"
                    onChange={handleChange}
                    errStatus={errStatus}
                    formValues={formValues}
                    formErr={formErrors.nameErr}
                  />
                  {errStatus === true && validateErr && validateErr.name !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.name}</p>}
                </div>

                <div className="formInput">
                  <InputFields
                    label={'Code'}
                    name="code"
                    defaultValue={formValues.code}
                    type="text"
                    placeholder="Code"
                    onChange={handleChange}
                    errStatus={errStatus}
                    formValues={formValues}
                    formErr={formErrors.codeErr}
                  />
                  {errStatus === true && validateErr && validateErr.code !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.code}</p>}
                </div>

                <div className="formInput">
                  <InputFields
                    label={'Description'}
                    name="description"
                    defaultValue={formValues.description}
                    type="text"
                    placeholder="Description"
                    onChange={handleChange}
                    errStatus={errStatus}
                    formValues={formValues}
                    formErr={formErrors.descErr}
                  />
                  {errStatus === true && validateErr && validateErr.description !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.description}</p>}
                </div>

                <div className="formInput">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>Inactive</p>
                      <Switch name="status" checked={formValues.status} onChange={handleChange} />
                      <p>Active</p>
                    </div>
                    {errStatus === true && formValues && formErrors && formErrors.statusErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formValues.status === "" ? formErrors.statusErr : ""}</p>}

                  </FormControl>
                </div>
              </div>
              <button type="submit" className={showloader === true ? 'btn-disable' : 'submit-modal'} disabled={showloader === "submit" ? true : false}>Submit</button>

            </form>
          </DialogContent>
        </Dialog>

        {/* for delete popup */}
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are You Sure To ${changeStatus} This Department`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />

        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >

          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

        </Menu>
      </div>
    </div>
  )
}
export default AllDepartments