import "./attendacecalender.scss"
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import CloseIcon from '@mui/icons-material/Close';
import AttendanceDetails from "./AttendanceDetails";
import { useDispatch, useSelector } from "react-redux";
import { allResources, getTeamAttendance, attandanceDetailById } from '../../redux/features/teamAttendanceSlice';
import { useEffect, useMemo, useState } from "react";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Paper, Skeleton, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Close, Edit, ArchiveIcon, RemoveRedEye } from "@mui/icons-material";
import Draggable from "react-draggable";
const user = LocalStorageDataService.userDetail();


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-modal"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} />
    </Draggable>
  );
}
const SubTeamAttendanceList = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-punchin_date"},"skip":0,"limit":25,"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`)
  const [filterResourceList, setFilterResourceList] = useState([]);
  const [limit, setLimit] = useState(25);

  const [modalView, setModelView] = useState(false)
  const [attendaceDetails, setAttendaceDetails] = useState([])
  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [ID, setID] = useState("");

  const { teamAttendanceList, status, resourceList, attendaceDetailsById, teamAttendance_list } = useSelector(state => state.teamAttendance)
  const { data: list } = teamAttendanceList

  // const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

  if (reload === true && filterResourceList && filterResourceList.length === 0 && status === `${STATUSES.SUCCESS}_resourceList` && resourceList && resourceList.data && resourceList.data.rows && resourceList.data.rows.length > 0) {
    setLoader(false)
    let array = []
    resourceList.data && resourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
    array.length > 0 && setFilterResourceList(array)
    array.length > 0 && setOptions({ ...options, resource_id: array })
  }

  useEffect(() => {
    // dispatch(getTeamAttendance(filterQuery))
    dispatch(allResources())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (modalView === true && status === `${STATUSES.SUCCESS}_attendaceDetailsById` && attendaceDetailsById && attendaceDetailsById.data && attendaceDetails.length === 0) {
    const attenance_details_data = attendaceDetailsById.data && attendaceDetailsById.data.rows;
    setAttendaceDetails(attenance_details_data)
  }


  if (reload === true && status === STATUSES.SUCCESS && teamAttendance_list && teamAttendance_list.message) {
    if (teamAttendance_list.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...teamAttendance_list.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(teamAttendance_list.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...teamAttendance_list.data.rows]);
        SET_MAX_ROW_LENGTH(teamAttendance_list.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(teamAttendance_list.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  //custom pagination
  // const handlePagination = (data) => {
  //   setCurrentPage(data)
  //   let newFilterData = JSON.parse(filterQuery);
  //   newFilterData['skip'] = data * pageSize
  //   newFilterData['limit'] = limit
  //   //setLimit(limit)
  //   setFilterQuery(JSON.stringify(newFilterData))
  //   dispatch(getTeamAttendance(JSON.stringify(newFilterData)))
  // }

  // const handlePageSizeChange = (newPageSize) => {
  //   setPageSize(newPageSize.target.value)
  //   setCurrentPage(0)
  //   setLimit(newPageSize.target.value)
  //   let newFilterData = JSON.parse(filterQuery);
  //   newFilterData['skip'] = 0
  //   newFilterData['limit'] = newPageSize.target.value
  //   setFilterQuery(JSON.stringify(newFilterData))
  //   dispatch(getTeamAttendance(JSON.stringify(newFilterData)))
  // }

  // function CustomPagination() {
  //   return (
  //     <TablePagination
  //       component="div"
  //       count={(list && list.rows && list.count) || 0}
  //       page={currentPage}
  //       rowsPerPage={pageSize}
  //       rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
  //       onPageChange={() => setCurrentPage(pageSize)}
  //       onRowsPerPageChange={handlePageSizeChange}
  //       ActionsComponent={(subProps) =>
  //         <TablePaginationActions {...subProps}
  //           handlePagination={handlePagination}
  //         />}
  //     />
  //   );
  // }

  // filter
  // const onFilterChange = (filterModel, r) => {

  //   if (filterModel.items.length) {
  //     setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
  //   }

  //   if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
  //     setReload(true);
  //     setTimeout(() => {
  //       setReload(false)
  //       setCurrentPage(0)
  //       // setPageSize(10)
  //       setDefaultValue({})
  //       setFilterQuery(`{order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`)
  //       dispatch(getTeamAttendance(`{"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`))
  //       setDefaultValue("")
  //     }, 0);
  //   }
  //   if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
  //     setFieldName(filterModel.items[0].columnField)
  //   }
  //   if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
  //     let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
  //       filterModel.items[0].operatorValue === "equals" ? 'ieq' :
  //         filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
  //           filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
  //             filterModel.items[0].operatorValue === "is" ? 'eq' :
  //               filterModel.items[0].operatorValue
  //     const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`
  //     setFilterQuery(filterData)
  //     setCurrentPage(0)
  //     dispatch(getTeamAttendance(filterData))
  //   }
  //   // if (filterModel.items && filterModel.items.length === 0) {
  //   //   setCurrentPage(0)
  //   //   // setPageSize(10)
  //   //   setDefaultValue({})
  //   //   setFilterQuery(`{order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`)
  //   //   dispatch(getTeamAttendance(`{"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`))
  //   //   setDefaultValue("")
  //   // }
  // }

  const handleClose = () => {
    if (ID) { setTimeout(() => { setID(0) }, 400); }
    setContextMenu(null)
  }
  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);

    switch (actionType) {
      case 'view':
        viewDetails(row);
        break;
    }
    setContextMenu(null);
  }
  // const handleChange = (evt) => {
  //   const { name, value } = evt.target
  //   if (name === 'remark') {
  //     setManagerRemark(value)
  //   } else {
  //     setRemark(value);
  //   }

  // }

  const viewDetails = async (data) => {
    setAttendaceDetails([])
    if (data && data.id) {
      setModelView(true)
      dispatch(attandanceDetailById(data.id))
    }
  }

  const closeModal = () => {
    setModelView(false)
    setAttendaceDetails([])
    dispatch(attandanceDetailById(false))
  }


  const teamAttendanceColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "emp_code",
      headerName: <Box justifyContent={"center"}>
        <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Emp Code</Typography>
      </Box>,
      width: 250, minWidth: 200, maxWidth: 250, sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          params.row.emp_code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.resource__name && params.row.resource__emp_code ? params.row.resource__emp_code : ""}</Typography>
            </div>
        );
      },
    },
    {
      field: "resource_id",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Name</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("id")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 250, minWidth: 200, maxWidth: 250, sortable: false,
      type: 'singleSelect',
      valueOptions: filterResourceList,
      renderCell: (params) => {
        return (
          params.row.resource_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">
                {params.row.resource__name ? params.row.resource__name : ""}
              </Typography>
            </div>
        );
      },
    },
    {
      field: "punchin_date", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch In Date</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("punchin_date")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false, type: 'date',
      renderCell: (params) => {
        return (
          params.row.punchin_date === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.punchin_date}</Typography>
            </div>
        );
      },
    },
    {
      field: "punchin_time", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch In Time</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("punchin_time")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.punchin_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.punchin_time}</Typography>
            </div>
        );
      },
    },
    {
      field: "break_duration_minutes", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Break Duration</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("break_duration_minutes")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.break_duration_minutes === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.break_duration_minutes}</Typography>
            </div>
        );
      },
    },
    {
      field: "work_duration_minutes", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Work Duration</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("work_duration_minutes")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.work_duration_minutes === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.work_duration_minutes}</Typography>
            </div>
        );
      },
    },
    {
      field: "punch_out_date", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch Out Date</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("punch_out_date")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.punch_out_date === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.punch_out_date}</Typography>
            </div>
        );
      },
    },
    {
      field: "punch_out_time", headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch Out Time</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("punch_out_time")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>, width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.punch_out_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.punch_out_time}</Typography>
            </div>
        );
      },
    },
    {
      field: "action",
      headerName: <Box justifyContent={"center"}>
        <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Action</Typography>
      </Box>,
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="teamAttendance"
              rowid={params.row.id}
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={false}
              deleteIconName=""
              editButton={false}
              viewButton={viewDetails}
              addMoreData={params.row}
              deleteButton={false} />
          </div>
        );
      },
    },
  ]

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    teamAttendanceColumns.map((col) => {
      if (col.field === "resource_id") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                && CustomFilterInputValue

            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [teamAttendanceColumns]
  );

  return (
    <div className="listing">
      <div className="listingContainer">
        <div className="datatable">

          {/* <div className="datatableTitle">
            <h3>Team Attendance</h3>
            <div>
              <RefreshButton api={getTeamAttendance} filter={filterQuery} style={{ marginRight: 5 }} />
              <Tooltip title="Back">
                <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
              </Tooltip>
            </div>
          </div> */}
          <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
            <Stack direction={'row'}>
              <Typography className="headerTitleName" variant="h3">Team Attendance</Typography>
            </Stack>
            <Stack direction={'row'}>
              <Tooltip title="Add Party List">
                <Button size="small" variant="outlined" onClick={() => navigate(-1)} ><ArrowBackIcon /></Button>
              </Tooltip>
              <RefreshButton api={getTeamAttendance} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
            </Stack>
          </Stack>
          <ToastContainer />
          {/* <div className="custom-pagination-class">
            <CustomPagination />
          </div> */}
          {/* {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
          {
            !reload && 
            <DataGrid
              className="datagrid"
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              rows={list && list.rows ? list.rows : []}
              columns={columns}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              hideFooterPagination={true}
            />
          } */}
          <Stack direction={'row'} mt={2}>
            <DataGridPro
              className="datagrid"
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              rows={gridRows.concat(skeletonRows)}
              columns={columns}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              disableSelectionOnClick
              components={{
                // Pagination: CustomPagination,
                // LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                ColumnSortedDescendingIcon: UnfoldMoreIcon,
              }}
              loading={!!reload}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                }
              }}
              onSortModelChange={(sort) => handleSort(sort)}
              hideFooterPagination={true}
              onRowsScrollEnd={handleOnRowScrollEnd}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              sx={{
                boxShadow: '1px 13px 31px -15px gray',
                borderRadius: '15px',
                height: '700px',
                '& .MuiDataGrid-cell:hover': {
                  color: '#000',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#1B3764',
                  fontWeight: '650'
                },
                '& .even.MuiDataGrid-row': {
                  backgroundColor: 'white'
                },
                '& .odd.MuiDataGrid-row': {
                  backgroundColor: '#F2F5FA'
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#b8d3fd',
                },
                "& ::-webkit-scrollbar": {
                  width: "8px",
                  height: "8px",
                },
                "& ::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                "& ::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                  backgroundColor: "#f5f5f5",
                },
                '& .MuiDataGrid-menuIconButton': {
                  rotate: '90deg',
                  marginRight: '20px'
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  marginLeft: '10px'
                },
                ".cellAction": {
                  display: 'none'
                },
                [`& .${gridClasses.row}`]: {
                  "&:hover, &.Mui-hovered": {
                    ".cellAction": {
                      display: 'block'
                    }
                  }
                }

              }}
            />
          </Stack>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
              root: {
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                }
              }
            }}
          >
            <MenuItem onClick={() => { handleMenuAction('view') }} sx={{ color: gridRows.find(item => item.id === selectedRow)?.status && gridRows.find(item => item.id === selectedRow)?.status === 4 ? '#2196f3' : '#0ff044' }}>
              {gridRows.find(item => item.id === selectedRow)?.status && gridRows.find(item => item.id === selectedRow)?.status === 4 ? 'Regularize' : <><RemoveRedEye sx={{ marginRight: '8px', fontSize: '.98rem' }} /> View </> || <><RemoveRedEye sx={{ marginRight: '8px', fontSize: '.98rem' }} /> View </>}</MenuItem>
          </Menu>
        </div>

        {modalView === true &&
          <Dialog
            hideBackdrop
            fullWidth={true}
            maxWidth={"md"}
            open={modalView}
            onClose={closeModal}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-modal"
            aria-describedby="child-modal-description"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeModal}
              aria-label="close"
              style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>

            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>Attendance Details</DialogTitle>
            <DialogContent>
              <AttendanceDetails attendaceDetails={attendaceDetails} />
            </DialogContent>
          </Dialog>
        }
      </div>
    </div>
  )
}

const TeamAttendanceList = DataGridProLazyLoadHOC(SubTeamAttendanceList, { filterColumns: DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()), getDispatcherAsyncThunk: getTeamAttendance, gridColumns: ['emp_code', 'punchin_time', 'punchin_date', 'punch_out_time', 'punch_out_date', 'break_duration_minutes', 'work_duration_minutes', 'extra_working_minutes', 'resource__name', 'resource__emp_code', 'resource_id'] });
export default TeamAttendanceList