import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./hiring.scss";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { toast, ToastContainer } from "react-toastify";
import parse from 'html-react-parser';
import { getProjectMappingDomain, jobPostEdit, updateJobApprovalStatus,jobBudgetReviseList } from "../../redux/features/jobPostSlice";
import GetLocalstorage from "../common-function/GetLocalstorage";
import dataService from "../../redux/services/data.service";
import { DefaultFilterColumns } from "../common-function/DefaultFilterColumns";
import { jobScreeningFormEdit } from "../../redux/features/candidateScreeningFormSlice";
import ApproverForm from "./job-post/ApproverForm";
import DefaultScreeningForm from "../../pages/master/candidate-screening-form/DefaultScreeningForm";
import { resourceList } from "../../redux/features/resourceSlice";
import JobPost from "./AddJobPost";
import RevisedSalaryJob from "./budget-revise/RevisedSalaryJob";
import { getAllIndustryList, getAllQualificationList, getAllSkillList } from "../../redux/features/masterSlice";
import { getResourceWiseProjectList } from "../../redux/features/taskSlice";
import CancelBudget from "./budget-revise/CancelBudget";
import ApproveBudget from "./budget-revise/ApproveBudget";
import RejectBudget from "./budget-revise/RejectBudget";
import BudgetLog from "./job-post/BudgetLog";


const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role;

const initialFormData = Object.freeze({
    job_title: "",
    job_description: "",
    job_skill: "",
    work_mode: "",
    work_experience: "0",
    preferred_industry: "",
    industry: "",
    education_qualification: "",
    other_skill: "",
    project_id: "",
    status: "",
    type: ""

});

const ViewJobList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { revised_Salary_List } = useSelector(state => state.jobpost)
    const [reload, setReload] = useState(false)
    const [selectedJObData, setSelectedJObData] = useState({})
    const [showJobAdd, setShowJobAdd] = useState(false)
    const [showReviseSalary, setShowReviseSalary] = useState(false)
    const [showCancleReviseSalary, setShowCancleReviseSalary] = useState(false)
    const [showApproveReviseSalary, setShowApproveReviseSalary] = useState(false)
    const [showRejectReviseSalary, setShowRejectReviseSalary] = useState(false)
    const [openAssignApprover, setOpenAssignApprover] = useState(false)
    const [fieldType, setFieldType] = useState("")
    const [rowId, setRowId] = useState("")
    const [job_id, setJobId] = useState('')
    const [screeningApiReload, setScreeningApiReload] = useState(false)
    const { resourceListDropDown } = useSelector(state => state.resource)
    const [comment, setComment] = useState("")
    const [approverID, setApproverID] = useState();
    const [openScreenigForm, setOpenScreenigForm] = useState(false)
    const [editJobScreening, setEditJobScreening] = useState({})
    const [formValues, setFormValues] = useState(initialFormData)
    const { qualification_AllList, industry_AllList } = useSelector(state => state.master)

    const { id } = useParams()
    const { view_list, status, error, jobPost_Edit, jobPost_Approver_Status } = useSelector(state => state.jobpost)
    const { jobScreeningForm_getbyID } = useSelector(state => state.candidateScreeningForm)

    React.useEffect(() => {
        if (id) {
            dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${id},"operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}`))
        }
    }, [id])


    let view_data = view_list?.data && view_list.data.hiring_request
    let view_data_status = view_list?.data && view_list.data

    const additionActionArray = [
        { title: "MANAGE JOB", key: 'epic' },
        // { title: "Budget Revise", key: 'salary' },
    ]

    if (tmsRole && tmsRole.role_key === "hr" && [0, 2, 3, 4, 6].includes(view_data_status?.approver_status)) {
        let arr = revised_Salary_List?.data?.rows ? [...revised_Salary_List?.data?.rows] : [];
        arr.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on))
        if (arr.length && arr[0].approval_status === 4) {
            // additionActionArray.push({ title: "Cancel Budget Revise", key: 'cancel-budget' })
        } else {
            additionActionArray.push({ title: "BUDGET REVISE", key: 'salary' })
        }
    }

    //     additionActionArray.push({ title: "Reject Budget Revise", key: 'reject-budget' })

    // }

    switch (view_data_status?.approver_status) {//createJobPostStatus
        case 0:
            // approval pending
            if (tmsRole && tmsRole.role_key === 'hr') {
                additionActionArray.push({ title: "ASSIGN APPROVER", key: "assign-approver" })
                additionActionArray.push({ title: "EDIT", key: "edit" })
                additionActionArray.push({ title: "EDIT SCREENING", key: "form" })
            }
            break;
        case 1:
            // Request For Approval

            //approver login
            if (tmsRole && tmsRole.role_key !== 'hr' && user.is_approver === true) {

                // additionActionArray.push({ title: "EDIT", key: "edit" })
                // additionActionArray.push({ title: "On hold", key: "hold" })
                additionActionArray.push({ title: "CLOSE", key: "close" })

                additionActionArray.push({ title: "APPROVE", key: "approved" })
                additionActionArray.push({ title: "REJECT", key: "rejected" })
            }

            // hr login 
            if (tmsRole && tmsRole.role_key === 'hr') {
                // additionActionArray.push({ title: "On hold", key: "hold" })
                additionActionArray.push({ title: "CLOSE", key: "close" })
                // additionActionArray.push({ title: "Edit", key: "edit" })
                // additionActionArray.push({ title: "Edit Screening", key: "form" })
            }

            break;
        case 2:
            // Approved

            // hr login and approver same 
            additionActionArray.push({ title: "LIVE&ACCEPT", key: "live" })
            additionActionArray.push({ title: "HOLD", key: "hold" })
            additionActionArray.push({ title: "CLOSE", key: "close" })
            break;

        case 3:
            // Rejected same login
            // additionActionArray.push({ title: "On hold", key: "hold" })
            additionActionArray.push({ title: "CLOSE", key: "close" })
            additionActionArray.push({ title: "EDIT", key: "edit" })

            // only for hr 
            if (tmsRole && tmsRole.role_key === 'hr') {
                additionActionArray.push({ title: "EDIT SCREENING", key: "form" })
                additionActionArray.push({ title: "REQUEST FOR APPROVAL", key: "request-for-approval" })
            }
            break;

        case 4:
            // Live
            additionActionArray.push({ title: "HOLD", key: "hold" })
            additionActionArray.push({ title: "CLOSE", key: "close" })
            break;
        case 5:
            // On Hold
            additionActionArray.push({ title: "LIVE", key: "live" })
            break;
        case 6:
            // Closed
            break;
        case 7:
            // RE-APPROVAL REQUEST
            additionActionArray.push({ title: "HOLD", key: "hold" })
            additionActionArray.push({ title: "CLOSE", key: "close" })
            additionActionArray.push({ title: "APPROVE", key: "approved" })
            additionActionArray.push({ title: "REJECT", key: "rejected" })
            break;

        default:
            break;
    }

    async function handleEpicCall(id) {
        const resquest = await dataService.get_task_list(`{"filter":{"job_id":{"value":${id}, "operation":"eq"}},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`)
        const response = resquest.data;
        if (response.status === 200) {
            if (response.data?.rows && response.data.rows.length > 0) {
                navigate(`/epic-task/${response.data.rows[0].id}`)
            }
        } else {
            response?.message && toast.error(response.message)
        }

    }

    const handleClickEdit = (id) => {
        dispatch(jobPostEdit(id))
        setReload('edit_data')
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && jobPost_Edit && jobPost_Edit.data) {
        let editData = jobPost_Edit.data
        let hiringData = editData?.hiring_request
        let array1 = []
        let array2 = []
        let array3 = []
        let otherSkillArray = []
        let technologyArray = []
        let addMoreSpecificationArray = []

        if (editData.education_qualification) {
            let qualification = qualification_AllList?.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []
            editData.education_qualification.map(e => {
                let filterId = qualification.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array1.push(filterId[0])
                }
                return array1
            })
        }
        if (hiringData?.technology_data) {
            hiringData.technology_data && hiringData.technology_data.length > 0 && hiringData.technology_data.map(e => {
                return technologyArray.push({
                    label: e.specialization?.name,
                    value: e.id,
                    name: "name"
                })
            })
        }


        if (editData?.add_more_specifications_data) {
            editData.add_more_specifications_data && editData.add_more_specifications_data.length > 0 && editData.add_more_specifications_data.map(e => {
                return addMoreSpecificationArray.push({
                    label: e.specification_name,
                    value: e.id
                })
            })
        }

        if (editData.preferred_industry) {
            let industry_list = industry_AllList?.data && industry_AllList.data.rows ? industry_AllList.data.rows : [];
            editData.preferred_industry.map(e => {
                let filterId = industry_list.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array3.push(filterId[0])
                }
                return array3
            })

        }
        setFormValues({
            ...formValues,
            education_qualification: array1,
            job_skill: array2,
            other_skill: otherSkillArray,
            preferred_industry: array3,
            job_title: editData.job_title,
            work_experience: editData.job_experience,
            type: editData.experience_type,
            work_mode: { label: editData.work_mode, value: editData.work_mode },
            project_id: editData.project && { label: editData.project.project_name, value: editData.project_id },
            industry: { label: editData.industry.industry_name, value: editData.industry_id },
            status: editData.status,
            approver_status: editData.approver_status,
            job_description: editData.job_description,
            hiring_request: editData.hiring_request_id,

            // hiring form data//
            client: { label: hiringData?.client && hiringData?.client?.name, value: hiringData?.client_id },
            number_of_vacancies: hiringData?.number_of_vacancies || "",
            department: { label: editData?.department && editData?.department?.name, value: editData?.department?.value },
            client_budget_from: editData.client_budget_from,
            client_budget_to: editData.client_budget_to,
            type_of_vacancy: { label: hiringData?.type_of_vacancy && hiringData?.type_of_vacancy?.vacancy_type, value: hiringData?.type_of_vacancy_id },
            contract_type: { label: hiringData?.contract_type && hiringData?.contract_type.name, value: hiringData?.contract_type_id },
            hours_per_day: hiringData?.hours_per_day ? hiringData?.hours_per_day : null,
            expected_duration_in_months: hiringData?.expected_duration_in_months ? hiringData?.expected_duration_in_months : null,
            urgency: { label: hiringData?.urgency && hiringData?.urgency?.urgency_type, value: hiringData?.urgency_id },
            job_location: { label: hiringData?.job_location && hiringData?.job_location?.job_location, value: hiringData?.job_location_id },
            job_experience: hiringData?.relevant_experience_expected,
            job_responsibilities: hiringData?.job_responsibilities,

            relevant_experience_expected_in_years: hiringData?.relevant_experience_expected_in_years,
            relevant_experience_expected_in_months: hiringData?.relevant_experience_expected_in_months,
            add_more_specifications: addMoreSpecificationArray,
            specialistation_id: technologyArray,
            id: editData.id,


            relevant_experience_expected_from: hiringData?.relevant_experience_expected_from,
            relevant_experience_expected_to: hiringData?.relevant_experience_expected_to,
            total_experience_to: hiringData?.total_experience_to,
            total_experience_from: hiringData?.total_experience_from,
            expected_ETA: hiringData?.expected_ETA,
            eta_of_Arrival: hiringData?.expected_ETA,
            urgency_unit: hiringData?.urgency_unit,
        })
        setShowJobAdd(true)
        setReload(false)
    }

    //open job status action
    const openEpicModule = async (jobId_v, key) => {
        setRowId(jobId_v)
        const currentJob = view_data_status
        const updatedJob = {
            industry: currentJob?.industry_id,
            project: currentJob?.project_id,
            job_title: currentJob?.job_title,
            work_mode: currentJob?.work_mode,
            job_description: currentJob?.job_description,
            preferred_industry: currentJob?.preferred_industry,
            education_qualification: currentJob?.education_qualification,
            job_experience: currentJob?.job_experience,
            experience_type: currentJob?.experience_type,
            attachment: currentJob?.attachments || "",
            department: currentJob?.department_id,
            status: currentJob?.status,
            // department: currentJob.department,
            technologies: currentJob?.technologies,
            job_skill: currentJob?.skills.map(item => ({
                "skill": item.skill_key,
                "skill_id": item.id,
                "experience": item.work_experience,
                "experience_type": item.experience_type
            })),
            other_skill: currentJob?.other_skill || null,
            approver: currentJob?.approver_id,
            approver_comment: currentJob?.approver_comment
        }

        if (key === 'epic') {
            handleEpicCall(jobId_v)
        } else if (key === 'form') {
            setJobId(jobId_v)
            setScreeningApiReload('edit-screening-form')
            dispatch(jobScreeningFormEdit(`{"filter":{"job_id":{"value":${jobId_v},"operation":"eq"}}}`))
        } else if (key === 'edit') {
            handleClickEdit(jobId_v)
        } else if (key === 'assign-approver') {
            setOpenAssignApprover(true)
        } else if (key === 'request-for-approval') {
            updatedJob['approver_status'] = 1
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))
        } else if (key === 'approved') {
            updatedJob['approver_status'] = 2
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))
        } else if (key === 'rejected') {
            updatedJob['approver_status'] = 3
            setOpenAssignApprover(true)
        } else if (key === 'live') {
            updatedJob['approver_status'] = 4
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))
        } else if (key === 'hold') {
            updatedJob['approver_status'] = 5
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))
        } else if (key === 'close') {
            updatedJob['approver_status'] = 6
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))
        } else if (key === 'salary') {
            setShowReviseSalary(true)
            setSelectedJObData(currentJob)
        } else if (key === 'cancel-budget') {
            setShowCancleReviseSalary(true)
            setSelectedJObData(currentJob)
        }
        else if (key === 'approve-budget') {
            setShowApproveReviseSalary(true)
            setSelectedJObData(currentJob)
        } else if (key === 'reject-budget') {
            setShowRejectReviseSalary(true)
            setSelectedJObData(currentJob)
        }
        setFieldType(key)
    }

    //job status response handling
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_jobPost_Approver_Status` && jobPost_Approver_Status && jobPost_Approver_Status.message) {
        if (jobPost_Approver_Status.status === 200) {
            toast.success(jobPost_Approver_Status.message)
            setOpenAssignApprover(false);
            dispatch(jobPostEdit(id))
        } else {
            toast.error(jobPost_Approver_Status.message)
        }
        setReload(false);
    }

    const closeAssignApprover = () => {
        setOpenAssignApprover(false)
    }

    //job status handle change
    const handleChangeApproverForm = (evt) => {
        const { name, value } = evt.target || evt
        if (name === 'approver') {
            setApproverID(evt.value)
        } else if (name === "approver_comment") {
            setComment(value)
        }
    }

    const submitApprover = (e) => {
        e.preventDefault();
        const currentJob = view_data_status
        const updatedJob = {
            industry: currentJob?.industry_id,
            project: currentJob?.project_id,
            job_title: currentJob?.job_title,
            work_mode: currentJob?.work_mode,
            job_description: currentJob?.job_description,
            preferred_industry: currentJob?.preferred_industry,
            education_qualification: currentJob?.education_qualification,
            job_experience: currentJob?.job_experience,
            experience_type: currentJob?.experience_type,
            attachment: currentJob?.attachment || "",
            // department_id: currentJob.department_id,
            status: currentJob?.status,
            department: currentJob?.department_id,
            technologies: currentJob?.technologies,
            job_skill: currentJob?.skills.map(item => ({
                "skill": item.skill_key,
                "skill_id": item.id,
                "experience": item.work_experience,
                "experience_type": item.experience_type
            })),
            other_skill: currentJob?.other_skill || null,
            // approver_status: 1,
            // approver: approverID,
            approver_comment: currentJob?.approver_comment
        }
        let commentFlag = false

        if (fieldType === 'rejected') {
            updatedJob['approver_status'] = 3
            updatedJob['approver_comment'] = comment
            updatedJob['approver'] = currentJob?.approver_id
            if (comment === "") {
                commentFlag = true
            }
        } else if (fieldType === 'assign-approver') {
            updatedJob['approver_status'] = 1
            updatedJob['approver_comment'] = comment
            updatedJob['approver'] = approverID
        }
        if (commentFlag === true) {
            toast.error("Comment is required")
        } else {
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: rowId, data: updatedJob }))
        }
    }

    // job screening form section
    if (screeningApiReload === 'edit-screening-form' && jobScreeningForm_getbyID && jobScreeningForm_getbyID.status) {

        if (jobScreeningForm_getbyID.status === 200) {
            setOpenScreenigForm(true)
            if (jobScreeningForm_getbyID.data?.rows && jobScreeningForm_getbyID.data.rows.length > 0) {
                // setOpenScreenigForm(true)
                let formData = jobScreeningForm_getbyID.data.rows.length > 0 ? jobScreeningForm_getbyID.data.rows[0] : ""
                setEditJobScreening(formData)
            }
        } else {
            toast.error(jobScreeningForm_getbyID.message)
        }
        setScreeningApiReload(false)
    }

    const closeModalScreening = () => {
        setOpenScreenigForm(false)
        setRowId('')
        setEditJobScreening({})
        dispatch(jobScreeningFormEdit('type'))
    }

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        if (id) {
            dispatch(jobPostEdit(id))
        }
        dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}, "tms_role__role_key": {"value":"admin", "operation": "eq"}, "is_approver": {"value":"True", "operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
        dispatch(getAllIndustryList())
        dispatch(getAllQualificationList())
        dispatch(getAllSkillList())
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getProjectMappingDomain({ "order_by": { "column": "-id" }, "no_limit": 'True', "columns": ["id", "domain__id", "domain__name"] }))

        // eslint-disable-next-line
    }, [dispatch])

    return (
        <div className="resource">
            <div className="resourceContainer">
                <ToastContainer />
                <div className="top-headings">
                    <h3>View Job Post List </h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <RefreshButton api={jobPostEdit} filter={id} style={{ marginTop: 5 }} />
                    </div>

                </div>
                {(status === STATUSES.LOADING) && <LoaderForm />}

                <div className="view-details-row" style={{ display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                        {additionActionArray?.map((item, i) => {
                            return (
                                <p key={i} style={{ color: "#0052cc" }}
                                    onClick={() => openEpicModule(id, item.key)}
                                >{item.title}</p>
                            )
                        })}

                    </div>
                    <div className="detailItem">
                        <div className="detailItem">
                            <span className="itemKey">Project :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.project && view_list.data.project.project_name ? view_list.data.project.project_name : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey">Job Title :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.job_title ? view_list.data.job_title : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey">Industry :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.industry && view_list.data.industry.industry_name ? view_list.data.industry.industry_name : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey">Department :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.department && view_list.data.department.name ? view_list.data.department.name : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Job Responsibilities :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.job_responsibilities ? view_list.data.hiring_request.job_responsibilities
                                : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey"> Client :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.client?.name ? view_list.data.hiring_request.client?.name
                                : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey"> Contract Type :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.contract_type?.name ? view_list.data.hiring_request.contract_type?.name
                                : "N/A"}</span>
                        </div>
                        <div className="detailItem">
                            <span className="itemKey"> Number Of Vacancies:</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.number_of_vacancies ? view_list.data.hiring_request.number_of_vacancies
                                : "N/A"}</span>
                        </div>



                        <div className="detailItem">
                            <span className="itemKey"> Type of Vacancy  :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.type_of_vacancy?.vacancy_type ? view_list.data.hiring_request.type_of_vacancy?.vacancy_type
                                : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Working Mode :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.work_mode && view_list.data.work_mode ? view_list.data.work_mode : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey"> Urgency  :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.hiring_request &&
                                view_list.data.hiring_request.urgency?.urgency_type ? view_list.data.hiring_request.urgency?.urgency_type
                                : "N/A"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Expected ETA In Years :</span>
                            <span className="itemKey2">{view_list?.data && view_list.data.expected_ETA_in_years ? view_list.data.expected_ETA_in_years : "0"}</span>
                        </div>

                        <div className="detailItem">
                            <span className="itemKey">Relevant Experience In Months :</span>
                            <span className="itemKey2">{view_data?.relevant_experience_expected_in_months ? view_data.relevant_experience_expected_in_months : "0"}</span>
                        </div>



                        <div className="detailItem">
                            <span className="itemKey">Relevant Experience In Years  :</span>
                            <span className="itemKey2">{view_data?.relevant_experience_expected_in_years ? view_data.relevant_experience_expected_in_years : "N/A"}</span>
                        </div>

                        {
                            view_list?.data &&
                            <div className="detailItem">
                                <span className="itemKey">Add More Specifications :</span>
                                <span className="itemKey2">
                                    {view_list.data?.add_more_specifications_data && view_list.data.add_more_specifications_data.length > 0 ?
                                        view_list.data.add_more_specifications_data.map((e, index) => `${index ? ', ' : ''}${(e.specification_name).trim()}`) : "N/A"}
                                </span>
                            </div>
                        }

                        {
                            view_data?.job_location &&
                            <div className="detailItem">
                                <span className="itemKey">Job Location :</span>
                                <span className="itemKey2">
                                    {view_data?.job_location && view_data.job_location.job_location ? view_data.job_location.job_location : "N/A"}
                                </span>
                            </div>
                        }

                        {
                            view_data?.technology_data &&
                            <div className="detailItem">
                                <span className="itemKey"> Technology :</span>
                                <span className="itemKey2">
                                    {view_data?.technology_data && view_data.technology_data.length > 0 ? view_data.technology_data.map((e, index) => `${index ? ', ' : ''}${e.specialization?.name.trim()}`) : "N/A"}
                                </span>
                            </div>
                        }
                        {tmsRole && (tmsRole.role_key === "admin" || tmsRole.role_key === "hr") &&
                            <React.Fragment>
                                <div className="detailItem">
                                    <span className="itemKey">Client Budget From :</span>
                                    <span className="itemKey2">{view_list?.data && view_list.data?.client_budget_from ? view_list.data.client_budget_from : "0"}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Client Budget To :</span>
                                    <span className="itemKey2">{view_list?.data && view_list.data?.client_budget_to ? view_list.data.client_budget_to : "0"}</span>
                                </div>
                            </React.Fragment>
                        }


                        {/* {
                        view_list && view_list.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Required Skill Set :</span>
                            <span className="itemKey2">
                                {view_list.data && view_list.data.required_skill_set_data && view_list.data.required_skill_set_data.length > 0 ? view_list.data.required_skill_set_data.map((e, index) => `${index ? ', ' : ''}${(e.skill_name).trim()}`) : "N/A"}
                                 { view_data.technology_data && view_data.technology_data.[]] && view_data.technology_data.specialization && view_data.technology_data[0].specialization.length >0 ? view_data.technology_data.filter((e)=>e.index).specialization.map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    } */}


                        <div className={"detailItem"}>
                            <span className="itemKey" style={{ float: 'left' }}>Job Description :</span>
                            <span className="itemKey2 item-value" style={{ float: 'left' }}>
                                <span className={view_list?.data && parse(view_list.data.job_description).length > 70 ? "para-text" : "text"}>
                                    {view_list?.data && parse(view_list.data.job_description)}</span>
                            </span>
                        </div>
                    </div>

                    {tmsRole && (tmsRole.role_key === "admin" && user.is_approver === true) &&
                        <div className="add-new-user-form">
                            {revised_Salary_List?.data?.rows?.length > 0 ?
                                <BudgetLog data={revised_Salary_List?.data?.rows ? revised_Salary_List?.data?.rows : []} />
                                : ""}
                        </div>
                    }
                </div>

            </div>

            {showJobAdd && <JobPost id={id} filterQuery={''} getJobData={formValues} open={showJobAdd} setOpen={setShowJobAdd} />}
            {showReviseSalary && <RevisedSalaryJob open={showReviseSalary} setOpen={setShowReviseSalary} jobData={selectedJObData} />}
            {showCancleReviseSalary && <CancelBudget open={showCancleReviseSalary} setOpen={setShowCancleReviseSalary} jobData={selectedJObData} />}
            {showApproveReviseSalary && <ApproveBudget open={showApproveReviseSalary} setOpen={setShowApproveReviseSalary} jobData={selectedJObData} />}
            {showRejectReviseSalary && <RejectBudget open={showRejectReviseSalary} setOpen={setShowRejectReviseSalary} jobData={selectedJObData} />}

            {/*  default screening form */}
            {openScreenigForm === true ?
                <DefaultScreeningForm title='jobpost' closeModal={closeModalScreening}
                    jobId={job_id}
                    editJobScreening={editJobScreening}
                    filterQuery={''}
                />
                : ""
            }
            {/*  assign approver form */}
            {openAssignApprover === true ?
                <ApproverForm
                    approverList={resourceListDropDown}
                    handleClose={closeAssignApprover}
                    handleChange={handleChangeApproverForm}
                    submitData={submitApprover}
                    open={openAssignApprover}
                    fieldType={fieldType}
                />
                : ""
            }
        </div >
    )

}

export default ViewJobList