import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Paper, Skeleton, Stack, Switch, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { QualificationColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qualificationCreate, qualificationEdit, qualificationUpdate, getQualificationList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import Draggable from "react-draggable";


const initialFormData = Object.freeze({
    education_qualification_name: "",
    status: true,

});

const formErrData = Object.freeze({
    education_qualification_nameErr: "Qualification name is required",
    statusErr: "Status is required"
});

let flag = 1;

function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} {...props} />
        </Draggable>
    );
}

function StatusInputValue(props) {
    const { item, applyValue, focusElementRef } = props;
    const ratingRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Value
                </InputLabel>
                <NativeSelect
                    defaultValue={30}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleFilterChange}
                >
                    <option value={'True'}>True</option>
                    <option value={'False'}>False</option>
                </NativeSelect>
            </FormControl>
        </Box>
    );
}

const SubQualification = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, qualification_List, qualification_Create, qualification_Edit, qualification_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    // const [reload, setReload] = useState(false)
    // const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const label = { inputProps: { 'aria-label': 'Switch demo' } };


    // useEffect(() => {
    //     dispatch(getQualificationList(filterQuery))
    //     if (status === STATUSES.FAILURE && error) {
    //         setReload(false)
    //         errorHandling(error, navigate)
    //     }
    // }, [])


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setTimeout(() => { setID(0) }, 400); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false)
        setContextMenu(null)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.education_qualification_name === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.education_qualification_name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(qualificationUpdate({ ID, addvalues }))
            } else {
                dispatch(qualificationCreate(addvalues))
            }
        }
    }



    const handleClickEdit = (id) => {
        flag = 1;
        dispatch(qualificationEdit(id))
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    // const handleClickArchive = (data) => {
    //     setReload(true)
    //     setArchiveOpen(false)
    //     let addvalues = {
    //         education_qualification_name: data.education_qualification_name,
    //         status: !data.status,
    //         updated_by: GetLocalstorage.userDetail().id
    //     }
    //     let ID = data.id;
    //     setReload(true)
    //     dispatch(qualificationUpdate({ ID, addvalues }))
    // }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            education_qualification_name: data.education_qualification_name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        // let copyRows = [...gridRows];
        // let index = copyRows.findIndex(item => item.id === data.id);
        // copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        // setGridRows(copyRows);
        let ID = data?.id;
        dispatch(qualificationUpdate({ ID, addvalues }))
        setReload(false)
    }


    // sorting
    // const handleSort = (sort) => {
    //     if (sort.length) {
    //         let newFilterData = JSON.parse(filterQuery);
    //         newFilterData['skip'] = 0;
    //         newFilterData['limit'] = limit;
    //         newFilterData['order_by'] = {
    //             "column": sort[0].sort === "desc" ?
    //                 '-' + sort[0].field : sort[0].field
    //         }
    //         setFilterQuery(JSON.stringify(newFilterData))
    //         dispatch(getQualificationList(JSON.stringify(newFilterData)))
    //     } else {
    //         setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS}] }`)
    //     }
    // }

    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
    const actionColumn = [
        {
            field: "education_qualification_name", headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Education Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("education_qualification_name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon fontSize={'16px'} />
                    </IconButton>
                </Box>
            </Stack>, width: 900, maxWidth: 1000, minWidth: 200, sortable: false,
            renderCell: params =>
            (
                params.row.education_qualification_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={950} /> :
                    params.row.education_qualification_name
            )
        },
        {
            field: "status",
            filterOperators: statusOnlyOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
                {/* <Box>
                    <IconButton onClick={() => !reload && handleSort("status")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon fontSize={'16px'} />
                    </IconButton>
                </Box> */}
            </Stack>,
            width: 900, maxWidth: 900, minWidth: 200, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={900} /> :
                        <Stack width={'20%'} justifyContent={"space-between"} direction="horizontal">
                            <Stack>
                                <Switch {...label} defaultChecked={params.row.status} onChange={() => handleArchive(params.row)} />
                            </Stack>
                            <Stack className="cellAction">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="qualification"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    // isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                     deleteButton={false}
                                />
                            </Stack>
                        </Stack>
                );
            },
        },  
    ];

    // const onFilterChange = (filterModel, r) => {

    //     if(filterModel.items.length)
    //     {
    //         setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
    //     }

    //     if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
    //     {
    //         setReload(true);
    //         setTimeout(()=>
    //         {
    //             setReload(false)
    //             setCurrentPage(0)
    //             //setPageSize(10)
    //             setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS}]}`)
    //             dispatch(getQualificationList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS}]}`))
    //         },0);
    //     }

    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                 filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
    //         const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS}]}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(getQualificationList(filterData))
    //     }
    // }


    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            // case 'status':
            //     handleArchive(row);
            //     break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }
        setContextMenu(null);
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_qualification_Update` && qualification_Update && qualification_Update.message) {
        if (qualification_Update.status === 200) {
            toast.success(qualification_Update.message)
            let copyGridRows = [...gridRows].map(item => {
                if (editID?.current?.id === item?.id)
                    return { ...qualification_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            // dispatch(getQualificationList(filterQuery))
        } else {
            toast.error(qualification_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_qualification_Create` && qualification_Create && qualification_Create.message) {
        if (qualification_Create.status === 200) {
            toast.success(qualification_Create.message)
            dispatch(getQualificationList(filterQuery))
        } else {
            toast.error(qualification_Create.message)
        }
        setReload(false)
    }

    if (flag === 1 && status === `${STATUSES.SUCCESS}` && qualification_Edit && qualification_Edit.data) {
        let editData = qualification_Edit.data
        setFormValues({
            education_qualification_name: editData.education_qualification_name,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
        flag = 0;
    }

    if (reload === true && status === STATUSES.SUCCESS && qualification_List && qualification_List.message) {
        if (qualification_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...qualification_List.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(qualification_List.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...qualification_List.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(qualification_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(qualification_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }


    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">Qualification List</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Party List">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getQualificationList} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </Stack>
                    </Stack>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />} */}
                    <Stack direction={'row'} mt={2}>
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={gridRows.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                // Pagination: CustomPagination,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                            }}
                            loading={!!reload}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            hideFooterRowCount={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                boxShadow: '1px 13px 31px -15px gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                        />
                    </Stack>
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>{ID ? 'Edit' : 'Add'} Qualification</DialogTitle>

                    <DialogContent>
                        <form>
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                width={'95%'}
                                m={'auto'}
                            >
                                <Typography variant="subtitle1" fontSize={'18px'}>Qualification Name</Typography>
                                <TextField
                                    sx={{ marginBottom: '10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    fullWidth
                                    name="education_qualification_name"
                                    defaultValue={formValues.education_qualification_name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange} size="small" id="outlined-basic" variant="outlined"
                                    error={errStatus === true && formValues && formValues.education_qualification_name === ""}
                                    helperText={errStatus === true && formValues?.education_qualification_name === "" && formErr.education_qualification_nameErr}
                                />
                                <Stack justifyContent={"end"} alignItems={"center"} direction={'row'}>
                                    <Typography mr={'25px'} variant="subtitle1" fontSize={'18px'}>
                                        Status
                                    </Typography>
                                    <Typography variant="subtitle2">InActive</Typography>
                                    <Switch name="status" {...label} defaultChecked={formValues.status} onChange={(e) => { handleChange({ target: { value: e.target.checked, name: 'status' } }) }} />
                                    <Typography variant="subtitle2">Active</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"end"} >
                                    <Button variant="contained" color="info" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                                </Stack>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Qualification`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    {/* <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem> */}

                </Menu>
            </div>
        </div >
    )
}

const Qualification = DataGridProLazyLoadHOC(SubQualification, { filterColumns: DefaultFilterColumns.QUALIFICATION_LIST_COLUMNS?.map(item => item.replaceAll('"', "").trim()), getDispatcherAsyncThunk: getQualificationList, gridColumns: ['education_qualification_name', 'status'] });


export default Qualification