import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustryList, getIndustryDepartmentList, getIndusDeprtSpecSkills, indsDepSpecSkillCreat, indsDepSpecSkillEdit, indsDepSpecSkillUpdate, getIndusDepSpecListData, getSkillList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputMultiSelectField } from "../../components/common-function/InputFields";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';


const initialFormData = Object.freeze({
    department: "",
    industry: "",
    specialization: "",
    skills: "",
    status: true,
});


const formErrData = Object.freeze({
    departmentErr: "Department is required",
    industryErr: "Industry is required",
    specializationErr: "Specialization is required",
    skillsErr: "Skills is required",
    statusErr: "Status is required",
});


const IndustryDepSpecialization = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, indusDeprtSpecSkills_List, industry_department_List, indsDepSpecSkill_create, indsDepSpecSkill_Edit, indsDepSpecSkill_Update, industry_List, industryDepSpec_List_data, skill_List } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"rows_skip":0,"page_limit":25,"no_limit":"True"}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [updatedspecList, setUpdatedspecList] = useState([])
    const [updatedDepList, setUpdatedDepList] = useState([])
    const [industryId, setIndustyId] = useState(null)
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const [selectedSkills, setSelectedSkills] = useState([])

    let selectDeprtRef = React.useRef();
    let selectSpectRef = React.useRef();

    useEffect(() => {
        dispatch(getSkillList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndusDeprtSpecSkills(filterQuery))
        dispatch(getIndustryList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line
    }, [])

    const clearValue = (department, spect) => {
        if (formValues.department && department) {
            selectDeprtRef.clearValue();
        }
        if (formValues.specialization && spect) {
            selectSpectRef.clearValue();
        }

    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_indsDepSpecSkill_Update` && indsDepSpecSkill_Update && indsDepSpecSkill_Update.message) {
        if (indsDepSpecSkill_Update.status === 200) {
            toast.success(indsDepSpecSkill_Update.message)
            dispatch(getIndusDeprtSpecSkills(filterQuery))
        } else {
            toast.error(indsDepSpecSkill_Update.message)
        }
        setReload(false)
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_indsDepSpecSkill_Create` && indsDepSpecSkill_create && indsDepSpecSkill_create.message) {
        if (indsDepSpecSkill_create.status === 200) {
            toast.success(indsDepSpecSkill_create.message)
            dispatch(getIndusDeprtSpecSkills(filterQuery))
            handleClose()
        } else {
            toast.error(indsDepSpecSkill_create.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setFormValues({ ...formValues, [name]: value })
        }
    }

    const handleIndustrychange = (evt) => {
        setUpdatedDepList([])
        const deplist = industry_department_List?.data?.rows?.filter((options) => options?.industry?.id === evt.value)
        setUpdatedDepList(deplist[0]?.department)
        setIndustyId(evt.value)
        clearValue('department', 'spec')
    }

    const handleDepartmentChange = (evt) => {
        const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
            if (evt?.value === options?.department_id && options.industry_id === industryId) {
                return options?.specialization_data
            } else {
                return [];
            }
        }).flat()
        setUpdatedspecList(speclist ? speclist : [])
        clearValue(null, 'spec')

    }

    const handleSkillChange = (evt) => {
        setFormValues({ ...formValues, skills: evt })
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.department === "" || formValues.industry === "" || formValues.specialization === "" || formValues.skills === "") {
            setErrStatus(true)
        }

        if (formValues.department !== "" && formValues.industry !== "" && formValues.specialization !== "" && formValues.skills !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            const updatedIndustry = formValues?.industry?.value ? formValues?.industry?.value : formValues?.industry
            const updatedDepartment = formValues?.department?.value ? formValues?.department?.value : formValues?.department
            const updatedSpecialization = formValues?.specialization?.value ? formValues?.specialization?.value : formValues?.specialization
            const updatedSkills = formValues.skills.map((skil) => skil.id)
            let addvalues = {
                ...formValues,
                industry: updatedIndustry,
                department: updatedDepartment,
                specialization: updatedSpecialization,
                skills: updatedSkills,
                created_by: userId,
            }
            setErrStatus(false)
            setReload('submit')
            if (ID) {
                dispatch(indsDepSpecSkillCreat(addvalues))
            } else {
                dispatch(indsDepSpecSkillCreat(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_indsDepSpecSkill_Edit` && indsDepSpecSkill_Edit && indsDepSpecSkill_Edit.data) {
        let editData = indsDepSpecSkill_Edit.data
        let array1 = []
        if (editData.skill && selectedSkills.length === 0) {
            let skillList = skill_List?.data?.rows ? skill_List.data.rows : [];
            let filterId = skillList.filter(x => x.id === editData.skill.id);
            if (filterId.length > 0) {
                array1.push(filterId[0])
            }
        }else{
            array1 =  selectedSkills;
        }
        setFormValues({
            department: {
                label: editData?.department?.name,
                value: editData?.department?.id,
                name: "department"
            },
            industry: {
                label: editData?.industry?.industry_name,
                value: editData?.industry?.id,
                name: "industry"
            },
            specialization: {
                label: editData?.specialization?.name,
                value: editData?.specialization?.id,
                name: "specialization"
            },
            skills: array1,
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (ids) => {
        if (ids?.skills?.length > 0) {
            let array1 = []
            let skillList = skill_List?.data?.rows ? skill_List.data.rows : [];
            ids?.skills?.map(itm => {
                let filterId = skillList.filter(x => x.id === itm.id);
                if (filterId.length > 0) {
                    array1.push(filterId[0])
                }
            })
            setSelectedSkills(array1)
            dispatch(indsDepSpecSkillEdit(ids?.id[0]))
        } else {
            setSelectedSkills([])
            dispatch(indsDepSpecSkillEdit(ids?.id))
        }

        setReload('edit_data')
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['rows_skip'] = data * pageSize
        newFilterData['page_limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getIndusDeprtSpecSkills(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['rows_skip'] = 0
        newFilterData['page_limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getIndusDeprtSpecSkills(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(indusDeprtSpecSkills_List?.data?.count && indusDeprtSpecSkills_List?.data?.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['rows_skip'] = 0;
            newFilterData['page_limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getIndusDeprtSpecSkills(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"order_by":{"column":"-id"},"rows_skip":0, "page_limit":25, "no_limit":"True"}`)
        }
    }

    const actionColumn = [
        {
            field: "industry__industry_name",
            headerName: "Industry",
            width: 400,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={params?.row?.industry?.industry_name || params?.row?.industry__industry_name || "N/A"}>
                        <p>{params?.row?.industry?.industry_name || params?.row?.industry__industry_name || "N/A"}</p>
                    </Tooltip>
                </div>
            ),
        },

        {
            field: "department__name",
            headerName: "Department",
            width: 280,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={params?.row?.department?.name || params?.row?.department__name || "N/A"} >
                        <p>{params?.row?.department?.name || params?.row?.department__name || "N/A"}</p>
                    </Tooltip>
                </div>

            ),
        },
        {
            field: "specialization__name",
            headerName: "Specialization",
            width: 280,
            renderCell: (params) => {
                return <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={params?.row?.specialization?.name || params?.row?.specialization__name || "N/A"}>
                        <p>{params?.row?.specialization?.name || params?.row?.specialization__name || "N/A"}</p>
                    </Tooltip>
                </div>
            },
        },
        {
            field: "skill__skill_name",
            headerName: "Skills",
            width: 300,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={Object.keys(params?.row?.skills).map(function (k) { return params?.row?.skills[k]['skill_name'] }).join(",") || "N/A"}>
                        <p>{Object.keys(params?.row?.skills).map(function (k) { return params?.row?.skills[k]['skill_name'] }).join(",").substring(0, 20) + "..." || "N/A"}</p>
                    </Tooltip>

                </div >
            ),
        },

        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="purches_package"
                            rowid={params.row}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel, r) => {

        if (filterModel.items.length) {
            setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
        }

        if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
            setReload(true);
            setTimeout(() => {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"order_by":{"column":"-id"},"rows_skip":0, "page_limit":${limit}, "no_limit":"True"}`)
                dispatch(getIndusDeprtSpecSkills(`{"order_by":{"column":"-id"},"rows_skip":0,"page_limit":${limit}, "no_limit":"True"}`))
            }, 0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"rows_skip":0, "page_limit":${limit},"no_limit":"True"}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getIndusDeprtSpecSkills(filterData))
        }
    }
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Industry Department Spcecialization & skills</h3>
                        <div>
                            <Tooltip title="Add Industry Dep Specializaton & skills">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getIndusDeprtSpecSkills} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                            filterMode="server"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={indusDeprtSpecSkills_List?.data?.rows ? indusDeprtSpecSkills_List.data.rows : []}
                            columns={actionColumn}
                            pageSize={pageSize}
                            rowHeight={40}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                        />
                    }
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} </DialogTitle>
                    <DialogContent style={{ minHeight: "450px" }}>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >Select Industry</label>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder={'Select Industry'}
                                        name={'industry'}
                                        onChange={(e) => {
                                            handleIndustrychange(e)
                                            handleChange(e)
                                        }}
                                        defaultValue={formValues.industry}
                                        isSearchable={true}
                                        options={
                                            industry_List?.data?.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: "industry"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Select Department</label>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder={'Select Department'}
                                        name={'department'}
                                        ref={c => selectDeprtRef = c}
                                        onChange={(e) => {
                                            handleDepartmentChange(e)
                                            handleChange(e)
                                        }}
                                        defaultValue={formValues.department}
                                        isSearchable={true}
                                        options={
                                            updatedDepList?.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "department"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Select Specialization</label>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder={'Select specialization'}
                                        name={'specialization'}
                                        ref={c => selectSpectRef = c}
                                        onChange={handleChange}
                                        defaultValue={formValues.specialization}
                                        isSearchable={true}
                                        options={
                                            updatedspecList?.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "specialization"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.specialization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.specializationErr}</p>}
                                </div>
                                <div className="formInput">
                                    <InputMultiSelectField
                                        label={"Skills"}
                                        name={"skills"}
                                        placeholder={"Skills"}
                                        onSelect={handleSkillChange}
                                        onRemove={handleSkillChange}
                                        defaultValue={formValues.skills}
                                        errStatus={errStatus}
                                        formErr={formErr.skillsErr}
                                        optionList={skill_List?.data?.rows ? skill_List?.data?.rows : []}
                                        optionLevel={['skill_name']}
                                        optionValue={'id'}
                                    />
                                </div>
                            </div>
                            <button className={reload === "submit" ? 'btn-disable' : 'submit-modal'} disabled={reload === "submit" ? true : false} onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}

export default IndustryDepSpecialization;