import React from 'react'
import Paper from '@mui/material/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function AlReadyInterviewSchedule({ viewData }) {
    return (
        <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Schedule Date</b></TableCell>
                        <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Schedule Time</b></TableCell>
                        <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Schedule End Time</b></TableCell>
                        <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Interviewer Name</b></TableCell>
                        <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Interview Status</b></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {viewData && viewData.length > 0 && viewData.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className="tableCell">{row.scheduled_date ? row.scheduled_date : "N/A"}</TableCell>
                            <TableCell className="tableCell">{row.scheduled_time ? row.scheduled_time : "N/A"}</TableCell>
                            <TableCell className="tableCell">{row.scheduled_end_time ? row.scheduled_end_time : "N/A"}</TableCell>
                            <TableCell className="tableCell">{row.interviewer?.name ? row.interviewer.name : "N/A"}</TableCell>
                            <TableCell className="tableCell">{row.interview_status?.status_name ? row.interview_status.status_name : "N/A"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
