import { Button, Dialog, DialogContent, DialogTitle, Menu, MenuItem, Skeleton, Switch, TablePagination, Tooltip } from '@mui/material'
import "./master.scss"
import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import LoaderForm from '../../components/common-function/LoaderForm'
import TablePaginationActions from '../../components/TablePaginationActions'
import { STATUSES } from "../../redux/common-status/constant";
import { getDomainList, getThirdPartyDomainList, thirdPartyUpdate } from '../../redux/features/thirdPartySlice'
import { errorHandling } from "../../redux/features/authLogoutSlice";
import UcActionComponent from '../../components/common-function/UcActionComponent'
import { ArrowCircleLeft, Close, Edit } from '@mui/icons-material'
import Multiselect from 'multiselect-react-dropdown';
import dataService from '../../redux/services/data.service'
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay'
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns'
import GetLocalstorage from '../../components/common-function/GetLocalstorage'
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { RefreshButton } from '../../components/common-function/RefreshButton'
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';


const initialFormData = Object.freeze({
    name: "",
    code: "",
    domains: "",
    status: true
});

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', domain: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['name', 'code', 'domain', 'status'];

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const ThirdPartyDomainMapping = () => {

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let userId = rtm_user?.user?.data.id

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { status, error, thirdParty_Domain, domainList,thirdParty_Update} = useSelector(state => state.thirdParty)
    const [reload, setReload] = useState(false)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":25,"order_by":{"column":"-status"},"columns":[${DefaultFilterColumns.THIRDPARTY_DOMAIN_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [openSingle, setOpenSingle] = useState(false)
    const [domainArray, setDomainArray] = useState([])
    const [domainsIds, setDomainsIds] = useState([])
    const [selectedValuesDomain, setSelectedValuesDomain] = useState([])
    const [thirdPartyData, setThirdPartyData] = useState({})
    const [gridRows, setGridRows] = useState([]);
    const [formValues, setFormValues] = useState(initialFormData);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [changeStatus, setChangeStatus] = useState('');
    const [archiveId, setArchiveId] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});


    let clearGridListing = useRef(false);
    let stopOnRowScrollEnd = useRef(false);
    let editID = useRef(null);
    let isAnyChange = useRef({});


    if (status === `${STATUSES.SUCCESS}_domainList` && domainArray && domainArray?.length === 0 && domainList) {
        let array = []
        domainList?.map(e => { return array.push({ value: e.id, label: e.name, id: e.id }) })
        array?.length > 0 && setDomainArray(array)
    }
    
    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Update` && thirdParty_Update.message) {  
        if (thirdParty_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(thirdParty_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(thirdParty_Update.message)
        }
      setReload(false)
    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true)
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getThirdPartyDomainList(filterQuery))
        dispatch(getDomainList())
    }, [filterQuery])

    if (reload === true && status === STATUSES.SUCCESS && thirdParty_Domain && thirdParty_Domain.message) {
        if (thirdParty_Domain.status === 200) {
            if (clearGridListing.current) {
                // alert("hello")
                setGridRows([...thirdParty_Domain.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(thirdParty_Domain.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...thirdParty_Domain.data.rows]);
                SET_MAX_ROW_LENGTH(thirdParty_Domain.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(thirdParty_Domain.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };


    // const handleClickEdit = (id) => {
    //     dispatch(thirdPartyUpdate(id))
    //     setReload('edit_data')
    // }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleSort = (sortingCol) => {

        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter?.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows?.length;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);
    }

    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setThirdPartyData({});
        setOpenSingle(false)
        setSelectedValuesDomain([])
        setContextMenu(null)
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        // setFormValues({ ...formValues, [name]: value })
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })

    }

    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyDomainList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyDomainList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(thirdParty_Domain && thirdParty_Domain.data && thirdParty_Domain.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const handleClickArchive = (data) => {

        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            code: data.code,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
            domain: data.domains
        }

        clearGridListing.current = true;
        editID.current = { ...data };
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(thirdPartyUpdate({ ID, addvalues }))
    }

    const onAdditionalBtn = (data) => {
        setSelectedValuesDomain(data.domains)
        const domains_ids = data.domains.map(e => (e.id))
        domains_ids.length > 0 && setDomainsIds(domains_ids);
        onOpenModal()
        setThirdPartyData({
            name: data.name,
            third_party: data.id,
            domains: data.domains,
            status: data.status
        })
    }

    const handleChangeMulti = (event) => {
        const optArray = event.map((e) => e.id);
        setDomainsIds(optArray)
    };

    const onSubmitSingle = async (e) => {
        e.preventDefault()

        const request = {
            ...thirdPartyData,
            created_by: userId,
            domains: domainsIds
        }
        // validation
        if (domainsIds?.length === 0) {
            return toast.error('Please select domain')
        }
        clearGridListing.current = true;
        delete request.name;
        delete request.status;
        const response = await dataService.assign_domain_thirdparty(request);
        if (response?.data && response.data.status === 200) {
            toast.success(response.data.message)
            dispatch(getThirdPartyDomainList(filterQuery))
            onCloseModal();
        } else {
            toast.error(response.data.message)
        }
    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);
        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'assign':
                onAdditionalBtn(row)
                break;
        }

        setContextMenu(null);

    }

    const ThirdPartyColumns = [
        {
            field: "name",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 230,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.name}
                        </div>
                );
            },
        },
        {
            field: "code",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            width: 230,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.code}
                        </div>
                );
            },
        },
        {
            field: "domains",
            headerName: "Domains",
            // headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Domains</p><button style={{ background: 'white', marginTop: '10px' }}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 380,
            sortable: false,
            renderCell: (params) => {
                return (
                    params?.row?.domains === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div className="cellWithImg">
                            {params?.row?.domains?.length >=0 && params?.row?.domains.map((domain, index) => {
                                return (
                                    <p key={index} style={{ marginTop: "0px" }}>
                                        {index === 0 && domain.name} {index === 0 && params.row.domains?.length > 1 && ","}
                                        {params.row.domains?.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains?.length > 2 && ","}
                                    </p>
                                )
                            })
                            }
                            {(params.row.domains && params.row.domains?.length > 2) ? `..[+${params.row.domains?.length - 2}]` : ""}

                        </div>

                )
                // return (
                //     <div className="cellWithImg">
                //         {
                //             params?.row?.domains === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} />
                //             : params.row.domains?.map((domain, index) => {
                //                 return (
                //                     <p key={index} style={{ marginTop: "0px" }}>
                //                         {index === 0 && domain.name} {index === 0 && params.row.domains.length > 1 && ","}
                //                         {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                //                     </p>
                //                 )
                //             })
                //         }
                //         {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}
                //     </div>
                // );
            },
        },
        // {
        //     field: "status",
        //     headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
        //     minWidth: 160,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus ${params.row.status}`}>
        //                 {params.row.status === true ? 'Active' : 'Inactive'}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "status",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 160,
            sortable: false,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus ${params.row.status}`}>
                    //     {params.row.status === true ? 'Active' : 'Inactive'}
                    // </div>
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName=""
                                    moduleName="third-party-domain"
                                    rowid={params.row.id}
                                    addMoreData={params.row}
                                    additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={false}
                                    editButton={false}
                                    viewButton={false}
                                    deleteButton={false}
                                    extraButton={() => onAdditionalBtn(params.row)}
                                />
                            </div>
                        </div >
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: "120",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName=""
        //                     moduleName="third-party-domain"
        //                     rowid={params.row.id}
        //                     addMoreData={params.row}
        //                     additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     isDelete={false}
        //                     editButton={false}
        //                     viewButton={false}
        //                     deleteButton={false}
        //                     extraButton={() => onAdditionalBtn(params.row)}
        //                 />
        //             </div>
        //         );
        //     },
        // },
    ]

    const onFilterChange = (props) =>
    {
        if(props.items.length > ThirdPartyColumns.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.THIRDPARTY_DOMAIN_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.THIRDPARTY_DOMAIN_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }

    

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings new-rtms-heading new-design-icon">
                        {/* <h3><i><ArrowCircleLeft className='new-back-icon' /> </i>Third Party Domains</h3> */}
                        <h2>Third Party Domains</h2>
                        <div>
                            <Button variant="outlined" onClick={onOpenModal}>Create<BorderColorTwoToneIcon /></Button>
                            <RefreshButton api={getThirdPartyDomainList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </div>
                    </div>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || reload === true) && <LoaderForm />} */}
                    <DataGridPro
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        filterMode='server'
                        rows={gridRows?.concat(skeletonRows)}
                        columns={ThirdPartyColumns}
                        pageSize={pageSize}
                        // style={{ fontSize: 16 }}
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        onFilterModelChange={onFilterChange}
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,

                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        hideFooterPagination={true}
                        hideFooter={true}
                    />
                </div>
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="child-modal-description"
                    sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: "move" }} id="draggable-dialog-title">Assign Domain To Third Party</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label>
                                        <span style={{ fontWeight: "bold" }}>Third Party Name :</span> {thirdPartyData.name}
                                    </label>
                                </div>
                                {/* <div className="formInput">
                                    <label >
                                        <span style={{ fontWeight: "bold" }}>Status :</span>
                                        {thirdPartyData.status === true ? ' Active' : " InActive"}
                                    </label>
                                </div> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <div className='add-new-user-form'>
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <label>Select Domains*</label>
                                    <Multiselect
                                        isObject={true}
                                        onRemove={handleChangeMulti}
                                        onSelect={handleChangeMulti}
                                        displayValue={"label"}
                                        selectedValues={selectedValuesDomain.map(e => ({ id: e.id, label: e.name, value: e.id }))}
                                        options={domainArray}
                                    />
                                </div>
                            </div>

                            <button className="submit-modal" style={{ marginTop: "40px" }} onClick={onSubmitSingle}>Assign</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} setting`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={onCloseModal}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                onCloseModal();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction("assign")} sx={{ color: 'rgba(0,0,220,.8)' }}>  <AddBoxRoundedIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Assign Domain</MenuItem>
                </Menu>
            </div>
        </div >
    )
}
export default ThirdPartyDomainMapping