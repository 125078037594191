import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Menu, MenuItem, Skeleton, Switch, TablePagination, Tooltip, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationCreate, locationEdit, locationUpdate, getLocationList, getCountryList, getStateList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
const initialFormData = Object.freeze({
  city_name: "",
  code: "",
  country: "",
  state: "",
  status: true,

});

const formErrData = Object.freeze({
  city_nameErr: "City is required",
  code_Err: "Code is required",
  country_Err: "Country name is required",
  state_Err: "State is required",
  statusErr: "Status is required"
});

const skeletonBase = { city_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['city_name', 'code', 'status'];

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="parent" 
    >
      <Paper {...props} />
    </Draggable>
  );
}

const PreferredLocation = () => {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialFormData)
  const [formErr] = useState(formErrData)
  const [errStatus, setErrStatus] = useState(false);
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const { status, error, location_List, location_Create, location_Edit, location_Update, country_List, state_List } = useSelector(state => state.master)
  const [currentPage, setCurrentPage] = useState(0);
  const [ID, setID] = useState("");
  const [reload, setReload] = useState(false)
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [changeStatus, setChangeStatus] = useState('');
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });

  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let gridTotalRows = useRef(null);
  let editID = useRef(null);
  let isAnyChange = useRef({});


  useEffect(()=>
  {
    dispatch(getCountryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))

  },[])

  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setReload(true);
    setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    dispatch(getLocationList(filterQuery))

  }, [filterQuery])

  if (reload === true && status === `${STATUSES.SUCCESS}_location_Update` && location_Update && location_Update.message) {
    if (location_Update.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      toast.success(location_Update.message)
      // dispatch(getLocationList(filterQuery))
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(location_Update.message)
    }
    setReload(false)
  }

  if (reload === true && status === `${STATUSES.SUCCESS}_location_Create` && location_Create && location_Create.message) {
    if (location_Create.status === 200) {
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
      setFilterQuery(str)
      setGridRows([]);
      toast.success(location_Create.message)
      // dispatch(getLocationList(filterQuery))
    } else {
      toast.error(location_Create.message)
    }
    setReload(false)
  }
  if (reload === true && status === STATUSES.SUCCESS && location_List && location_List.message) {
    if (location_List.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...location_List.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(location_List.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...location_List.data.rows]);
        SET_MAX_ROW_LENGTH(location_List.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(location_List.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    setErrStatus(false);
  }

  const handleChange = (evt) => {
    const { name, value, checked } = evt.target || evt;
    if (evt) {
      if (name === 'country') {

        dispatch(getStateList(`{"filter":{"country_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
        setFormValues({ ...formValues, [name]: evt, state: "" })

      } else {
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value });
      }
    }

  }


  const submitData = (e) => {
    e.preventDefault()

    if (formValues.code === "" || formValues.city_name === "" || formValues.country === "" | formValues.state === "" || formValues.status === "") {
      setErrStatus(true)
    }
    clearGridListing.current = true;

    if (formValues.code !== "" && formValues.city_name !== "" && formValues.status !== "") {
      const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
      let userId = rtm_user?.user?.data.id
      let addvalues = { ...formValues, created_by: userId, country: formValues.country.value ? formValues.country.value : formValues.country, state: formValues.state.value ? formValues.state.value : formValues.state }
      setErrStatus(false)
      setReload(true)
      handleClose()
      if (ID) {
        let copyRows = [...gridRows];
        editID.current = copyRows.find(item => item.id === ID);
        let index = copyRows.findIndex(item => item.id === ID);
        copyRows.splice(index, 1, { ...skeletonBase, id: ID });
        setGridRows(copyRows);
        dispatch(locationUpdate({ ID, addvalues }))
      } else {
        gridTotalRows.current = gridRows.length;
        dispatch(locationCreate(addvalues))
      }
    }
  }

  const selectValueInLable = (id) => {
    let data = country_List?.data?.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.name })

    })
    return newObject
  }

  const selectValueInState = (id) => {
    let data = state_List?.data?.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.name })

    })
    return newObject
  }

  if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && location_Edit && location_Edit.data) {
    let editData = location_Edit.data
    setFormValues({
      city_name: editData.city_name,
      code: editData.code,
      country: selectValueInLable(editData.country_id),
      state: selectValueInState(editData.state_id),
      status: editData.status
    })
    setID(editData.id)
    setOpen(true)
    setReload(false)
  }

  const handleClickEdit = (id) => {
    dispatch(locationEdit(id))
    setReload('edit_data')
  }

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClickArchive = (data) => {
    setReload(true)
    setArchiveOpen(false)
    let addvalues = {
      city_name: data.city_name,
      country: data.country,
      state: data.state,
      code: data.code,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }
    // clearGridListing.current = true;
    // editID.current = { ...data };
    // let copyRows = [...gridRows];
    // let index = copyRows.findIndex(item => item.id === data.id);
    // copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    // setGridRows(copyRows);
    let ID = data.id;

    let updatedRows = [...gridRows];
    let rowIndex = updatedRows.findIndex((item) => item.id === data.id);
  
    if (rowIndex !== -1) {
      updatedRows[rowIndex] = { ...data, status: !data.status };
      setGridRows(updatedRows);
    }
    dispatch(locationUpdate({ ID, addvalues }))
  }

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getLocationList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getLocationList(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(location_List && location_List.data && location_List.data.rows && location_List.data.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  // const handleSort = (sort) => {
  //   if (sort.length) {
  //     let newFilterData = JSON.parse(filterQuery);
  //     newFilterData['skip'] = 0;
  //     newFilterData['limit'] = limit;
  //     newFilterData['order_by'] = {
  //       "column": sort[0].sort === "desc" ?
  //         '-' + sort[0].field : sort[0].field
  //     }
  //     setFilterQuery(JSON.stringify(newFilterData))
  //     dispatch(getLocationList(JSON.stringify(newFilterData)))
  //   } else {
  //     setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}] }`)
  //   }
  // }

  const handleSort = (sortingCol) => {
    setReload(true);
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }

    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }


  const actionColumn = [
    {
      field: "city_name",
      // headerName: "Location",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Location </p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("city_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      sortable: false,
      width: 280,
      renderCell: (params) => {
        return (
          params.row.city_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div style={{color:'rgba(23, 28, 38, 1)'}}>
              {params.row.city_name}
            </div>
        );
      },
    },
    {
      field: "code",
      // headerName: "Location Code",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Location Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      sortable: false,
      width: 280,
      renderCell: (params) => {
        return (
          params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div style={{color:'rgba(23, 28, 38, 1)'}}>
              {params.row.code}
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      // headerName: "Status",
      width: 160,
      sortable: false,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      renderCell: (params) => {
        return (
          // <div className={`cellWithStatus ${params.row.status}`}>
          //   {params.row.status === true ? 'Active' : 'Inactive'}
          // </div>
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
              <div className="actionButton">
                <UcActionComponent
                  deleteIconName="ArchiveIcon"
                  moduleName="core-settings"
                  rowid={params.row.id}
                  addMoreData=""
                  editLinkUrl=""
                  viewLinkUrl=""
                  // isDelete={true}
                  editButton={handleClickEdit}
                  viewButton={false}
                  // deleteButton={() => handleArchive(params.row)}
                />
              </div>
            </div>
        );
      },
    }
  ];
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);

    switch (actionType) {
      // case 'status':
      //         handleArchive(row);
      //         break;

      case 'edit':
        handleClickEdit(row.id)
        break;

    }

    setContextMenu(null);

  }
  // const onFilterChange = (filterModel, r) => {

  //   if (filterModel.items.length) {
  //     setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
  //   }

  //   if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
  //     setReload(true);
  //     setTimeout(() => {
  //       setReload(false)
  //       setCurrentPage(0)
  //       //setPageSize(10)
  //       setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`)
  //       dispatch(getLocationList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`))
  //     }, 0);
  //   }

  //   if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
  //     let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
  //       filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
  //         filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
  //     const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`
  //     setFilterQuery(filterData)
  //     setCurrentPage(0)
  //     dispatch(getLocationList(filterData))
  //   }
  // }

  const onFilterChange = (props) => {
    if (props.items.length > actionColumn.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`);
      setFilterQuery(str);
      isAnyChange.current = {};
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          if (!newProps.items[index].value) {
            newProps.items[index].value = undefined;
            newProps.items[index].operatorValue = 'is';
          }
        }

        setFilterModel(newProps)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.LOCATION_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts

          if (props.items.length < Object.keys(isAnyChange.current).length) {
            let lenDiff = Object.keys(isAnyChange.current).length - props.items.length;
            let copyObj = { ...isAnyChange.current };
            for (let i = lenDiff; i >= 0; i--) {
              delete copyObj[i];
            }
            isAnyChange.current = copyObj;
          }
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })

          // no change (! reverse by not operator)
          if (Object.values(isAnyChange.current).every(item => item === false) && (props.items.length === Object.keys(isAnyChange.current).length) && (props.items.length === filterModal.items.length))
            return;
          else {
            setGridRows([]);
            setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
          }
          // if no changes on filter open ends


        }
      }
      return;
    }
  }


  return (
    <div className="jiraStatus">
      <div className="jiraStatusContainer">
        <ToastContainer />
        {/* <div className="datatable">
          <div className="top-headings">
            <h3>City</h3>
            <div>
              <Tooltip title="Add City">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
              </Tooltip>
              <RefreshButton api={getLocationList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 20 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 

            </div>
          </div> */}
        <div className="datatable">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>City</p>
            </div>
            <div>
              <Stack direction="row">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                <RefreshButton api={getLocationList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
              </Stack>
            </div>
          </div>
          {/* <div className="custom-pagination-class">
            <CustomPagination />
          </div> */}
          {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
          {
            !reload && */}
          <DataGridPro
            className="datagrid"
            filterMode="server"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            // rows={location_List?.data?.rows ? location_List.data.rows : []}
            rows={gridRows?.concat(skeletonRows)}
            columns={actionColumn}
            pageSize={pageSize}
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              ".MuiDataGrid-columnHeader":{
                "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root" : {
                  color: 'rgba(25, 39, 52, 1)'
                }
              },

              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            onFilterModelChange={onFilterChange}
            disableSelectionOnClick
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            onSortModelChange={(sort) => handleSort(sort)}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooterPagination={true}
          />
          {/* } */}
        </div>

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <Button
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} City</DialogTitle>

          <DialogContent>
            <form>
              <div className="add-new-user-form">
                <InputFields
                  label={"City"}
                  name="city_name"
                  defaultValue={formValues.city_name}
                  type="text"
                  placeholder="City"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.city_nameErr}
                />
                <InputFields
                  label={"Location code"}
                  name="code"
                  defaultValue={formValues.code}
                  type="text"
                  placeholder="Location-code"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.code_Err}
                />
                <div className="formInput">
                  <label >
                    Select Country
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    divstyle={{ flexBasis: '100%' }}
                    placeholder={'Select Country'}
                    onChange={handleChange}
                    defaultValue={formValues.country}
                    isSearchable={true}
                    name={'name'}
                    options={
                      country_List?.data?.rows.map((option) => {
                        return {
                          label: option.name,
                          value: option.id,
                          name: 'country'
                        }
                      })
                    }
                  />
                  {errStatus === true && formValues && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.country_Err}</p>}
                </div>

                <div className="formInput">
                  <label >
                    Select State
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    divstyle={{ flexBasis: '100%' }}
                    placeholder={'Select State'}
                    onChange={handleChange}
                    defaultValue={formValues.state}
                    isSearchable={true}
                    name={'name'}
                    options={
                      state_List?.data?.rows.map((option) => {
                        return {
                          label: option.name,
                          value: option.id,
                          name: 'state'
                        }
                      })
                    }
                  />
                  {errStatus === true && formValues && formValues.state === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.state_Err}</p>}
                </div>

                {/* <InputRadioGroupFields
                  label={"Status"}
                  name="status"
                  defaultValue={formValues.status}
                  type="text"
                  placeholder="Status"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.statusErr}
                /> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '20px' }}>
                    Status
                  </label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>Inactive</p>
                    <Switch name="status" checked={formValues.status} onChange={handleChange} />
                    <p>Active</p>
                  </div>
                </div>

              </div>
              <button style={{backgroundColor:"rgba(33, 150, 243, 1)"}} className="btn btn-submit" onClick={submitData}>{ID ? "Update" : "Submit" }</button>
            </form>
          </DialogContent>
        </Dialog>
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} Location`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >

          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          {/* <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem> */}

        </Menu>
      </div>
    </div >
  )
}

export default PreferredLocation;