import { createSlice } from "@reduxjs/toolkit";

const breadcrumbsSlice = createSlice({
    name: "breadcrumbs",
    initialState: {
        data: { main: [], active: '' },
        subdata: { main: [], active: '' },
        thirdleveldata: { main: [], active: '' },
        singleData: { main: [], active: '' }
    },
    reducers: {
        updateBreadcrumbs: (state, action) => {
            let { mainMenu, menuId } = action?.payload
            state.data = { main: mainMenu, active: menuId };
        },
        updateBreadcrumbSubMenu: (state, action) => {
            let { mainMenu, menuId } = action?.payload
            state.subdata = { main: mainMenu, active: menuId };
        },
        updatethirdlevelBreadcrumb: (state, action) => {
            let { mainMenu, menuId } = action?.payload
            state.thirdleveldata = { main: mainMenu, active: menuId };
        },
        updateSingleBreadcrumb: (state, action) => {
            let { mainMenu, menuId } = action?.payload
            state.singleData = { main: mainMenu, active: menuId };
        }
    }
});
export const { updateBreadcrumbs, updateBreadcrumbSubMenu, updatethirdlevelBreadcrumb, updateSingleBreadcrumb } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer;