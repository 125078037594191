import React, { useState, memo, useRef, useEffect } from "react";
import Select from "react-select";
import { Box, Tabs, Tab, Tooltip, } from "@mui/material";
import { InputFields } from "../../../common-function/InputFields";
import './part-one.scss'
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { questionslist } from "../../../../redux/features/hiringRequestSlice";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import LoaderForm from "../../../common-function/LoaderForm";
import PartThree from "./PartThree";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (<>{children} </>)}
        </div>
    );
}

const PartTwo = ({ technologies, setFormData, formData, testTypes, setTestTypes, parentIndex,
    test_type_key, level_key, questionType_List, is_mcq, formValues, questionlevel_list, preFormData, setPreFormData, for_agent
}) => {

    const dispatch = useDispatch()

    const [tabvalue, settabValue] = useState(0);
    // const [totalQuestion, setTotalQuestion] = useState(null)
    // const [randomQuestionNo, setRandomQuestionNo] = useState(null)
    // const [preDefineQuestionNo, setPreDefineQuestionNo] = useState(null)
    // const [error, setError] = useState(false)
    // const [formData, setFormData] = useState(initialFormData)
    const [statusOfLoader, setStatusOfLoader] = useState(false)
    const [updatedTechList, setUpdatedTechList] = useState([])
    const [indval, setIndval] = useState(0)
    const [updatedQuestionList, setUpdatedQuestionList] = useState([])

    const { question_list } = useSelector(state => state.hiringRequest)


    //Part three state and ref to persist the selected rows
    const [selectionModel, setSelectionModel] = useState([])
    let prevColIds = useRef({ selected: [] })

    useEffect(() => {
        setUpdatedTechList(technologies)
    }, [technologies])

    useEffect(() => {
        dispatch(questionslist((`{"filter":{"status":{"value":"True","operation": "icontains"}},"order_by":{"column":"-id"},"skip":0,"no_limit":"True"}`)))
        setUpdatedQuestionList(question_list?.data?.rows)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (testTypes[parentIndex].is_mcq === true || testTypes[parentIndex].is_mcq === 'true') {
            let arr = question_list?.data?.rows.filter((item) => item.question_type_key === 'mcq')
            setUpdatedQuestionList(arr)
        } else {
            setUpdatedQuestionList(question_list?.data?.rows)
        }
        // eslint-disable-next-line
    }, [testTypes[parentIndex].test_type_key, testTypes[parentIndex].is_for_agent_panel, testTypes[parentIndex].is_mcq, testTypes[parentIndex].total_no_question])

    const handleTabChange = (evt, newValue) => {
        if (Number(testTypes[parentIndex].total_no_question) === Number(testTypes[parentIndex]?.random_questions?.no_of_random_question) || Number(testTypes[parentIndex].total_no_question) === Number(testTypes[parentIndex]?.no_of_predefined_questions)) {
        } else {
            settabValue(newValue);
        }
    }

    // hanlde for total question no change
    const handleQuestionNoChange = (evt) => {
        const { name, value } = evt.target || evt
        if (name === "totalnumberquestions") {
            if (Number(value) < 0) {
                testTypes[parentIndex]['total_no_question'] = ""
                setFormData({ ...formData, total_no_question: "" })
                return toast.error("Please enter valid number")
            }
            testTypes[parentIndex]['total_no_question'] = value

            setFormData({ ...formData, total_no_question: value })
            // setTotalQuestion(value)
        } else if (name === "no_of_random_question") {
            if (Number(value) > Number(testTypes[parentIndex]['total_no_question'])) {
                return toast.error("Number of Random Questions exceed number of total question")
            } else {
                testTypes[parentIndex]['random_questions'] = { ...formData.random_questions, no_of_random_question: value }
                setFormData({ ...formData, random_questions: { ...formData.random_questions, no_of_random_question: value } })

                testTypes[parentIndex]['no_of_predefined_questions'] = Number(testTypes[parentIndex]['total_no_question']) - Number(value)
                testTypes[parentIndex]['predefined_questions'] = { ...preFormData.predefined_questions, no_of_predefined_questions: Number(testTypes[parentIndex]['total_no_question']) - Number(value) }

                // setRandomQuestionNo(value)
                // setPreDefineQuestionNo(Number(testTypes[parentIndex]['total_no_question']) - Number(value))
                // setError(false)
            }
        }
    }

    // handle for add more technology
    const addMoreTechnology = () => {
        if (formData?.random_questions?.technologies.length > 0) {
            const newData = Object.freeze({
                "technology_key": null,
                "question_type_details": {
                    // "mcq": 0,
                    // "programming": 0,
                    // "descriptive": 0
                }
            })
            setFormData({
                ...formData,
                random_questions: {
                    ...formData.random_questions,
                    technologies: [...formData?.random_questions?.technologies, newData]
                }
            })
            testTypes[parentIndex]['random_questions'] = {
                ...formData.random_questions,
                technologies: [...formData?.random_questions?.technologies, newData]
            }

        }
    }

    // handle remove technology
    const removeTechnology = (ind) => {
        setStatusOfLoader(true)
        const updatedList = formData.random_questions.technologies.filter((tech, i) => i !== ind)
        testTypes[parentIndex]['random_questions'] = { ...formData.random_questions, technologies: updatedList }
        setFormData({ ...formData, random_questions: { ...formData.random_questions, technologies: updatedList } })
        setTimeout(() => {
            setStatusOfLoader(false)
        }, 5)
    };


    // handle select technology
    const handleTechnologySelect = (evt, ind) => {
        const selectedTechnology = evt ? evt.key : null;
        testTypes[parentIndex]['random_questions'] = {
            ...formData.random_questions, technologies: formData.random_questions.technologies.map((technology, index) =>
                index === ind ? { ...technology, technology_key: selectedTechnology } : technology
            )
        }

        setFormData((prevState) => ({
            ...prevState,
            random_questions: {
                ...prevState.random_questions,
                technologies: prevState.random_questions.technologies.map((technology, index) =>
                    index === ind ? { ...technology, technology_key: selectedTechnology } : technology
                )
            }
        }));

        setUpdatedTechList(technologies.filter((tech, i) =>
            tech.key !== testTypes[parentIndex].random_questions.technologies.some((fil) => fil.technology_key)
        ))
    };

    useEffect(() => {
        const sum = testTypes[parentIndex]?.random_questions?.technologies.map((tech) => Object.values(tech.question_type_details).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0)).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0)
        if ((sum) > testTypes[parentIndex]?.random_questions?.no_of_random_question) {
            toast.error("Question type numbers exceed the total number of random questions")
        }
        // eslint-disable-next-line
    }, [indval, formData]);


    const handleQuestionTypeNoChange = (evt, ind) => {
        const { name, value } = evt.target
        // let sum = Object.values(formData.random_questions.technologies[ind].question_type_details)
        setIndval(ind)
        testTypes[parentIndex]['random_questions'] = {
            ...formData.random_questions, technologies: formData.random_questions.technologies.map((technology, index) =>
                index === ind ? { ...technology, question_type_details: { ...technology.question_type_details, [name]: value } } : technology
            )
        }

        setFormData(prevState => ({
            ...prevState,
            random_questions: {
                ...prevState.random_questions,
                technologies: prevState.random_questions.technologies.map((technology, index) =>
                    index === ind ? { ...technology, question_type_details: { ...technology.question_type_details, [name]: value } } : technology
                )
            }
        }))
    }

    return <>
        {/* <ToastContainer /> */}
        {/* <div > */}
        <div className="add-new-user-form">
            <div className="formInput">
                <InputFields
                    min={0}
                    flex=""
                    label={"Total Number Of Question"}
                    name="totalnumberquestions"
                    defaultValue={testTypes[parentIndex].total_no_question}
                    type="number"
                    placeholder="Total Number Of Question"
                    onChange={handleQuestionNoChange}
                // errStatus={errStatus}
                // formValues={formData.job_responsibilities}
                // formErr={formErrors.jobResErr}
                />
            </div>
        </div>

        {/* </div> */}
        {
            testTypes[parentIndex].total_no_question ?
                <div>
                    <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                        {/* <AppBar style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}> */}
                        <Tabs
                            value={tabvalue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab value={0} label="Random Question" />
                            <Tab value={1} label="PreDefine Question" />
                        </Tabs>
                        {/* </AppBar> */}
                        <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                            <div className="form-candidate-test">
                                <div className="add-new-user-form">
                                    <div className="formInput">
                                        <InputFields
                                            flex=""
                                            label={"Random Questions"}
                                            name="no_of_random_question"
                                            type="number"
                                            placeholder="Random Questions"
                                            onChange={handleQuestionNoChange}
                                            defaultValue={testTypes[parentIndex]?.random_questions?.no_of_random_question}
                                        // errStatus={errStatus}
                                        // formValues={formData.job_responsibilities}
                                        // formErr={formErrors.jobResErr}
                                        />

                                    </div>

                                </div>
                                {
                                    testTypes[parentIndex].random_questions?.technologies && testTypes[parentIndex].random_questions?.technologies.map((tech, ind) => {
                                        return <>
                                            <div key={ind}>
                                                <div className="add-new-user-form d-flex-clm">
                                                    {statusOfLoader === true ? <LoaderForm />
                                                        :
                                                        <div className="formInput" style={{ width: "32.5%"}}>
                                                            <label >Technology</label>

                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // divstyle={{ flexBasis: '100%' }}
                                                                placeholder={'Select Technology'}
                                                                name={'technology_key'}
                                                                onChange={(evt) => handleTechnologySelect(evt, ind)}
                                                                defaultValue={() => {
                                                                    return updatedTechList.map((tech) => {
                                                                        return tech.key === testTypes[parentIndex]?.random_questions?.technologies[ind].technology_key ? tech : null
                                                                    }).flat()
                                                                }}
                                                                isSearchable={true}
                                                                options={updatedTechList}
                                                            />
                                                        </div>
                                                    }

                                                    <div className="formInput" style={{ flexBasis: "1%"}}>
                                                        {
                                                            technologies && ind < (technologies.length - 1) && (
                                                                <span style={{ color: "green", cursor: "pointer" }}
                                                                    onClick={() => addMoreTechnology()}>
                                                                    <Tooltip title="Add More Technology">
                                                                        <AddCircleOutline style={{ marginTop: "0px" }} />
                                                                    </Tooltip>
                                                                </span>
                                                            )}

                                                    </div>
                                                    <div className="formInput" style={{ marginTop: "0px", }}>
                                                        {ind !== 0 ?

                                                            <div className="form-credencial-row-remove">
                                                                <span onClick={() => removeTechnology(ind)}>
                                                                    {/* Remove Candidate Test */}
                                                                    <Tooltip title="Remove Technology">
                                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} />
                                                                    </Tooltip>
                                                                </span>
                                                            </div>
                                                            : ""
                                                        }

                                                    </div>
                                                    {
                                                        updatedQuestionList?.map((questionType, index) => (
                                                            <div className="add-new-user-form" style={{ flexBasis: "32.5%", marginLeft:"-4px",marginTop:"5px"}} key={index}>
                                                                <div className="formInput screening-test-inputss" style={{ flexBasis: "32.5%",marginLeft:"-4px",marginTop:"-12px"}} key={questionType.question_type_key}>
                                                                    <InputFields

                                                                        flex=""
                                                                        label={"No. of " + questionType.question_type + " questions"}
                                                                        name={questionType.question_type_key}
                                                                        type="number"
                                                                        placeholder={"No. of " + questionType.question_type + " questions"}
                                                                        onChange={(evt) => handleQuestionTypeNoChange(evt, ind)}
                                                                        defaultValue={testTypes[parentIndex].random_questions.technologies[ind].question_type_details[questionType.question_type_key]}
                                                                    // formValues={formData.random_questions.technologies[ind].question_type_details[questionType.question_type_key]}
                                                                    // formErr={formErrors.jobResErr}
                                                                    />

                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                            </div>
                                        </>
                                    })

                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                            {/* part 3 section */}
                            {
                                test_type_key ?
                                    <PartThree
                                        index={parentIndex}
                                        formValues={formValues}
                                        technologies={technologies}
                                        testTypes={testTypes}
                                        setTestTypes={setTestTypes}
                                        test_type_key={test_type_key}
                                        level_key={questionlevel_list?.level_key}
                                        questionType_List={questionType_List}
                                        is_mcq={is_mcq}
                                        for_agent={for_agent}
                                        formData={preFormData}
                                        setFormData={setPreFormData}
                                        selectionModel={selectionModel}
                                        setSelectionModel={setSelectionModel}
                                        prevColIds={prevColIds}
                                    />
                                    : ""
                            }
                            {/* </div> */}
                        </TabPanel>
                    </Box>
                </div>
                : ""
        }

    </>
}
export default memo(PartTwo);
