import { toast } from "react-toastify";
import { AppBar, Box, Checkbox, Dialog, DialogContent, FormControl, FormLabel, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircleOutline, Close, RemoveCircleOutline } from "@mui/icons-material";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import moment from "moment";
import { skilOption,interviewerListOption } from "../../redux/features/optionsSlice";
import "./manageAvailability.scss"
import { availabiltyCreate, availabiltyFromDateFilter, availabiltyUpdate, getUserDetails } from "../../redux/features/interviewSlice";
import { resourceUpdateById } from "../../redux/features/resourceSlice";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { STATUSES } from "../../redux/common-status/constant";
import Select from 'react-select';

const user = LocalStorageDataService.userDetail();
const userId = user && user.id
const shiftStartTime = user && user.shift && user.shift.start_time ? user.shift.start_time : '09:30:00'
const shiftEndTime = user && user.shift && user.shift.end_time ? user.shift.end_time : '19:15:00'

const shiftStartHours = shiftStartTime && shiftStartTime.split(':')
const shiftEndHours = shiftEndTime && shiftEndTime.split(':')


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (<>{children} </>)}
        </div>
    );
}

function addZero(n) {
    return n > 9 ? "" + n : "0" + n;
}

let timeArrForFrom = [];
for (let i = 0; i <= 23; i++) {
    timeArrForFrom.push(addZero(i));
}

let minutesDurationList = [];
for (let i = 0; i <= 55; i += 5) {
    minutesDurationList.push(addZero(i));
}

let toHoursList = [];
for (let i = shiftStartHours[0]; i <= 23; i++) {
    toHoursList.push((i));
}

function timeToMins(time) {
    let b = time.split(':');
    return b[0] * 60 + +b[1];
}

// Convert minutes to a time in format hh:mm
// Returned value is in range 00  to 24 hrs
function timeFromMins(mins) {
    function z(n) { return (n < 10 ? '0' : '') + n; }
    let h = (mins / 60 | 0) % 24;
    let m = mins % 60;
    return z(h) + ':' + z(m);
}

// Add two times in hh:mm format
function addTimes(t0, t1) {
    return timeFromMins(timeToMins(t0) + timeToMins(t1));
}

const CreateAvailability = ({ openModal, closeModal, listApi, filters, editData, fromDateL, ID, setID }) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [, setErrStatus] = useState(false);
    const { status, error, manageAvailability_Create, user_Details, manageAvailability_fromDate, manageAvailability_Update } = useSelector(state => state.interview)
    const { skils } = useSelector(state => state.dropdownOptions)

    const [reload, setReload] = useState(false)

    const [fromDate, setFromDate] = useState(fromDateL ? fromDateL : "")

    //tabs
    const [tabLoading, setTabLoading] = useState(false)
    const [tabvalue, settabValue] = useState(0);

    // update technology
    const { updateResource } = useSelector(state => state.resource)
    const [selectTechnology, setSelectTechnology] = useState("")

    // create availability
    const [selectScheduleDetails, setSelectScheduleDetails] = useState(
        editData && editData.length > 0 && editData ? editData :
            [
                {
                    days: LocalStorageDataService.get_week_days(),
                    schedule_time: [
                        { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList, "hourList_from": [] }],
                    // { "availability_from": "", "availability_to": "", "availability_from_hours": "", "availability_from_minutes": "", "availability_to_minutes": "", "availability_to_hours": "" }],
                }
            ])


    const timeArray = (i) => {
        let timeArrayForTo = [];
        for (i; i <= 23; i++) {
            timeArrayForTo.push(addZero(i));
        }
        return timeArrayForTo
    }

    // const minutesTimeArray = (i) => {
    //     var timeArrayForToMin = [];
    //     for (i; i <= 55; i += 5) {
    //         timeArrayForToMin.push(addZero(i));
    //     }
    //     return timeArrayForToMin
    // }

    useEffect(() => {
        dispatch(skilOption())
        dispatch(interviewerListOption({ "filter": { "is_interviewer": { "value": "True", "operation": "eq" } }, "order_by": { "column": "name" }, "no_limit": "True", "columns": DefaultFilterColumns.RESOURCE_ROLES_DROUPDOWN_COLUMNS }))
        dispatch(getUserDetails())
        setTabLoading(true)
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    const handleTabChange = (event, newValue) => {
        setTabLoading(true)
        settabValue(newValue);
        clearStates();
        if (newValue === 0) {
            settabValue(0);
            dispatch(getUserDetails())
        }
    };

    // const closeModale = () => {
    //     if (ID) { setID(0); setFormValues(initialFormData) }
    //     setOpen(false);
    //     setFormValues(initialFormData);
    //     setErrStatus(false)
    //     // setSelectTechnology("");
    //     clearStates();
    //     // settabValue(0)
    // }

    // start technology and skill section 

    if (tabLoading === true && status === `${STATUSES.SUCCESS}_user_Details` && user_Details && user_Details.message) {
        if (user_Details.status === 200) {
            if (user_Details.data.technology) {
                let technologyArray = []
                user_Details.data.technology.length > 0 && user_Details.data.technology.map(e => {
                    return technologyArray.push({
                        label: e.skill_name,
                        value: e.id
                    })
                })
                setSelectTechnology(technologyArray);
            }
        } else {
            toast.error(user_Details.message)
        }
        setTabLoading(false)
    }

    const handleChangeOnTechnology = (evt) => {
        let technologyArr = []
        if (evt.length > 0) {
            evt.map(e => {
                return technologyArr.push(e.value)
            })
            setSelectTechnology(technologyArr)
        } else {
            setSelectTechnology("")
        }
    }

    const submitTechnology = (evt) => {
        evt.preventDefault()
        let techArr = []
        if (selectTechnology && selectTechnology.length > 0) {
            selectTechnology.map(e => techArr.push(e.value ? e.value : e))
        }
        let addvalues = {
            is_interviewer: true,
            technologies: techArr,
            user: LocalStorageDataService.userDetail().user_id,
            email: LocalStorageDataService.userDetail().email,
            password: LocalStorageDataService.userDetail().password,
            display_name: LocalStorageDataService.userDetail().display_name,
            name: LocalStorageDataService.userDetail().name,
            status: LocalStorageDataService.userDetail().status,
            cug_ph_no: LocalStorageDataService.userDetail().cug_ph_no,
            agent_id: LocalStorageDataService.userDetail().agent_id,
            contact_no:LocalStorageDataService.userDetail().contact_no
        }
        const resourceid = LocalStorageDataService.userDetail().id
        setReload("submit")
        dispatch(resourceUpdateById({ id: resourceid, addvalues: addvalues }))
    }

    if (reload === "submit" && updateResource && updateResource.message) {
        if (updateResource.status === 200) {
            toast.success(updateResource.message);
            setTimeout(() => {
                closeModal()
            }, 1000);
            setTabLoading(true)
            dispatch(getUserDetails())

        } else {
            toast.error(updateResource.message);
        }
        setReload(false)
    }
    // end technology and skill section 

    //start create your availability
    const updateFormDate = (evt) => {
        setFromDate(evt)
        setReload('fromDate_data')
        // dispatch(availabiltyFromDateFilter(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"from_date":{"value":"${evt}","operation": "eq"}},"skip":0, "limit":25}`))
        dispatch(availabiltyFromDateFilter(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"}},"schedule_date":"${evt}","skip":0, "limit":25}`))
    }

    if (reload === 'fromDate_data' && status === `${STATUSES.SUCCESS}_manageAvailability_fromDate` && manageAvailability_fromDate && manageAvailability_fromDate.data) {
        let datas = manageAvailability_fromDate.data && manageAvailability_fromDate.data ? manageAvailability_fromDate.data : []
        // let count = manageAvailability_fromDate.data && manageAvailability_fromDate.data.count ? manageAvailability_fromDate.data.count : 0

        // setActiveEditMode(true)
        setID(datas.id)
        setReload(false)

        let slots = { schedule_details: [] };
        let tempDaysArr = []

        // eslint-disable-next-line
        datas.schedule_details && datas.schedule_details.length > 0 && datas.schedule_details.map((e, index) => {
            // e['is_week_day_seleceted'] = true

            let dayObject = {}
            let dayArray = []
            let newScheduleTime = []

            // eslint-disable-next-line
            e.days.map((item, i) => {
                dayObject = {
                    // ...dayObject,
                    'label': item,
                    'value': item,
                    'is_checked': true,
                    'p_index': index,
                    'disabled': true
                }
                dayArray.push(dayObject)
                tempDaysArr.push(item)
            })

            // eslint-disable-next-line
            e.schedule_time.map((item, i) => {
                let to_hour = item.availability_to.split(':')[0]
                let to_min = item.availability_to.split(':')[1]
                let from_hour = item.availability_from.split(':')[0]
                let from_min = item.availability_from.split(':')[1]
                let hourList = timeArray(Number(from_hour))
                let hourListFrom = timeArray(Number(from_hour))

                let timeObject = {
                    ...item,
                    disabled: true,
                    availability_from_hours: from_hour,
                    availability_from_minutes: from_min,
                    availability_to_hours: to_hour,
                    availability_to_minutes: to_min,
                    hourList: hourList,
                    hourList_from: hourListFrom,
                    edit_mode: false
                }
                newScheduleTime.push(timeObject)
            })
            slots.schedule_details.push(
                { days: dayArray, schedule_time: newScheduleTime, disabled: true, is_week_day_seleceted: true }
            )
        })

        let filterDays = LocalStorageDataService.get_week_days().filter(o1 => !tempDaysArr.some(o2 => o1.value === o2))
        let tempDayObj
        if (filterDays && filterDays.length > 0 && slots.schedule_details && slots.schedule_details.length > 0) {
            // eslint-disable-next-line
            filterDays.map((item, i) => {
                tempDayObj = {
                    'label': item.label,
                    'value': item.value,
                    'is_checked': false,
                    'p_index': slots.schedule_details.length - 1,
                    'disabled': true
                }
                slots.schedule_details[slots.schedule_details.length - 1].days.push(tempDayObj)
            })
        }

        slots.schedule_details && slots.schedule_details.length > 0 ?
            setSelectScheduleDetails(slots.schedule_details)
            :
            setSelectScheduleDetails([
                {
                    days: LocalStorageDataService.get_week_days(),
                    schedule_time: [
                        { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList, "hourList_from": [] }],
                }
            ])
    }

    const clearStates = () => {
        setReload(false)
        setFromDate(fromDateL ? fromDateL : "")
        if (editData && editData.length > 0) {
            setSelectScheduleDetails(editData?.length > 0 && editData)
        } else {
            setSelectScheduleDetails([{
                days: LocalStorageDataService.get_week_days(),
                schedule_time: [
                    { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList }],
            }])
        }
    }

    const addMoreScheduleDetails = () => {
        let array = []
        // eslint-disable-next-line
        selectScheduleDetails.map((e) => {
            if (e.is_week_day_seleceted === true) {
                e['is_week_day_seleceted'] = false
                // eslint-disable-next-line
                e.days.map((item, i) => {
                    if (ID && (item.is_checked === false || !item.is_checked)) {
                        return array.push({
                            ...item,
                            disabled: false
                        })
                    } else if (item.is_checked === false || !item.is_checked) {
                        return array.push(item)
                    }
                })
            }
            // e['index'] = Index
        })

        let uniqueStoryArray = [...new Map(array.map((item) => [item["value"], item])).values()];
        // uniqueStoryArray && uniqueStoryArray.length > 0 && setSelectScheduleDetails([...selectScheduleDetails,
        //     {
        //         'days': uniqueStoryArray,
        //         "schedule_time": [
        //             { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList }],
        //     }])

        if (uniqueStoryArray && uniqueStoryArray.length > 0) {
            if (ID) {
                setSelectScheduleDetails([...selectScheduleDetails,
                {
                    'days': uniqueStoryArray,
                    "schedule_time": [
                        { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList, "new": true, }],
                }])
            } else {
                setSelectScheduleDetails([...selectScheduleDetails,
                {
                    'days': uniqueStoryArray,
                    "schedule_time": [
                        { "availability_from": shiftStartTime, "availability_to": shiftEndTime, "availability_from_hours": shiftStartHours[0], "availability_from_minutes": shiftStartHours[1], "availability_to_minutes": shiftEndHours[1], "availability_to_hours": shiftEndHours[0], "hourList": toHoursList }],
                }])
            }

        }
    }

    const removeScheduleDetails = (i) => {
        const updateScheduleDetails = selectScheduleDetails.filter((item, index) => index !== i);
        const length = updateScheduleDetails.length - 1
        updateScheduleDetails[length]["is_week_day_seleceted"] = true
        setSelectScheduleDetails(updateScheduleDetails)
    }

    const addMoreSlotTime = (i) => {
        let array = []

        let filterSchedule = selectScheduleDetails
        let lastIndex = filterSchedule[i].schedule_time.length - 1

        const updateScheduleDetails = filterSchedule.map((e, index) => {
            array = e.schedule_time && e.schedule_time.length > 0 ? e.schedule_time.concat({ "availability_from": "", "availability_to": "" }) : [{ "availability_from": "", "availability_to": "" }]
            if (index === i) {
                e.schedule_time[lastIndex]['disabled'] = true

                let updateTime = addTimes(e.schedule_time[lastIndex].availability_to, `00:05`)
                let updateToTime = addTimes(e.schedule_time[lastIndex].availability_to, `00:10`)
                let hourListTo = timeArray(Number(updateTime.split(':')[0]))

                let newTime = updateToTime.split(':')[0] + ":" + updateToTime.split(':')[1]

                array = e.schedule_time && e.schedule_time.length > 0 ?
                    e.schedule_time.concat(
                        { "availability_from": updateTime, "availability_to": newTime, "availability_to_minutes": updateToTime.split(':')[1], "availability_to_hours": updateToTime.split(':')[0], "hourList_from": timeArray(Number(updateTime.split(':')[0])), "availability_from_hours": updateTime.split(':')[0], "availability_from_minutes": updateTime.split(':')[1], "hourList": hourListTo })
                    : [
                        { "availability_from": updateTime, "availability_to": newTime, "availability_to_minutes": updateToTime.split(':')[1], "availability_to_hours": updateToTime.split(':')[0], "hourList_from": timeArray(Number(updateTime.split(':')[0])), "availability_from_hours": updateTime.split(':')[0], "availability_from_minutes": updateTime.split(':')[1], "hourList": hourListTo }]


                let result = array.map(function (el) {
                    let obj = Object.assign({}, el);
                    if (ID && obj.edit_mode !== false && !obj.edit_mode) {
                        obj.new = true;
                    }
                    return obj;
                })
                e['schedule_time'] = result;
            }
            return e
        })
        setSelectScheduleDetails(updateScheduleDetails)
    }

    const removeSlotTime = (i, parent_index) => {
        const filterSchedule = selectScheduleDetails.map((item, index) => {
            // if (index === parent_index) {
            //     let firstIndex = i - 1
            //     if (firstIndex >= 0) {
            //         item['schedule_time'][firstIndex]['disabled'] = false
            //     }
            //     const updateDetails = item.schedule_time.filter((itm, idx) => idx !== i)
            //     item['schedule_time'] = updateDetails
            // }
            if (index === parent_index) {
                let firstIndex = i - 1
                if (firstIndex >= 0) {
                    // eslint-disable-next-line
                    item.schedule_time.map((itm, i) => {
                        if (!itm.edit_mode || itm.edit_mode === false) {
                            item['schedule_time'][firstIndex]['disabled'] = true
                        } else {
                            item['schedule_time'][firstIndex]['disabled'] = false
                        }
                    })
                    const updateDetails = item.schedule_time.filter((itm, idx) => idx !== i)
                    item['schedule_time'] = updateDetails
                }
            }
            return item
        })

        setSelectScheduleDetails(filterSchedule)
    }

    const handleCheckbox = (evt, p_index, c_index) => {
        const { name, checked } = evt.target
        let newScheduleDetails = structuredClone(selectScheduleDetails)
        let parentIndex = newScheduleDetails[p_index].days;
        newScheduleDetails[p_index]['is_week_day_seleceted'] = true;
        // eslint-disable-next-line
        parentIndex.map(((item) => {
            if (item.value === name) {
                item["is_checked"] = checked;
                item["p_index"] = p_index;
            }
        }))
        setSelectScheduleDetails(newScheduleDetails)
        // selectScheduleDetails && selectScheduleDetails.map((e, i) => {
        //     if (p_index === i) {
        //         e['is_week_day_seleceted'] = true
        //         // eslint-disable-next-line
        //         e.days.map(((item, jindex) => {
        //             if (item.value === name && jindex === c_index) {
        //                 item["is_checked"] = checked;
        //                 item["p_index"] = p_index;
        //             }
        //         }))
        //     }
        // })

    }

    const handleChangeAvailableFromTime = (evt, index = null, parent_index = null) => {
        const { name, value } = evt.target || evt;
        let flag = false
        const updateObject = selectScheduleDetails.length > 0 && selectScheduleDetails.map((e, i) => {
            if (i === parent_index) {
                if (e.schedule_time.length > 0) {
                    // eslint-disable-next-line
                    e.schedule_time.map((items, idx) => {
                        let lastIndex = index - 1

                        if (idx === index) {
                            // for min validation
                            if (lastIndex > -1) {
                                if (items && Number(e.schedule_time[lastIndex].availability_to_hours) === Number(items.availability_from_hours) && Number(e.schedule_time[lastIndex].availability_to_minutes) >= Number(value) && name === "availability_from_minutes") {
                                    flag = true
                                    return toast.error("This slot is already added")
                                }
                                // for hours validation
                                if (items && Number(e.schedule_time[lastIndex].availability_to_hours) === Number(value) && Number(e.schedule_time[lastIndex].availability_to_minutes) > Number(items.availability_from_minutes) && name === "availability_from_hours") {
                                    flag = true
                                    return toast.error("This slot is already added")
                                }
                            }

                            let total_min = items.availability_from_minutes ? items.availability_from_minutes : "00"
                            let total_hour = items.availability_from_hours ? items.availability_from_hours : "00"
                            let total_time

                            if (name === "availability_from_hours" && flag === false) {
                                total_hour = value
                                total_time = total_hour + ":" + total_min
                                items['availability_from_hours'] = value
                                items['availability_from'] = total_time
                                items['availability_to_hours'] = value
                                if (Number(items.availability_from_minutes) === 55) {
                                    total_hour = Number(items.availability_from_hours) + 1
                                    items['availability_to_hours'] = Number(items.availability_from_hours) + 1
                                    // eslint-disable-next-line
                                    items['availability_to'] = total_hour + ":" + "00"
                                } else {
                                    total_hour = Number(items.availability_from_hours)
                                    items['availability_to_hours'] = items.availability_from_hours
                                    items['availability_to'] = total_hour + ":" + (Number(total_min) + 5)
                                }
                            }

                            if (name === "availability_from_minutes" && flag === false) {
                                total_min = value
                                total_time = total_hour + ":" + total_min
                                items['availability_from_minutes'] = value
                                items['availability_from'] = total_time
                                if (Number(value) === 55) {
                                    total_hour = Number(items.availability_from_hours) + 1
                                    items['availability_to_hours'] = Number(items.availability_from_hours) + 1
                                    // eslint-disable-next-line
                                    items['availability_to'] = total_hour + ":" + '00'
                                } else {
                                    total_hour = Number(items.availability_from_hours)
                                    items['availability_to_hours'] = items.availability_from_hours
                                    items['availability_to'] = total_hour + ":" + (Number(value) + 5)
                                }
                                if (Number(value) === 55) {
                                    items['availability_to_minutes'] = '00'
                                } else if (Number(value) === 0) {
                                    items['availability_to_minutes'] = "05"
                                } else {
                                    items['availability_to_minutes'] = Number(value) + 5
                                }
                            }
                            // eslint-disable-next-line
                            items['hourList'] = timeArray(Number(total_hour))
                        }
                    })
                }
            }

            return e
        })

        setTimeout(() => {
            setSelectScheduleDetails(updateObject)
        }, 500);
    }

    const handleChangeAvailableToTime = (evt, index = null, parent_index = null) => {
        const { name, value } = evt.target || evt;
        const updateObject = selectScheduleDetails.length > 0 && selectScheduleDetails.map((e, i) => {
            if (i === parent_index) {
                if (e.schedule_time.length > 0) {
                    // eslint-disable-next-line
                    e.schedule_time.map((items, idx) => {
                        if (idx === index) {
                            if (items && Number(items.availability_to_hours) === Number(items.availability_from_hours) && Number(items.availability_from_minutes) >= Number(value) && name === "availability_to_minutes") {
                                return toast.error("This slot is already added")
                            }

                            // // for hours validation
                            if (items && Number(items.availability_from_hours) === Number(value) && Number(items.availability_from_minutes) >= Number(items.availability_to_minutes) && name === "availability_to_hours") {
                                return toast.error("This slot is already added")
                            }
                            let total_hour = items.availability_to_hours ? items.availability_to_hours : "00"
                            let total_min = items.availability_to_minutes ? items.availability_to_minutes : "00"
                            let total_time
                            if (name === "availability_to_hours") {
                                total_hour = value
                                total_time = total_hour + ":" + total_min
                                items['availability_to_hours'] = value
                                items['availability_to'] = total_time
                            }
                            if (name === "availability_to_minutes") {
                                total_min = value
                                total_time = total_hour + ":" + total_min
                                items['availability_to_minutes'] = value
                                items['availability_to'] = total_time
                            }
                        }
                    })
                }
            }
            return e
        })

        setTimeout(() => {
            setSelectScheduleDetails(updateObject)
        }, 500);
    }

    //end create your availability

    const submitData = (evt) => {
        evt.preventDefault()
        const updateData = structuredClone(selectScheduleDetails);
        let addvalues = {}
        // eslint-disable-next-line
        const data = updateData.map((item, index) => {
            let filterDyasData = item && item.days.filter((itm) => itm.is_checked === true && itm.p_index === index)
            let filterArray = []
            filterDyasData.map((e) => {
                return filterArray.push(e.value)
            })
            item['days'] = filterArray
            item && item.schedule_time.map(ele => {
                delete ele.availability_from_hours
                delete ele.availability_from_minutes
                delete ele.availability_to_hours
                delete ele.availability_to_minutes
                delete ele.hourList
                delete ele.hourList_from
                delete ele.disabled
                delete ele.edit_mode
                return ele
            })
            delete (item.is_week_day_seleceted)
            delete (item?.disabled)

            return item
        })

        setErrStatus(false)
        setReload('submit')
        addvalues['from_date'] = fromDate
        addvalues['resource'] = userId
        addvalues['schedule_details'] = data
        // setSelectScheduleDetails(selectScheduleDetails)
        if (ID) {
            addvalues['updated_by'] = userId
            dispatch(availabiltyUpdate({ ID, addvalues }))
        } else {
            addvalues['created_by'] = userId
            dispatch(availabiltyCreate(addvalues))
        }
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_manageAvailability_Create` && manageAvailability_Create && manageAvailability_Create.message) {
        if (manageAvailability_Create.status === 200) {
            toast.success(manageAvailability_Create.message)
            dispatch(listApi(filters))
            closeModal()
            clearStates()
            settabValue(0)
        } else {
            toast.error(manageAvailability_Create.message)
        }
        setReload(false)
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_manageAvailability_Update` && manageAvailability_Update && manageAvailability_Update.message) {
        if (manageAvailability_Update.status === 200) {
            settabValue(0)
            toast.success(manageAvailability_Update.message)
            dispatch(listApi(filters))
            closeModal()
            clearStates()
        } else {
            toast.error(manageAvailability_Update.message)
        }
        setReload(false)
    }

    return (
        <div className="manage-availability">
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={openModal}
                onClose={() => { closeModal(); clearStates(); settabValue(0); }}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={() => { closeModal(); clearStates(); settabValue(0); }}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>

                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                    Manage Availability
                </DialogTitle>

                <DialogContent style={{ minHeight: "400px" }}>
                    <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                        <AppBar position="sticky" style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab value={0} label="Technology and skill set" />
                                <Tab value={1} label="Create your availability" />
                            </Tabs>
                        </AppBar>

                        {/*  technology tabs*/}
                        <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                            <form>
                                <div className="add-new-user-form">
                                    <div className="formInput" style={{ flexBasis: "100%", marginTop: "5px" }}>
                                        <label>Skill</label>
                                        <Select
                                            isMulti
                                            className=""
                                            classNamePrefix="select"
                                            placeholder="Select Technogogy"
                                            defaultValue={selectTechnology}
                                            // value={selectTechnology}
                                            isSearchable={true}
                                            name={"technology"}
                                            options={
                                                skils && skils.map((option) => {
                                                    return { label: option.label, value: option.value, name: "technology" }
                                                })
                                            }
                                            onChange={handleChangeOnTechnology}
                                        />
                                    </div>
                                </div>
                                <button
                                    className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                    style={{ marginBottom: "20px", marginTop: "20px", width: "190px" }}
                                    // disabled={reload === "submit" ? true : false} 
                                    onClick={submitTechnology}>
                                    {selectTechnology && selectTechnology.length > 0 ? 'Update' : 'Add'} Skill
                                </button>
                            </form>
                        </TabPanel>

                        {/*  create availability tabs*/}
                        <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                            <form>
                                <div className="add-new-user-form">
                                    <div className="formInput">
                                        <div className="formInput pickets-input">
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">{'From Date'}</FormLabel>
                                                <TextField
                                                    id="date"
                                                    type="date"
                                                    name={'form_date'}
                                                    onChange={(evt) => updateFormDate(evt.target.value)}
                                                    defaultValue={fromDate}
                                                    sx={{ width: 220 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                    disabled={fromDateL && ID ? true : false}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                {
                                    fromDate !== "" ?
                                        <>
                                            <div className="add-new-user-form"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    marginBottom: "10px"
                                                }}>
                                                <h4>Schedule Details &nbsp;</h4>
                                                <span style={{ color: "green", cursor: "pointer" }}
                                                    onClick={() => addMoreScheduleDetails()}>
                                                    <Tooltip title="Add More Schedule">
                                                        <AddCircleOutline style={{ marginTop: "5px" }} />
                                                    </Tooltip>
                                                </span>
                                            </div>

                                            <div className="add-new-user-form" style={{ display: "unset" }}>
                                                <h6>Week Days</h6><br />
                                                <div className="formInput" style={{ flexBasis: "65%" }}>
                                                    {selectScheduleDetails && selectScheduleDetails.map((option, index) => {

                                                        return (
                                                            <div>
                                                                {option.days && option.days.length > 0 && option.days.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Checkbox
                                                                                className="checkbox"
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name={item.value}
                                                                                checked={item.is_checked === true ? true : false}
                                                                                onChange={(e) => handleCheckbox(e, index, i)}
                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                                disabled={item.disabled && ID ? true : false}
                                                                            />
                                                                            <label style={{ fontSize: "17px", marginLeft: "-9px" }}>{item.label}</label>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                <div className="add-new-user-form"
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginBottom: "10px",
                                                                        justifyContent: "flex-start",
                                                                        marginTop: "8px"
                                                                    }}>
                                                                    <h5>Available Slot  &nbsp;</h5>
                                                                    <span style={{ color: "green", cursor: "pointer" }} onClick={() => addMoreSlotTime(index)}>
                                                                        <Tooltip title="Add More Slot">
                                                                            <AddCircleOutline />
                                                                        </Tooltip>
                                                                    </span>
                                                                </div>
                                                                {option && option.schedule_time && option.schedule_time.length > 0 && option.schedule_time.map((item, i) => {
                                                                    return (
                                                                        <div className="add-time-field" style={{ display: "flex" }}>
                                                                            <div className="formInput formInputTime" style={{ flexBasis: "20%" }}>
                                                                                <label style={{ fontSize: "18px" }}>Availability From</label>

                                                                                <div className="formInput formInputTime" style={{ flexBasis: "20%" }}>
                                                                                    <span>Hours</span>
                                                                                    <select
                                                                                        className="time-hours-classes"
                                                                                        style={{ minWidth: "unset" }}
                                                                                        name={"availability_from_hours"}
                                                                                        onChange={(evt) => handleChangeAvailableFromTime(evt, i, index)}
                                                                                        value={item.availability_from_hours}
                                                                                        disabled={item.disabled ? true : false}
                                                                                    >
                                                                                        {i === 0 ? timeArrForFrom.map((e, i) => {
                                                                                            return (<option value={e} key={i}>{e}</option>)
                                                                                        }) :
                                                                                            item && item.hourList_from && item.hourList_from.map((e, i) => {
                                                                                                return (<option value={e} key={i}>{e}</option>)
                                                                                            })
                                                                                        }
                                                                                    </select>

                                                                                    <span style={{ marginLeft: "15px" }}>Minutes</span>
                                                                                    <select
                                                                                        style={{ minWidth: "unset" }}
                                                                                        className="time-minutes-classes"
                                                                                        name={"availability_from_minutes"}
                                                                                        onChange={(evt) => handleChangeAvailableFromTime(evt, i, index)}
                                                                                        value={item.availability_from_minutes}
                                                                                        disabled={item.disabled ? true : false}
                                                                                    >
                                                                                        {minutesDurationList.map((e, i) => {
                                                                                            return (<option value={e} key={i}>{e}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="formInput formInputTime" style={{ flexBasis: "20%" }}>
                                                                                <label style={{ fontSize: "18px" }}>Availability to</label>

                                                                                <div className="formInput formInputTime" style={{ flexBasis: "20%" }}>
                                                                                    <span>Hours</span>
                                                                                    <select
                                                                                        className="time-hours-classes"
                                                                                        style={{ minWidth: "unset" }}
                                                                                        name={"availability_to_hours"}
                                                                                        onChange={(evt) => handleChangeAvailableToTime(evt, i, index)}
                                                                                        value={item.availability_to_hours}
                                                                                        disabled={item.disabled ? true : false}
                                                                                    >
                                                                                        {item && item.hourList && item.hourList.map((e, i) => {
                                                                                            return (<option value={e} key={i}>{e}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                    <label>
                                                                                        <span style={{ marginLeft: "15px" }}>Minutes</span>
                                                                                        <select
                                                                                            style={{ minWidth: "unset" }}
                                                                                            className="time-minutes-classes"
                                                                                            name={"availability_to_minutes"}
                                                                                            onChange={(evt) => handleChangeAvailableToTime(evt, i, index)}
                                                                                            value={item.availability_to_minutes}
                                                                                            disabled={item.disabled ? true : false}
                                                                                        >
                                                                                            {minutesDurationList.map((e, i) => {
                                                                                                return (<option value={e} key={i}>{e}</option>)
                                                                                            })}
                                                                                        </select>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            {i !== 0 && item.edit_mode !== false ?
                                                                                <div className="">
                                                                                    <Tooltip title="Remove Slot">
                                                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer", marginTop: "60px" }} onClick={() => removeSlotTime(i, index)} />
                                                                                    </Tooltip>
                                                                                </div>
                                                                                :
                                                                                <div className="formInput formInputTime"></div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                                {index !== 0 && option.disabled !== true ?
                                                                    <div className="form-credencial-row-remove">
                                                                        <span onClick={() => removeScheduleDetails(index)}>
                                                                            {/* Remove Schedule */}
                                                                            <Tooltip title="Remove Schedule">
                                                                                <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeScheduleDetails(index)} />
                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>

                                            </div>

                                            <button
                                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                                style={{ width: "180px" }}
                                                disabled={reload === "submit" ? true : false}
                                                onClick={submitData}>
                                                Create availability
                                            </button>
                                        </>
                                        : ""
                                }

                            </form>
                        </TabPanel>
                    </Box>
                </DialogContent >
            </Dialog >
        </div >
    )
}
export default CreateAvailability