import BasicLayout from '../../components/layout/BasicLayout'
import './Workflows.scss'

import { toast, ToastContainer } from 'react-toastify';
import React, { useEffect, useMemo, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import { useDispatch, useSelector } from "react-redux"
import {
  workFlowList,
  //  updateStatusList,
  statusList, workflowMappingForWorkflowId
} from "../../redux/features/workflowSlice"
import { STATUSES } from "../../redux/common-status/constant"
import LoaderForm from '../../components/common-function/LoaderForm';
import moment from 'moment';
import Select from 'react-select';
import CustomButton from '../../components/button/CustomButton';
import Filter from '../../components/Filter';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Switch, Tooltip } from '@mui/material';

const { REACT_APP_ENV } = process.env;

const errorLogs = {
  workflowErr: "Workflow is required",
  workflowStatusErr: "Workflow status is required",
  defaultWorkflowStatusErr: "Default workflow is required"
}

const Workflows = () => {

  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { workflows, status_List, sts_status,
    workflow_Status, workflow_Count, workflow_StatusMapping, workflow_StatusMapSingleObj
  } = useSelector(state => state.workflow)

  const [errStatus, setErrStatus] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})

  const [updatingAssignment, setUpdatingAssignment] = useState(false);

  const statusParam = { filter: { is_jira_status: { value: "False", operation: "eq" } }, "no_limit": "True" }
  const workFlowDetail = useLocation().state;

  const navigate = useNavigate();
  const [workFlowData, setworkFlowData] = useState({})
  const [editFormData, setEditFormData] = useState({})
  const [relatedStatusOption, setRelatedStatusOption] = useState([])
  const [status_RelatedStatusMapping, setStatus_RelatedStatusMapping] = useState([])
  const [finalStatusMapping, setFinalStatusMapping] = useState([])

  const [isInitialStatus, setIsInitialStatus] = useState({})
  const [color, setcolor] = useState()

  const workflowColumns = [
    { field: "id", headerName: "ID", width: 70, hideable: true, filterable: false, sortable: false },
    {
      field: "workflow_id",
      headerName: "Workflow Name",
      width: 275,

      renderCell: (params) => {
        return (
          <p>{params.row.workflow.name}</p>
        )
      }
    },
    {
      field: "code",
      headerName: "Code",
      width: 250,
      hideable: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <p>{params.row.workflow.code}</p>
        )
      }
    },
    {
      field: "workflow_status_id",
      headerName: "Workflow Status",
      width: 240,
      hideable: true,
      filterable: true,
      sortable: false,
      type: 'singleSelect',
      valueOptions: status_List.map(e => ({ label: e.name, value: e.id })),
      renderCell: (params) => {
        return (
          <p>{params.row.workflow_status.name}</p>
        )
      },

    },
    {
      field: "related_status",
      headerName: "Relateds Status",
      width: 480,
      hideable: true,
      filterable: false,
      sortable: false,
      type: 'singleSelect',
      valueOptions: status_List.map(e => ({ label: e.name, value: e.id })),
      renderCell: (params) => {
        return (
          <p>{(params.row.related_status && params.row.related_status.length) ? filterObjectForIdArray(status_List, params.row.related_status).map((sts, index) => {
            const arrayLength = params.row.related_status.length;
            return (
              <span key={index}>
                {sts.name}{(arrayLength - 1 === index) ? "" : ", "}
              </span>
            )
          }) : ""}</p>
        )
      },

    },
    {
      field: "is_initial",
      headerName: "Is Initial",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Yes", value: "True" },
        { label: "No", value: "False" },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.is_initial}`} >
            <p>{params.row.is_initial === true ? 'Yes' : 'No'}</p>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: "True" },
        { label: "Inactive", value: "False" },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`} >
            <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          </div>
        );
      },
    },
  ];



  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "workflow_status_id" ?
        <Filter options={status_List.map(e => ({ label: e.name, value: e.id }))} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "related_status" ?
          <Filter options={status_List.map(e => ({ label: e.name, value: e.id }))} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "workflow_id" ?
            <Filter options={workflows.map(e => ({ label: e.name, value: e.id }))} onChange={handleFilterChange} defaultValue={defaultValue} />
            : fieldName === "status" ?
              <Filter options={[
                { label: "Active", value: "True" },
                { label: "Inactive", value: "False" },
              ]} onChange={handleFilterChange} defaultValue={defaultValue} />
              : ""
    );
  }

  const columns = useMemo(() =>
    workflowColumns.map((col) => {
      if (col.field === "workflow_status_id" || col.field === "related_status" || col.field === "workflow_id" || col.field === "status") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [workflowColumns]
  );

  // Find object_list from ids'
  const filterObjectForIdArray = (objArray, idArray) => {
    const filterResult = objArray.filter(e => {
      let arr = [];
      if (idArray.includes(e.id)) {
        const res = {
          id: e.id,
          label: e.name,
          name: e.name,
          value: e.id
        }
        return res;
      }
      return arr;
    })
    return filterResult;
  }

  //  Close Modal
  const onCloseModalAssignment = () => {
    setEditFormData({});
    setworkFlowData({});
    setFinalStatusMapping([]);
    setStatus_RelatedStatusMapping([])

  }

  // On View
  const onView = (data) => {
    setEditFormData(
      {
        id: data.id,
        name: data.workflow.name,
        code: data.workflow.code,
        workflow_status: data.workflow_status.name,
        related_status: (data.related_status && data.related_status.length)
          ?
          filterObjectForIdArray(status_List, data.related_status).map(e => ({ ...e, label: e.name }))
          :
          [],
        status: data.status,
        created_by: userId
      });
  }


  const [formData, setFormData] = useState({ workflow: "", workflow_status: "", status: false, default_status: "", ...workFlowData })


  useEffect(() => {
    workFlowDetail?.id && dispatch(workflowMappingForWorkflowId(workFlowDetail.id))
    if (workFlowDetail?.id && workflow_Status === STATUSES.SUCCESS) {
      setStatus_RelatedStatusMapping(
        workflow_StatusMapSingleObj.workflow_mapping.map((option, index) => {
          return ({
            from_status: { id: option.workflow_status.id, label: option.workflow_status.name, name: option.workflow_status.name, value: option.workflow_status.value, },
            // relatedStatusOptions: status_List.filter((e, index) => {
            //   if (option.related_status.includes(e.id)) {
            //     return e;
            //   }
            // }),
            relatedStatusOptions: status_List.filter((e, index) =>
              (option.related_status.includes(e.id))
            ),
            // option.related_status,
          })
        })
      )
      setRelatedStatusOption(workflow_StatusMapSingleObj.workflow_status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  workflow_StatusMapping.filter(e => {
    let arr = []
    if (e.is_initial) {
      if (workFlowDetail.id && !isInitialStatus.id) {
        setIsInitialStatus({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "default_status" })
      }
    }
    return arr;
  })

  const handleEdit = (data) => {
    setEditFormData(data);
    setUpdatingAssignment(true)
    setworkFlowData(
      {
        ...workFlowData,
        workflow: data.workflow,
      }
    )
  }


  const handleChange = (evt) => {
    let eventValue = {}
    if (evt.type && evt.type === "date") {
      eventValue = { name: evt.name, type: evt.type, value: evt.format ? moment(evt.value).format(evt.format) : evt.value }
    }
    const { name, value, type, checked } = (evt && evt.target) ? evt.target : eventValue;
    const finalValue = type === "checkbox" ? checked : type === "select" ? value.value : value
    if (name === "workflow_status") {
      setStatus_RelatedStatusMapping(
        finalValue.map((option, index) => {
          return ({
            from_status: option,
            // relatedStatusOptions: finalValue.filter((e, indx) => {
            //   if (index !== indx) { return e }
            // })
            relatedStatusOptions: finalValue.filter((e, indx) =>
              (index !== indx)
            )
          })
        })
      )
      setFinalStatusMapping(
        finalValue.map((option, index) => {
          return ({
            from_status: option.id,
            related_status: []
          })
        })
      )
      setRelatedStatusOption(finalValue);
    }
    setFormData(updatingAssignment ? { ...workFlowData, ...formData, [name]: name === 'status' ? checked : finalValue } : { ...formData, [name]: name === 'status' ? checked : finalValue })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(formData.workflow === '' || formData.workflow_status === '' || formData.default_status === '') {
      setErrStatus(true)
      return
    }
    setErrStatus(false);

    const finalData = workFlowData.id ? { ...workFlowData, ...formData } : formData
    const created_updated_by = workFlowData.id ? "updated_by" : "created_by"
    const postData = {
      id: finalData.id,
      workflow: finalData.workflow?.value || editFormData.workflow_id,
      workflow_status: finalData?.workflow_status ? finalData.workflow_status.map(e => { return e.id }) : editFormData.workflow_status,
      status_mapping: finalStatusMapping.map(e => ({
        "workflow": e.workflow,
        "workflow_status": e.workflow_status,
        "is_initial": finalData.default_status?.value === e.workflow_status,
        "related_status": e.related_status,
        "created_by": e.created_by
      })),
      "status": finalData?.status ?? editFormData.status,
      [created_updated_by]: userId
    };
      
    if (!postData?.workflow_status || !postData?.workflow) {
      toast.error("Enter the mandatory details!")
      return;
    }

    const res = await dataService.assign_project_workflow(postData);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      setTimeout(() => {
        navigate(-1)
      }, 3000);
    }
    else {
      toast.error(res.data.message)
    }
    setFormData({})
  }

  const handleStatusMappingChange = (evt, from_status) => {
    const finalStsMapping = finalStatusMapping;
    setFinalStatusMapping(
      finalStsMapping.map((e) => {
        if (e?.from_status === from_status) {
          return (
            {
              ...e,
              "workflow": formData.workflow?.value,
              "workflow_status": e.from_status,
              "related_status": evt.map(e => e.value),
              "created_by": userId
            }
          )
        }
        return {
          ...e,
          "workflow": formData.workflow?.value,
          "workflow_status": e.workflow_status ? e.workflow_status : e.from_status,
          "related_status": e.related_status,
          "created_by": userId
        };
      })
    )
  }


  useEffect(() => {
    dispatch(statusList(statusParam))
    dispatch(workFlowList({"order_by":{"column":"-id"}, "no_limit":"True","columns":["id","code","description","name","status"]}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, params]);
  return (
    <BasicLayout>
      <div className="top-headings">
        <h3 > {workFlowDetail?.is_editing ? " Edit Workflow Mapping " : workFlowDetail?.is_viewing ? "Workflow Mapping" : "Add Workflow Mapping"} </h3>
        <div>
          {
            <CustomButton title={" "}
              className="btn-add-new "
              onClick={() => { onCloseModalAssignment(); navigate(-1) }}
              tooltip="Back"
              preBackIcon
            />
          }
          {workFlowDetail?.is_viewing && <RefreshButton api={statusList} filter={statusParam} style={{ marginTop: 0 }} />}
        </div>
      </div>

      <ToastContainer />
      {(sts_status === STATUSES.LOADING || workflow_Status === STATUSES.LOADING) ? <LoaderForm />
        :
        <>
          {
            <div>
              <div>
                {
                  (!workFlowDetail?.is_viewing) ?
                    <div>
                      <form className="workflow-form" style={{ marginTop: "0", paddingBottom: "50px" }} onSubmit={handleSubmit}>
                        <div className="formInput" >
                          <label className='label-heading'>
                            {"Workflow*"}
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={"Select"}
                            isSearchable={true}
                            name={"workflow"}
                            defaultValue={workFlowDetail?.id ? { id: workFlowDetail?.id, label: workFlowDetail?.name, value: workFlowData?.id } : ""}
                            options={workflows && workflows?.data?.rows?.map(e => ({ id: e.id, label: e.name, value: e.id, name: "workflow" }))}
                            onChange={(evt) => { handleChange({ target: { name: "workflow", value: evt } }) }}
                          />
                          {errStatus && formData.workflow === '' && <p style={{color: 'red'}}>{errorLogs.workflowErr}</p>}
                        </div>


                        <div className="formInput" >
                          <label className='label-heading'>
                            {"Workflow Status"}
                          </label>

                          {/* <Multiselect
                            isObject={true}
                            onRemove={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                            onSelect={(evt) => { handleChange({ target: { name: "workflow_status", value: evt } }) }}
                            displayValue={"label"}
                            // required
                            placeholder={"Select Workflow-Status"}
                            selectedItemTextColor="green"
                            itemTextColor="#d1d0cf"
                            selectedValues={workFlowDetail.is_editing ? workflow_StatusMapping.map(e => ({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "workflow_status" })) : ""}
                            options={status_List && status_List.map(e => {
                              let arr = []
                              if (!e.is_jira_project) {
                                return { id: e.id, label:`${e.short_code ? e.short_code : e.name}`, value: e.id, status_category:e.workflow_status_category, name: "workflow_status" }
                              }
                              return arr
                            })}
                            style={ {chips: { background: "red" }, searchBox: { border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }}
                          /> */}

                          <Stack spacing={3}>
                            <Autocomplete
                              multiple
                              onChange={(evt, value) => {
                                handleChange({ target: { name: "workflow_status", value: value } })
                              }}
                              id="tags-filled"
                              fullWidth
                              options={status_List && status_List.map(e => {
                                let arr = []
                                if (!e.is_jira_project) {
                                  return { id: e?.id, label:e.name,shortCode :`${e.short_code ? e.short_code : e.name}`, value: e?.id, status_category: e.workflow_status_category, name: "workflow_status" }
                                }
                                return arr
                              })}
                              freeSolo
                              renderTags={(value, getTagProps) =>

                                value.map((option, index) => (
                                  <>
                                    <Tooltip title={option.label} placement='bottom'>
                                      <Chip
                                        variant="outlined"
                                        label={option.shortCode}
                                        {...getTagProps({ index })}
                                        sx={{ backgroundColor: `${option.status_category?.background_color_code}`, color: `${option.status_category?.foreground_color_code}` }}
                                      />
                                    </Tooltip>
                                  </>
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Favorites"
                                />
                              )}
                            />
                          </Stack>
                          {errStatus && formData.workflow_status === '' && <p style={{color: 'red'}}>{errorLogs.workflowStatusErr}</p>}
                        </div>

                        <div className="formInput" >
                          <label className='label-heading'>
                            {"Default Workflow Status*"}
                          </label>
                          <Select
                            className="basic-single"
                            placeholder="Select"
                            isSearchable={true}
                            name={"default_status"}
                            defaultValue={workFlowDetail?.is_editing ? isInitialStatus : ""}
                            options={relatedStatusOption?.length ? relatedStatusOption : workflow_StatusMapping.map(e => ({ id: e?.workflow_status?.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "workflow_status" }))}
                            onChange={(evt) => { handleChange({ target: { name: "default_status", value: evt } }) }}
                          />
                          {/* {errStatus && formData.default_status} */}
                          {errStatus && formData.default_status === '' && <p style={{color: 'red'}}>{errorLogs.defaultWorkflowStatusErr}</p>}
                        </div>

                        <div className="formInput" >
                          <label className='label-heading'>
                            {"Status*"}
                          </label>
                          {/* <Select
                            className="basic-single"
                            placeholder="Select"
                            isSearchable={true}
                            required
                            name={"status"}
                            defaultValue={
                              (workFlowDetail?.is_editing && (workFlowDetail?.status === true || workFlowDetail?.status === false)) ?
                                { label: workFlowDetail?.status ? "Active" : "Inactive", value: workFlowDetail?.status } : ""}
                            options={
                              [
                                { id: 1, name: "Active", label: "Active", value: true },
                                { id: 2, name: "Inactive", label: "Inactive", value: false }
                              ]
                            }
                            onChange={(evt) => { handleChange({ target: { name: "status", value: evt } }) }}
                          /> */}

                          <div>
                            <p>InActive</p>
                            <Switch name='status' checked={workFlowDetail?.status} onChange={e => handleChange({target: {name: 'status', value: e}})} />
                            <p>Active</p>
                          </div>

                        </div>

                        <>
                          {
                            status_RelatedStatusMapping && status_RelatedStatusMapping.length ?

                              <>
                                <div className="formInput" >
                                  <label className='label-heading'>
                                    {"From Status"}
                                  </label>
                                </div>
                                <div className="formInput" >
                                  <label className='label-heading'>
                                    {"Related Status"}
                                  </label>
                                </div>
                                {
                                  status_RelatedStatusMapping.map((element, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <div className="formInput" style={{ marginBottom: "20px", display: "flex" }}>
                                          {/* <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            // isSearchable={true}
                                            isDisabled
                                            required
                                            name={"status"}
                                            defaultValue={{ label: element.from_status.label }}
                                            options={
                                              [
                                                { label: element.from_status.label },
                                              ]
                                            }
                                          /> */}
                                          <div style={{ background: `${element?.from_status?.status_category?.background_color_code}`, height: "40px", width: "70%", color: `${element?.from_status?.status_category?.foreground_color_code}`, padding: "10px" }} >
                                            <p>{element.from_status?.shortCode? element.from_status.shortCode :element.from_status.label}</p>
                                          </div><div style={{ width: "30%", color: `${element?.from_status?.status_category?.foreground_color_code}`, margin: "10px" }} > <p>{element?.from_status?.status_category?.status_category_name}</p> </div>
                                        </div>
                                        <div className="formInput" style={{ marginBottom: "20px" }}>
                                          {/* <Multiselect
                                            isObject={true}
                                            onRemove={(evt) => { handleStatusMappingChange(evt, element.from_status.value) }}
                                            onSelect={(evt) => { handleStatusMappingChange(evt, element.from_status.value); }}
                                            displayValue={"label"}
                                            placeholder={"Select Related-Status"}
                                            options={element?.relatedStatusOptions}
                                            style={{ chips: { background: `${element?.from_status?.status_category?.foreground_color_code}` }, searchBox: { border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }}
                                          /> */}

                                          <Stack spacing={3}>
                                            <Autocomplete
                                              multiple
                                              onChange={(evt, value) => {
                                                handleStatusMappingChange(value,element.from_status.value)
                                              }}
                                              id="tags-filled"
                                              fullWidth
                                              options={element?.relatedStatusOptions}
                                              freeSolo
                                              renderTags={(value, getTagProps) =>

                                                value.map((option, index) => (
                                                  <>
                                                    <Tooltip title={option?.label} placement='right-end'>
                                                      <Chip
                                                        variant="outlined"
                                                        label={option?.shortCode}
                                                        {...getTagProps({ index })}
                                                        sx={{ backgroundColor: `${option.status_category?.background_color_code}`, color: `${option.status_category?.foreground_color_code}` }}
                                                      />
                                                    </Tooltip>
                                                  </>
                                                ))
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  size='small'
                                                  fullWidth
                                                  variant="outlined"
                                                  placeholder="Favorites"
                                                />
                                              )}
                                            />
                                          </Stack>

                                        </div>
                                      </React.Fragment>

                                    )
                                  })
                                }
                              </>
                              : ""
                          }
                        </>


                        <CustomButton
                          loading={false}
                          type={"submit"}
                        />
                      </form>
                    </div>
                    : ""
                }
              </div>


              {
                workFlowDetail?.is_viewing ?
                  <DynamicTable
                    moduleName="holidays"
                    tableColumns={columns}
                    tableRows={workflow_StatusMapping || []}
                    currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                    setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                    setFieldName={setFieldName} setDefaultValue={setDefaultValue}
                    paginationMode={"server"}
                    rowCount={workflow_Count}
                    loading={workflow_Status === STATUSES.LOADING}
                    columnVisibilityModel={{
                      id: false,
                      action: false,
                    }}
                    // additionalAction={[{ id: 1, key: "edit", title: "Edit" }]}
                    // onAdditionalAction={onAdditionalAction}

                    onViewDetail={onView}
                    onEdit={handleEdit}
                  // deleteRow={deleteHoliday}
                  />
                  : ""
              }


            </div>

          }
        </>
      }


    </BasicLayout>
  )
}
export default Workflows



//   <>
//     {
//       <DynamicTable
//         moduleName="holidays"
//         tableColumns={columns}
//         tableRows={wfWorkflows || []}
//         currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
//         setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
//         setFieldName={setFieldName} setDefaultValue={setDefaultValue}
//         paginationMode={"server"}
//         rowCount={sts_count}
//         loading={sts_status === STATUSES.LOADING}
//         columnVisibilityModel={{
//           id: false,
//         }}
//         // additionalAction={[{ id: 1, key: "edit", title: "Edit" }]}
//         // onAdditionalAction={onAdditionalAction}

//         onViewDetail={onView}
//         onEdit={handleEdit}
//       // deleteRow={deleteHoliday}
//       />
//     }

//     <RegularizeModal
//       modalDetail={modalDetail}
//       open={open}
//       onOpen={onOpenModal}
//       onClose={onCloseModal}
//       // onCancel={onCancel}
//       // onSubmit={onSubmit}
//       hideBackdrop
//       fullWidth
//       maxWidth
//       updating={updating}
//       editData={editFormData}
//       editable={editable}
//       formLoading={formLoading}
//       setFormLoading={setFormLoading}
//     // additionalActions={modalDetail.additionalActions}
//     // onAdditionalAction={() => {return;}}
//     />


//   </>