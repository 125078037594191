export const STATUSES = Object.freeze({
    IDLE:'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'error'
})

export const STATUS= {
    true:"ACTIVE", "ACTIVE":true,
    false:"INACTIVE", "INACTIVE":false,
    0:"", 1:"", 2:"", 3:"",
    4:"PENDING",5:"APPROVED",6:"CANCELLED", 7:"REJECTED",
    "PENDING":4, "APPROVED":5, "CANCELLED":6, "REJECTED":7,

   COMPOFF: {
    true:"ACTIVE", "ACTIVE":true,
    false:"INACTIVE", "INACTIVE":false,
    0:"", 1:"", 2:"", 3:"",
    4:"PENDING",5:"APPROVED",6:"CANCELLED", 7:"REJECTED",
    "PENDING":4, "APPROVED":5, "CANCELLED":6, "REJECTED":7
   }
}
