import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Skeleton, Stack, Switch, Typography } from "@mui/material";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContractTypeCreate, ContractTypeEdit, ContractTypeUpdate, getContractTypeList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Close, Edit } from "@mui/icons-material";
import { InputFields } from "../../components/common-function/InputFields";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DataGridPro, getGridSingleSelectOperators, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import "./master.scss"
import Filter from "../../components/Filter";
import PaperComponent from '../../components/common-function/PaperComponent'

const initialFormData = Object.freeze({
    name: "",
    status: true,
});

const formErrData = Object.freeze({
    nameErr: "Contract Name is required",
    statusErr: "Status is required"
});

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['name', 'status'];

const ContractType = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, contracttype_List, contracttype_Create, contracttype_Edit, contracttype_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.CONTRACT_TYPE_LIST_COLUMNS}]}`)
    const [limit, _] = useState(20);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    // grid states and ref starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});
    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value),
    );
    const [defaultValue, setDefaultValue] = useState({})

    // grid states and ref ends

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.name === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.name !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId }

            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item => item.id === ID);
                let index = copyRows.findIndex(item => item.id === ID);
                copyRows.splice(index, 1, { ...skeletonBase, id: ID });
                setGridRows(copyRows);
                dispatch(ContractTypeUpdate({ ID, addvalues }))

            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(ContractTypeCreate(addvalues))
            }

            setErrStatus(false)
            setReload(true)
            handleClose()
        }
    };

    const handleClickEdit = (id) => {
        dispatch(ContractTypeEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        clearGridListing.current = true;
        editID.current = { ...data };
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(ContractTypeUpdate({ ID, addvalues }))

    };

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);

    }

    // we make common function in the future.
    function StatusInputValue(props) {
        const { item, applyValue, focusElementRef } = props;
        const ratingRef = React.useRef(null);
        React.useImperativeHandle(focusElementRef, () => ({
            focus: () => {
                ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                    .focus();
            },
        }));

        const handleFilterChange = (event) => {
            applyValue({ ...item, value: event.target.value });
        };

        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Value
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                        onChange={handleFilterChange}
                    >
                        <option value={'True'}>True</option>
                        <option value={'False'}>False</option>
                    </NativeSelect>
                </FormControl>
            </Box>
        );
    }

    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];



    const actionColumn = [
        {
            field: "name",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Contract Type</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{ color: "#BCC2CE" }} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (

                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280} /> :

                        <div>
                            {params.row.name}
                        </div>
                );
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
            </Stack>,
            type: 'singleSelect',
            // filterOperators: statusOnlyOperators,
            valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
            width: 160,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160} /> :

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="contract-type"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)} />
                            </div>
                        </div>
                );
            },
        },
    ];

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={[{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "status") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                && CustomFilterInputValue

                        }))
                };
            }
            return col;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [actionColumn]
    );


    const onFilterChange = (props) => {
        if (props?.items?.length > actionColumn?.length)
            return;
        if (props?.items?.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CONTRACT_TYPE_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            setDefaultValue("")
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends
                setFilterModel(props)
                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.CONTRACT_TYPE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                startsWith: 'istartswith',
                endsWith: 'endswith',
                is: 'eq',
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }


    // const onFilterChange = (props) => {
    //     if (props.items.length > actionColumn.length)
    //         return;

    //     if (props.items.length === 0) {
    //         setFilterModel(props)
    //         setGridRows([]);
    //         let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.CONTRACT_TYPE_LIST_COLUMNS}]}`);
    //         setFilterQuery(str);
    //         return;
    //     }

    //     if (filterModal?.items?.length < props?.items?.length) {
    //         if (props.items.length > 1) {
    //             let selectedCols = filterModal.items.map(item => item.columnField);
    //             let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
    //             let newProps = { ...props };
    //             newProps.items[newProps.items.length - 1].columnField = remainCols[0];
    //             let index = props.items.findIndex(item => item.columnField === 'status')
    //             if (newProps.items.some(item => item.columnField === 'status')) {
    //                 if (!newProps.items[index].value) {
    //                     newProps.items[index].value = undefined;
    //                     newProps.items[index].operatorValue = 'is';
    //                 }
    //             }
    //             setFilterModel(props)
    //         }
    //         else
    //             setFilterModel(props)
    //         return;
    //     } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

    //         if (!(filterModal?.items?.length > props?.items?.length)) {

    //             // if same col is selected then do not let it select - starts
    //             let selectedCols = props.items.map(item => item.columnField);
    //             let prevLen = selectedCols.length;

    //             if ((new Set(selectedCols)).size != prevLen) {
    //                 toast.warn("Please select distinct column fields!")
    //                 return;
    //             }
    //             // if same col is selected then do not let it select - ends

    //             setFilterModel(props)

    //             let filterModalCols = [];
    //             let propsCols = [];
    //             filterModal.items.map(item => filterModalCols.push(item.columnField));
    //             props.items.map(item => propsCols.push(item.columnField));

    //             for (let i = 0; i < filterModalCols.length; i++) {
    //                 if (filterModalCols[i] != propsCols[i])
    //                     return;
    //             }

    //         }

    //         setFilterModel(props)

    //         let filterItems = props.items;
    //         let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.CONTRACT_TYPE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
    //         let operators = {
    //             contains: 'icontains',
    //             equals: 'ieq',
    //             is: 'eq',
    //             startsWith: 'istartsWith',
    //             endsWith: 'iendsWith'
    //         }

    //         if (filterItems?.length) {
    //             let valContainers = filterItems.filter(item => item?.value)
    //             if (valContainers?.length) {
    //                 let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})

    //                 // if no changes on filter open starts
    //                 props.items.map((elem, index) => {

    //                     if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
    //                         isAnyChange.current[index] = true;
    //                     else
    //                         isAnyChange.current[index] = false;
    //                 })
    //                 // no change (! reverse by not operator)
    //                 if (!(Object.values(isAnyChange.current).every(item => item === false))) {
    //                     setGridRows([]);
    //                 }
    //                 // if no changes on filter open ends

    //                 setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
    //             }
    //         }
    //         return;
    //     }
    // }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }

        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            case 'status':
                handleArchive(row);
                break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    if (reload === true && status === `${STATUSES.SUCCESS}_contracttype_Update` && contracttype_Update && contracttype_Update.message) {
        if (contracttype_Update.status === 200) {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
            setFilterQuery(str)
            toast.success(contracttype_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID?.current?.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(contracttype_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_contracttype_Create` && contracttype_Create && contracttype_Create.message) {
        if (contracttype_Create.status === 200) {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current, order_by: { column: "-id" } }));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(contracttype_Create.message)
        } else {
            toast.error(contracttype_Create.message)
        }
        setReload(false)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && contracttype_Edit && contracttype_Edit.data) {
        let editData = contracttype_Edit.data
        setFormValues({
            name: editData.name,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_contracttype_list` && contracttype_List && contracttype_List.message) {
        if (contracttype_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...contracttype_List.data.rows].sort(item => item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(contracttype_List.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...contracttype_List.data.rows].sort(item => item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(contracttype_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(contracttype_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getContractTypeList(filterQuery))
    }, [filterQuery])



    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>Contract type List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getContractTypeList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                            </Stack>
                        </div>
                    </div>

                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={skeletonRows.length === 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={columns}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: () => (<MoreHorizIcon sx={{ color: "#171c26ba" }} />),
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />

                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Contract Type</DialogTitle>

                    <DialogContent >
                        <form>
                            <div className="add-new-user-form">
                                <div style={{ width: '100%' }}>
                                    <InputFields
                                        label={"Contract Type"}
                                        labelStyle={{ fontWeight: '500' }}
                                        name="name"
                                        defaultValue={formValues.name}
                                        type="text"
                                        placeholder="Contract Type"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.nameErr}
                                    />
                                </div>

                                {/* <div style={{display:'flex', alignItems:'center', margin:'10px 0'}}>
                                    <label style={{marginRight:'20px', fontWeight:'500'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div> */}
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className={`cellWithStatus false`}>
                                        Inactive
                                    </Typography>
                                    <Switch
                                        checked={formValues.status}
                                        inputProps={{ 'aria-label': 'ant design' }}
                                        onChange={handleChange}
                                    />
                                    <Typography className={`cellWithStatus true`}>
                                        Active
                                    </Typography>
                                </Stack>
                            </div>
                            <button className="submit-modal" style={{ float: 'right' }} onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Contract-Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

                </Menu>
            </div>
        </div >
    )
}

export default ContractType;
