import "./attendacecalender.scss"
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { useState, useEffect } from "react";
import { Button, IconButton, Skeleton, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import Slider from '@mui/material/Slider';
import { attandanceList, defaultAttendenceColumn } from "../../redux/features/calendarSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { useNavigate } from "react-router-dom";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'
import { timeConvert } from "../../components/utlis/CalculationTime"
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { ArrowBack } from "@mui/icons-material";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { ToastContainer, toast } from "react-toastify";


const default_attendence_columns = LocalStorageDataService.get_default_attendence_column()
const SubAttendancePresence = ({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) => {

    // let user = LocalStorageDataService.userDetail();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dateState, setDateState] = useState(new Date());
    const { attendanceData, status, error, defaultAttendenceColumns } = useSelector(state => state.calender)
    const [attendance, setAttendance] = useState([])
    // const [loader, setLoader] = useState(true)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    // const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-id" },"skip":0,"limit":25, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [count, setCount] = useState(0)
    // const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    //columns visibility
    // const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_attendence_columns && default_attendence_columns.attendence_column ? default_attendence_columns.attendence_column : {
        current_status: false,
        id: false,
    });
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    const setKeyForList = (obj) => {
        let data;
        if (default_attendence_columns) {
            data = { 'attendence_column': obj, 'reported_attendence_column': default_attendence_columns.reported_attendence_column, 'attendence_presence_column': default_attendence_columns.attendence_presence_column, 'phone_column': default_attendence_columns.phone_column }
        } else {
            data = { 'attendence_column': obj }
        }
        if (defaultAttendenceColumns.reported_attendence_column) {
            data['reported_attendence_column'] = defaultAttendenceColumns.reported_attendence_column
        }
        if (defaultAttendenceColumns.attendence_presence_column) {
            data['attendence_presence_column'] = defaultAttendenceColumns.attendence_presence_column
        }
        if (defaultAttendenceColumns.phone_column) {
            data['phone_column'] = defaultAttendenceColumns.phone_column
        }
        dispatch(defaultAttendenceColumn(data))
    }

    if (reload === true && status === STATUSES.SUCCESS && attendanceData.message) {
        if (attendanceData.status === 200) {
            if (clearGridListing.current) {
                const attenance_data = attendanceData.data && attendanceData.data.rows;
                let binding_attendance = []
                attenance_data.map(item => {
                    return binding_attendance.push(
                        {
                            date: item?.punchin_date,
                            punchin_time: item?.punchin_time,
                            punch_out_time: item?.punchin_date === moment().format('YYYY-MM-DD') && !item?.punch_out_time ? '-' : item?.punch_out_time ? item?.punch_out_time : 'missing',
                            current_status: item?.current_status,
                            //title: item?.resource.display_name,
                            id: item?.id,
                            day_duration: item?.durations ? item?.durations : 0,
                            //user_id: item?.resource_id,
                            work_duration_minutes: item?.work_duration_minutes ? item?.work_duration_minutes : 0,
                            break_duration_minutes: item?.break_duration_minutes ? item?.break_duration_minutes : 0,
                            color: item?.punchin_time && !item?.punch_out_time ? '#fbc02d' : '#86c06a',
                        }
                    )
                })
                setGridRows(binding_attendance);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(attendanceData.data.count);
                setCount(attendanceData.data && attendanceData.data.count);
            }
            else {
                const attenance_data = attendanceData.data && attendanceData.data.rows;
                let binding_attendance = []
                attenance_data.map(item => {
                    return binding_attendance.push(
                        {
                            date: item?.punchin_date,
                            punchin_time: item?.punchin_time,
                            punch_out_time: item?.punchin_date === moment().format('YYYY-MM-DD') && !item?.punch_out_time ? '-' : item?.punch_out_time ? item?.punch_out_time : 'missing',
                            current_status: item?.current_status,
                            //title: item?.resource.display_name,
                            id: item?.id,
                            day_duration: item?.durations ? item?.durations : 0,
                            //user_id: item?.resource_id,
                            work_duration_minutes: item?.work_duration_minutes ? item?.work_duration_minutes : 0,
                            break_duration_minutes: item?.break_duration_minutes ? item?.break_duration_minutes : 0,
                            color: item?.punchin_time && !item?.punch_out_time ? '#fbc02d' : '#86c06a',
                        }
                    )
                })
                setTimeout(() => {
                    setGridRows((pre) => [...pre, ...binding_attendance]);
                    SET_MAX_ROW_LENGTH(attendanceData.data.count);
                    setSkeletonRows([])
                }, 1000)
            }
        } else {
            toast.error(attendanceData.message)
        }
        // setLoader(false)
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    // useEffect(() => {
    //     dispatch(attandanceList(filterQuery))
    //     if (status === STATUSES.FAILURE && error) {
    //         errorHandling(error, navigate)
    //         setLoader(false)
    //     }
    //     setInterval(() => setDateState(new Date()), 60 * 1000);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // if (loader === true && status === STATUSES.SUCCESS && attendanceData && attendanceData.data && attendance.length === 0) {
    //     setLoader(false)
    //     const attenance_data = attendanceData.data && attendanceData.data.rows;
    //     setCount(attendanceData.data && attendanceData.data.count);
    //     let binding_attendance = []
    //     attenance_data.map(item => {
    //         return binding_attendance.push(
    //             {
    //                 date: item?.punchin_date,
    //                 punchin_time: item?.punchin_time,
    //                 punch_out_time: item?.punchin_date === moment().format('YYYY-MM-DD') && !item?.punch_out_time ? '-' : item?.punch_out_time ? item?.punch_out_time : 'missing',
    //                 current_status: item?.current_status,
    //                 //title: item?.resource.display_name,
    //                 id: item?.id,
    //                 day_duration: item?.durations ? item?.durations : 0,
    //                 //user_id: item?.resource_id,
    //                 work_duration_minutes: item?.work_duration_minutes ? item?.work_duration_minutes : 0,
    //                 break_duration_minutes: item?.break_duration_minutes ? item?.break_duration_minutes : 0,
    //                 color: item?.punchin_time && !item?.punch_out_time ? '#fbc02d' : '#86c06a',
    //             }
    //         )
    //     })
    //     setAttendance(binding_attendance)
    //     setGridRows(binding_attendance)
    // }

    const calculateTimeHour = (data) => {
        let cal = '0: 00';
        let work_duration_minutes = data.work_duration_minutes;
        let break_duration_minutes = data.break_duration_minutes;
        if (work_duration_minutes > 0) {
            cal = timeConvert(work_duration_minutes + break_duration_minutes)
            //cal = (work_duration_minutes + break_duration_minutes) / 60
        }
        return cal
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains'].includes(value),
    );

    const presenceColumn = [
        {
            field: "id",
            headerName: "ID",
            width: 70,
            filterable: false,
        },
        {
            field: "date",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Date</Typography>
                </Box>
                {/* <Box>
                    <IconButton onClick={() => !reload && handleSort("date")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box> */}
            </Stack>,
            width: 450, minWidth: 200, maxWidth: 500, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.date === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={450} /> :
                        <div className={`cellWithStatus`}>
                            <Typography variant="p">
                                {moment(params.row.date).format('ll')}
                            </Typography>
                        </div>
                );
            },
        },
        {
            field: "hour",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Hours</Typography>
                </Box>
                {/* <Box>
                    <IconButton onClick={() => !reload && handleSort("hour")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box> */}
            </Stack>,
            filterable: false,
            width: 450, minWidth: 200, maxWidth: 500, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.hour === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={450} /> :
                        <div className={`cellWithStatus`}>
                            <Typography variant="p">
                                {calculateTimeHour(params.row)}
                            </Typography>
                        </div>
                );
            },
        },
        {
            field: "punchin_time",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch In Time</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("punchin_time")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            width: 450, minWidth: 200, maxWidth: 500, sortable: false,filterOperators,
            renderCell: (params) => {
                return (
                    params.row.punchin_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={450} /> :
                        <div className={`cellWithStatus`}>
                            <Typography variant="p">
                                {params.row.punchin_time}
                            </Typography>
                        </div>
                );
            },
        },
        {
            field: "punch_out_time",filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Punch Out Time</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("punch_out_time")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            width: 450, minWidth: 200, maxWidth: 500, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.punch_out_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={450} /> :
                        <div className={`cellWithStatus`}>
                            <Typography variant="p">
                                {params.row.punch_out_time}
                            </Typography>
                        </div>
                );
            },
        },
    ];


    // filter
    // const onFilterChange = (filterModel, r) => {

    //     if (filterModel.items.length) {
    //         setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
    //     }

    //     if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
    //         setLoader(true);
    //         setTimeout(() => {
    //             setLoader(false)
    //             setCurrentPage(0)
    //             // setPageSize(10)
    //             setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`)
    //             dispatch(attandanceList(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`))
    //         }, 0);
    //     }

    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "equals" ? 'ieq' :
    //                 filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                     filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
    //                         filterModel.items[0].operatorValue
    //         const filterData = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0,"limit":${limit}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(attandanceList(filterData))
    //     }
    //     // if (filterModel.items && filterModel.items.length === 0) {
    //     //     setCurrentPage(0)
    //     //     // setPageSize(10)
    //     //     setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`)
    //     //     dispatch(attandanceList(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`))
    //     // }
    // }

    return (
        <div className="listing">
            <div className="listingContainer">
                <Box m={1} display={'flex'} justifyContent={"space-between"}>
                    <Paper style={{ width: '100%', textAlign: 'center', marginRight: 10, marginBottom: 5, }} elevation={2} >
                        <Typography variant="h3" style={{ margin: 20, fontSize: '20px' }}>Today Date & Time</Typography>
                        <Button variant="outlined" >
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: true,
                            })}
                        </Button>
                        <Typography variant="p" style={{ fontSize: 14, margin: 10 }} >
                            {dateState.toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            })}
                        </Typography >
                    </Paper>
                    <Paper style={{ width: '100%', textAlign: 'center', marginBottom: 5, }} elevation={2} >
                        <Typography variant="h3" style={{ margin: 20, fontSize: '20px' }}>Timing</Typography>
                        <div style={{ textAlign: 'center', marginRight: 30, marginLeft: 30, padding: 10 }}>
                            <Slider
                                track="inverted"
                                step={null}
                                aria-labelledby="track-inverted-range-slider"
                                value={[40, 60]}
                                marks={[
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 40,
                                        label: '',
                                    },
                                    {
                                        value: 50,
                                        label: 'lunch-break ',
                                    },
                                    {
                                        value: 60,
                                        label: '',
                                    },
                                    {
                                        value: 100,
                                        label: '9h',
                                    },]}

                            />
                        </div>
                    </Paper>
                    {/* <Paper style={{ textAlign: 'center' }} elevation={2} >
                            <h3 style={{ margin: 20 }}>Clock Out Timing</h3>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right" ><Button variant="outlined" >{attendance.map(x => x.date === moment().format('YYYY-MM-DD') && calculateTimeHour(x))}</Button></TableCell>
                                        <TableCell><Button variant="outlined" >out</Button></TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper> */}
                </Box>
                <div className="datatable">
                    {/* <div className="datatableTitle">
                        <h3>My Presence</h3>
                        <div>
                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn btn-add-new" style={{ marginRight: "10px" }}><ArrowBack /></button>
                            </Tooltip>
                            <RefreshButton api={attandanceList} filter={filterQuery} style={{ marginTop: 5 }} />
                        </div>
                    </div> */}
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">My Presence</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Party List">
                                <Button size="small" variant="outlined" onClick={() => navigate(-1)} ><ArrowBack /></Button>
                            </Tooltip>
                            <RefreshButton api={attandanceList} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </Stack>
                    </Stack>
                    <ToastContainer />
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || loader === true) && <LoaderForm />}
                    {
                        !loader &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={defaultAttendenceColumns && defaultAttendenceColumns.attendence_column ? defaultAttendenceColumns.attendence_column : columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                            }
                            rows={attendance}
                            columns={presenceColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            components={{
                                // Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            hideFooterPagination={true}
                        /> */}
                    <Stack direction={'row'} mt={2}>
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            rows={gridRows.concat(skeletonRows)}
                            columns={presenceColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                // Pagination: CustomPagination,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                            }}
                            loading={skeletonRows.length === 0 && !!reload}
                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }}
                            // componentsProps={{
                            //     row: {
                            //         onContextMenu: handleContextMenu,
                            //     }
                            // }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                boxShadow: '1px 13px 31px -15pxhour gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                        />
                    </Stack>
                    {/* } */}
                </div>
            </div>
        </div>
    )
}

// export default AttendancePresence
const AttendancePresence = DataGridProLazyLoadHOC(SubAttendancePresence, {
    filterColumns: DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()),
    getDispatcherAsyncThunk: attandanceList,
    gridColumns: [
        'date',
        'hour',
        'punchin_time',
        'punch_out_time',
        // 'punch_out_date',
        // 'break_duration_minutes',
        // 'work_duration_minutes',
        // 'extra_working_minutes',
        // 'resource__name',
        // 'resource__emp_code',
        // 'resource_id'
    ]
});
export default AttendancePresence