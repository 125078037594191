import React, { useState } from 'react'
import { CopyBlock, Snippet } from 'react-code-blocks'

function ScreeningQuestion({ submitQuestionData, questionData, }) {

    let candidateExamData = questionData && questionData.data

    const [myAnswer, setMyAnswer] = useState({});
    const checkAnswer = (quest, variant) => {
        setMyAnswer({ ...myAnswer, [quest.id]: variant.id });
    };

    let questionArray = []
    if (candidateExamData && candidateExamData.technology_question_data) {
        // eslint-disable-next-line
        candidateExamData.technology_question_data.map(e => {
            // eslint-disable-next-line
            e.question_data && e.question_data.map(itm => {
                itm['technology_key'] = e.technology_key
                itm['technology_name'] = e.technology_name
                questionArray.push(itm)
            })
        })
    }

    const submitExamTest = () => {
        let array = [];
        Object.keys(myAnswer).forEach(function (key, index) {
            let filterData = questionArray.filter(x => Number(x.id) === Number(key));
            if (filterData.length > 0 && filterData[0].question_type_key === "mcq") {
                let data = {
                    [key]: myAnswer[key]
                };
                array.push(data);
            }
        })


        let total_marks = 0;
        questionArray.map((x) => total_marks += x.marks);

        let answer = {
            "candidate": candidateExamData.candidate,
            "candidate_exam_schedule": candidateExamData.id,
            "exam_start_datetime": new Date(),
            "exam_end_datetime": new Date(),
            "total_marks": total_marks,
            "total_answered_questions": Object.keys(myAnswer).length,
            // "total_unanswered_question": totalQuestions - Object.keys(myAnswer).length,
            "total_unanswered_question": questionArray?.length - Object.keys(myAnswer).length,
            'all_question_data': questionArray,
            "candidate_question_answer_data": {
                "mcq": array,
            },
            "status": true,
        }
        submitQuestionData(answer)
    }

    return (
        <div style={{ overflow: "scroll", height: "600px" }} className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
            <div className="submit-btn-sticky-screening">
                <button
                    className="btn-add-new"
                    onClick={() => submitExamTest()}
                >
                    Submit Test
                </button>
            </div><br /><br />
            {questionArray && questionArray?.map((item, i) => {
                return (
                    <div className="wrapper" key={i}>
                        <p className="question-wrapper">
                            <strong>Question  {i + 1}
                                : </strong>{" "}
                            <span style={{ lineBreak: 'anywhere', color: '#000' }}>
                                <CopyBlock
                                    language="java"
                                    text={item.title}
                                    codeBlock
                                    theme={Snippet}
                                    showLineNumbers={false}
                                />
                            </span>
                        </p>
                        <p><strong>Answer : </strong></p>
                        {(
                            item && item?.answer_option.map((variant, ansIndex) => (
                                <div key={ansIndex} className="m-2 h-14 border-2 border-black mx-auto">
                                    <p style={{ paddingLeft: "70px" }}>
                                        <input
                                            onClick={() =>
                                                checkAnswer(
                                                    questionArray[i],
                                                    variant
                                                )
                                            }
                                            type="radio"
                                            // name="site_name"
                                            name={`site_name_${i}`}
                                            className="radio"
                                            checked={
                                                myAnswer[
                                                questionArray[i] &&
                                                questionArray[i].id] === variant.id
                                            }
                                            style={{ height: "18px", width: "35px" }}
                                            value={"ss"}
                                        />
                                        {" " + variant.answer_option}

                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default ScreeningQuestion