import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './InputField';
import { toast } from 'react-toastify'
import dataService from '../../redux/services/data.service';

export default function DialogBox({ setOpenDialog, openDialog }) {

    const [credential, setCredential] = React.useState({ old_password: '', new_password: '', retype_new_password: '' });
    const [err, setErr] = React.useState(false)
    const [errMessage, setErrMessage] = React.useState('')

    const authInfo = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_ENV}_rtms`));
    const resourse_id = authInfo?.user?.data?.id;

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onChange = (e) => {
        setCredential(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const onSubmit = () => {
        let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*_])(?=.{8,16}$)");

        if (Object.values(credential).every(value => value != '')) {
            if (regex.test(credential.new_password)) {
                if (credential.new_password?.length >= 8 && credential.new_password?.length <= 16) {
                    if (credential.retype_new_password === credential.new_password) {

                        // this part of api implimentation
                        const data = { new_password: credential.new_password, old_password: credential.old_password, resourse_id: resourse_id }

                        const response = async (data, id) => {
                            const res = await dataService.change_password(data, id);
                            return res.data;
                        }
                        response(data, resourse_id).then(res => {
                            if (res.status === 200) {
                                toast.success(`${res.message}`)
                                handleClose();
                            }
                            if (res.status === 400) {
                                setErr(true)
                                setErrMessage(res.message)
                            }
                        }).catch(err => {
                            throw new Error(err)
                        })

                    } else {
                        setErr(true);
                        setErrMessage('password missmatch !')
                    }
                } else {
                    setErr(true);
                    setErrMessage('password should be 8 charecters and max 16 charecters !')
                }
            } else {
                //regex else
                const msg = ['Must be between 8 to 16 character', 'Must contain at least one character uppercase , one lowsercase & one specaial character, number']
                setErr(true);
                setErrMessage(msg)
            }

        } else {
            setErr('true')
            setErrMessage('All field required !')
        }
        // 
    }

    return (
            <div>
                <Dialog open={openDialog} onClose={handleClose}>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                        <InputField
                            label='Enter your old password'
                            onChange={onChange}
                            name={'old_password'}
                            err={(err && errMessage === 'Old password not match' && err) || (err && errMessage === 'All field required !' && err)}
                        />
                        <InputField
                            label='Enter your new password'
                            onChange={onChange}
                            name={'new_password'}
                            err={(err && errMessage === 'updated password cannot be same as new password' && err) || (err && errMessage === 'All field required !' && err) || (err && errMessage === 'password should be 8 charecters and max 16 charecters !' && err) || (err && Array.isArray(errMessage) && err)}
                        />
                        <InputField
                            label='Retype new password'
                            onChange={onChange}
                            name={'retype_new_password'}
                            err={(err && errMessage === 'password missmatch !' && err) || (err && errMessage === 'All field required !' && err)}
                        />
                        {err && errMessage && <div style={{ color: "red" }}>{
                            Array.isArray(errMessage) ? errMessage.map((value, index) => <p key={index}>{value}</p>) : <p>{errMessage}</p>
                        }</div>}
                        <Button sx={{ marginTop: '10px' }} variant='contained' onClick={onSubmit}>Change Password</Button>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
