import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@emotion/react'

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, handlePagination } = props;


    const handleBackButtonClick = () => {
        handlePagination(page - 1)
    };

    const handleNextButtonClick = () => {
        handlePagination(page + 1)
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            {/* <Tooltip title="first page"
                disableFocusListener
                disableTouchListener>
                <span>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                </span>
            </Tooltip> */}
            <span><b className='total-page-class'>Total:</b> {props.count}</span>

            <Tooltip title="previous page">
                <span>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="previous page"
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                </span>
            </Tooltip>
            <span>{props?.page + 1} / {Math.ceil(count / rowsPerPage)}</span>

            <Tooltip title="next page">
                <span>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                </span>
            </Tooltip>

            {/* <Tooltip title="last page">
                <span>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </span>
            </Tooltip> */}
        </Box >
    );
}
export default TablePaginationActions

