import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

////// Client resource apis///////

export const getAllClientResourceList = createAsyncThunk("add-client-resource/all-list", async (data) => {
    const res = await dataService.all_client_resource_list(data);
    return res.data;
});

export const getClientResourceList = createAsyncThunk("add-client-resource/list", async (data) => {
    const res = await dataService.client_resource_list(data);
    return res.data;
});

export const ClientResourceCreate = createAsyncThunk("add-client-resource/create", async (data) => {
    const res = await dataService.client_resource_create(data);
    return res.data;
});

export const ClientResourceEdit = createAsyncThunk("add-client-resource/edit", async (id) => {
    const res = await dataService.client_resource_edit(id);
    return res.data;
});

export const ClientResourceUpdate = createAsyncThunk("add-client-resource/update", async (data) => {
    const res = await dataService.client_resource_update(data.ID, data.addvalues);
    return res.data;
});

export const ClientResourceArchive = createAsyncThunk("add-client-resource/archive", async (id) => {
    const res = await dataService.client_resource_archive(id);
    return res.data;
});

export const ClientResourceList = createAsyncThunk("add-client-resource/Client", async (id) => {
    const res = await dataService.client_list_for_resource(id);
    return res.data;
});
export const ClientResourceSkills = createAsyncThunk("add-client-resource-skills/client-skills", async (id) => {
    const res = await dataService.client_resource_skill_list(id);
    return res.data;
});

export const tmsRoleClientList = createAsyncThunk("tmsrole/list", async () => {
    const res = await dataService.tms_role_client_list();
    return res.data;
});
export const ClientResourceStatus = createAsyncThunk("client-resource-status/status-update", async (data) => {
    const res = await dataService.client_resource_status(data.ID, data.addvalues);
    return res.data;
});

const clientResourceSlice = createSlice({
    name: "clientResourceSlice",
    initialState: {
        data: [],
        status: 'idle',
        error: '',
        clientresource_AllList: [],
        clientresource_Create: [],
        clientresource_List: [],
        clientresource_Edit: [],
        clientresource_Update: [],
        clientresource_Archive: [],
        clientlistresource_List: [],
        clientresourceskills_List: [],
        roleList: [],
        clientresources_Status: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
              //---------------------///client resource///--------------------//

            ///all client resource List
            .addCase(getAllClientResourceList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllClientResourceList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.clientresource_AllList = action.payload;
            })
            .addCase(getAllClientResourceList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            /// get filterd client resource list
            .addCase(getClientResourceList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientResourceList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.clientresource_List = action.payload;
                state.error = ""
            })
            .addCase(getClientResourceList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///Client Resource archive
            .addCase(ClientResourceArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ClientResourceArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientresource_Archive`;
                state.clientresource_Archive = action.payload;
                state.error = ""
            })
            .addCase(ClientResourceArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
                ///Client Resource Update
                .addCase(ClientResourceUpdate.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(ClientResourceUpdate.fulfilled, (state, action) => {
                    state.status = `${STATUSES.SUCCESS}_clientresource_Update`;
                    state.clientresource_Update = action.payload;
                    state.error = ""
                })
                .addCase(ClientResourceUpdate.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE
                    state.error = action.error
                })
                ///Client Resource edit
                .addCase(ClientResourceEdit.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(ClientResourceEdit.fulfilled, (state, action) => {
                    state.status = STATUSES.SUCCESS;
                    state.clientresource_Edit = action.payload;
                    state.error = ""
                })
                .addCase(ClientResourceEdit.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE
                    state.error = action.error
                })
                /// Client Resource create
                .addCase(ClientResourceCreate.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(ClientResourceCreate.fulfilled, (state, action) => {
                    state.status = `${STATUSES.SUCCESS}_clientresource_Create`;
                    state.clientresource_Create = action.payload;
                    state.error = ""
                })
                .addCase(ClientResourceCreate.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE
                    state.error = action.error
                })

                /// client resource list ////
                .addCase(ClientResourceList.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(ClientResourceList.fulfilled, (state, action) => {
                    state.status = `${STATUSES.SUCCESS}_clientlistresource_List`;
                    state.clientlistresource_List = action.payload;
                    state.error = ""
                })
                .addCase(ClientResourceList.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE
                    state.error = action.error
                })
                /// client resource skills list ////
                .addCase(ClientResourceSkills.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(ClientResourceSkills.fulfilled, (state, action) => {
                    state.status = `${STATUSES.SUCCESS}_clientresourceskills_List`;
                    state.clientresourceskills_List = action.payload;
                    state.error = ""
                })
                .addCase(ClientResourceSkills.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE
                    state.error = action.error
                })
                ///get TMS Role Client list

            .addCase(tmsRoleClientList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(tmsRoleClientList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleList`;
                state.roleList = action.payload;
                state.error = ""
            })
            .addCase(tmsRoleClientList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
               ///get  Client Resource status list

               .addCase(ClientResourceStatus.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(ClientResourceStatus.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientresources_Status`;
                state.clientresources_Status = action.payload;
                state.error = ""
            })
            .addCase(ClientResourceStatus.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



    },
});


export default clientResourceSlice.reducer;