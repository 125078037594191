import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { toast, ToastContainer } from "react-toastify";
import { Box, Checkbox, Dialog, DialogContent, IconButton, Menu, MenuItem, Skeleton, Stack, Switch, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoreSettingsCreate, CoreSettingsEdit, CoreSettingsUpdate, getCoreSettingsList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Close, Edit } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

const initialFormData = Object.freeze({
    setting_display_name: "",
    setting_key: "",
    value: "",
    status: true,
    is_json_value: false,
});

const formErrData = Object.freeze({
    coreSettingsErr: "setting name is required",
    statusErr: "Status is required",
    valueErr: "Value is required"
});

const skeletonBase = {setting_display_name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', setting_key:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',setting_value:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['setting_display_name', 'setting_key', 'key_value', 'status'];

const PaperComponent = (props) => {
    return (
        <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}

const CoreSettings = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, coresettings_List, coresettings_Create, coresettings_Edit, coresettings_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`)
    const [limit, _] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [jsonValueChecked, setJsonValueChecked] = useState("false")

    // grid states and ref starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});
    // grid states and ref ends

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {

        const { name, value, checked } = evt.target || evt;

        if (name === 'is_json_value' ) {
            setFormValues({ ...formValues, [name]: checked })
            setJsonValueChecked(true)
        } 
        else {
            setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
            setJsonValueChecked(false)
        }
    }

    const handleSettingKeyChange = (evt) => {
        const { value } = evt.target
        let newValue = value.toLowerCase().split(' ').join('-')
        setFormValues({ ...formValues, setting_display_name: value, setting_key: newValue })

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.setting_display_name === "" || formValues.status === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.setting_display_name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, key_value:formValues.setting_value, created_by: userId, assigned_by: userId }
            // setDisableButton(true)
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(CoreSettingsUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(CoreSettingsCreate(addvalues))
            }

            setErrStatus(false)
            setReload(true)
            handleClose()
        }
    }

    const handleClickEdit = (id) => {
        dispatch(CoreSettingsEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            setting_display_name: data.setting_display_name,
            setting_key: data.setting_key,
            is_json_value: data.is_json_value,
            value: data.key_value ? typeof(data.key_value) === "string" ? data.key_value : JSON.stringify(data.key_value) : data.setting_value,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        dispatch(CoreSettingsUpdate({ ID, addvalues }))
    }

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;        
    }

    const actionColumn = [
        {
            field: "setting_display_name",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Setting Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("setting_display_name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 320,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {

                return (

                    params.row.setting_display_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.setting_display_name}
                    </div>
                );
            },
        },
        {
            field: "setting_key",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Setting Key</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("setting_key")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 360,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (

                    params.row.setting_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360}/> :
                    <div>
                        {params.row.setting_key}
                    </div>
                );
            },
        },
        {
            field: "key_value",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Setting Value</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("setting_value")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    
                    params.row.setting_value === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :
                    
                    <div >
                        { params.row.setting_value ? 

                        typeof (params.row.setting_value) === "string" ? params.row.setting_value : JSON.stringify(params.row.setting_value)
                        : params.row.key_value
                        }
                    </div>    
                                    
                );
                
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Setting Status</Typography>
                </Box>
            </Stack>,
            width: 160,
            sortable:false,
            pinnable:false,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            renderCell: (params) => {
                return (

                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> 
                    
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="core-settings"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)} />
                        </div>
                    </div>
                );
            },
        },
    ];
    
    const onFilterChange = (props) =>
    {
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    if(!newProps.items[index].value)
                    {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"}, columns:DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS[0].replace("'","").split(",").map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };


    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

    if (reload === true && status === `${STATUSES.SUCCESS}_coresettings_Update` && coresettings_Update && coresettings_Update.message) {
        if (coresettings_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(coresettings_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(coresettings_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_coresettings_Create` && coresettings_Create && coresettings_Create.message) {
        if (coresettings_Create.status === 200) {            
            toast.success(coresettings_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(coresettings_Create.message)
        }
        setReload(false)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && coresettings_Edit && coresettings_Edit.data) {
        let editData = coresettings_Edit.data
        setFormValues({  
            setting_display_name: editData.setting_display_name,
            setting_key: editData.setting_key,
            value: editData.setting_value ?  typeof(editData.setting_value) === "string" ? editData.setting_value : JSON.stringify(editData.setting_value):editData.key_value  ,
            status: editData.status,
            id: editData?.id,
            is_json_value: editData.is_json_value,


        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && coresettings_List && coresettings_List.message) {
        if (coresettings_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...coresettings_List.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(coresettings_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...coresettings_List.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(coresettings_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(coresettings_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getCoreSettingsList(filterQuery))
    }, [filterQuery])
    
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Core Setting</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getCoreSettingsList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>

                     <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={ skeletonRows.length === 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                              },
                              [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                  ".actionButton": {
                                    display: 'block'
                                  }
                                }
                              }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: ()=>(<MoreHorizIcon sx={{color:"#171c26ba"}}/>),
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                          }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Core Settings </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                {
                                    <div className="formInput">
                                        <InputFields
                                            label={"Setting Name"}
                                            name="setting_display_name"
                                            defaultValue={formValues.setting_display_name}
                                            type="text"
                                            placeholder="Setting-Name"
                                            onBlur={handleSettingKeyChange}
                                            errStatus={errStatus}
                                            formErr={formErr.coreSettingsErr}
                                        />
                                    </div>
                                }
                                {formValues.setting_display_name ? <div className="formInput">
                                    <InputFields
                                        readOnly={ID ? true : false}
                                        label={"Setting Key"}
                                        name="setting_key"
                                        defaultValue={formValues.setting_display_name.toLowerCase().split(' ').join('-')}
                                        type="text"
                                        placeholder="Setting-Key"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.valueErr}
                                    />
                                </div> : ""
                                }


                                {jsonValueChecked === true ?
                                    <div style={{ width:'100%'}}>
                                        <InputTextareaField
                                            label={"Setting Value"}
                                            name="setting_value"
                                            defaultValue={formValues.value}
                                            type="text"
                                            placeholder="value"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.valueErr}
                                        />
                                    </div>
                                    :
                                    <div style={{width:'100%'}}>
                                        <InputFields
                                            label={"Setting Value"}
                                            name="setting_value"
                                            defaultValue={formValues.value}
                                            type="text"
                                            placeholder="value"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.valueErr}
                                        />
                                    </div>
                                }


                                {
                                    <div className="formInput" style={{ marginTop: 20 }} >
                                        <Checkbox
                                            className="checkbox"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            name={"is_json_value"}
                                            defaultChecked={formValues.is_json_value}
                                            //   checked={}
                                            onChange={handleChange}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                        <label>
                                            JSON Value
                                        </label>
                                    </div>
                                }

                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className={reload === true ? 'btn-disable':'submit-modal'}
                            disabled={reload === true ? true :false } onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} setting`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                    <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>
            
                </Menu>

            </div>
        </div >
    )
}

export default CoreSettings;
