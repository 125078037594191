import "./master.scss"
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import ArchiveIcon from '@mui/icons-material/Archive';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { toast } from "react-toastify";
import { Dialog, DialogContent, Skeleton, Switch, Stack, Menu, MenuItem, Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiringModeCreate, HiringModeEdit, HiringModeUpdate, getHiringModeList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Edit, Close } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import StatusInputValue from "../../components/common-function/StatusOperator";

const initialFormData = Object.freeze({
    name: "",
    description: "",
    status: true,
});

const formErrData = Object.freeze({
    hiring_modeErr: "Hiring Mode is required",
    descriptionErr: "Hiring Mode description is required",
    statusErr: "Status is required"
});

let flag = 1;

function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} {...props} />
        </Draggable>
    );
}

const SubHiringMode = ({onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu,  clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu}) => {
    const { REACT_APP_ENV } = process.env;

    const [ID, setID] = useState("");
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const [changeStatus, setChangeStatus] = useState('');
    const [formErr] = useState(formErrData)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    
    const { status, hiring_mode_List, hiring_mode_Create, hiring_mode_Edit, hiring_mode_Update } = useSelector(state => state.master)
    const dispatch = useDispatch();

    const filterOperators = getGridStringOperators().filter(({ value }) =>
    
        ['endsWith','startsWith', 'contains', 'equals'].includes(value),
    );
    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
 

    let actionColumn = [
        {
            field: "name",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 400,
            sortable: false,
            pinnable: false,
            filterOperators,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
                    <div>
                        <CustomeTooltip
                            title={params?.row?.name || "N/A"}
                            data={params?.row?.name || "N/A"}
                        />
                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Description</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("description")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 400,
            sortable: false,
            pinnable: false,
            filterOperators,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
                    <div>
                        <CustomeTooltip
                            title={params?.row?.description || "N/A"}
                            data={params?.row?.description || "N/A"}
                        />
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
            <Box justifyContent={"center"}>
                <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
            </Box>
        </Stack>,
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable: false,
            pinnable: false,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
    
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                            <Switch  name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)}/>
                            <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="hiring-mode"
                                rowid={params?.row?.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                // isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                // deleteButton={() => handleArchive(params.row)}
                                />
                            </div>
                        </div>
                );
            },
        },
    ];

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })

    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.name === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.name !== "" && formValues.description !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId }
            
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(HiringModeUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(HiringModeCreate(addvalues))
            }

            setErrStatus(false)
            setReload(true)
            handleClose()
        }
    }

    const handleClickEdit = (id) => {
        flag = 1;
        dispatch(HiringModeEdit(id))
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }

        // clearGridListing.current = true;
        // editID.current = {...data};
        // let copyRows = [...gridRows];
        // let index = copyRows.findIndex(item=>item.id === data.id); 
        // copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        // setGridRows(copyRows);
        // setReload(true)
        let ID = data.id;
        let updatedRows = [...gridRows];
        let rowIndex = updatedRows.findIndex((item) => item.id === data.id);
        if (rowIndex !== -1) {
            updatedRows[rowIndex] = { ...data, status: !data.status };
            setGridRows(updatedRows);
        }
        dispatch(HiringModeUpdate({ ID, addvalues }))
    }

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

    if (flag ===1 && status === `${STATUSES.SUCCESS}_hiring_mode_edit` && hiring_mode_Edit.message) {
        if (hiring_mode_Edit.status === 200) 
        {
            let editData = hiring_mode_Edit.data
            setFormValues({
                name: editData.name,
                description: editData.description,
                status: editData.status,
                id: editData?.id
            })
            setID(editData.id)
            setOpen(true)
        }
        else
        {
            toast.error(hiring_mode_Edit.message)
        }
        flag = 0;
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_hiring_mode_Update` && hiring_mode_Update && hiring_mode_Update.message) {
        if (hiring_mode_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(hiring_mode_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(hiring_mode_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_hiring_mode_Create` && hiring_mode_Create && hiring_mode_Create.message) {
        if (hiring_mode_Create.status === 200) {
            toast.success(hiring_mode_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(hiring_mode_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && hiring_mode_List && hiring_mode_List.message) {
        if (hiring_mode_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...hiring_mode_List.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(hiring_mode_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...hiring_mode_List.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(hiring_mode_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(hiring_mode_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    return (
            <>
                <div className="datatable">
                    
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Hiring Mode List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getHiringModeList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                ".actionButton": {
                                    display: 'block'
                                }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Hiring Mode</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div style={{width:'100%'}}>
                                    <InputFields
                                        label={"Hiring Mode"}
                                        name="name"
                                        defaultValue={formValues.name}
                                        type="text"
                                        placeholder="Hiring Mode"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.hiring_modeErr}
                                    />
                                </div>

                                <div style={{width:'100%'}}>
                                    <InputTextareaField
                                        label={"Hiring Mode Description"}
                                        name="description"
                                        defaultValue={formValues.description}
                                        type="text"
                                        placeholder="Hiring Mode Description"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />
                                </div>

                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-submit" style={{float:'right',backgroundColor:"rgba(33, 150, 243)"}} onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} hiring mode`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                    <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>
            
                </Menu>
            </>
    )
}

const  HiringMode = DataGridProLazyLoadHOC(SubHiringMode, {filterColumns: DefaultFilterColumns.HIRING_MODE_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim()), getDispatcherAsyncThunk:getHiringModeList, gridColumns:['name', 'description', 'status']});

export default HiringMode;