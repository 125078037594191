import React, { useState } from "react";
import { Dialog, DialogContent,FormControl, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getHourlyCallReport } from "../../redux/features/agentCallPerformanceReportSlice";
import { STATUSES } from "../../redux/common-status/constant"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
const columns = [
  { field: "id", headerName: "ID", width: 90, hideable:true },
  {
    field: "resource_name",
    headerName: "Agent",
    width: 160,
    filterable: false,
  },
  {
    field: "total_call_count",
    headerName: "Total Call count",
    width: 260,
    filterable: false,
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 260,
    filterable: false,
  },
  {
    field: "in_bound_calls",
    headerName: "Inbound Calls",
    width: 260,
    filterable: false,
  },
  {
    field: "out_bound_calls",
    headerName: "Outbound Calls",
    width: 260,
    filterable:false, 
  },
  {
    field: "interviews_scheduled",
    headerName: "Interview Scheduled",
    width: 275,
    filterable: false, 
  },
];

const HourlyCallStatus = ({ setShowHourly }) => {
  const { agentCallReportList, status,HourlyCallReportList } = useSelector(
    (state) => state.agentCallPerformanceReport
  );

  const [selectTime,setSelectTime]=useState("")
  const [dataList,setDataList]=useState([])
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false)
  const [formDate,setFromDate]=useState(new Date())
const dispatch=useDispatch()
  const timeData=[
    {labe:'09:30 AM - 10:30 AM',value:'09:30:00-10:30:00'},
    {labe:'10:30 AM - 11:30 AM',value:'10:30:00-11:30:00'},
    {labe:'11:30 AM - 12:30 PM',value:'11:30:00-12:30:00'},
    {labe:'12:30 PM - 01:30 PM',value:'12:30:00-13:30:00'},
    {labe:'01:30 PM - 02:30 PM',value:'13:30:00-14:30:00'},
    {labe:'02:30 PM - 03:30 PM',value:'14:30:00-15:30:00'},
    {labe:'03:30 PM - 04:30 PM',value:'15:30:00-16:30:00'},
    {labe:'04:30 PM - 05:30 PM',value:'16:30:00-17:30:00'},
    {labe:'05:30 PM - 06:30 PM',value:'17:30:00-18:30:00'},
    {labe:'06:30 PM - 07:30 PM',value:'18:30:00-19:30:00'},
    {labe:'07:30 PM - 05:30 PM',value:'19:30:00-20:30:00'},
    {labe:'08:30 PM - 09:30 PM',value:'20:30:00-21:30:00'},
    {labe:'09:30 PM - 10:30 PM',value:'21:30:00-22:30:00'},
    {labe:'10:30 PM - 11:30 PM',value:'22:30:00-23:30:00'},
    {labe:'11:30 PM - 12:30 PM',value:'23:30:00-24:30:00'},
    {labe:'12:30 AM - 01:30 AM',value:'24:30:00-01:30:00'},
    {labe:'01:30 AM - 02:30 AM',value:'01:30:00-02:30:00'},
    {labe:'02:30 AM - 03:30 AM',value:'02:30:00-03:30:00'},
    {labe:'03:30 AM - 04:30 AM',value:'03:30:00-04:30:00'},
    {labe:'04:30 AM - 05:30 AM',value:'04:30:00-05:30:00'},
    {labe:'05:30 AM - 06:30 AM',value:'05:30:00-06:30:00'},
    {labe:'06:30 AM - 07:30 AM',value:'06:30:00-07:30:00'},
    {labe:'07:30 AM - 08:30 AM',value:'07:30:00-08:30:00'},
    {labe:'08:30 AM - 09:30 AM',value:'08:30:00-09:30:00'},
    
  ]
  //["06:30:00","19:30:00"]
    
  const handleChange=(evt)=>{
    setSelectTime(evt)

    let date = new Date(formDate);
    let filter = `{"filter":{"call_date":{"value":"${moment(date).format('YYYY-MM-DD')}","operation":"eq"},"call_time":{"value":["${evt.value.split("-")[0]}","${evt.value.split("-")[1]}"],"operation":"range"}},"skip":0,"limit":${limit}}`;
    setLoading(true);
     dispatch(getHourlyCallReport(filter))
  }

  if(status === `${STATUSES.SUCCESS}_hourlyCallslist` && loading === true)
  {
    setDataList(HourlyCallReportList.data.rows)
    setLoading(false)
  }

  return (
    <>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"xl"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Button
          edge="start"
          onClick={() => setShowHourly(false)}
          color="inherit"
          aria-label="close"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <Close />
        </Button>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
        <FormControl  variant="contained" style={{ position: "absolute", top: "11px", right: "350px"}} sx={{width:250}}>
              <DatePicker
                  value={formDate}
                  onChange={(newValue) => {

                      setFromDate(newValue);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} size="small" />}

              />
           </FormControl>
          </LocalizationProvider>
        <FormControl  variant="contained" style={{ position: "absolute", top: "20px", right: "100px"}} sx={{width:250}}>
          <Select
              placeholder="select time"
              value={selectTime}
              options={timeData.map((option)=>{
                  return(
                    {value:option.value,label:option.labe}
                  )
              })}
              onChange={(evt) =>handleChange(evt)}
          />
      </FormControl>
        <DialogContent>
          <p style={{ fontSize: "22px", padding: "5px" ,fontWeight:"bold"}}>Hourly call status</p>
          <div className="popup-confirming">
            <DataGrid
              rows={dataList.length && dataList?.map(item=>({...item, id:item.resource_id}))}
              columns={columns}
              style={{marginTop:"10px"}}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
             // disableRowSelectionOnClick
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HourlyCallStatus;
  