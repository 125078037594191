import "./manageshift.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../redux/APIS/api";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from "../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Tooltip, responsiveFontSizes } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dataService from "../../redux/services/data.service";

const AddShift = () => {
    const navigate = useNavigate()
    const editFormData = useLocation().state;
    const [formData, setFormData] = useState({ ...editFormData, "created_by": LocalStorageDataService.userDetail().id, } || { "created_by": LocalStorageDataService.userDetail().id, });
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)


    const handleChange = (evt) => {
        let { name, type, value, checked } = evt.target;
        value = type === "checkbox" ? checked : type === "time" ? value.length < 8 ? value + ":00" : value : value;
        setFormData({ ...formData, [name]: value })
    }




    const loadDataById = async (id) => {
        setLoader(true)
        const response = await dataService.shiftBYId(id).catch(error => {
            setLoader(false)
            toast.error(error.message)
            return error;
        });
        if (response && response.data && response.data.data) {

            setFormData({ ...formData, ...response.data.data })
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (editFormData?.id) {
            loadDataById(editFormData?.id)
        }
    }, [])

    const handleSubmit = async (evt) => {
        setLoading(true)
        evt.preventDefault();
        let updateddata = {
            shift_name: formData?.shift_name,
            start_time: formData?.start_time,
            end_time: formData?.end_time,
            duration: formData?.duration,
            timing_display: formData?.timing_display,
            time_zone: formData?.time_zone,
            description: formData?.description,
            status: formData?.status
        }
        const res = formData.id ? await api.put("/mst/shift/" + formData.id, updateddata) : await api.post("/mst/shift", { ...formData, timing_display: "24Hours", created_by: LocalStorageDataService.userDetail().id })
        if (res) {
            if (res.data && res.data.data) {
                toast.success(res.data.message);
                setTimeout(() => {
                    navigate("/all-shift", { replace: true })
                    setLoading(false)
                }, 3000);
            }
            else {
                toast.error(res.data.message)
                setLoading(false)
            }

        }
    }


    return (
        <div className="new">
            <div className="newContainer">
                <div className="top-headings">
                    <h3> {formData.id ? "Edit Shift" : "Add new Shift"} </h3>
                    <Tooltip title="Back">
                        <Link to={"/all-shift"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>

                </div>
                <ToastContainer />
                <form onSubmit={handleSubmit}>
                    <div className="add-new-user-form">
                        {loading && <LoaderForm />}
                        <div className="formInput">
                            <label >
                                Shift Name
                            </label>
                            <input
                                name={"shift_name"}
                                defaultValue={formData?.shift_name}
                                onChange={handleChange}
                                type="text"
                                placeholder="Shift Name"
                            />

                        </div>

                        <div className="formInput">
                            <label >
                                From Time
                            </label>

                            <input
                                name="start_time"
                                defaultValue={formData?.start_time}
                                onChange={handleChange}
                                type="time"
                                placeholder="Shift Starts"
                            />

                        </div>

                        <div className="formInput">
                            <label >
                                To Time
                            </label>

                            <input
                                name="end_time"
                                defaultValue={formData?.end_time}
                                onChange={handleChange}
                                type="time"
                                placeholder="To Time"
                            />

                        </div>


                        <div className="formInput">
                            <label >
                                Time Zone
                            </label>
                            <select
                                name={"time_zone"}
                                value={formData?.time_zone}
                                onChange={handleChange}
                            >
                                <option label="">Select</option>
                                <option value="ist">India Standard Time</option>
                                <option value="others">Others</option>
                            </select>

                        </div>

                        <div className="formInput">
                            <label >
                                Description
                            </label>

                            <textarea rows="3"
                                name={"description"}
                                defaultValue={formData?.description}
                                onChange={handleChange}
                                placeholder="Short Description"
                            >
                            </textarea>

                        </div>
                        <div className="formInput">
                            <label >
                                Status
                            </label>
                            <select
                                name={"status"}
                                value={formData?.status}
                                onChange={handleChange}
                            >
                                <option value={true} label="">Active</option>
                                <option value={false} label="">Inactive</option>
                            </select>

                        </div>

                    </div>

                    <button type="submit" disabled={loading} className="btn btn-submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default AddShift