import React, { useEffect, useState } from "react";
import "./manageCredential.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dataService from "../../../redux/services/data.service";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../../components/common-function/LoaderForm";
import LocalStorageDataService from "../../../components/common-function/GetLocalstorage";
import { Switch, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from "react-redux";
import { credentialHeadCreate, credentialHeadUpdate } from "../../../redux/features/cedentialHeadSlice";

function AddCredentialHead({ inputs, title,handleclose,id }) {

    const dispatch=useDispatch()
    const [showLoader, setShowLoader] = useState(false)

    const [formData, setFormData] = useState({
        head_name: "",
        description: "",
        status: true
    })
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (evt) => {
        const { name, value,checked} = evt.target;
        // setFormData({ ...formData, [name]: value });
        setFormData({ ...formData, [name]: name === 'status' ? checked : value })
    }

    const loadDataById = async () => {
        setShowLoader(true)

        const response = await dataService.credentialHeadById(id).catch(error => {
            setShowLoader(false)
            return error;
        });
        if (response && response.data && response.data.status === 200) {
            setFormData(response.data.data)
            setShowLoader(false)
        } else {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        id && loadDataById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const onSubmit = async (e) => {
        e.preventDefault();
        const request = { ...formData, created_by: LocalStorageDataService.userDetail().id, };

        if (!formData.head_name) {
            return setFormErrors({ 'nameErr': 'Head Name is Required' })
        } else if (!formData.description) {
            return setFormErrors({ 'descErr': 'Description is Required' })
        } else if (formData?.status === "") {
            return setFormErrors({ 'statusErr': 'Status is Required' })
        }
        setShowLoader(true)
         !id ?  dispatch(credentialHeadCreate(request)).then((response)=>{
            if(response && response.payload.data && response.payload.status ===200){
                // toast.success(response.payload.massage)
                handleclose()
            }else{
               // toast.error(response.payload.massage)
                setShowLoader(false)
            }
            if (response && response.payload?.status === 400) {
                setShowLoader(false)
                const res = response.data.message
                // toast.error(res)
                setFormErrors({
                    "nameErr": res.head_name,
                    "descErr": res.description,
                    "statusErr": res.status,
                })}
        }) : dispatch(credentialHeadUpdate({ID:id, addvalues: request})).then(response=>{   
            if (response && response.payload.data && response.payload.status=== 200) {
                // toast.success(response.payload.message);
                setTimeout(() => {
                    // navigate('/all-user')
                    handleclose()
                }, 800)
           }else{
            // toast.error(response.payload.massage)
           }
           if (response && response.payload?.status === 400) {
            setShowLoader(false)
            const res = response.payload.message
            // toast.error(res)
            setFormErrors({
                "nameErr": res?.head_name,
                "descErr": res?.description,
                "statusErr": res?.status,
            })
        }
        })
        setTimeout(()=>{
            setShowLoader(false)
        },300)
    }

    return (
        <div className="new">
            <div className="newContainer">
               { !handleclose?
                <div className="top-headings">
                <h3>{title} </h3>
              
                <Tooltip title="Back">
                    <Link to={"/all-credentials-head"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                </Tooltip>
            </div>:""
               } 

                {/* <ToastContainer /> */}
                {
                    showLoader ? <LoaderForm /> :
                        <form onSubmit={onSubmit}>

                            <div className="add-new-user-form">

                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={formData[input.name]}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <div className="errorMessage">{
                                            formErrors && input.name === "head_name" ?
                                                <p>{formData.head_name === "" ? formErrors.nameErr : ""}</p>
                                                : input.name === "description" ?
                                                    <p>{formData.description === "" ? formErrors.descErr : ""}</p>
                                                    : ""
                                        }
                                        </div>
                                    </div>
                                ))}

                                <div className="formInput">
                                    {/* <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {formErrors && formErrors.statusErr && <p style={{ color: "#e33417", fontSize: 14 }}>{formData.status === "" ? formErrors.statusErr : ""}</p>}

                                    </FormControl> */}
                                    <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formData.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                                </div>

                            </div>
                            {/* <button className="btn btn-submit">Submit</button> */}
                            <button type="submit" className ={showLoader === true ? 'btn-disable':'submit-modal'} disabled={showLoader === "submit" ? true :false }>Submit</button>
                        </form>
                }


            </div >

        </div >
    )
}
export default AddCredentialHead