import React from 'react'
import './Loader.css'

const ProcessSpinner = ({size, varient}) => {
  return (
    <>
             <span className={size===
            "sm" ? "loader1 " :
            "md" ? "loaderMd" :
            "lg" ? "loaderLg " :
            "xl" ? "loaderXl " :
            "25" ? "loader25" :
            "loader48 "
            + " " +
            varient===
            "transparent" ? "transparent" :
            "blue" ? "blue" :
            "red" ? "red" :
            "yellow" ? "yellow" :
            "white"
            }></span>
    </>
  )
}

export default ProcessSpinner