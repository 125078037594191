import React, { useEffect, useRef, useState } from 'react'
import './domain.scss'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { projectsList, domainList, projectDomains } from "../../redux/features/domainSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import { Add, MoreHoriz, UnfoldMore } from '@mui/icons-material';
import { DataGridPro, getGridStringOperators, gridClasses } from '@mui/x-data-grid-pro';
import { LinearProgress, Menu, MenuItem, Skeleton, Stack, Switch, Typography } from '@mui/material';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import UcActionComponent from '../../components/common-function/UcActionComponent';
const { REACT_APP_ENV } = process.env;

const ProjectDomain = () => {

  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { domains, project_DomainList, status } = useSelector(state => state.domain)
  const [limit, setLimit] = useState(25);
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);

  const [projectsData, setProjectsData] = useState({})

  const [reload, setReload] = useState(false)
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  // grid states starts
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });

  const skeletonBase = { project_name: '_skeleton_', project_key: '_skeleton_', status: '_skeleton_', is_jira_project: '_skeleton_', domains: '_skeleton_' };
  const filterableColumns = ['project_name', 'project_key', 'status', 'is_jira_project', 'domains'];

  const [loading, setLoading] = useState(false)
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();

  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let editID = useRef(null);
  let isAnyChange = useRef({});
  const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value),
);
  // grid states ends

  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setLoading(true);
    setReload(true);
    dispatch(projectDomains(filterQuery))
  }, [filterQuery]);


  const modalDetail = {
    title: "Project Details",
    editModalTitle: "Edit Project",
    addModalTitle: "Add Project",
    formInputs: [
      { id: 1, name: "project_name", label: "Project Name", placeholder: "Enter Name", type: "textWithLabel", required: true, disabled: false },
      {
        id: 3, name: "status", label: "Status", placeholder: "Enter For Date", type: "status",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      {
        id: 2, name: "domains", label: "Domains", placeholder: "Enter Domains", type: "multiSelect",
        options: domains.map(e => ({ id: e.id, label: e.name, value: e.id })),
        required: true, disabled: false
      },
    ],
  }

  // columns section
  const projectDomainColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "project_name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Project Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("project_name")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 275,
      sortable: false,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        return (
          params?.row?.project_name === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.project_name}</Typography>
        )
      },
    },
    {
      field: "project_key",
      headerName: "Project Key",
      filterOperators,
      sortable: false,
      pinnable: false,
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Project Key</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("project_key")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 150,
      renderCell: (params) => {
        return (
          params?.row?.project_key === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.project_key}</Typography>
        )
      },

    },

    {
      field: "is_jira_project",
      headerName: "Is Jira Project",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Is Jira Project</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("is_jira_project")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 150,
      hideable: false,
      sortable: false,
      pinnable: false,
      type: 'singleSelect',
      valueOptions: [{ label: "Yes", value: "True" }, { label: "No", value: "False" }],
      renderCell: (params) => {
        return (
          params?.row?.is_jira_project === "_skeleton_" ? <Skeleton width={"300px"} /> :
            <div className="cellWithImg">
              {params.row.is_jira_project ? "Yes" : "No"}
            </div>)
      },
    },
    {
      field: "domains",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Domains</p></div>,
      width: 450,
      hideable: false,
      filterable: false,
      sortable: false,
      hideable: false,
      pinnable: false,
      type: 'singleSelect',
      valueOptions: domains,
      renderCell: (params) => {
        return (
          params?.row?.domains === "_skeleton_" ? <Skeleton width={"300px"} /> :
            <Stack direction={'row'}>
              {params.row.domains && params.row.domains.length ?
                params.row.domains.map((domain, index) => {
                  return (
                    <>
                      <Typography variant='p'>{index === 0 && domain.name}</Typography>
                      <Typography variant='p'>{index === 0 && params.row.domains.length > 1 && ","}</Typography>
                      <Typography variant='p'>
                        {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                      </Typography>
                    </>
                  )
                })
                : ""
              }
              {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}

            </Stack>
        );
      },
    },
  ];


  const onSubmit = async (data) => {
    setFormLoading(true)
    clearGridListing.current = true;
    const finalData = projectsData.id ? { ...projectsData, ...data, updated_by: userId } : { ...data, created_by: userId }
    // return;
    const res = await dataService.assign_project_domain({
      "project_id": finalData.project_id,
      "domains_ids": finalData?.domains?.map(e => e.id),
      "created_by": userId
    });
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      onCloseModal();
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      setFormLoading(false)
      toast.error(res.data.message)
    }
    setReload(false)
  }

  const onAdditionalAction = async (data, key) => {
    if (key === "assign_domain") {
      setProjectsData({
        ...projectsData,
        project_name: data.project_name, domains: data.domains,
        project_id: data.id, status: data.status,
        created_by: userId
      });
      setUpdating(true)
      setEditable(true);
      setOpen(true);
    }
  }
  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setProjectsData({});
    setContextMenu(null);
    setFormLoading(false)
  }

  useEffect(() => {
    dispatch(domainList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //lazyloading 
  const loadServerRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      loadServerRows(params.viewportPageSize);
    }
  };

  // right click section
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);

    switch (actionType) {
      case 'assign_domain':
        onAdditionalAction(row, 'assign_domain')
        break;

    }

    setContextMenu(null);

  }

  // filter section
  const onFilterChange = (props) => {
    if (props.items.length > projectDomainColumns.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" } };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }

  // sorting section
  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;
  }

  // handle load data response
  if (loading === true && status === `${STATUSES.SUCCESS}_project_DomainList`) {
    if (project_DomainList.status === 200) {
      SET_MAX_ROW_LENGTH(project_DomainList.data.count);
      let fout = [];
      let copyLoadedRows = [...gridRows, ...project_DomainList.data.rows];
      copyLoadedRows = copyLoadedRows.filter(item => {
        if (fout.includes(item.id)) return false
        fout.push(item.id)
        return true
      })

      if (clearGridListing.current === true) {
        setGridRows([...copyLoadedRows]);
        setSkeletonRows([])
      } else {
        setGridRows((pre) => [...pre, ...copyLoadedRows]);
        setSkeletonRows([])
      }
    } else {
      toast.error(project_DomainList.message)
    }
    setLoading(false)
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  return (
    <div className="new">
      <div className="newContainer">
        <div className="datatable">
          <ToastContainer />

          {/* header section */}
          {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <div style={{ fontSize: "20px", color: "#27276f" }}>
              <p>Poject Domain List</p>
            </div>
            <div>
              <Stack spacing={2} direction="row">
                <RefreshButton api={projectDomains} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
              </Stack>
            </div>
          </div> */}

          <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
            <Stack direction={'row'}>
              <Typography className="headerTitleName" variant="h3">Poject Domain List</Typography>
            </Stack>
            <Stack direction={'row'}>
              <RefreshButton api={projectDomains} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
            </Stack>
          </Stack>
        </div>

        {/* data grid section */}
        <DataGridPro
          className="datagrid"
          filterMode="server"
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          rows={gridRows?.concat(skeletonRows)}
          columns={projectDomainColumns}
          filterModel={filterModal}
          getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          components={{
            ColumnMenuIcon: MoreHoriz,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          componentsProps={{
            row: { onContextMenu: handleContextMenu, }
          }}
          sx={{
            height: 700, "& .MuiDataGrid-row:hover": {
              backgroundColor: "#438bf969",
            }, ".actionButton": {
              display: 'none'
            }, [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                ".actionButton": {
                  display: 'block'
                }
              }
            }
          }}
          onFilterModelChange={onFilterChange}
          onRowsScrollEnd={handleOnRowScrollEnd}
          hideFooter
        />

        {/* right click action section */}
        <Menu
          open={contextMenu !== null}
          onClose={onCloseModal}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                onCloseModal();
              }
            }
          }}
        >
          <MenuItem onClick={() => handleMenuAction('assign_domain')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Add sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Manage Domain</MenuItem>
        </Menu>

      </div>

      {/* assign domain popup section */}
      {<RegularizeModal
        modalDetail={modalDetail}
        open={open}
        onOpen={onOpenModal}
        onClose={onCloseModal}
        onSubmit={onSubmit}
        hideBackdrop
        fullWidth
        maxWidth
        updating={updating}
        editData={projectsData}
        editable={editable}
        formLoading={formLoading}
        setFormLoading={setFormLoading}
        additionalActions={modalDetail.additionalActions}
      />
      }
    </div>

  )
}

export default ProjectDomain