import { memo } from "react";
import { InputFieldNumber, InputSelectField } from "../../../components/common-function/InputFields";
import LoaderForm from "../../common-function/LoaderForm";

const StepFormTwo = ({ currency_AllList, paymenttype_List, hiring_mode_AllList, handleChangeSelect, handleChangeText, onSubmitStep2, formValues, errStatus, formErr,
    reload, }) => {

    const isNegativeValue = (value) => {
        return value < 0;
    }

    return <>
        {(reload === true || reload === 'submit') ? <LoaderForm /> :
            <form>
                <h4>Step 2 JOB COMMERCIALS </h4>
                <br />
                <div className="add-new-user-form">
                    <div className="formInput">
                        <InputFieldNumber
                            label={'Salary Budget From'}
                            name="client_budget_from"
                            defaultValue={formValues.client_budget_from}
                            type="text"
                            placeholder="Salary Budget From"
                            onChange={handleChangeText}
                            errStatus={errStatus}
                            formValues={formValues.client_budget_from}
                            formErr={formErr.budgetFormErr}                            
                            min={0}
                        />
                        {isNegativeValue(formValues.client_budget_from) || formValues.client_budget_from === 0 && <span style={{ color: "#96332c", fontSize: 13 }} className="error-message">Value cannot be {formValues.client_budget_from === 0 ? "zero" :"negative"}</span>}

                    </div>

                    <div className="formInput">
                        <InputFieldNumber
                            label={'Salary Budget To'}
                            name="client_budget_to"
                            defaultValue={formValues.client_budget_to}
                            type="text"
                            placeholder="Salary Budget To"
                            onChange={handleChangeText}
                            errStatus={errStatus}
                            formValues={formValues.client_budget_to}
                            formErr={formErr.budgetToErr}
                            min={0}
                        />
                        {isNegativeValue(formValues.client_budget_to) || formValues.client_budget_to === 0 && <span style={{ color: "#96332c", fontSize: 13 }} className="error-message">Value cannot be {formValues.client_budget_to === 0 ? "zero" :"negative"}</span>}
                    </div>


                    <div className="formInput">
                        <InputSelectField
                            label={"Amount in currency"}
                            name={"currency"}
                            placeholder={"Currency"}
                            onChange={handleChangeSelect}
                            defaultValue={formValues.currency}
                            errStatus={errStatus}
                            formErr={formErr.currenyErr}
                            optionList={currency_AllList?.data && currency_AllList.data.rows.map((option) => {
                                return {
                                    label: option.currency_name,
                                    value: option.id,
                                    name: 'currency'
                                }
                            })
                            }
                            optionLevel={['label']}
                            optionValue={'value'}
                        />
                    </div>

                    <div className="formInput">
                        <InputSelectField
                            label={"Payment Type"}
                            name={"payment_type"}
                            placeholder={"Payment Type"}
                            onChange={handleChangeSelect}
                            defaultValue={formValues.payment_type}
                            errStatus={errStatus}
                            formErr={formErr.paymentTypeErr}
                            optionList={paymenttype_List?.data && paymenttype_List.data.rows.map((option) => {
                                return {
                                    label: option.name,
                                    value: option.id,
                                    name: 'payment_type'
                                }
                            })
                            }
                            optionLevel={['label']}
                            optionValue={'value'}
                        />
                    </div>

                    <div className="formInput">
                        <InputSelectField
                            label={"Hiring Mode"}
                            name={"hiring_mode"}
                            placeholder={"Hiring Mode"}
                            onChange={handleChangeSelect}
                            defaultValue={formValues.hiring_mode}
                            errStatus={errStatus}
                            formErr={formErr.hiringModeErr}
                            optionList={hiring_mode_AllList?.data && hiring_mode_AllList.data.rows.map((option) => {
                                return {
                                    label: option.name,
                                    value: option.id,
                                    name: 'hiring_mode'
                                }
                            })
                            }
                            optionLevel={['label']}
                            optionValue={'value'}
                        />
                    </div>
                    <div className="formInput"></div>

                </div>
                {/* <button className="btn btn-submit"
                    onClick={(e) => submitFormData(e, null)}
                >Save as Draft</button> */}
            </form >
        }
    </>
}
export default memo(StepFormTwo)