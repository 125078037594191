import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ThirdPartyColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { thirdPartyCreate, thirdPartyEdit, thirdPartyUpdate, getThirdPartyList } from "../../redux/features/thirdPartySlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogTitle, Button, Tooltip, Dialog, DialogContent, TablePagination, Menu, MenuItem, Stack, Box, Typography, IconButton, Switch, Skeleton, Paper, TextField, TextareaAutosize, InputLabel, NativeSelect } from '@mui/material';
import * as React from 'react';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close } from "@mui/icons-material";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses, getGridStringOperators, getGridBooleanOperators } from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Edit } from "@mui/icons-material";
import { useRef } from "react";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import Draggable from "react-draggable";

const initialFormData = Object.freeze({
    t_name: "",
    code: "",
    description: "",
    status: true
});

const formErrData = Object.freeze({
    status_nameErr: "Third party name is required",
    descriptionErr: "Description is required",
    categoryErr: "Code is required",
    statusErr: "Status is required"
});

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', description: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} {...props} />
        </Draggable>
    );
}

// we make common function in the future.
function StatusInputValue(props) {
    const { item, applyValue, focusElementRef } = props;
    const ratingRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Value
                </InputLabel>
                <NativeSelect
                    defaultValue={30}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleFilterChange}
                >
                    <option value={'True'}>True</option>
                    <option value={'False'}>False</option>
                </NativeSelect>
            </FormControl>
        </Box>
    );
}

const ThirdParty = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, thirdParty_List, thirdParty_Create, thirdParty_Edit, thirdParty_Update, thirdParty_Archive } = useSelector(state => state.thirdParty)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [gridRows, setGridRows] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [colSortingPerform, setColSortingPerform] = useState([]);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    const editID = useRef()
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const filterableColumns = ['name', 'code', 'description', 'status'];
    let isAnyChange = useRef({});



    useEffect(() => {
        setReload(true)
        dispatch(getThirdPartyList(filterQuery))
    }, [filterQuery])

    if (status === STATUSES.FAILURE && error) {
        setReload(false)
        errorHandling(error, navigate)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Update` && thirdParty_Update && thirdParty_Update.message) {
        if (thirdParty_Update.status === 200) {
            let copyGridRows = [...gridRows].map(item => {
                if (editID?.current?.id === item?.id)
                    return { ...thirdParty_Update?.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            // let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
            // setFilterQuery(str)
            toast.success(thirdParty_Update.message)
        } else {
            // let copyLoadedRows = [...gridRows];
            // let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            // copyLoadedRows.splice(index, 1, editID.current)
            // setGridRows(copyLoadedRows);
            toast.error(thirdParty_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Create` && thirdParty_Create && thirdParty_Create.message) {
        if (thirdParty_Create.status === 200) {
            toast.success(thirdParty_Create.message)
            dispatch(getThirdPartyList(filterQuery))
        } else {
            toast.error(thirdParty_Create.message)
        }
        setReload(false)
    }

    // if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Archive` && thirdParty_Archive && thirdParty_Archive.message) {
    //     if (thirdParty_Archive.status === 200) {
    //         toast.success(thirdParty_Archive.message)

    //         // dispatch(getThirdPartyList(filterQuery))
    //     } else {
    //         toast.error(thirdParty_Archive.message)
    //     }
    //     setReload(false)
    // }

    if (reload === true && status === STATUSES.SUCCESS && thirdParty_List && thirdParty_List.message) {
        if (thirdParty_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...thirdParty_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(thirdParty_List.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...thirdParty_List.data.rows]);
                SET_MAX_ROW_LENGTH(thirdParty_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(thirdParty_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false)
        setContextMenu(null)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.t_name === "" || formValues.description === "" || formValues.code === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.t_name !== "" && formValues.description !== "" && formValues.code !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data?.id
            let addvalues = { ...formValues, name: formValues.t_name, created_by: userId }
            delete addvalues.t_name;
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(thirdPartyUpdate({ ID, addvalues }))
            } else {
                dispatch(thirdPartyCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && thirdParty_Edit && thirdParty_Edit.data) {
        let editData = thirdParty_Edit.data
        setFormValues({
            t_name: editData.name,
            description: editData.description,
            code: editData.code,
            status: editData.status
        })
        setID(editData?.id)
        setOpen(true)
        setReload(false)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_getThirdPartyList` && thirdParty_List.message) {
        if (thirdParty_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...thirdParty_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(thirdParty_List.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...thirdParty_List.data.rows]);
                SET_MAX_ROW_LENGTH(thirdParty_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(thirdParty_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleClickEdit = (id) => {
        dispatch(thirdPartyEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const onFilterChange = (props) => {
        if (props?.items?.length > actionColumn?.length)
            return;
        if (props?.items?.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends
                setFilterModel(props)
                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                startsWith: 'istartswith',
                endsWith: 'endswith',
                is: 'eq',
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        editID.current = { ...data };
        let addvalues = {
            name: data.name,
            code: data.code,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(thirdPartyUpdate({ ID, addvalues }))
    }
    const archiveModalClose = () => {
        setArchiveOpen(false)
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyList(JSON.stringify(newFilterData)))
    }

    // function CustomPagination() {
    //     return (
    //         <TablePagination
    //             component="div"
    //             count={(thirdParty_List && thirdParty_List.data && thirdParty_List.data.rows && thirdParty_List.data.count) || 0}
    //             page={currentPage}
    //             rowsPerPage={pageSize}
    //             rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
    //             onPageChange={() => setCurrentPage(pageSize)}
    //             onRowsPerPageChange={handlePageSizeChange}
    //             ActionsComponent={(subProps) =>
    //                 <TablePaginationActions {...subProps}
    //                     handlePagination={handlePagination}
    //                 />}
    //         />
    //     );
    // }

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = limit;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }
        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
    }

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value),
    );


    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];

    const actionColumn = [
        // { field: "id", headerName: "ID", width: 70, filterable: false, },
        {
            field: "name",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            width: 450, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack direction={"row"} display={"flex"} justifyContent={"start"} width={'20%'}>
                            <Typography variant="p">
                                {params.row.name}
                            </Typography>
                        </Stack>
                );
            },
        },
        {
            field: "code",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Code</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("code")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>, width: 450, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack direction={"row"} display={"flex"} justifyContent={"start"} width={'20%'}>
                            <Typography variant="p">
                                {params.row.code}
                            </Typography>
                        </Stack>
                );
            },
        },
        {
            field: "description",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Discription</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("description")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>, width: 450, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack direction={"row"} display={"flex"} justifyContent={"start"} width={'20%'}>
                            <Typography variant="p">
                                {params.row.description}
                            </Typography>
                        </Stack>
                );
            },
        },
        {
            field: "status",
            type: 'singleSelect',
            filterOperators: statusOnlyOperators,
            valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("status")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            width: 450, maxWidth: 650,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack width={'20%'} justifyContent={"space-between"} direction="horizontal">
                            <Stack>
                                <Switch {...label} defaultChecked={params.row.status} onChange={() => handleArchive(params.row)} />
                            </Stack>
                            <Stack className="cellAction">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="country"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)}
                                />
                            </Stack>
                        </Stack>
                );
            },
        },
    ];


    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = limit;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };
    const handleMenuAction = (actionType) => {

        let row = gridRows.find(item => item.id === selectedRow);
        switch (actionType) {
            case 'status':
                handleArchive(row);
                break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    {/* <div className="top-headings new-rtms-heading new-design-icon">
                        <h3>Third Party List</h3>
                        <div>

                            <Tooltip title="Add Third Party">
                                <button onClick={handleOpen} className="new-add-icon">
                                    Add Third Party
                                </button>
                            </Tooltip>
                        </div>
                    </div> */}
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">Third Party List</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Party List">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getThirdPartyList} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} />
                        </Stack>
                    </Stack>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />} */}
                    <Stack direction={'row'} mt={2}>
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={gridRows.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                // Pagination: CustomPagination,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                            }}
                            loading={(status === STATUSES.LOADING || reload === true || reload === 'edit_data')}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooter={true}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                boxShadow: '1px 13px 31px -15px gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                        />
                    </Stack>
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="child-modal-description"
                    PaperComponent={PaperComponent}
                    sx={{ borderRadius: '30px' }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ backgroundColor: "#438bf9", color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>Add Third Party</DialogTitle>

                    <DialogContent>
                        {/* <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Third Party Name
                                    </label>
                                    <input
                                        name="t_name"
                                        defaultValue={formValues.t_name}
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.t_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.status_nameErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Code
                                    </label>
                                    <input
                                        name="code"
                                        defaultValue={formValues.code}
                                        type="text"
                                        placeholder="Code"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.code === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.categoryErr}</p>}
                                </div>

                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formValues.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}
                                    </FormControl>
                                </div>

                                <div className="formInputDescription">
                                    <label >
                                        Description
                                    </label>

                                    <textarea defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>

                                    {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.descriptionErr}</p>}
                                </div>

                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form> */}
                        <form>
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                width={'95%'}
                                m={'auto'}
                            >
                                <Typography variant="subtitle1" fontSize={'18px'}>Third Party Name</Typography>
                                <TextField
                                    sx={{ marginBottom: '10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    fullWidth
                                    name="t_name"
                                    defaultValue={formValues.t_name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange} size="small" id="outlined-basic" variant="outlined"
                                    error={errStatus === true && formValues && formValues.t_name === ""}
                                    helperText={errStatus === true && formValues?.t_name === "" && formErr.status_nameErr}
                                />
                                <Typography variant="subtitle1" fontSize={'18px'}>Code</Typography>
                                <TextField
                                    sx={{ marginBottom: '10px' }}
                                    fullWidth
                                    name="code"
                                    defaultValue={formValues.code}
                                    type="text"
                                    placeholder="Code"
                                    onChange={handleChange} size="small" id="outlined-basic" variant="outlined"
                                    error={errStatus === true && formValues && formValues.code === ""}
                                    helperText={errStatus === true && formValues?.code === "" && formErr.categoryErr}
                                />
                                <Typography variant="subtitle1" fontSize={'18px'}>Discription</Typography>
                                <>
                                    <div className="formInputDescription">
                                        <textarea style={{ width: '100%', borderRadius: '5px', border: errStatus === true && formValues && formValues.description === "" ? '1px solid #d32f2f' : '1px solid #000000de' }} defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>
                                        {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#d32f2f", fontSize: 13 }}>{formErr.descriptionErr}</p>}
                                    </div>
                                </>
                                <Stack justifyContent={"end"} alignItems={"center"} direction={'row'}>
                                    <Typography mr={'25px'} variant="subtitle1" fontSize={'18px'}>
                                        Status
                                    </Typography>
                                    <Typography variant="subtitle2">InActive</Typography>
                                    <Switch name="status" {...label} checked={formValues?.status} onChange={(e) => { handleChange({ target: { value: e.target.checked, name: 'status' } }) }} />
                                    <Typography variant="subtitle2">Active</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"end"} >
                                    <Button variant="contained" color="info" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                                </Stack>

                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>


                {/* for delete popup */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={archiveModalClose}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} third parrty`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

                </Menu>
            </div>
        </div >
    )
}

export default ThirdParty