import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LinearProgress from '@mui/material/LinearProgress';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Dialog, DialogContent, Skeleton, Switch, Stack,Typography, MenuItem, Menu } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkingModeCreate, workingModeEdit, WorkingModeUpdate, getWorkingModeList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { Close, Edit } from "@mui/icons-material";
import { InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses,getGridStringOperators } from "@mui/x-data-grid-pro";
import PaperComponent from "../../components/common-function/PaperComponent"
import StatusInputValue  from "../../components/common-function/StatusOperator"
const skeletonBase = { working_mode: '_skeleton_', working_mode_key: '_skeleton_', status: '_skeleton_' };


const initialFormData = Object.freeze({
    working_mode: "",
    status: true,
});

const formErrData = Object.freeze({
    working_modeErr: "Working Mode is required",
    statusErr: "Status is required"
});






const WorkingMode = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, workingmode_List, workingmode_Create, workingmode_Edit, workingmode_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.WORKINGMODE_LIST_COLUMNS}]}`)
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [gridRows, setGridRows] = useState([]);
    const [skeleton, setSkeleton] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    let stopOnRowScrollEnd = React.useRef(false);
    const [colSortingPerform, setColSortingPerform] = useState([])
    let clearGridListing = React.useRef(false);
    let gridTotalRows = React.useRef(null);
    let editID = React.useRef(null);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null)
    let isAnyChange = React.useRef({})
    const [sort, setSort] = useState(false)

  
    let filterableColumns = ['working_mode', 'status']

    useEffect(() => {
        setReload(true);
        dispatch(getWorkingModeList(filterQuery))
        setSkeleton(() => Array((20)).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    }, [filterQuery])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])



    // update section 
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_workingmode_Update` && workingmode_Update && workingmode_Update.message) {
        if (workingmode_Update.status === 200) {
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            // setFilterQuery(str)
            let copyGridRows = [...gridRows].map(item => {
                if (item?.id === editID.current?.id)
                    return { id: workingmode_Update.data?.id, working_mode: workingmode_Update.data.working_mode, status: workingmode_Update.data.status }
                else
                    return item
            })

            setGridRows(copyGridRows)
            toast.success(workingmode_Update.message)

        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(workingmode_Update.message)
        }
        setReload(false)

    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_workingmode_Create` && workingmode_Create && workingmode_Create.message) {
        if (workingmode_Create.status === 200) {
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            setFilterQuery(str)
            setGridRows([])
            toast.success(workingmode_Create.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(workingmode_Create.message)
        }
        setReload(false)

    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {

        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
        if (name === 'status') {
            setFormValues({ ...formValues, [name]: checked })

        }
        else {
            setFormValues({ ...formValues, [name]: value })

        }
    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.working_mode === "" || formValues.status === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.working_mode !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }

            setErrStatus(false)
            setReload('submit')
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item => item.id === ID);
                let index = copyRows.findIndex(item => item.id === ID);
                copyRows.splice(index, 1, { ...skeletonBase, id: ID });
                setGridRows(copyRows);
                dispatch(WorkingModeUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                setGridRows([]);
                dispatch(WorkingModeCreate(addvalues))
            }


            setSort(false)

        }
    }


    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }
    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && workingmode_Edit && workingmode_Edit.data) {
        let editData = workingmode_Edit.data
        setFormValues({
            working_mode: editData.working_mode,
            working_mode_key: editData.working_mode_key,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(workingModeEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {

      
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            working_mode: data.working_mode,
            working_mode_key: data.working_mode_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }


        let ID = data.id;
        setReload('submit')
        dispatch(WorkingModeUpdate({ ID, addvalues }))
    }






    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }

        setFilterQuery(JSON.stringify(newFilterData))
        setSort(true)
        setGridRows([])
        setSkeleton([])
    }



    const loadServerRows = (viewPortRowLen) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.order_by = { "column": "-id" };
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
        setSkeleton(() => Array((viewPortRowLen)).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))

    }




    const handleOnRowsScrollEnd = (params) => {

        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }

        if (MAX_ROW_LENGTH === null) return;

        if (gridRows.length < MAX_ROW_LENGTH) {
            loadServerRows(params.viewportPageSize);
        }
    };



    const onchangeStatus = (status, data) => {

        stopOnRowScrollEnd.current = true;
        const ID = data?.id
        const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
        let userId = rtm_user?.user?.data.id
        let formValues;
        if (status === true) {
            formValues = { id: data.id, working_mode_key: data.working_mode_key, working_mode: data.working_mode, created_by: userId, assigned_by: userId, status: false }
        } else {
            formValues = { id: data.id, working_mode_key: data.working_mode_key, working_mode: data.working_mode, created_by: userId, assigned_by: userId, status: true }
        }

        clearGridListing.current = true;
        editID.current = { ...data };
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        dispatch(WorkingModeUpdate({ ID, addvalues: formValues }))
        setReload('submit')
    }


    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );

    const actionColumn = [
        {
            field: "working_mode",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: "#1B3764" }}>Working Mode</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("working_mode")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: "#BCC2CE" }} /></button></div>,
            width: 280,
            filterOperators,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.working_mode === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        <div>
                            {params.row.working_mode}
                        </div>
                );
            },
        },
        {
            field: "status",
            sortable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem', color: '#BCC2CE' }} /></button></div>,
            width: 200,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (
                    params?.row?.status === "_skeleton_" ? <Skeleton width={"300px"} />
                        : <div style={{ display: "flex" }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography className={`cellWithStatus ${params.row.status}`}>
                                    {params.row.status === true ? "Active" : "Inactive"}
                                </Typography>
                                <Switch
                                    checked={params.row.status === true ? true : false}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    onChange={() => onchangeStatus(params.row.status, params.row)}
                                />
                            </Stack>
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="working-mode"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)} />
                            </div>
                        </div>
                );
            },
        },
    ];




    const onFilterChange = (props) => {
        setSort(false)
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.WORKINGMODE_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.WORKINGMODE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartswith',
                endsWith: 'iendswith'
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})

                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                         setGridRows([]);
                        
                    }
                    // if no changes on filter open ends

                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter }}));
                  
                }
            }
            return;
        }
    }



    // handle load data response
    if (reload === true && status === `${STATUSES.SUCCESS}`) {
        if (workingmode_List.status === 200) {
            if (clearGridListing.current) {
                sort? setGridRows([...workingmode_List.data.rows])  :setGridRows([...workingmode_List.data.rows]?.sort(item=>item.status? -1:1));
                 clearGridListing.current = false;
                 setSkeletonRows([])
                 SET_MAX_ROW_LENGTH(workingmode_List.data.count);
             }
             else {
                sort ? setGridRows((pre) => [...pre, ...workingmode_List.data.rows]): setGridRows((pre) => [...pre, ...workingmode_List.data.rows].sort(item=>item.status? -1:1));
                 SET_MAX_ROW_LENGTH(workingmode_List.data.count);
                 setSkeletonRows([])
             }
        } else {

            toast.error(workingmode_List.message)
        }
        setSkeleton([]);
        setReload(false)
    }


    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                            <div style={{ fontSize: "20px" }}>
                                <p>Working Mode List</p>

                            </div>
                            <div>
                                <Stack direction="row">
                                    <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                    <RefreshButton api={getWorkingModeList} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort} />
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <DataGridPro
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={gridRows?.concat(skeleton)}
                        onFilterModelChange={onFilterChange}
                        columns={actionColumn}
                        disableSelectionOnClick
                        onSortModelChange={(sort) => handleSort(sort)}
                        hideFooterPagination={true}
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        rowThreshold={.7}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        onRowsScrollEnd={handleOnRowsScrollEnd}
                        hideFooter={true}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: "move" }} id="draggable-dialog-title" >{ID ? 'Edit' : 'Add'} Working Mode</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputTextareaField
                                    label={"Working Mode"}
                                    name="working_mode"
                                    defaultValue={formValues.working_mode}
                                    type="text"
                                    placeholder="Working Mode"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.working_modeErr}
                                />
                                {formValues.id ? <div className="formInput">
                                    <label >
                                        Working Mode Slug*
                                    </label>
                                    <input
                                        readOnly
                                        label={"Working Mode Slug"}
                                        name="working_mode_key"
                                        defaultValue={formValues.working_mode_key}
                                        type="text"
                                        placeholder="Working-Mode-Slug"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""}
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                                    <label style={{ marginRight: '20px', fontWeight: '500' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Working Mode`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                </Menu>
            </div>
        </div >
    )
}

export default WorkingMode;