import "./master.scss";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Skeleton, Stack,Menu, MenuItem,Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IndustryDepSpecCreate,
    IndustryDepSpecEdit,
    IndustryDepSpecUpdate,
    getIndustryDepSpecList,
    getIndustryList,
    getSpecialisationsList,
    getIndustryDepartmentList,
    getAllSpecialisationsList,
} from "../../redux/features/masterSlice";
import { getDepartmentList } from "../../../src/redux/features/departmentSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { Add, Close, Edit } from "@mui/icons-material";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import Filter from "../../components/Filter";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';






const initialFormData = Object.freeze({
    industry: "",
    department: "",
    specialization: "",
    status: true,
});

const formErrData = Object.freeze({
    industryErr: "Industry is required",
    departmentErr: "department is required",
    specialisationsErr: "specialisations is required",
    statusErr: "Status is required",
});

let flag = 1;

const PaperComponent = (props) => {
    return (
        <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}

const SubIndustryDepartmentSpecialisation = ({onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu,  clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu}) => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const [formErr] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const [defaultValue, setDefaultValue] = useState("")
    const [fieldName, setFieldName] = useState("")

    const {
        status,
        error,
        industryDepartmentSpecialisation_List,
        industryDepartmentSpecialisation_Create,
        industryDepartmentSpecialisation_Edit,
        industryDepartmentSpecialisation_Update,
        industry_List,
        specialisations_option_List,
        department_List,
        specialisations_All_List_option,


    } = useSelector((state) => state.master);
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    // const [reload, setReload] = useState(false);

    // const [filterQuery, setFilterQuery] = useState(
    //     `{"filter": {"status": {"value": "True", "operation": "eq" } },"order_by":{"column":"-id"},"skip":0, "limit":25}`
    // );
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });
    const [changeStatus, setChangeStatus] = useState("");
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    useEffect(() => {
        // dispatch(getIndustryDepSpecList(filterQuery));
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getDepartmentList())
        dispatch(getSpecialisationsList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        dispatch(getIndustryDepartmentList(`{"filter":{"status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        // if (status === STATUSES.FAILURE && error) {
        //     setReload(false);
        //     errorHandling(error, navigate);
        // }
        dispatch(getAllSpecialisationsList())
    }, []);

    if (reload === true && status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Update` && industryDepartmentSpecialisation_Update && industryDepartmentSpecialisation_Update.message) {
        if (industryDepartmentSpecialisation_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(industryDepartmentSpecialisation_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(industryDepartmentSpecialisation_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Create` && industryDepartmentSpecialisation_Create && industryDepartmentSpecialisation_Create.message) {
        if (industryDepartmentSpecialisation_Create.status === 200) {
            toast.success(industryDepartmentSpecialisation_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(industryDepartmentSpecialisation_Create.message)
        }
        setReload(false)
    }

    if (flag ===1 && status === `${STATUSES.SUCCESS}_industryDepartmentSpecialisation_Edit` && industryDepartmentSpecialisation_Edit.message) {
        if (industryDepartmentSpecialisation_Edit.status === 200) 
        {
            let editData = industryDepartmentSpecialisation_Edit.data
            setFormValues({
                industry: editData.industry,
                description: editData.description,
                status: editData.status,
                id: editData?.id
            })
            setID(editData.id)
            setOpen(true)
        }
        else
        {
            toast.error(industryDepartmentSpecialisation_Edit.message)
        }
        flag = 0;
    }

    if (reload === true && status === STATUSES.SUCCESS && industryDepartmentSpecialisation_List && industryDepartmentSpecialisation_List.message) {
        if (industryDepartmentSpecialisation_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...industryDepartmentSpecialisation_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(industryDepartmentSpecialisation_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...industryDepartmentSpecialisation_List.data.rows]);
                SET_MAX_ROW_LENGTH(industryDepartmentSpecialisation_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(industryDepartmentSpecialisation_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) {
            setID(0);
            setFormValues(initialFormData);
        }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false);
        setContextMenu(null);
    };

    const handleChangeOnSpecialization = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "specialization": evt });
        } else {
            setFormValues({ ...formValues, "specialization": "" });
        }
    }
    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {

                // setIndustryId(value)
                dispatch(getIndustryDepartmentList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, department: "" })

            }
            else if (name === 'department') {
                setFormValues({ ...formValues, [name]: evt });
            }
            else if (name === 'status') {
                setFormValues({ ...formValues, [name]: evt.target.checked });
            }
            else {
                setFormValues({ ...formValues, [name]: value });
            }
        }
    }

    const submitData = (e) => {
        e.preventDefault();

        if (
            formValues.industry === "" ||
            formValues.department === "" ||
            formValues.specialization === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }
        clearGridListing.current = true;
        let specArray = []
        if (formValues.specialization && formValues.specialization.length > 0) {
            formValues.specialization.map(e => e.value ? specArray.push(e.value) : specArray.push(e))
        }
        if (
            formValues.industry !== "" &&
            formValues.department !== "" &&
            formValues.specialization !== "" &&
            formValues.status !== ""
        ) {
            const rtm_user = JSON.parse(
                localStorage.getItem(`${REACT_APP_ENV}_rtms`)
            );
            let userId =rtm_user?.user?.data.id;
            let addvalues = {
                ...formValues, created_by:
                    userId,
                industry:formValues?.industry.label ? formValues.industry.value : formValues.industry,
                department:formValues?.department.label ? formValues.department.value : formValues.department,
                specialization: specArray

            };

            setErrStatus(false);
            setReload(true);
            handleClose();
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(IndustryDepSpecUpdate({ ID, addvalues }));
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(IndustryDepSpecCreate(addvalues));
            }
        }
    };

    const handleClickEdit = (id) => {
        flag = 1;
        dispatch(IndustryDepSpecEdit(id));
    };

    const handleArchive = (id) => {
        setArchiveId(id);
        setArchiveOpen(true);
        if (id.status === true) {
            setChangeStatus("InActive");
        } else {
            setChangeStatus("Active");
        }
    };
    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

    const handleClickArchive = (data) => {
        setReload(true);
        setArchiveOpen(false);
        let addvalues = {
            industry: data.industry_id,
            department: data.department_id,
            specialization: data.specialization,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
        };
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true);
        dispatch(IndustryDepSpecUpdate({ ID, addvalues }));
    };

    //custom pagination
    // const handlePagination = (data) => {
    //     setCurrentPage(data);
    //     let newFilterData = JSON.parse(filterQuery);
    //     newFilterData["skip"] = data * pageSize;
    //     newFilterData["limit"] = limit;
    //     setFilterQuery(JSON.stringify(newFilterData));
    //     dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
    // };

    // const handlePageSizeChange = (newPageSize) => {
    //     setPageSize(newPageSize.target.value);
    //     setCurrentPage(0);
    //     setLimit(newPageSize.target.value);
    //     let newFilterData = JSON.parse(filterQuery);
    //     newFilterData["skip"] = 0;
    //     newFilterData["limit"] = newPageSize.target.value;
    //     setFilterQuery(JSON.stringify(newFilterData));
    //     dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
    // };

    // function CustomPagination() {
    //     return (
    //         <TablePagination
    //             component="div"
    //             count={
    //                 (industryDepartmentSpecialisation_List &&
    //                     industryDepartmentSpecialisation_List.data &&
    //                     industryDepartmentSpecialisation_List.data.rows &&
    //                     industryDepartmentSpecialisation_List.data.count) ||
    //                 0
    //             }
    //             page={currentPage}
    //             rowsPerPage={pageSize}
    //             rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
    //             onPageChange={() => setCurrentPage(pageSize)}
    //             onRowsPerPageChange={handlePageSizeChange}
    //             ActionsComponent={(subProps) => (
    //                 <TablePaginationActions
    //                     {...subProps}
    //                     handlePagination={handlePagination}
    //                 />
    //             )}
    //         />
    //     );
    // }
    const getUnique = (arr, index) => {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    // sorting
    // const handleSort = (sort) => {
    //     if (sort.length) {
    //         let newFilterData = JSON.parse(filterQuery);
    //         newFilterData["skip"] = 0;
    //         newFilterData["limit"] = limit;
    //         newFilterData["order_by"] = {
    //             column: sort[0].sort === "desc" ? "-" + sort[0].field : sort[0].field,
    //         };
    //         setFilterQuery(JSON.stringify(newFilterData));
    //         dispatch(getIndustryDepSpecList(JSON.stringify(newFilterData)));
    //     } else {
    //         setFilterQuery(
    //             `{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" }}`
    //         );
    //     }
    // };

    const actionColumn = [
        {
            field: "industry__industry_name",
            headerName: "Industry Name",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Industry Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("industry__industry_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 400,
            sortable: false,
            pinnable: false,

            renderCell: (params) => {
                return params.row.industry__industry_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
                <div>{params.row?.industry?.industry_name}
                </div>;

            },
        },

        {
            field: "department__name",
            headerName: "Department Name",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Department Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("department__name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 400,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return params.row.department__name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :

                 <div>{params.row?.department?.name}</div>;
            },
        },
        {
            field: "specialization",
            headerName: "Specialisation",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Specialisation</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("specialization")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 400,
            sortable: false,
            pinnable: false,
            type: 'singleSelect',
            valueOptions: specialisations_All_List_option,
            renderCell: (params) => {
                return params.row.specialization === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :

                 <div>
                    {params.row.specialization_data?.name}
                    <CustomeTooltip
                        title={params.row.specialization_data && params.row.specialization_data.length > 0 ? getUnique(params.row.specialization_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                        data={params.row.specialization_data && params.row.specialization_data.length > 0 ? getUnique(params.row.specialization_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                    />
                </div>;
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    <Switch  name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)}/>
                    <div className="actionButton">
                    <UcActionComponent
                        deleteIconName="ArchiveIcon"
                        moduleName="hiring-mode"
                        rowid={params?.row?.id}
                        addMoreData=""
                        editLinkUrl=""
                        viewLinkUrl=""
                        isDelete={true}
                        editButton={handleClickEdit}
                        viewButton={false}
                        deleteButton={() => handleArchive(params.row)}
                    />
                    </div>
                </div>
                );
            },
        },
    ];

    // const onFilterChange = (filterModel, r) => {

    //     if(filterModel.items.length)
    //     {
    //         setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
    //     }

    //     if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
    //     {
    //         setReload(true);
    //         setTimeout(()=>
    //         {
    //             setReload(false)
    //             setCurrentPage(0);
    //             //setPageSize(10)
    //             setFilterQuery(
    //                 `{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
    //             );
    //             dispatch(
    //                 getIndustryDepSpecList(
    //                     `{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`
    //                 )
    //             );
    //             setDefaultValue("")
    //         },0);
    //     }

    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
    //         setFieldName(filterModel.items[0].columnField)
    //     }
    //     if (
    //         filterModel.items &&
    //         filterModel.items.length > 0 &&
    //         filterModel.items[0].columnField &&
    //         filterModel.items[0].value
    //     ) {
    //         let operatorValue =
    //             filterModel.items[0].operatorValue === "contains"
    //                 ? "icontains"
    //                 : filterModel.items[0].operatorValue === "startsWith"
    //                     ? "istartswith"
    //                     : filterModel.items[0].operatorValue === "endsWith"
    //                         ? "iendswith"
    //                         : filterModel.items[0].operatorValue === "is"
    //                             ? 'eq'
    //                             : filterModel.items[0].operatorValue;

    //         let filterData
    //         if (filterModel.items[0].columnField === 'specialization') {
    //             filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":[${filterModel.items[0].value}],"operation": "contains"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
    //         } else {
    //             filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
    //         }

    //         setFilterQuery(filterData);
    //         setCurrentPage(0);
    //         dispatch(getIndustryDepSpecList(filterData));
    //     }
    // };

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "specialization" ?
                <Filter options={specialisations_option_List} onChange={handleFilterChange} defaultValue={defaultValue} />
                : ""
        );
    }

    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "specialization") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }), [actionColumn]
    );

    let i = 0
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Industry Department Specialization List</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getIndustryDepSpecList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                        <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                ".actionButton": {
                                    display: 'block'
                                }
                                }
                            }
                        }}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>
                        <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Industry Department Specialisations</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChange}

                                        defaultValue={formValues.industry}
                                        value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                             industry_List?.data?.rows.map((option) => {
                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>

                                {
                                    formValues.industry ?
                                        <div className="formInput">

                                            <label >
                                                Select Department
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // divstyle={{ flexBasis: '50%' }}
                                                placeholder={'Select Department'}
                                                name={"department"}
                                                onChange={handleChange}
                                                defaultValue={formValues.department}
                                                value={formValues.department}
                                                isSearchable={true}
                                                options={
                                                     department_List?.map((option) => {

                                                        return {
                                                            label: option.label,
                                                            value: option.value,
                                                            name: "department"
                                                        }
                                                    })

                                                }
                                            />
                                            {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }

                                {
                                    formValues.department ?
                                        <div className="formInput">
                                            <label >
                                                Specialization
                                            </label>
                                            <Select
                                                isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Specialization"
                                                defaultValue={formValues.specialization}
                                                // value={formValues.department}
                                                isSearchable={true}
                                                name={"specialization"}
                                                options={
                                                    specialisations_option_List?.map((option) => {
                                                        return { label: option.label, value: option.value, name: "specialization" }
                                                    })
                                                }
                                                onChange={handleChangeOnSpecialization}
                                            // onChange={handleChange}
                                            />
                                            {errStatus === true && formValues && formValues.specialization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.specialisationsErr}</p>}
                                        </div>
                                        : <></>
                                }
                                    <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>
                                {ID
                                    ? CONSTANTS.COMMON_BUTTONS.UPDATE
                                    : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                            
                            {/* </div> */}

                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Add`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
                    <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>
            
                </Menu>
            </div>
        </div>
    );
};

const  IndustryDepartmentSpecialisation = DataGridProLazyLoadHOC(SubIndustryDepartmentSpecialisation, {defaultFilter:`{"order_by":{"column":"-status"},"skip":0, "limit":25}`, getDispatcherAsyncThunk:getIndustryDepSpecList, gridColumns:['industry__industry_name', 'department__name', 'status']});






export default IndustryDepartmentSpecialisation;
