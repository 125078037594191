import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

//////Source type apis///////
export const getAllSourceTypeList = createAsyncThunk("sourcetype/all-list", async (data) => {
    const res = await dataService.all_sourcetype_list(data);
    return res.data;
});

export const getSourceTypeList = createAsyncThunk("sourcetype/list", async (data) => {
    const res = await dataService.source_type_list(data);
    return res.data;
});

export const sourceTypeCreate = createAsyncThunk("sourceType/create", async (data) => {
    const res = await dataService.source_type_create(data);
    return res.data;
});

export const sourceTypeEdit = createAsyncThunk("sourceType/edit", async (id) => {
    const res = await dataService.source_type_edit(id);
    return res.data;
});

export const sourceTypeUpdate = createAsyncThunk("sourceType/update", async (data) => {
    const res = await dataService.source_type_update(data.ID, data.addvalues);
    return res.data;
});

export const sourceTypeArchive = createAsyncThunk("sourceType/archive", async (id) => {
    const res = await dataService.source_type_archive(id);
    return res.data;
});


//////Communication type apis///////
export const getAllCommunicationTypeList = createAsyncThunk("communicationtype/all-list", async (data) => {
    const res = await dataService.all_communicationtype_list(data);
    return res.data;
});

export const getCommunicationTypeList = createAsyncThunk("communicationtype/list", async (data) => {
    const res = await dataService.communication_type_list(data);
    return res.data;
});

export const communicationTypeCreate = createAsyncThunk("communicationType/create", async (data) => {
    const res = await dataService.communication_type_create(data);
    return res.data;
});

export const communicationTypeEdit = createAsyncThunk("communicationType/edit", async (id) => {
    const res = await dataService.communication_type_edit(id);
    return res.data;
});

export const communicationTypeUpdate = createAsyncThunk("communicationType/update", async (data) => {
    const res = await dataService.communication_type_update(data.ID, data.addvalues);
    return res.data;
});

export const communicationTypeArchive = createAsyncThunk("communicationType/archive", async (id) => {
    const res = await dataService.communication_type_archive(id);
    return res.data;
});
const marketingMasterSlice = createSlice({
    name: "marketingMasterSlice",
    initialState: {
        sourceType_AllList: [],
        sourceType_Create: [],
        sourceType_List: [],
        sourceType_Edit: [],
        sourceType_Update: [],
        sourceType_Archive: [],

        communicationType_AllList: [],
        communicationType_Create: [],
        communicationType_List: [],
        communicationType_Edit: [],
        communicationType_Update: [],
        communicationType_Archive: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //////////sourceType store section/////////
            ///all sourceType  list
            .addCase(getAllSourceTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllSourceTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.sourceType_AllList = action.payload;
            })
            .addCase(getAllSourceTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd sourceType list
            .addCase(getSourceTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSourceTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.sourceType_List = action.payload;
            })
            .addCase(getSourceTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///sourceType create
            .addCase(sourceTypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(sourceTypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_sourceType_Create`;
                state.sourceType_Create = action.payload;
            })
            .addCase(sourceTypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///sourceType edit
            .addCase(sourceTypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(sourceTypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.sourceType_Edit = action.payload;
            })
            .addCase(sourceTypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///sourceType Update
            .addCase(sourceTypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(sourceTypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_sourceType_Update`;
                state.sourceType_Update = action.payload;
            })
            .addCase(sourceTypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///sourceType archive
            .addCase(sourceTypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(sourceTypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_sourceType_Archive`;
                state.sourceType_Archive = action.payload;
            })
            .addCase(sourceTypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////communicationType store section/////////
            ///all communicationType  list
            .addCase(getAllCommunicationTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllCommunicationTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.communicationType_AllList = action.payload;
            })
            .addCase(getAllCommunicationTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd communicationType list
            .addCase(getCommunicationTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCommunicationTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.communicationType_List = action.payload;
            })
            .addCase(getCommunicationTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///communicationType create
            .addCase(communicationTypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(communicationTypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_communicationType_Create`;
                state.communicationType_Create = action.payload;
            })
            .addCase(communicationTypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///communicationType edit
            .addCase(communicationTypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(communicationTypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.communicationType_Edit = action.payload;
            })
            .addCase(communicationTypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///communicationType Update
            .addCase(communicationTypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(communicationTypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_communicationType_Update`;
                state.communicationType_Update = action.payload;
            })
            .addCase(communicationTypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///communicationType archive
            .addCase(communicationTypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(communicationTypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_communicationType_Archive`;
                state.communicationType_Archive = action.payload;
            })
            .addCase(communicationTypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    }
});


export default marketingMasterSlice.reducer;