import React from 'react'

function BudgetLog({ data }) {
    const createBudgetLogStatus = Object.freeze({
        4: "PENDING",
        5: "APPROVED",
        6: "CANCELLED",
        7: "REJECTED"
    })
    return (
        <div style={{ width: '100%', margin: '5px' }}>
            <table>
                <thead>
                    <tr className="revised-table-header">
                        <th>Previous Salary Budget From</th>
                        <th>Previous Salary Budget To</th>
                        <th>Salary Budget From</th>
                        <th>Salary Budget To</th>
                        <th>Reason for revising the budget</th>
                        <th>Reason for approval the budget</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.length > 0 ? (
                        data.map(row => (
                            <tr key={row.id} style={{ textAlignLast: "center" }}>
                                <td>{row.previous_from_salary_per_annum || "N/A"}</td>
                                <td>{row.previous_to_salary_per_annum || "N/A"}</td>
                                <td>{row.from_salary_per_annum || "N/A"}</td>
                                <td>{row.to_salary_per_annum || "N/A"}</td>
                                <td>{row.reason_for_revise || "N/A"}</td>
                                <td>{row.approver_comment || "N/A"}</td>
                                <td>
                                    {createBudgetLogStatus[row.approval_status] ? createBudgetLogStatus[row.approval_status] : "N/A"}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr style={{ textAlignLast: "center" }}>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                    )}
                </tbody>

            </table>
        </div>
    )
}

export default BudgetLog