import { memo, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../CustomEmptyOverlay";
import CustomeTooltip from "../../../common-function/CustomeTooltip";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "../../../TablePaginationActions";

const QuestionTableList = ({ existingData, rowCount, testTypes, parentIndex, setFormData, formData, filterQuery, setFilterQuery, setDisabledAddButton, setShowAddForm, selectionModel, setSelectionModel, prevColIds, currentPage, setCurrentPage, limit, setLimit, isRedirection, isPagination, loader, stopAutoCheckOnOpen }) => {

    //pagination 
    const [pageSize, setPageSize] = useState(10);

    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    let duoFlagRedirection = useRef(false);
    const columns = [
        // {
        //     field:"question_id",
        //     headerName: 'Question Id',
        //     width:100
        // },
        {
            field: 'question__title',
            headerName: 'Question Title',
            width: 400,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <CustomeTooltip
                            title={params.row.question?.title ? params.row.question.title : "-"}
                            data={params.row.question?.title ? params.row.question.title : "-"}
                        />
                    </div>
                )
            }
        },
        {
            field: 'question__question_level__level_key',
            headerName: 'Question level',
            width: 200,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question?.question_level && params.row.question.question_level.level_name ? params.row.question.question_level.level_name : "-"}
                    </div>
                )
            }

        },
        {
            field: 'question__technology__technology_key',
            headerName: 'Technology',
            width: 205,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question?.technology && params.row.question.technology.name ? params.row.question.technology.name : "-"}
                    </div>
                )
            }

        },
        {
            field: 'question__qusetion_type__type_key',
            headerName: 'Question Type',
            width: 200,
            filterable: false,
            sortable: false,
            // type: 'singleSelect',
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.question?.question_type && params.row.question.question_type.question_type ? params.row.question.question_type.question_type : "-"}
                    </div>
                )
            }
        },

    ];


    const onSelectionChange = (idArr) => {

        if (duoFlagRedirection.current === true) {
            duoFlagRedirection.current = false
            return;
        }

        prevColIds.current = { ...prevColIds.current, 'selected': idArr }

        //condition
        let evts = [...new Set(prevColIds.current['selected'])]

        if (evts.length > idArr.length) {
            evts = evts.filter(item => idArr.includes(item));
        }

        setSelectionModel(evts);

        if (Number(testTypes[parentIndex].no_of_predefined_questions) === Number(evts.length)) {
            setShowAddForm(false)
            setDisabledAddButton(true)
            testTypes[parentIndex]['predefined_questions'] = {
                ...formData.predefined_questions,
                already_selected_question: evts
            }
            setFormData({
                ...formData,
                predefined_questions: {
                    ...formData.predefined_questions,
                    already_selected_question: evts
                }
            })
        } else if (Number(testTypes[parentIndex].no_of_predefined_questions) < Number(evts.length)) {
            setDisabledAddButton(true)
            toast.error(`Your total no of prdefine question are Number(${testTypes[parentIndex].no_of_predefined_questions}) is required`, {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            testTypes[parentIndex]['predefined_questions'] = {
                ...formData.predefined_questions,
                already_selected_question: evts
            }
            setFormData({
                ...formData,
                predefined_questions: {
                    ...formData.predefined_questions,
                    already_selected_question: evts
                }
            })
            setDisabledAddButton(false)
        }

    }

    //custom pagination
    const handlePagination = (data) => {
        let customLimit = pageSize
        setCurrentPage(data)
        let newFilterData = filterQuery
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = customLimit
        setLimit(customLimit)
        setFilterQuery({ ...filterQuery, ...newFilterData })

        // setSelectionModel([...selectionModel, prevColIds.current['page'+currentPage-1]])
        setSelectionModel([...selectionModel, ...[...new Set(prevColIds.current['selected'])]])
        isPagination.current = true;
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery({ ...filterQuery, ...newFilterData })
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(rowCount && rowCount) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue;
            const filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue } }, "skip": 0, "limit": limit }
            setFilterQuery(filterData)
            setCurrentPage(0)
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery(filterQuery)
        }
    }

    useEffect(() => {
        if (loader === false)
            isPagination.current = false;
    }, [loader])

    return <>
        <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }}>

            <DataGrid
                // className="datagrid"
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                rows={existingData}
                columns={columns}
                loading={loader}
                style={{ fontSize: 16 }}
                onFilterModelChange={onFilterChange}
                components={{
                    Pagination: loader ? null : CustomPagination,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={pageSize}
                checkboxSelection
                selectionModel={[...new Set(selectionModel)]}
                onSelectionModelChange={(arr) => {
                    if (isRedirection.current === true) {
                        isRedirection.current = false;
                        duoFlagRedirection.current = true;
                        return;
                    }

                    if (isPagination.current === true) {
                        return;
                    }

                    if (stopAutoCheckOnOpen.current === true) {
                        return;
                    }

                    onSelectionChange(arr)
                }}

                disableSelectionOnClick
                filterMode="server"
                className="style-datatable-existing-qusetion"

            />

        </div>

    </>
}
export default memo(QuestionTableList)