import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import Paper from '@mui/material/Paper';

function AttendanceDetails({ attendaceDetails }) {

    const toHoursAndMinutes = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);
        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>In Time</TableCell>
                            <TableCell>Out Time</TableCell>
                            <TableCell>Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attendaceDetails.map((row, i) => (
                            <TableRow key={i} >
                                <TableCell> {moment(row.resource_attendance.punchin_date).format('ll')}</TableCell>
                                <TableCell>{row.punchin_time}</TableCell>
                                <TableCell>{row.punch_out_time}</TableCell>
                                <TableCell align="right">{toHoursAndMinutes(row.durations)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AttendanceDetails