import React, { useEffect, useState } from 'react'
import { Rating } from "@mui/material";
import { getAllHeadsList } from '../../redux/features/hiringRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { InputMultiSelectField } from '../../components/common-function/InputFields';
import CreatableSelect from 'react-select/creatable';

import LoaderForm from '../../components/common-function/LoaderForm';

const AddFeedBackform = ({ formValues, headData, handleChangeFeedBack, setAddHeadData, errStatus }) => {

    const dispatch = useDispatch()
    const { heads_List } = useSelector(state => state.hiringRequest)
    const [headsId, setHeadsId] = useState([])
    const [reload, setReload] = useState(true)

    useEffect(() => {
        dispatch(getAllHeadsList())
        setTimeout(() => {
            setReload(false)
        }, 1000);
    }, [])

    const handleHeadChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = headsId.length > 0 && headsId.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setHeadsId([...valueArray])
        index !== null && setAddHeadData([...valueArray])
    }

    const addMoreHead = (evt) => {
        let array = []
        evt && evt.map((e, i) => {
            let filterExp = headsId.filter(x => x.interview_feedback_head === e.value)
            return array.push({
                status: true,
                interview_feedback_head: e.__isNew__ ? { new: true, value: e.label } : e.value,
                sequence_number: i + 1,
                rating_comment: filterExp.length > 0 ? filterExp[0].rating_comment : "",
                rating_value: filterExp.length > 0 ? Number(filterExp[0].rating_value) : "",
                interview_feedback_head__head_name: e.label
            })
        })

        setTimeout(() => {
            setHeadsId(array)
            setAddHeadData(array)
        }, 500);

    }

    return (
        <div>
            <table>
                <thead></thead>
                {reload === true ? <LoaderForm /> :
                    <tbody>
                        {/* <tr><th>Specific feedback like :</th></tr> */}

                        {headData && headData.length > 0 ? headData.map((item, index) => {
                            return (

                                <>
                               

                                <tr key={index} >
                                    <td classname="text-dark">{item.interview_feedback_head__head_name}  </td>
                                    <td>
                                        <Rating
                                            // value={formValues.ele}
                                            name={'rating_value'}
                                            onChange={(evt) => handleChangeFeedBack(evt, index)}
                                            precision={0.1}
                                        />
                                    </td>
                                    <td>
                                        <div className='formInput'>
                                            <textarea
                                                // defaultValue={formValues.ele}
                                                name={"rating_comment"}
                                                onChange={(evt) => handleChangeFeedBack(evt, index)}
                                                rows={2}
                                                placeholder={'comment'}>
                                            </textarea>
                                        </div>
                                    </td>
                                </tr>
                                </>
                            )
                        }) :
                            <>
                                <div className="formInput">
                                    <label>Select heads ,or add new heads</label>
                                    <CreatableSelect
                                        // value={formValues.head_ids}
                                        onChange={addMoreHead}
                                        isMulti
                                        isClearable
                                        name="head_ids"
                                        placeholder="Select heads ,or add new heads"
                                        options={
                                            heads_List && heads_List.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.value,
                                                    name: "head_ids"
                                                }
                                            })
                                        } />

                                </div>
                                {
                                    headsId && headsId.map((item, i) => {
                                        return (
                                            <>
                                            <div class="row" style={{display:'flex'}}>
                                                <div className='col'>
                                                    <p>{item.interview_feedback_head__head_name} </p>
                                                    <Rating
                                                        // value={formValues.ele}
                                                        name={'rating_value'}
                                                        onChange={(e) => handleHeadChange(e, i)}
                                                        precision={0.1}
                                                    />
                                                    {errStatus === true && !item.rating_value && <p style={{ color: "#96332c", fontSize: 10 }}>{'This field is required'}</p>}
                                                </div>
                                                <div className='col'>
                                                <div className='formInput'>
                                                        <p style={{ color: "#96332c", fontSize: 10, marginLeft:'280px' }}>*</p>

                                                        <textarea
                                                            defaultValue={formValues.ele}
                                                            name={"rating_comment"}
                                                            onChange={(e) => handleHeadChange(e, i)}
                                                            rows={2}
                                                            placeholder={'comment'}>
                                                        </textarea>
                                                        {errStatus === true && !item.rating_comment && <p style={{ color: "#96332c", fontSize: 10 }}>{'This field is required'}</p>}

                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </tbody>}
            </table>

        </div>
    )
}
export default AddFeedBackform
