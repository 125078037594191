import React, { useState, useEffect, useRef } from 'react'
import "./manageshift.scss";
// import { shiftColumns } from "../../datatablesource";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
// import DynamicTable from '../../components/dynamic-table/DynamicTable';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Paper, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Refresh } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import { useDispatch, useSelector } from 'react-redux';
import { defaultshiftColumn } from '../../redux/features/projectSlice';
import { Close } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import Draggable from 'react-draggable';
import LoaderForm from "../../components/common-function/LoaderForm";
import dataService from '../../redux/services/data.service';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
const skeletonBase = { shift_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', start_time: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', end_time: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status_value: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };


const default_shift_Columns = LocalStorageDataService.get_default_shift_column()

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-modal"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} />
    </Draggable>
  );
}


const AllShifts = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [shiftRows, setShiftRows] = useState([])
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  const [pageSize, setPageSize] = useState(25);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [open, setOpen] = useState(false);
  const [ID, setID] = useState("");
  // const editFormData = useLocation().state;
  const [editData, setEditData] = useState()
  const [formData, setFormData] = useState({ "created_by": LocalStorageDataService.userDetail().id, } || { "created_by": LocalStorageDataService.userDetail().id, });
  const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const { defaultshiftColumns } = useSelector(state => state.projects)
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [gridRows, setGridRows] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":"[${default_shift_Columns?.columnVisibilityModel}]"}`)
  const [editLable, setEditLable] = useState(false)
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');
  const [archiveId, setArchiveId] = useState(0)
  const [viewModal, setViewModal] = useState();
  const [showViewModal, setShowViewModal] = useState(false);
  const [dialogeTitle, setDialogeTitle] = useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_shift_Columns && default_shift_Columns.all_shift_column ? default_shift_Columns.all_shift_column : {
    id: false,   
    timing_display: false,
  });

  const loadDataById = async (id) => {
    const response = await dataService.shiftBYId(id).catch(error => {
      toast.error(error.message)
      return error;
    });
    if (response && response.data && response.data.data) {
      setEditData(response.data.data)
    }
  }

  useEffect(() => {
    if (editData?.id) {
      loadDataById(editData?.id)
    }
  }, [])


  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClickArchive = async (data) => {
    setArchiveOpen(false)
    setLoading(true)
    let addvalues = {
      description: data.description,
      end_time: data.end_time,
      id: data.id,
      shift_key: data.shift_key,
      shift_name: data.shift_name,
      start_time: data.start_time,
      status: !data.status,
      time_zone: data.time_zone,
      timing_display: "24Hours",
      updated_by: LocalStorageDataService.userDetail().id,

    }
    const res = await api.put(`/mst/shift/${data.id}`, addvalues)
    if (res && res.data && res.data.data && res.status === 200) {
      toast.success(res.data.message)
      loadShifts();
    }
  }

  const shiftColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "shift_name",
      headerName: "Shift",
      width: 450, minWidth: 200, maxWidth: 500,
      renderCell: (params) => {
        return (
          // <div className="cellWithImg">
          //   <img className="cellImg" src={params.row.img} alt="avatar" />
          //   {params.row.shiftName}
          // </div>
          params.row.shift_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={250} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.shift_name}</Typography>
            </div>
        );
      },

      //     created_by: 1
      // ​​​
      // duration: 2
      // ​​​
      // id: 1
      // ​​​
      // shift_key: "1"
      // ​​​
      // shift_name: "Shift 1"
      // ​​​
      // start_time: "2022-09-21T05:38:24Z"
      // ​​​
      // status: true
      // ​​​
      // timing_display: "2hrs"
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 150,
    // },
    {
      field: "start_time",
      headerName: "From Time",
      width: 450, minWidth: 200, maxWidth: 500,
      renderCell: (params) => {
        return (
          params.row.start_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={250} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.start_time}</Typography>
            </div>
        );
      }
    },
    {
      field: "end_time",
      headerName: "To Time",
      width: 450, minWidth: 200, maxWidth: 500,
      renderCell: (params) => {
        return (
          params.row.end_time === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={250} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.end_time}</Typography>
            </div>
        );
      }
    },
    // {
    //   field: "timeZone",
    //   headerName: "Time Zone",
    //   width: 150,
    // },
    {
      field: "status_value",
      headerName: "Status",

      type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      width: 450, minWidth: 200, maxWidth: 500,
      renderCell: (params) => {
        return (
          params.row.status_value === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={250} /> :
            <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} >
              {/* <div className={`cellWithStatus ${params.row.status}`}> */}
              {/* <Typography variant="p">{params.row.status === 3 ? "Archive" : params.row.status === true ? "Active" : "Inactive"}</Typography> */}
              <Switch {...label} defaultChecked={params.row.status} onChange={() => handleArchive(params.row)} />
              {/* </div> */}
              <div className="cellAction">
                <UcActionComponent
                  moduleName="dynamic_table"
                  rowid={params.row.id}
                  addMoreData={params.row}
                  editLinkUrl=""
                  viewLinkUrl=""
                  isDelete={!!deleteShift}
                  editButton={() => editShift(params.row)}
                  viewButton={() => viewShift(params.row)}
                  deleteButton={() => deleteShift(params.row)}
                  // additionalAction={additionalAction}
                  // extraButton={onAdditionalAction}
                  deleteIconName="ArchiveIcon"
                />
              </div>
            </Stack>
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: "90",
    //   hideable: false,
    //   filterable: false,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellAction">
    //         <UcActionComponent
    //           moduleName="dynamic_table"
    //           rowid={params.row.id}
    //           addMoreData={params.row}
    //           editLinkUrl=""
    //           viewLinkUrl=""
    //           isDelete={!!deleteShift}
    //           editButton={() => editShift(params.row)}
    //           viewButton={() => viewShift(params.row)}
    //           deleteButton={() => deleteShift(params.row)}
    //         // additionalAction={additionalAction}
    //         // extraButton={onAdditionalAction}
    //         // deleteIconName="ArchiveIcon"
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleOpen = () => {
    setShowViewModal(false)
    setEditData({
      description: '',
      end_time: '',
      id: '',
      shift_key: '',
      shift_name: '',
      start_time: '',
      status: true,
      status_value: '',
      time_zone: ''
    })
    setDialogeTitle('Add New Shift')
    setEditLable(false)
    setOpen(true)
  };

  const handleClose = () => {
    setEditData({
      description: '',
      end_time: '',
      id: '',
      shift_key: '',
      shift_name: '',
      start_time: '',
      status: '',
      status_value: '',
      time_zone: ''
    })
    setOpen(false)
    setContextMenu(null)
  }


  const handleFilter = (filterParamsArray, r) => {
    if (filterParamsArray.items.length) {
      setFilterClosingFields(() => ({ columnField: filterParamsArray.items[0].columnField, operatorValue: filterParamsArray.items[0].operatorValue }))
    }
  }

  const handleMenuAction = (actionType) => {
    let row = shiftRows.find(item => item.id === selectedRow);
    switch (actionType) {
      case 'edit_shift':
        setEditLable(true)
        setShowViewModal(false)
        editShift(row)
        break;
      case 'view_shift':
        viewShift(row)
        setShowViewModal(true)
        break;
      case 'delete_shift':
        deleteShift(row)
        break;
      default:
        return;
    }
    setContextMenu(null);
  }

  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = {};
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }

    setGridRows([]);
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    // loadProjects(newFilterData, false, true)
    // loadResources()
    clearGridListing.current = true;
  }

  const setKeyForList = (obj) => {
    let data;
    if (default_shift_Columns) {
      data = { 'all_shift_column': obj, 'reported_shift_column': default_shift_Columns.reported_shift_column, 'all_projectlist_column': default_shift_Columns.all_projectlist_column, 'task_column': default_shift_Columns.task_column }
    } else {
      data = { 'all_shift_column': obj }
    }
    if (defaultshiftColumns.reported_shift_column) {
      data['reported_shift_column'] = defaultshiftColumns.reported_shift_column
    }
    if (defaultshiftColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultshiftColumns.all_projectlist_column
    }
    if (defaultshiftColumns.task_column) {
      data['task_column'] = defaultshiftColumns.task_column
    }
    dispatch(defaultshiftColumn(data))
  }

  // Load All Shifts

  const loadShifts = async () => {
    setLoading(true);
    const data = { ...params, "skip": skip, "limit": pageSize || 25, "order_by": { "column": "-status" }, "columns": DefaultFilterColumns.SHIFT_LIST_COLUMNS }
    const res = await api.get(`/mst/shift?where=${JSON.stringify(data)}`)
    if (res && res.data && res.data.data) {
      setShiftRows(res.data.data.rows.map(e => ({ ...e, status_value: e.status ? "True" : "False" })))
      setRowCount(res.data.data.count)
    }
    setLoading(false);
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = shiftRows?.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (shiftRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };


  // Delete Shift
  const deleteShift = async (data) => {
    setLoading(true)
    let addvalues={
        description:data.description,
        end_time:data.end_time,
        id:data.id,
        shift_name: data.shift_name,
        start_time:data.start_time,
        status:!data.status,
        time_zone:data.time_zone,
        timing_display:"24Hours",
        updated_by: LocalStorageDataService.userDetail().id,        
        
    }
    setLoading(true)
    const res = await api.put(`/mst/shift/${data.id}`, addvalues)
    if (res && res.data && res.data.data && res.status === 200) {
      toast.success(res.data.message)
      loadShifts();
    }
  }

  // Edit Shift
  const editShift = (data) => {
    setShowViewModal(false)
    setDialogeTitle('Edit Shift')
    setEditData({
      description: data.description,
      end_time: data.end_time,
      id: data.id,
      shift_key: data.shift_key,
      shift_name: data.shift_name,
      start_time: data.start_time,
      status: data.status,
      status_value: data.status_value,
      time_zone: data.time_zone
    })
    setOpen(true)
  }

  // View Shift
  const viewShift = async (data) => {
    setDialogeTitle('View Shift')
    setLoading(true)
    setShowViewModal(true)
    setOpen(true)
    const response = await dataService.shiftBYId(data.id).catch(error => {
      setLoading(false)
      toast.error(error.message)
      return error;
    });
    if (response && response.data && response.data.data) {
      setViewModal(response.data.data)
      setLoading(false)
      ViewShiftData();
    } else {
      setLoading(false)
    }

  }


  function ViewShiftData() {
    return (
      <Card variant='outlined' sx={{ border: 'none' }}>
        <CardContent>
          <Stack width={'100%'} justifyContent={'space-around'} direction={'row'}>
            <Stack>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Shift Name :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Shift Key :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Start Time :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>End Time :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Shift Id :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Status :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Timing Display :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Time Zone :</Typography>
              <Typography m={1} variant='subtitle1' fontWeight={'600'}>Description :</Typography>
            </Stack>
            <Stack>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.shift_name ? viewModal.shift_name : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.shift_key ? viewModal.shift_key : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.start_time ? viewModal.start_time : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.end_time ? viewModal.end_time : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.id ? viewModal.id : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.status ? "Active" : "Inactive"}</Typography>
              <Typography display={'flex'} m={1} variant='subtitle1'>
                {viewModal && viewModal.duration ? parseInt(viewModal.duration / 60) ? parseInt(viewModal.duration / 60) + " Hours "
                  // + viewModal.duration%60 + "Minutes" 
                  : "" : ""}
                {(viewModal && viewModal.duration && parseInt(viewModal.duration % 60)) ? parseInt(viewModal.duration % 60) + " Minutes " : ""}
              </Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.time_zone ? viewModal.time_zone : "N/A"}</Typography>
              <Typography m={1} variant='subtitle1'>{viewModal && viewModal.description ? viewModal.description : "N/A"}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  }
  useEffect(() => {
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    loadShifts();
    // eslint-disable-next-line
  }, [skip, pageSize, params])

  const handleChange = (evt) => {
    let { name, type, value, checked } = evt.target;
    value = type === "checkbox" ? checked : type === "time" ? value.length < 8 ? value + ":00" : value : value;
    setFormData({ ...editData, ...formData, [name]: value })
  }

  const handleSubmit = async (evt) => {
    setLoading(true)
    evt.preventDefault();
    const res = editData.id ? await api.put("/mst/shift/" + formData.id, { ...formData, timing_display: "24Hours", created_by: LocalStorageDataService.userDetail().id }) : await api.post("/mst/shift", { ...formData, timing_display: "24Hours", created_by: LocalStorageDataService.userDetail().id })
    if (res) {
      if (res.data && res.data.data) {
        toast.success(res.data.message);
        loadShifts()
        // setTimeout(() => {
        //   navigate("/all-shift", { replace: true })
        //   setLoading(false)
        // }, 3000);
      }
      else {
        toast.error(res.data.message)
        setLoading(false)
      }

    }
  }

  return (
    <div className="new">
      <div className="newContainer">
        {/* <div className="top-headings">
          <h3>All Shifts</h3>
          <div>
            <button onClick={() => loadShifts()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
            <Tooltip title="Add New Shift ">
              <Link to="/add-new-shift" className="btn-add-new ">
                <AddIcon />
              </Link>
            </Tooltip>
          </div>
        </div> */}
        <div className="datatable">
          {/* <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
            <Stack direction={'row'}>
              <Typography className="headerTitleName" variant="h3">All Shifts</Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton color="primary" aria-label="Reload Refresh" onClick={() => loadShifts()} style={{ marginTop: 0 }} >
                <Tooltip title="Reload">
                  <Refresh />
                </Tooltip>
              </IconButton>
              <Link to="/add-new-shift">
                <IconButton color="primary" aria-label="Reload Refresh">
                  <Tooltip title="Add New Shift">
                    <AddIcon />
                  </Tooltip>
                </IconButton>
              </Link>
            </Stack>
          </Stack> */}
          <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
            <Stack direction={'row'}>
              <Typography className="headerTitleName" variant="h3">All Shifts</Typography>
            </Stack>
            <Stack direction={'row'}>
              <Tooltip title="Add Country">
                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                  Create
                </Button>
              </Tooltip>
              <IconButton color="primary" aria-label="Reload Refresh" onClick={() => loadShifts()} style={{ marginTop: 0 }} >
                <Tooltip title="Reload">
                  <AutorenewIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>
          <ToastContainer />
          {/* <DynamicTable
            moduleName="allShifts"
            tableColumns={shiftColumns}
            tableRows={shiftRows || []}
            currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
            setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
            paginationMode={"server"}
            rowCount={rowCount}
            loading={loading}
            deleteRow={deleteShift}
            onViewDetail={viewShift}
            onEdit={editShift}
            editLink={"/add-new-shift"}
            // columnVisibilityModel={{
            //   id: false,
            // }}
            columnVisibilityModel={defaultshiftColumns && defaultshiftColumns.all_shift_column ? defaultshiftColumns.all_shift_column : columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
            }
            setFieldName={setFieldName} setDefaultValue={setDefaultValue}
            limit={limit}
            setLimit={setLimit}
          /> */}
          <Stack direction={'row'} mt={2}>
            <DataGridPro
              rows={(!loading && shiftRows && shiftRows.length) ? shiftRows : skeletonRows}
              loading={loading}
              columns={shiftColumns}
              filterMode="server"
              onFilterModelChange={(filter, r) => handleFilter(filter, r)}
              onSortModelChange={(sort) => handleSort(sort)}
              pageSize={pageSize}
              // onSelectionModelChange={onSelectionChange}
              checkboxSelection
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                setColumnVisibilityModel(newModel);
                setKeyForList(newModel);
              }
              }
              deleteRow={deleteShift}
              onViewDetail={viewShift}
              onEdit={editShift}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                }
              }}
              // filterModel={filterModel}
              experimentalFeatures={{ lazyLoading: true }}
              style={{ fontSize: 16 }}
              components={{
                // Pagination: CustomPagination,
                // LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                ColumnSortedDescendingIcon: UnfoldMoreIcon,
              }}
              // getRowHeight={getRowHeight}
              // {...restProps}
              hideFooterPagination={true}
              onRowsScrollEnd={handleOnRowScrollEnd}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              sx={{
                boxShadow: '1px 13px 31px -15px gray',
                borderRadius: '15px',
                height: '700px',
                '& .MuiDataGrid-cell:hover': {
                  color: '#000',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#1B3764',
                  fontWeight: '650'
                },
                '& .even.MuiDataGrid-row': {
                  backgroundColor: 'white'
                },
                '& .odd.MuiDataGrid-row': {
                  backgroundColor: '#F2F5FA'
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#b8d3fd',
                },
                "& ::-webkit-scrollbar": {
                  width: "8px",
                  height: "8px",
                },
                "& ::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                "& ::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                  backgroundColor: "#f5f5f5",
                },
                '& .MuiDataGrid-menuIconButton': {
                  rotate: '90deg',
                  marginRight: '20px'
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  marginLeft: '10px'
                },
                ".cellAction": {
                  display: 'none'
                },
                [`& .${gridClasses.row}`]: {
                  "&:hover, &.Mui-hovered": {
                    ".cellAction": {
                      display: 'block'
                    }
                  }
                }

              }}

            />
          </Stack>
          <Dialog
            hideBackdrop
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-modal"
          >
            <Button
              edge="start"
              onClick={handleClose}
              aria-label="close"
              style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}>
              <Close />
            </Button>
            <DialogTitle style={{ backgroundColor: "#438bf9", color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>
              {dialogeTitle}
            </DialogTitle>
            <DialogContent>
              {showViewModal === false &&
                <form onSubmit={handleSubmit}>
                  <div className="add-new-user-form">
                    {loading && <LoaderForm />}
                    <div className="formInput">
                      <label >
                        Shift Name
                      </label>
                      <input
                        name={"shift_name"}
                        disabled={editData && editData?.shift_name}
                        defaultValue={editData?.shift_name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Shift Name"
                      />

                    </div>

                    <div className="formInput">
                      <label >
                        Shift Key
                      </label>
                      <input
                        name={"shift_key"}
                        disabled={editData && editData?.shift_name}
                        defaultValue={editData?.shift_key}
                        onChange={handleChange}
                        type="text"
                        placeholder="Shift Key"
                      />

                    </div>

                    <div className="formInput">
                      <label >
                        From Time
                      </label>

                      <input
                        name="start_time"
                        defaultValue={editData?.start_time}
                        onChange={handleChange}
                        type="time"
                        placeholder="Shift Starts"
                      />

                    </div>

                    <div className="formInput">
                      <label >
                        To Time
                      </label>
                      <input
                        name="end_time"
                        defaultValue={editData?.end_time}
                        onChange={handleChange}
                        type="time"
                        placeholder="To Time"
                      />

                    </div>


                    <div className="formInput">
                      <label >
                        Time Zone
                      </label>
                      <select
                        name={"time_zone"}
                        value={editData?.time_zone}
                        onChange={handleChange}
                      >
                        <option label="">Select</option>
                        <option value="ist">India Standard Time</option>
                        <option value="others">Others</option>
                      </select>

                    </div>

                    <div className="formInput">
                      <label >
                        Description
                      </label>

                      <textarea rows="3"
                        name={"description"}
                        defaultValue={editData?.description}
                        onChange={handleChange}
                        placeholder="Short Description"
                      >
                      </textarea>

                    </div>
                    {/* <div className="formInput">
                    <label >
                      Status
                    </label>
                    <select
                      name={"status"}
                      defaultValue={editData?.status}
                      onChange={handleChange}
                    >
                      <option value={true} label="">Active</option>
                      <option value={false} label="">Inactive</option>
                    </select>

                  </div> */}
                    <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'end'}>
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                        <Typography variant='subtitle1' >Status</Typography>
                        <Switch {...label} defaultChecked={editData?.status} onChange={handleChange} />
                      </Stack>
                      <Stack width={'20%'} justifyContent={'end'}>
                        <Button type="submit" disabled={loading} variant='contained'>Submit</Button>
                      </Stack>
                    </Stack>
                  </div>
                </form>
              }
              {showViewModal === true && <ViewShiftData />}
            </DialogContent>
          </Dialog>
          <DeleteDialogModal
            open={archiveOpen}
            onClose={setArchiveOpen}
            heading={changeStatus}
            paragraph={`Are you sure to ${changeStatus} Shift`}
            handleArchive={handleClickArchive}
            id={archiveId}
          />
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
              root: {
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                }
              }
            }}
          >
            <MenuItem onClick={() => handleMenuAction('view_shift')} sx={{ color: '#11cc00' }}><VisibilityIcon />&nbsp; View</MenuItem>
            <MenuItem onClick={() => handleMenuAction('edit_shift')} sx={{ color: '#0052cc' }}><EditIcon />&nbsp; Edit</MenuItem>
            <MenuItem onClick={() => handleMenuAction('delete_shift')} sx={{ color: '#cc0000' }}><ArchiveIcon />&nbsp; Active/InActive</MenuItem>
          </Menu>
        </div>
      </div>
    </div >
  )
}

export default AllShifts