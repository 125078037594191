// import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobPostCreate, jobPostEdit, jobPostUpdate, getJobPostList, getProjectMappingDomain, defaultjobPostColumn, jobBudgetReviseList, updateJobApprovalStatus } from "../../../redux/features/jobPostSlice";
import { getAllIndustryList, getAllQualificationList, getAllSkillList, hiringRequestSoftDelete } from "../../../redux/features/masterSlice";
import { getResourceWiseProjectList } from "../../../redux/features/taskSlice";
import { STATUSES } from "../../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../../components/common-function/LoaderForm";
import UcActionComponent from "../../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../../components/TablePaginationActions";
import { Close, RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";
import { InputFields, InputMultiSelectField, InputRadioGroupFields, InputSelectField, InputEditorField, ViewField } from "../../../components/common-function/InputFields";
import DeleteDialogModal from "../../../components/common-function/DeleteDialogModal";
import Select from 'react-select';
import GetLocalstorage from "../../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from "../../../components/CustomEmptyOverlay";
import { jobScreeningFormEdit } from "../../../redux/features/candidateScreeningFormSlice";
import { resourceList } from "../../../redux/features/resourceSlice";
import { RefreshButton } from "../../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../../components/common-function/DefaultFilterColumns";
import dataService from "../../../redux/services/data.service";
import ApproverForm from "./ApproverForm";
import DefaultScreeningForm from "../../../pages/master/candidate-screening-form/DefaultScreeningForm";
import JobPost from "../AddJobPost";
import RevisedSalaryJob from "../budget-revise/RevisedSalaryJob";


const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role
const Approver = user?.is_approver
const jobStatus = GetLocalstorage.get_jobpost_status();


const initialFormData = Object.freeze({
    job_title: "",
    job_description: "",
    job_skill: "",
    work_mode: "",
    work_experience: "0",
    preferred_industry: "",
    industry: "",
    education_qualification: "",
    other_skill: "",
    project_id: "",
    status: "",
    type: ""

});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    job_titleErr: "Title is required",
    work_modeErr: "Work mode is required",
    work_experienceErr: "Work experience is required",
    project_idErr: "Project is required",
    industryErr: "Industry is required",
    education_qualificationErr: "Education qualification is required",
    job_skillErr: "Skills is required",
    domain_idErr: "Domain is required",
});


const default_jobList_Columns = GetLocalstorage.get_default_joblist_column()

const JobPostList = () => {
    const dispatch = useDispatch();
    const { jobId } = useParams()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    // const [statusCreatingJobPost] = useState(createJobPostStatus)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, jobPost_List, jobPost_Create, jobPost_Edit, jobPost_Update, jobPost_Archive, mappingDomain_List, jobPost_Approver_Status, defaultjoblistColumns, revised_Salary_List } = useSelector(state => state.jobpost)
    const { skill_AllList, qualification_AllList, industry_AllList } = useSelector(state => state.master)
    // const { taskList } = useSelector(state => state.task)
    const { resourceWiseProjectList, createNewProjectList } = useSelector(state => state.task)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin') ? `{"filter":{"hiring_request__status":{"value":"True","operation": "eq"},"approver":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS},"hiring_request__status"]}` : `{"filter":{"hiring_request__status":{"value":"True","operation": "eq"}},"skip":0, "limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS},"hiring_request__status"]}`

    // const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_jobList_Columns?.all_job_column ? default_jobList_Columns.all_job_column : {
        tms_role__id: false,
        id: false,
    });
    const [skills, setSkills] = useState([])
    const [other_skill, setOther_skill] = useState([])
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [job_description, setJob_description] = useState('')
    const [createNewProject, setCreateNewProject] = useState(false)
    const [domainId, setDomainId] = useState('');
    //screening states
    const [openScreenigForm, setOpenScreenigForm] = useState(false)
    const [openAssignApprover, setOpenAssignApprover] = useState(false)
    const { jobScreeningForm_getbyID } = useSelector(state => state.candidateScreeningForm)
    const [editJobScreening, setEditJobScreening] = useState({})
    const [job_id, setJobId] = useState('')
    const [screeningApiReload, setScreeningApiReload] = useState(false)
    const [approverID, setApproverID] = useState();
    const { resourceListDropDown } = useSelector(state => state.resource)

    const [fieldType, setFieldType] = useState("")
    const [comment, setComment] = useState("")
    const [rowId, setRowId] = useState("")
    const [showJobAdd, setShowJobAdd] = useState(false)
    const [showReviseSalary, setShowReviseSalary] = useState(false)
    const [selectedJObData, setSelectedJObData] = useState({})
    // const [skillData, setSkillData] = useState([])
    // const [specialisationSkills, setSpecialisationSkills] = useState([])
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const [softDelete, setsoftDelete] = useState(false)
    let softDel = React.useRef(false);


    const setKeyForList = (obj) => {
        let data;
        if (default_jobList_Columns) {
            data = { 'all_job_column': obj, 'reported_joblist_column': default_jobList_Columns.reported_joblist_column, 'my_job_column': default_jobList_Columns.my_job_column, 'job_list_column': default_jobList_Columns.job_list_column }
        } else {
            data = { 'all_job_column': obj }
        }
        if (defaultjoblistColumns.reported_joblist_column) {
            data['reported_joblist_column'] = defaultjoblistColumns.reported_joblist_column
        }
        if (defaultjoblistColumns.my_job_column) {
            data['my_job_column'] = defaultjoblistColumns.my_job_column
        }
        if (defaultjoblistColumns.job_list_column) {
            data['job_list_column'] = defaultjoblistColumns.job_list_column
        }
        dispatch(defaultjobPostColumn(data))
    }

    const closeAssignApprover = () => {
        setOpenAssignApprover(false)
    }

    const closeModalScreening = () => {
        setOpenScreenigForm(false)
        setRowId('')
        setEditJobScreening({})
        dispatch(jobScreeningFormEdit('type'))
    }
    const [statusJobPost, setStatusJobPost] = useState('');

    async function handleEpicCall(id) {

        const resquest = await dataService.get_task_list(`{"filter":{"job_id":{"value":${id}, "operation":"eq"}},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`)
        const response = resquest.data;
        if (response.status === 200) {
            if (response.data?.rows && response.data.rows.length > 0) {
                navigate(`/epic-task/${response.data.rows[0].id}`)
            }
        } else {
            response?.message && toast.error(response.message)
        }

    }

    const JobPostColumns = [
        { field: "id", headerName: "ID", width: 70, filterable: false, },
        {
            field: "job_title",
            headerName: "Job Title",
            width: 230,
            renderCell: (params) => {
                return <div style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                    {params.row.job_title}
                </div>
            }
        },
        {
            field: "job_description", headerName: "Job Description", width: 300, height: 200,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ellips-css-data`} style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: params.row.job_description }} />} >
                            {params.row?.job_description && <span dangerouslySetInnerHTML={{ __html: (params.row.job_description && params.row.job_description.split('>')[1].split('<')[0].substring(0, 15) + "...") }} />}

                        </Tooltip>
                    </div>
                );
            }
        },
        {
            field: "work_mode", headerName: "Work Mode", width: 300,
            renderCell: (params) => (
                <div className={`cellWithStatus`} style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                    {params.row.work_mode}
                </div>
            ),
        },
        {
            field: "job_experience", headerName: "Work Experience", width: 150,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`} style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                        {params.row.job_experience ? params.row.job_experience : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "industry__industry_name", headerName: "Industry", width: 230,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`} style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                        {params.row.industry__industry_name ? params.row.industry__industry_name : "N/A"}
                    </div>
                );
            }
        },
    ];


    useEffect(() => {
        if (jobId) {
            // dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${jobId},"operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}`))
            dispatch(jobPostEdit(jobId))
            setReload('edit_data')
        }
        dispatch(getJobPostList(roleWiseFilter))
        dispatch(getAllIndustryList())
        dispatch(getAllQualificationList())
        dispatch(getAllSkillList())
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getProjectMappingDomain({ "order_by": { "column": "-id" }, "no_limit": 'True', "columns": ["id", "domain__id", "domain__name"] }))

        dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}, "tms_role__role_key": {"value":"admin", "operation": "eq"}, "is_approver": {"value":"True", "operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        if (tmsRole.role_key === "admin" || Approver === true) {
            setsoftDelete(true)
        }
        else {
            setsoftDelete(false)
        }
        // eslint-disable-next-line
    }, [error, softDelete])
    //job update section
    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Update` && jobPost_Update && jobPost_Update.message) {
        if (jobPost_Update.status === 200) {
            toast.success(jobPost_Update.message)
            setOpen(false);
            let jobId_v = jobPost_Update.data?.id
            if (jobPost_Update.data && jobPost_Update.data.status === true) {
                jobPost_Update.data && setJobId(jobPost_Update.data.id)
                setScreeningApiReload('edit-screening-form')
                dispatch(jobScreeningFormEdit(`{"filter":{"job_id":{"value":${jobId_v},"operation":"eq"}}}`))
            }
            // setOpenScreenigForm(true)
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setJob_description("")
            setCreateNewProject(false)
            setID(0)
            dispatch(getJobPostList(roleWiseFilter))
        } else {
            toast.error(jobPost_Update.message)
        }
        setReload(false)
    }

    //job create section
    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Create` && jobPost_Create && jobPost_Create.message) {
        if (jobPost_Create.status === 200) {
            toast.success(jobPost_Create.message)
            setOpen(false);
            jobPost_Create.data && setJobId(jobPost_Create.data.id)
            setOpenScreenigForm(true)
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setJob_description("")
            setID(0)
            setCreateNewProject(false)
            dispatch(getJobPostList(roleWiseFilter))
        } else {
            toast.error(jobPost_Create.message)
        }
        setReload(false)
    }

    //job active/inactive section
    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Archive` && jobPost_Archive && jobPost_Archive.message) {
        if (jobPost_Archive.status === 200) {
            toast.success(jobPost_Archive.message)
            dispatch(getJobPostList(roleWiseFilter))
        } else {
            toast.error(jobPost_Archive.message)
        }
        setReload(false)
    }

    const handleClose = () => {
        if (activeViewMode === true) {
            setActiveViewMode(false)
        } else {
            setOpen(false);
            setSkills([])
            setOther_skill([])
            setFormValues(initialFormData);
            setJob_description("")
            setID(0)
        }
        setErrStatus(false)
        if (createNewProject === true) {
            setFormValues({ ...formValues, project_id: { value: "-1", label: "Create New Project" } });
        }
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'project_id') {
            if (value === '-1') {
                setCreateNewProject(true)
            } else {
                setCreateNewProject(false)
            }
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handleMultiIndustryChange = (evt) => {
        setFormValues({ ...formValues, preferred_industry: evt })
    }

    const handleMultiQualificationChange = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, education_qualification: evt })
        } else {
            setFormValues({ ...formValues, education_qualification: "" })
        }
    }

    const handleSkillChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = skills.length > 0 && skills.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setSkills([...valueArray])
    }

    const handleOtherSkillChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = other_skill.length > 0 && other_skill.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setOther_skill([...valueArray])
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.job_title === "" || job_description === "" || formValues.job_skill === "" || formValues.work_mode === ""
            || formValues.education_qualification === "" || formValues.industry === "" || formValues.project_id === "" || formValues.type === "") {
            setErrStatus(true)
        }

        if (formValues.job_title && job_description && formValues.job_skill && formValues.work_mode &&
            formValues.education_qualification && formValues.industry && formValues.project_id && formValues.type) {
            setErrStatus(false)
            findName()
            setActiveViewMode(true)
        }
    }

    const addMoreSkill = (evt) => {
        let array = []
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, job_skill: evt })
        } else {
            setFormValues({ ...formValues, job_skill: "" })
        }

        evt.map(e => {
            let filterExp = skills.filter(x => x.skill_id === e.id)
            return array.push({
                skill: e.skill_name,
                skill_id: e.id,
                experience: filterExp.length > 0 ? filterExp[0].experience : "0",
                experience_type: "Months",
                status: true
            })
        })

        setTimeout(() => {
            setSkills(array)
        }, 500);

    }

    const removeSkill = (i) => {
        const updateformData = skills.filter((item, index) => index !== i);
        const updateFormValues = formValues?.job_skill.filter((item, index) => index !== i);
        if (updateFormValues && updateFormValues.length > 0) {
            setFormValues({ ...formValues, job_skill: updateFormValues })
        } else {
            setFormValues({ ...formValues, job_skill: "" })
        }
        setSkills(updateformData)
    }

    const addOtherSkill = (evt) => {
        setFormValues({ ...formValues, other_skill: evt })
        if (other_skill.length > 0) {
            setOther_skill([...other_skill,
            {
                skill: "",
                experience: "0",
                experience_type: "Months"
            }]
            )

        } else {
            setOther_skill([
                {
                    skill: "",
                    experience: "0",
                    experience_type: "Months"
                }
            ])
        }

    }

    const removeOtherSkill = (i) => {
        const updateformData = other_skill.filter((item, index) => index !== i);
        setOther_skill(updateformData)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && jobPost_Edit && jobPost_Edit.data) {
        let editData = jobPost_Edit.data
        let hiringData = editData?.hiring_request
        let array1 = []
        let array2 = []
        let array3 = []
        let array4 = []
        let otherSkillArray = []
        let otherSkillValueArray = []
        let technologyArray = []
        let addMoreSpecificationArray = []

        setID(editData.id)
        if (editData.education_qualification) {
            let qualification = qualification_AllList?.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []
            editData.education_qualification.map(e => {
                let filterId = qualification.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array1.push(filterId[0])
                }
                return array1
            })
        }
        if (hiringData?.technology_data) {
            hiringData.technology_data && hiringData.technology_data.length > 0 && hiringData.technology_data.map(e => {
                return technologyArray.push({
                    label: e.specialization?.name,
                    value: e.id,
                    name: "name"
                })
            })
        }


        if (editData?.add_more_specifications_data) {
            editData.add_more_specifications_data && editData.add_more_specifications_data.length > 0 && editData.add_more_specifications_data.map(e => {
                return addMoreSpecificationArray.push({
                    label: e.specification_name,
                    value: e.id
                })
            })
        }


        if (editData.skills) {
            let skill_list = skill_AllList?.data && skill_AllList.data.rows ? skill_AllList.data.rows : []
            editData.skills.map(e => {
                let filterId = skill_list.filter(x => x.skill_name === e.skill_name);
                if (filterId.length > 0) {
                    array2.push(filterId[0])
                    array4.push({
                        skill: e.skill_name,
                        skill_id: filterId[0].id,
                        experience: e.work_experience,
                        experience_type: e.experience_type,
                    })
                } else {
                    otherSkillArray.push(e)
                    otherSkillValueArray.push({
                        skill: e.skill_name,
                        skill_id: e.id,
                        experience: e.work_experience,
                        experience_type: e.experience_type,
                    })
                }
                return array2
            })
        }
        if (editData.preferred_industry) {
            let industry_list = industry_AllList?.data && industry_AllList.data.rows ? industry_AllList.data.rows : [];
            editData.preferred_industry.map(e => {
                let filterId = industry_list.filter(x => x.id === e);
                if (filterId.length > 0) {
                    array3.push(filterId[0])
                }
                return array3
            })

        }
        setFormValues({
            ...formValues,
            education_qualification: array1,
            job_skill: array2,
            other_skill: otherSkillArray,
            preferred_industry: array3,
            job_title: editData.job_title,
            work_experience: editData.job_experience,
            type: editData.experience_type,
            work_mode: { label: editData.work_mode, value: editData.work_mode },
            project_id: editData.project && { label: editData.project.project_name, value: editData.project_id },
            industry: { label: editData.industry.industry_name, value: editData.industry_id },
            status: editData.status,
            approver_status: editData.approver_status,
            job_description: editData.job_description,
            hiring_request: editData.hiring_request_id,

            // hiring form data//
            client: { label: hiringData?.client && hiringData?.client?.name, value: hiringData?.client_id },
            number_of_vacancies: hiringData?.number_of_vacancies || "",
            department: { label: hiringData?.department && hiringData?.department?.name, value: hiringData?.department_id },
            client_budget_from: editData.client_budget_from,
            client_budget_to: editData.client_budget_to,
            client_budget_from_new: revised_Salary_List?.data?.rows && revised_Salary_List?.data?.rows[0]?.from_salary_per_annum ? revised_Salary_List?.data?.rows[0]?.from_salary_per_annum : editData.client_budget_from,
            client_budget_to_new: revised_Salary_List?.data?.rows && revised_Salary_List?.data?.rows[0]?.to_salary_per_annum ? revised_Salary_List?.data?.rows[0]?.to_salary_per_annum : editData.client_budget_to,
            type_of_vacancy: { label: hiringData?.type_of_vacancy && hiringData?.type_of_vacancy?.vacancy_type, value: hiringData?.type_of_vacancy_id },
            contract_type: { label: hiringData?.contract_type && hiringData?.contract_type.name, value: hiringData?.contract_type_id },
            hours_per_day: hiringData?.hours_per_day ? hiringData?.hours_per_day : null,
            expected_duration_in_months: hiringData?.expected_duration_in_months ? hiringData?.expected_duration_in_months : null,
            urgency: { label: hiringData?.urgency && hiringData?.urgency?.urgency_type, value: hiringData?.urgency_id },
            job_location: { label: hiringData?.job_location && hiringData?.job_location?.job_location, value: hiringData?.job_location_id },
            job_experience: hiringData?.relevant_experience_expected,
            job_responsibilities: hiringData?.job_responsibilities,

            // expected_ETA_in_months: hiringData.expected_ETA_in_months,
            // expected_ETA_in_days: hiringData.expected_ETA_in_days,
            relevant_experience_expected_in_years: hiringData?.relevant_experience_expected_in_years,
            relevant_experience_expected_in_months: hiringData?.relevant_experience_expected_in_months,
            add_more_specifications: addMoreSpecificationArray,
            specialistation_id: technologyArray,
            id: editData.id,


            relevant_experience_expected_from: hiringData?.relevant_experience_expected_from,
            relevant_experience_expected_to: hiringData?.relevant_experience_expected_to,
            total_experience_to: hiringData?.total_experience_to,
            total_experience_from: hiringData?.total_experience_from,
            expected_ETA: hiringData?.expected_ETA,
            eta_of_Arrival: hiringData?.expected_ETA,
            urgency_unit: hiringData?.urgency_unit,
        })


        if (editData.job_description) { setJob_description(editData.job_description) }
        setSkills(array4)
        setOther_skill(otherSkillValueArray)
        // setOpen(true)
        setShowJobAdd(true)
        setReload(false)
    }


    const handleClickEdit = (id) => {
        dispatch(jobPostEdit(id))
        setReload('edit_data')
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        let update_job_skill = [];
        id.skills.length > 0 && id.skills.map((item) => {
            return update_job_skill.push({
                skill: item.skill_name,
                skill_id: item.id,
                experience: item.work_experience,
                experience_type: item.experience_type
            })
        });

        let data = {
            ID: id.id,
            addvalues: {
                status: !id.status,
                job_experience: id.job_experience,
                project: id.project_id,
                job_skill: update_job_skill,
                work_mode: id.work_mode,
                preferred_industry: id.preferred_industry,
                industry: id.industry_id,
                education_qualification: id.education_qualification,
                experience_type: id.experience_type,
                job_description: id.job_description,
                other_skill: id.other_skill ? id.other_skill : [],
                job_title: id.job_title,
            }
        }
        dispatch(jobPostUpdate(data))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        // setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getJobPostList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getJobPostList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(jobPost_List && jobPost_List.data && jobPost_List.data.rows && jobPost_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getJobPostList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0,"limit": ${limit},"order_by":{"column":"-id" },"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}`)
        }
    }

    //filter
    const onFilterChange = (filterModel, r) => {

        if (filterModel.items.length) {
            setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
        }

        if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
            setReload(true);
            setTimeout(() => {
                setReload(false)
                setCurrentPage(0)
                //setPageSize(10)
                setFilterQuery(roleWiseFilter)
                dispatch(getJobPostList(roleWiseFilter))
            }, 0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue === "isAnyOf" ? "ieq" :
                                filterModel.items[0].operatorValue;
            let filterData;
            if (tmsRole && tmsRole.role_key === 'admin') {
                filterData = `{"filter":{"approver":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}`
            } else {
                filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}`
            }
            // const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getJobPostList(filterData))
        }
    }

    const findName = () => {
        let industrylist = (industry_AllList?.data && industry_AllList.data.rows) || [];
        let projectlist = (resourceWiseProjectList) || []
        let filter = industrylist.filter(x => x.id === formValues.industry)
        let filterWorkMode = GetLocalstorage.get_default_work_mode().filter(x => x === formValues.work_mode)
        let filterProject = projectlist.filter(x => x.id === formValues.project_id)

        if (filterProject.length > 0) {
            formValues.project_id = { label: filterProject[0].project_name, value: filterProject[0].id }

            setFormValues({ ...formValues, project_id: { label: filterProject[0].project_name, value: filterProject[0].id } })

        }
        if (filterWorkMode.length > 0) {
            formValues.work_mode = { label: formValues.work_mode, value: formValues.work_mode }
            setFormValues({ ...formValues, work_mode: formValues.work_mode })

        }
        if (filter.length > 0) {
            formValues.industry = { label: filter[0].industry_name, value: filter[0].id }
            setFormValues({ ...formValues, industry: { label: filter[0].industry_name, value: filter[0].id } })
        }
        //return industry
    }

    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setJob_description(newContent)
        // Define your onSubmit function here
        // ...
        // for example, setData() here

    }

    const finalSubmit = (e) => {
        e.preventDefault()
        let education_qualification_array = [];
        let preferred_industry_array = [];
        if (formValues.education_qualification && formValues.education_qualification.length > 0) {
            formValues.education_qualification.map(e => {
                return education_qualification_array.push(e.id)
            })
        }
        if (formValues.preferred_industry && formValues.education_qualification_name.length > 0) {
            formValues.preferred_industry.map(e => {
                return preferred_industry_array.push(e.id)
            })
        }
        let addvalues = {
            ...formValues,
            created_by: GetLocalstorage.userDetail().id,
            job_skill: skills, other_skill: other_skill,
            industry: formValues.industry.value ? formValues.industry.value : formValues.industry,
            work_mode: formValues.work_mode.value ? formValues.work_mode.value : formValues.work_mode,
            education_qualification: education_qualification_array,
            preferred_industry: preferred_industry_array,
            project: formValues.project_id.value ? formValues.project_id.value : formValues.project_id,
            job_experience: formValues.work_experience,
            experience_type: formValues.type.value ? formValues.type.value : formValues.type,
            job_description: job_description,
            hiring_request: formValues.hiring_request_id

        }
        if (createNewProject === true) {
            addvalues['project'] = null;
            addvalues['create_new_project'] = true;
            addvalues['domain_id'] = domainId.value;
        }
        delete addvalues['project_id']
        delete addvalues['work_experience']
        delete addvalues['type']
        setReload(true)

        if (ID) {
            dispatch(jobPostUpdate({ ID, addvalues }))
        } else {
            dispatch(jobPostCreate(addvalues))
        }

    }

    // job screening form section
    if (screeningApiReload === 'edit-screening-form' && jobScreeningForm_getbyID && jobScreeningForm_getbyID.status) {

        if (jobScreeningForm_getbyID.status === 200) {
            setOpenScreenigForm(true)
            if (jobScreeningForm_getbyID.data && jobScreeningForm_getbyID.data.rows && jobScreeningForm_getbyID.data.rows.length > 0) {
                // setOpenScreenigForm(true)
                let formData = jobScreeningForm_getbyID.data.rows.length > 0 ? jobScreeningForm_getbyID.data.rows[0] : ""
                setEditJobScreening(formData)
            }
        } else {
            toast.error(jobScreeningForm_getbyID.message)
        }
        setScreeningApiReload(false)
    }

    var experienceArr = [];
    for (let i = 0; i <= 40; i++) {
        experienceArr.push(i);
    }

    //job status response handling
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_jobPost_Approver_Status` && jobPost_Approver_Status && jobPost_Approver_Status.message) {
        if (jobPost_Approver_Status.status === 200) {
            toast.success(jobPost_Approver_Status.message)
            setOpenAssignApprover(false);
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Approver_Status.message)
        }
        setReload(false);

    }

    //job status handle change
    const handleChangeApproverForm = (evt) => {
        const { name, value } = evt.target || evt
        if (name === 'approver') {
            setApproverID(evt.value)
        } else if (name === "approver_comment") {
            setComment(value)
        }
    }

    //open job status action
    const openEpicModule = async (jobId_v, key) => {
        setRowId(jobId_v)
        const currentJob = jobPost_List.data.rows.filter(item => item.id === jobId_v)[0]
        const updatedJob = {
            industry: currentJob?.industry_id,
            project: currentJob?.project_id,
            job_title: currentJob?.job_title,
            work_mode: currentJob?.work_mode,
            job_description: currentJob?.job_description,
            preferred_industry: currentJob?.preferred_industry,
            education_qualification: currentJob?.education_qualification,
            job_experience: currentJob?.job_experience,
            experience_type: currentJob?.experience_type,
            attachment: currentJob?.attachment || "",
            department: currentJob?.department_id,
            status: currentJob?.status,
            // department: currentJob.department,
            technologies: currentJob?.technologies,
            job_skill: currentJob?.skills.map(item => ({
                "skill": item.skill_key,
                "skill_id": item.id,
                "experience": item.work_experience,
                "experience_type": item.experience_type
            })),
            other_skill: currentJob?.other_skill || null,
            approver: currentJob?.approver_id,
            approver_comment: currentJob?.approver_comment
        }

        if (key === 'epic') {
            handleEpicCall(jobId_v)
            //dispatch(getTaskList(`{"filter":{"job_id":{"value":${jobId_v}, "operation":"eq"}},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
        } else if (key === 'form') {
            setJobId(jobId_v)
            setScreeningApiReload('edit-screening-form')
            dispatch(jobScreeningFormEdit(`{"filter":{"job_id":{"value":${jobId_v},"operation":"eq"}}}`))

        } else if (key === 'edit') {
            dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${jobId_v},"operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}`))
            setTimeout(() => {
                handleClickEdit(jobId_v)
            }, 300)

        } else if (key === 'assign-approver') {
            setOpenAssignApprover(true)

        } else if (key === 'request-for-approval') {
            updatedJob['approver_status'] = 1
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))

        } else if (key === 'approved') {
            dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${jobId_v},"operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}`))
            setTimeout(() => {
                handleClickEdit(jobId_v)
            }, 300)

        } else if (key === 'rejected') {
            updatedJob['approver_status'] = 3
            setOpenAssignApprover(true)

            // setReload('submit')
            // dispatch(updateJobApprovalStatus({ id: rowId, data: updatedJob }))
        } else if (key === 'live') {
            updatedJob['approver_status'] = 4
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))

        } else if (key === 'hold') {
            updatedJob['approver_status'] = 5
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))

        } else if (key === 'close') {
            updatedJob['approver_status'] = 6
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: jobId_v, data: updatedJob }))

        } else if (key === 'salary') {
            setShowReviseSalary(true)
            setSelectedJObData(currentJob)
        }
        setFieldType(key)
    }

    const actionColumn = [
        {
            field: "approver_status",
            headerName: "Status",
            width: 220,
            filterable: true,
            type: 'singleSelect',
            valueOptions: jobStatus,
            renderCell: (params) => {
                const createJobPostStatus = Object.freeze({
                    0: "PENDING",
                    1: "REQUEST FOR APPROVAL",
                    2: "APPROVED",
                    3: "REJECTED",
                    4: "LIVE",
                    5: "HOLD",
                    6: "CLOSE",
                    7: "RE-APPROVAL REQUEST"
                })
                return (
                    <div className={`cellWithStatus`} style={{ cursor: "pointer" }} onClick={() => handleEpicCall(params.row.id)}>
                        {/* + params.row.status */}
                        {createJobPostStatus[params.row.approver_status] ? createJobPostStatus[params.row.approver_status] : "-"}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: 130,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const additionActionArray = [

                    { title: "MANAGE JOB", key: 'epic' },
                    // { title: "Budget Revise", key: 'salary' },

                    // { title: "Edit Screening", key: 'form' },
                    // { title: "View", key: 'view' }
                ]

                if (tmsRole && tmsRole.role_key === "hr" && [2, 3, 4].includes(params.row.approver_status)) {
                    additionActionArray.push({ title: "BUDGET REVISE", key: 'salary' })
                }

                switch (params.row.approver_status) {//createJobPostStatus

                    case 0:
                        // approval pending
                        if (tmsRole && tmsRole.role_key === 'hr') {
                            additionActionArray.push({ title: "ASSIGN APPROVER", key: "assign-approver" })
                            additionActionArray.push({ title: "EDIT", key: "edit" })
                            additionActionArray.push({ title: "EDIT SCREENING", key: "form" })
                        }

                        break;
                    case 1:
                        // Request For Approval

                        //approver login
                        if (tmsRole && tmsRole.role_key !== 'hr' && user.is_approver === true) {

                            // additionActionArray.push({ title: "EDIT", key: "edit" })
                            // additionActionArray.push({ title: "On hold", key: "hold" })
                            additionActionArray.push({ title: "CLOSE", key: "close" })

                            additionActionArray.push({ title: "APPROVE", key: "approved" })
                            additionActionArray.push({ title: "REJECT", key: "rejected" })
                        }

                        // hr login 
                        if (tmsRole && tmsRole.role_key === 'hr') {
                            // additionActionArray.push({ title: "On hold", key: "hold" })
                            additionActionArray.push({ title: "CLOSE", key: "close" })
                            // additionActionArray.push({ title: "Edit", key: "edit" })
                            // additionActionArray.push({ title: "Edit Screening", key: "form" })
                        }

                        break;
                    case 2:
                        // Approved

                        // hr login and approver same 
                        additionActionArray.push({ title: "LIVE&ACCEPT", key: "live" })
                        additionActionArray.push({ title: "HOLD", key: "hold" })
                        additionActionArray.push({ title: "CLOSE", key: "close" })
                        break;

                    case 3:
                        // Rejected same login
                        // additionActionArray.push({ title: "On hold", key: "hold" })
                        additionActionArray.push({ title: "CLOSE", key: "close" })
                        additionActionArray.push({ title: "EDIT", key: "edit" })

                        // only for hr 
                        if (tmsRole && tmsRole.role_key === 'hr') {
                            additionActionArray.push({ title: "EDIT SCREENING", key: "form" })
                            additionActionArray.push({ title: "REQUEST FOR APPROVAL", key: "request-for-approval" })
                        }
                        break;

                    case 4:
                        // Live
                        additionActionArray.push({ title: "HOLD", key: "hold" })
                        additionActionArray.push({ title: "CLOSE", key: "close" })
                        break;
                    case 5:
                        // On Hold
                        additionActionArray.push({ title: "LIVE", key: "live" })
                        break;
                    case 6:
                        // Closed
                        break;
                    case 7:
                        // RE-APPROVAL REQUEST

                        additionActionArray.push({ title: "HOLD", key: "hold" })
                        additionActionArray.push({ title: "CLOSE", key: "close" })
                        // only for hr 
                        if (tmsRole && tmsRole.role_key === 'admin') {
                            additionActionArray.push({ title: "APPROVE", key: "approved" })
                            additionActionArray.push({ title: "REJECT", key: "rejected" })
                        }
                        break;

                    default:
                        break;
                }
                const handleSoftArchive = (id) => {
                    setArchiveId(id)
                    setArchiveOpen(true)
                }
                const handleClick = (id) => {

                    if (id.status === true) {
                        dispatch(hiringRequestSoftDelete({
                            "key": "job",
                            "id": id.id
                        })).then((response) => {
                            if (response.payload.status === 200) {
                                toast.success(response.payload.message)

                                softDel.current = true
                            }
                            else {
                                toast.error(`${response.payload.status} ${response.payload.message}`)
                            }
                        })
                    }
                    setArchiveOpen(false)
                }
                if (softDel.current === true) {
                    dispatch(getJobPostList(roleWiseFilter))
                    softDel.current = false
                }
                else {
                    toast.success(error.massage)
                }
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="job-post"
                            rowid={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl={`/view-job-list/${params.row.id}`}
                            // isDelete={false}
                            editButton={false}
                            viewButton={false}
                            // editButton={handleClickEdit}
                            // deleteButton={() => handleArchive(params.row)} //params.row
                            isSoftDelete={softDelete}
                            // deleteButton={false} //params.row
                            extraButton={openEpicModule}
                            additionalAction={additionActionArray}
                            addMoreData={params.row.id}
                            additionalActionIcon="TaskIcon"
                            statusUpdate={true}
                            deleteButton={() => handleSoftArchive(params.row)}
                        />
                        <DeleteDialogModal
                            open={archiveOpen}
                            onClose={setArchiveOpen}
                            paragraph={'Are you sure to delete job Post'}
                            handleArchive={handleClick}
                            id={archiveId}
                        />
                    </div>
                );
            },
        },
    ];

    const submitApprover = (e) => {
        e.preventDefault();
        const currentJob = jobPost_List.data.rows.filter(item => item.id === rowId)[0]
        const updatedJob = {
            industry: currentJob?.industry_id,
            project: currentJob?.project_id,
            job_title: currentJob?.job_title,
            work_mode: currentJob?.work_mode,
            job_description: currentJob?.job_description,
            preferred_industry: currentJob?.preferred_industry,
            education_qualification: currentJob?.education_qualification,
            job_experience: currentJob?.job_experience,
            experience_type: currentJob?.experience_type,
            attachment: currentJob?.attachment || "",
            // department_id: currentJob.department_id,
            status: currentJob?.status,
            department: currentJob?.department_id,
            technologies: currentJob?.technologies,
            job_skill: currentJob?.skills.map(item => ({
                "skill": item.skill_key,
                "skill_id": item.id,
                "experience": item.work_experience,
                "experience_type": item.experience_type
            })),
            other_skill: currentJob?.other_skill || null,
            // approver_status: 1,
            // approver: approverID,
            approver_comment: currentJob?.approver_comment
        }
        let commentFlag = false
        if (fieldType === 'rejected') {
            updatedJob['approver_status'] = 3
            updatedJob['approver_comment'] = comment
            updatedJob['approver'] = currentJob?.approver_id
            if (comment === "") {
                commentFlag = true
            }
        } else if (fieldType === 'assign-approver') {
            updatedJob['approver_status'] = 1
            updatedJob['approver_comment'] = comment
            updatedJob['approver'] = approverID
        }
        if (commentFlag === true) {
            toast.error("Comment is required")
        } else {
            setReload('submit')
            dispatch(updateJobApprovalStatus({ id: rowId, data: updatedJob }))
        }
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    {showJobAdd && <JobPost id={ID} filterQuery={filterQuery} getJobData={formValues} open={showJobAdd} setOpen={setShowJobAdd} />}
                    {showReviseSalary && <RevisedSalaryJob open={showReviseSalary} setOpen={setShowReviseSalary} jobData={selectedJObData} />}

                    <div className="top-headings">
                        <h3>Job List</h3>
                        <div>
                            {/* <Tooltip title="Add Job Post">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip> */}
                            <RefreshButton api={getJobPostList} filter={filterQuery} />
                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data' || reload === 'submit') && <LoaderForm />}
                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={defaultjoblistColumns?.all_job_column ? defaultjoblistColumns.all_job_column : columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                            }
                            rows={jobPost_List?.data && jobPost_List.data.rows ? jobPost_List.data.rows : []}
                            columns={JobPostColumns.concat(actionColumn)}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            filterMode="server"
                        />
                    }
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"xl"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Job Post {activeViewMode === true && "(Preview)"}</DialogTitle>
                    {activeViewMode === true ?
                        <DialogContent style={{ height: "650px" }}>
                            <div>
                                <form className="regulazation-form">
                                    <div>
                                        {(formValues.project_id && (formValues.project_id === '-1' || formValues.project_id.value === '-1')) ?
                                            <>
                                                <ViewField
                                                    label={'Project'}
                                                    key={'project_id'}
                                                    type={1}
                                                    value={'N/A'}
                                                />
                                            </>
                                            :
                                            <ViewField
                                                label={'Project'}
                                                key={'project_id'}
                                                type={1}
                                                value={formValues.project_id}
                                            />
                                        }
                                        <ViewField
                                            label={'Job Title'}
                                            key={'job_title'}
                                            type={1}
                                            value={formValues.job_title}
                                        />
                                        <ViewField
                                            label={'Work Mode'}
                                            key={'work_mode'}
                                            type={1}
                                            value={formValues.work_mode}
                                        />
                                        <ViewField
                                            label={'Qualification'}
                                            key={'education_qualification'}
                                            length={1}
                                            optionLevel={['education_qualification_name']}
                                            type={2}
                                            value={formValues.education_qualification}
                                        />

                                        <ViewField
                                            label={'Description'}
                                            key={'editor'}
                                            fieldType={'editor'}
                                            type={1}
                                            value={job_description}
                                        />
                                        <ViewField
                                            label={'Skills'}
                                            key={'job_skill'}
                                            type={2}
                                            length={3}
                                            optionLevel={['skill', 'experience', 'experience_type']}
                                            value={skills}
                                        />
                                        {other_skill && other_skill.length > 0 &&
                                            <ViewField
                                                label={'Other Skill (optional)'}
                                                key={'other_skill'}
                                                type={2}
                                                length={3}
                                                optionLevel={['skill', 'experience', 'experience_type']}
                                                value={other_skill}
                                            />}
                                        <ViewField
                                            label={'Work Experience'}
                                            key={'work_experience'}
                                            type={3}
                                            value={[formValues.work_experience, formValues.type]}
                                        />

                                        <ViewField
                                            label={'Industry'}
                                            key={'industry'}
                                            type={1}
                                            value={formValues.industry}
                                        />
                                        <ViewField
                                            label={'Preferred Industry'}
                                            key={'preferred_industry'}
                                            length={1}
                                            optionLevel={['industry_name']}
                                            type={2}
                                            value={formValues.preferred_industry}
                                        />
                                        <ViewField
                                            label={'Status'}
                                            key={'status'}
                                            type={1}
                                            value={formValues.status}
                                        />

                                    </div>
                                    <br />
                                    <div>
                                        <Button style={{ minWidth: '15%' }} className="submit-modal" onClick={finalSubmit}>Confirm</Button>
                                        <Button style={{ minWidth: '15%', backgroundColor: 'red', marginLeft: 10 }} className="submit-modal" onClick={handleClose}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        </DialogContent>
                        :
                        <DialogContent>
                            <form>
                                <div className="add-new-user-form">
                                    <InputSelectField
                                        label={"Project"}
                                        name={"project_id"}
                                        placeholder={"Select Project"}
                                        onChange={handleChange}
                                        defaultValue={formValues.project_id}
                                        errStatus={errStatus}
                                        formErr={formErr.project_idErr}
                                        optionList={ID && resourceWiseProjectList && resourceWiseProjectList.length > 0 ? resourceWiseProjectList : createNewProjectList?.length > 0 ? createNewProjectList : []}
                                        optionLevel={['project_name']}
                                        optionValue={'id'}
                                    />
                                    {
                                        createNewProject === true ?
                                            <div className="formInput">
                                                <label>Domain</label>
                                                <Select
                                                    className="select"
                                                    placeholder="Select Domain"
                                                    defaultValue={domainId}
                                                    isSearchable={true}
                                                    onChange={(e) =>
                                                        setDomainId(e)}
                                                    options={
                                                        mappingDomain_List?.map((option) => {
                                                            return { label: option.domain__name, value: option.domain__id, name: "domain_id" }
                                                        })
                                                    }
                                                />
                                            </div>
                                            : ""
                                    }
                                    <InputFields
                                        label={"Job Title"}
                                        name="job_title"
                                        defaultValue={formValues.job_title}
                                        type="text"
                                        placeholder="Title"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.job_titleErr}
                                    />
                                    <InputSelectField
                                        label={"Work Mode"}
                                        name={"work_mode"}
                                        placeholder={"Work Mode"}
                                        onChange={handleChange}
                                        defaultValue={formValues.work_mode}
                                        errStatus={errStatus}
                                        formErr={formErr.work_modeErr}
                                        optionList={GetLocalstorage.get_default_work_mode()}
                                        optionLevel={['option']}
                                        optionValue={'option'}
                                    />
                                    <InputMultiSelectField
                                        label={"Education Qualification"}
                                        name={"education_qualification"}
                                        placeholder={"Education Qualification"}
                                        onRemove={handleMultiQualificationChange}
                                        onSelect={handleMultiQualificationChange}
                                        defaultValue={formValues.education_qualification}
                                        errStatus={errStatus}
                                        formErr={formErr.education_qualificationErr}
                                        optionList={qualification_AllList?.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []}
                                        optionLevel={['education_qualification_name']}
                                        optionValue={'id'}
                                    />
                                    <InputEditorField
                                        label={"Description"}
                                        defaultValue={job_description}
                                        name="job_description"
                                        onChange={inputHandler}
                                        placeholder="Description"
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />
                                    <InputMultiSelectField
                                        divstyle={{ flexBasis: '100%' }}
                                        label={"Skill"}
                                        name={"job_skill"}
                                        placeholder={"Skill"}
                                        onRemove={addMoreSkill}
                                        onSelect={addMoreSkill}
                                        defaultValue={formValues.job_skill}
                                        errStatus={errStatus}
                                        formErr={formErr.job_skillErr}
                                        optionList={skill_AllList?.data && skill_AllList.data.rows ? skill_AllList.data.rows : []}
                                        optionLevel={['skill_name']}
                                        optionValue={'id'}
                                    />
                                    {
                                        skills.map((item, i) => {
                                            return (
                                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>

                                                    <div className="formInput" style={{ flexBasis: '40%' }}>
                                                        <label >
                                                            Skill
                                                        </label>
                                                        <input
                                                            disabled
                                                            name={"skill"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            type="text"
                                                            placeholder="Skill"
                                                            value={item.skill}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Experience
                                                        </label>
                                                        {/* <input
                                                            name={"experience"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            type="number"
                                                            min="0"
                                                            placeholder="Experience"
                                                            value={item.experience}
                                                        /> */}
                                                        <select
                                                            name={"experience"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            value={item.experience}
                                                        >

                                                            {experienceArr.map((e, i) => {
                                                                return (<option value={e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Month/Year
                                                        </label>
                                                        <select
                                                            name={"experience_type"}
                                                            onChange={(e) => handleSkillChange(e, i)}
                                                            value={item.experience_type}
                                                        >

                                                            {GetLocalstorage.get_default_month_year().filter(e => e !== 'Select Anyone').map((e, i) => {
                                                                return (<option disabled={e === 'Select Anyone' && true} value={e === 'Select Anyone' ? '' : e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeSkill(i)} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    <div className="formInput" style={{ flexBasis: '100%' }}>
                                        <span style={{ display: "flex", alignItems: "center" }} >Other Skill (optional) <AddCircleOutline onClick={() => addOtherSkill()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                                    </div>

                                    {
                                        other_skill.map((item, i) => {
                                            return (
                                                <div className="add-new-user-form" style={{ width: "100%", alignItems: "center" }} key={i}>

                                                    <div className="formInput" style={{ flexBasis: '40%' }}>
                                                        <label >
                                                            Skill
                                                        </label>
                                                        <input
                                                            name={"skill"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            type="text"
                                                            placeholder="Skill"
                                                            value={item.skill}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Experienced
                                                        </label>
                                                        {/* <input
                                                            name={"experience"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            type="number"
                                                            min="0"
                                                            placeholder="Experience"
                                                            value={item.experience}
                                                        /> */}
                                                        <select
                                                            name={"experience"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            value={item.experience}
                                                        >
                                                            {experienceArr.map((e, i) => {
                                                                return (<option value={e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="formInput" style={{ flexBasis: '20%' }}>
                                                        <label >
                                                            Month/Year
                                                        </label>
                                                        <select
                                                            name={"experience_type"}
                                                            onChange={(e) => handleOtherSkillChange(e, i)}
                                                            value={item.experience_type}
                                                        >
                                                            {GetLocalstorage.get_default_month_year().filter(e => e !== 'Select Anyone').map((e, i) => {
                                                                return (<option disabled={e === 'Select Anyone' && true} value={e === 'Select Anyone' ? '' : e} key={i}>{e}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                        <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeOtherSkill(i)} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    {/* <InputFields
                                        label={"Work Experience"}
                                        name="work_experience"
                                        defaultValue={formValues.work_experience}
                                        type="number"
                                        placeholder="Work Experience"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.work_experienceErr}
                                    /> */}
                                    <div className="formInput">
                                        <label >
                                            Work Experience
                                        </label>
                                        <select
                                            name={"work_experience"}
                                            onChange={handleChange}
                                            defaultValue={formValues.work_experience}
                                        >

                                            {experienceArr.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="formInput">
                                        <label >
                                            Experience(Year/Month)
                                        </label>
                                        <select
                                            name={"type"}
                                            onChange={handleChange}
                                            value={formValues.type}
                                        >

                                            {GetLocalstorage.get_default_month_year().map((e, i) => {
                                                return (<option value={e === 'Select Anyone' ? '' : e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                        {<p style={{ color: "#96332c", fontSize: 13 }}>{errStatus === true && formValues.type === '' ? "Please Select Month/Year" : []}</p>}
                                    </div>


                                    <InputSelectField
                                        label={"Industry"}
                                        name={"industry"}
                                        placeholder={"Industry"}
                                        onChange={handleChange}
                                        defaultValue={formValues.industry}
                                        errStatus={errStatus}
                                        formErr={formErr.industryErr}
                                        optionList={industry_AllList?.data && industry_AllList.data.rows ? industry_AllList.data.rows : []}
                                        optionLevel={['industry_name']}
                                        optionValue={'id'}
                                    />
                                    <InputMultiSelectField
                                        label={"Preferred Industry"}
                                        name={"preferred_industry"}
                                        placeholder={"Preferred Industry"}
                                        onRemove={handleMultiIndustryChange}
                                        onSelect={handleMultiIndustryChange}
                                        defaultValue={formValues.preferred_industry}
                                        optionList={industry_AllList?.data && industry_AllList.data.rows ? industry_AllList.data.rows : []}
                                        optionLevel={['industry_name']}
                                        optionValue={'id'}
                                    />
                                    <InputRadioGroupFields
                                        label={"Status"}
                                        name="status"
                                        defaultValue={formValues.status}
                                        type="text"
                                        placeholder="Status"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.statusErr}
                                    />
                                </div>
                                <Button style={{ minWidth: '17%' }} className="submit-modal" onClick={submitData}>{ID ? 'Preview and Update' : 'Preview And Submit'}</Button>
                            </form>
                        </DialogContent>
                    }
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={`${statusJobPost}`}
                    paragraph={`Are you sure to ${statusJobPost} job-post`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>

            {/*  default screening form */}
            {openScreenigForm === true ?
                <DefaultScreeningForm title='jobpost' closeModal={closeModalScreening}
                    jobId={job_id}
                    editJobScreening={editJobScreening}
                    filterQuery={filterQuery}
                />
                : ""
            }
            {/*  default screening form */}
            {openAssignApprover === true ?
                <ApproverForm
                    approverList={resourceListDropDown}
                    handleClose={closeAssignApprover}
                    handleChange={handleChangeApproverForm}
                    submitData={submitApprover}
                    open={openAssignApprover}
                    fieldType={fieldType}
                />
                : ""
            }

            {/* <dialog open = {openAssignApprover}>
        <Select
                                className="select-a"
                                placeholder="Select Default Screening Questions"
                                name={"default_screening_form"}
                                isClearable={true}
                                // options={
                                //     defaultScreeningForm_dropDown && defaultScreeningForm_dropDown.map((option) => {
                                //         return { label: option.title, value: option.id, name: "default_screening_form", key: option.id }
                                //     })
                                // }
                                // onChange={(e) => changeDefaultScreeningForm(e)}
                            />
        </dialog> */}

        </div >
    )
}

export default JobPostList