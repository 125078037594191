import './manageCredential.scss'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { credentialHeadUpdate, getCredentialHead } from '../../../redux/features/cedentialHeadSlice';
import { credentialHeadColumns } from "../../../components/datatable-coulms/tableCoulmns";
import dataService from "../../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import { Button, Dialog, DialogContent, Stack, Switch, TablePagination, Tooltip, Menu, MenuItem, Skeleton } from "@mui/material";
import LoaderForm from '../../../components/common-function/LoaderForm';
import UcActionComponent from '../../../components/common-function/UcActionComponent';
import { STATUSES } from "../../../redux/common-status/constant";
import TablePaginationActions from '../../../components/TablePaginationActions';
import AddIcon from '@mui/icons-material/Add';
import CustomNoRowsOverlay from '../../../components/CustomEmptyOverlay';
import { RefreshButton } from '../../../components/common-function/RefreshButton';
import GetLocalstorage from '../../../components/common-function/GetLocalstorage';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { DataGridPro, gridClasses, getGridStringOperators } from '@mui/x-data-grid-pro';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Close, Edit } from "@mui/icons-material";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DataGridProLazyLoadHOC from '../../../components/common-function/DataGridProLazyLoadHOC';
import DeleteDialogModal from '../../../components/common-function/DeleteDialogModal';
import DialogTitle from '@mui/material/DialogTitle';
import AddCredentialHead from './AddCredentialHead';


let flag = 1;

const filterableColumns = ['head_name', 'description', 'status'];


const inputs = [{ id: 1, label: "Head Name", name: "head_name", type: "text", placeholder: "Head Name" },
{ id: 2, label: "Description", name: "description", type: "text-area", placeholder: "Description" }]

const PaperComponent = (props) => {
  return (
    <Draggable
      bounds="body"
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function SubAllCredentialHeads({ onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu }) {

  const dispatch = useDispatch();
  const { data, status, error, update_credential_head, credential_head_create } = useSelector(state => state.credentialHead)
  const { data: list } = data

  const [open, setOpen] = useState(false);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [archiveId, setArchiveId] = useState(0)
  const [changeStatus, setChangeStatus] = useState('');
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [showProjectForm, setShowProjectForm] = useState(false)
  let isAnyChange = useRef({});
  const [editableID, setEditId] = useState()
  const [archiveOpen, setArchiveOpen] = useState(false)


 





  const handleArchive = (id) => {
    //ishandleClickArchiveActive.current = true;
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }


  const handleOpen = () => {
    setShowProjectForm(true);
    setEditId('')
  }

  const handleClickArchive = (data) => {

    setArchiveOpen(false)
    let addvalues = {
      description: data.description,
      head_name: data.head_name,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }
    clearGridListing.current = true;
    editID.current = { ...data };
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item => item.id === data.id);
    copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
    setGridRows(copyRows);
    let ID = data.id;
    flag = 0;
    dispatch(credentialHeadUpdate({ ID, addvalues }))
  }




  const handleClose = () => {
    setOpen(false);
    setShowProjectForm(false);
    setContextMenu(null);
  }

  const handleMenuAction = (actionType) => {
    let row = gridRows.find(item => item.id === selectedRow);
    switch (actionType) {
      case 'edit':
        handleClickEdit(row.id)
        break;
    }
    setContextMenu(null);
  }

  const handleClickEdit = (id) => {
    flag = 0
    setEditId(id)
    setShowProjectForm(true)
  }

  const filterOperators = getGridStringOperators().filter(({value}) => ['contains', 'equals','startsWith', 'endsWith'].includes(value))

  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false, hideable: false },
    {
      field: "head_name",
      filterOperators,
      // headerName: "Head Name",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Head Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("head_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      minWidth: 250,
      sortable: false,
      pinnable: false,
      renderCell: (params) => {
        return (
          params.row.head_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div>
              {params.row.head_name}
            </div>
        )
      }
    },
    {
      field: "description",
      filterOperators,
      // headerName: "Description",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      minWidth: 330,
      pinnable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div>
              {params.row.description}
            </div>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      pinnable: false,
      type: 'singleSelect',
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      sortable: false,
      renderCell: (params) => {
        return (
          params?.row?.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Switch name="status" checked={params?.row?.status}
                onChange={() => handleArchive(params?.row)}
              />
              <div className="actionButton">
                <UcActionComponent moduleName="credential"
                  rowid={params.row.id}
                  editLinkUrl=""
                  viewLinkUrl=""
                  editButton={handleClickEdit}
                  viewButton={false}
                />
              </div>
            </div>

        );
      },
    }
  ];





  if (flag === 0 && status === `${STATUSES.SUCCESS}_update_credential_head` && update_credential_head && update_credential_head.message) {

    if (update_credential_head.status === 200) {
      let copyGridRows = [...gridRows].map(item => {
        if(editID.current.id === item.id)
          return { ...update_credential_head.data}
        else
          return item;
      })
      setGridRows(copyGridRows)
      handleClose();
      toast.success(update_credential_head.message)
      // let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      // setFilterQuery(str)
      // setGridRows([]);
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.error(update_credential_head.message)
    }
    flag = 1;
  }

  if (reload === true && status === STATUSES.SUCCESS && data && data.message) {

    if (data.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...data.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(data.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...data.data.rows]);
        SET_MAX_ROW_LENGTH(data.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(getCredentialHead.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  if (reload = true && status === `${STATUSES.SUCCESS}_create_credential_head` && credential_head_create && credential_head_create.message) {
    if (credential_head_create.status === 200) {
      toast.success(credential_head_create.message)
      setGridRows([]);
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: 25 }));
      setFilterQuery(str)
    } else {
      toast.error(credential_head_create.message)
    }
    setReload(false)
  }




  return (
    <div className="new">
      <div className="newContainer">
        <div className="datatable">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px", marginTop: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>All Credential Head</p>
            </div>
            <div>
              <Stack direction="row">
                <Button variant="outlined" onClick={handleOpen}>
                  Create<BorderColorTwoToneIcon />
                </Button>
                <RefreshButton api={getCredentialHead} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 25 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
              </Stack>
            </div>
          </div>

          <ToastContainer />
          {/* <div className="custom-pagination-class">
            <CustomPagination />
          </div> */}
          <DataGridPro
            className="datagrid"
            filterMode='server'
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={gridRows?.concat(skeletonRows)}
            columns={actionColumn}
            // pageSize={pageSize}
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            onFilterModelChange={onFilterChange}
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            onSortModelChange={(sort) => handleSort(sort)}
            hideFooterPagination={true}
            hideFooter={true}
          />
        </div>

        {/* for delete popup */}

        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} hiring mode`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={showProjectForm}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}>
          <Button
            edge="start"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
            <Close />
          </Button>

          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">
            {editableID ? 'Edit Employment Type' : 'Add New Employment Type'}
            {/* Add New Employment Type */}
          </DialogTitle>

          <DialogContent>
            {editableID ? <AddCredentialHead showProjectForm={showProjectForm} id={editableID} handleclose={handleClose} inputs={inputs} /> : <AddCredentialHead showProjectForm={showProjectForm} handleclose={handleClose} inputs={inputs} />}
          </DialogContent>
        </Dialog>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              }
            }
          }}
        >

          <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
          {/* <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem> */}
        </Menu>
      </div>

    </div>
  )
}


const AllCredentialHeads = DataGridProLazyLoadHOC(SubAllCredentialHeads, { filterColumns: [], getDispatcherAsyncThunk: getCredentialHead, gridColumns: ['head_name', 'description', 'status'] });
export default AllCredentialHeads
