import './phoneCallDetails.scss';
import { ArrowBack, Download } from '@mui/icons-material';
import { TablePagination, Tooltip, FormControl, Checkbox, Card, CardContent, Grid, Stack, ListItem, List, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getCallLogDetails, defaultCallFilter, getReportCallLogDetails, filterForPagination, getAgentWiseResource,defaultAllPhoneCallDetailsColumn } from '../../redux/features/callingSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import Select from "react-select";
import { getMyJobList, getTaskList } from '../../redux/features/taskSlice';
import { DateRangePicker } from 'rsuite';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { StoryDetails } from '../task/StoryDetails';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import MoreFilters from '../../components/moreFilters/MoreFIlters';

const roleWiseFilter = `{"order_by":{"column":"-id"}, "no_limit":True}&columns={"val":{"id","summary","ticket_key"}}`
const { REACT_APP_ENV, REACT_APP_API_BASEURL } = process.env;

const default_phoneCallDetails_columns = LocalStorageDataService.get_default_AllphoneCallDetails_column()

export const PhoneCallDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { callDetails, callLogReport, status, error, agentResourceList,defaultAllphoneColumns } = useSelector(state => state.callingSlice)
    const { data: list } = callDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_phoneCallDetails_columns?.phone_call_details_column ? default_phoneCallDetails_columns.phone_call_details_column : {
        recording_url: false,
        id:false,
    });

    //filter
    const [, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [, setDefaultValue] = useState({})
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterProjectList, setFilterProjectList] = useState([]);
    const [downliadFile, setDownloadFile] = useState(false)
    //const [filterStoryList, setFilterStoryList] = useState([]);
    const [selectResource, setSelectResource] = useState("")
    const [selectCheckResource, setSelectCheckResource] = useState(false)

    const [selectEpic, setselectEpic] = useState("")
    const [selectCheckEpic, setselectCheckEpic] = useState(true)
    const [selectedStory, setSelectedStory] = useState("")
    const [selectedCallingType, setSelectedCallingType] = useState("")
    const [selectedCheckStory, setSelectedCheckStory] = useState(true)
    const { taskList, myJobList } = useSelector(state => state.task)
    const [activeEpic, setActiveEpic] = useState(false)
    const [dateAndTime, setDateAndTime] = useState([new Date(), new Date(new Date().getTime() + (24 *60 *60 * 1000))])
    const [dateAndTimecheck, setDateAndTimecheck] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [isRecordingcheck, setisRecordingcheck] = useState(false)
    const [loading, setLoading] = useState(true)
    const pagination_filter = LocalStorageDataService.get_pagination_filter_all_call();
    const [filterQuery, setFilterQuery] = useState( pagination_filter ? JSON.stringify(pagination_filter) : `{ "skip":0,"limit":25,"order_by":{"column":"-id"}}`)
    const [paginationFilterstate] = useState(pagination_filter ? JSON.stringify(pagination_filter) : filterQuery)
    const [filterQue, setFilterQue] = useState(localStorage.getItem(`${REACT_APP_ENV}_call_filter`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_call_filter`)) : '')
    const [columnFilter, setColumnFilter] = useState('')
    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDives, setActiveDives] = useState("")
    const [isBgColor, setIsBgColor] = useState(false)
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    if (loading === true && callDetails && callDetails.message) {
        if (callDetails.status === 200) {
        } else {
            toast.error(callDetails.message)
        }
        setLoading(false)
    }
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
            setDownloadFile(false)
        }
    }, [error])

    if (filterResourceList && filterResourceList.length === 0 && agentResourceList) {
        let array = []
         agentResourceList?.data && agentResourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name + "(" + e.email + ")" }) })
        array.length > 0 && setFilterResourceList(array)
    }
    if (filterProjectList && filterProjectList.length === 0 && myJobList) {
        let array = []
       myJobList?.data && myJobList.data.rows.map(e => { return array.push({ value: e.id, label: e.ticket_key }) })
        const arrayUniqueByKey = [...new Map(array.map(item =>
            [item['value'], item])).values()];
        arrayUniqueByKey.length > 0 && setFilterProjectList(arrayUniqueByKey)
    }

    if (activeEpic === true && taskList) {
        setActiveEpic(false)
        let array = []
        taskList?.data && taskList.data.rows.map(e => {
            return array.push({ value: e.id, label: e.ticket_key })
        })
       
        array.length > 0 && setOptions({ ...options, story: array })
    }

    const onchangeEpic = (evt) => {
        setSelectedStory("")
        setselectEpic(evt)
        setTimeout(() => {
            setActiveEpic(true)
        }, 500);

      evt?.value && dispatch(getTaskList(`{"filter":{"epic_id":{"value":${evt.value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

    }
    const clear = () => {
        setSelectedStory("")
        setSelectCheckResource(true)
        setselectCheckEpic(true)
        setisRecordingcheck(true)
        setDateAndTime([new Date(), new Date(new Date().getTime() + (24 *60 *60 * 1000))])
        setDateAndTimecheck(true)
        setIsRecording(false)
        setSelectResource('')
        setSelectedCallingType("");
        setselectEpic("")
        setFilterQue("")
        setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`)
        localStorage.removeItem(`${REACT_APP_ENV}_call_filter`)
        setLoading(true)
        dispatch(getCallLogDetails(`{"filter":{"created_on":{"value":${`["${moment(new Date()).utc(true).format('YYYY-MM-DD HH:mm:ss')}", "${moment(new Date(new Date().getTime() + (24 *60 *60 * 1000))).utc(true).format('YYYY-MM-DD HH:mm:ss')}"]`},"operation":"range"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`))
    }
    const setKeyForList = (obj) => {
        let data;
        if (default_phoneCallDetails_columns) {
            data = { 'phone_call_details_column': obj, 'reported_phoneCallDetail_column': default_phoneCallDetails_columns.reported_phoneCallDetail_column, 'my_phone_calldetails_column': default_phoneCallDetails_columns.my_phone_calldetails_column, 'phone_column': default_phoneCallDetails_columns.phone_column }
        } else {
            data = { 'phone_call_details_column': obj }
        }
        if (defaultAllphoneColumns.reported_phoneCallDetail_column) {
            data['reported_phoneCallDetail_column'] = defaultAllphoneColumns.reported_phoneCallDetail_column
        }
        if (defaultAllphoneColumns.my_phone_calldetails_column) {
            data['my_phone_calldetails_column'] = defaultAllphoneColumns.my_phone_calldetails_column
        }
        if (defaultAllphoneColumns.phone_column) {
            data['phone_column'] = defaultAllphoneColumns.phone_column
        }
        dispatch(defaultAllPhoneCallDetailsColumn(data))
    }

    const search = () => {
        let array = []
        selectResource && selectResource.length > 0 && selectResource.map((o) => array.push(o.value));
        let filterSetCondition = { "datetime": dateAndTime, 'resources': selectResource, 'epic': selectEpic, 'story': selectedStory, 'checkbox': isRecording, 'direction': selectedCallingType }
        let filterCondition = { filter: {} }
        let date_time_filter = dateAndTime && dateAndTime.length > 0 && { "value": [moment(new Date(dateAndTime[0])).utc().format('YYYY-MM-DD HH:mm:ss'), moment(new Date(dateAndTime[1])).utc().format('YYYY-MM-DD HH:mm:ss')], "operation": "range" }

        if (dateAndTime && dateAndTime.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "created_on": date_time_filter,
                }
            }
        }

        if (selectResource && selectResource.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "resource_id": { "value": array, "operation": "in" }
                }
            }
        }
        if (selectEpic) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "epic_id": { "value": `${selectEpic.value}`, "operation": "eq" },
                }
            }
        }
        if (selectedStory) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "story_id": { "value": `${selectedStory.value}`, "operation": "eq" },
                }
            }
        }

        if (selectedCallingType) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "direction": { "value": `${selectedCallingType.value}`, "operation": "eq" },
                }
            }
        }

        if (isRecording === true) {
            filterCondition = {
                ...filterCondition,
                "is_recording_url": "True"
            }
        }

        dispatch(defaultCallFilter(filterSetCondition))
        if (isRecording === true) {
            filterCondition['is_recording_url'] = "True"
        }
        filterCondition['skip'] = 0
        filterCondition['limit'] = limit
        filterCondition['order_by'] = { "column": '-id' }
        setFilterQuery(JSON.stringify(filterCondition))
        let paginationFilter = { "skip": 0, "limit": limit, 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(paginationFilter))
        setLoading(true)
        dispatch(getCallLogDetails(JSON.stringify(filterCondition)))
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }

    if (downliadFile === true && callLogReport && callLogReport.data) {
        let api_url = callLogReport?.data && callLogReport.data.download_api_url;
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const downloadCsvFile = () => {
        setDownloadFile(true)
        let newFilter = JSON.parse(filterQuery)
        newFilter['for_download'] = "True"
        newFilter['no_limit'] = "True"
        dispatch(getReportCallLogDetails(JSON.stringify(newFilter)))
    }

    const setDefaultFilter = () => {
        let to_date = filterQue.datetime && filterQue.datetime.length > 0 && new Date(filterQue.datetime[0])
        let to_date_1 = filterQue.datetime && filterQue.datetime.length > 0 && new Date(filterQue.datetime[1])
        let datetime = filterQue.datetime && filterQue.datetime.length > 0 ? filterQue.datetime : []
        let resources = filterQue.resources && filterQue.resources.length > 0 ? filterQue.resources : []
        let epic = filterQue.epic
        let story = filterQue.story
        let direction = filterQue.direction
        let array = []
        let check = filterQue.checkbox
        setSelectedCallingType(direction)
        setSelectResource(resources)
        setselectEpic(epic)
        setSelectedStory(story)
        to_date && setDateAndTime([(to_date), to_date_1])
        setIsRecording(check)
        if (epic) {
            setTimeout(() => {
                setActiveEpic(true)
            }, 500);

            dispatch(getTaskList(`{"filter":{"epic_id":{"value":${epic.value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True,"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

        }
        let filterCondition = { filter: {} }
        resources && resources.length > 0 && resources.map((o) => array.push(o.value));
        let date_time_filter = datetime && datetime.length > 0 && { "value": [moment(new Date(datetime[0])).utc().format('YYYY-MM-DD HH:mm:ss'), moment(new Date(datetime[1])).utc().format('YYYY-MM-DD HH:mm:ss')], "operation": "range" }

        if (datetime && datetime.length > 0) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "created_on": date_time_filter,
                }
            }

        }
        if (resources && resources.length > 0) {
            filterCondition = { "filter": { ...filterCondition.filter, "resource_id": { "value": array, "operation": "in" } } }

        }
        if (epic) {
            filterCondition = { "filter": { ...filterCondition.filter, "epic_id": { "value": `${epic.value}`, "operation": "eq" } } }

        }
        if (story) {

            filterCondition = { "filter": { ...filterCondition.filter, "story_id": { "value": story.value, "operation": "eq" } } }
        }

        if (direction) {
            filterCondition = {
                "filter": {
                    ...filterCondition.filter,
                    "direction": { "value": `${direction.value}`, "operation": "eq" },
                }
            }
        }
        if (check === true) {
            filterCondition = { ...filterCondition.filter, "is_recording_url": "True" }

        }
        if (check !== true && !datetime && !resources && !epic && !story) {
            filterCondition = { ...filterCondition.filter, "skip": 0, "limit": limit, "order_by": { "column": "-id" } }
        }

        // Cleanup Section End

        let parse_pagination = JSON.parse(paginationFilterstate)
        filterCondition['skip'] = parse_pagination?.skip ? parse_pagination.skip : 0
        filterCondition['limit'] = parse_pagination?.limit ? parse_pagination.limit : limit
        filterCondition['order_by'] = { "column": '-id' }
        setFilterQuery(JSON.stringify(filterCondition))
        let paginationFilter = { "skip": filterCondition['skip'], "limit": filterCondition['limit'], 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(paginationFilter))
        setLoading(true)
        let newFilter = {filter:{created_on:{...filterCondition.created_on}}};        
        delete filterCondition["created_on"];
        newFilter={...newFilter, ...filterCondition}
        dispatch(getCallLogDetails(JSON.stringify(newFilter)))
        // }
    }

    useEffect(() => {
        setIsBgColor(false)
        if (location?.state && location.state.filter) {
            setIsBgColor(true)
            setFilterQuery(JSON.stringify(location.state.filter))
            dispatch(getCallLogDetails(JSON.stringify(location.state.filter)))
        } else if (filterQue) {
            setDefaultFilter()
        } else if (filterQue === '') {
            let filter = `{"filter":{"created_on":{"value":${`["${moment(dateAndTime[0]).utc(true).format('YYYY-MM-DD HH:mm:ss')}", "${moment(dateAndTime[1]).utc(true).format('YYYY-MM-DD HH:mm:ss')}"]`},"operation":"range"}},"skip":0,"limit":25,"order_by":{"column":"-id"}}`;
            dispatch(getCallLogDetails(filter))
        }
        dispatch(getMyJobList(roleWiseFilter))
        dispatch(getAgentWiseResource(`{"order_by":{"column":"-id"}, "no_limit":True, "columns":["id", "display_name", "email"]}`))
        setpaginationFilterFunction(paginationFilterstate)
    }, [])

    const updateDateTime = (evt) => {
        setDateAndTime(evt)
    }

    const getRowHeight = (params) => {
        const storyName = params.model.story_ticket_key
        const epicName = params.model.epic_ticket_key

        if (storyName || epicName) {
            const noOfStoryLines = Math.ceil(storyName?.length / 32)
            const noOfEpicLines = Math.ceil(epicName?.length / 32)

            let maxNoOfLines = 2;
            if (typeof noOfStoryLines !== "number") {
                maxNoOfLines = noOfEpicLines
            }
            else if (typeof noOfEpicLines !== "number") {
                maxNoOfLines = noOfStoryLines
            }
            else {
                maxNoOfLines = Math.max(noOfStoryLines, noOfEpicLines)
            }
            const rowHeight = (maxNoOfLines * 32)
            return rowHeight

        }
        return 40 //default row height 
    }

    const getRowClassName = (params) => {
        if (isBgColor === true && params?.row?.bgcolor) {
            return params?.row?.bgcolor === 'gray' ? 'gray-row' : 'white-row';
        } else {
            return '';
        }
    }


    const setpaginationFilterFunction = (obj) => {
        const data = JSON.parse(obj);
        setPageSize(data.limit)
        const current = Math.ceil((data.skip) / (data.limit))
        setCurrentPage(current)
        setLimit(data.limit)
        dispatch(filterForPagination(obj))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        let filter_pagination = { "skip": newFilterData['skip'], "limit": newFilterData['limit'], 'order_by': { "column": '-id' } }
        setpaginationFilterFunction(JSON.stringify(filter_pagination))
        setLoading(true)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        let filter_pagination = { "skip": newFilterData['skip'], "limit": newFilterData['limit'], 'order_by': { "column": '-id' } }
        setFilterQuery(JSON.stringify(newFilterData))
        setpaginationFilterFunction(JSON.stringify(filter_pagination))
        setLoading(true)
        dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setColumnFilter('')
                setCurrentPage(0)
                setDefaultValue("")
                setFilterQuery( `{"filter":{"created_on":{"value":["2023-03-01 13:21:11","2023-03-02 13:21:11"],"operation":"range"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`)
                dispatch(getCallLogDetails( `{"filter":{"created_on":{"value":["2023-03-01 13:21:11","2023-03-02 13:21:11"],"operation":"range"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue
            
            let newFilterData

            if (filterQue) {
                if (typeof filterQuery === 'string') {
                    newFilterData = JSON.parse(filterQuery)
                } else {
                    newFilterData = filterQuery
                }

                newFilterData['filter'][filterModel.items[0].columnField] = { "value": filterModel.items[0].value, "operation": operatorValue }
                newFilterData['filter']['created_on'] = {"value":[moment(dateAndTime[0]).utc().format('YYYY-MM-DD HH:mm:ss'),moment(dateAndTime[1]).utc().format('YYYY-MM-DD HH:mm:ss')],"operation":"range"}
                setFilterQuery(JSON.stringify(newFilterData))
                dispatch(getCallLogDetails(JSON.stringify(newFilterData)))
                setColumnFilter(JSON.stringify(newFilterData))
            } else {
                let date = `["${moment(dateAndTime[0]).utc().format('YYYY-MM-DD HH:mm:ss')}", "${moment(dateAndTime[1]).utc().format('YYYY-MM-DD HH:mm:ss')}"]`
                newFilterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}, "created_on":{"value":${date},"operation":"range"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
                setFilterQuery(newFilterData)
                dispatch(getCallLogDetails((newFilterData)))
                setColumnFilter((newFilterData))
            }
            setCurrentPage(0)
        }
      
    }

    const activeStoryDiv = (id) => {
        setActiveDives(`story_${id}`)
        if (storyDiv > 0) { setStoryDiv(id) } else { setStoryDiv(id); }
    }

    const nothing = () => { 
        //nothing
    }

    const phoneCallDetailsColumns = [
        { field: 'id', headerName: 'ID', width: 10, filterable: false },
        { field: 'destination_number', headerName: 'Destination Number', width: 160, },
        { field: 'resource_name', headerName: 'Resource', width: 250, filterable: false },
        { field: 'call_duration', headerName: 'Call Duration(in sec)', width: 200 },
        { field: 'answer_duration', headerName: 'Actual Duration(in sec)', width: 200 },
        {
            field: "direction", headerName: "Call Type", width: 200,
            type: 'singleSelect',
            filterable: false,
            valueOptions: [{ value: 0, label: 'In-bound' }, { value: 1, label: 'Out-bound' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.direction}`}>
                        {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
                    </div>
                );
            },
        },
        {
            field: "call_date", headerName: "Call Date", type: 'date', width: 200, filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.call_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: 'call_time',
            headerName: 'Call Time',
            width: 150, filterable: false
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.description ? params.row.description : ""} >
                        <div className="cellWithStatus">
                            {params.row.description}
                        </div>
                    </Tooltip >
                )
            },
        },
        {
            field: 'epic_ticket_key',
            headerName: 'Jobs',
            width: 300, filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus" style={{ whiteSpace: 'normal' }}>
                        {
                            params.row.epic_id ?
                                <Tooltip title="View Jobs">
                                    <Link to={`/epic-task/${params.row.epic_id}`} >{params.row.epic_ticket_key}</Link>
                                </Tooltip> : "-"
                        }
                    </div>
                )
            }
        },
        {
            field: 'story_ticket_key',
            headerName: 'Resumes',
            width: 250,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {
                            params.row.story_id ?
                                <div style={{ cursor: 'pointer', color: '#0052cc', whiteSpace: 'normal' }} className="cellWithStatus" onClick={() => params.row.story_id ? activeStoryDiv(params.row.story_id) : nothing()} >
                                    <Tooltip title="Resume">
                                        <p>{params.row.story_ticket_key}</p>
                                    </Tooltip>
                                </div>

                                : "-"
                        }
                    </div>
                );
            }
        },
        {
            field: 'recording_url',
            headerName: 'Call Recording Link',
            width: 350,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.recording_url ?
                            <ReactAudioPlayer
                                src={params.row.recording_url}
                                autoPlay={false}
                                controls
                                style={{ height: '30px' }}
                            />
                            : "-"}
                    </div>
                )
            }
        },
    ]

    let i = 0

    const FilterComponents = () => {
        return (
            <>
                <List>
                    <ListItem>
                        <FormControl style={{ flexBasis: "100%" }}>
                            <DateRangePicker
                                    format="yyyy-MM-dd hh:mm aa"
                                    placeholder="yyyy-mm-dd hh:mm aa ~ yyyy-mm-dd hh:mm aa"
                                    showMeridian
                                    value={dateAndTimecheck === false && filterQue.datetime && filterQue.datetime.length > 0 ? [(new Date(filterQue.datetime[0])), new Date(filterQue.datetime[1])] : dateAndTime}
                                    onChange={(evt) => updateDateTime(evt)}
                                    onClean={(evt) => {
                                        setFilterQue({ ...filterQue, datetime: [] }); updateDateTime([])
                                    }}
                            />
                        </FormControl>
                    </ListItem>
                    
                    <ListItem>
                         <FormControl style={{ flexBasis: "100%"}}>
                            <Select
                                placeholder="Select Job"
                                isClearable
                                value={(selectCheckEpic === false && filterQue.epic) ? filterQue.epic : selectEpic}
                                onChange={(evt) => { onchangeEpic(evt); setselectCheckEpic(true) }}
                                width='300px'
                                options={filterProjectList} />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                         <FormControl style={{ flexBasis: "100%"}}>
                                <Select
                                    isDisabled={activeEpic === true ? true : false}
                                    placeholder="Select Resume"
                                    isClearable
                                    value={selectedCheckStory === false && filterQue.story ? filterQue.story : selectedStory}
                                    onChange={(evt) => { setSelectedStory(evt); setSelectedCheckStory(true) }}
                                    width='300px'
                                    //options={filterStoryList}
                                    options={selectEpic ? taskList && taskList.data && taskList.data.rows && taskList.data.rows.length > 0 ? taskList.data.rows.map(e => { return { value: e.id, label: e.ticket_key } }) : [] : []}
                                />
                            </FormControl>
                    </ListItem>

                    <ListItem>
                    <FormControl style={{ flexBasis: "100%"}}>
                                <Select
                                    placeholder="Select Resources"
                                    isMulti
                                    value={selectCheckResource === false && filterQue.resources && filterQue.resources.length > 0 ? filterQue.resources : selectResource}
                                    onChange={(evt) => { setSelectResource(evt); setSelectCheckResource(true) }}
                                    width='300px'
                                    options={filterResourceList} />
                            </FormControl>
                    </ListItem>

                    <ListItem>
                    <FormControl style={{ flexBasis: "100%" }}>
                                <Select
                                    placeholder="Select Call Type"
                                    isClearable
                                    value={selectedCallingType === false && filterQue.direction ? filterQue.direction : selectedCallingType}
                                    onChange={(evt) => { setSelectedCallingType(evt); setFilterQue({ ...filterQue, direction: evt }) }}
                                    width='300px'
                                    options={[
                                        { id: 1, label: "In-bound", value: 0 },
                                        { id: 2, label: "Out-bound", value: 1 },
                                    ]}
                                />
                            </FormControl>
                    </ListItem>
                        
                    <ListItem>
                        <div style={{display:'flex', alignItems:'center', width:'100%', justifyContent:'flex-start', border:'1px solid rgba(0,0,0,.2)', borderRadius:'5px'}}>
                            <FormControl>
                                <Checkbox onChange={() => { setIsRecording(!isRecording); setisRecordingcheck(true) }} checked={isRecordingcheck === false && filterQue.checkbox === true ? true : isRecording} />
                            </FormControl>
                            <p style={{ textAlign: 'left' }}>Filter list with phone call recording</p>
                        </div>
                    </ListItem>
                </List>
            </>
        )
    }

    // advance search - searchHandler
    const handleFilterSearch = (drawerOpen) =>
    {
        search();
        drawerOpen(false);
    }

    // advance search - resetHandler
    const handleFilterReset = () =>
    {
        clear();
    }

    return (
        storyDiv > 0 ?
            <div className="task">
                <div className="taskContainer">
                    <StoryDetails
                        epicId={storyDiv}
                        hideDiv={setStoryDiv}
                        activeDivs={activeDives}
                    />
                </div>
            </div>
            :
            <div className="phoneCallListing">
                <div className="phoneCallListingContainer">
                    <div className="datatable">
                        <div className="datatableTitle">
                            <h3>All Phone Call Details</h3>
                            <div style={{display:'flex', alignItems:'flex-end'}}>
                                {/* advance search filter start */}
                                <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}__callLogReport` || status === `${STATUSES.LOADING}__agentResourceList`) ? true : false} inlineStyle={{marginRight:'5px'}}/>
                                {/* advance search filter end */}
                                <RefreshButton api={getCallLogDetails} filter={filterQuery} style={{ marginRight: 5 }} />
                                <Tooltip title="Back">
                                    <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                                </Tooltip>
                            </div>
                        </div>

                        <ToastContainer />
                        {(loading === true || downliadFile === true || status === `${STATUSES.LOADING}__callDetails`) && <LoaderForm />}
                           <Card>
                                <CardContent>
                                <Grid
                                    container
                                    rowSpacing={1}
                                >
                                    <Grid item md={7}>
                                        <CustomPagination />
                                    </Grid>
                                    <Grid item md={5}>
                                    <Stack
                                        style={{
                                        justifyContent: "flex-end",
                                        marginTop: "6px",
                                        display:'flex'
                                        }}
                                        direction={"row"}
                                    >

                                        <div style={{ marginLeft: "3px" }}>
                                            <Button startIcon={<Download/>} disabled={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}__callLogReport` || status === `${STATUSES.LOADING}__agentResourceList`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__callDetails` || status === `${STATUSES.LOADING}__callLogReport` || status === `${STATUSES.LOADING}__agentResourceList`) ? { background:'grey' ,flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { background:'#0052cc', flexBasis: "auto", marginLeft: "6px" }} onClick={() => downloadCsvFile()} >CSV</Button>
                                        </div>
                                    </Stack>
                                    </Grid>
                                </Grid>
                                {
                                    !reload &&
                                    <DataGrid
                                        className="datagrid"
                                        columnVisibilityModel={defaultAllphoneColumns?.phone_call_details_column ? defaultAllphoneColumns.phone_call_details_column : columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                                        }
                                        rows={list?.rows ? list.rows : []}
                                        columns={phoneCallDetailsColumns}
                                        pageSize={pageSize}
                                        style={{ fontSize: 16 }}
                                        getRowId={(row) => row + i++}

                                        onFilterModelChange={onFilterChange}
                                        components={{
                                            Pagination: CustomPagination,
                                            NoRowsOverlay: CustomNoRowsOverlay
                                        }}
                                        filterMode="server"
                                        getRowHeight={getRowHeight}
                                        getRowClassName={getRowClassName}
                                        hideFooterPagination={true}
                                    />
                                    }
                                </CardContent>
                            </Card>
                    </div>

                </div>
            </div>
    )
}
