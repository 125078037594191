import "./task.scss"
import { ToastContainer, toast } from "react-toastify";
import { TablePagination, Tooltip ,DialogContent, Dialog, DialogTitle, Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { workHistory } from "../../redux/features/taskSlice";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { DataGrid} from "@mui/x-data-grid";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { Close } from '@mui/icons-material';
import FileViewer from "react-file-viewer";
import Gallery from 'react-image-gallery';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
const { REACT_APP_API_BASEURL } = process.env;
const MyWorkHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"unique_id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MY_WORK_HISTORY}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const {workHistory_Data,status}=useSelector(state=>state.task)
     
    

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false})
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    const [open, setOpen] = useState(false);
    const [attachments, setAttachments] = useState()

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(workHistory(JSON.stringify(newFilterData)))
    }
 


    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(workHistory(JSON.stringify(newFilterData)))
    }

useEffect(()=>{
dispatch(workHistory(filterQuery))
},[])

if(workHistory.message==='Success' && status===`${STATUSES.SUCCESS}_workHistory`)
{
    setReload(false)
}

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={workHistory_Data?.data?.row_count && workHistory_Data.data.row_count || 0 }
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={LocalStorageDataService.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }


    const handleClickView = (data) => {
        
        setOpen(true)
              if(data.candidate_file_name || data.candidate_file_upload_id){
              let fileIs =data?.candidate_file_name.split(".") 
              let lengthIs = fileIs.length - 1
              let  attachmentIs=({ uri: REACT_APP_API_BASEURL.replace("api/", "api") +"/file/download/"+ `${data?.candidate_file_upload_id}`, type: fileIs[lengthIs] })
              setAttachments(attachmentIs)
              }
            else{
                toast.error("Resume not available")
                setAttachments({});
                setOpen(false)
            }
    }
    const actionColumn = [
        {
            field: "id", headerName: "ID", width: 150,
        },
        {
            field: "candidate_id", headerName: "Candidate_id", width: 150,
            renderCell:(params)=>{
                return(
                    <div className={`cellWithStatus`}>
                        {params?.row?.candidate_id ?? "NA"}
                    </div>
                )
            }  
        },
        {
            field: "candidate_name", headerName: "Condidate_Name", width: 250,
            renderCell:(params)=>{
                return(
                    <div className={`cellWithStatus`}>
                        {params?.row?.candidate_name ?? "NA"}
                    </div>
                )
            }
        },
  
         {field: "candidate_email", headerName: "Email", width: 270,
         renderCell:(params)=>{
            return(
                <div className={`cellWithStatus`}>
                    {params?.row?.candidate_email ?? "NA"}
                </div>
            )
        }
        
        },
         { 
         field: "candidate_phone", headerName: "Contact_no", width: 180,
         renderCell:(params)=>{
            return(
                <div className={`cellWithStatus`}>
                    {params?.row?.candidate_phone ?? "NA"}
                </div>
            )
        } 
        },
        {
            field: "ticket_id", headerName: "ticket_ID", width:300,
            renderCell:(params)=>{
                return(
                    <div className={`cellWithStatus`}>
                        {params?.row?.ticket_id ?? "NA"}
                    </div>
                )
            }
            
        },
      
        { field: "assignee__display_name", headerName: "Assignee", width: 200,
        renderCell:(params)=>{
         return(
             <div className={`cellWithStatus`}>
                 {params?.row?.assignee__display_name ?? "NA"}
             </div>
         )
       }
      },
         {field: "job__job_title", headerName: "Job", width: 300,
         renderCell:(params)=>{
            return(
                <div className={`cellWithStatus`}>
                    {params?.row?.job__job_title ?? "NA"}
                </div>
            )
        } 
         
        },
        {
            field: "status__name", headerName: "Status", width: 200,
            renderCell:(params)=>{
                return(
                    <div className={`cellWithStatus`}>
                        {params?.row?.status__name ? params?.row?.status__name:"NA"}
                    </div>
                )   
            } 
        },
       
        {
            field: "action",
            headerName: "Action",
            width: "150",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                           deleteIconName="ArchiveIcon"
                           moduleName="source-type"
                           rowid={params.row.id}
                           addMoreData={params.row.id}
                           editLinkUrl=""
                           viewLinkUrl=""
                           isDelete={false}
                           editButton={false}
                           viewButton={()=>handleClickView(params.row)}
                           deleteButton={false} 
                           />
                    </div>
                );
            },
        },
    ];

  
    const onFilterChange = (filterModel,r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"order_by":{"column":"unique_id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MY_WORK_HISTORY}]}`)
                dispatch(workHistory(`{"order_by":{"column":"unique_id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MY_WORK_HISTORY}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
          
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;

            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"unique_id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MY_WORK_HISTORY}]}`;
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(workHistory(filterData))
        }

    }

    const closeViewMode = () => {
        setOpen(false)
    }

   


  

    return (
        <div className="task">
            {/* <Sidebar /> */}
            <div className="taskContainer">
                {/* <Navbar /> */}

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>My work History</h3>
                        <div>
                            <RefreshButton api={workHistory} filter={filterQuery} />

                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBackIcon /></button>
                            </Tooltip>

                        </div>
                    </div>
                    <div className="custom-pagination-class">
                       <CustomPagination />
                    </div>
                    {(reload === true || status === STATUSES.LOADING) && <LoaderForm />}
      
                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                             columnVisibilityModel={columnVisibilityModel}
                             onColumnVisibilityModelChange={(props)=>
                                {
                                    setColumnVisibilityModel(props);
                                }}
                             columns={actionColumn}
                             rows={workHistory_Data && workHistory_Data.data && workHistory_Data.data.rows ? workHistory_Data.data.rows.map((item, ind)=>({id:ind+1,...item})):[]}
                            pageSize={pageSize}
                            filterMode="server"
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            disableSelectionOnClick
                            hideFooterPagination={true}
                        />
                    }

           <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={closeViewMode}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <Button
                edge="start"
                color="inherit"
                onClick={closeViewMode}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>
             <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Candidate Resume'}</ DialogTitle>
             <DialogContent>
               
             {attachments && Object.keys(attachments).length > 0 &&
              
                       (attachments?.type === 'doc') ?
                         <iframe className="google-docs iframe" src={`https://docs.google.com/viewer?url=${attachments?.uri}&embedded=true`} frameborder="0"
                          scrolling="no" marginHeight="0" marginWidth="0" role="document" aria-label="PDF document" title="PDF document" style={{ borderStyle: 'none', width: '100%', height: '800px' }}></iframe> :
                      (['jpg', 'jpeg', 'png'].includes(attachments?.type)) ?
                          <Gallery
                            items={{
                                original: attachments?.uri,
                                thumbnail: attachments?.uri,
                                description: attachments?.name,
                              }}
                            thumbnailPosition="middle"
                            showPlayButton={false}
                            showFullscreenButton={true}
                          />
                          :
                      (attachments?.type === 'txt') ?
                          <div>
                            <DocViewer
                              documents={{ uri: attachments?.uri }}
                              pluginRenderers={DocViewerRenderers}
                            />
                          </div>
                      :
                     <FileViewer fileType={attachments?.type} filePath={attachments?.uri} />
                    } 
             </DialogContent>
            </Dialog >
                </div>
            </div>
        </div >
    )
}

export default MyWorkHistory