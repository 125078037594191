export const ContentParser = (body) => {

    let htmlContent = "";
    let strContent = JSON.stringify(body);
    let objContent = JSON.parse(strContent);

    const textContent = (oldContent, paraItem, type = null) => {
        // marks: [{ type: "strong" }],
        const strongTag = paraItem?.marks?.filter(e => e.type === "strong" && true).length ? true : false
        return oldContent + `<p> ${+ strongTag ? "<strong>" : ""} ${paraItem.text ? paraItem.text : ""} ${+ strongTag ? "</strong>" : ""}</p>`
    }

    const inlineCardContent = (oldContent, paraItem, type = null) => {
        // return oldContent + "" + `<a href="${paraItem.attrs.url ? paraItem.attrs.url : ""}">${paraItem.attrs.url ? paraItem.attrs.url : ""}</a>`
        return oldContent + `<a href="${paraItem.attrs.url ? paraItem.attrs.url : ""}">${paraItem.attrs.url ? paraItem.attrs.url : ""}</a>`
    }

    const paragraphContent = (element, htmlContent) => {
        let newContent = htmlContent
        element.content.forEach((e) => {
            if (e.type === "text") {
                newContent = textContent(newContent, e)
            }
            else if (e.type === "inlineCard") {
                newContent = inlineCardContent(newContent, e)
            }
        })
        return newContent;
    }

    const listContent = (oldContent, paraItem, index, lastIndex, listType = "orderedList", listIndex, listLastIndex) => {
        const startOlTag = (index === 0 && listIndex === 0) ? listType === "orderedList" ? "<ol>" : "<ul>" : "";
        const endOlTag = (index === lastIndex && listIndex === listLastIndex) ? listType === "orderedList" ? "</ol>" : "</ul>" : "";
        return oldContent + startOlTag + `<li>${paraItem.text ? paraItem.text : ""}${(index === lastIndex) ? "<br>" : ""}</li>` + endOlTag;
    }

    const orderedList = (element, htmlContent, listIndex, listLastIndex) => {
        let newContent = htmlContent
        const lastIndex = element.content.length - 1
        element.content.forEach((e, index) => {
            if (e.type === "text") {
                newContent = listContent(newContent, e, index, lastIndex, "orderedList", listIndex, listLastIndex)
                // newContent= newContent +""+ index===0 ? "<ol>" : "" + listContent(newContent, e) + index===element.content.length-1 ? "</ol>":"";
            }
        })
        return newContent;
    }

    const unorderedList = (element, htmlContent, listIndex, listLastIndex) => {
        let newContent = htmlContent
        const lastIndex = element.content.length - 1
        element.content.forEach((e, index) => {
            if (e.type === "text") {
                newContent = listContent(newContent, e, index, lastIndex, "unorderedList")
                // newContent= newContent +""+ index===0 ? "<ol>" : "" + listContent(newContent, e) + index===element.content.length-1 ? "</ol>":"";
            }
        })
        return newContent;
    }

    // const orderedListContent=(element ,htmlContent )=>{
    let orderedListContent = (element, htmlContent) => {
        let newContent = htmlContent;
        element.content.forEach((e, index) => {
            if (e.type === "listItem") {
                e.content.forEach(e1 => {
                    newContent = e1.type === "paragraph" ? orderedList(e1, newContent, index, element.content.length - 1) :
                        e1.type === "unorderedList" ? orderedListContent = (e1, newContent)
                            : ""
                })

            }
        })
        return newContent;
    }

    const unorderedListContent = (element, htmlContent) => {
        let newContent = htmlContent;
        element.content.forEach((e, index) => {
            if (e.type === "listItem") {
                e.content.forEach(e1 => {
                    newContent = e1.type === "paragraph" ? unorderedList(e1, newContent, index, element.content.length - 1) :
                        e1.type === "unorderedList" ? "paragraphContent(e1 ,newContent) "
                            : ""
                })

            }
        })
        return newContent;
    }

    const cellTextContent = (oldContent, paraItem, length = null) => {
        const widthPer = 100 / length;
        // return oldContent + "" + `<td style="width: ${widthPer}%;">${paraItem.text ? paraItem.text : ""}</td>`
        return oldContent + `<td style="width: ${widthPer}%;">${paraItem.text ? paraItem.text : ""}</td>`
    }
    const cellTextContentWithParagraph = (oldContent, paraItem, length) => {
        const widthPer = parseInt(100 / (length));
        return oldContent + "" +
            `<td style="width: ${widthPer}%;">
        ${paraItem.content && paraItem.content.length ? paraItem.content.map(e => e.text + `</br>`) : ""}
        </td>`
    }
    const cellItemContent = (element, htmlContent = "", length) => {
        let newContent = htmlContent
        element.content.forEach((e) => {
            if (e.type === "paragraph") {
                // newContent=paragraphContent(e, newContent)
                newContent = cellTextContentWithParagraph(newContent, e, length)
                // cellTextContent(newContent, e, element.content.length)
            }
            if (e.type === "text") {
                newContent = cellTextContent(newContent, e, element.content.length)
            }
            else if (e.type === "inlineCard") {
                newContent = inlineCardContent(newContent, e, element.content.length)
            }
        })
        return newContent;
    }

    const cellContent = (oldContent, paraItem, index, lastIndex, rowIndex, rowLastIndex, length) => {
        const startTableBodyTag = (index === 0 && rowIndex === 0) ? `<table  style="border-collapse:collapse;width: 100%;"><tbody>` : "";
        const endTableBodyTag = (rowIndex === rowLastIndex && index === lastIndex) ? "</tbody></tbody>" : "";
        const startTrTag = index === 0 ? "<tr>" : "";
        const endTrTag = index === lastIndex ? "</tr>" : "";
        const testPara =
            oldContent +
            startTableBodyTag + startTrTag + cellItemContent(paraItem, "", length) + endTrTag + endTableBodyTag;
        return testPara;
        // return oldContent + startTrTag + `<td>${paraItem.text ? paraItem.text : ""}</td>`  + endTrTag ;
    }
    // const rowContent=(element ,htmlContent, rowIndex, lastRowIndex)=>{
    //     let newContent=htmlContent
    //     const lastIndex=element.content.length-1
    //     element.content.forEach((e, index)=>{
    //         if( e.type==="paragraph"){
    //             newContent=cellContent(newContent, e, index, lastIndex, rowIndex, lastRowIndex, element.content.length )
    //             // newContent= newContent +""+ index===0 ? "<ol>" : "" + listContent(newContent, e) + index===element.content.length-1 ? "</ol>":"";
    //         }
    //     })
    //     return newContent;
    // }

    const tableContent = (element, htmlContent) => {
        let newContent = htmlContent;
        element.content.forEach((e, index) => {
            if (e.type === "tableRow") {
                e.content.forEach((e1, indx) => {
                    // newContent=e1.type==="tableCell" ? rowContent(e1 ,newContent, index, element.content.length-1) 
                    newContent = e1.type === "tableCell" ? cellContent(newContent, e1, indx, e.content.length - 1, index, element.content.length - 1, e.content.length)
                        // : e1.type==="unorderedList" ? "paragraphContent(e1 ,newContent) "
                        : ""
                })
            }
        })
        // return htmlContent;
        return newContent;
    }

    objContent?.content?.forEach(element => {
        switch (element.type) {

            case 'paragraph':

                htmlContent = paragraphContent(element, htmlContent)

                break;
            case 'bulletList':
                htmlContent = unorderedListContent(element, htmlContent)
                break;
            case 'orderedList':
                htmlContent = orderedListContent(element, htmlContent)

                break;
            case 'unorderedList':
                htmlContent = unorderedListContent(element, htmlContent)
                break;
            case 'mediaSingle':
                console.log("mediaSingle", element);
                break;
            case 'table':
                htmlContent = tableContent(element, htmlContent)
                break;

            default:

        }
    });

    return htmlContent;

}