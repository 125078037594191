import { Oval } from "react-loader-spinner"

const SmallLoader = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '99999' }}>
            <Oval
                height={50}
                width={50}
                color="#0052cc"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#0052c2"
                strokeWidth={3}
                strokeWidthSecondary={4}
            />
        </div>
    )
}

export default SmallLoader