import { Close } from "@mui/icons-material"
import { Button, Dialog, DialogTitle } from "@mui/material"
import { memo } from "react";
import Select from 'react-select';

const ApproverForm = ({ approverList, handleClose, handleChange, submitData, open, fieldType }) => {
    return (
        <Dialog className="candidate-screening-modal-box"
            hideBackdrop
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Button
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>

            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                {fieldType === 'rejected' ? 'Rejected' :
                    fieldType === 'assign-approver' ? 'Assign approver' :
                        fieldType === 's' ? 'Start' :
                            'Reject'}</DialogTitle>
            <form style={{ margin: "20px 20px 20px 20px" }}>
                <div className="add-new-user-form">
                    {fieldType === 'assign-approver' &&
                        <div className='formInput' style={{ flexBasis: "100%" }}>
                            <label>Select Job Approver </label>
                            <Select
                                className="select"
                                placeholder="Select Approver"
                                isSearchable={true}
                                onChange={handleChange}
                                name="approver"
                                options={Array.isArray(approverList) ? approverList.map(item => ({ label: item.display_name, value: item.id, name: "approver" })) : []}
                            />
                        </div>
                    }
                    <div className='formInput' style={{ flexBasis: "100%" }}>
                        <label>Comment </label>
                        <textarea
                            className="select"
                            placeholder="Comment"
                            onChange={handleChange}
                            name="approver_comment"
                        />
                    </div>

                    <div className="formInput">
                        <button className="btn btn-submit" onClick={submitData}>Submit</button>
                    </div>
                </div>
                <br />
                <br />
            </form>
        </Dialog>
        // </div>
        // </div>
    )
}
export default memo(ApproverForm)