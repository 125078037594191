// import "./navbar.scss"
import React, { useState, useEffect } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsNoneOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Avatar, Box, Dialog, DialogContent, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from "react-router-dom";
import LocalStorageDataService from '../common-function/GetLocalstorage';
import moment from "moment";
import { logout } from '../../redux/features/authSlice'
import dataService from "../../redux/services/data.service";
import PhonecallProgressbar from "../phone-call-status/PhonecallProgressbar";
import Punchinout from "../punchinout/PunchinOut";
import BadgeMark from '@mui/material/Badge';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Logo from '../../images/fevicon.png'
import MultilevelMegamenu from './MultilevelMegamenu';
import PersonIcon from '@mui/icons-material/Person';
import GetLocalstorage from '../common-function/GetLocalstorage';
import { getRemainderList } from '../../redux/features/taskSlice';
import UniqueMegaMenu from './UniqueMegaMenu';
import { updateBreadcrumbSubMenu, updateBreadcrumbs, updateSingleBreadcrumb, updatethirdlevelBreadcrumb } from '../../redux/features/BreadcrumbsSlice';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { red } from '@mui/material/colors';


const role = LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().tms_role && LocalStorageDataService.userDetail().tms_role.role_name

const timeOutInterval = 1000;
function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const Navbar = ({ setLogoutStatus }) => {
  let timer = undefined;
  //const events = ['click', 'scroll', 'load', 'keydown']
  const events = ['click']

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState([])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [showLogo, setShowLogo] = useState(getCurrentDimension());
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [emptyNav, setEmptyNave] = useState(false)
  const [errMessage, setErrMessage] = useState("Token Expire! You are Logged out. Please Login Again!")
  const [remainderList, setRemainderList] = useState([])
  const location = useLocation()
  const openmessage = Boolean(anchorEl1);


  const messageClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const messageClose = () => {
    setAnchorEl1(null);
  };


  useEffect(() => {
    addEvents();
    ckeckUserRemainder()
    return (() => {

      removeEvents();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  useEffect(() => {
    if (screenSize.width <= 1200) {
      setShowLogo(false)
    } else {
      setShowLogo(true)
    }
  }, [screenSize])

  const ckeckUserAccessToken = async () => {
    let token = LocalStorageDataService.userToken()
    if (token) {
      await dataService.userLogin(token).then(response => {
        if (response.data && response.status === 200) {
          setUserData(response.data.data)
        }
      }).catch((error) => {
        if (error.response) {
          let err = error.response
          if (err.status === 500) {
            //toast.info(err.statusText)
            // setErrMessage(err.statusText)
            // setOpenModal(true)
          }
          if (err.status === 401) {
            err.data?.message && setErrMessage(err.data.message)
            setOpenModal(true)
            logout_user()
          }
        }
      });
    }
  }

  const ckeckUserRemainder = async () => {
    let filter = LocalStorageDataService.userDetail() && `{ "filter":{"is_viewed":{"value":False, "operation":"eq" },"created_by":{"value":${LocalStorageDataService.userDetail().id} , "operation":"eq"} },"no_limit":True}`
    if (filter) {
      await dataService.get_reminder_list(filter).then(response => {
        if (response.data && response.status === 200) {
          setRemainderList(response.data.data)
        }
      }).catch((error) => {
        if (error.response) {
          let err = error.response
          if (err.status === 500) {
            //toast.info(err.statusText)
            // setErrMessage(err.statusText)
            // setOpenModal(true)
          }
          if (err.status === 401) {
            // err.data.message 
            //setOpenModal(true)
          }
        }
      });
    }
  }

  const startTimer = () => {
    const timeOut = 3600 * 1000 * 8
    const lastInteractionTime = LocalStorageDataService.lastInteractionTime()
    const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
    timer = setTimeout(() => {
      if (diff._milliseconds < timeOut) {
        ckeckUserAccessToken()
        ckeckUserRemainder()
      } else {
        ckeckUserAccessToken()
        ckeckUserRemainder()
      }
    }, timeOutInterval)
  }

  const eventHandler = (eventType) => {
    if (timer) {
      startTimer()
    }
  }

  const logout_user = () => {
    setTimeout(() => {
      dispatch(logout())
      localStorage.setItem(`${process.env.REACT_APP_ENV}_lastVisitPath`, JSON.stringify(location.pathname))
      setOpenModal(false);
    }, 3000)
    // window.location.reload('/')
  }

  useEffect(() => {
    if (events === 'click') {
      addEvents()
    }

  }, [events])

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler)
    })
    startTimer();
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    })
  };

  let lettername = "";
  if (LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().display_name) {
    let displayName = LocalStorageDataService.userDetail().display_name;

    let arr = displayName.split(" ");
    lettername = arr && arr.length > 0 ? arr.length >= 2 ? arr[0].charAt(0) + arr[1].charAt(0) : arr[0].charAt(0) : '';

  }

  const localDashboardMenu = {
    "mainMenu": {
      "menu_id": 142,
      "menu": {
        "id": 142,
        "title": "Dashboard",
        "description": "dashboard",
        "url_slug": "dashboard",
        "sort_by": 1,
        "class_name": "FolderIcon",
        "status": true,
        "parent_id": null,
        "created_on": "2023-08-01T06:04:55.752666Z",
        "created_by": 1,
        "updated_on": "2023-08-01T06:05:43.815908Z",
        "updated_by": 1
      },
      "submenu": []
    },
    "menuId": 142
  }

  const SingleBreadcrumbManipulate = (menu) => {
    dispatch(updateSingleBreadcrumb(menu))
    dispatch(updateBreadcrumbs({ mainMenu: "", menuId: "" }))
    dispatch(updateBreadcrumbSubMenu({ mainMenu: "", menuId: "" }))
    dispatch(updatethirdlevelBreadcrumb({ mainMenu: "", menuId: "" }))
  }

  return (
    <>
      <Box sx={{
        boxShadow: 1, padding: '3px', position: "sticky", top: 0, zIndex: 1010, background: "#438BF9",
        minHeight: '65px', paddingTop: '15px'
      }}>
        <Grid2 container columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid2 item md={userData && userData?.agent_call_progress_bar ? 10 : 11} >
            <Stack direction="row">
              <Stack>
                <Link onClick={() => { SingleBreadcrumbManipulate({ mainMenu: localDashboardMenu, menuId: localDashboardMenu?.menu?.id }); setEmptyNave(true) }} to="/dashboard" style={{ textDecoration: "none" }}>
                  <div className="rtms-logo">
                    <Avatar src={Logo} />
                    {showLogo && <span className="logo-text">
                      RTMS
                    </span>}
                  </div>
                </Link>
              </Stack>
              <Stack>
                {/* <MultilevelMegamenu /> */}
                <UniqueMegaMenu setEmptyNave={setEmptyNave} emptyNav={emptyNav}  />
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 item columns={{ xs: 6, sm: 6, md: 6 }} md={userData && userData?.agent_call_progress_bar ? 2 : 1} >
            <Stack alignItems={"center"} justifyContent={"space-around"} direction={"row"}>
              {
                userData && userData.agent_call_progress_bar &&
                <PhonecallProgressbar details={userData.agent_call_progress_bar} />
              }
              <Tooltip title="message">
                <BadgeMark badgeContent={1} color="success">
                  <Link to="/all-massages" style={{ textDecoration: "none" }}>
                    <ChatBubbleOutlinedIcon
                      sx={{ color: '#fff', fontSize: '30px' }}
                      size="small"
                      aria-controls={open ? 'message-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    />
                  </Link>
                </BadgeMark>
              </Tooltip>
              <BadgeMark sx={{ cursor: 'pointer' }} badgeContent={remainderList.count ? remainderList.count : 0} color="success">
                {/* <Link to={"/notification"} style={{ textDecoration: "none" }}> */}
                <NotificationsNoneOutlined
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? 'notification-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ color: '#fff', fontSize: '30px' }}
                  style={{marginBottom:10}}RTMS
                />
                <Popover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{ marginTop: '25px' }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <Stack direction={"column"}>
                    <Box maxHeight={'500px'}>
                      {remainderList?.rows?.length !== 0 ? <>
                        {remainderList?.rows?.map((valueReminder, keyReminder) => {
                          const d = new Date(valueReminder?.notification_date);
                          const year = d.getFullYear();
                          const month = d.toLocaleString('default', { month: 'long' });
                          const day = d.getDay();
                          const time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                          const formatedDateTime = day + " " + month.slice(0, 3) + " " + year + " " + time
                          return (
                            <Link to={"/notification"} style={{ textDecoration: "none" }}>
                              <Box p={1} sx={{ backgroundColor: keyReminder % 2 == 0 ? '#fff' : '#c6dafb', cursor: 'pointer' }} justifyContent={"space-between"} display={"flex"} alignItems={"center"} width={"350px"}>
                                <Box>
                                  <Typography color={'#485e83'} variant='h5' fontSize={'20px'} >
                                    {valueReminder?.comment}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography color={'#485e83'} variant='subtitle2' >
                                    {formatedDateTime}
                                  </Typography>
                                </Box>
                              </Box>
                            </Link>
                          )
                        })}
                      </> : <>
                        <Box p={1} sx={{ cursor: 'pointer' }} width={"300px"}>
                          <Typography color={'darkgreen'} textAlign={"center"} variant='subtitle2' fontSize={'16px'} >
                            No new notification.
                          </Typography>
                        </Box>
                      </>}
                    </Box>
                  </Stack>
                </Popover>
                {/* </Link> */}
              </BadgeMark>
              <Avatar
                sx={{ bgcolor: '#438bf9', border: '2px solid #fff', cursor: 'pointer', }}
                alt="Remy Sharp"
                onClick={messageClick}
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <PersonIcon />
              </Avatar>
              <Menu
                anchorEl={anchorEl1}
                className="MuiMenu-list-dropdown"
                id="message-menu"
                open={openmessage}
                onClose={messageClose}
                // onClick={messageClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,

                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <Punchinout setLogoutStatus={setLogoutStatus} />
                </MenuItem>
              </Menu>
            </Stack>
          </Grid2 >
        </Grid2 >
      </Box >
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <div className="popup-confirming">
            <p> <WarningAmberIcon sx={{ color: red[900] }} fontSize="large" /><h5> Alert </h5></p>
            <p>{errMessage}</p>
            <button className="yes" onClick={() => logout_user()}>LOG-OUT</button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Navbar
