import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { InputFields } from '../common-function/InputFields';
import { toast } from 'react-toastify';
import { Close, Mail } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { updateCandidateEmail } from '../../redux/features/emailSlice';

export default function AddCandidateEmail({ showEmail, handleClose, storyId, setReload }) {

    const dispatch = useDispatch()
    const [email, setEmail] = useState('')

    const validate = (email) => {
        let errors = {};
        if (!email) {
            errors.emails = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.emails = 'Please Enter Vailid Email Id '
        } else {
            errors.emails = true
        }
        return errors
    }

    useEffect(()=>{
        dispatch(updateCandidateEmail('type'))
    },[])

    const submitEmailData = (evt) => {
        evt.preventDefault();
        if (email === "") {
            return toast.error("Email Id is required")
        }
        if (email !== "") {
            const isValidate = validate(email)
            if (isValidate.emails === true) {
                const addvalues = { email: email, story_id: storyId }
                setReload('email_submit')
                dispatch(updateCandidateEmail(addvalues))
            } else {
                return toast.error(isValidate.emails)
            }
        }
    }

    return (
        <div>
            {/* form section */}
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"sm"}
                open={showEmail}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{ left: "68%", height: "340px", width: "500px", top: "25px" }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Add Candidate Email Id'}</DialogTitle>
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">
                            <div className='formInput' style={{ flexBasis: "100%" }}>
                                <InputFields
                                    label={"Enter Email Id"}
                                    name="email"
                                    type="text"
                                    placeholder="Enter Email Id"
                                    onChange={(evt) => setEmail(evt.target.value)}
                                />
                            </div>
                        </div>
                        <Tooltip title="Email">
                            <button
                                style={{
                                    width: "70px", padding: "7px 17px", backgroundColor: "green",
                                    marginBottom: "0px", borderRadius: "4px", color: "white"
                                }}
                                onClick={(e) => submitEmailData(e)}
                            >
                                <Mail />
                            </button>
                        </Tooltip>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
