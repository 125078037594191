import React from 'react'
import "./error404.scss";
import { Link } from "react-router-dom";





const Error401 = () => {

    return (
        <section className="page_404">
            <div className="four_zero_four_bg">
                <h1 className="text-center "><span>Access Denied</span></h1>
            </div>
            <div className="contant_box_404">
                <p>You do not have access to the requested page.</p>
                <Link to={"/"} className="link_404">Go to Dashboard</Link>
            </div>
        </section>
    )
}

export default Error401
