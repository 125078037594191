
import "./ReviseSalary.scss"
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobBudgetReviseCreate } from "../../../redux/features/jobPostSlice";
import { STATUSES } from "../../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from "@mui/icons-material";
import { InputFields } from "../../common-function/InputFields";

const initialFormData = Object.freeze({
    approver_comment: "",
});

const formErrData = Object.freeze({
    approver_commentErr: "Reason for approve is required"
});

const ApproveBudget = ({ open, setOpen, jobData }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({ ...initialFormData, client_budget_from: jobData.client_budget_from, client_budget_to: jobData.client_budget_to })
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, revised_Salary_Create } = useSelector(state => state.jobpost)
    const [reload, setReload] = useState(false)

    React.useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_revised_Salary_Create` && revised_Salary_Create && revised_Salary_Create.message) {
        if (revised_Salary_Create.status === 200) {
            toast.success(revised_Salary_Create.message)
            setOpen(false);
            setFormValues(initialFormData);
        } else {
            toast.error(revised_Salary_Create.message)
        }
        setReload(false)
    }

    const handleClose = () => {
        setOpen(false);
        setErrStatus(false);
        setFormValues(initialFormData);
    };

    const handleChangeText = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.approver_comment === "") {
            setErrStatus(true)
        }

        if (formValues.approver_comment !== "") {
            setErrStatus(false)
            setReload(true)
            
            let addvalues = {
                job: jobData.id,
                approval_status: 5,
                approver_comment: formValues.approver_comment
            }

            dispatch(jobBudgetReviseCreate(addvalues))
        }

    }

    return (
        
            <Dialog
                hideBackdrop
                // fullWidth
                // maxWidth={"l"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Approve Revise Salary Budget</DialogTitle>
                <DialogContent>
                    <form>
                        <div className="add-new-user-form" style={{display:"block"}}>

                                    <InputFields
                                        label={'Comment for approval'}
                                        name="approver_comment"
                                        defaultValue={formValues.approver_comment}
                                        type="text"
                                        placeholder="Comment for approval"
                                        onChange={handleChangeText}
                                        errStatus={errStatus}
                                        formValues={formValues.approver_comment}
                                        formErr={formErr.approver_commentErr}
                                    />
                        </div>
                        <Button style={{ minWidth: '17%' }} className="submit-modal" onClick={submitData}>Submit</Button>
                    </form>
                </DialogContent>
            </Dialog>
        
    )
}

export default ApproveBudget