import React,{useState,useEffect} from "react";
import "./attendacecalender.scss"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid'
import { attandanceList, attandanceDetailById, attandanceRegulizationByDate, createAttandanceRegulization, attandanceRegulizationById, updateAttandanceRegulization } from "../../redux/features/calendarSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import moment from "moment"
import { errorHandling } from "../../redux/features/authLogoutSlice"
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Edit, MoreVert, RemoveRedEye } from "@mui/icons-material";
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import GetLocalstorage from "../../components/common-function/GetLocalstorage"
import { timeConvert } from "../../components/utlis/CalculationTime"
import CryptoJS from 'crypto-js';
const { REACT_APP_UI_WEBSITEURL } = process.env;

const AttendanceCalender = () => {
    const {REACT_APP_ENCRPTION_KEY_FOR_UI } = process.env;

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [attendance, setAttendance] = useState([])
    const { attendanceData, status, error, attendaceDetailsById, createRegulization, attandanceRegulizationList, regulizationById, updateRegulization } = useSelector(state => state.calender)
    const [modalView, setModelView] = useState(false)
    const [loader, setLoader] = useState(true)
    const [regulizModalView, setRegulizModalView] = useState(false)
    const [attendaceDetails, setattendaceDetails] = useState([])
    const [punchIn, setPunchIn] = useState(-1);
    const [remark, setRemark] = useState('');
    const [punchInTime, setPunchInTime] = useState('');
    const [punchOutTime, setPunchOutTime] = useState('');
    const [regularId, setRegularId] = useState(0);
    // const [minDate, setMinDate] = useState('');
    //const [maxDate, setMaxDate] = useState('');
    const [regulizeData, setRegulizeData] = useState("")
    const [calenderInfo, setCalenderInfo] = useState("")
    const [regularStatus, setRegularStatus] = useState("")
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [corrected_punch_in_datetime, setcorrected_punch_in_datetime] = useState('')
    const [corrected_punch_out_datetime, setcorrected_punch_out_datetime] = useState('')
    const [actual_punch_in_datetime, setactual_punch_in_datetime] = useState('')
    const [actual_punch_out_datetime, setactual_punch_out_datetime] = useState('')
    const [managerRemark, setManagerRemark] = useState("")
    const open = Boolean(anchorEl);

    useEffect(() => {
        setLoader(true)
        dispatch(attandanceList())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (modalView === true && status === STATUSES.SUCCESS && attendaceDetailsById && attendaceDetailsById.data && attendaceDetails.length === 0) {
        const attenance_details_data = attendaceDetailsById.data && attendaceDetailsById.data.rows;
        setattendaceDetails(attenance_details_data)
        setRemark("")
        setManagerRemark("")
    }


    if (regulizModalView === true && status === STATUSES.SUCCESS && attendaceDetailsById && attendaceDetailsById.data && attendaceDetails.length === 0) {
        setRemark("")
        const attenance_details_data = attendaceDetailsById.data && attendaceDetailsById.data.rows;
        let actual_punching_date_time = attenance_details_data[0].resource_attendance.punchin_date ? attenance_details_data[0].resource_attendance.punchin_date + '  ' + attenance_details_data[0].resource_attendance.punchin_time : null;
        let actual_punch_out_date_time = attenance_details_data[0].resource_attendance.punch_out_date ? attenance_details_data[0].resource_attendance.punch_out_date + '  ' + attenance_details_data[0].resource_attendance.punch_out_time : null;
        actual_punching_date_time ? setactual_punch_in_datetime(actual_punching_date_time) : setactual_punch_in_datetime("")
        actual_punch_out_date_time ? setactual_punch_out_datetime(actual_punch_out_date_time) : setactual_punch_out_datetime("")
        const [year, month, day] = attenance_details_data[0].resource_attendance.punchin_date.split('-');
        const [hours, minutes, seconds] = attenance_details_data[0].resource_attendance.punchin_time.split(':');
        if (attenance_details_data[0].resource_attendance.punch_out_date) {
            const [year1, month1, day1] = attenance_details_data[0].resource_attendance.punch_out_date.split('-');
            const [hours1, minutes1, seconds1] = attenance_details_data[0].resource_attendance.punch_out_time.split(':');
            const actul_date_out = year1 && seconds1 && new Date(+year1, month1 - 1, +day1, +hours1, +minutes1, +seconds1);
            actual_punch_out_date_time !== null && actul_date_out && setPunchOutTime(actul_date_out)
        } else {
            setPunchOutTime("")

        }
        const actul_date_in = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
        actual_punching_date_time !== null && actul_date_in && setPunchInTime(actul_date_in)
        setattendaceDetails(attenance_details_data)
        setRegulizeData("")
        setRegularStatus("")
    }

    if (loader === true && status === STATUSES.FAILURE && error) {
        setLoader(false)
        errorHandling(error, navigate)
    }

    if (loader === true && status === STATUSES.SUCCESS && attendanceData && attendanceData.data && attendance.length === 0) {
        setLoader(false)
        const attenance_data = attendanceData.data && attendanceData.data.rows;
        let binding_attendance = []
        attenance_data.map(item => {
            return binding_attendance.push(
                {
                    date: item.punchin_date,
                    current_date: item.punchin_date,
                    in_time: item.punchin_time,
                    out_time: item.punchin_date === moment().format('YYYY-MM-DD') && !item.punch_out_time ? '-' : item.punch_out_time ? item.punch_out_time : 'missing',
                    current_status: item.current_status,
                    title: item.resource ? item.resource.display_name : "",
                    id: item.id,
                    day_duration: item.durations ? item.durations : 0,
                    user_id: item.resource_id,
                    work_duration_minutes: item.work_duration_minutes ? item.work_duration_minutes : 0,
                    break_duration_minutes: item.break_duration_minutes ? item.break_duration_minutes : 0,
                    color: item.punchin_time && !item.punch_out_time ? '#fbc02d' : '#86c06a',
                }
            )
        })
        setAttendance(binding_attendance)
    }



    const handleDateClick = (arg) => {
        //handleDateClick
    }
    const closeModal = () => {
        setModelView(false)
        setPunchInTime('')
        setPunchIn('-1')
        setPunchOutTime('')
        setRegulizeData("")
        setRegularId(0)
        setRemark('')
        setattendaceDetails([])
        setRegularStatus("")
        setactual_punch_in_datetime("")
        setactual_punch_out_datetime("")
    }

    const viewDetail = (info) => {
        let ID = calenderInfo.event._def.publicId
        dispatch(attandanceDetailById(ID))
        setModelView(true)
    }

    if (status === `${STATUSES.SUCCESS}_createRegulization` && loader === `${true}_createRegulization` && createRegulization) {
        toast.success(createRegulization.message)
        setLoader(false)
        setRegulizModalView(false)
        setModelView(false)
        setPunchInTime('')
        setPunchOutTime('')
        setRegulizeData("")
        setManagerRemark("")
        setRegularId(0)
        setRemark('')
        setattendaceDetails([])
        setPunchIn('-1')
        setRegularStatus("")
        setactual_punch_in_datetime("")
        setactual_punch_out_datetime("")
    }
    if (status === `${STATUSES.FAILURE}_createRegulization` && loader === `${true}_createRegulization` && createRegulization) {
        toast.success(error.message)
        setLoader(false)
    }
    if (status === `${STATUSES.FAILURE}_updateRegulization` && loader === `${true}_updateRegulization` && updateRegulization) {
        toast.success(error.message)
        setLoader(false)
    }
    if (status === `${STATUSES.SUCCESS}_updateRegulization` && loader === `${true}_updateRegulization` && updateRegulization) {
        toast.success(updateRegulization.message)
        setRegulizModalView(false)
        setModelView(false)
        setPunchInTime('')
        setPunchOutTime('')
        setRegulizeData("")
        setRegularId(0)
        setRemark('')
        setattendaceDetails([])
        setRegularStatus("")
        setManagerRemark("")
        setactual_punch_in_datetime("")
        setactual_punch_out_datetime("")
        setLoader(false)
        setOpenCancelModal(false)
    }
    if (status === `${STATUSES.SUCCESS}_regulizationById` && regulizeData === '') {

        setRegulizeData(regulizationById.data)
        let in_date = regulizationById.data && moment(regulizationById.data.corrected_punch_in_datetime).format('YYYY-MM-DD')
        let in_time = regulizationById.data && moment(regulizationById.data.corrected_punch_in_datetime).format('HH:mm:ss')
        let out_date = regulizationById.data && regulizationById.data.corrected_punch_out_datetime && moment(regulizationById.data.corrected_punch_out_datetime).format('YYYY-MM-DD')
        let out_time_is = regulizationById.data.corrected_punch_out_datetime && moment(regulizationById.data.corrected_punch_out_datetime).format('HH:mm:ss')
        let corrected_punch_in_datetime = regulizationById.data && regulizationById.data.corrected_punch_in_datetime.replace('T', ' ')
        setcorrected_punch_in_datetime(corrected_punch_in_datetime.replace('Z', ''))
        let corrected_punch_out_datetime = regulizationById.data.corrected_punch_out_datetime && regulizationById.data.corrected_punch_out_datetime.replace('T', ' ')
        regulizationById.data.corrected_punch_out_datetime && setcorrected_punch_out_datetime(corrected_punch_out_datetime.replace('Z', ''))
        let actual_punch_in_datetime = regulizationById.data.actual_punch_in_datetime.replace('T', ' ')
        setactual_punch_in_datetime(actual_punch_in_datetime.replace('Z', ''))
        let actual_punch_out_date_time = regulizationById.data.actual_punch_out_date_time && regulizationById.data.actual_punch_out_date_time.replace('T', ' ')
        regulizationById.data.actual_punch_out_date_time && setactual_punch_out_datetime(actual_punch_out_date_time.replace('Z', ''))
        const [year, month, day] = in_date.split('-');
        const [hours, minutes, seconds] = in_time.split(':');
        if (out_date) {
            const [year1, month1, day1] = out_date.split('-');
            const [hours1, minutes1, seconds1] = out_time_is && out_time_is.split(':');
            let new_hour1 = out_time_is && hours1 - 5
            let new_minute1 = out_time_is && minutes1 - 30
            const actul_date_out = out_time_is && new Date(+year1, month1 - 1, +day1, +new_hour1, +new_minute1, +seconds1);
            setPunchOutTime(actul_date_out)
        } else {
            setPunchOutTime('')
        }

        let new_hour = hours - 5
        let new_minute = minutes - 30
        const actul_date_in = new Date(+year, month - 1, +day, +new_hour, +new_minute, +seconds);

        //let time_in = new Date(regulizationById.data.corrected_punch_in_datetime)
        //let out_time = new Date(regulizationById.data.corrected_punch_out_datetime)
        let statusIs = regulizationById.data.status === 4 ? "PENDING" :
            regulizationById.data.status === 5 ? "APPROVED" :
                regulizationById.data.status === 6 ? "CANCELLED" :
                    regulizationById.data.status === 7 && "REJECTED";
        setPunchInTime(actul_date_in)
        setRegularStatus(statusIs)
        setRemark(regulizationById.data.remark)
        if (regularId > 0) {
            setPunchIn(regulizationById.data.request_type)
        } else {
            setPunchIn(-1)
        }
        regulizationById.data && regulizationById.data.reporting_head_remark && setManagerRemark(regulizationById.data.reporting_head_remark)
    }
    if (status === `${STATUSES.SUCCESS}_attandanceRegulizationList` && regularId === 0 && attandanceRegulizationList.data && attandanceRegulizationList.data.rows.length > 0) {
        setRegularId(attandanceRegulizationList.data.rows[0].id)
        dispatch(attandanceRegulizationById(attandanceRegulizationList.data.rows[0].id))
    }

    const calculateTimeHour = (data) => {
        let cal = '0: 00';
        // let integr = '0';
        // let decimal = '0';
        let work_duration_minutes = data.work_duration_minutes;
        let break_duration_minutes = data.break_duration_minutes;
        if (work_duration_minutes > 0) {
            cal = timeConvert(work_duration_minutes + break_duration_minutes)
            // cal = (work_duration_minutes + break_duration_minutes) / 60;
            // integr = Math.floor(cal);
            // decimal = integr * 6;
        }
        return cal
    }

    const toHoursAndMinutes = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const regulizationModal = () => {
        if (regulizModalView === false) {
            let ID = calenderInfo.event._def.publicId
            dispatch(attandanceDetailById(ID))
            dispatch(attandanceRegulizationByDate({ id: ID, current_date: calenderInfo.event._def.extendedProps.current_date }))
        } else {
            setPunchInTime('')
            setPunchOutTime('')
            setRegulizeData("")
            setRegularId(0)
            setRemark('')
            setattendaceDetails([])
            setRegularStatus("")
            setManagerRemark("")
            setPunchIn('-1')
            //setactual_punch_in_datetime("")
            //setactual_punch_out_datetime("")
        }
        setRemark("")
        setManagerRemark("")
        setRegulizModalView(!regulizModalView)
    }

    const handleClick = (event, info) => {
        setPunchInTime('')
        setPunchOutTime('')
        setRegulizeData("")
        setRegularId(0)
        setRemark('')
        setManagerRemark("")
        setattendaceDetails([])
        setRegularStatus("")
        setPunchIn('-1')
        setCalenderInfo(info)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div >
                <div>
                    <p
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        style={{ textAlign: "right", padding: "5px 5px 0px 0px" }}
                        onClick={(e) => handleClick(e, eventInfo)}
                    >
                        <MoreVert style={{ height: "25px", width: "25px", backgroundColor: "rgba(0, 0, 0, 0.44)", borderRadius: "50%", display: "inline-block", padding: "5px", cursor: "pointer" }} />
                    </p>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Tooltip title={'Regularize'} >
                            <MenuItem onClick={regulizationModal}><Edit style={{ marginRight: "5px", color: "#0052cc" }} />Regularize</MenuItem>
                        </Tooltip>
                        <Tooltip title={'View Details'} >
                            <MenuItem onClick={viewDetail}><RemoveRedEye style={{ marginRight: "5px", color: "#0052cc" }} />View Details</MenuItem>

                        </Tooltip>

                    </Menu>
                </div>

                <div style={{ padding: "10px" }}>
                    <p style={{ fontWeight: "bold" }}>In time : {eventInfo.event._def.extendedProps.in_time}</p>
                    <p style={{ fontWeight: "bold" }}>Out time :{eventInfo.event._def.extendedProps.out_time}</p>



                    <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }}> {eventInfo.event._def.extendedProps.current_date === moment().format('YYYY-MM-DD')
                        ? "Current Hours/Status" : "Working Hours"}
                        : {eventInfo.event._def.extendedProps.current_date === moment().format('YYYY-MM-DD')
                            ? eventInfo.event._def.extendedProps.current_status === "punch_out"
                                ? calculateTimeHour(eventInfo.event._def.extendedProps)
                                : eventInfo.event._def.extendedProps.current_status
                            : calculateTimeHour(eventInfo.event._def.extendedProps)}
                    </p>
                </div>


            </div>
        )
    }
    const handleChange = (evt) => {
        const { value } = evt.target;
        setRemark(value);
    }
    const cancelReq = (e) => {
        //e.preventDefault()
        setLoader(`${true}_updateRegulization`)
        const prepairData = {
            "id": regularId,
            "resource_attendance": regulizeData.resource_attendance.id,
            "resource": regulizeData.resource.id,
            // "corrected_punch_in_datetime": punchInTime,
            // "corrected_punch_out_datetime": punchOutTime,
            "remark": remark,
            "request_type": parseInt(punchIn),
            "created_by": regulizeData.resource.id,
            "status": 6,//(Pending/Approved/Cancelled/Rejected) Pending will be stored at the time of request

        }
        dispatch(updateAttandanceRegulization(prepairData))

    }


    const compareStringTime = (time1, time2) => {
        let splitTime1 = time1.split(':')
        let splitTime2 = time2.split(':')
        let res = true;

        for (let i = 0; i < splitTime1.length; i++) {
            if ((+splitTime1[i]) < (+splitTime2[i])) {
                res = false;
                break;
            }
            else if ((+splitTime1[i]) === (+splitTime2[i]))
                continue;
            else
                break
        }
        return res;
    }


    const submitData = (e) => {
        e.preventDefault()

        let currentTime = moment().format('HH:mm:SS');

        if (!compareStringTime(currentTime, moment(punchInTime).format('HH:mm:SS'))) {
            toast.error("Invalid Punchin time!");
            return;
        }

        if (!compareStringTime(currentTime, moment(punchOutTime).format('HH:mm:SS'))) {
            toast.error("Invalid Punchout time!");
            return;
        }

        if (remark) {
            let actual_punching_date_time = attendaceDetails[0].resource_attendance.punchin_date ? attendaceDetails[0].resource_attendance.punchin_date + 'T' + attendaceDetails[0].resource_attendance.punchin_time + 'Z' : null;
            let actual_punch_out_date_time = attendaceDetails[0].resource_attendance.punch_out_date ? attendaceDetails[0].resource_attendance.punch_out_date + 'T' + attendaceDetails[0].resource_attendance.punch_out_time + 'Z' : null
            let in_date = moment(punchInTime).format('YYYY-MM-DD')
            let in_time = moment(punchInTime).format('HH:mm:SS')
            let out_date = punchOutTime && moment(punchOutTime).format('YYYY-MM-DD')
            let out_time = punchOutTime && moment(punchOutTime).format('HH:mm:SS')


            const encryptData = (data) => {              
                const { resource, resource_attendance } = data;
                
                const dataToEncrypt = JSON.stringify({ resource, resource_attendance });
                const secretKey =REACT_APP_ENCRPTION_KEY_FOR_UI
                
                const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
              
                // Encrypted link
                const path = "team-regularization";
                const appurl = REACT_APP_UI_WEBSITEURL
                const baseUrl = `${appurl}${path}`;
                const queryParams = `data=${encryptedData}`;
                const encrypted_link = `${baseUrl}?${queryParams}`;
              
                return encrypted_link;
            }          

            if (regularId > 0) {
                let prepairData = {
                    "id": regularId,
                    "resource_attendance": regulizeData.resource_attendance.id,
                    "resource": regulizeData.resource.id,
                    "corrected_punch_in_datetime": in_date + 'T' + in_time + 'Z',
                    "corrected_punch_out_datetime": punchOutTime ? out_date + "T" + out_time + 'Z' : null,
                    "remark": remark,
                    "created_by": regulizeData.resource.id,
                    "request_type": punchIn === 0 || punchIn === '0' ? 0 : parseInt(punchIn),
                    "status": 4,//(Pending/Approved/Cancelled/Rejected) Pending will be stored at the time of request
                }
                const encrypted_link = encryptData(prepairData)
                prepairData = {
                    ...prepairData,
                    "encrypted_link":encrypted_link
                    
                }
                setLoader(`${true}_updateRegulization`)
                dispatch(updateAttandanceRegulization(prepairData))
            } else {
                let prepairData = {
                    //"id": '',
                    "resource_attendance": attendaceDetails[0].resource_attendance_id,
                    "resource": attendaceDetails[0].resource_attendance.resource.id,
                    "actual_punch_in_datetime": actual_punching_date_time,
                    "corrected_punch_in_datetime": in_date + 'T' + in_time + 'Z',
                    "actual_punch_out_datetime": actual_punch_out_date_time,
                    "corrected_punch_out_datetime": punchOutTime ? out_date + "T" + out_time + 'Z' : null,
                    "remark": remark,
                    "created_by": attendaceDetails[0].resource_attendance.resource.id,
                    "request_type": punchIn === 0 || punchIn === '0' ? 0 : parseInt(punchIn),
                    "status": 4,//(Pending/Approved/Cancelled/Rejected) Pending will be stored at the time of request
                   
                }
                const encrypted_link = encryptData(prepairData)
                prepairData = {
                    ...prepairData,
                    "encrypted_link":encrypted_link
                    
                }
                setLoader(`${true}_createRegulization`)
                dispatch(createAttandanceRegulization(prepairData))
            }
        } else {
            toast.error("Clarification is Required!")
        }

    }

  
      

    const cancelModale = (e) => {
        e.preventDefault()
        if (remark) {
            setOpenCancelModal(!openCancelModal)
        } else {
            toast.error("Clarification is Required!")
        }
    }

    const addExtraDate = (selectedDate) => {
        let actualDate = new Date(selectedDate);
        return new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 1);
    }

    return (
        <div className="home">
            <div className="homeContainer">
                <div className="datatable">
                    <ToastContainer />
                    {/* {loader === `${true}_updateRegulization` || loader === `${true}_createRegulization` && <LoaderForm />} */}
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        initialView="dayGridMonth"
                        events={attendance}
                        dateClick={handleDateClick}
                        eventContent={renderEventContent}

                    />
                </div>

                {modalView === true &&
                    <Dialog
                        hideBackdrop
                        fullWidth={true}
                        maxWidth={"md"}
                        open={modalView}
                        onClose={closeModal}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeModal}
                            aria-label="close"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Attendance Details</DialogTitle>
                        <DialogContent >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>In Time</TableCell>
                                            <TableCell>Out Time</TableCell>
                                            <TableCell>Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendaceDetails.map((row, i) => (
                                            <TableRow key={i} >
                                                <TableCell> {moment(row.resource_attendance.punchin_date).format('ll')}</TableCell>
                                                <TableCell>{row.punchin_time}</TableCell>
                                                <TableCell>{row.punch_out_time}</TableCell>
                                                <TableCell align="right">{toHoursAndMinutes(row.durations)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                    </Dialog>
                }
                <Dialog
                    hideBackdrop
                    fullWidth={true}
                    maxWidth={"md"}

                    open={regulizModalView}
                    onClose={regulizationModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}> Regularization Request-{regulizeData && regulizeData.resource_attendance ? moment(regulizeData.resource_attendance.punchin_date).format('ll') :
                        attendaceDetails && attendaceDetails.length > 0 && attendaceDetails[0].resource_attendance.punchin_date && moment(attendaceDetails[0].resource_attendance.punchin_date).format('ll')}{
                            regularStatus && ` - (${regularStatus})`}
                        <IconButton
                            edge="start"
                            //color="inherit"
                            onClick={regulizationModal}
                            aria-label="close"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                        ><Close /></IconButton>
                    </DialogTitle>
                    {(loader === `${true}_updateRegulization` || loader === `${true}_createRegulization`) && <LoaderForm />}
                    <DialogContent style={{ height: "500px" }}>
                        <div>
                            <form className="regulazation-form">
                                {regulizeData && (regulizeData.status === 5 || regulizeData.status === 7 || regulizeData.status === 6) ?
                                    <div>
                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Actual Punch In Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{actual_punch_in_datetime}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Corrected Punch In Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{corrected_punch_in_datetime}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Actual Punch Out Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{actual_punch_out_datetime ? actual_punch_out_datetime : "-"}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Corrected Punch Out Date and Time </span></p></div>
                                            <div className="table-row-cell-regular"><p>{corrected_punch_out_datetime}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Clarification</span></p></div>
                                            <div className="table-row-cell-regular"><p>{remark}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Remark</span></p></div>
                                            <div className="table-row-cell-regular"><p>{managerRemark}</p></div>
                                        </div>

                                    </div>
                                    :
                                    <div className="add-new-user-form">
                                        <div className="formInput">
                                            <label >
                                                <span className="label-heading"> Actual Punch In Date and Time : </span>{actual_punch_in_datetime ? actual_punch_in_datetime : ''}
                                            </label>
                                        </div>
                                        <div className="formInput">
                                            <label >
                                                <span className="label-heading"> Actual Punch Out Date and Time : </span>{actual_punch_out_datetime ? actual_punch_out_datetime : ""}
                                            </label>
                                        </div>
                                        <div className="formInput" style={parseInt(punchIn) === 0 ? { flexBasis: "100%" } : {}}>
                                            <label >
                                                Regularize Type
                                            </label>
                                            <select name="request_type" value={punchIn} onChange={(e) => setPunchIn(e.target.value)}>
                                                {GetLocalstorage.regularize_type().map((e, i) => {
                                                    return (
                                                        <option key={i} value={e.value}>{e.label}</option>
                                                    )
                                                })}

                                            </select>
                                        </div>
                                        {parseInt(punchIn) === 1 || parseInt(punchIn) === 0 ?
                                            <div className="formInput">
                                                <label >
                                                    Punch In Date and Time
                                                </label>
                                                <DatePicker
                                                    //disabled={punchIn === '' && true}
                                                    //minDate={minDate}
                                                    //maxDate={maxDate}
                                                    minDate={attendaceDetails && attendaceDetails.length > 0 && attendaceDetails[0].resource_attendance.punchin_date ? new Date(attendaceDetails[0].resource_attendance.punchin_date) : new Date()}
                                                    maxDate={attendaceDetails && attendaceDetails.length > 0 && attendaceDetails[0].resource_attendance.punchin_date ? new Date(attendaceDetails[0].resource_attendance.punchin_date) : new Date()}
                                                    name="punch_in_time"
                                                    selected={punchInTime}
                                                    onChange={(time) => setPunchInTime(time)}
                                                    dropdownMode="select"
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                />
                                            </div>
                                            : ""}
                                        {parseInt(punchIn) === 2 || parseInt(punchIn) === 0 ?
                                            <div className="formInput">
                                                <label >
                                                    Punch Out Date and Time
                                                </label>
                                                <DatePicker
                                                    //disabled={punchOut === '' && true}
                                                    name="punch_out_time"
                                                    //minDate={minDate}
                                                    //maxDate={maxDate}
                                                    minDate={attendaceDetails && attendaceDetails.length > 0 && attendaceDetails[0].resource_attendance.punchin_date ? new Date(attendaceDetails[0].resource_attendance.punchin_date) : new Date()}
                                                    maxDate={attendaceDetails && attendaceDetails.length > 0 && attendaceDetails[0].resource_attendance.punchin_date ? addExtraDate(attendaceDetails[0].resource_attendance.punchin_date) : new Date()}
                                                    selected={punchOutTime}
                                                    onChange={(punchOutTime) => setPunchOutTime(punchOutTime)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                />
                                            </div>

                                            : ""}
                                        <div className="formInput" style={{ flexBasis: "100%" }}>
                                            <label >
                                                Clarification
                                            </label>

                                            <textarea defaultValue={remark} name="address" onChange={handleChange} rows="3" placeholder="clarification"></textarea>
                                        </div>
                                    </div>
                                }
                                {regularId > 0 && regulizeData && (regulizeData.status === 5 || regulizeData.status === 6 || regulizeData.status === 7)
                                    ?
                                    <div className="table-row-regular">
                                        <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Status</span></p></div>
                                        <div className="table-row-cell-regular"><button style={{ marginLeft: "0" }} disabled className={`statuscard ${regularStatus}`}>{regularStatus}</button></div>
                                    </div>
                                    :
                                    loader === false && <button onClick={submitData} className="btn btn-submit">{regulizeData.status === 4 ? "Update" : "Submit"}</button>

                                }
                                {regularId > 0 && regulizeData.status === 4 && loader === false && <button onClick={cancelModale} className="btn btn-cancel">Cancel</button>}
                            </form>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* for delete popup */}
                <Dialog
                    open={openCancelModal}
                    onClose={cancelModale}
                    hideBackdrop
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >

                    <DialogContent>
                        {(loader === `${true}_updateRegulization` || loader === `${true}_createRegulization`) && <LoaderForm />}
                        <div className="popup-confirming">
                            <h3>Cancelled</h3>
                            <p>Are you sure to cancelled regularization request</p>
                            <button className="yes" onClick={() => cancelReq()}>Yes</button>
                            <button className="no" onClick={() => setOpenCancelModal(false)} autoFocus>
                                No
                            </button>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </div>
    )


}

export default AttendanceCalender