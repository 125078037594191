import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const compoffList = createAsyncThunk(
    "get/compoffList",
    async (data) => {
        const res = await DataService.compoff_list(data);
        const resp = res.data;
        return resp;
    }
);

const compoffSlice = createSlice({
    name: "compoff",
    initialState: {
        compOffs: [],
        status: STATUSES.IDLE,
        error: '',
        compoff_List:[],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(compoffList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(compoffList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.compoff_List = action?.payload;
                //state.authLoggedIn = true
            })
            .addCase(compoffList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
    },
});


export default compoffSlice.reducer;