import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getAllDepartmentList = createAsyncThunk(
    "get/department",
    async (data) => {
        const res = await dataService.departmentList(data);
        const resp = res.data;
        return resp;
    }
);

export const getDepartmentById = createAsyncThunk(
    "get/departmentById",
    async (data) => {
        const res = await dataService.departmentById(data);
        const resp = res.data;
        return resp;
    }
);

export const getDepartmentList = createAsyncThunk(
    "get/departmentList",
    async (data) => {
        const res = await dataService.department_List(data);
        const resp = res.data;
        return resp;
    }
);

export const getDepartmentDomains = createAsyncThunk(
    "get/departmentDomains",
    async (data) => {
        const res = await dataService.departmentDomainList(data);
        const resp = res.data;
        return resp;
    }
);

export const DepartmentCreate = createAsyncThunk("department/create", async (data) => {
    const res = await dataService.addDepartment(data);
    return res.data;
});

export const DepartmentUpdate = createAsyncThunk("department/update", async (data) => {
    const res = await dataService.updateDepartment(data.ID, data.addvalues);
    return res.data;
});



const departmentSlice = createSlice({
    name: "department",
    initialState: {
        data: [],
        status: 'idle',
        error: '',
        departmentList: [],
        departmentDomainsList: [],
        departmemtOptionList:[],
        department_Update:[],
        department_Create:[],
        departmentAllList:[],
        department_ById:[],
        count: 0,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getAllDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllDepartmentList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_department_list`;
                state.data = action.payload;
                state.departmentAllList = action.payload;
                state.error = action.error

            })
            .addCase(getAllDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get DepartmentList
            .addCase(getDepartmentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;

            })
            .addCase(getDepartmentList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_department_list`;
                state.departmentList = action.payload.data.rows;
                state.departmemtOptionList = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.count = action.payload.data.count;
                state.error = action.error

            })
            .addCase(getDepartmentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            // Department-Domains
            .addCase(getDepartmentDomains.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentDomains.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.departmentDomainsList = action.payload;

                state.error = action.error

            })
            .addCase(getDepartmentDomains.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

              ///Department create
              .addCase(DepartmentCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(DepartmentCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_department_Create`;
                state.department_Create = action.payload;
                state.error = ""
            })
            .addCase(DepartmentCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Department Update
            .addCase(DepartmentUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(DepartmentUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_department_Update`;
                state.department_Update = action.payload;
                state.error = ""
            })
            .addCase(DepartmentUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///Department by id
            .addCase(getDepartmentById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDepartmentById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_department_ById`;
                state.department_ById = action.payload;
                state.error = ""
            })
            .addCase(getDepartmentById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
    },
});


export default departmentSlice.reducer;