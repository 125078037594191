import React, { useEffect, useState } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { IconButton, Skeleton, Stack, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import './UniqueMegaMenu.css'
import AdminIcon from './UniqueMegamenuAssest/Admin.png'
import HumanResourceIcon from './UniqueMegamenuAssest/human_resource.png'
import ClientIcon from './UniqueMegamenuAssest/client.png'
import RequirementIcon from './UniqueMegamenuAssest/Requirement.png'
import JobIcon from './UniqueMegamenuAssest/job.png'
import InterviewIcon from './UniqueMegamenuAssest/interview.png'
import ScreenignIcon from './UniqueMegamenuAssest/screening.png'
import TestIcon from './UniqueMegamenuAssest/test.png'
import TaskIcon from './UniqueMegamenuAssest/Task.png'
import ReportIcon from './UniqueMegamenuAssest/Report.png'
import { getMenuListForSideBar } from '../../redux/features/sidebarSlice';
import GetLocalstorage from '../common-function/GetLocalstorage';
import { useDispatch, useSelector } from 'react-redux';
import ArrowRight from "@mui/icons-material/ArrowRight";
import Zoom from '@mui/material/Zoom';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { updateBreadcrumbSubMenu, updateBreadcrumbs, updatethirdlevelBreadcrumb } from '../../redux/features/BreadcrumbsSlice';
import { useLocation } from 'react-router-dom';
import { allReport } from '../../redux/features/dashboardSlice';

let user = GetLocalstorage.userDetail();
let tmsRole = user?.tms_role
const tmsRoleId = user?.tms_role_id

const UniqueMegaMenu = ({ emptyNav, setEmptyNave }) => {
    const { sidebarMenuList } = useSelector((state) => state.sidebar);
    let location = useLocation();
    const customMenuList = sidebarMenuList
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElSubmenu, setAnchorElSubmenu] = React.useState(null);
    const [submenuLayerTwo, setsubMenuLayerTwo] = useState([])
    const [submenuLayerThree, setsubMenuLayerThree] = useState([])
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [addClassResponsive, setAddClassResponsive] = useState('')
    const [addActiveBorder, setAddActiveBorder] = useState(null);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const open = Boolean(anchorEl);
    const openSubMenu = Boolean(anchorElSubmenu);

    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClicksubmenu = (event) => {

        setAnchorElSubmenu(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSubMenu = () => {
        setAnchorElSubmenu(null);
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip TransitionComponent={Zoom} {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const BreadcrumbManipulate = (menu) => {
        dispatch(updateBreadcrumbs(menu))
        dispatch(updateBreadcrumbSubMenu({ mainMenu: "", menuId: "" }))
        dispatch(updatethirdlevelBreadcrumb({ mainMenu: "", menuId: "" }))
    }

    useEffect(() => {
        let menu = JSON.parse(localStorage.getItem("breadcrumbsData1"))
        let menu2 = JSON.parse(localStorage.getItem("breadcrumbsData2"))
        let menu3 = JSON.parse(localStorage.getItem("breadcrumbsData3"))
        if (menu3 !== null || menu2 !== null) {
            dispatch(updateBreadcrumbs(menu))
            dispatch(updateBreadcrumbSubMenu(menu2))
            dispatch(updatethirdlevelBreadcrumb(menu3))
        }
    }, [localStorage.getItem("breadcrumbsData1"), localStorage.getItem("breadcrumbsData2"), localStorage.getItem("breadcrumbsData3")])

    const SubBreadcrumbManipulate = (menu) => {
        dispatch(updateBreadcrumbSubMenu(menu))
        dispatch(updatethirdlevelBreadcrumb({ mainMenu: "", menuId: "" }))
    }

    const thirdlevelBreadcrumb = (menu) => {
        dispatch(updatethirdlevelBreadcrumb(menu))
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        if (screenSize.width <= 985) {
            setAddClassResponsive('responsive')
        } else {
            setAddClassResponsive('');
        }
    }, [screenSize])

    useEffect(() => {
        dispatch(getMenuListForSideBar(tmsRoleId))
    }, [dispatch])
    useEffect(() => {
        if (emptyNav === true) {
            setsubMenuLayerTwo([])
            localStorage.setItem('localSubMenuLayerTwo', JSON.stringify(''));
            localStorage.setItem('breadcrumbsData1', JSON.stringify({ mainMenu: '', menuId: '' }));
            localStorage.setItem('breadcrumbsData2', JSON.stringify({ mainMenu: '', menuId: '' }));
            localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: '', menuId: '' }));

        } else {
            setsubMenuLayerTwo(submenuLayerTwo)
        }
    }, [emptyNav])

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("localSubMenuLayerTwo"))
        setsubMenuLayerTwo(data)
    }, [localStorage.getItem("localSubMenuLayerTwo")])
    return (
        <Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <IconButton id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick} sx={{ marginLeft: '10px' }} style={{ color: '#fff' }}>
                    <DashboardIcon />
                </IconButton>
                <Stack>
                    <Stack direction={'row'} className='navBarMenu'>
                        {submenuLayerTwo && submenuLayerTwo.submenu?.length > 0 && submenuLayerTwo?.submenu?.map((subMenuInnerLayer, key) => {
                            return (
                                <>
                                    {subMenuInnerLayer?.submenu.length === 0 ?
                                        <>
                                            <Link id='basic-button' className={location.pathname.replace('/', '') === subMenuInnerLayer?.menu?.url_slug ? 'submenuBorder' : ''} style={{ textDecoration: 'none', marginRight: '2px' }} to={'/' + subMenuInnerLayer?.menu?.url_slug}>
                                                <MenuItem onClick={() => {
                                                    setAddActiveBorder(subMenuInnerLayer?.menu?.url_slug); handleClose();
                                                    SubBreadcrumbManipulate({ mainMenu: subMenuInnerLayer, menuId: subMenuInnerLayer?.menu?.id });
                                                    localStorage.setItem('breadcrumbsData2', JSON.stringify({ mainMenu: subMenuInnerLayer, menuId: subMenuInnerLayer?.menu?.id }));
                                                    localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                }}
                                                    sx={{ display: 'flex', justifyContent: 'space-between', color: '#fff', fontWeight: '600' }}>
                                                    {subMenuInnerLayer?.menu?.title.length > 20 ?
                                                        <LightTooltip arrow placement="top" title={subMenuInnerLayer?.menu?.title}>
                                                            {subMenuInnerLayer?.menu?.title.slice(0, 15) + '...'}
                                                        </LightTooltip> : subMenuInnerLayer?.menu?.title}
                                                    {/* {subMenuInnerLayer?.submenu.length > 0 ? <KeyboardArrowDownIcon /> : <></>} */}
                                                </MenuItem>
                                            </Link>
                                        </>
                                        :
                                        <>
                                            <div className={addActiveBorder === key ? 'submenuBorder' : ''}>
                                                <MenuItem id='basic-button' onClick={(evt) => {
                                                    setAddActiveBorder(key);
                                                    setsubMenuLayerThree(subMenuInnerLayer); handleClicksubmenu(evt);
                                                    // customMenuList.map(item => {
                                                    //     return item.submenu.map(inItem => {
                                                    //         inItem.submenu?.map(coreInitem => {
                                                    //             if (coreInitem.menu_id === subMenuInnerLayer.menu.id) {
                                                    //                 setCoreInitem(coreInitem)
                                                    //             }
                                                    //         })
                                                    //     })
                                                    // })
                                                    SubBreadcrumbManipulate({ mainMenu: subMenuInnerLayer, menuId: subMenuInnerLayer?.menu?.id });
                                                    localStorage.setItem('breadcrumbsData2', JSON.stringify({ mainMenu: subMenuInnerLayer, menuId: subMenuInnerLayer?.menu?.id }));
                                                    localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                }}
                                                    sx={{ display: 'flex', justifyContent: 'space-between', color: '#fff', fontWeight: '600', marginRight: '2px' }}>
                                                    {subMenuInnerLayer?.menu?.title.length > 20 ? <LightTooltip arrow placement="top" title={subMenuInnerLayer?.menu?.title}>
                                                        {subMenuInnerLayer?.menu?.title.slice(0, 15) + '...'}
                                                    </LightTooltip> : subMenuInnerLayer?.menu?.title}
                                                    {subMenuInnerLayer?.submenu.length > 0 ? <KeyboardArrowDownIcon /> : <></>}
                                                </MenuItem>
                                            </div>
                                        </>
                                    }
                                </>
                            )
                        })}
                    </Stack>
                    <Menu
                        anchorEl={anchorElSubmenu}
                        id="account-menu"
                        open={openSubMenu}
                        onClose={handleCloseSubMenu}
                        onClick={handleCloseSubMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: '0px',
                                    right: '100px',
                                    width: '10px',
                                    height: '10px',
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {submenuLayerThree && submenuLayerThree.submenu?.length > 0 && submenuLayerThree?.submenu?.map((subMenuChildInner, key) => {
                            return (
                                <Link style={{ textDecoration: 'none', color: '#000' }} to={'/' + subMenuChildInner?.menu?.url_slug}>
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        thirdlevelBreadcrumb({ mainMenu: subMenuChildInner, menuId: subMenuChildInner?.menu?.id })
                                        localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: subMenuChildInner, menuId: subMenuChildInner?.menu?.id }));

                                    }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* {subMenuChildInner?.menu?.title.length > 20 ? <LightTooltip  placement="bottom-end" title={subMenuChildInner?.menu?.title}>
                                            <Typography variant='inherit'>{subMenuChildInner?.menu?.title.slice(0, 15) + '...'}</Typography>
                                        </LightTooltip> : subMenuChildInner?.menu?.title} */}
                                        {/* {subMenuChildInner?.submenu.length > 0 ? <ArrowRight /> : <></>} */}
                                        <Typography variant='inherit'>{subMenuChildInner?.menu?.title}</Typography>
                                    </MenuItem>
                                </Link>
                            )
                        })}
                    </Menu>
                </Stack>
            </Stack>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                sx={{
                    top: '15px',
                    width: '100%', "& ::-webkit-scrollbar": {
                        width: "10px",
                        height: "8px",
                    },
                    "& ::-webkit-scrollbar-track": {
                        backgroundColor: "#f5f5f5",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                        backgroundColor: "#f5f5f5",
                    },
                }}
            >
                <Stack m={2} direction={"row"}>
                    <div class={`container ${addClassResponsive}`}>
                        {
                            customMenuList.length <= 0 && Array(10).fill({}).map((val, key) => {
                                return (
                                    <div key={key} style={{ margin: 'auto' }} class="card">
                                        <Skeleton sx={{ padding: '5px' }} height={135} variant="rectangular" />
                                        <Stack mt={'15px'} direction={"column"}>
                                            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                                        </Stack>
                                    </div>
                                )
                            })
                        }
                        {customMenuList && customMenuList.length > 0 && customMenuList?.map((mainMenuList, key) => {
                            return (
                                mainMenuList?.menu?.title !== "Dashboard" && mainMenuList?.menu?.title !== "Profile" &&
                                <div class="card" key={key}>
                                    <div class="face face1">
                                        <Stack direction="column" class="content">
                                            {/* <div className='innerHeading'>
                                                <Typography variant='h5' fontSize={'20px'} fontWeight={600}>{mainMenuList?.menu?.title}</Typography>
                                            </div> */}
                                            <div>
                                                {mainMenuList.submenu.length === 0 &&
                                                    <Link style={{ textDecoration: 'none', color: '#000' }} to={'/' + mainMenuList?.menu?.url_slug}>
                                                        <MenuItem onClick={() => {
                                                            setsubMenuLayerTwo([]); handleClose();
                                                            BreadcrumbManipulate({ mainMenu: mainMenuList, menuId: mainMenuList?.menu?.id });
                                                        }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {mainMenuList?.menu?.title.length > 20 ?
                                                                <LightTooltip arrow placement="top" title={mainMenuList?.menu?.title}>
                                                                    {mainMenuList?.menu?.title.slice(0, 15) + '...'}
                                                                </LightTooltip> : mainMenuList?.menu?.title}
                                                            {/* {mainMenuList?.submenu.length > 0 ? <ArrowRight /> : <></>} */}
                                                        </MenuItem>
                                                    </Link>
                                                }
                                                {mainMenuList?.submenu?.map((submenuLayerOne, key, index) => {
                                                    return (
                                                        <>
                                                            {submenuLayerOne?.submenu.length === 0 ?
                                                                <>
                                                                    <Link style={{ textDecoration: 'none', color: '#000' }} to={'/' + submenuLayerOne?.menu?.url_slug}>
                                                                        <MenuItem onClick={() => {
                                                                            BreadcrumbManipulate({ mainMenu: mainMenuList, menuId: submenuLayerOne?.menu?.id });
                                                                            localStorage.setItem('breadcrumbsData1', JSON.stringify({ mainMenu: mainMenuList, menuId: submenuLayerOne?.menu?.id }));
                                                                            localStorage.setItem('breadcrumbsData2', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                                            localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                                            setsubMenuLayerTwo([]); handleClose();
                                                                        }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            {submenuLayerOne?.menu?.title.length > 20 ?
                                                                                <LightTooltip arrow placement="top" title={submenuLayerOne?.menu?.title}>
                                                                                    {submenuLayerOne?.menu?.title.slice(0, 15) + '...'}
                                                                                </LightTooltip> : submenuLayerOne?.menu?.title}
                                                                            {/* {submenuLayerOne?.submenu.length > 0 ? <ArrowRight /> : <></>} */}
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                                :
                                                                <MenuItem onClick={() => {
                                                                    setEmptyNave(false);
                                                                    setsubMenuLayerTwo(submenuLayerOne);
                                                                    localStorage.setItem('localSubMenuLayerTwo', JSON.stringify(submenuLayerOne));
                                                                    handleClose();
                                                                    BreadcrumbManipulate({ mainMenu: mainMenuList, menuId: submenuLayerOne?.menu?.id });
                                                                    localStorage.setItem('breadcrumbsData1', JSON.stringify({ mainMenu: mainMenuList, menuId: submenuLayerOne?.menu?.id }));
                                                                    localStorage.setItem('breadcrumbsData2', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                                    localStorage.setItem('breadcrumbsData3', JSON.stringify({ mainMenu: '', menuId: '' }));
                                                                }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    {submenuLayerOne?.menu?.title.length > 20 ? <LightTooltip arrow placement="top" title={submenuLayerOne?.menu?.title}>
                                                                        {submenuLayerOne?.menu?.title.slice(0, 15) + '...'}
                                                                    </LightTooltip> : submenuLayerOne?.menu?.title}
                                                                    {submenuLayerOne?.submenu.length > 0 ? <ArrowRight /> : <></>}
                                                                </MenuItem>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </Stack>
                                    </div>
                                    <div class="face face2">
                                        <Typography className='menuHeading' variant='h2'>{mainMenuList?.menu?.title}</Typography>
                                        <img className='uniquneMenuIcon' src={
                                            mainMenuList?.menu?.title === 'Admin' ? AdminIcon :
                                                mainMenuList?.menu?.title === 'HR' ? HumanResourceIcon :
                                                    mainMenuList?.menu?.title === 'Client' ? ClientIcon :
                                                        mainMenuList?.menu?.title === 'Recruitment' ? RequirementIcon :
                                                            mainMenuList?.menu?.title === 'Jobs' ? JobIcon :
                                                                mainMenuList?.menu?.title === 'Interview' ? InterviewIcon :
                                                                    mainMenuList?.menu?.title === 'Screening' ? ScreenignIcon :
                                                                        mainMenuList?.menu?.title === 'Test' ? TestIcon :
                                                                            mainMenuList?.menu?.title === 'Task' ? TaskIcon :
                                                                                mainMenuList?.menu?.title === 'Reports' ? ReportIcon : AdminIcon
                                        }
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Stack>
                {/* <Stack className={submenuLayerTwo?.submenu?.length > 0 ? `subMenuContainer ${addClassResponsive}` : ''} direction={'row'}>
                    {submenuLayerTwo && submenuLayerTwo.submenu?.length > 0 && submenuLayerTwo?.submenu?.map((subMenuInnerLayer, key) => {
                        return (
                            <>
                                {subMenuInnerLayer?.submenu.length === 0 ?
                                    <>
                                        <Link style={{ textDecoration: 'none', color: '#000' }} to={'/' + subMenuInnerLayer?.menu?.url_slug}>
                                            <MenuItem onClick={handleClose} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {subMenuInnerLayer?.menu?.title.length > 20 ?
                                                    <LightTooltip arrow placement="top" title={subMenuInnerLayer?.menu?.title}>
                                                        {subMenuInnerLayer?.menu?.title.slice(0, 15) + '...'}
                                                    </LightTooltip> : subMenuInnerLayer?.menu?.title}
                                                {subMenuInnerLayer?.submenu.length > 0 ? <ArrowRight /> : <></>}
                                            </MenuItem>
                                        </Link>
                                    </>
                                    :
                                    <MenuItem onClick={(evt) => { setsubMenuLayerThree(subMenuInnerLayer); handleClicksubmenu(evt) }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {subMenuInnerLayer?.menu?.title.length > 20 ? <LightTooltip arrow placement="top" title={subMenuInnerLayer?.menu?.title}>
                                            {subMenuInnerLayer?.menu?.title.slice(0, 15) + '...'}
                                        </LightTooltip> : subMenuInnerLayer?.menu?.title}
                                        {subMenuInnerLayer?.submenu.length > 0 ? <ArrowRight /> : <></>}
                                    </MenuItem>
                                }
                            </>
                        )
                    })}
                    <Menu
                        anchorEl={anchorElSubmenu}
                        id="account-menu"
                        open={openSubMenu}
                        onClose={handleCloseSubMenu}
                        onClick={handleCloseSubMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: '8px',
                                    right: '158px',
                                    width: '10px',
                                    height: '10px',
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {submenuLayerThree && submenuLayerThree.submenu?.length > 0 && submenuLayerThree?.submenu?.map((subMenuChildInner, key) => {
                            return (
                                <Link style={{ textDecoration: 'none', color: '#000' }} to={'/' + subMenuChildInner?.menu?.url_slug}>
                                    <MenuItem onClick={handleClose} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {subMenuChildInner?.menu?.title.length > 20 ? <LightTooltip placement="right" title={subMenuChildInner?.menu?.title}>
                                            <Typography variant='inherit'>{subMenuChildInner?.menu?.title.slice(0, 15) + '...'}</Typography>
                                        </LightTooltip> : subMenuChildInner?.menu?.title}
                                        {subMenuChildInner?.submenu.length > 0 ? <ArrowRight /> : <></>}
                                    </MenuItem>
                                </Link>
                            )
                        })}
                    </Menu>
                </Stack> */}
            </Menu >
        </Stack >
    )
}

export default UniqueMegaMenu