import "./master.scss"
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Skeleton, Switch, TablePagination, Tooltip, Menu, MenuItem, Stack, Typography, IconButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { meetingproviderEdit, meetingproviderCreate, meetingprovidereUpdate, getMeetingProvider } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Close, Edit } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";


const initialFormData = Object.freeze({
    meeting_provider_name: "",
    description: "",
    status: true,
});


const formErrData = Object.freeze({
    nameErr: "Provider name is required",
    descriptionErr: "Meeting Description is required",
    statusErr: "Status is required"
});

const skeletonBase = { meeting_provider_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['meeting_provider_name', 'status'];

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const MeetingProvider = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, meetingprovider_list, meetingprovider_create, meetingprovider_edit, meetingprovider_update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null)
    let clearGridListing = useRef(false);
    let stopOnRowScrollEnd = useRef(false)
    let editID = useRef(null)
    let gridTotalRows = useRef(null)
    let isAnyChange = useRef({});
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [colSortingPerform, setColSortingPerform] = useState([]);


    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getMeetingProvider(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [filterQuery])


    if (reload === true && status === `${STATUSES.SUCCESS}_meetingprovider_Update` && meetingprovider_update && meetingprovider_update.message) {
        if (meetingprovider_update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(meetingprovider_update.message)
            // dispatch(getMeetingProvider(filterQuery))
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(meetingprovider_update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_meetingprovider_Create` && meetingprovider_create && meetingprovider_create.message) {
        if (meetingprovider_create.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.current}));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(meetingprovider_create.message)
            // dispatch(getMeetingProvider(filterQuery))
        } else {
            toast.error(meetingprovider_create.message)
        }
        setReload(false)
    }
    if (reload === true && status === STATUSES.SUCCESS && meetingprovider_list && meetingprovider_list.message) {
        if (meetingprovider_list.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...meetingprovider_list.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(meetingprovider_list.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...meetingprovider_list.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(meetingprovider_list.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(meetingprovider_list.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null)
    }

    // const handleChange = (evt) => {
    //     const { name, value } = evt.target || evt;
    //     setFormValues({ ...formValues, [name]: value })

    // }
    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        // setFormValues({ ...formValues, [name]: value })
        if (name === 'status') {
            setFormValues({ ...formValues, [name]: checked })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.description === "" || formValues.name === "" || formValues.status === "") {
            setErrStatus(true)
        }
        clearGridListing.current = true;
        if (formValues.description !== "" && formValues.name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item => item.id === ID);
                let index = copyRows.findIndex(item => item.id === ID);
                copyRows.splice(index, 1, { ...skeletonBase, id: ID });
                setGridRows(copyRows);
                dispatch(meetingprovidereUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(meetingproviderCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_meetingprovider_Edit` && meetingprovider_edit && meetingprovider_edit.data) {
        let editData = meetingprovider_edit.data
        setFormValues({
            meeting_provider_name: editData.meeting_provider_name,
            description: editData.description,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(meetingproviderEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickStatus = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            meeting_provider_name: data.meeting_provider_name,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = { ...data };
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(meetingprovidereUpdate({ ID, addvalues }))
    }

    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }
    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] ? 25 : newFilterData['skip'];
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);

    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    const actionColumn = [
        {
            field: "meeting_provider_name",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Meeting</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("meeting_provider_name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.meeting_provider_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.meeting_provider_name}
                        </div>
                );
            },
        },
        // {
        // field: "status",
        // headerName: "Status",
        // width: 160,
        // renderCell: (params) => {
        //     return (
        //         <div className={`cellWithStatus ${params.row.status}`}>
        //             {params.row.status === true ? 'Active' : 'Inactive'}
        //         </div>
        //     );
        // },
        // },
        {
            field: "status",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 160,
            sortable: false,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus ${params.row.status}`}>
                    //     {params.row.status === true ? 'Active' : 'Inactive'}
                    // </div>
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="specialization"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)}
                                />
                            </div>
                        </div >
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: "120",
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName="ArchiveIcon"
        //                     moduleName="meeting_type"
        //                     rowid={params.row.id}
        //                     addMoreData=""
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     isDelete={true}
        //                     editButton={handleClickEdit}
        //                     viewButton={false}
        //                     deleteButton={() => handleArchive(params.row)} />
        //             </div>
        //         );
        //     },
        // },
    ];

    // const onFilterChange = (filterModel) => {
    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                 filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
    //         const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS}]}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(getMeetingProvider(filterData))
    //     }
    //     if (filterModel.items && filterModel.items.length === 0) {
    //         setCurrentPage(0)
    //         //setPageSize(10)
    //         setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS}]}`)
    //         dispatch(getMeetingProvider(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS}]}`))
    //     }
    // }
    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.MEETING_PROVIDER_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartsWith',
                endsWith: 'iendsWith'
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    {/* <div className="top-headings">
                        <h3>Meeting Provider</h3>
                        <div>
                            <Tooltip title="Add Meeting Provider">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                            </Tooltip>
                            <RefreshButton api={getMeetingProvider} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 20 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 

                        </div>
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px",paddingTop: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Meeting Provider</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getMeetingProvider} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />} */}
                    {/* <DataGridPro
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={meetingprovider_list?.data?.rows ? meetingprovider_list.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                        hideFooterPagination={true}
                    /> */}
                    <DataGridPro
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        filterMode="server"
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        loading={ skeletonRows.length === 0 && !!reload }
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        pageSize={pageSize}
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            ColumnMenuIcon: ()=>(<MoreHorizIcon sx={{color:"#171c26ba"}}/>),
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        hideFooterPagination={true}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    {/* <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Meeting Povider</DialogTitle> */}
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Meeting Provider </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Meeting Provider"}
                                    name="meeting_provider_name"
                                    defaultValue={formValues.meeting_provider_name}
                                    type="text"
                                    placeholder="Meeting Provider"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <InputTextareaField
                                    label={"Meeting Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Meeting Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Meeting`}
                    handleArchive={handleClickStatus}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                </Menu>
            </div>
        </div >
    )
}

export default MeetingProvider;