// import Navbar from "../../../components/navbar/Navbar"
// import Sidebar from "../../../components/sidebar/Sidebar"
import "../master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Skeleton, Stack, Switch, TablePagination, Tooltip, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Add, ArrowDownward, ArrowUpward, BorderColorTwoTone, Close, UnfoldMore } from "@mui/icons-material";
import UcActionComponent from "../../../components/common-function/UcActionComponent";
import { InputFields, InputTextareaField, ViewField } from "../../../components/common-function/InputFields";
import LoaderForm from "../../../components/common-function/LoaderForm";
import { STATUSES } from "../../../redux/common-status/constant";
import CONSTANTS from "../../../components/constant/constantComponents";
import { templateCategoryColumns } from "../../../components/datatable-coulms/tableCoulmns";
import CustomNoRowsOverlay from "../../../components/CustomEmptyOverlay";
import { DataGridPro, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { getAllTemplateCategoryList, templateCategoryCreate, templateCategoryEdit, templateCategoryUpdate } from "../../../redux/features/templateCategorySlice";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const skeletonBase = { template_category: '_skeleton_', description: '_skeleton_', status: '_skeleton_' };

const initialFormData = Object.freeze({
    template_category: "",
    description: "",
    status: false,
});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    sourceType_nameErr: "Category name is required",
    statusErr: "Status is required"
});

const TemplateCategory = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, templateCategory_AllList, templateCategory_Create, templateCategory_Edit, templateCategory_Update, sourceType_Archive } = useSelector(state => state.templateCategorySlice)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`)
    const [limit, setLimit] = useState(25);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });

    const apiRef = useGridApiRef();
    const [loadedRows, setLoadedRows] = React.useState([]);
    const [skeleton, setSkeleton] = useState((Array(50).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind }))));
    const [loading, setLoading] = useState(false)
    let stopOnRowScrollEnd = React.useRef(false);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [pinnedRowsIds, setPinnedRowsIds] = useState({
        top: [],
        bottom: [],
    });
    const [pinnedCols, setPinnedCols] = useState({ right: ['action'] })
    let clearGridListing = useRef(false);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const editID = useRef()

    // useEffect(() => {
    //     dispatch(getAllTemplateCategoryList(filterQuery))
    // }, [dispatch])

    useEffect(() => {
        setLoading(true);
        setReload(true);
        dispatch(getAllTemplateCategoryList(filterQuery))
        setSkeleton(() => Array((25)).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    }, [filterQuery]);

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setActiveViewMode(false)
    }

    // update section 
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_templateCategory_Update` && templateCategory_Update && templateCategory_Update.message) {
        if (templateCategory_Update.status === 200) {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: loadedRows.length }));
            setFilterQuery(str)
            toast.success(templateCategory_Update.message)
        } else {
            let copyLoadedRows = [...loadedRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setLoadedRows(copyLoadedRows);
            toast.error(templateCategory_Update.message)
        }
        setReload(false)
    }

    // create section 
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_templateCategory_Create` && templateCategory_Create && templateCategory_Create.message) {
        if (templateCategory_Create.status === 200) {
            setLoadedRows([])
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: loadedRows.length }));
            setFilterQuery(str)
            toast.success(templateCategory_Create.message)
            handleClose()
        } else {
            toast.error(templateCategory_Create.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const handleStatus = (evt) => {
        const { checked } = evt.target || evt;
        setFormValues({ ...formValues, 'status': checked })
    }

    // final submit section 
    const submitData = (e) => {
        e.preventDefault()

        if (formValues.template_category === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.template_category !== "" && formValues.description !== "" && formValues.status !== "") {
            let addvalues = { ...formValues }
            setErrStatus(false)
            setReload('submit')
            if (ID) {
                dispatch(templateCategoryUpdate({ ID, addvalues }))
            } else {
                dispatch(templateCategoryCreate(addvalues))
            }
        }
    }

    //edit setion
    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && templateCategory_Edit && templateCategory_Edit.data) {
        let editData = templateCategory_Edit.data
        setFormValues({
            template_category: editData.template_category,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    //view setion
    if (reload === 'view_data' && status === `${STATUSES.SUCCESS}` && templateCategory_Edit && templateCategory_Edit.data) {
        let editData = templateCategory_Edit.data
        setFormValues({
            template_category: editData.template_category,
            description: editData.description,
            status: editData.status
        })
        setActiveViewMode(true)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(templateCategoryEdit(id))
        setReload('edit_data')
    }

    const handleViewDetail = (id) => {
        dispatch(templateCategoryEdit(id))
        setReload('view_data')
        setActiveViewMode(true)
    }

    // change status on listing page 
    const changeStatus = (status, data) => {
        const ID = data?.id
        let formValues;
        if (status === true) {
            formValues = { template_category: data.template_category, description: data.description, status: false }
        } else {
            formValues = { template_category: data.template_category, description: data.description, status: true }
        }
        setReload('submit')
        dispatch(templateCategoryUpdate({ ID, addvalues: formValues }))
    }

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = loadedRows.length;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        } else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }
        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
    }


    // action columns
    const actionColumn = [
        {
            field: 'pin',
            type: 'actions',
            width: 100,
            getActions: (params) => {
                const isPinnedTop = pinnedRowsIds.top.includes(params.id);
                const isPinnedBottom = pinnedRowsIds.bottom.includes(params.id);
                if (isPinnedTop || isPinnedBottom) {
                    return [
                        <div>
                            <Tooltip title="Unpin">
                                <GridActionsCellItem label="Unpin" icon={
                                    isPinnedTop ? <ArrowDownward /> : <ArrowUpward />
                                }
                                    onClick={() => setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        top: prevPinnedRowsIds.top.filter(
                                            (rowId) => rowId !== params.id,
                                        ),
                                        bottom: prevPinnedRowsIds.bottom.filter(
                                            (rowId) => rowId !== params.id,),
                                    }))
                                    }
                                />
                            </Tooltip>
                        </div>
                    ];
                }
                return [
                    <div>
                        <Tooltip title="Pin at the top">
                            <GridActionsCellItem icon={
                                <ArrowUpward />
                            }
                                label="Pin at the top" onClick={() =>
                                    setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        ...prevPinnedRowsIds,
                                        top: [...prevPinnedRowsIds.top, params.id],
                                    }))}
                            />
                        </Tooltip>
                    </div>,
                    <div>
                        <Tooltip title="Pin at the bottom">
                            <GridActionsCellItem
                                icon={
                                    <ArrowDownward />
                                }
                                label="Pin at the bottom"
                                onClick={() =>
                                    setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        ...prevPinnedRowsIds,
                                        bottom: [...prevPinnedRowsIds.bottom, params.id],
                                    }))
                                }
                            />
                        </Tooltip>
                    </div>
                ];
            },
        },
        {
            field: "template_category",
            sortable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Category Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("template_category")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            width: 240, renderCell: params => {
                return (
                    params?.row?.template_category === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.template_category}</Typography>
                )
            }
        },
        {
            field: "description",
            sortable: false,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("description")}><UnfoldMore sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            width: 340, renderCell: params => {
                return (
                    params?.row?.description === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.description}</Typography>
                )
            }

        },
        {
            field: "status",
            headerName: "Status",
            sortable: false, width: 160, type: 'singleSelect',
            valueOptions: [{ label: "Active", value: 'True' }, { label: "In-Active", value: 'False' }],
            renderCell: (params) => {
                return (
                    params?.row?.status === "_skeleton_" ? <Skeleton width={"300px"} />
                        : <div>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* <Typography className={`cellWithStatus ${params.row.status}`}>
                                    {params.row.status === true ? "Active" : "Inactive"}
                                </Typography> */}
                                <Switch
                                    checked={params.row.status === true ? true : false}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    onChange={() => changeStatus(params.row.status, params.row)}
                                />
                            </Stack>
                        </div>
                );
            },
        },
        {
            field: "action", headerName: "Action", width: "120", hideable: false, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={handleClickEdit}
                            viewButton={handleViewDetail}
                            deleteButton={false}
                        />
                    </div>
                );
            },
        },
    ];

    // filter
    const onFilterChange = (props) => {
        stopOnRowScrollEnd.current = false;
        setLimit(20)

        if (filterModal?.items?.length > props?.items?.length) {
            setFilterModel(props)
            if (props?.items?.length === 0) {
                setFilterQuery(`{ "skip":0,"limit": 20,"order_by": { "column": "-status" } }`)
            }
            return;
        } else if (filterModal?.items?.length < props?.items?.length && props?.items?.length <= 5) {
            setFilterModel(props)
            return;
        } else if (filterModal?.items?.length === props?.items?.length) {
            setFilterModel(props)
            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" } };
            let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }
            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }))
                }
            }
        }
    }

    // handle load data response
    if (loading === true && status === `${STATUSES.SUCCESS}_template_category_list`) {
        if (templateCategory_AllList.status === 200) {
            let fout = [];
            // let copyRows = [...loadedRows, ...templateCategory_AllList.data.rows];
            // copyRows = copyRows.filter(item => {
            //     if (fout.includes(item.id)) return false
            //     fout.push(item.id)
            //     return true
            // })
            // setLoadedRows(copyRows)
            // SET_MAX_ROW_LENGTH(templateCategory_AllList?.data?.count || null)
            if (clearGridListing.current) {
                setLoadedRows([...templateCategory_AllList.data.rows]);
                clearGridListing.current = false;
                setSkeleton([])
                SET_MAX_ROW_LENGTH(templateCategory_AllList.data.count);
            } else {
                setLoadedRows((pre) => [...pre, ...templateCategory_AllList.data.rows]);
                SET_MAX_ROW_LENGTH(templateCategory_AllList.data.count);
                setSkeleton([])
            }
        } else {
            toast.error(templateCategory_AllList.message)
        }
        setSkeleton([]);
        setLoading(false)
        setReload(false)
    }

    // lazyloading
    const loadServerRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.order_by = { "column": "-status" };
        updatedFilter.skip = loadedRows.length;
        updatedFilter.limit = 20;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowsScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (loadedRows.length < MAX_ROW_LENGTH && skeleton.length === 0) {
            loadServerRows(params.viewportPageSize);
        }
    };


    //   for pinning the row 
    const { pinnedRows } = React.useMemo(() => {
        const rowsData = [];
        const pinnedRowsData = { top: [], bottom: [] };
        loadedRows.forEach((row) => {
            if (pinnedRowsIds.top.includes(row.id)) {
                pinnedRowsData.top.push(row);
            } else if (pinnedRowsIds.bottom.includes(row.id)) {
                pinnedRowsData.bottom.push(row);
            } else {
                rowsData.push(row);
            }
        });
        return { rows: rowsData, pinnedRows: pinnedRowsData, };
    }, [pinnedRowsIds]);

    return (
        <div className="jiraStatus">
            {/* <Sidebar /> */}
            <div className="jiraStatusContainer">
                {/* <Navbar /> */}
                <ToastContainer />
                <div className="datatable">
                    {/* header section */}
                    {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px", color: "#353163" }}>
                            <p>Template Category</p>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row">
                                <Button onClick={handleOpen} variant="outlined" style={{ color: "#353163" }}>Create Category<BorderColorTwoTone /></Button>
                            </Stack>
                        </div>
                    </div> */}

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p style={{ color: "#37377e" }}>Template Category</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create Category<BorderColorTwoTone /></Button>
                            </Stack>
                        </div>
                    </div>
                    {/* datagrid pro */}

                    <DataGridPro
                        apiRef={apiRef}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={loadedRows?.concat(skeleton) || []}
                        columns={(actionColumn)}
                        hideFooterPagination
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        initialState={{
                            pinnedColumns: { right: ['action'] },
                        }}
                        rowThreshold={.7}
                        filterModel={filterModal}
                        onFilterModelChange={onFilterChange}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        // onSortModelChange={(sort) => handleSort(sort)}
                        onRowsScrollEnd={handleOnRowsScrollEnd}
                        hideFooter
                        experimentalFeatures={{ lazyLoading: true, rowPinning: true }}
                        onPinnedColumnsChange={(prop) => {
                            setPinnedCols(prop)
                        }}
                        pinnedColumns={pinnedCols || []}
                        pinnedRows={pinnedRows}

                    />
                </div>

                {/* start add/edit/view popup */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                        {ID ? 'Edit' : activeViewMode === true ? "View" : 'Add New'} Category
                    </DialogTitle>
                    {(reload === 'submit' || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {/* view form*/}
                    {activeViewMode === true ?
                        <DialogContent>
                            <div>
                                <form className="regulazation-form">
                                    <div style={{ marginBottom: "50px" }}>
                                        <ViewField
                                            label={'Category Name'}
                                            key={'template_category'}
                                            type={1}
                                            value={formValues.template_category}
                                        />
                                        <ViewField
                                            label={'Description'}
                                            key={'editor'}
                                            fieldType={'editor'}
                                            type={1}
                                            value={formValues.description}
                                        />
                                        <ViewField
                                            label={'Status'}
                                            key={'status'}
                                            type={1}
                                            value={formValues.status}
                                        />
                                    </div>
                                </form>
                            </div>
                        </DialogContent>
                        :
                        <DialogContent>
                            <form>
                                <div className="add-new-user-form">
                                    <div className='formInput' style={{ flexBasis: "100%" }}>
                                        <InputFields
                                            label={"Category Name"}
                                            name="template_category"
                                            defaultValue={formValues.template_category}
                                            type="text"
                                            placeholder="Category Name"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.sourceType_nameErr}
                                        />
                                    </div>

                                    <InputTextareaField
                                        label={"Description"}
                                        defaultValue={formValues.description}
                                        name="description"
                                        onChange={handleChange}
                                        rows="3"
                                        placeholder="Description"
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />

                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography className={`cellWithStatus false`}>
                                            Inactive
                                        </Typography>
                                        <Switch
                                            checked={formValues.status === true ? true : false}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                            onChange={handleStatus}
                                        />
                                        <Typography className={`cellWithStatus true`}>
                                            Active
                                        </Typography>
                                    </Stack>

                                </div>
                                <button
                                    className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                    disabled={reload === "submit" ? true : false} onClick={submitData}
                                    style={{ float: "inline-end" }}
                                >
                                    {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}

                                </button>
                            </form>
                        </DialogContent>
                    }
                </Dialog>

            </div>
        </div>
    )
}

export default TemplateCategory