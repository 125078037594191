import "./master.scss";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Menu, Switch, TablePagination, Tooltip, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SpecialisationsCreate,
    SpecialisationsEdit,
    SpecialisationsUpdate,
    getSpecialisationsList,
} from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Edit, Close } from "@mui/icons-material";
import ArchiveIcon from '@mui/icons-material/Archive';

import {
    InputFields,
    InputRadioGroupFields,
    InputTextareaField,
} from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const initialFormData = Object.freeze({
    name: "",
    description: "",
    tech_type: "",
    specialization_key: "",
    status: true,
});

const formErrData = Object.freeze({
    specialisationErr: "Specialisation is required",
    descriptionErr: "Specialisation description is required",
    techErr: "Technology type is required",
    specialization_keyErr: "Specialization key is required",
    statusErr: "Status is required",
});

function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', description: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', tech_type: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', specialization_key: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const Specialisations = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const [formErr] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const {
        status,
        error,
        specialisations_List,
        specialisations_Create,
        specialisations_Edit,
        specialisations_Update,
    } = useSelector((state) => state.master);
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false);
    const [filterQuery, setFilterQuery] = useState(
        `{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
    );
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });
    const [changeStatus, setChangeStatus] = useState("");
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    //DataGrid Pro
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const filterableColumns = ['name', 'description', 'specialization_key', 'tech_type', 'status'];
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});



    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) {
            setID(0);
            setFormValues(initialFormData);
        }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false);
        setContextMenu(null);
    };

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true)
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getSpecialisationsList(filterQuery));
        if (status === STATUSES.FAILURE && error) {
            setReload(false);
            errorHandling(error, navigate);
        }
    }, [filterQuery]);

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_specialisations_Update` &&
        specialisations_Update &&
        specialisations_Update.message
    ) {
        if (specialisations_Update.status === 200) {
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...specialisations_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            // let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
            // setFilterQuery(str)
            handleClose();
            toast.success(specialisations_Update.message);
        } else {
            // let copyLoadedRows = [...gridRows];
            // let index = copyLoadedRows.findIndex(item => item?.id === editID?.current?.id)
            // copyLoadedRows.splice(index, 1, editID.current)
            // setGridRows(copyLoadedRows);
            toast.error(specialisations_Update.message);
        }
        setReload(false);
    }

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_specialisations_Create` &&
        specialisations_Create &&
        specialisations_Create.message
    ) {
        if (specialisations_Create.status === 200) {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(specialisations_Create.message);
            handleClose();

            // dispatch(getSpecialisationsList(filterQuery));
        } else {
            toast.error(specialisations_Create.message);
        }
        setReload(false);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}`) {
        if (specialisations_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...specialisations_List.data.rows])
                clearGridListing.current = false;
                setSkeletonRows([])
            }
            else {
                setGridRows((pre) => [...pre, ...specialisations_List.data.rows]);
                SET_MAX_ROW_LENGTH(specialisations_List.data.count);
                setSkeletonRows([])
            }

        } else {
            toast.error(specialisations_List.message)
        }
        setSkeletonRows([]);
        setReload(false)
    }



    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        if (name === 'status') {
            setFormValues({ ...formValues, [name]: checked })
        }
        else {
            setFormValues({ ...formValues, [name]: value })
        }
        // setFormValues({ ...formValues, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();

        if (
            formValues.name === "" ||
            formValues.description === "" ||
            formValues.tech_type === "" ||
            formValues.specialization_key === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }
        clearGridListing.current = true;

        if (
            formValues.name !== "" &&
            formValues.description !== "" &&
            formValues.tech_type !== "" &&
            formValues.specialization_key !== "" &&
            formValues.status !== ""
        ) {
            const rtm_user = JSON.parse(
                localStorage.getItem(`${REACT_APP_ENV}_rtms`)
            );
            let userId = rtm_user?.user?.data.id;
            let addvalues = { ...formValues, created_by: userId };
            setErrStatus(false);
            setReload(true);
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item => item.id === ID);
                let index = copyRows.findIndex(item => item.id === ID);
                copyRows.splice(index, 1, { ...skeletonBase, id: ID });
                setGridRows(copyRows);
                dispatch(SpecialisationsUpdate({ ID, addvalues }));
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(SpecialisationsCreate(addvalues));
            }
        }
    };

    if (
        reload === "edit_data" &&
        status === `${STATUSES.SUCCESS}` &&
        specialisations_Edit &&
        specialisations_Edit.data
    ) {
        let editData = specialisations_Edit.data;
        setFormValues({
            name: editData.name,
            description: editData.description,
            tech_type: editData.tech_type,
            specialization_key: editData.specialization_key,
            status: editData.status,
            id: editData?.id,
        });
        setID(editData.id);
        setOpen(true);
        setReload(false);
    }

    const handleClickEdit = (id) => {
        dispatch(SpecialisationsEdit(id));
        setReload("edit_data");
    };

    const handleArchive = (id) => {
        setArchiveId(id);
        setArchiveOpen(true);
        if (id.status === true) {
            setChangeStatus("InActive");
        } else {
            setChangeStatus("Active");
        }
    };

    const handleClickArchive = (data) => {
        setReload(true);
        setArchiveOpen(false);
        let addvalues = {
            name: data.name,
            description: data.description,
            tech_type: data.tech_type,
            specialization_key: data.specialization_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
        };
        clearGridListing.current = true;
        editID.current = { ...data }
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true);
        dispatch(SpecialisationsUpdate({ ID, addvalues }));
    };

    //custom pagination
    // const handlePagination = (data) => {
    //     setCurrentPage(data);
    //     let newFilterData = JSON.parse(filterQuery);
    //     newFilterData["skip"] = data * pageSize;
    //     newFilterData["limit"] = limit;
    //     setFilterQuery(JSON.stringify(newFilterData));
    //     dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
    // };

    // const handlePageSizeChange = (newPageSize) => {
    //     setPageSize(newPageSize.target.value);
    //     setCurrentPage(0);
    //     setLimit(newPageSize.target.value);
    //     let newFilterData = JSON.parse(filterQuery);
    //     newFilterData["skip"] = 0;
    //     newFilterData["limit"] = newPageSize.target.value;
    //     setFilterQuery(JSON.stringify(newFilterData));
    //     dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
    // };

    // function CustomPagination() {
    //     return (
    //         <TablePagination
    //             component="div"
    //             count={
    //                 (specialisations_List &&
    //                     specialisations_List.data &&
    //                     specialisations_List.data.rows &&
    //                     specialisations_List.data.count) ||
    //                 0
    //             }
    //             page={currentPage}
    //             rowsPerPage={pageSize}
    //             rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
    //             onPageChange={() => setCurrentPage(pageSize)}
    //             onRowsPerPageChange={handlePageSizeChange}
    //             ActionsComponent={(subProps) => (
    //                 <TablePaginationActions
    //                     {...subProps}
    //                     handlePagination={handlePagination}
    //                 />
    //             )}
    //         />
    //     );
    // }

    // sorting
    // const handleSort = (sort) => {
    //     if (sort.length) {
    //         let newFilterData = JSON.parse(filterQuery);
    //         newFilterData["skip"] = 0;
    //         newFilterData["limit"] = limit;
    //         newFilterData["order_by"] = {
    //             column: sort[0].sort === "desc" ? "-" + sort[0].field : sort[0].field,
    //         };
    //         setFilterQuery(JSON.stringify(newFilterData));
    //         dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
    //     } else {
    //         setFilterQuery(
    //             `{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}] }`
    //         );
    //     }
    // };
    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);

    }
    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };
    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }
    const actionColumn = [
        {
            field: "name",
            // headerName: "Specialization",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Specialization</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (

                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.name}
                        </div>
                )
            },
        },
        {
            field: "description",
            // headerName: "Description",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.description}
                        </div>
                )
            },
        },
        {
            field: "tech_type",
            // headerName: "Technology Type",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Technology Type</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("tech_type")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.tech_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div>
                            {params.row.tech_type}
                        </div>
                )
            },
        },
        {
            field: "specialization_key",
            // headerName: "Specialization key",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Specialization key</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("specialization_key")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.specialization_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div>
                            {params.row.specialization_key}
                        </div>
                )
            },
        },
        {
            field: "status",
            headerName: "Status",
            // headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 160,
            sortable: false,
            type: 'singleSelect',
            valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            {/* {params.row.status === true ? "Active" : "Inactive"} */}
                            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="specialization"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    // isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                // deleteButton={() => handleArchive(params.row)}
                                />
                            </div>
                        </div >
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: "120",
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName="ArchiveIcon"
        //                     moduleName="specialization"
        //                     rowid={params.row.id}
        //                     addMoreData=""
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     isDelete={true}
        //                     editButton={handleClickEdit}
        //                     viewButton={false}
        //                     deleteButton={() => handleArchive(params.row)}
        //                 />
        //             </div>
        //         );
        //     },
        // },
    ];

    // const onFilterChange = (filterModel, r) => {

    //     if (filterModel.items.length) {
    //         setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
    //     }

    //     if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
    //         setReload(true);
    //         setTimeout(() => {
    //             setReload(false)
    //             setCurrentPage(0);
    //             setFilterQuery(
    //                 `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
    //             );
    //             dispatch(
    //                 getSpecialisationsList(
    //                     `{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
    //                 )
    //             );
    //         }, 0);
    //     }

    //     if (
    //         filterModel.items &&
    //         filterModel.items.length > 0 &&
    //         filterModel.items[0].columnField &&
    //         filterModel.items[0].value
    //     ) {
    //         let operatorValue =
    //             filterModel.items[0].operatorValue === "contains"
    //                 ? "icontains"
    //                 : filterModel.items[0].operatorValue === "startsWith"
    //                     ? "istartswith"
    //                     : filterModel.items[0].operatorValue === "endsWith"
    //                         ? "iendswith"
    //                         : filterModel.items[0].operatorValue;
    //         const filterData = `{"filter":{"${[
    //             filterModel.items[0].columnField,
    //         ]}":{"value":"${filterModel.items[0].value
    //             }","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS
    //             }]}`;
    //         setFilterQuery(filterData);
    //         setCurrentPage(0);
    //         dispatch(getSpecialisationsList(filterData));
    //     }
    // };
    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartsWith',
                endsWith: 'iendsWith'
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})

                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">Specialisations List</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Country">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getSpecialisationsList} filter={filterQuery} style={{ marginTop: 0 }} />
                        </Stack>
                    </Stack>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING ||
                        reload === true ||
                        reload === "edit_data") && <LoaderForm />} */}
                    <Stack mt={2} direction={"row"}>
                        <DataGridPro
                            className="datagrid"
                            filterMode="server"
                            loading={!!reload}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={
                                gridRows?.concat(skeletonRows)
                            }
                            columns={actionColumn}

                            sx={{
                                boxShadow: '1px 13px 31px -15px gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                ColumnMenuIcon: MoreHorizIcon,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                            }
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            // onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            // onFilterModelChange={onFilterChange}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                        // hideFooter
                        />

                    </Stack>


                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: "white" }}
                    >
                        <Close />
                    </Button>
                    <DialogTitle
                        style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Specialisations
                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputTextareaField
                                    label={"Specialisation"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Specialisation"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.specialisationErr}
                                />

                                <InputTextareaField
                                    label={"Technology Type"}
                                    name="tech_type"
                                    defaultValue={formValues.tech_type}
                                    type="text"
                                    placeholder="Technology Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.techErr}
                                />

                                <InputTextareaField
                                    label={"Specialisation Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Specialisation Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                <InputFields
                                    label={"Specialization key"}
                                    name="specialization_key"
                                    defaultValue={formValues.specialization_key}
                                    type="text"
                                    placeholder="Specialization key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.specialization_keyErr}
                                />

                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" defaultChecked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <Stack direction={"row"} justifyContent={"end"}>
                                <Button variant="contained" onClick={submitData}>
                                    {ID
                                        ? CONSTANTS.COMMON_BUTTONS.UPDATE
                                        : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                                </Button>
                            </Stack>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} specialisations`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    {/* <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem> */}

                </Menu>
            </div>
        </div>
    );
};

export default Specialisations;
