import "./master.scss"
import React, { useRef } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Paper, Skeleton, Stack, Switch, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countryCreate, countryEdit, countryUpdate, getCountryList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
// import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { AttachFile } from "@mui/icons-material";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import dataService from "../../redux/services/data.service";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import ArchiveIcon from '@mui/icons-material/Archive';
import { DataGridPro, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import Draggable from 'react-draggable';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
const { REACT_APP_API_BASEURL } = process.env;

const initialFormData = Object.freeze({
    name: "",
    code: "",
    status: true,
});

const formErrData = Object.freeze({
    nameErr: "Country name is required",
    codeErr: "Country code is required",
    statusErr: "Status is required"
});

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} {...props} />
        </Draggable>
    );
}

function StatusInputValue(props) {
    const { item, applyValue, focusElementRef } = props;
    const ratingRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Value
                </InputLabel>
                <NativeSelect
                    defaultValue={30}
                    inputProps={{
                        name: 'value',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleFilterChange}
                >
                    <option value={'True'}>True</option>
                    <option value={'False'}>False</option>
                </NativeSelect>
            </FormControl>
        </Box>
    );
}

const Country = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, country_List, country_Create, country_Edit, country_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const [files, setFiles] = useState()
    const [selectedFile, setSelectedFile] = useState(`${REACT_APP_API_BASEURL}file/download/2690`);
    const uploadInputRef = useRef(null);

    const [gridRows, setGridRows] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [skeletonRows, setSkeletonRows] = useState(Array(15).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const editID = useRef()
    let isAnyChange = useRef({});
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const filterableColumns = ['name', 'code', 'status'];



    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
        setSelectedFile(`${REACT_APP_API_BASEURL}file/download/2690`)
    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            case 'status':
                handleArchive(row);
                break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    useEffect(() => {
        dispatch(getCountryList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [])
    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getCountryList(filterQuery))
    }, [filterQuery])

    if (reload === true && status === `${STATUSES.SUCCESS}_country_Update` && country_Update && country_Update.message) {
        if (country_Update.status === 200) {
            // let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
            // setFilterQuery(str)
            let copyGridRows = [...gridRows].map(item => {
                if (editID?.current?.id === item?.id)
                    return { ...country_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            handleClose();
            toast.success(country_Update.message)
        } else {
            // let copyLoadedRows = [...gridRows];
            // let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            // copyLoadedRows.splice(index, 1, editID.current)
            // setGridRows(copyLoadedRows);
            toast.error(country_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_country_Create` && country_Create && country_Create.message) {
        if (country_Create.status === 200) {
            toast.success(country_Create.message)
            dispatch(getCountryList(filterQuery))
        } else {
            toast.error(country_Create.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);


    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }


    const submitData = async (e) => {
        e.preventDefault()

        if (formValues.code === "" || formValues.name === "" || formValues.status === "") {
            setErrStatus(true)
            return;
        }

        if (files) {
            const acceptedLogoFormat = ["jpg", "png"]
            if (!acceptedLogoFormat.includes(files.type.split("/")[1])) {
                toast.error("Please select image in jpg or png")
                return
            }
        }
        if (files) {
            const maxSizeInBytes = 512 * 1024
            if (files.size > maxSizeInBytes) {
                toast.error("Image size should be less than 512 KB");
                return;
            }
        }
        let formData = new FormData();
        formData.append('file', files);
        formData.append('upload_type', "country");
        formData.append('display_name', "country");

        const res = await dataService.create_attachment(formData)
        const response = res?.data

        if (files && response.status === 200) {
            const logo = response?.data?.api_path_for_download

            if (formValues.code !== "" && formValues.name !== "" && formValues.status !== "") {
                const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
                let userId = rtm_user?.user?.data && rtm_user?.user?.data.id
                let addvalues = { ...formValues, created_by: userId, assigned_by: userId, logo: logo }
                setErrStatus(false)
                setReload(true)
                handleClose()
                if (ID) {
                    dispatch(countryUpdate({ ID, addvalues }))
                } else {
                    dispatch(countryCreate(addvalues))
                }
            }
        } else {
            if (formValues.code !== "" && formValues.name !== "" && formValues.status !== "") {
                const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
                let userId = rtm_user?.user?.data && rtm_user.user.data.id
                let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
                setErrStatus(false)
                setReload(true)
                handleClose()
                if (ID) {
                    dispatch(countryUpdate({ ID, addvalues }))
                } else {
                    dispatch(countryCreate(addvalues))
                }
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && country_Edit && country_Edit.data) {
        let editData = country_Edit.data
        setFormValues({
            name: editData.name,
            code: editData.code,
            status: editData.status
        })
        const path = editData?.logo?.slice(1) || ""
        setSelectedFile(`${REACT_APP_API_BASEURL}${path}`)
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && country_List && country_List.message) {
        if (country_List.status === 200) {

            setGridRows([...country_List?.data?.rows].sort(item => item.status ? -1 : 1));
            setSkeletonRows([])
            SET_MAX_ROW_LENGTH(country_List.data.count);
        }
        else {
            setSkeletonRows([])
            toast.error(country_List.message);
        }
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(countryEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            code: data.code,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        editID.current = { ...data };
        clearGridListing.current = true;
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(countryUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCountryList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCountryList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(country_List && country_List.data && country_List.data.rows && country_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];

        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }
        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith' /* add more over time */].includes(value),
    );

    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];
    const actionColumn = [
        {
            field: "name",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Country Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon fontSize="16px" />
                    </IconButton>
                </Box>
            </Stack>,
            width: 600, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={600} /> :
                        <Typography variant="p">
                            {params.row.name}
                        </Typography>
                );
            },
        },
        {
            field: "code",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Country Code</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("code")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon fontSize="16px" />
                    </IconButton>
                </Box>
            </Stack>,
            width: 600, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={600} /> :
                        <Typography variant="p">
                            {params.row.code}
                        </Typography>

                );
            },
        },
        {
            field: "status",
            filterOperators: statusOnlyOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
                {/* <Box>
                    <IconButton onClick={() => !reload && handleSort("status")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon fontSize="16px" />
                    </IconButton>
                </Box> */}
            </Stack>,
            width: 600, maxWidth: 650, sortable: false,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus ${params.row.status}`}>
                    //     {params.row.status === true ? 'Active' : 'Inactive'}
                    // </div>
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={600} /> :
                        <Stack width={'20%'} justifyContent={"space-between"} direction="horizontal">
                            <Stack>
                                <Switch {...label} defaultChecked={params.row.status} onChange={() => handleArchive(params.row)} />
                            </Stack>
                            <Stack className="cellAction">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="country"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)}
                                />
                            </Stack>
                        </Stack>
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: 150, maxWidth: 150,
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             params.row.action === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
        //                 <div className="cellAction">
        //                     <UcActionComponent
        //                         deleteIconName="ArchiveIcon"
        //                         moduleName="country"
        //                         rowid={params.row.id}
        //                         addMoreData=""
        //                         editLinkUrl=""
        //                         viewLinkUrl=""
        //                         isDelete={true}
        //                         editButton={handleClickEdit}
        //                         viewButton={false}
        //                     // deleteButton={() => handleArchive(params.row)} 
        //                     />
        //                 </div>
        //         );
        //     },
        // },
    ];

    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    if (!newProps.items[index].value) {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.COUNTRY_LIST_COLUMNS?.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                startsWith: 'startswith',
                endsWith: 'endswith',
                equals: 'ieq',
                is: 'eq',
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }

    let file = null;

    const onFileChange = async (event) => {
        file = event.target.files[0];
        setFiles(file);

        if (file.type.includes("image")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedFile(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
        };
    }

    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };



    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">Country List</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Country">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getCountryList} filter={filterQuery} setReload={setReload} style={{ marginTop: 0 }} />
                        </Stack>
                    </Stack>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    <Stack mt={2} direction={"row"}>
                        <DataGridPro
                            sortingOrder={[null]}
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={gridRows.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                // Pagination: CustomPagination,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                            }}
                            loading={(status === STATUSES.LOADING || reload === true || reload === 'edit_data')}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            hideFooterRowCount={true}
                            // hideFooter={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                boxShadow: '1px 13px 31px -15px gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                        />
                    </Stack>
                </div>
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ backgroundColor: "#438bf9", color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>{ID ? 'Edit' : 'Add'} Country</DialogTitle>

                    <DialogContent>
                        <form>
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                width={'95%'}
                                m={'auto'}
                            >
                                <Typography variant="subtitle1" fontSize={'18px'}>Country</Typography>
                                <TextField
                                    sx={{ marginBottom: '10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    fullWidth
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Country Name"
                                    onChange={handleChange}
                                    error={formValues.name === '' && errStatus}
                                    helperText={formValues.name === '' || errStatus ? formErr.nameErr : ""}
                                />
                                <Typography variant="subtitle1" fontSize={'18px'}>Code</Typography>
                                <TextField
                                    sx={{ marginBottom: '10px' }}
                                    fullWidth
                                    name="code"
                                    defaultValue={formValues.code}
                                    type="text"
                                    placeholder="Country-Code"
                                    onChange={handleChange}
                                    formErr={formErr.codeErr}
                                    error={formValues.code === '' && errStatus}
                                    helperText={formValues.name === '' || errStatus ? formErr.codeErr : ""}
                                />
                                <>
                                    <Stack direction={"row"}>
                                        <Typography variant="subtitle1" fontSize={'18px'}>Add Country Flag</Typography>
                                        <input
                                            label="Add Country Flag"
                                            id="fileUpload"
                                            ref={uploadInputRef}
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={onFileChange}
                                            name="file"
                                        />
                                        <Tooltip title="Add Attachment">
                                            <span style={{ cursor: 'pointer' }}>
                                                <AttachFile
                                                    className={`attach_icon`}
                                                    onClick={() =>
                                                        uploadInputRef.current && uploadInputRef.current.click()
                                                    }
                                                />
                                            </span>
                                        </Tooltip>
                                        {selectedFile && (
                                            <div className="image-preview-container">
                                                <img style={{ height: "40px", width: "60px" }} src={selectedFile} alt="Selected File Preview" />
                                            </div>
                                        )}
                                    </Stack>
                                </>
                                <Stack justifyContent={"end"} alignItems={"center"} direction={'row'}>
                                    <Typography mr={'25px'} variant="subtitle1" fontSize={'18px'}>
                                        Status
                                    </Typography>
                                    <Typography variant="subtitle2">InActive</Typography>
                                    <Switch name="status" {...label} checked={formValues?.status} onChange={(e) => { handleChange({ target: { value: e.target.checked, name: 'status' } }) }} />
                                    <Typography variant="subtitle2">Active</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"end"} >
                                    <Button variant="contained" color="info" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                                </Stack>

                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Country`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

                </Menu>
            </div>
        </div >
    )
}

export default Country;