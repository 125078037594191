import React from 'react'
import Navbar from '../navbar/Navbar'
import Sidebar from '../sidebar/Sidebar'

const BasicLayout = ({ children }) => {
  return (
    // <div>
    // </div>
    <div className="new">
      <div className="newContainer">
        {children}

      </div>
    </div>
  )
}

export default BasicLayout