import React, { useState, useEffect } from 'react'
import "./notification.scss";
import { useDispatch, useSelector } from 'react-redux';
import { allIssuetype, allResource, getRemainderList, main_Project_List, remainderUpdateById } from '../../redux/features/taskSlice';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import TablePaginationActions from "../../components/TablePaginationActions";
import { TablePagination, Tooltip } from '@mui/material';
import { RemainderColumns } from '../../components/datatable-coulms/tableCoulmns';
import { DataGrid, getGridDateOperators, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { STATUSES } from '../../redux/common-status/constant';
import { toast, ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import moment from 'moment/moment';
import { getGridStringOperators } from '@mui/x-data-grid';

const Notification = () => {
    const dispatch = useDispatch()
    const { remainderList, mainProjectList, status, resource_list, allIssueList, remainderUpdate } = useSelector(state => state.task)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"created_by":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterProjectList, setProjectFilterList] = useState([]);
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterIssueTypeList, setFilterIssueTypeList] = useState([]);
    const [reloading, setReloading] = useState(true)
    const [updateLoad, setUpdateLoad] = useState(false)

    useEffect(() => {
        dispatch(getRemainderList(filterQuery))
        dispatch(main_Project_List())
        dispatch(allResource())
        dispatch(allIssuetype())
    }, [])

    if (filterProjectList && filterProjectList.length === 0 && status === `${STATUSES.SUCCESS}_mainProjectList` && mainProjectList && mainProjectList.data) {
        let array = []
        mainProjectList?.data && mainProjectList.data.rows.map(e => { return array.push({ value: e.id, label: e.project_name }) })
        array.length > 0 && setProjectFilterList(array)
    }

    if (filterResourceList && filterResourceList.length === 0 && status === `${STATUSES.SUCCESS}_resource_list` && resource_list && resource_list.data) {
        let array = []
        resource_list?.data && resource_list.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
        array.length > 0 && setFilterResourceList(array)
    }

    if (filterIssueTypeList && filterIssueTypeList.length === 0 && status === `${STATUSES.SUCCESS}_allIssueList` && allIssueList && allIssueList.data) {
        let array = []
        allIssueList?.data && allIssueList.data.rows.map(e => { return array.push({ value: e.id, label: e.issue_name }) })
        array.length > 0 && setFilterIssueTypeList(array)
    }

    const changeReadStatus = (rowdata) => {

        let data = {
            id: rowdata.id,
            addvalues: { is_viewed: rowdata.is_viewed === true ? false : true }
        }
        setUpdateLoad('update')
        dispatch(remainderUpdateById(data))

    }

    //custom pagination
    const handlePaginations = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getRemainderList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getRemainderList(JSON.stringify(newFilterData)))
    }

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "equals" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}, "created_by":{"value":${GetLocalstorage.userDetail().id} , "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getRemainderList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"filter":{"created_by":{"value":${GetLocalstorage.userDetail().id} , "operation":"eq"}}"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getRemainderList(`{"filter":{"created_by":{"value":${GetLocalstorage.userDetail().id} , "operation":"eq"}},"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(remainderList && remainderList.data && remainderList.data.rows && remainderList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePaginations}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getRemainderList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
const filterOperatorsForSelect = getGridSingleSelectOperators().filter(({ value }) =>
    ['is'].includes(value),
);
const filterOperatorsForDate = getGridDateOperators().filter(({ value }) =>
    ['is'].includes(value),
);

  
  const RemainderColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    // {
    //   field: "story", headerName: "RTMS key", width: 230,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus`}>
    //         {params.row.story && params.row.story.ticket_key}
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "project", headerName: "Project", width: 230,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus`}>
    //         {params.row.project && params.row.project.project_name}
    //       </div>
    //     );
    //   }
    // },
    {
      field: "notification_date", headerName: "Date And Time", width: 230, type: "date",filterOperators:filterOperatorsForDate,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {moment(params.row.notification_date + ' ' + params.row.notification_time).format('DD-MM-YYYY HH:mm A')}
          </div>
        );
      }
    },
    {
      field: "comment", headerName: "Comment", width: 230,filterOperators,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.comment}>
            <div className={`cellWithStatus ellips-css-data`}>
              {params.row.comment}
            </div>
          </Tooltip>
        );
      }
    },
    // {
    //   field: "resource_id", headerName: "Resource", width: 230,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus`}>
    //         {params.row.resource && params.row.resource.display_name}
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "is_viewed",
    //   headerName: "Mark Read",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.is_viewed}`}>
    //         {params.row.is_viewed === true ? 'Read' : 'Unread'}
    //       </div>
    //     );
    //   },
    // }
  ];

    const actionColumn = [
        {
            field: "story_id", headerName: "RTMS key", width: 230, type: 'singleSelect', valueOptions: filterIssueTypeList,filterOperators:filterOperatorsForSelect,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params?.row.story && params.row.story.ticket_key}
                    </div>
                );
            }
        },
        {
            field: "project_id", headerName: "Project", width: 230, type: 'singleSelect', valueOptions: filterProjectList,filterOperators:filterOperatorsForSelect,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params?.row.project && params.row.project.project_name}
                    </div>
                );
            }
        },
        {
            field: "resource_id", headerName: "Resource", type: 'singleSelect', width: 200, valueOptions: filterResourceList,filterOperators:filterOperatorsForSelect,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params?.row.resource && params.row.resource.display_name}
                    </div>
                );
            }
        },
        {
            field: "is_viewed",
            headerName: "Mark Read",
            width: 160,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.is_viewed === false ? 'mark as read' : 'mark as unread'}>
                        <div onClick={() => changeReadStatus(params.row)} style={{ cursor: 'pointer' }} className={`cellWithStatus ${params.row.is_viewed}`}>
                            {params.row.is_viewed === true ? 'Read' : 'Unread'}
                        </div>
                    </Tooltip>
                );
            },
        }
    ];

    if (updateLoad === 'update' && remainderUpdate.status) {
        if (remainderUpdate.status === 200) {
            toast.success(remainderUpdate.message)
            dispatch(getRemainderList(filterQuery))
            dispatch(remainderUpdateById("type"))
            setReloading(true)
        }
        if (remainderUpdate.status !== 200) {
            toast.error(remainderUpdate.message)
        }
        setUpdateLoad(false)
    }
    if (reloading === true && remainderList) {
        if (remainderList.status !== 200) {
            toast.error(remainderList.message)
        }
        setReloading(false)

    }

    return (
        <div className="notification">
            <div className="newContainer">
                <div className="listContainer">
                    {reloading === true && <LoaderForm />}
                    <ToastContainer />
                    <div className="listTitle">Latest Notifications</div>
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={remainderList?.data && remainderList.data.rows ? remainderList.data.rows : []}
                        columns={RemainderColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>

        </div>
    )
}

export default Notification
