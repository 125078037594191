import "./report.scss"
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Button, Card, CardContent, FormControl, Grid, List, ListItem, Stack, TablePagination, Tooltip } from "@mui/material"
import { ArrowBack, Download } from "@mui/icons-material"
import { toast, ToastContainer } from "react-toastify"
import { DataGrid } from "@mui/x-data-grid"
import { useDispatch, useSelector } from "react-redux"
import { getAgentCallReport, getAgentWiseResource } from "../../redux/features/agentCallPerformanceReportSlice"
import { STATUSES } from "../../redux/common-status/constant"
import LoaderForm from "../../components/common-function/LoaderForm"
import moment from "moment"
import TablePaginationActions from "../../components/TablePaginationActions"
import Select from "react-select";
import { DateRangePicker } from 'rsuite';
import ShareModule from "../../components/common-function/ShareModule"
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay"
import { TaskModal } from "../task/TaskModal"
import { RefreshButton } from "../../components/common-function/RefreshButton"
import HourlyCallStatus from "./HourlyCallStatus"
import GetLocalstorage from "../../components/common-function/GetLocalstorage"
import MoreFilters from "../../components/moreFilters/MoreFIlters"


export default function AgentCallPerformance() {
    const { REACT_APP_API_BASEURL } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { agentCallReportList, status, error, agentResourceList } = useSelector(state => state.agentCallPerformanceReport)
    const { data: list } = agentCallReportList
    let current_date_filter = { "value": [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], "operation": "range" }
    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [filterQuery, setFilterQuery] = useState({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": 25 })
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [loading, setLoading] = useState(true)
    const [downliadFile, setDownloadFile] = useState(false)
    const [selectAgent, setSelectAgent] = useState("")
    const [date, setDate] = useState([new Date(), new Date()])

    //form
    const [openSingle, setOpenSingle] = useState(false)
    const [callDetailList, setCallDetailList] = useState([])
    const [openModal, setOpenModal] = useState(false)

    const [showHourly, setShowHourly] = useState(false)
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    useEffect(() => {
        dispatch(getAgentWiseResource(`{"order_by":{"column":"-id"}, "no_limit":True, "columns":["id", "display_name", "email"]}`))

    }, [])


    useEffect(() => {
        dispatch(getAgentCallReport(filterQuery))
    }, [filterQuery])

    if (loading === true && agentCallReportList) {
        if (agentCallReportList.status === 200) {
        } else {
            toast.error(agentCallReportList.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
            setDownloadFile(false)
        }
    }, [error])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = filterQuery
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery({ ...filterQuery, ...newFilterData })
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery({ ...filterQuery, ...newFilterData })
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel, r) => {

        if (filterModel.items.length) {
            setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
        }

        if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
            setReload(true);
            setTimeout(() => {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": limit })
                dispatch(getAgentCallReport({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": limit }))
            }, 0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue;
            const filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue } }, "skip": 0, "limit": limit }
            setFilterQuery(filterData)
            setCurrentPage(0)
        }
    }

    const handleSort = (sort) => {
        let newFilterData = filterQuery
        let sortColumnName = newFilterData?.order_by && newFilterData.order_by.column ? newFilterData.order_by.column : ""
        let filterSortColumn = sortColumnName ? sortColumnName.match('-') ? sortColumnName.replace('-', '') : "-" + sortColumnName : ""
        if (sort.length) {
            if (sort[0].field === sortColumnName || sort[0].field === "-" + sortColumnName) {
                newFilterData['order_by'] = {
                    "column": filterSortColumn ? filterSortColumn : sort[0].sort === "desc" ?
                        '-' + sort[0].field : sort[0].field
                }
            } else {
                newFilterData['order_by'] = {
                    "column": sort[0].sort === "desc" ?
                        '-' + sort[0].field : sort[0].field
                }
            }

            setFilterQuery(newFilterData)

        } else {
            newFilterData['order_by'] = {
                "column": filterSortColumn
            }
            setFilterQuery(newFilterData)
        }
        dispatch(getAgentCallReport(newFilterData))
    }
    const updateDateTime = (evt) => {
        setDate(evt)
    }

    const openDrawer = (json, type) => {
        let filter = { "filter": { "call_date": { "value": json.date, "operation": "eq" }, "resource_id": { "value": json.resource_id, "operation": "eq" } }, "skip": 0, "limit": limit }
        if (type) {
            filter['filter']['direction'] = { "value": type, "operation": "eq" }
        }
        navigate('/all-call-details', { state: { filter } })
    }

    const handleClickPopover = async (json) => {
        let totalInBound = [];

        json?.job_details && json.job_details.forEach((e, i) => {
            totalInBound.push({
                id: i,
                outbound: e.total_durations_out_hrs,
                inbound: e.total_durations_in_hrs,
                call_date: json.date,
                call_duration: e.total_calls,
                title: e.summary
            })
        })
        setCallDetailList(totalInBound)
        setOpenModal(true);
    }

    const handleClose = () => {
        setCallDetailList([])
        setOpenModal(false);
    }



    const search = () => {
        let array = []
        selectAgent && selectAgent.length > 0 && selectAgent.map((o) => array.push(o.value));
        let filterCondition;
        let newFilterData = filterQuery
        let date_filter = date && { "value": [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')], "operation": "range" }

        if (date && date.length > 0 && selectAgent && selectAgent.length > 0) {
            filterCondition = {
                "filter": {
                    "call_date": date_filter,
                    "resource_id": { "value": array, "operation": "in" }
                }
            }
            newFilterData['filter'] = {
                "call_date": date_filter,
                "resource_id": { "value": array, "operation": "in" }
            }
        } else if (date && date.length > 0) {
            filterCondition = {
                "filter": {
                    "call_date": date_filter,
                }
            }
            newFilterData['filter'] = {
                "call_date": date_filter,
            }
        } else if (selectAgent && selectAgent.length > 0) {
            filterCondition = { "filter": { "resource_id": { "value": array, "operation": "in" } } }
            newFilterData['filter'] = { "resource_id": { "value": array, "operation": "in" } }
        } else {
            filterCondition = { "filter": { "call_date": current_date_filter }, "skip": 0, "limit": limit }
            newFilterData['filter'] = { "call_date": current_date_filter }
        }
        newFilterData['skip'] = 0
        newFilterData['limit'] = limit
        filterCondition['skip'] = 0
        filterCondition['limit'] = limit
        setLoading(true)
        setFilterQuery(newFilterData)
        dispatch(getAgentCallReport(newFilterData))

    }

    const clear = () => {
        setSelectAgent("")
        setDate([new Date(), new Date()])
        setLoading(true)
        setFilterQuery({ "filter": { "call_date": current_date_filter }, "skip": 0, "limit": limit })
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
        delete filterQuery['for_download']
        delete filterQuery['no_limit']
        let newFilterData = filterQuery
        setFilterQuery(newFilterData)
        dispatch(getAgentCallReport(newFilterData))
    }

    if (downliadFile === true && agentCallReportList && agentCallReportList.data) {
        let api_url = agentCallReportList?.data && agentCallReportList.data.download_api_url;
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const downloadCsvFile = () => {
        setDownloadFile(true)
        let newFilter = filterQuery
        delete newFilter['skip']
        delete newFilter['limit']
        newFilter['for_download'] = "True"
        newFilter['no_limit'] = "True"
        dispatch(getAgentCallReport(newFilter))
    }

    const agentCallReportColumns = [
        { field: 'id', headerName: 'ID', width: 10, filterable: false },
        {
            field: 'resource_name', headerName: 'Agent Name', width: 190, filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                        {params.row.resource_name}
                    </div>
                );
            },
        },
        {
            field: "date", headerName: "Call Date", type: 'date', width: 160, filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                        {moment(params.row.date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: 'total_durations_in_and_out_hrs',
            headerName: 'Total Call Duration',
            width: 260,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { openDrawer(params.row) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_in_and_out_percent) + " % " +
                            ("(" + params.row.total_durations_in_and_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'total_durations_out_hrs',
            headerName: 'Total Out Bound Call Duration',
            width: 262,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { openDrawer(params.row, 1) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_out_percent) + " % " +
                            ("(" + params.row.total_durations_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'total_durations_in_hrs',
            headerName: 'Total In Bound Call Duration',
            width: 262,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { openDrawer(params.row, 2) }} className="cellWithStatus hover-call">
                        {
                            (params.row.total_durations_in_percent) + " % " +
                            ("(" + params.row.total_durations_in_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'remaining_total_effective_call_durations_in_and_out_hrs',
            headerName: 'Total Remaining Call Duration',
            width: 260,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {
                            (params.row.remain_in_percent) + " % " +
                            ("(" + params.row.remaining_total_effective_call_durations_in_and_out_hrs + ")")
                        }
                    </div>
                );
            },
        },
        {
            field: 'total_jobs', headerName: 'Jobs Count', width: 150, filterable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => handleClickPopover(params.row)} className="cellWithStatus hover-call">
                        {params.row.total_jobs}
                    </div>
                );
            },
        },
        {
            field: 'total_interview', headerName: 'Total Interview Scheduled', width: 150, filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row?.total_interview}
                    </div>
                );
            },
        },

    ]

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
    }

    let i = 0

    const FilterComponents = () => {
        return (
            <List>
                <ListItem>
                    <FormControl fullWidth>
                        <DateRangePicker
                            format="dd-MM-yyyy"
                            placeholder="yyyy-mm-dd ~ yyyy-mm-dd"
                            showMeridian
                            value={date}
                            onChange={(evt) => updateDateTime(evt)}
                        />
                    </FormControl>
                </ListItem>

                <ListItem>
                    <FormControl fullWidth>
                        <Select
                            placeholder="Select Agents"
                            isMulti
                            isClearable
                            value={selectAgent}
                            onChange={(evt) => { setSelectAgent(evt) }}
                            options={agentResourceList && agentResourceList.data && agentResourceList.data.rows &&
                                agentResourceList.data.rows.length > 0
                                ? agentResourceList.data.rows.map(e => { return { value: e.id, label: e.display_name + "(" + e.email + ")" } })
                                : []
                            }
                        />
                    </FormControl>
                </ListItem>
            </List>
        )
    }

    // advance search - searchHandler
    const handleFilterSearch = (drawerOpen) => {
        search();
        drawerOpen(false);
    }

    const handleFilterReset = () => {
        clear();
    }

    return (
        <div className="report">
            <div className="reportContainer">
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Agent Call Performance Report</h3>

                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>

                            {/* advance search filter component start*/}
                            <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={(status === `${STATUSES.SUCCESS}_agentCallReportList`) ? false : true} inlineStyle={{ width: '60px' }} />
                            {/* advance search filter component end*/}

                            <RefreshButton api={getAgentCallReport} filter={filterQuery} />
                            <Tooltip title="Back">
                                <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                            </Tooltip>
                        </div>
                    </div>

                    <ToastContainer />
                    {(loading === true || status === `${STATUSES.LOADING}_agentCallReportList`) && <LoaderForm />}
                    <Card>
                        <CardContent>
                        <Grid
                            container
                            rowSpacing={1}
                        >
                            <Grid item md={7}>
                                <CustomPagination />
                            </Grid>
                            <Grid item md={5}>
                            <Stack
                                style={{
                                justifyContent: "flex-end",
                                marginTop: "6px",
                                display:'flex',
                                }}
                                direction={"row"}
                            >
                                    <Button
                                        disabled={status === `${STATUSES.LOADING}_agentCallReportList`}
                                        className="filter-btn-primary"
                                        onClick={() => setOpenSingle(true)}
                                        style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { backgroundColor: "#0052cc" }}>
                                        Share
                                    </Button>
                                    <Button
                                        startIcon={<Download/>}
                                        disabled={status === `${STATUSES.LOADING}_agentCallReportList` ? true : false}
                                        className="filter-btn-primary"
                                        onClick={() => downloadCsvFile()}
                                        style={status === `${STATUSES.LOADING}_agentCallReportList` ? { backgroundColor: 'grey' } : { background:'#0052cc' }}>
                                        Download as CSV
                                    </Button>
                            </Stack>
                            </Grid>
                            </Grid>
                            {list?.last_synced && <p className="hourly-status">
                                <button
                                    onClick={() => setShowHourly(true)}
                                    className="btn">Hourly Call Status
                                </button>
                                {
                                    showHourly && <HourlyCallStatus setShowHourly={setShowHourly} />
                                }
                                <span>Last Synced Date : {list.last_synced.last_updated_date} {list.last_synced.last_updated_time}</span>
                            </p>}
                            {
                                !reload &&
                                <DataGrid
                                    className="datagrid"
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                    rows={list?.rows ? list.rows : []}
                                    columns={agentCallReportColumns}
                                    pageSize={pageSize}
                                    style={{ fontSize: 16 }}
                                    getRowId={(row) => row + i++}
                                    onFilterModelChange={onFilterChange}
                                    components={{
                                        Pagination: CustomPagination,
                                        NoRowsOverlay: CustomNoRowsOverlay

                                    }}
                                    onSortModelChange={(sort) => handleSort(sort)}
                                    hideFooterPagination={true}
                                />
                            }
                        </CardContent>
                    </Card>
                </div>

                <ShareModule
                    open={openSingle}
                    close={onCloseModal}
                    filter={filterQuery}
                    apiServiceName={getAgentCallReport}
                    dowunloadResponse={agentCallReportList}
                    moduleName="agentCallPerformance"
                    subject="Agent Call Performance Report"
                />

                <TaskModal
                    openModal={openModal}
                    setOpenModal={handleClose}
                    title={"View Details"}
                    formtype="data-grid"
                    list={callDetailList}
                    columns={[
                        { field: 'title', headerName: 'Title', width: 230, filterable: false },
                        { field: 'call_date', headerName: 'Call Date', width: 150, filterable: false },
                        { field: 'call_duration', headerName: 'Total Calls', width: 200, filterable: false },
                        { field: 'outbound', headerName: 'Out Bound', width: 150, filterable: false },
                        { field: 'inbound', headerName: 'In Bound', width: 150, filterable: false }
                    ]}
                />
            </div>
        </div>

    )
}
