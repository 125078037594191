import React, { useEffect, useState } from "react";
import "./clientresource.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../../redux/common-status/constant";
import Select from 'react-select';
import dataService from "../../../redux/services/data.service";
import { ClientResourceUpdate, ClientResourceCreate, ClientResourceSkills, ClientResourceList, tmsRoleClientList } from "../../../redux/features/clientResourceSlice";
import { InputFields, InputRadioGroupFields } from "../../../components/common-function/InputFields";
import LoaderForm from "../../../components/common-function/LoaderForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Add from "@mui/icons-material/Add";

const initialFormData = Object.freeze({
    name: "",
    email: "",
    contact_no: "",
    client: "",
    tms_role: "",
    client_skill_details_ids: "",
    status: "",
});

const formErrData = Object.freeze({
    nameErr: 'Name is required',
    emailErr: "Email is required",
    Contact_noErr: "Contact number is required",
    tms_roleErr: "Tms role is required",
    clientErr: "Client is required",
    clientSkillErr: "Client skill is required",
    statusErr: "Status is required"

});


function AddClientResource() {

    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const { id } = useParams()
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormData)
    const [formErrors] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const [reload, setReload] = useState(false)
    const { status, error, clientresource_Create, clientlistresource_List, clientresourceskills_List, roleList, clientresource_Update, } = useSelector(state => state.clientResourceSlice)
    const [, setOpen] = useState(false);
    const [ID, setID] = useState("");
    const [formErr] = useState(formErrData);
    const [validateErr, setvalidateErr] = useState("");

    useEffect(() => {
        if (id) {
            loadDataById()
        }
        dispatch(ClientResourceSkills())
        dispatch(ClientResourceList())
        dispatch(tmsRoleClientList())
        // eslint-disable-next-line
    }, [dispatch])

    const loadDataById = async () => {
        setReload(true)
        const res = await dataService.client_resource_edit(id)

        if (res && res.data && res.data.data) {

            const editData = res.data.data
            setID(editData.id)
            setFormData({
                name: editData.name,
                email: editData.email,
                contact_no: editData.contact_no,
                tms_role: { label: editData.tms_role && editData.tms_role.role_name, value: editData && editData.tms_role_id },
                client: { label: editData.client && editData.client.name, value: editData && editData.client.id },
                // client: editData && editData.client,
                // client_skill_details_ids: { label: editData.client_skill_details_ids && editData.client_skill_details_ids.skill && editData.client_skill_details_ids.skill.skill_name, value:  editData.client_skill_details_ids.id },
                client_skill_details_ids: selectValueInLable(editData.client_skill_details_ids),
                status: editData && editData.status
            })
            setReload(false)

        }

    }
    const selectValueInLable = (id) => {
        let data = clientresourceskills_List.data && clientresourceskills_List.data.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.skill_name })

        })
        return newObject
    }

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    const handleOpen = () => navigate("/client-resource-list");

    const handleClose = () => {
        if (ID) { setID(0); setFormData(initialFormData) } setOpen(false); setFormData(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormData({ ...formData, [name]: value });
    }
    const handleChangeClientSkills = (evt, id) => {
        // const { name, value } = evt.target || evt;
        // setFormData({ ...formData, [name]: evt.id });
        if (evt.length > 0) {
            setFormData({ ...formData, "client_skill_details_ids": evt });
        } else {
            setFormData({ ...formData, "client_skill_details_ids": "" });
        }
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_clientresource_Update` && clientresource_Update && clientresource_Update.message) {
        if (clientresource_Update.status === 200) {
            toast.success(clientresource_Update.message)
            setTimeout(() => {
                navigate('/client-resource-list')
            }, 1000)
        } else {
            toast.error(clientresource_Update.message)
        }
        setReload(false)
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_clientresource_Create` && clientresource_Create && clientresource_Create.message) {
        if (clientresource_Create.status === 200) {
            toast.success(clientresource_Create.message)
            setTimeout(() => {
                navigate('/client-resource-list')
            }, 1000)
        } else {
            toast.error(clientresource_Create.message)
        }
        setReload(false)
    }
    const validate = (contact) => {
        const errors = {}
        let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";

        }
        return errors

    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (formData.name === "" || formData.status === "") {
            setErrStatus(true)
        }
        let skillArray = []
        if (formData.client_skill_details_ids && formData.client_skill_details_ids.length > 0) {
            formData.client_skill_details_ids.map(e => e.value ? skillArray.push(e.value) : skillArray.push(e))
        }


        if (formData.name !== "" && formData.status !== "") {
            const isValidate = validate(formData.contact_no)

            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id

            if (isValidate.contact !== undefined) {

                setErrStatus(true)
                setvalidateErr(isValidate)
                return

            }
    
            let addvalues = {
                ...formData, created_by: userId, assigned_by: userId,
                client: formData.client,
                client_skill_details_ids: skillArray
            }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(ClientResourceUpdate({ ID, addvalues }))
            } else {
                dispatch(ClientResourceCreate(addvalues))
            }

        }
    }
    return (
        <div className="client-resource">
            <div className="client-resource-container">
                <div className="top-headings">
                    <h3> Add Client Resource </h3>
                    <Tooltip title="Back">
                    <button onClick={handleOpen} className="btn-add-new ">
                    <ArrowBackIcon />
                        </button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {reload === true ? <LoaderForm />
                    :
                    <div className="form-client">
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Client
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Client "
                                        defaultValue={formData.client}
                                        isSearchable={true}
                                        name={"client"}
                                        options={
                                            clientlistresource_List && clientlistresource_List.data && clientlistresource_List.data.rows.filter(data=>data.is_internal===false).map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "client"
                                                }
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formData && formData.client === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.clientErr}</p>}
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Name"}
                                        name="name"
                                        defaultValue={formData.name}
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formValues={formData}
                                        formErr={formErrors.nameErr}
                                    />
                                </div>

                                <div className="formInput">
                                    <InputFields
                                        label={'Email'}
                                        name="email"
                                        defaultValue={formData.email}
                                        type="email"
                                        placeholder="Email"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formValues={formData}
                                        formErr={formErrors.emailErr}
                                    />
                                </div>

                                <div className="formInput">
                                    <label> Contact No</label>
                                    <input
                                        name="contact_no"
                                        defaultValue={formData.contact_no}
                                        type="text"
                                        placeholder="Contact No"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formData && formData.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.Contact_noErr}</p>}
                                    {errStatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                                </div>

                                <div className="formInput">
                                    <label> TMS Role</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select  TMS Role"
                                        defaultValue={formData.tms_role}
                                        isSearchable={true}
                                        name={"tms_role"}
                                        options={
                                            roleList && roleList.data && roleList.data.rows.map((option) => {

                                                return { label: option.role_name, value: option.id, name: "tms_role" }
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formData && formData.tms_role === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.tms_roleErr}</p>}
                                </div>


                                <div className="formInput">
                                    <label >
                                        Client skill details
                                    </label>
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="client skill detail"
                                        defaultValue={formData.client_skill_details_ids}
                                        isSearchable={true}
                                        name={"client_skill_details_ids"}
                                        options={
                                            clientresourceskills_List && clientresourceskills_List.data && clientresourceskills_List.data.rows.filter(ele=>ele.skill).map((option) => {
                                                return {
                                                    label: option.skill && option.skill.skill_name,
                                                    value: option.id,
                                                    name: "client_skill_details_ids"
                                                }
                                            })
                                        }
                                        onChange={handleChangeClientSkills}
                                    />
                                    {errStatus === true && formData && formData.client_skill_details_ids === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.clientSkillErr}</p>}
                                </div>
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formData.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />

                            </div>

                        </form>
                        <button className="btn btn-submit" onClick={onSubmit}>Submit</button>
                    </div>
                }
            </div >
        </div >
    )
}
export default AddClientResource