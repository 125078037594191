import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux"
import AutorenewIcon from '@mui/icons-material/Autorenew';

export const RefreshButton = ({ api, filter, style, setReload, clearGridListing,setSort}) => {
    const dispatch = useDispatch()
    const refresh = () => {
        setReload && setReload(true);
        setSort && setSort(false)
        clearGridListing && (clearGridListing.current = true);
        dispatch(api(filter))
    }
  
    return (
        <IconButton onClick={() => refresh()} color="primary" aria-label="Reload Refresh">
            <Tooltip title="Refresh">
                <AutorenewIcon />
            </Tooltip>
        </IconButton>
        // <button onClick={() => refresh()} style={style || { marginTop: 12 }} className="btn-add-new ">
        //     <Tooltip title="Reload">

        //     </Tooltip>
        // </button>
    )
}