import "./manageProject.scss";
import api from '../../redux/APIS/api';
import dataService from '../../redux/services/data.service';
import moment from 'moment';
import Filter from '../../components/Filter';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import AddProject from './AddProject';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import ViewProject from './ViewProject';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { toast, ToastContainer } from "react-toastify";
import { useState, useEffect, useMemo, useRef } from 'react'
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Skeleton, Stack, Switch, Tooltip } from "@mui/material";
import { ExpandMore, ExpandLess, Close } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { useDispatch, useSelector } from 'react-redux';
import { defaultallprojectColumn, projectByIdWithResources } from '../../redux/features/projectSlice';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { Edit } from "@mui/icons-material";

const default_All_Project_Columns = LocalStorageDataService.get_default_allproject_column()

const skeletonBase = { project_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', project_key: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', is_jira_project_text: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', projectsResources: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', created_on: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status_value: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

// const filterableColumns = ['project_name', 'project_key', 'is_jira_project', 'resource', "created_on", "status"];

let projectFilterKey = ['project_name', 'project_key', 'is_jira_project', 'status'];
let projectResourceFilterKey = ['resource', 'created_on'];

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const initialFormData = Object.freeze({
  setting_display_name: "",
  setting_key: "",
  value: "",
  status: true,
  is_json_value: false,
});

const formErrData = Object.freeze({
  coreSettingsErr: "setting name is required",
  statusErr: "Status is required",
  valueErr: "Value is required"
});

const AllProjects = () => {

  const dispatch = useDispatch();
  const [_, setOpen] = useState(false);
  const [errStatus, setErrStatus] = useState(false);
  const [limit, setLimit] = useState(25)
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [optionsObj, setOptionsObj] = useState({})
  const [expandedID, setExpandedID] = useState()
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');
  
  // custom filter
  const [ID, setID] = useState("");
  const [formValues, setFormValues] = useState(initialFormData)
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [resourcesLists, setResourcesLists] = useState([])

  let filterQuery = useRef({});
  let isFilterActive = useRef(false);
  let filterHasVal = useRef(false);
  let activeMainArr = useRef(projectFilterKey);

  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState({active:false, data:null});
  const [viewForm, setViewForm] = useState({active:false, id:null});


  // grid states and ref starts
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let isAnyChange = useRef({});
  // grid states and ref ends


  const { defaultAllProjectColumns } = useSelector(state => state.projects)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_All_Project_Columns && default_All_Project_Columns.all_project_column ? default_All_Project_Columns.all_project_column : {
    id: false,
    created_on: false,

  });

  const handleOpen = () => { setShowProjectForm(true) };

  // View Project
  const viewProject = async (data) => {
      
    // navigate('/view-project', { state: { id: data.id } })
    setViewForm({active:true, id:data.id});
  }

  const setKeyForList = (obj) => {
    let data;
    if (default_All_Project_Columns) {
      data = { 'all_project_column': obj, 'reported_projectlist_column': default_All_Project_Columns.reported_projectlist_column, 'all_projectlist_column': default_All_Project_Columns.all_projectlist_column, 'task_column': default_All_Project_Columns.task_column }
    } else {
      data = { 'all_project_column': obj }
    }
    if (defaultAllProjectColumns.reported_projectlist_column) {
      data['reported_projectlist_column'] = defaultAllProjectColumns.reported_projectlist_column
    }
    if (defaultAllProjectColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultAllProjectColumns.all_projectlist_column
    }
    if (defaultAllProjectColumns.task_column) {
      data['task_column'] = defaultAllProjectColumns.task_column
    }
    dispatch(defaultallprojectColumn(data))
  }

  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
    let newFilterData = {};
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if(foundElement)
    {
        newFilterData['order_by'] = {
            "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
        }
        copySorter.map(item=>{
            if(item.id===foundElement.id)
                item.order = item.order === "asc" ? "desc" : "asc"
        });
        setColSortingPerform(()=>copySorter)
    }
    else{
      
        newFilterData['order_by'] = {
            "column": `${sortingCol}`
        }
        setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
    }

    setGridRows([]);
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    loadProjects(newFilterData, false, true)
    clearGridListing.current = true;        
  }


  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
        setChangeStatus("InActive")
    } else {
        setChangeStatus("Active")
    }
}

  const projectsCols = [
    { field: "id", headerName: "ID", width: 70, filterable: false, sortable:false, pinnable:false },
    {
      field: "project_name",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Project</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !loading && skeletonRows.length ===0 && handleSort("project_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable:false,
      pinnable:false,
      renderCell: (params) => {
        return (
          params.row.project_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={200} /> :
            <div className="cellWithImg" onClick={()=>viewProject(params.row)} style={{textDecoration:'underline', color:'#1976d2', cursor:'pointer'}}>
              <Tooltip title={params.row.project_name}>
                {params.row.project_name}
              </Tooltip>
            </div>
        );
      },
    },
    {
      field: "project_key",
      headerName:  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Project Key</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !loading && skeletonRows.length ===0 && handleSort("project_key")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 160,
      sortable:false,
      pinnable:false,
      renderCell: (params) => {
        return (
          params.row.project_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160} /> :
            params.row.project_key
        );
      },
    },

    {
      field: "is_jira_project",
      headerName: "Is Jira Project",
      width: 140,
      // hideable: false,
      // filterable:false,
      sortable: false,
      pinnable:false,
      type: 'singleSelect',
      valueOptions: [{ label: "Yes", value: "True" }, { label: "No", value: "False" }],
      renderCell: (params) => {
        return (
          params.row.is_jira_project_text === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={140} /> :
            params.row.is_jira_project_text
        );
      },
    },
    {
      field: "resource",
      headerName: "Assignee",
      width: 340,
      hideable: true,
      filterable: true,
      sortable: false,
      pinnable:false,
      type: 'singleSelect',
      valueOptions: optionsObj.projectsResourcesNames,
      renderCell: (params) => {
        return (
          params.row.projectsResources === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={340} /> :
            <div style={{ width: '100%' }} >
              <div className="cellWithImg" style={{ width: '90%', display: 'inline-block' }}>
                {params.row.projectsResourcesNames && params.row.projectsResourcesNames.length ?
                  params.row.projectsResourcesNames.map((resource, index) => {
                    return (
                      <p key={index} style={{ margin: 0 }}>
                        {index === 0 && resource} {index === 0 && params.row.projectsResourcesNames.length > 1 && ","}
                        {params.row.projectsResourcesNames.length > 1 && index === 1 && resource} {index === 1 && params.row.projectsResourcesNames.length > 2 && ","}
                        {expandedID === params.id && index > 1 ? `${resource} ${index === params.row.projectsResourcesNames.length - 1 ? '' : ','}` : null}
                      </p>
                    )
                  })
                  : "N/A"
                }
              </div>
              <div style={{ width: '10%', display: 'inline-block' }}>
                {(params.row.projectsResourcesNames && params.row.projectsResourcesNames.length > 2) ?
                  expandedID === params.id ?
                    <Tooltip title="View less">
                      <ExpandLess onClick={() => handleExpansion(params.id)} />
                    </Tooltip> :
                    <Tooltip title="View more">
                      <ExpandMore onClick={() => handleExpansion(params.id)} />
                    </Tooltip>
                  : ""}
              </div>
            </div>
        );
      },
    },

    {
      field: "created_on",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Created On</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !loading && handleSort("created_on")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      type: "date",
      width: 120,
      sortable:false,
      pinnable:false,
      renderCell: (params) => {
        return (
          params.row.created_on === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={120} /> :
            <div className="cellWithImg">
              {params.row.created_on ? moment(params.row.created_on).format('DD-MM-YYYY') : "N/A"}
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      sortable:false,
      pinnable:false,
      renderCell: (params) => {
        return (
          params.row.status_value === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={120} /> :
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
            <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)}/>
            <div className="actionButton">
                   <UcActionComponent
                    moduleName="dynamic_table"
                    rowid={params.row.id}
                    addMoreData={params.row}
                    editLinkUrl=""
                    viewLinkUrl=""
                    editButton={editProject}
                    // viewButton={viewProject}
                    // deleteButton={() => handleDelete(params.row)}
                    deleteIconName="ArchiveIcon"
                  />
            </div>
          </div>
            
              // {params.row.status === 3 ? "Archive" : params.row.status === true ? "Active" : "Inactive"}
        );
      },
    },
  ];

  // Load All Projects
  const loadProjects = async (filterData={}, specialFilter=false) => {
    const resourcesList = resourcesLists.length > 0 ? resourcesLists : await loadProjectResources();
    // const data={...params,  no_limit:"True"}
    // const data = specialFilter ? { ...params, filter: { ...params.filter, "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" }, skip: skip, "limit": pageSize || 25 } : { ...params, filter: { ...params.filter }, "order_by": { "column": "-id" }, "skip": skip, "limit": pageSize || 25 }

    // filterData = specialFilter ? { ...filterData, filter: { ...params.filter, "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "-id" }, skip: skip, "limit": pageSize || 25 } : { ...filterData, filter: { ...filterData.filter }, "order_by": { "column": "-id" }, "skip": skip, "limit": pageSize || 25 };
    if (resourcesList) {
      const apiEndPoint = specialFilter ? "/resources/resource-project" : "projects/main"
      try {
        const res = await api.get(`${apiEndPoint}?where=${ Object.keys(filterData).length ===0 ? JSON.stringify({order_by:{column: "-id"}, skip: skip, limit: limit}) : JSON.stringify({...filterData})}`)
        setLoading(false);
        if (res && res.data && res.data.data) {
          let resData = res.data.data.rows
          if (clearGridListing.current) {
            specialFilter ?
              setGridRows(resData.map((row) => (
                {
                  ...row,
                  id: row.project.id,
                  is_jira_project_text: row.project.is_jira_project ? "Yes" : "No",
                  status_text: row.project.status ? "Active" : "Inactive",
                  project_name: row.project.project_name,
                  project_key: row.project.project_key,
                  project_type_name: row.project.project_type_name,
                  is_jira_project: row.project.is_jira_project,
                  is_private: row.project.is_private,
                  status: row.project.status,
                  status_value: row.project.status ? "True" : "False",
                  // resource:row.resource.id,
                  description: row.project.description,
                  projectsResources: resourcesList.filter((element) => element.project__id === row.project.id),
                  projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.project__id && element.resource__name }).filter(e => e !== false),

                  resource: resourcesList.map((element) => { return element.project__id === row.project.id && element.resource__id }).filter(e => e !== false),
                }
              )).sort(item=>item.status ? -1 : 1))
              :
              setGridRows(resData.map((row) => (
                {
                  ...row,
                  is_jira_project_text: row.is_jira_project ? "Yes" : "No",
                  status_text: row.status ? "Active" : "Inactive",
                  status_value: row.status ? "True" : "False",
                  project_type_name: row.project_type.project_type_name,
                  resource: null,
                  projectsResources: resourcesList.filter((element) => element.project__id === row.id),
                  projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.id && element.resource__name }).filter(e => e !== false),

                }

              )).sort(item=>item.status ? -1 : 1))
            clearGridListing.current = false;
            setSkeletonRows([])
            SET_MAX_ROW_LENGTH(res.data.data.count);
            stopOnRowScrollEnd.current = false;
          }
          else {
            specialFilter ?
              setGridRows([...gridRows, ...resData.map((row) => (
                {
                  ...row,
                  id: row.project.id,
                  is_jira_project_text: row.project.is_jira_project ? "Yes" : "No",
                  status_text: row.project.status ? "Active" : "Inactive",
                  project_name: row.project.project_name,
                  project_key: row.project.project_key,
                  project_type_name: row.project.project_type_name,
                  is_jira_project: row.project.is_jira_project,
                  is_private: row.project.is_private,
                  status: row.project.status,
                  status_value: row.project.status ? "True" : "False",
                  // resource:row.resource.id,
                  description: row.project.description,
                  projectsResources: resourcesList.filter((element) => element.project__id === row.project.id),
                  projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.project__id && element.resource__name }).filter(e => e !== false),

                  resource: resourcesList.map((element) => { return element.project__id === row.project.id && element.resource__id }).filter(e => e !== false),
                }
              ))].sort(item=>item.status ? -1 : 1))
              :
              setGridRows([...gridRows, ...resData.map((row) => (
                {
                  ...row,
                  is_jira_project_text: row.is_jira_project ? "Yes" : "No",
                  status_text: row.status ? "Active" : "Inactive",
                  status_value: row.status ? "True" : "False",
                  project_type_name: row.project_type.project_type_name,
                  resource: null,
                  projectsResources: resourcesList.filter((element) => element.project__id === row.id),
                  projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.id && element.resource__name }).filter(e => e !== false),

                }

              ))].sort(item=>item.status ? -1 : 1))
            SET_MAX_ROW_LENGTH(res.data.data.count);
            setSkeletonRows([])
            stopOnRowScrollEnd.current = false;
          }

          setRowCount(res.data.data.count ? res.data.data.count : 0)

        }
      }
      catch (err) {

      }
      stopOnRowScrollEnd.current = false;
    }
  }

  const handleExpansion = (e_id) => {

    if (e_id === expandedID)
      return setExpandedID(undefined);
    setExpandedID(e_id);

  }

  // Sync Projects
  const syncProjects = async () => {
    const res = await api.get("/jira/get-projects/0/24")
    if (res && res.status === 200) {
      toast.success(res.data.message)
      clearGridListing.current=true;
      setLoading(true)
      loadProjects({...filterQuery.current, skip:0, limit:gridRows.length});
    };

  }

  //  Load Project Resources
  const loadProjectResources = async () => {
    //  Load all Project Resources
    const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True", "columns": DefaultFilterColumns.MY_PROJECT_LIST_COLUMNS };
    const res = await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      const resourceArray = res.data.data.rows
      setResourcesLists(resourceArray)
      return resourceArray
    }
    return [];

  }

  //  LoadResourceList
  const loadResourceList = async () => {
    const data = { filter: { "status": { "value": "True", "operation": "eq" } }, "order_by": { "column": "name" }, "skip": 0, "no_limit": "True", "columns": DefaultFilterColumns.MAIN_RESOURCE_DROUPDOWN_COLUMNS }
    const response = await dataService.resource_list(JSON.stringify(data))
    response && response.data && response.data.data && response.data.data.rows &&
      setOptionsObj({
        ...optionsObj, projectsResourcesNames: response.data.data.rows.map(e => { return { value: e.id, label: e.name + "(" + e.email + ")" } })
          || []
      });
  }

  // // // Edit Project
  const editProject = (data) => {
    setLoading(true)
    dispatch(projectByIdWithResources(data.id)).then((res) => {
      // navigate("/edit-project", { state: res.payload.data })
      setShowEditForm({active:true, data:res.payload.data})
      setLoading(false)
    })
  }
  

  useEffect(() => {
    if(isFilterActive.current){
      isFilterActive.current=false;
      return;
    }
    stopOnRowScrollEnd.current = true;
    setLoading(true)
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    
    loadProjects(filterQuery.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit])

  useEffect(() => {
    loadResourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRowHeight = (params) => {
    if (params.id === expandedID && params && params.model && Array.isArray(params.model.projectsResourcesNames))
      return 20 * params.model.projectsResourcesNames.length + 20;
    return 52; // Default row height
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "projectsResources" ?
        <Filter options={optionsObj.projectsResourcesNames} onChange={handleFilterChange} defaultValue={defaultValue} />
        : ""
    );
  }
  const columns = useMemo(() =>
    projectsCols.map((col) => {
      if (col.field === "projectsResources") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [projectsCols]
  );

  const handleClose = () => {
    if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    setErrStatus(false);
    setContextMenu(null);
    setShowProjectForm(false);
    setViewForm({active:false,id:null});
    setShowEditForm({active:false, data:null});
  }

  const fetchFurtherRows = (viewPortRowSize) => {
    // let updatedFilter = JSON.parse(filterQuery);
    // updatedFilter.skip = gridRows.length;
    // updatedFilter.limit = 25;
    // setFilterQuery(JSON.stringify(updatedFilter));
    if(filterHasVal.current){
      filterQuery.current["skip"] = 0;
      filterQuery.current["limit"] = 25;
      setSkip(0);
      setLimit(25);
    }
    else{
      filterQuery.current["skip"] = skip+25;
      filterQuery.current["limit"] = 25;
      setSkip(skip+25);
      setLimit(25);
    }
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }

    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  const handleFormSubmit = () =>
  {
    setShowProjectForm(false);
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
    contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleMenuAction = (actionType) =>
  {
      let row = gridRows.find(item=>item.id === selectedRow);
          
      switch(actionType)
      {

        case 'view':
            viewProject(row)
            break;
          
        case 'edit':
            editProject(row)
            break;

        default:
            return;
      }

      setContextMenu(null);

  }

  const onFilterChange = (props) =>
  {
      isFilterActive.current = true;

      if(props.items.length > activeMainArr.current.length)
          return;

      if(props.items.length === 0)
      {
          setFilterModel(props)
          setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
          setGridRows([]);
          filterHasVal.current = false;
          filterQuery.current = {order_by:{column:"-id"},skip:0, limit:25};
          loadProjects({order_by:{column:"-id"},skip:0, limit:25}, false);
          clearGridListing.current = true;
          return;
      }
      filterHasVal.current = false;
      if(filterModal?.items?.length < props?.items?.length){

          if(props.items.length > 1)
          {

              let selectedCols = filterModal.items.map(item=>item.columnField);
              let remainCols = activeMainArr.current.filter(item=>![...selectedCols].includes(item))
              let newProps ={...props};
              newProps.items[newProps.items.length-1].columnField = remainCols[0];
              let index = props.items.findIndex(item=>item.columnField === 'status')
              let index2 = props.items.findIndex(item=>item.columnField === 'is_jira_project') 
              let index3 = props.items.findIndex(item=>item.columnField === 'resource') 
              let index4 = props.items.findIndex(item=>item.columnField === 'created_on') 
              
              if(newProps.items.find(item=>item.columnField==='status')){
                if(!newProps.items[index].value)
                {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
              }
              if(newProps.items.find(item=>item.columnField==='is_jira_project')){
                if(!newProps.items[index2].value)
                {
                    newProps.items[index2].value = undefined;
                    newProps.items[index2].operatorValue = 'is';
                }
              }
              if(newProps.items.find(item=>item.columnField==='resource')){
                if(!newProps.items[index3].value)
                {
                    newProps.items[index3].value = undefined;
                    newProps.items[index3].operatorValue = 'is';
                }
              }
              if(newProps.items.find(item=>item.columnField==='created_on')){
                if(!newProps.items[index4].value)
                {
                    newProps.items[index4].value = undefined;
                    newProps.items[index4].operatorValue = 'is';
                }
              }

              setFilterModel(props)
          }
          else{
            if(projectFilterKey.includes(props.items[0].columnField))
              activeMainArr.current = projectFilterKey;
            else
              activeMainArr.current = projectResourceFilterKey;
            setFilterModel(props)
          }
          
          
          return;
      }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

          if(props.items.length === 1)
          {
              if(projectFilterKey.includes(props.items[0].columnField))
                activeMainArr.current = projectFilterKey;
              else
                activeMainArr.current = projectResourceFilterKey;

              setFilterModel(props)
          }

          if(!(filterModal?.items?.length > props?.items?.length)){

              if(props.items.length > 1)
              {
                let mainArr = projectFilterKey
                if(props.items.map(item=>item.columnField).includes(projectResourceFilterKey[0]) || props.items.map(item=>item.columnField).includes(projectResourceFilterKey[1])){
                  mainArr = projectResourceFilterKey;
                } 
                for(let i=0; i<props.items.length; i++)
                {
                  if(!mainArr.includes(props.items[i].columnField)){
                    toast.warn("Please choose seperately:-"+JSON.stringify(['Project', 'Project Key', 'Is Jira Project', 'status'])+', '+JSON.stringify(['Assignee', 'Created On']))
                    return;
                  }
                }
              }

              // if same col is selected then do not let it select - starts
              let selectedCols = props.items.map(item=>item.columnField);
              let prevLen = selectedCols.length;
            
              if((new Set(selectedCols)).size != prevLen){
                  toast.warn("Please select distinct column fields!")
                  return;
              }
              // if same col is selected then do not let it select - ends

              setFilterModel(props)

              let filterModalCols = [];
              let propsCols = [];
              filterModal.items.map(item=>filterModalCols.push(item.columnField));
              props.items.map(item=>propsCols.push(item.columnField));
              
              for(let i=0; i<filterModalCols.length; i++)
              {
                  if(filterModalCols[i] != propsCols[i])
                      return;
              }

          }

          setFilterModel(props)

          let filterItems = props.items;
          let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"} };
          let operators = {
          contains:'icontains',
          equals: 'ieq',
          is: 'eq',
          startsWith: 'istartsWith',
          endsWith: 'iendsWith'
          }
      
          if(filterItems?.length)
          {
              let valContainers = filterItems.filter(item=>item?.value)
              if(valContainers?.length)
              {
                  let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                  // if no changes on filter open starts
                  if(props.items.length < Object.keys(isAnyChange.current).length){
                    let lenDiff = Object.keys(isAnyChange.current).length - props.items.length;
                    let copyObj = {...isAnyChange.current};
                    for(let i=lenDiff; i>=0; i--)
                        delete copyObj[i];
                    isAnyChange.current = copyObj;
                  } 
                  props.items.map((elem, index) => {

                      if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                          isAnyChange.current[index] = true; 
                      else
                          isAnyChange.current[index] = false; 
                  })
                  // no change (! reverse by not operator)
                  if(Object.values(isAnyChange.current).every(item=>item===false) && (props.items.length === Object.keys(isAnyChange.current).length) && (props.items.length === filterModal.items.length))
                      return;
                  else{
                    // setGridRows([]);
                    filterQuery.current = {...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}};
                    clearGridListing.current = true;
                    if(filter.hasOwnProperty("resource")){
                      setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
                      loadProjects({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}, true); 
                    }else{
                      setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
                      loadProjects({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}, false);
                    }
                  }

              }
          }

          return;
      }
  }


  const handleClickArchive = async (data) => {
    setLoading(true)
    setArchiveOpen(false)
    let res = await api.get('/projects/main/' + data.id);

    let addvalues = {
        project_name: res.data.data.project_name,
        project_type: res.data.data.project_type_id,
        is_jira_project: res.data.data.is_jira_project,
        description: res.data.data.description,
        industry: res.data.data.industry_id,
        department: res.data.data.department_id,
        status: !res.data.data.status,
        updated_by: GetLocalstorage.userDetail().id
    }

    try
    {
      let updateResponse = await api.put('/projects/main/'+data.id, addvalues)

      if(updateResponse.data.status === 200)
      {
        clearGridListing.current = true;
        loadProjects({...filterQuery.current, skip:0, limit:gridRows.length}, false);
        toast.success(updateResponse.data.message);
      }
      else if(updateResponse.data.status === 400)
        toast.error(updateResponse.data.message);

      setLoading(false);
    }
    catch(err)
    {
      toast.error(err);
    }
    
}


  const handleRefresh = () =>
  {
    setLoading(true);
    clearGridListing.current = true;
    loadProjects({...filterQuery.current, skip:0, limit:gridRows.length});
  };

  return (
    <div className="jiraStatus">
      <div className="jiraStatusContainer">
        <div className="datatable">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
            <div style={{ fontSize: "20px" }}>
              <p>All Projects</p>
            </div>
            <div>
              <Stack direction="row">
                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                <IconButton color="primary" aria-label="Reload Refresh" onClick={handleRefresh}>
                  <Tooltip title="Refresh">
                    <AutorenewIcon />
                  </Tooltip>
                </IconButton>
                <IconButton color="primary" aria-label="Sync Project" onClick={syncProjects}>
                  <Tooltip title="Sync">
                    <SyncAltIcon />
                  </Tooltip>
                </IconButton>
              </Stack>
            </div>
          </div>
        <ToastContainer />

          <DataGridPro
            className="datagrid"
            filterMode="server"
            loading={skeletonRows.length == 0 && !!loading}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            rows={gridRows?.concat(skeletonRows)}
            columns={projectsCols}
            filterModel={filterModal}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
              }
            }}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowScrollEnd}
            getRowHeight={getRowHeight}
            hideFooter
          />
        </div>

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={showProjectForm || viewForm.active || showEditForm.active}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: "10px",
              maxHeight: 700
            }
          }}
        >
          <Button
            edge="start"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ ( viewForm.active && 'View Project Details' ) || (showEditForm.active && 'Edit Project') || (showProjectForm && 'Add new Project')  } </DialogTitle>

          <DialogContent>
            {
              showProjectForm &&
              <AddProject showProjectForm={showProjectForm} handleFormSubmit={handleFormSubmit} />
            }
            {
              viewForm.active &&
              <ViewProject id={viewForm.id}/>
            }
            {
              showEditForm.active &&
              <AddProject showProjectForm={true} editableData={showEditForm.data} handleFormSubmit={()=>setShowEditForm({active:false, data:null})} />
            }

          </DialogContent>
        </Dialog>
        
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} setting`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />
        
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
          contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
          root: {
              onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
              }
          }
          }}
      >   
          <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
      </Menu>

      </div>
    </div>
  )
}

export default AllProjects