import { useEffect, useState } from "react";
import "./report.scss"
import { DataGrid} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { MonthWiseAttendanceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import { monthlyAttandanceReport } from "../../redux/features/calendarSlice";
import { useDispatch, useSelector } from "react-redux";

import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { Button, Card, CardContent, FormControl, Grid, List, ListItem, Stack, TablePagination, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import LoaderForm from '../../components/common-function/LoaderForm';
import { ArrowBack, Download } from '@mui/icons-material';
import TablePaginationActions from '../../components/TablePaginationActions';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import MoreFilters from '../../components/moreFilters/MoreFIlters';
import { resourceListOption } from '../../redux/features/optionsSlice';
const { REACT_APP_API_BASEURL } = process.env;

function MonthWiseAttendanceReport() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [list, setList] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const { resource_list_options:data } = useSelector(state => state.dropdownOptions)
  const [resourceId, setResourceId] = useState('select resource');
  const [resourceLabel, setResourceLabel] = useState("");
  const [loader, setLoader] = useState(true)
  const [value, setValue] = useState();
  const [minToDate, setMinToDate] = useState([null])
  const [startMaxDate, setStartMaxDate] = useState([null])
  const [fromDate, setFromDate] = useState([null])
  const [toDate, setToDate] = useState([null])
  const [downliadFile, setDownloadFile] = useState(false)
  const { status, error, monthlyAttandanceReportList } = useSelector(state => state.calender)
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const [filterQuery, setFilterQuery] = useState(`{,"order_by":{"column":"-id"}, "skip":0,"limit":25}`)
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

   //error handling
   useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setLoader(false)
    }
  }, [error])

  if (loader === true && status === `${STATUSES.SUCCESS}_monthlyAttandanceReportList` && monthlyAttandanceReportList && monthlyAttandanceReportList.data) {
    setLoader(false)
    let reportRows = monthlyAttandanceReportList.data.rows
    let reportCount = monthlyAttandanceReportList.data.count
    let reportList = []

    reportRows && reportRows.length > 0 && reportRows.map(e => {
      return reportList.push(e)
    })

    setRowCount(reportCount)
    setList(reportList)
  }

  useEffect(() => {
    daysInMonth(moment().format('MM'), moment().format('YYYY'))
    let monthDays = new Date(moment().format('YYYY'), moment().format('MM'), 0).getDate()
    let startMonth = `${moment().format('YYYY')}-${moment().format('MM')}-1`
    let endMonth = `${moment().format('YYYY')}-${moment().format('MM')}-${monthDays}`

    let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    setFilterQuery(filter)
    dispatch(monthlyAttandanceReport(filter))

    dispatch(resourceListOption())
    if (status === STATUSES.FAILURE) {
      setLoader(false)
      errorHandling(error, navigate)
    }
  }, [])

  const setYearAndMonth = (newValue) => {
    const d = new Date(newValue);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    daysInMonth(month, year);
    setValue(newValue)
  }

  const handleChange = (evt) => {
    if (evt) {
      const { name, value, label } = evt.target || evt;
      if (name === 'resourceId') {
        setResourceLabel(label)
        setResourceId(value);
      }
    } else {
      setResourceId('Select resource');
      setResourceLabel("");
    }
  }

  const daysInMonth = (month, year) => {
    let monthDays = new Date(year, month, 0).getDate()
    setMinToDate(`${year}-${month}-1`)
    setStartMaxDate(`${year}-${month}-${monthDays}`)
    setFromDate(`${year}-${month}-1`)
    setToDate(`${year}-${month}-${monthDays}`)
    return new Date(year, month, 0).getDate();
  }

  const setToDateFunction = (newValue) => {
    setToDate(newValue)
  }

  const setFromDateFunction = (newValue) => {
    setFromDate(newValue)
    setMinToDate(newValue)
  }

  const downloadCsvFile = () => {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
    }
    setDownloadFile(true)
    dispatch(monthlyAttandanceReport(filter))
  }


  //custom pagination
  const handlePagination = (data) => {
    setLoader(true)
    setCurrentPage(data)
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${limit}}`
    }
    let newFilterData = JSON.parse(filter);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setList([])
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(monthlyAttandanceReport(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setLoader(true)
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    setList([])
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${newPageSize.target.value}}`
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"skip":0,"limit":${newPageSize.target.value}}`
    }
    let newFilterData = JSON.parse(filter);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(monthlyAttandanceReport(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={rowCount || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  const handleClick = (url) => {
    setDownloadFile(false)
    window.open(url);
  }

  if (downliadFile === true) {
    let api_url = monthlyAttandanceReportList?.data.download_api_url
    if (REACT_APP_API_BASEURL && api_url) {
      let url = REACT_APP_API_BASEURL + api_url;
      if (url) {
        handleClick(url.replace("api//", "api/"))
      }
    }
  }

  const CustomToolbar = () => {
    //nothing
  }

  // advance search filter fields
  const FilterComponents = () => {
    return (
    <>
      <List>
          <ListItem>
            <FormControl style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                <DatePicker
                  views={['month', 'year']}
                  label="Year and Month"
                  minDate={dayjs('2012-03-01')}
                  value={value}
                  onChange={(newValue) => {
                    setYearAndMonth(newValue);
                  }}
                  format="YYYY-MM"
                  renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                />
              </LocalizationProvider>
            </FormControl>
            </ListItem>

            <ListItem>
            {/*select resource section*/}
            <FormControl style={{ width: "98%" }}>
              <Autocomplete
                id="controllable-states-demo"
                value={resourceLabel}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={ (data && data?.data && data?.data?.rows?.length > 0) ? data?.data?.rows?.map((e) => {
                  return { name: "resourceId", value: e.id, label: e.name + "(" + e.email + ")" }
                }) : [] } 
                renderInput={(params) => <TextField {...params} label="Select resource" />}
              />
            </FormControl>
            </ListItem>
            <ListItem>

            {/*from date section*/}
            <FormControl style={{width:'100%'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDateFunction(newValue);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            </ListItem>

            <ListItem>
            {/*to date section*/}
            <FormControl style={{width:'100%'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                <DatePicker
                  minDate={minToDate}
                  label="To Date"
                  value={toDate}
                  onChange={(newValue) => {
                    setToDateFunction(newValue);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            </ListItem>
      </List>
    </>
    )
  }

  // advance search - searchHandler
  const handleFilterSearch = (drawerOpen) => {
    setLoader(true);

    let from_date = fromDate;
    let to_date = toDate;

    if(typeof fromDate === 'object')
      from_date = new Date(fromDate);

    if(typeof toDate === 'object')
      to_date = new Date(toDate);
    
    let filter = '';

    if(resourceId>0)
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${from_date.toString().includes("GMT") ? moment(from_date).format("YYYY-MM-DD") : from_date}","${to_date.toString().includes("India Standard Time") || to_date.toString().includes("GMT") ? moment(to_date).format("YYYY-MM-DD") : to_date}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
    else
      filter = `{"filter":{"punchin_date":{"value":["${from_date.toString().includes("GMT") ? moment(from_date).format("YYYY-MM-DD") : from_date}","${to_date.toString().includes("India Standard Time") || to_date.toString().includes("GMT") ? moment(to_date).format("YYYY-MM-DD") : to_date}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;

    setFilterQuery(filter);
    dispatch(monthlyAttandanceReport(filter));
    drawerOpen(false);
  }
  
  // advance search - resetHandler
  const handleFilterReset = () => {
    setResourceId("select resources")
    setResourceLabel("");
    setValue();
    setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    setToDate(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0))
    setLoader(true);
    let filter = `{"filter":{"punchin_date":{"value":["2023-10-1","2023-10-31"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    setFilterQuery(filter);
    dispatch(monthlyAttandanceReport(filter));
  }

  return (
    <div className="report">
      <div className="reportContainer">
        <div className="datatable">
          <div className="top-headings">
            <h3>Monthly Attendance Report</h3>
            <div style={{width:'200px', display:'flex', alignItems:'center'}}>
              {/* advance filter search start */}
              <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={(status === 'loading') ? true : false} inlineStyle={{width:'60px'}}/>
              {/* advance filter search end */}
              <RefreshButton api={monthlyAttandanceReport} filter={filterQuery} />
              <Tooltip title={"Back"}>
                <button onClick={() => navigate(-1)} className="btn-add-new" style={{marginTop: 12}}>
                  <ArrowBack />
                </button>
              </Tooltip>
            </div>
          </div>
          <ToastContainer />
          {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
            <Card>
              <CardContent>
                <Grid
                  container
                  rowSpacing={1}
                >
                  <Grid item md={7}>
                    <CustomPagination />
                  </Grid>
                  <Grid item md={5}>
                    <Stack
                      style={{
                        justifyContent: "flex-end",
                        marginTop: "6px",
                        display:'flex'
                      }}
                      direction={"row"}
                    >
                     
                      <div style={{ marginLeft: "3px" }}>
                        <Button
                          startIcon={<Download />}
                          variant='contained'
                          onClick={() => downloadCsvFile()}
                          style={status === 'loading' ? { backgroundColor: 'grey' } : { background:'#0052cc' }}
                          disabled={(status === 'loading' ) ? true : false}
                        >
                          CSV(server)
                        </Button>
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
                <DataGrid
                  className="datagrid"
                  rows={list || []}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                  }
                  columns={MonthWiseAttendanceReportColumns}
                  pageSize={pageSize}
                  style={{ fontSize: 16 }}
                  getRowId={(row) => row.emp_code + row.resource_id + row.attendance_month}
                  components={{
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination,
                    NoRowsOverlay: CustomNoRowsOverlay
                  }}
                  hideFooterPagination={true}
                />
              </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )

}
export default MonthWiseAttendanceReport
