import "./master.scss"
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenuList, getMenuList, getRoleMenuList, postAssignMenuRole, postBaseMenu } from '../../redux/features/masterSlice';
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { Button, Dialog, DialogContent, DialogTitle, Checkbox, TablePagination, Tooltip, Switch, Skeleton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Select from 'react-select';
import { getRole } from '../../redux/features/roleSlice';
import { toast, ToastContainer } from 'react-toastify';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import dataService from "../../redux/services/data.service";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import StatusInputValue from "../../components/common-function/StatusOperator";



const formErrData = Object.freeze({
  titleErr: "Title is required",
  url_slugErr: "Slug is required",
  descriptionErr: 'Description required',
  class_nameErr: "Icon name is required",
  statusErr: "Status is required",
});

const skeletonBase = { title: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', description: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', roles: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };
const filterableColumns = ['title', 'description', 'roles', 'status'];

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const MenuList = () => {
  const dispatch = useDispatch();
  const { menuList, status, createMenu, createRoleMenu, createAssignRoleMenu, allMenuList, sortedMenuList } = useSelector(state => state.master)
  const { data } = useSelector(state => state.role)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`)
  const [modalView, setModelView] = useState(false)
  const [formValues, setFormValues] = useState({
    title: "", description: "", class_name: "", url_slug: "", status: "", parent_id: null, sort_by: 0
  })
  const [errStatus, setErrStatus] = useState(false);
  const [loading, setLoading] = useState(false)
  const [roleModalView, setRoleModalView] = useState(false)
  const [selectedIds, setSelectedIds] = useState("")
  const [seletedMenuList, setSeletedMenuList] = useState([])
  const [roleMenu, setRoleMenu] = useState([])
  const [selectedCheckbox, setSelectedCheckbox] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [limit, setLimit] = useState(25);
  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [archiveData, setArchiveData] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState(null);

  // grid states and ref starts
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);
  let gridTotalRows = useRef(null);
  let editID = useRef(null);
  let isAnyChange = useRef({});

  useEffect(() => {
    dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
    dispatch(getRole(`{'filter':{'status':{'value':True,'operation':'eq'}},"no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`))
    dispatch(getAllMenuList(`{'filter':{'status':{'value':True,'operation':'eq'}},"no_limit":True,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MENU_DROUPDOWN_COLUMNS}]}`))
  }, [])


  const closeModal = () => {
    setModelView(false)
    setFormValues({
      title: "", description: "", class_name: "", url_slug: "", status: "", parent_id: null, sort_by: 0
    })
    setErrStatus(false)
  }
  const closeRoleModal = () => {
    setRoleModalView(false)
  }

  const handleChange = (evt) => {
    const { name, value, checked } = evt.target || evt;
    setFormValues({
      ...formValues,
      [name]: name === 'status' ? checked : value,
    });
  }
  const submitData = (e) => {
    e.preventDefault()
    if (formValues.title === "" || formValues.status === "" || formValues.url_slug === "" || formValues.class_name === "" || formValues.description === "") {
      setErrStatus(true)
    } else {
      setErrStatus(false)
      let statusIs = (formValues.status === 'true' || formValues.status === true) ? true : (formValues.status === 'false' || formValues.status === false) && false;
      if (formValues.id > 0) {
        setLoading(`true_updateMenu`)
        let formdata = ({ ...formValues, status: statusIs, parent_id: formValues?.parent_id?.value ? formValues.parent_id.value : formValues.parent_id })
        dispatch(postBaseMenu(formdata))
      } else {
        setLoading(`true_createMenu`)
        let formdata = ({ ...formValues, created_by: GetLocalstorage.userDetail().id, status: statusIs })
        dispatch(postBaseMenu(formdata))
      }

    }
  }


  const editMode = (params) => {
    setFormValues({
      title: params.title,
      description: params.description,
      class_name: params.class_name,
      url_slug: params.url_slug,
      status: params.status,
      parent_id: selectValueInLable(params.parent_id),
      sort_by: params.sort_by,
      id: params.id
    })
    setModelView(true)
  }

  const selectValueInLable = (id) => {
    let data = menuList?.data?.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.title })

    })
    return newObject
  }

  const onSelectionChange = (selectionArray) => {
    if (selectionArray.length > 0) {
      let array = []
      let array2 = []
      let array3 = []
      let array4 = []
      setSelectedIds(selectionArray)
      data?.data?.rows.map(e => {
        return array2.push({
          role_id: e.id,
        })
      })
      selectionArray.map(e => {
        return menuList?.data?.rows.forEach((option) => {
          if (e === option.id) {
            array.push({ all_role: array2, menu_id: e, option })

            if (option.roles && option.roles.length > 0) {
              option.roles.forEach(r => {
                array3.push({ role_id: r.id, menu_id: e, status: r.status, parent_id: option.parent_id })
                array4.push({ [`check_${e}_${r.id}`]: r.status })
              })
            }

          }
        })
      })
      if (array.length > 0) {
        array.forEach(e => {
          e.all_role && e.all_role.length > 0 && e.all_role.forEach(e1 => {
            e1[`check_${e.menu_id}_${e1.role_id}`] = array3 && array3.length > 0 && array3.filter(x => {
              return (x.role_id === e1.role_id && x.menu_id === e.menu_id && x.status === true) && e1
            })
          })

        })
        setSeletedMenuList(array)
      }
      if (array3.length > 0) {
        setRoleMenu(array3)
        setSelectedCheckbox(array4)
      }

    } else {
      setSelectedIds("")
      setSeletedMenuList([])
    }
  }
  function arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele !== value;
    });
  }
  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }
  const checkBoxFunction = async (selectedRolesMenu) => {
    if (selectedRolesMenu) {
      let roleArray = []
      const response = await dataService.getRoleMenu(`{"filter":{"menu_id":{"value":${selectedRolesMenu.menu_id}, 'operation' :'eq'}},"no_limit":True}`)
      const result = response?.data
      const rows = result?.data?.rows
      if (result.status === 200) {
        if (rows && rows.length > 0) {
          rows.map(el => el.status === true && roleArray.push(el.role_id))
        }
      }

      if (roleArray && roleArray.length > 0) {
        if (selectedRolesMenu.status === false) {
          let results = arrayRemove(roleArray, selectedRolesMenu.role_id);
          roleArray = results
        }

        if (selectedRolesMenu.status === true) {
          roleArray.push(selectedRolesMenu.role_id)
        }

      } else {
        roleArray.push(selectedRolesMenu.role_id)
      }

      let roleMenuArray = removeDuplicates(roleArray)
      let data = {
        "roles": roleMenuArray,
        "menu": selectedRolesMenu.menu_id,
        "created_by": GetLocalstorage.userDetail().id
      }

      dispatch(postAssignMenuRole(data))
      setLoading("update_role_menu")
    }

  }

  const addCheckBoxes = (event, role, menu) => {
    setSelectedCheckbox({ ...selectedCheckbox, [event.target.name]: event.target.checked })
    seletedMenuList.forEach(e => {
      e.all_role && e.all_role.length > 0 && e.all_role.forEach(e1 => {
        e1[event.target.name] = event.target.checked
      })
    })
    setSeletedMenuList(seletedMenuList)
    let object = { role_id: role, menu_id: menu, status: event.target.checked }
    if (roleMenu && roleMenu.length > 0) {
      var filteredRoleMenus = roleMenu.filter(function (el) {
        return (el.role_id === role && el.menu_id === menu);
      });
      if (filteredRoleMenus.length === 0) {
        checkBoxFunction(object)
      }
      if (filteredRoleMenus.length > 0) {
        checkBoxFunction(object)
      }
    } else {
      checkBoxFunction(object)
    }

  }

  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {
      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }
    setFilterQuery(JSON.stringify(newFilterData))
    setGridRows([]);
  }

  const handleArchive = (row) => {
    setArchiveData(row)
    setArchiveOpen(true)
    if (row.status === true) {
      setChangeStatus(false)
    } else {
      setChangeStatus(true)
    }
  }

  const statusOnlyOperators = [
    {
        label: "is",
        value: "is",
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params) => {
                return params.value === 'is';
            };
        },
        InputComponent: StatusInputValue,
    },
];

const filterOperators = getGridStringOperators().filter(({ value }) =>
['contains', 'equals', 'startsWith', 'endsWith' ].includes(value),
);

  const menuListColumn = [
    { field: "id", headerName: "ID", width: 70,filterable:false },
    {
      field: "title",
      sortable: false,
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Title</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("title")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 230,
      filterOperators,
      renderCell: (params) => {
        return (
          params.row.title === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div>
              {params.row.title}
            </div>
        );
      },
    },
    {
      field: "description",
      sortable: false,
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 230,
      filterOperators,
      renderCell: (params) => {
        return (
          params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div>
              {params.row.description}
            </div>
        );
      },
    },
    {
      field: "roles",
      sortable: false,
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Permissions</p></div>,
      width: 300,
      filterable: false,
      renderCell: (params) => {
        let list = Array.isArray(params.row.roles) ? params.row.roles : [];
        let resArr = [];
        list && list?.length > 0 && list?.filter(function (item) {
          let i = resArr.findIndex(x => (x.role_id === item.role_id && x.menu_id === item && x.status === item.status));
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        return (
          params.row.roles === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
            <div className={`cellWithStatus ellips-css-data`}>
              {resArr.length > 0 && resArr.map((e, index) => `${index ? ', ' : ''}${(e.role_name).trim()}`)}
            </div>
        );
      },

    },
    {
      field: "status",
      headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Menu Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
      width: 160,
      sortable: false,
      pinnable: false,
      type: 'singleSelect',
      filterOperators: statusOnlyOperators,
      valueOptions: [{ label: 'Active', value: 'True' }, { label: 'InActive', value: 'False' }],
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
          </div>
        );
      },
    },
  ]
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="actionButton">
            <UcActionComponent
              moduleName="menubar"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl={false}
              viewLinkUrl={false}
              isDelete={false}
              deleteIconName="ArchiveIcon"
              editButton={editMode}
              viewButton={false}
              deleteButton={false}
            />
          </div>
        );
      },
    },
  ]

  const handleClickArchive = (data) => {
    setLoading(`true_updateMenu`)
    const payLoad = {
      title: data.title,
      description: data.description,
      class_name: data.class_name,
      url_slug: data.url_slug,
      status: changeStatus,
      parent_id: data?.parent_id?.value ? data.parent_id.value : data.parent_id,
      sort_by: data.sort_by,
      id: data.id
    }
    dispatch(postBaseMenu(payLoad))
  }

  const onFilterChange = (props) => {
    if (props.items.length > actionColumn.length)
      return;

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.MENU_LIST_COLUMNS}]}`);
      setFilterQuery(str);
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.MENU_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartswith',
        endsWith: 'iendswith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {
          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }

  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows.length;
    updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
    setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  if (loading === "update_role_menu" && status === `${STATUSES.SUCCESS}_createAssignRoleMenu` && createAssignRoleMenu && createAssignRoleMenu.message) {
    if (createAssignRoleMenu.status === 200) {
      dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
      // dispatch(getMenuList(filterQuery))
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
      setFilterQuery(str)
      setLoading(true)
    } else {
      toast.error(createAssignRoleMenu.message)
      setLoading(false)
    }
  }
  if (loading === "update_role_menu" && status === `${STATUSES.SUCCESS}_createRoleMenu` && createRoleMenu && createRoleMenu.message) {
    if (createRoleMenu.status === 200) {
      dispatch(getRoleMenuList(`{"no_limit":True, "order_by":{"column":"-id"}}`))
      // dispatch(getMenuList(filterQuery))
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
      setFilterQuery(str)
      setLoading(true)
    } else {
      toast.error(createRoleMenu.message)
      setLoading(false)
    }
  }
  if ((status === `${STATUSES.SUCCESS}_createMenu` || status === `${STATUSES.FAILURE}_createMenu`) && loading === 'true_createMenu') {
    if (createMenu.status === 200) {
      toast.success(createMenu.message)
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      closeModal()
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.success(createMenu.message)
    }
    setLoading(false)
  }

  if ((status === `${STATUSES.SUCCESS}_createMenu` || status === `${STATUSES.FAILURE}_createMenu`) && loading === 'true_updateMenu') {
    if (createMenu.status === 200) {
      toast.success(createMenu.message)
      let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
      setFilterQuery(str)
      closeModal()
    } else {
      let copyLoadedRows = [...gridRows];
      let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
      copyLoadedRows.splice(index, 1, editID.current)
      setGridRows(copyLoadedRows);
      toast.success(createMenu.message)
    }
    setLoading(false)
    setChangeStatus(null)
    setArchiveOpen(false)
  }

  if (reload === true && status === STATUSES.SUCCESS && menuList && menuList.message) {
    if (menuList.status === 200) {
      if (clearGridListing.current) {
        setGridRows([...menuList.data.rows]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(menuList.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...menuList.data.rows]);
        SET_MAX_ROW_LENGTH(menuList.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(menuList.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }
  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setReload(true);
    setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    dispatch(getMenuList(filterQuery))
  }, [filterQuery])



  return (
    <div className="master">
      <div className="masterContainer">
        <div className="datatable">
          <div className="top-headings">
            <h3>Menu List</h3>
            <div>
              <Tooltip title="Assign Roles">
                <button onClick={() => selectedIds && setRoleModalView(true)} className={selectedIds ? "btn btn-add-new" : "btn-add-disabled"}><AssignmentIcon /></button>
              </Tooltip>

              <Tooltip title="Create Menu">
                <button onClick={() => setModelView(true)} className="btn btn-add-new">
                  <AddIcon />
                </button>
              </Tooltip>
              {/* <RefreshButton api={getMenuList} filter={filterQuery} style={{ marginTop: 0 }} /> */}
              <RefreshButton api={getMenuList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 20 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 
            </div>
          </div>
          <ToastContainer />
          {/* <div className="custom-pagination-class">
            <CustomPagination />
          </div> */}


          <DataGridPro
            className="datagrid"
            loading={status === STATUSES.LOADING}
            columnVisibilityModel={{
              id: false
            }}
            sx={{
              height: 700,
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#438bf969",
              },
              ".actionButton": {
                display: 'none'
              },
              [`& .${gridClasses.row}`]: {
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    display: 'block'
                  }
                }
              }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            rows={gridRows?.concat(skeletonRows)}
            columns={menuListColumn.concat(actionColumn)}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            checkboxSelection
            onSelectionModelChange={onSelectionChange}
            disableSelectionOnClick
            onFilterModelChange={onFilterChange}
            components={{
              ColumnMenuIcon: MoreHorizIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={(sort) => handleSort(sort)}
            onRowsScrollEnd={handleOnRowScrollEnd}
            hideFooterSelectedRowCount
          />

        </div>

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"lg"}
          open={modalView}
          onClose={closeModal}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <Button
            edge="start"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">Create Menu</DialogTitle>
          {(loading === 'true_createMenu' || loading === 'true_updateMenu') && <LoaderForm />}
          <DialogContent style={{ height: "500px" }}>
            <div>
              <form className="regulazation-form">
                <div className="add-new-user-form">
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Parent Menu
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select"
                      defaultValue={formValues.parent_id}
                      isSearchable={true}
                      name={"parent_id"}
                      options={
                        allMenuList?.data?.rows.map((option) => {
                          return { label: option.title, value: option.id, name: "parent_id" }
                        })
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Title*
                    </label>
                    <input
                      name="title"
                      type="text"
                      defaultValue={formValues.title}
                      placeholder="Menu"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.title === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.titleErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Url Slug*
                    </label>
                    <input
                      name="url_slug"
                      type="text"
                      defaultValue={formValues.url_slug}
                      placeholder="master(master is Parent)-menu-list(menu-list is Chlid)"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.url_slug === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.url_slugErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Icon Name*
                    </label>
                    <input
                      name="class_name"
                      type="text"
                      defaultValue={formValues.class_name}
                      placeholder="Icon Name"
                      onChange={handleChange}
                    />
                    {errStatus === true && formValues && formValues.parent_id !== "" && formValues.class_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.class_nameErr}</p>}

                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Sort By
                    </label>
                    <input
                      name="sort_by"
                      type="number"
                      defaultValue={formValues.sort_by}
                      placeholder="Sort by position"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formInput" style={{ flexBasis: "32%" }}>
                    <label >
                      Status*
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>Inactive</p>
                      <Switch name="status" checked={formValues.status} onChange={handleChange} />
                      <p>Active</p>
                    </div>
                    {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.statusErr}</p>}

                  </div>

                  <div className="formInput" style={{ flexBasis: '100%' }}>
                    <label >
                      Description*
                    </label>
                    <textarea style={{ flexBasis: "100%" }} defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>
                    {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.descriptionErr}</p>}
                  </div>
                </div>
                {loading === false && <button onClick={submitData} className="btn btn-submit">Submit</button>}
              </form>
            </div>
          </DialogContent>
        </Dialog >

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"xl"}
          open={roleModalView}
          onClose={closeRoleModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Button
            edge="start"
            color="inherit"
            onClick={closeRoleModal}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>Menu Role Assign</DialogTitle>
          {(loading === 'true_updateMenuRole') && <LoaderForm />}
          <DialogContent style={{ height: "600px" }}>
            <div>
              <table style={{ width: '100%' }}>
                <thead>
                  <th></th>
                  {data?.data?.rows.map((item, index) => {
                    return (
                      <th key={index}>{item.role_name}</th>
                    )
                  })}
                </thead>
                <tbody className="master-table-body">
                  {seletedMenuList.map((i, key) => {
                    return (
                      <tr key={key}>
                        <td>{i.option.title}</td>
                        {i.all_role && i.all_role.length > 0 && i.all_role.map((itm, idx) => {
                          return (
                            <td key={idx}><Checkbox name={`check_${i.menu_id}_${itm.role_id}`}
                              checked={itm[`check_${i.menu_id}_${itm.role_id}`].length === 0 ? false : itm[`check_${i.menu_id}_${itm.role_id}`] === false ? false : true}
                              onChange={(e) => addCheckBoxes(e, itm.role_id, i.menu_id)} /></td>
                          )
                        })}
                      </tr>
                    )
                  })}


                </tbody>
              </table>
            </div>
          </DialogContent>
        </Dialog>
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus ? "Active" : "InActive"}
          paragraph={`Are you sure to ${changeStatus ? "Active" : "InActive"} menu`}
          handleArchive={handleClickArchive}
          id={archiveData}
        />
      </div >

    </div >
  )
}


export default MenuList