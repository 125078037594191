import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const getSprintList = createAsyncThunk("sprint/list", async (data) => {
    const res = await DataService.all_sprint_list(data);
    return res.data;
});

export const getSprintListByJira = createAsyncThunk("sprint/sync", async (project_id) => {
    const res = await DataService.sprint_sync(project_id);
    return res.data;
});


const sprintSlice = createSlice({
    name: "sprintData",
    initialState: {
        sprintList: [],
        syncSprintList: [],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getSprintList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSprintList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.sprintList = action.payload;
            })
            .addCase(getSprintList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// sprint sync by jira 
            .addCase(getSprintListByJira.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSprintListByJira.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.syncSprintList = action.payload;
            })
            .addCase(getSprintListByJira.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});


export default sprintSlice.reducer;