
import "../../pages/master/master.scss"
import { toast } from "react-toastify";
import { Dialog, DialogContent, Tooltip, } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobPostCreate, getJobPostList, getProjectMappingDomain, jobPostUpdate, updateJobApprovalStatus, jobBudgetReviseList } from "../../redux/features/jobPostSlice";
import { getAllContractTypeList, getAllCurrencyList, getAllIndustryList, getAllJobLocationList, getAllMoreSpecificationList, getAllQualificationList, getAllSkillList, getAllUrgencyList, getAllVacancyList, getAllWorkingModeList, getHiringRequestList, } from "../../redux/features/masterSlice";
import { getResourceWiseProjectList, project_Jira_List } from "../../redux/features/taskSlice";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Download } from "@mui/icons-material";
import { InputFields, InputMultiSelectField, InputRadioGroupFields, InputSelectField, InputEditorField, ViewField } from "../../components/common-function/InputFields";
import Select from 'react-select';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { jobScreeningFormEdit } from "../../redux/features/candidateScreeningFormSlice";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { getClientList, getDepartmentList, getIndustryList, getSpecialisationList } from "../../redux/features/hiringRequestSlice";
import BudgetLog from "./job-post/BudgetLog";
import ApproverForm from "./job-post/ApproverForm";

const initialFormData = Object.freeze({
    job_title: "",
    job_description: "",
    job_skill: "",
    work_mode: "",
    work_experience: "0",
    preferred_industry: "",
    industry: "",
    education_qualification: "",
    other_skill: "",
    project_id: "",
    status: "",
    type: "",
    number_of_vacancies: "",
    preferred_location: "",
    relevant_experience_expected_in_months: "",
    relevant_experience_expected_in_years: "",
    required_skill_set: "",
    urgency: "",
    type_of_vacancy: "",
    contract_type: "",
    department: "",
    job_location: "",
    job_responsibilities: "",
    add_more_specifications: "",
    client_budget_from: "",
    client_budget_to: "",
    client: "",


});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    job_titleErr: "Title is required",
    work_modeErr: "Work mode is required",
    work_experienceErr: "Work experience is required",
    project_idErr: "Project is required",
    industryErr: "Industry is required",
    education_qualificationErr: "Education qualification is required",
    job_skillErr: "Skills is required",
    domain_idErr: "Domain is required",
    numOfVacancyErr: "Number of vacancy is required",
    preferredLocationErr: "Preferred location is required",
    relevantExpErr: "Relavant experience is required",
    skillSetErr: "Skill set is required",
    urgencyErr: "Urgency is required",
    vacancyTypeErr: "Vacancy type is required",
    departmentErr: "Department is required",
    contractTypeErr: "Contract type is required",
    job_locationErr: "Job location is required",
    jobResErr: "Job responsibility",
    moreSpecErr: "specification is required",
    budgetFormErr: " From budget is required",
    budgetToErr: "To budget is required",
});

let experienceYearArr = [];
let experienceMonthArr = [];
let etaMonthArr = [];
let etaDayArr = [];

for (let i = 0; i <= 24; i++) {
    etaMonthArr.push(i);
}

for (let i = 0; i <= 31; i++) {
    etaDayArr.push(i);
}
for (let i = 0; i <= 50; i++) {
    experienceYearArr.push(i);
}
for (let i = 0; i <= 11; i++) {
    experienceMonthArr.push(i);
}
const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role


const JobPost = ({ open, setOpen, getJobData, id, filterQuery }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(getJobData ? getJobData : initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, jobPost_Create, jobPost_Update, jobPost_Edit, jobPost_Archive, mappingDomain_List, revised_Salary_List, jobPost_Approver_Status } = useSelector(state => state.jobpost)

    const { client_List, industry_List, department_List, specialisation_List, } = useSelector(state => state.hiringRequest)
    const { qualification_AllList, industry_AllList, location_option_List, urgencyType_AllLists, contracttype_AllList, specificationOptionList, vacancy_AllList } = useSelector(state => state.master)
    const { resourceWiseProjectList, createNewProjectList } = useSelector(state => state.task)
    const [ID, setID] = useState(id ? id : "");
    const [reload, setReload] = useState(false)
    // const [filterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}`)

    const [skills, setSkills] = useState([])
    const [other_skill, setOther_skill] = useState([])
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [job_description, setJob_description] = useState(getJobData?.job_description ? getJobData.job_description : "")
    const [createNewProject, setCreateNewProject] = useState(false)
    const [domainId, setDomainId] = useState("");
    //screening states
    const [, setOpenScreenigForm] = useState(false)
    const [, setJobId] = useState('')
    const { jobScreeningForm_getbyID } = useSelector(state => state.candidateScreeningForm)
    const [, setEditJobScreening] = useState({})
    const [screeningApiReload, setScreeningApiReload] = useState(false)
    // const { skils } = useSelector(state => state.dropdownOptions)
    const [, setShowContractType] = useState(false);
    const [clientId, setClientId] = useState(0);
    const [industryId, setIdustryId] = useState(0);
    const [, setDepartmentId] = useState(0);
    const [, setShowExpectedHour] = useState(false);
    const [, setShowTextBox] = useState(false);
    const [, setSpecialisationSkills] = useState([])
    const [, setSkillData] = useState([])
    const [totalExperienceTo, setTotalExperienceTo] = useState(experienceYearArr)
    const [relevanExperienceTo, setRelevanExperienceTo] = useState(experienceYearArr)
    const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin') ? `{"filter":{"approver":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}` : `{"skip":0, "limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.JOB_POST_LIST_COLUMNS}]}`

    const { REACT_APP_API_BASEURL } = process.env;

    const { resourceListDropDown } = useSelector(state => state.resource)

    const [openAssignApprover, setOpenAssignApprover] = useState(false)
    const [reviseComment, setReviseComment] = useState("")
    const [commentRequireFrom, setCommentRequireFrom] = useState(false)
    const [commentRequireTo, setCommentRequireTo] = useState(false)
    const [rejectComment, setRejectComment] = useState("")

    useEffect(() => {
        // dispatch(getJobPostList(filterQuery))
        dispatch(getAllIndustryList())
        dispatch(getAllQualificationList())
        dispatch(getAllSkillList())
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getProjectMappingDomain({ "order_by": { "column": "-id" }, "no_limit": 'True', "columns": ["id", "domain__id", "domain__name"] }))
        dispatch(getAllContractTypeList())
        dispatch(getClientList())
        dispatch(getAllCurrencyList())
        dispatch(getAllVacancyList())
        dispatch(getAllWorkingModeList())
        dispatch(getAllMoreSpecificationList())
        dispatch(getAllUrgencyList())
        dispatch(getAllJobLocationList())
        dispatch(getDepartmentList())
        dispatch(getSpecialisationList())
        dispatch(project_Jira_List())
        if (id) { dispatch(jobBudgetReviseList(`{"filter":{"job_id":{"value":${id},"operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}`)) }
    }, [])

    // useEffect(() => {
    //     if (status === STATUSES.FAILURE && error) {
    //         toast.error(error.message)
    //         setReload(false)
    //     }
    //     // eslint-disable-next-line
    // }, [error])

    useEffect(() => {

        if (status === STATUSES.SUCCESS) {
            if (jobPost_Edit?.data?.job_attachments) {
                setFormValues((prev) => ({ ...prev, attachment: jobPost_Edit?.data?.job_attachments }))
            }
        }

    }, [status]);

    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Update` && jobPost_Update && jobPost_Update.message) {
        if (jobPost_Update.status === 200) {
            toast.success(jobPost_Update.message)
            setOpen(false);
            let jobId_v = jobPost_Update.data?.id
            if (jobPost_Update.data && jobPost_Update.data.status === true) {
                jobPost_Update.data && setJobId(jobPost_Update.data.id)
                setScreeningApiReload('edit-screening-form')
                dispatch(jobScreeningFormEdit(`{"filter":{"job_id":{"value":${jobId_v},"operation":"eq"}}}`))
            }
            // setOpenScreenigForm(true)
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setJob_description("")
            setCreateNewProject(false)
            setID(0)
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Update.message)
        }
        setReload(false)
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Create` && jobPost_Create && jobPost_Create.message) {
        if (jobPost_Create.status === 200) {
            toast.success(jobPost_Create.message)
            setOpen(false);
            jobPost_Create.data && setJobId(jobPost_Create.data.id)
            setOpenScreenigForm(true)
            setSkills([])
            setOther_skill([])
            setActiveViewMode(false)
            setFormValues(initialFormData);
            setJob_description("")
            setID(0)
            setCreateNewProject(false)
            filterQuery && dispatch(getJobPostList(filterQuery))
            dispatch(getHiringRequestList(`{"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`))
        } else {

            toast.error(jobPost_Create.message)

        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jobPost_Archive` && jobPost_Archive && jobPost_Archive.message) {
        if (jobPost_Archive.status === 200) {

            toast.success(jobPost_Archive.message)
            dispatch(getJobPostList(filterQuery))

        } else {
            toast.error(jobPost_Archive.message)
        }
        setReload(false)
    }

    const handleClose = () => {
        if (activeViewMode === true) {
            setActiveViewMode(false);
        } else {
            setOpen(false);
            setSkills([]);
            setOther_skill([]);
            setJob_description("");
            setID(0);

        }
        setErrStatus(false);
        if (!activeViewMode) {
            setFormValues(initialFormData);
            if (createNewProject == true) {
                setFormValues({
                    project_id: { value: -1, label: "Create New Project" },
                    ...formValues,
                });
            }
        }
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'project_id') {
            if (value === '-1') {
                setCreateNewProject(true);
            } else {
                setCreateNewProject(false);
            }
        }

        if (value === '-1' && name === 'project_id') {
            setFormValues({ ...formValues, [name]: { value: "-1", label: "Create New Project" } });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const handleChangeText = (evt) => {
        const { name, value } = evt.target || evt;

        if (name === 'client_budget_from') {
            if (Number(formValues.client_budget_from_new) !== Number(value)) {
                setCommentRequireFrom(true)
            } else {
                setCommentRequireFrom(false)
            }
        } else if (name === 'client_budget_to') {
            if (Number(formValues.client_budget_to_new) !== Number(value)) {
                setCommentRequireTo(true)
            } else {
                setCommentRequireTo(false)
            }
        }

        setFormValues({ ...formValues, [name]: name === "expected_ETA" ? value * 1 : value });
    }
    const addMoreTechnology = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, specialistation_id: evt })
            setSpecialisationSkills(evt)
        } else {
            setFormValues({ ...formValues, specialistation_id: "" })
            setSpecialisationSkills([])
            setSkillData([])
        }
    }
    const handleChangeSelect = (evt) => {
        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'client') {
                setClientId(value)
                dispatch(getIndustryList(value))
                setFormValues({ ...formValues, [name]: evt, industry: "", department: "", specialistation_id: "" });

            } else if (name === 'industry') {
                setIdustryId(value)
                dispatch(getDepartmentList({ client_id: clientId, industry_id: value }))
                setFormValues({ ...formValues, [name]: evt, department: "", specialistation_id: "" });

            } else if (name === 'department') {
                setDepartmentId(value)
                dispatch(getSpecialisationList({ client_id: clientId, industry_id: industryId, department_id: value }))
                setFormValues({ ...formValues, [name]: evt, specialistation_id: "" });

            } else if (name === 'type_of_vacancy') {
                setFormValues({ ...formValues, [name]: evt });
                if (evt.key_name && (evt.key_name.match('part-time') || evt.key_name.match('partTime') || evt.key_name.match('part-time'))) {
                    setShowContractType(false)
                    setShowExpectedHour(true)
                } else if (evt.key_name?.match('contractual')) {
                    setShowContractType(true)
                    setShowExpectedHour(false)
                }
                else if (evt.key_name?.match('permanent')) {
                    setShowContractType(false)
                    setShowExpectedHour(false)
                }
            } else if (name === 'urgency') {
                setFormValues({ ...formValues, [name]: evt });
                if (evt.value && evt.value === '-1') {
                    setShowTextBox(true)
                } else {
                    setShowTextBox(false)
                }
            } else {
                setFormValues({ ...formValues, [name]: evt });
            }
        } else {
            setClientId(0)
            setIdustryId(0)
        }
    }

    const handleMultiQualificationChange = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, education_qualification: evt })
        } else {
            setFormValues({ ...formValues, education_qualification: "" })
        }
    }
    const fileDownload = (url) => {
        let ApIURL = REACT_APP_API_BASEURL + url;
        window.open(ApIURL.replace("api//", "api/"));
    };


    const handleChangeOnSpecification = (evt) => {
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, "add_more_specifications": evt });
        } else {
            setFormValues({ ...formValues, "add_more_specifications": "" });
        }
    }



    const submitData = (e) => {
        e.preventDefault()
        if (job_description === "" || formValues.project_id === "") {
            setErrStatus(true)
        }

        if (formValues.client_budget_to < formValues.client_budget_from) {
            toast.error("Budget from should always be less than Budget To")
            return;
        }

        if (job_description && formValues.project_id) {
            setErrStatus(false)
            findName()
            setActiveViewMode(true)
        }
    }

    const addMoreSkill = (evt) => {
        let array = []
        if (evt && evt.length > 0) {
            setFormValues({ ...formValues, job_skill: evt })
        } else {
            setFormValues({ ...formValues, job_skill: "" })
        }

        evt.map(e => {
            let filterExp = skills.filter(x => x.skill_id === e.id)
            return array.push({
                skill: e.skill_name,
                skill_id: e.id,
                experience: filterExp.length > 0 ? filterExp[0].experience : "0",
                experience_type: "Months",
                status: true
            })
        })

        setTimeout(() => {
            setSkills(array)
        }, 500);

    }

    //     } else {
    //         setOther_skill([
    //             {
    //                 skill: "",
    //                 experience: "0",
    //                 experience_type: "Months"
    //             }
    //         ])
    //     }

    // }


    const findName = () => {
        let industrylist = (industry_AllList?.data && industry_AllList.data.rows) || [];
        let projectlist = (resourceWiseProjectList) || []
        let filter = industrylist.filter(x => x.id === formValues.industry)
        let filterWorkMode = GetLocalstorage.get_default_work_mode().filter(x => x === formValues.work_mode)
        let filterProject = projectlist.filter(x => x.id === formValues.project_id)

        if (filterProject.length > 0) {
            formValues.project_id = { label: filterProject[0].project_name, value: filterProject[0].id }

            setFormValues({ ...formValues, project_id: { label: filterProject[0].project_name, value: filterProject[0].id } })

        }
        if (filterWorkMode.length > 0) {
            formValues.work_mode = { label: formValues.work_mode, value: formValues.work_mode }
            setFormValues({ ...formValues, work_mode: formValues.work_mode })

        }
        if (filter.length > 0) {
            formValues.industry = { label: filter[0].industry_name, value: filter[0].id }
            setFormValues({ ...formValues, industry: { label: filter[0].industry_name, value: filter[0].id } })
        }
        //return industry
    }

    const inputHandler = (evt) => {

        let newContent = evt.editor.getData();
        setJob_description(newContent)
        setFormValues({ ...formValues, 'job_description': newContent })

    }
    const finalSubmit = (e) => {
        e.preventDefault()
        let education_qualification_array = [];
        let preferred_industry_array = [];
        
        if (formValues.education_qualification && formValues.education_qualification.length > 0) {
            formValues.education_qualification.map(e => {
                return education_qualification_array.push(e.id)
            })
        }
        if (formValues.preferred_industry && formValues.preferred_industry.length > 0) {
            formValues.preferred_industry.map(e => {
                return preferred_industry_array.push(e.id)
            })
        }

        let specArray = []
        if (formValues.add_more_specifications && formValues.add_more_specifications.length > 0) {
            // formValues.add_more_specifications.map(e => e.value ? specArray.push(e.value) : specArray.push(e.id))
            formValues.add_more_specifications.map(e => {
                return specArray.push(e.value)
            })
        }

        const { eta_of_Arrival, ...otherValues } = formValues
        let addvalues = {
            ...otherValues,
            created_by: GetLocalstorage.userDetail().id,
            job_skill: skills, other_skill: other_skill,
            industry: formValues.industry.value ? formValues.industry.value : formValues.industry,
            work_mode: formValues.work_mode.value ? formValues.work_mode.value : formValues.work_mode,
            education_qualification: education_qualification_array,
            preferred_industry: preferred_industry_array,
            project: formValues?.project_id && formValues.project_id.value ? formValues.project_id.value : formValues.project_id,
            job_experience: formValues.work_experience,
            // experience_type: formValues.type.value ? formValues.type.value : formValues.type,
            // job_description: formValues && formValues.job_description,
            hiring_request_id: formValues.hiring_request_id,

            client: formValues.client?.label ? formValues.client.value : formValues.client,
            // industry: formValues.industry?.label ? formValues.industry.value : formValues.industry,
            department: formValues.department?.label ? formValues.department.value : formValues.department,

            currency: formValues.currency?.label ? formValues.currency.value : formValues.currency,
            payment_type: formValues.payment_type?.label ? formValues.payment_type.value : formValues.payment_type,
            hiring_mode: formValues.hiring_mode?.label ? formValues.hiring_mode.value : formValues.hiring_mode,

            more_specifications: specArray,
            job_description: job_description,
            job_location: formValues.job_location?.value ? formValues.job_location.value : formValues.job_location,
            type_of_vacancy: formValues.type_of_vacancy?.value ? formValues.type_of_vacancy.value : formValues.type_of_vacancy,
            urgency: formValues.urgency?.value ? formValues.urgency.value : formValues.urgency,
            working_mode: formValues.working_mode?.value ? formValues.working_mode.value : formValues.working_mode,
            contract_type: { label: formValues.contract_type?.name, value: formValues.contract_type_id },
            // contract_type: formValues.contract_type && formValues.contract_type.value ? formValues.contract_type.value : formValues.contract_type,
            // job_experience: formValues?.job_experience,
            expected_duration_in_months: formValues.expected_duration_in_months,
            hours_per_day: formValues.hours_per_day,

            // job_attachments: formValues.attachment,
            attachment: formValues?.attachment?.job_attachments,

            relevant_experience_expected_from: formValues.relevant_experience_expected_from,
            relevant_experience_expected_to: formValues.relevant_experience_expected_to,
            total_experience_to: formValues.total_experience_to,
            total_experience_from: formValues.total_experience_from,
            expected_ETA: formValues.expected_ETA,
        }

        if (createNewProject === true) {
            addvalues['project'] = null;
            addvalues['create_new_project'] = true;
            addvalues['domain_id'] = domainId.value;
        }
        delete addvalues['project_id']
        delete addvalues['work_experience']
        delete addvalues['type']
        setReload(true)
        // Api call
        if (ID) {
            dispatch(jobPostUpdate({ ID, addvalues }))
        } else {
            dispatch(jobPostCreate(addvalues))
        }
    }


    if (screeningApiReload === 'edit-screening-form' && jobScreeningForm_getbyID && jobScreeningForm_getbyID.status) {

        if (jobScreeningForm_getbyID.status === 200) {
            setOpenScreenigForm(true)
            if (jobScreeningForm_getbyID.data?.rows && jobScreeningForm_getbyID.data.rows.length > 0) {
                let formData = jobScreeningForm_getbyID.data.rows.length > 0 ? jobScreeningForm_getbyID.data.rows[0] : ""
                setEditJobScreening(formData)
            }
        } else {
            toast.error(jobScreeningForm_getbyID.message)
        }
        setScreeningApiReload(false)
    }


    let experienceArr = [];
    for (let i = 0; i <= 40; i++) {
        experienceArr.push(i);
    }

    const handleConfirmApproved = (type) => {
        let addvalues = {
            job_title: jobPost_Edit.data.job_title,
            job_description: jobPost_Edit.data.job_description,
            job_skill: formValues.job_skill,
            work_mode: jobPost_Edit.data.work_mode,
            preferred_industry: jobPost_Edit.data.preferred_industry,
            industry: jobPost_Edit.data.industry_id,
            education_qualification: jobPost_Edit.data.education_qualification,
            other_skill: formValues.other_skill,
            status: true,
            hiring_request: jobPost_Edit.data.hiring_request_id,
            client: formValues.client.value,
            number_of_vacancies: formValues.number_of_vacancies,
            department: jobPost_Edit.data.department_id,
            client_budget_from: commentRequireFrom === true ? formValues.client_budget_from : formValues.client_budget_from_new,
            client_budget_to: commentRequireTo === true ? formValues.client_budget_to : formValues.client_budget_to_new,
            type_of_vacancy: jobPost_Edit.data.type_of_vacancy,
            contract_type: jobPost_Edit.data.contract_type,
            hours_per_day: jobPost_Edit.data.hours_per_day,
            expected_duration_in_months: jobPost_Edit.data.expected_duration_in_months,
            urgency: jobPost_Edit.data.urgency,
            job_location: jobPost_Edit.data.job_location,
            job_responsibilities: formValues.job_responsibilities,
            add_more_specifications: formValues.add_more_specifications,
            specialistation_id: formValues.specialistation_id,
            id: jobPost_Edit.data.id,
            relevant_experience_expected_from: jobPost_Edit.data.relevant_experience_expected_from,
            relevant_experience_expected_to: jobPost_Edit.data.relevant_experience_expected_to,
            total_experience_to: jobPost_Edit.data.total_experience_to,
            total_experience_from: jobPost_Edit.data.total_experience_from,
            expected_ETA: formValues.expected_ETA,
            urgency_unit: formValues.urgency_unit,
            created_by: jobPost_Edit.data.created_by,
            project: jobPost_Edit.data.project_id,
            more_specifications: formValues.more_specifications

        }

        if (type === 'accept') {
            addvalues['approver_status'] = 2
            if (jobPost_Edit.data.approval_status === 7) {
                addvalues["revise_budget"] = {
                    job: formValues.id,
                    approval_status: 5,
                    approver_comment: reviseComment,
                    is_approver_change: (commentRequireFrom === true || commentRequireTo === true) ? true : false,
                    id: revised_Salary_List?.data?.rows && revised_Salary_List?.data?.rows[0]?.id ? revised_Salary_List?.data?.rows[0]?.id : ""
                }
            }
        } else if (type === 'reject_confirm') {
            addvalues['approver_status'] = 3
        } else {
            addvalues['approver_status'] = 3
            if (jobPost_Edit.data.approver_status === 7) {
                setOpenAssignApprover(false)
                addvalues["revise_budget"] = {
                    job: formValues.id,
                    approval_status: 7,
                    approver_comment: reviseComment,
                    is_approver_change: (commentRequireFrom === true || commentRequireTo === true) ? true : false,
                    id: revised_Salary_List?.data?.rows && revised_Salary_List?.data?.rows[0]?.id ? revised_Salary_List?.data?.rows[0]?.id : ""
                }
            } else {
                setOpenAssignApprover(true)
            }
        }

        if (commentRequireFrom === true || commentRequireTo === true || (type === 'reject_confirm' && reviseComment === "" && jobPost_Edit.data.approval_status === 7)) {
            return toast.error('Comment is Required')
        } else if (type === 'reject_confirm' && jobPost_Edit.data.approver_status === 1 && openAssignApprover === true) {
            dispatch(updateJobApprovalStatus({ id: formValues.id, data: addvalues }))
            setReload('submit');
            dispatch(getJobPostList(roleWiseFilter))
            setOpenAssignApprover(false)
        } else {
            if (type === 'accept' || (type === 'reject' && jobPost_Edit.data.approver_status === 7)) {
                dispatch(updateJobApprovalStatus({ id: formValues.id, data: addvalues }))
                setReload('submit');
                dispatch(getJobPostList(roleWiseFilter))
            }
        }

    }


    //job status handle change
    const handleChangeApproverForm = (evt) => {
        const { name, value } = evt.target || evt
        if (name === "approver_comment") {
            setReviseComment(value)
            setRejectComment(value)
        }
    }

    const closeAssignApprover = () => {
        setOpenAssignApprover(false)
    }

    const submitApprover = (e) => {
        e.preventDefault()
        if (rejectComment === "") {
            return toast.error('Comment is Required')
        } else {
            handleConfirmApproved('reject_confirm')
        }
    }

    //job status response handling
    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_jobPost_Approver_Status` && jobPost_Approver_Status && jobPost_Approver_Status.message) {
        if (jobPost_Approver_Status.status === 200) {
            toast.success(jobPost_Approver_Status.message)
            setOpenAssignApprover(false);
            setOpen(false)
            dispatch(getJobPostList(filterQuery))
        } else {
            toast.error(jobPost_Approver_Status.message)
        }
        setReload(false);

    }

    return (
        <div className="jiraStatus">
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Job Post {activeViewMode === true && "(Preview)"}</DialogTitle>
                {activeViewMode === true ?
                    <DialogContent style={{ height: "650px" }}>
                        <div>
                            <form className="regulazation-form">
                                <div>

                                    {(formValues.project_id && (formValues.project_id === '-1' || formValues.project_id.value === '-1')) ?
                                        <>
                                            <ViewField
                                                label={'Project'}
                                                key={'project_id'}
                                                type={1}
                                                value={'N/A'}
                                            />
                                        </>
                                        :
                                        <ViewField
                                            label={'Project'}
                                            key={'project_id'}
                                            type={1}
                                            value={formValues.project_id}
                                        />
                                    }
                                    <ViewField
                                        label={'Job Title'}
                                        key={'job_title'}
                                        type={1}
                                        value={formValues && formValues?.job_title && formValues.job_title.label ? formValues.job_title.label : formValues.job_title}
                                    // value={formValues.job_title}
                                    />
                                    <ViewField
                                        label={'Work Mode'}
                                        key={'work_mode'}
                                        type={1}
                                        value={formValues?.work_mode && formValues.work_mode.label ? formValues.work_mode.label : formValues.work_mode}
                                    // value={formValues.work_mode}
                                    />
                                    <ViewField
                                        label={'Qualification'}
                                        key={'education_qualification'}
                                        length={1}
                                        optionLevel={['education_qualification_name']}
                                        type={2}
                                        value={formValues?.education_qualification && formValues.education_qualification.label ? formValues.education_qualification.label : formValues.education_qualification}
                                    // value={formValues.education_qualification}
                                    />

                                    <ViewField
                                        label={'Description'}
                                        key={'editor'}
                                        fieldType={'editor'}
                                        type={1}
                                        value={formValues?.job_description && formValues.job_description.label ? formValues.job_description.label : formValues.job_description}
                                    // value={job_description}
                                    />

                                    <ViewField
                                        label={'Technology /Specialisation'}
                                        key={'job_skill'}
                                        type={'2'}
                                        length={3}
                                        optionLevel={['skill', 'experience', 'experience_type']}
                                        value={
                                            specialisation_List.length > 0 ? specialisation_List.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.value,
                                                    name: "specialistation_id"
                                                }
                                            }) : formValues.specialistation_id
                                        }
                                    // value={skills}
                                    />
                                    
                                    {/* <ViewField
                                        label={'Work Experience'}
                                        key={'work_experience'}
                                        type={3}
                                        value={formValues && formValues.job_title && formValues.job_title.label ? formValues.job_title.label : formValues.job_title}
                                        // value={[formValues.work_experience, formValues.type]}
                                    /> */}

                                    <ViewField
                                        label={'Industry'}
                                        key={'industry'}
                                        type={1}
                                        value={formValues?.industry && formValues.industry.label ? formValues.industry.label : formValues.industry}
                                    />
                                    {/* <ViewField
                                        label={'Preferred Industry'}
                                        key={'preferred_industry'}
                                        length={1}
                                        optionLevel={['industry_name']}
                                        type={2}
                                        value={formValues && formValues.preferred_industry && formValues.preferred_industry.label ? formValues.preferred_industry.label : formValues.preferred_industry}
                                        // value={formValues.preferred_industry}
                                    /> */}
                                    <ViewField
                                        label={'Hiring status'}
                                        key={'hiring_status'}
                                        type={1}
                                        // value={formValues && formValues.status && formValues.status.label ? formValues.status.label : formValues.status}
                                        value={formValues.hiring_status}
                                    />

                                </div>
                                <br />
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <button
                                            disabled={reload === true}
                                            style={reload === true ? { backgroundColor: "gray !important", minWidth: '15%' } : { minWidth: '15%' }}
                                            className="submit-modal"
                                            onClick={finalSubmit}>Confirm
                                        </button>
                                        <button style={{ minWidth: '15%', backgroundColor: 'red', marginLeft: 10 }} className="submit-modal" onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    :
                    <DialogContent>
                        <form onKeyPress={(e)=>e.key === 'Enter' && e.preventDefault()}>
                            <div className="add-new-user-form">
                                <div className="formInput">

                                    <InputSelectField
                                        label={"Project"}
                                        name={"project_id"}
                                        placeholder={"Select Project"}
                                        onChange={handleChange}
                                        defaultValue={formValues.project_id}
                                        errStatus={errStatus}
                                        formErr={formErr.project_idErr}
                                        value={formValues.project_id}
                                        optionList={ID && resourceWiseProjectList && resourceWiseProjectList.length > 0 ? resourceWiseProjectList : createNewProjectList && createNewProjectList.length > 0 ? createNewProjectList : []}
                                        optionLevel={['project_name']}
                                        optionValue={'id'}
                                    />
                                </div>
                                {
                                    createNewProject === true ?
                                        <div className="formInput">
                                            <label>Domain</label>
                                            <Select
                                                className="select"
                                                placeholder="Select Domain"
                                                defaultValue={domainId}
                                                isSearchable={true}
                                                onChange={(e) =>
                                                    setDomainId(e)}
                                                options={
                                                    mappingDomain_List?.map((option) => {
                                                        return { label: option.domain__name, value: option.domain__id, name: "domain_id" }
                                                    })
                                                }
                                            />
                                        </div>
                                        : ""
                                }
                                <div className="formInput">
                                    <label >
                                        Client
                                    </label>
                                    <Select
                                        isDisabled={formValues.client}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Client"
                                        defaultValue={formValues.client}
                                        // isSearchable={true}
                                        name={"client"}
                                        options={
                                            client_List?.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.value,
                                                    name: "client"
                                                }
                                            })
                                        }
                                        onChange={handleChangeSelect}
                                    />
                                    {errStatus === true && formValues && formValues.client === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientErr}</p>}
                                </div>

                                <InputFields
                                    disabled={true}
                                    label={"Job Title"}
                                    name="job_title"
                                    defaultValue={formValues.job_title}
                                    type="text"
                                    placeholder="Title"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.job_titleErr}
                                />

                                <div className="formInput">
                                    <InputFields
                                        disabled={true}
                                        label={'Number Of Vacancies'}
                                        name="number_of_vacancies"
                                        defaultValue={formValues.number_of_vacancies}
                                        type="number"
                                        placeholder="Number of vacancies"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formValues={formValues}
                                        formErr={formErrData.numOfVacancyErr}
                                    />
                                </div>
                                <InputMultiSelectField
                                    disabled
                                    label={"Education Qualification"}
                                    name={"education_qualification"}
                                    placeholder={"Education Qualification"}
                                    onRemove={handleMultiQualificationChange}
                                    onSelect={handleMultiQualificationChange}
                                    defaultValue={formValues.education_qualification}
                                    errStatus={errStatus}
                                    formErr={formErr.education_qualificationErr}
                                    optionList={qualification_AllList?.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []}
                                    optionLevel={['education_qualification_name']}
                                    optionValue={'id'}
                                />
                                <div className="formInput">
                                    <label >Industry </label>
                                    <Select
                                        isDisabled={formValues.industry}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={industry_List?.data && industry_List.data.rows.map((option) => {
                                            return {
                                                label: option.industry,
                                                value: option.id,
                                                name: 'industry',
                                            }
                                        })}
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Department
                                    </label>
                                    <Select
                                        isDisabled={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Department'}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.department}
                                        isSearchable={true}
                                        name={'department'}
                                        options={department_List}
                                    />
                                    {errStatus === true && formValues && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.departmentErr}</p>}
                                </div>

                                <InputMultiSelectField
                                    disablePreSelectedValues={true}
                                    disabled={true}
                                    divstyle={{ flexBasis: '100%' }}
                                    label={"Technology /Specialisation"}
                                    name={"specialistation_id"}
                                    placeholder={"Technology /Specialisation"}
                                    onRemove={addMoreTechnology}
                                    onSelect={addMoreTechnology}
                                    defaultValue={formValues.specialistation_id}
                                    errStatus={errStatus}
                                    formErr={formErr.specialisationErr}
                                    optionList={
                                        specialisation_List?.map((option) => {
                                            return {
                                                label: option.label,
                                                value: option.value,
                                                name: "specialistation_id"
                                            }
                                        })
                                    }
                                    optionLevel={['label']}
                                    optionValue={'value'}
                                />

                                <div className="formInput">
                                    <label > Type Of Vacancy </label>
                                    <Select
                                        isDisabled={formValues.type_of_vacancy}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Type of vacancy'}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.type_of_vacancy}
                                        isSearchable={true}
                                        name={'type_of_vacancy'}
                                        options={vacancy_AllList?.data && vacancy_AllList.data.rows.map((option) => {
                                            return {
                                                label: option.vacancy_type,
                                                value: option.id,
                                                name: 'type_of_vacancy',
                                                key_name: option.vacancy_type_key
                                            }
                                        })}
                                    />
                                    {errStatus === true && formValues && formValues.type_of_vacancy === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.vacancyTypeErr}</p>}
                                </div>
                                {formValues?.contract_type ?
                                    <React.Fragment>
                                        <div className="formInput">
                                            <label >
                                                Contract Type
                                            </label>
                                            <Select
                                                isDisabled={true}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                divstyle={{ flexBasis: '100%' }}
                                                placeholder={'Select Contract Type '}
                                                onChange={handleChangeSelect}
                                                defaultValue={formValues.contract_type}
                                                isSearchable={true}
                                                name={'contract_type'}
                                                options={
                                                    contracttype_AllList?.data && contracttype_AllList.data.rows.map((option) => {

                                                        return {
                                                            label: option.name,
                                                            value: option.id,
                                                            name: 'contract_type'
                                                        }
                                                    })
                                                }
                                            />
                                            {errStatus === true && formValues && formValues.contract_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.contractTypeErr}</p>}
                                        </div>
                                    </React.Fragment> : ""
                                }

                                <div className="formInput">
                                    <label >Work mode </label>
                                    <Select
                                        isDisabled={formValues.work_mode}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Work Mode'}
                                        onChange={handleChange}
                                        defaultValue={formValues.work_mode}
                                        isSearchable={true}
                                        name={'work_mode'}
                                        options={GetLocalstorage.get_default_work_mode()}
                                    />
                                    {errStatus === true && formValues && formValues.work_mode === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.work_modeErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Job Location
                                    </label>
                                    <Select
                                        isDisabled={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Job Location'}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.job_location}
                                        isSearchable={true}
                                        name={'job_location'}
                                        options={
                                            location_option_List?.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.id,
                                                    name: 'job_location'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.job_location === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.job_locationErr}</p>}
                                </div>

                                {/* <div className="formInput">
                                    <label >
                                        Urgency
                                    </label>
                                    <Select
                                        isDisabled={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Urgency '}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.urgency}
                                        isSearchable={true}
                                        name={'urgency'}
                                        options={
                                            urgencyType_AllLists && urgencyType_AllLists.map((option) => {
                                                return {
                                                    label: option.urgency_type,
                                                    value: option.id,
                                                    name: 'urgency'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.urgency === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.urgencyErr}</p>}
                                </div> */}

                                {/* <div className="formInput" style={{ marginBottom: "5px" }}>
                                    <InputFields
                                        disabled={true}
                                        label={'Job Responsibilities'}
                                        name="job_responsibilities"
                                        defaultValue={formValues.job_responsibilities}
                                        type="text"
                                        placeholder="Job Responsibilities"
                                        onChange={handleChangeText}
                                        errStatus={errStatus}
                                        formValues={formValues.job_responsibilities}
                                        formErr={formErr.jobResErr}
                                    />
                                </div> */}

                                {/* <div className="formInput">
                                    <label >
                                        Add More Specifications
                                    </label>
                                    <Select
                                        isMulti
                                        disabled
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select Specifications"
                                        defaultValue={formValues.add_more_specifications}
                                        isSearchable={true}
                                        name={"add_more_specifications"}
                                        options={
                                            specificationOptionList && specificationOptionList.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.value,
                                                    name: "add_more_specifications"
                                                }
                                            })
                                        }
                                        onChange={handleChangeOnSpecification}
                                    />
                                    {errStatus === true && formValues && formValues.add_more_specifications === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.moreSpecErr}</p>}
                                </div> */}
                                <div className="formInput"></div>

                                {/* Expected ETA section satrt */}
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <label>Expected ETA</label>
                                </div>
                                <div className="formInput" style={{ flexDirection: "row", display: "flex", flexBasis: "15%", gap: "5px" }}>
                                    <select
                                        name={"expected_ETA"}
                                        onChange={handleChangeText}
                                        value={formValues.expected_ETA}
                                    >
                                        {etaMonthArr.map((e, i) => {
                                            return (<option value={e} key={i} disabled={parseInt(e, 10) > formValues.eta_of_Arrival}>{e}</option>)
                                        })}
                                    </select>
                                    <select
                                        disabled
                                        name={"urgency_unit"}
                                        onChange={handleChangeText}
                                        value={formValues.urgency_unit?.id}
                                    >
                                        {urgencyType_AllLists?.map((e, i) => {
                                            return (<option value={e?.id} key={i}>{e?.urgency_type}</option>)
                                        })}
                                    </select>
                                </div>
                                {/* <div className="formInput" style={{ flexBasis: "15%" }}>
                                    <select
                                        name={"urgency_unit"}
                                        onChange={handleChangeText}
                                        value={formValues.urgency_unit}
                                    >
                                        {urgencyType_AllLists && urgencyType_AllLists.map((e, i) => {
                                            return (<option value={e?.id} key={i}>{e?.urgency_type}</option>)
                                        })}
                                    </select>
                                </div> */}

                                <InputMultiSelectField
                                    disablePreSelectedValues={true}
                                    // disabled={true}
                                    divstyle={{ flexBasis: '100%' }}
                                    label={"Add More Specifications"}
                                    name={"add_more_specifications"}
                                    placeholder={"Add More Specification"}
                                    defaultValue={formValues.add_more_specifications}
                                    errStatus={errStatus}
                                    formErr={formErr.moreSpecErr}
                                    optionList={
                                        specificationOptionList?.map((option) => {
                                            return {
                                                label: option.label,
                                                value: option.value,
                                                name: "add_more_specifications"
                                            }
                                        })
                                    }
                                    // onChange={handleChangeOnSpecification}
                                    onRemove={handleChangeOnSpecification}
                                    onSelect={handleChangeOnSpecification}
                                    optionLevel={['label']}
                                    optionValue={'value'}
                                />
                                <div className="formInput" style={{ flexBasis: "100%", marginBottom: "10px" }} >

                                </div>
                                {/* Total Years of Experience section start */}
                                <div className="add-new-user-form"  >
                                    <div className="formInput">
                                        <label> Total Years of Experience From</label>
                                        <select
                                            disabled
                                            name={"total_experience_from"}
                                            // onChange={handleChangeText}
                                            value={formValues.total_experience_from}
                                        >
                                            {experienceYearArr.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>

                                    <div className="formInput">
                                        <label> Total Years of Experience To</label>
                                        <select
                                            disabled
                                            name={"total_experience_to"}
                                            onChange={handleChangeText}
                                            value={formValues.total_experience_to}
                                        >
                                            {totalExperienceTo.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="formInput"></div>
                                </div>
                                {/* Total Years of Experience section end */}

                                {/* Total Years of Relevant Experience section start */}
                                <div className="add-new-user-form"  >
                                    <div className="formInput">
                                        <label>Total Years of Relevant Experience From</label>
                                        <select
                                            disabled
                                            name={"relevant_experience_expected_from"}
                                            // onChange={handleChangeText}
                                            value={formValues.relevant_experience_expected_from}
                                        >
                                            {experienceYearArr.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>

                                    <div className="formInput">
                                        <label>Total Years of Relevant Experience To</label>
                                        <select
                                            disabled
                                            name={"relevant_experience_expected_to"}
                                            // onChange={handleChangeText}
                                            value={formValues.relevant_experience_expected_to}
                                        >
                                            {relevanExperienceTo.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="formInput"></div>
                                </div>
                                {/* Total Years of Relevant Experience section end */}

                                {formValues?.hours_per_day &&
                                    <div className="formInput">
                                        <label>Hours per day</label>
                                        <select
                                            disabled
                                            name={"hours_per_day"}
                                            onChange={handleChangeText}
                                            value={formValues.hours_per_day}
                                        >
                                            {etaMonthArr.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>
                                }
                                {formValues?.expected_duration_in_months &&
                                    <div className="formInput">
                                        <label>Number of months</label>
                                        <input
                                            disabled
                                            name="expected_duration_in_months"
                                            min={0}
                                            type="number"
                                            onChange={handleChangeText}
                                            placeholder="Number of months"
                                            defaultValue={formValues.expected_duration_in_months}
                                        />
                                    </div>
                                }

                                <InputEditorField
                                    label={"Description"}
                                    defaultValue={formValues.job_description}
                                    name="job_description"
                                    onChange={inputHandler}
                                    placeholder="Description"
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                {tmsRole && (tmsRole.role_key === "admin" || tmsRole.role_key === "hr") &&
                                    <React.Fragment>
                                        <div className="formInput">
                                            <InputFields
                                                label={'Salary Budget From'}
                                                name="client_budget_from"
                                                defaultValue={formValues.client_budget_from_new}
                                                type="number"
                                                placeholder="Salary Budget From"
                                                onChange={handleChangeText}
                                                errStatus={errStatus}
                                                formValues={formValues.client_budget_from_new}
                                                formErr={formErr.budgetFormErr}
                                            />
                                        </div>
                                        <div className="formInput">
                                            <InputFields
                                                label={'Salary Budget To'}
                                                name="client_budget_to"
                                                defaultValue={formValues.client_budget_to_new}
                                                type="number"
                                                placeholder="Salary Budget To"
                                                onChange={handleChangeText}
                                                errStatus={errStatus}
                                                formValues={formValues.client_budget_to_new}
                                                formErr={formErr.budgetToErr}
                                            />
                                        </div>
                                    </React.Fragment>
                                }

                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />

                                {/* <InputMultiSelectField
                                    divstyle={{ flexBasis: '100%' }}
                                    label={"Skill"}
                                    name={"job_skill"}
                                    placeholder={"Skill"}
                                    onRemove={addMoreSkill}
                                    onSelect={addMoreSkill}
                                    defaultValue={formValues.job_skill}
                                    errStatus={errStatus}
                                    formErr={formErr.job_skillErr}
                                    optionList={skill_AllList && skill_AllList.data && skill_AllList.data.rows ? skill_AllList.data.rows : []}
                                    optionLevel={['skill_name']}
                                    optionValue={'id'}
                                /> */}
                                {/* <div className="formInput">
                                    <label >
                                        Required Skill Set
                                    </label>
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select Required skill set"
                                        defaultValue={formValues.required_skill_set}
                                        isSearchable={true}
                                        name={"required_skill_set"}
                                        options={
                                            skils && skils.map((option) => {
                                                return { label: option.label, value: option.value, name: "required_skill_set" }
                                            })
                                        }
                                        onChange={handleChangeOnSkill}
                                    />
                                    {errStatus === true && formValues && formValues.required_skill_set === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.skillSetErr}</p>}
                                </div> */}
                                                                {/* <div className="formInput" style={{ flexBasis: '100%' }}>
                                    <span style={{ display: "flex", alignItems: "center" }} >Other Skill (optional) <AddCircleOutline onClick={() => addOtherSkill()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} /> </span>
                                </div> */}
                                

                                {/* <div className="formInput">
                                        <InputSelectField
                                            disabled={true}
                                            label={"Industry "}
                                            name={"industry"}
                                            placeholder={"Industry"}
                                            onChange={handleChangeSelect}
                                            defaultValue={formValues.industry}
                                            errStatus={errStatus}
                                            formErr={formErr.industryErr}
                                            optionList={industry_List}
                                            optionLevel={['industry_name']}
                                            optionValue={'id'}
                                        />
                                    </div> */}

                                {/* <InputMultiSelectField
                                    label={"Preferred Industry"}
                                    name={"preferred_industry"}
                                    placeholder={"Preferred Industry"}
                                    onRemove={handleMultiIndustryChange}
                                    onSelect={handleMultiIndustryChange}
                                    defaultValue={formValues.preferred_industry}
                                    optionList={industry_List}
                                    optionLevel={['industry_name']}
                                    optionValue={'id'}
                                /> */}

                                {/* <div className="formInput">
                                    <label >
                                        Preferred Location
                                    </label>
                                    <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Select Preferred Location"
                                        defaultValue={formValues.preferred_location}
                                        isSearchable={true}
                                        name={"preferred_location"}
                                        options={
                                            location_option_List && location_option_List.map((option) => {
                                                return {
                                                    label: option.label,
                                                    value: option.id,
                                                    name: "preferred_location"
                                                }
                                            })
                                        }
                                        onChange={handleChangeOnLocationcation}
                                    />
                                    {errStatus === true && formValues && formValues.preferred_location === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrData.preferredLocationErr}</p>}
                                </div> */}


                                {/* edit */}
                                <div className="add-new-user-form" style={{ marginBottom: '10px' }}>
                                    {formValues?.attachment && formValues.attachment.length > 0 ? formValues.attachment.map((item, i) => {

                                        return <>
                                            <div className="uploaded-files-list" style={{ flexBasis: "40%" }}>
                                                <div key={i}>
                                                    <ul>
                                                        <li>
                                                            <span>{item && item.substring(0, 35) + "..."}</span>

                                                            <Tooltip title="Download">
                                                                <button onClick={() => fileDownload(item)}>
                                                                    <Download style={{ backgroundColor: "gray" }} />
                                                                </button>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="uploaded-files-list" style={{ flexBasis: "40%" }}></div>
                                        </>

                                    })
                                        :
                                        <p>No Attachment</p>
                                    }
                                </div>

                            </div>


                            {tmsRole && tmsRole.role_key === "admin" && user.is_approver === true && jobPost_Edit?.data?.approver_status === 7 &&
                                <div className="add-new-user-form">
                                    <div className='formInput' style={{ flexBasis: "100%" }}>
                                        <label>Comment </label>
                                        <textarea
                                            className="select"
                                            placeholder="Comment"
                                            onChange={handleChangeApproverForm}
                                            name="approver_comment"
                                        />
                                    </div>
                                </div>
                            }

                            {tmsRole && (tmsRole.role_key === "admin" && user.is_approver === true) && jobPost_Edit?.data?.approver_status === 7 &&
                                <div className="add-new-user-form">
                                    {revised_Salary_List?.data?.rows?.length > 0 ?
                                        <BudgetLog data={revised_Salary_List?.data?.rows ? revised_Salary_List?.data?.rows : []} />
                                        : ""}
                                </div>
                            }

                            <div class="add-new-user-from" style={{ display: "flex", justifyContent: "space-between" }}>
                                {tmsRole && tmsRole.role_key === "admin" && user.is_approver === true ?
                                    <div>
                                        <Button className="submit-modal" onClick={() => handleConfirmApproved('accept')}>Confirm & Approve</Button> &nbsp;&nbsp;
                                        <Button className="submit-modal" onClick={() => handleConfirmApproved('reject')}>Reject</Button>
                                    </div>
                                    :
                                    <Button className="submit-modal" onClick={submitData}>{ID ? 'Preview and Update' : 'Preview And Submit'}</Button>
                                }
                            </div>

                        </form>
                    </DialogContent>
                }
            </Dialog>

            {/*  assign approver form */}
            {openAssignApprover === true ?
                <ApproverForm
                    approverList={resourceListDropDown}
                    handleClose={closeAssignApprover}
                    handleChange={handleChangeApproverForm}
                    submitData={submitApprover}
                    open={openAssignApprover}
                    fieldType={'reject'}
                />
                : ""
            }
        </div >
    )
}

export default JobPost