import "./sidebar.scss"
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {  useNavigate } from "react-router-dom";
import {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
// import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import LocalStorageDataService from '../common-function/GetLocalstorage';
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";
import { Box} from "@mui/material";
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
// import WorkIcon from '@mui/icons-material/Work';
// import SourceIcon from '@mui/icons-material/Source';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import CoPresentIcon from '@mui/icons-material/CoPresent';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
// import WavingHandIcon from '@mui/icons-material/WavingHand';
// import PortraitIcon from '@mui/icons-material/Portrait';
// import LanIcon from '@mui/icons-material/Lan';
// import StarsIcon from '@mui/icons-material/Stars';
// import CircleIcon from '@mui/icons-material/Circle';
// import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
// import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
// import FactCheckIcon from '@mui/icons-material/FactCheck';
// import BadgeIcon from '@mui/icons-material/Badge';
// import GroupsIcon from '@mui/icons-material/Groups';
// import EngineeringIcon from '@mui/icons-material/Engineering';
// import TokenIcon from '@mui/icons-material/Token';
// import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
// import Groups3Icon from '@mui/icons-material/Groups3';
// import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
// import HubIcon from '@mui/icons-material/Hub';
// import SquareIcon from '@mui/icons-material/Square';
// import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import Diversity1Icon from '@mui/icons-material/Diversity1';
// import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
// import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
// import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import EventAvailableIcon from '@mui/icons-material/EventAvailable';
// import TtyIcon from '@mui/icons-material/Tty';
// import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
// import VideoCallIcon from '@mui/icons-material/VideoCall';
// import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
// import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
// import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
// import LineAxisIcon from '@mui/icons-material/LineAxis';
// import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
// import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
// import UpdateIcon from '@mui/icons-material/Update';
// import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

let user = LocalStorageDataService.userDetail();
let tmsRole = user?.tms_role
const tmsRoleId = user?.tms_role_id
// const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

const Sidebar = () => {
  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  const [urlPath, setUrlPath] = useState();
  const [childUrlPath, setChildUrlPath] = useState();
  const [parentUrlPath, setParentUrlPath] = useState();
  const { sidebarMenuList } = useSelector((state) => state.sidebar);
  const [toggle, setToggle] = useState(true);
  const [showSideBar, setShowSidebar] = useState(false)
  // const dispatchtheme = useDispatch()
  // const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize(getCurrentDimension())
  //   }
  //   window.addEventListener('resize', updateDimension);

  //   return (() => {
  //     window.removeEventListener('resize', updateDimension);
  //   })
  // }, [screenSize])

  // useEffect(() => {
  //   if (screenSize.width <= 985) {
  //     // setToggle(true)
  //     setShowSidebar(true)
  //   } else {
  //     // setToggle(false)
  //     setShowSidebar(false)
  //   }
  // }, [screenSize])


  const dispatch = useDispatch();
  const navigate = useNavigate()

  // const logout_user = useCallback(() => {
  //   dispatch(logout());
  //   window.location.reload('/');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  useEffect(() => {
    if (authLoggedIn === false && logoutUser && logoutUser.message) {
      navigate('/')
    }
  }, [navigate, logoutUser])



  // const { data } = useSelector((state) => state.navbartoggle);

  const fetchUrlPath = () => {
    const menuData = JSON.parse(localStorage.getItem('menuData'));
    const path = window.location.href;
    let pathArr = path.split('/');
    pathArr = pathArr.splice(3);
    const propertyName = pathArr.join('/');
    const currentMenu = document.getElementById(propertyName);
    setChildUrlPath(propertyName);
    if (menuData !== null) {
      const propertyObject = menuData.data.rows.filter((item) => item.menu === propertyName)[0];
      if (propertyObject) {
        if (propertyObject.parent_menu) {
          setUrlPath(propertyObject.parent_menu);
          currentMenu?.parentNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          const parentPropertyObject = menuData.data.rows.filter((item) => item.menu === propertyObject.parent_menu)[0];

          if (parentPropertyObject?.parent_menu)
            return setParentUrlPath(parentPropertyObject?.parent_menu)

          return setParentUrlPath(parentPropertyObject?.menu)
        }
        currentMenu?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        return setParentUrlPath(propertyObject?.menu);
      }
    }
  };

  function isVisible(arr) {
    const res = [];
    arr?.map((submenusItem) => {
      if (!submenusItem.menu?.url_slug.match("edit") && !submenusItem.menu?.url_slug.match("view")) {
        // --------------------------------
        if (submenusItem.menu.url_slug === 'my-intervie-ws' || submenusItem.menu.url_slug === 'manage-availability') {
          if (tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || tmsRole.role_name === 'Agent' || user.is_interviewer === true) {
            res.push(submenusItem)
          }
        } else {
          res.push(submenusItem);
        }
      }
    })
    return res;
  }

  useEffect(() => fetchUrlPath(), [sidebarMenuList, dispatch])

  useEffect(() => {
    dispatch(getMenuListForSideBar(tmsRoleId))
  }, [dispatch])


  //implement parent menu toggle functionality
  const openCloseMenu = (pathLink) => {
    (parentUrlPath === pathLink) ? setParentUrlPath('') : setParentUrlPath(pathLink);
  }
  const openCloseSubmenu = (e, pathLink) => {
    e.stopPropagation();
    (urlPath === pathLink) ? setUrlPath('') : setUrlPath(pathLink);
  }

  const [activeClass, setActiveClass] = useState('')
  const [openSubMenu, setOpenSubMenu] = useState(null)
  return (

    // dynamic side bar menu code using api 

    <Box sx={{
      position: "sticky",
      display: showSideBar ? "flex" : "none",
      height: "100vh",
      top: 0,
      bottom: 0,
      zIndex: 1000,
      height: '94vh'
    }}>

    </Box>
    // <Box
    //   sx={{
    //     position: "sticky",
    //     display: showSideBar ? "flex" : "none",
    //     height: "100vh",
    //     top: 0,
    //     bottom: 0,
    //     zIndex: 1000,
    //     height: '94vh'
    //   }}>
    //   <ProSidebar collapsed={toggle}>
    //     <Menu>
    //       {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
    //         return (
    //           item?.submenu.length === 0 ?
    //             <Tooltip title={item.menu?.title} placement='right'>
    //               {/* item.menu?.url_slug === parentUrlPath ? "active" : ""  */}
    //               {/* className={activeClass === item.menu?.url_slug ? 'active' : ''} */}
    //               <MenuItem id={item.menu?.url_slug} key={index}
    //                 onClick={() => { setActiveClass(item.menu?.url_slug); setOpenSubMenu(index) }} active={activeClass === item.menu?.url_slug} icon={
    //                   item?.menu?.class_name === 'DashboardIcon' ? <DashboardIcon /> :
    //                     item?.menu?.class_name === 'EpicsIcon' ? <AssignmentIndIcon /> :
    //                       // item.menu?.class_name === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
    //                       item?.menu?.class_name === 'MyProjectsIcon' ? <WorkIcon /> :
    //                         item?.menu?.class_name === 'MyCredentialsIcon' ? <SourceIcon /> :
    //                           item?.menu?.class_name === 'ProfileIcon' ? <AccountCircleIcon /> :
    //                             <UpdateIcon />}>

    //                 {tmsRole && (tmsRole.role_key?.match('hr') || tmsRole.role_key?.match('agent')) && item.menu?.url_slug.match('my-jobs') ? "My Jobs" :
    //                   tmsRole && (tmsRole.role_key !== 'hr' || tmsRole.role_key !== 'agent') && item.menu?.url_slug.match('my-jobs') ? "Epics" :
    //                     item.menu?.title
    //                 }
    //                 <Link to={"/" + item.menu?.url_slug} />
    //               </MenuItem>
    //             </Tooltip>

    //             : item?.submenu.length > 0 &&
    //             <SubMenu key={index} title={item.menu?.title}
    //               // onClick={() => { openCloseMenu(item.menu?.url_slug) }}
    //               // open={item.menu?.url_slug === parentUrlPath}
    //               open={openSubMenu === index}
    //               onClick={() => { setOpenSubMenu(index) }}
    //               onDoubleClick={() => { setOpenSubMenu(null) }}
    //               icon={
    //                 item.icon === 'DashboardIcon' ? <DashboardIcon /> :
    //                   item.menu?.class_name === 'TaskIcon' ? <AssignmentIcon /> :
    //                     item?.menu?.class_name === 'AdminIcon' ? <AdminPanelSettingsIcon /> :
    //                       item?.menu?.class_name === 'HrIcon' ? <CoPresentIcon /> :
    //                         item?.menu?.class_name === 'RecruitmentIcon' ? <PersonSearchIcon /> :
    //                           item?.menu?.class_name === 'ReportsIcon' ? <AssessmentIcon /> : <UpdateIcon />}>
    //               {item.submenu.map((subItem, subIndex) => {
    //                 if (!subItem.menu?.url_slug.match('edit') && !subItem.menu?.url_slug.match('view')) {
    //                   return (
    //                     subItem.submenu.length === 0 ?
    //                       <Tooltip title={subItem.menu?.title} placement="right">
    //                         <MenuItem onClick={() => { setActiveClass(subItem.menu?.url_slug) }} className={activeClass === subItem.menu?.url_slug ? 'active' : ''}
    //                           icon={
    //                             subItem?.menu?.class_name === 'AllTaskIcon' ? <ListAltOutlinedIcon /> :
    //                               subItem?.menu?.class_name === 'MyTaskIcon' ? <PortraitIcon /> :
    //                                 subItem?.menu?.class_name === 'myWorkHistoryIcon' ? <WorkHistoryOutlinedIcon /> :
    //                                   subItem?.menu?.class_name === 'AddNewTaskIcon' ? <FactCheckIcon /> :
    //                                     subItem?.menu?.class_name === 'CoreSettingsIcon' ? <HubIcon /> :
    //                                       subItem?.menu?.class_name === 'EmailTemplateIcon' ? <MarkEmailUnreadIcon /> :
    //                                         subItem?.menu?.class_name === 'SprintIcon' ? <DirectionsRunIcon /> :
    //                                           subItem?.menu?.class_name === 'JobPostIcon' ? <WorkHistoryIcon /> :
    //                                             subItem?.menu?.class_name === 'DefaultScreeningTestIcon' ? <QueuePlayNextIcon /> :
    //                                               subItem?.menu?.class_name === 'UploadResumeIcon' ? <ContactPhoneIcon /> :
    //                                                 subItem?.menu?.class_name === 'UpLoadReportIcon' ? <UploadFileIcon /> :
    //                                                   subItem?.menu?.class_name === 'DailyAttendanceIcon' ? <EditCalendarIcon /> :
    //                                                     subItem?.menu?.class_name === 'MonthlyAttendanceIcon' ? <CalendarMonthIcon /> :
    //                                                       subItem?.menu?.class_name === 'MonthlyAttendanceIcon' ? <CalendarMonthIcon /> :
    //                                                         subItem?.menu?.class_name === 'AttendanceReportStatusIcon' ? <EventAvailableIcon /> :
    //                                                           subItem?.menu?.class_name === 'AgentCallPerformanceIcon' ? <TtyIcon /> :
    //                                                             subItem?.menu?.class_name === 'ResumeAnalysisIcon' ? <ContentPasteSearchIcon /> :
    //                                                               subItem?.menu?.class_name === 'AllZoomCallIcon' ? <VideoCallIcon /> :
    //                                                                 subItem?.menu?.class_name === 'AllPhoneCallDetailsIcon' ? <PermPhoneMsgIcon /> :
    //                                                                   subItem?.menu?.class_name === 'MyPhoneCallDetailsIcon' ? <PhoneCallbackIcon /> :
    //                                                                     subItem?.menu?.class_name === 'HolidayListIcon' ? <FactCheckIcon /> : <UpdateIcon />
    //                           } id={subItem.menu?.url_slug} key={subIndex}>
    //                           {subItem.menu?.title}
    //                           <Link to={'/' + subItem.menu?.url_slug} />
    //                         </MenuItem>
    //                       </Tooltip>

    //                       : subItem.submenu.length > 0 && isVisible(subItem.submenu)?.length > 0 &&
    //                       <SubMenu icon={
    //                         // subItem?.menu?.class_name === 157 ? <WavingHandIcon /> :
    //                         subItem?.menu?.class_name === 'ManageProjectIcon' ? <LanIcon /> :
    //                           subItem?.menu?.class_name === 'SovernIcon' ? <StarsIcon /> :
    //                             subItem?.menu?.class_name === 'ManageJiraIcon' ? <SquareIcon style={{ transform: 'rotate(45deg)' }} /> :
    //                               subItem?.menu?.class_name === 'ManageWorkFlowIcon' ? <AccountTreeIcon /> :
    //                                 subItem?.menu?.class_name === 'MastersIcon' ? <EngineeringIcon /> :
    //                                   subItem?.menu?.class_name === 'AttendanceIcon' ? <AppRegistrationIcon /> :
    //                                     subItem?.menu?.class_name === 'ManageShiftIcon' ? <HowToRegOutlinedIcon /> :
    //                                       subItem?.menu?.class_name === 'EvaluationPanelIcon' ? <GroupsIcon /> :
    //                                         subItem?.menu?.class_name === 'ManageHolidayIcon' ? <HolidayVillageOutlinedIcon /> :
    //                                           subItem?.menu?.class_name === 'MasterMappingIcon' ? <TokenIcon /> :
    //                                             subItem?.menu?.class_name === 'ManageResourceCredentialIcon' ? <PrecisionManufacturingIcon /> :
    //                                               subItem?.menu?.class_name === 'MasterStatusIcon' ? <LineAxisIcon /> :
    //                                                 subItem?.menu?.class_name === 'ManageUserTypeIcon' ? <Groups3Icon /> :
    //                                                   subItem?.menu?.class_name === 'ManageRoleIcon' ? <EscalatorWarningIcon /> :
    //                                                     subItem?.menu?.class_name === 'ManageDepartmentIcon' ? <Diversity3Icon /> :
    //                                                       subItem?.menu?.class_name === 'ManageClientIcon' ? <Diversity1Icon /> :
    //                                                         subItem?.menu?.class_name === 'HiringRequestIcon' ? <SettingsAccessibilityIcon /> :
    //                                                           subItem?.menu?.class_name === 'ScheduleInterviewIcon' ? <InterpreterModeIcon /> :
    //                                                             subItem?.menu?.class_name === 'ResourcesIcon' ? <BadgeIcon /> : <UpdateIcon />
    //                       } key={subIndex} title={subItem.menu?.title}
    //                       // onClick={(e) => { openCloseSubmenu(e, subItem.menu?.url_slug) }} open={subItem.menu?.url_slug === urlPath}
    //                       >
    //                         {subItem.submenu.map((submenusItem, submenusIndex) => {
    //                           if (!submenusItem.menu?.url_slug.match("edit") && !submenusItem.menu?.url_slug.match("view")) {
    //                             // --------------------------------
    //                             return ((submenusItem.menu.url_slug === 'my-intervie-ws' || submenusItem.menu.url_slug === 'all-availability' || submenusItem.menu.url_slug === 'all-intervie-ws') ?
    //                               ((tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || tmsRole.role_name === 'Agent') || user.is_interviewer === true ?
    //                                 <Tooltip placement="right" title={submenusItem.menu?.title}>
    //                                   <MenuItem onClick={() => { setActiveClass(submenusItem.menu?.url_slug) }}
    //                                     // className={activeClass === submenusItem.menu?.url_slug ? 'active' : ''}
    //                                     icon={<CircleIcon fontSize="18px" />} id={submenusItem.menu?.url_slug} key={submenusIndex}
    //                                     // active={childUrlPath === submenusItem.menu?.url_slug}
    //                                     active={activeClass === submenusItem.menu?.url_slug}
    //                                   >
    //                                     {submenusItem.menu?.title}
    //                                     {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
    //                                   </MenuItem>
    //                                 </Tooltip>
    //                                 :
    //                                 null) :
    //                               submenusItem.submenu.length == 0 &&

    //                               <Tooltip title={submenusItem.menu?.title} placement="right">
    //                                 <MenuItem onClick={() => { setActiveClass(submenusItem.menu?.url_slug) }}
    //                                   // className={childUrlPath === submenusItem.menu?.url_slug ? 'active' : ''} 
    //                                   active={activeClass === submenusItem.menu?.url_slug}
    //                                   icon={<CircleIcon fontSize="18px" />} id={submenusItem.menu?.url_slug} key={submenusIndex}>
    //                                   {submenusItem.menu?.title}
    //                                   {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
    //                                 </MenuItem>
    //                               </Tooltip>

    //                               ||

    //                               submenusItem.submenu.length > 0 && isVisible(submenusItem.submenu)?.length > 0 &&
    //                               <SubMenu icon={<CircleIcon fontSize="18px" />} key={subIndex} title={submenusItem.menu?.title}
    //                               // onClick={(e) => { openCloseSubmenu(e, submenusItem.menu?.url_slug) }} open={submenusItem.menu?.url_slug === urlPath}
    //                               >
    //                                 {submenusItem.submenu.map((submenusItems, submenusIndex) => {
    //                                   if (!submenusItems.menu?.url_slug.match("edit") && !submenusItems.menu?.url_slug.match("view")) {
    //                                     // --------------------------------
    //                                     return ((submenusItems.menu.url_slug === 'my-intervie-ws' || submenusItems.menu.url_slug === 'all-availability' || submenusItems.menu.url_slug === 'all-intervie-ws') ?
    //                                       ((tmsRole.role_name === 'Admin' || tmsRole.role_name === 'HR' || tmsRole.role_name === 'Agent') || user.is_interviewer === true ?
    //                                         <Tooltip placement="right" title={submenusItems.menu?.title} key={submenusIndex}>
    //                                           <MenuItem onClick={() => { setActiveClass(submenusItems.menu?.url_slug) }} icon={<AdjustOutlinedIcon fontSize="17px" />} id={submenusItems.menu?.url_slug} active={activeClass === submenusItems.menu?.url_slug}>
    //                                             {submenusItems.menu?.title}
    //                                             {submenusItems.menu?.url_slug && <Link to={"/" + submenusItems.menu?.url_slug}></Link>}
    //                                           </MenuItem>
    //                                         </Tooltip>
    //                                         :
    //                                         null) :
    //                                       <Tooltip title={submenusItems.menu?.title} placement="right" key={submenusIndex}>
    //                                         <MenuItem onClick={() => { setActiveClass(submenusItems.menu?.url_slug) }} icon={<AdjustOutlinedIcon fontSize="17px" />} id={submenusItems.menu?.url_slug} active={activeClass === submenusItems.menu?.url_slug}>
    //                                           {submenusItems.menu?.title}
    //                                           {submenusItems.menu?.url_slug && <Link to={"/" + submenusItems.menu?.url_slug}></Link>}
    //                                         </MenuItem>
    //                                       </Tooltip>

    //                                     )

    //                                     // --------------------------------
    //                                     // return (
    //                                     //   <Tooltip title={submenusItem.menu?.title}>
    //                                     //     <MenuItem id={submenusItem.menu?.url_slug} key={submenusIndex} active={urlPath === submenusItem.menu?.url_slug}>
    //                                     //       {submenusItem.menu?.title}
    //                                     //       {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
    //                                     //     </MenuItem>
    //                                     //   </Tooltip>
    //                                     // )
    //                                   }
    //                                   return true;
    //                                 })
    //                                 }

    //                               </SubMenu>

    //                             )
    //                             // --------------------------------
    //                             // return (
    //                             //   <Tooltip title={submenusItem.menu?.title}>
    //                             //     <MenuItem id={submenusItem.menu?.url_slug} key={submenusIndex} active={urlPath === submenusItem.menu?.url_slug}>
    //                             //       {submenusItem.menu?.title}
    //                             //       {submenusItem.menu?.url_slug && <Link to={"/" + submenusItem.menu?.url_slug}></Link>}
    //                             //     </MenuItem>
    //                             //   </Tooltip>
    //                             // )
    //                           }
    //                           return true;
    //                         })
    //                         }

    //                       </SubMenu>
    //                     // <MenuItem key={subIndex}>
    //                     //   {subItem.menu?.title}
    //                     //   <Link to={"/" + subItem.menu?.url_slug} />
    //                     // </MenuItem>
    //                   )
    //                 }
    //                 return true;
    //               })}
    //             </SubMenu>
    //           // </Tooltip>
    //         )
    //       }
    //       )}
    //       {/* {sidebarMenuList && sidebarMenuList.length > 0 &&
    //         <Button sx={{
    //           marginTop: '5px',
    //           background: '#2b548a',
    //           '&:hover': {
    //             backgroundColor: '#2b548a',
    //           },
    //         }} fullWidth onClick={() => {
    //           setToggle(!toggle)
    //           // dispatchtheme(navbartoggle(toggle))
    //         }} variant="contained" endIcon={!toggle ? <>
    //           <ArrowBackIosNewIcon className="toggle-openClose-icon" sx={{
    //             position: "absolute", right: "13px", top: "8px"
    //           }} /></> : <ArrowForwardIosIcon className="toggle-openClose-icon" />}>
    //           {!toggle ? <Typography variant="inherit">Close</Typography> : <Typography variant="inherit">Open</Typography>}
    //         </Button>
    //       } */}
    //     </Menu>
    //   </ProSidebar >
    // </Box >
  )
}

export default Sidebar