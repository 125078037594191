import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Switch, TablePagination, Tooltip, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyCreate, CurrencyEdit, CurrencyUpdate, getCurrencyList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { DataGridPro, gridClasses, getGridStringOperators } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const initialFormData = Object.freeze({
    currency_name: "",
    currency_code: "",
    status: true,
});

const formErrData = Object.freeze({
    currencyNameErr: "Currency name is required",
    currencyCodeErr: "Currency code is required",
    statusErr: "Status is required"
});

const skeletonBase = { currency_name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', currency_code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

const filterableColumns = ['currency_name', 'currency_code', 'status'];

function PaperComponent(props) {
    return (
        <Draggable
            bounds='body'
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Currency = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, currency_List, currency_Create, currency_Edit, currency_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    const [gridRows, setGridRows] = useState([]);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));

    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null)
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});

    let stopOnRowScrollEnd = useRef(false)
    let clearGridListing = useRef(false);
    let editID = useRef(null)
    let gridTotalRows = useRef(null)
    let isAnyChange = useRef({});

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getCurrencyList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)

            setReload(false)
        }
    }, [filterQuery])

    const handleClose = () => {
        if (ID) { 
            setID(0); 
            setFormValues(initialFormData) 
        } 
        setOpen(false); 
        setFormValues(initialFormData);
        setErrStatus(false);
        setContextMenu(null)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_currency_Update` && currency_Update && currency_Update.message) {
        if (currency_Update.status === 200) {
            let copyGridRows = [...gridRows].map(item => {
                if(editID?.current?.id === item?.id)
                    return { ...currency_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows);
            handleClose();
            toast.success(currency_Update.message)
            // let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            // setFilterQuery(str)
            // dispatch(getCurrencyList(filterQuery))
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(currency_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_currency_Create` && currency_Create && currency_Create.message) {
        if (currency_Create.status === 200) {
            toast.success(currency_Create.message)
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
            // dispatch(getCurrencyList(filterQuery))
        } else {
            toast.error(currency_Create.message)
        }
        setReload(false)
    }
    if (reload === true && status === STATUSES.SUCCESS && currency_List && currency_List.message) {
        if (currency_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...currency_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(currency_List.data.count);
            }
            else {
                setGridRows((pre) => [...pre, ...currency_List.data.rows]);
                SET_MAX_ROW_LENGTH(currency_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(currency_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    const handleOpen = () => setOpen(true);

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        // setFormValues({ ...formValues, [name]: value })
        if (name === 'status') {
            setFormValues({ ...formValues, [name]: checked })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.currency_name === "" || formValues.currency_code === "" ||  formValues.status === "") {
            setErrStatus(true)
            return;
        }
        clearGridListing.current = true;
        if (formValues.currency_name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item?.id === ID);
                let index = copyRows.findIndex(item=>item?.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(CurrencyUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                setGridRows([]);
                dispatch(CurrencyCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && currency_Edit && currency_Edit.data) {
        let editData = currency_Edit.data
        setFormValues({
            currency_name: editData.currency_name,
            currency_code: editData.currency_code,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(CurrencyEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            currency_name: data.currency_name,
            currency_code: data.currency_code,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(CurrencyUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCurrencyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCurrencyList(JSON.stringify(newFilterData)))
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(currency_List && currency_List.data && currency_List.data.rows && currency_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    // const handleSort = (sort) => {
    //     if (sort.length) {
    //         let newFilterData = JSON.parse(filterQuery);
    //         newFilterData['skip'] = 0;
    //         newFilterData['limit'] = limit;
    //         newFilterData['order_by'] = {
    //             "column": sort[0].sort === "desc" ?
    //                 '-' + sort[0].field : sort[0].field
    //         }
    //         setFilterQuery(JSON.stringify(newFilterData))
    //         dispatch(getCurrencyList(JSON.stringify(newFilterData)))
    //     } else {
    //         setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}] }`)
    //     }
    // }
    // }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        // newFilterData['limit'] = gridRows.length;
        newFilterData['limit'] = 25;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);

    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    const filterOperators = getGridStringOperators().filter(({value}) => ['contains', 'equals', 'startsWith', 'endsWith'].includes(value))

    const actionColumn = [
        {
            field: "currency_name",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Currency Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("currency_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.currency_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <div>
                            {params.row.currency_name}
                        </div>
                );
            },
        },
        {
            field: "currency_code",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Currency Code</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("currency_code")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.currency_code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                    <div>
                        {params.row.currency_code}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem' }} /></button></div>,
            minWidth: 160,
            sortable: false,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus ${params.row.status}`}>
                    //     {params.row.status === true ? 'Active' : 'Inactive'}
                    // </div>
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="specialization"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)}
                            />
                        </div>
                    </div >
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     minWidth: "120",
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName="ArchiveIcon"
        //                     moduleName="currency"
        //                     rowid={params.row.id}
        //                     addMoreData=""
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     isDelete={true}
        //                     editButton={handleClickEdit}
        //                     viewButton={false}
        //                     deleteButton={() => handleArchive(params.row)} />
        //             </div>
        //         );
        //     },
        // },
    ];

    // const onFilterChange = (filterModel, r) => {

    //     if (filterModel.items.length) {
    //         setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
    //     }

    //     if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
    //         setReload(true);
    //         setTimeout(() => {
    //             setReload(false)
    //             setCurrentPage(0)
    //             setFilterQuery(`{"order_by":{"column":"-status"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`)
    //             dispatch(getCurrencyList(`{"order_by":{"column":"-status"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`))
    //         }, 0);
    //     }

    //     if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
    //         let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
    //             filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
    //                 filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
    //         const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-status"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`
    //         setFilterQuery(filterData)
    //         setCurrentPage(0)
    //         dispatch(getCurrencyList(filterData))
    //     }
    // }

    const onFilterChange = (props) =>
    {
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":2,"columns":[${DefaultFilterColumns.CURRENCY_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.CURRENCY_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'startswith',
            endsWith: 'endswith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    {/* <div className="top-headings">
                        <h3>Currency</h3>
                        <div>
                            <Tooltip title=" Currency">
                                <Button variant="outlined" onClick={handleOpen}>
                                    Create<BorderColorTwoToneIcon />
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getCurrencyList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 20 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 

                        </div>
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Currency</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getCurrencyList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                            </Stack>
                        </div>
                    </div>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}  
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload && */}
                        <DataGridPro
                            className="datagrid"
                            filterMode="server"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            // rows={currency_List?.data?.rows ? currency_List.data.rows : []}
                            rows={gridRows?.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            sx={{
                                height: 700,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#438bf969",
                                },
                                ".actionButton": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".actionButton": {
                                            display: 'block'
                                        }
                                    }
                                }
                            }}
                            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnMenuIcon: MoreHorizIcon
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                        />
                    {/* } */}
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Currency </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Currency Name"}
                                    name="currency_name"
                                    defaultValue={formValues.currency_name}
                                    type="text"
                                    placeholder="Currency Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.currencyNameErr}
                                />

                                <InputFields
                                    label={"Currency Code"}
                                    name="currency_code"
                                    defaultValue={formValues.currency_code}
                                    type="text"
                                    placeholder="Currency Code"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.currencyCodeErr}
                                />

                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className={reload === true ? 'btn-disable' : 'submit-modal'} disabled={reload === true ? true : false} onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Currency`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    {/* {<MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem>  */}
                </Menu>
            </div>
        </div >
    )
}

export default Currency;