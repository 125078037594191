import React, { useEffect, useState } from "react";
import './part-one.scss'
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import { InputFields } from "../../../common-function/InputFields";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getAllScreeningMasterList, hiringRequestUpdate } from "../../../../redux/features/masterSlice";
import CreatableSelect from 'react-select/creatable';
import GetLocalstorage from "../../../common-function/GetLocalstorage";
import SmallLoader from "../../../common-function/SmallLoader";
import { candidateTestCreate, getScreenTestList, questionlevelList, questionTypeList, saveScreenFlowData } from "../../../../redux/features/hiringRequestSlice";
import { useNavigate, useParams } from "react-router-dom";
import dataService from "../../../../redux/services/data.service";

import PartTwo from "./PartTwo";
import { STATUSES } from "../../../../redux/common-status/constant";

const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role && user.tms_role?.role_key

const initialPredefinedFormData = Object.freeze({
    predefined_questions: {
        no_of_predefined_questions: "",
        already_selected_question: [],
        newly_created_questions: []
    }
})

const getTestScreeing = GetLocalstorage.get_test_screening();
const getManagedBy = GetLocalstorage.get_managed_by_test();

const initialFormData = Object.freeze({
    "random_questions": {
        "no_of_random_question": null,
        "technologies": [
            {
                "technology_key": null,
                "question_type_details": {
                    // "mcq": 0,
                    // "programming": 0,
                    // "descriptive": 0
                }
            }
        ]
    }
})

function PartOne() {

    // PART2 STATE
    const [formData, setFormData] = useState(initialFormData)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status, questionType_List, candidate_test_Create, screen_flow_Create, screen_flow_test_count, test_status } = useSelector(state => state.hiringRequest)
    const [preFormData, setPreFormData] = useState(initialPredefinedFormData)
    const { hiringid } = useParams();
    const [formValues, setFormValues] = useState({})
    const { screeningmaster_AllList } = useSelector(state => state.master)
    const { questionlevel_list } = useSelector(state => state.hiringRequest)
    const [technologies, setTechnologies] = useState([])

    const [reloadSmall, setReloadSmall] = useState(false);
    const [reload, setReload] = useState(false);
    const [screeStatus, setScreenStatus] = useState(false);

    const [finalDataHiring, setFinalData] = useState({})
    const [showFinalBtn, setShowFinalBtn] = useState(false)
    const [formStep, setFormStep] = useState(tmsRole === 'hr' ? 3 : 2);

    const [testTypes, setTestTypes] = useState([
        {
            test_type_key: "",
            test_name: "",
            is_screening_test: 0,
            is_for_agent_panel: true,
            is_mcq: true,
            no_of_predefined_questions: "",
            total_no_question: 0,
            predefined_questions: {
                no_of_predefined_questions: "",
                already_selected_question: [],
                newly_created_questions: []
            },
            random_questions: {
                no_of_random_question: null,
                technologies: [
                    {
                        technology_key: null,
                        question_type_details: {
                            // mcq: 0,
                            // programming: 0,
                            // descriptive: 0
                        }
                    }
                ]
            }
        }
    ])

    const loadDataById = async () => {
        setReload(true)
        const res = await dataService.hiring_request_edit(hiringid)
        let technologyArray = []
        if (res?.data && res.data.data) {
            const editData = res.data.data
            const from_years = editData.relevant_experience_expected_from;
            const from_month = from_years * 12;
            const to_years = editData.relevant_experience_expected_to
            const to_month = to_years * 12;

            if (editData?.skills_tech_data) {
                editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
                    return technologyArray.push({
                        label: e.technology?.name,
                        value: e.technology?.id,
                        name: "specialistation_id",
                        key: e.technology?.specialization_key
                    })
                })
                setTechnologies(technologyArray)
            }
            let educationArrayFinalArray = []
            let addMoreSpecificationFinalArray = []


            if (editData?.education_qualification_data) {
                editData.education_qualification_data.map(e => educationArrayFinalArray.push({ 'id': e.id }))
            }

            if (editData?.add_more_specifications_data) {
                editData.add_more_specifications_data.map(e => addMoreSpecificationFinalArray.push({ 'id': e.id }))
            }

            if (editData) {
                setFormValues({
                    // step 1
                    specialistation_id: technologyArray,
                    experience: editData.relevant_experience_expected,
                    relevant_experience_expected_in_years: editData.relevant_experience_expected_in_years,
                    relevant_experience_expected_in_months: editData.relevant_experience_expected_in_months,
                    department_id: editData.department?.code,
                    industry_id: editData.industry?.industry_key,
                })

                let headfinalArray = []
                if (editData?.head_ids_data && editData.head_ids_data.length > 0) {
                    editData.head_ids_data.map(e => headfinalArray.push({ 'id': e.interview_feedback_head_id }))
                }
                let skillDataArray = []
                editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
                    return skillDataArray.push({
                        technology_id: e.technology?.id,
                        skill_ids: e.skills.map((item) => {
                            return item.id;
                        }
                        )
                    })
                })

                setFinalData({
                    client: editData.client_id,
                    industry: editData.industry_id,
                    department: editData.department_id,
                    skills_data: skillDataArray,

                    //step 2
                    client_budget_from: editData.client_budget_from,
                    client_budget_to: editData.client_budget_to,
                    currency: editData.currency_id,
                    payment_type: editData.payment_type_id,
                    hiring_mode: editData.hiring_mode_id,

                    // step 3
                    role: editData.role,
                    number_of_vacancies: editData.number_of_vacancies,

                    type_of_vacancy: editData.type_of_vacancy_id,
                    contract_type: editData.contract_type_id,
                    hours_per_day: editData.hours_per_day,
                    expected_duration_in_months: editData.expected_duration_in_months,

                    // urgency: editData.urgency_id,
                    working_mode: editData.working_mode_id,
                    job_location: editData.job_location_id,
                    // job_responsibilities: editData.job_responsibilities,
                    job_description: editData.job_description,
                    add_more_specifications: addMoreSpecificationFinalArray,
                    // expected_ETA_in_months: editData.expected_ETA_in_months,
                    // expected_ETA_in_days: editData.expected_ETA_in_days,
                    // relevant_experience_expected_in_years: editData.relevant_experience_expected_in_years,
                    // relevant_experience_expected_in_months: editData.relevant_experience_expected_in_months,
                    relevant_experience_expected_from: editData.relevant_experience_expected_from,
                    relevant_experience_expected_to: editData.relevant_experience_expected_to,
                    total_experience_to: editData.total_experience_to,
                    total_experience_from: editData.total_experience_from,

                    attachment: editData.attachment,
                    status: true,

                    hiring_status: (tmsRole === 'hr' || tmsRole === 'admin') ? 1 : 0,
                    urgency_unit: editData.urgency_unit_id,
                    expected_ETA: editData.expected_ETA,
                    education_qualification: educationArrayFinalArray,

                    //step 5
                    head_ids: headfinalArray,
                    client_interviewer: editData.client_interviewer ? editData.client_interviewer : [],
                    request_level: editData.request_level,
                    current_assignee: editData.current_assignee,

                })
                const monthExp = editData.relevant_experience_expected
                dispatch(questionlevelList(`{"filter":{"status":{"value":True,"operation":"eq"},"from_in_month":{"value":${from_month},"operation":"eq"},"to_in_month":{"value":${to_month},"operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
            }

            setReload(false)
        } else {
            toast.error(res.data.message)
            setReload(false)
        }
    }

    useEffect(() => {
        dispatch(getAllScreeningMasterList())
        if (hiringid) {
            loadDataById()
        }
        dispatch(questionTypeList())
        dispatch(getScreenTestList(`{"filter":{"hiring_request":{"value":${hiringid},"operation":"eq"},"status":{"value":True,"operation":"eq"}}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // add more candidate test
    const addMoreCandidateTest = () => {
        if (testTypes.length > 0) {
            setTestTypes([...testTypes, {
                test_type_key: "",
                test_name: "",
                is_screening_test: 0,
                is_for_agent_panel: true,
                is_mcq: true,
                no_of_predefined_questions: "",
                total_no_question: 0,
                predefined_questions: {
                    no_of_predefined_questions: "",
                    already_selected_question: [],
                    newly_created_questions: []
                },
                random_questions: {
                    no_of_random_question: null,
                    technologies: [
                        {
                            technology_key: null,
                            question_type_details: {
                                // mcq: 0,
                                // programming: 0,
                                // descriptive: 0
                            }
                        }
                    ]
                }
            }])
        } else {
            setTestTypes([{
                test_type_key: "",
                test_name: "",
                is_screening_test: 0,
                is_for_agent_panel: true,
                is_mcq: true,
                no_of_predefined_questions: "",
                total_no_question: 0,
                predefined_questions: {
                    no_of_predefined_questions: "",
                    already_selected_question: [],
                    newly_created_questions: []
                },
                random_questions: {
                    no_of_random_question: null,
                    technologies: [
                        {
                            technology_key: null,
                            question_type_details: {
                                // mcq: 0,
                                // programming: 0,
                                // descriptive: 0
                            }
                        }
                    ]
                }
            }])
        }
    }

    // test type drop-down
    const addMoreTestType = (evt, i) => {
        let filterTestData = testTypes

        setReloadSmall(true)
        setFormData({
            "random_questions": {
                "no_of_random_question": null,
                "technologies": [
                    {
                        "technology_key": null,
                        "question_type_details": {
                            // "mcq": 0,
                            // "programming": 0,
                            // "descriptive ": 0
                        }
                    }
                ]
            }
        })
        if (evt?.__isNew__) {
            filterTestData[i]['test_type_key'] = { value: evt.value, new: true }
        } else {
            filterTestData[i]['test_type_key'] = evt?.value
        }
        filterTestData[i]['is_for_agent_panel'] = evt?.data?.is_for_agent_panel === true ? true : false
        filterTestData[i]['is_mcq'] = (evt?.data?.is_mcq === true || evt?.data?.is_for_agent_panel === true) ? true : false

        setTimeout(() => {
            setReloadSmall(false)
            setTestTypes(filterTestData)
        }, 7);

    }
    // remove candidate test
    const removeCandidateTest = (i) => {
        const updateTestType = testTypes.filter((item, index) => index !== i);
        setTestTypes(updateTestType)
    }

    // handle change 
    const handleChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        let valueArray = []
        if (name === 'is_for_agent_panel' && (value === 'true' || value === true)) {
            valueArray = testTypes.length > 0 && testTypes.map((item, i) => i === index ?
                { ...item, 'is_mcq': true, 'is_for_agent_panel': true } : item)
        } else {
            valueArray = testTypes.length > 0 && testTypes.map((item, i) => i === index ?
                { ...item, [name]: value } : item)
        }
        index !== null && setTestTypes([...valueArray])
    }

    // submit form data 
    const submitData = () => {
        let emptyTest = [];
        let emptyTotalNumber = [];


        emptyTest = testTypes.filter((item) =>
            item.test_name === "" || item.test_type_key === ""
        );
        emptyTotalNumber = testTypes.filter((item) =>
            item.total_no_question === ""
        );
        let techArray = []
        technologies.map((item) => {
            return techArray.push(item.key)
        })

        let addvalues = {}

        if (emptyTotalNumber && emptyTotalNumber.length > 0) {
            return toast.error("Please enter valid number")
        }

        if (emptyTest && emptyTest.length === 0) {
            addvalues['hiring_request_id'] = Number(hiringid)
            addvalues['level'] = questionlevel_list?.id
            addvalues['hiring_technologies'] = techArray

            addvalues['test_types'] = testTypes
            setReload(true)
            dispatch(candidateTestCreate(addvalues))
        } else {
            toast.error("Test can not be empty!")
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_candidate_test_Create` && candidate_test_Create && candidate_test_Create.message) {
        if (candidate_test_Create.status === 200) {
            let finalArr = []
            let addData = candidate_test_Create.data
            let finalObj = {}
            // eslint-disable-next-line
            addData.map((item, i) => {
                finalArr.push({
                    ces_set_id: item.set?.id,
                    ces_schedule_id: item.shedule?.id,
                    test_type: item.test_type_key,
                    sequence_number: i + 1,
                    test_name: item.set?.set_config?.test_name,
                    is_for_agent_panel: item.set?.set_config?.is_for_agent_panel,
                    is_mcq: item.set?.set_config?.is_mcq,
                    is_screening_test:item.set?.set_config?.is_screening_test
                })
            })
            finalObj['hiring_request'] = hiringid;
            finalObj['test_types'] = finalArr;
            setShowFinalBtn(true)
            setScreenStatus('screen')
            dispatch(saveScreenFlowData(finalObj))

            toast.success(candidate_test_Create.message)
        } else {
            toast.error(candidate_test_Create.message)
        }
        setReload(false)
    }

    // save screen flow dataa
    if (screeStatus === 'screen' && status === `${STATUSES.SUCCESS}_screen_flow_Create` && screen_flow_Create && screen_flow_Create.message) {
        if (screen_flow_Create.status === 200) {
            toast.success(screen_flow_Create.message)
        } else {
            toast.error(candidate_test_Create.message)
        }
        setScreenStatus(false)
    }

    let flag = false

    const finalSubmit = async () => {
        const addvalues = finalDataHiring
        let res;
        let result
        let ID = hiringid
        res = await dispatch(hiringRequestUpdate({ ID, addvalues }))
        result = await res.payload
        if (result.status === 200) {
            toast.success(result.message)
            setTimeout(() => {
                navigate('/my-hiring-requests')
            }, 1500)
        } else {
            toast.error(result.message)
        }
        setReload(false)
    }

    const handlePreviousPage = () => {
        const stateData = {
            id: hiringid,
            formStep: formStep,
            submitFinaleData: finalDataHiring
        };
        navigate(`/edit-hiring-request/${hiringid}`, { state: stateData })
    }

    return (
        <div className="new-candidate-test">
            <div className="new-candidate-test-container">
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Candidate Screening Test Creation</h3>
                        <div>
                            <Tooltip title="Back">
                                <Button onClick={() => handlePreviousPage()} className="btn btn-add-new"><ArrowBackIcon /></Button>
                            </Tooltip>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* part one section */}
                    {
                        testTypes?.map((item, index) => {
                            return (
                                <div className="form-candidate-test" key={index}>
                                    <div className="add-new-user-form">
                                        <div className="formInput">
                                            <InputFields
                                                label={'Test Name'}
                                                name="test_name"
                                                defaultValue={item.test_name}
                                                type="text"
                                                placeholder="Test Name"
                                                onChange={(evt) => handleChange(evt, index)}
                                            // formValues={formValues}
                                            // errStatus={errStatus}
                                            // formErr={formErrors.nameErr}
                                            />
                                        </div>
                                        <div className="formInput" style={{ flexBasis: "1%", marginTop: "30px", }}>
                                            <span style={{ color: "green", cursor: "pointer" }}
                                                onClick={() => addMoreCandidateTest()}>
                                                <Tooltip title="Add More Test Type">
                                                    <AddCircleOutline style={{ marginTop: "5px" }} />
                                                </Tooltip>
                                            </span>

                                        </div>
                                        <div className="formInput" style={{ flexBasis: "64%", marginTop: "35px", }}>

                                            {index !== 0 ?
                                                <div className="form-credencial-row-remove">
                                                    <span onClick={() => removeCandidateTest(index)}>
                                                        {/* Remove Candidate Test */}
                                                        <Tooltip title="Remove Test Type">
                                                            <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeCandidateTest(index)} />
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>

                                    <div className="add-new-user-form">
                                        <div className="formInput">
                                            <label>Select Test Type ,or add new test type</label>
                                            <CreatableSelect
                                                // value={formValues.test_type}
                                                // isClearable
                                                onChange={(evt) => addMoreTestType(evt, index)}
                                                name="head_ids"
                                                placeholder="Select Test Type ,or add new test type"
                                                options={
                                                    screeningmaster_AllList?.data && screeningmaster_AllList.data.rows.map((option) => {
                                                        return {
                                                            label: option.test_type,
                                                            value: option.test_type_key,
                                                            name: "test_type",
                                                            data: option
                                                        }
                                                    })
                                                } />

                                        </div>
                                    </div>
                                    {reloadSmall === true ? <SmallLoader /> :

                                        <div className="add-new-user-form">
                                            <div className="formInput">
                                                <label> Managed By </label>
                                                <select className="select"
                                                    name={"is_for_agent_panel"}
                                                    onChange={(evt) => handleChange(evt, index)}
                                                    defaultValue={item.is_for_agent_panel}
                                                >

                                                    {getManagedBy?.map((e, i) => {
                                                        return (<option value={e.value} key={i}>{e.label}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <div className="formInput" style={{ marginLeft: "10px" }}>
                                                <FormControl>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">Is MCQ</FormLabel>
                                                    <RadioGroup
                                                        value={item.is_mcq}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="is_mcq"
                                                        onChange={(evt) => handleChange(evt, index)}
                                                    >
                                                        <FormControlLabel
                                                            value={true} control={<Radio />} label="Yes"
                                                            disabled={(item.is_for_agent_panel === true || item.is_for_agent_panel === 'true') ? true : false}

                                                        />
                                                        <FormControlLabel
                                                            value={false} control={<Radio />} label="No"
                                                            disabled={(item.is_for_agent_panel === true || item.is_for_agent_panel === 'true') ? true : false}

                                                        />

                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className="formInput"></div>
                                        </div>
                                    }

                                    <div className="add-new-user-form">
                                        <div className="formInput">
                                            <label> Screening Type </label>
                                            <select className="select"
                                                name={"is_screening_test"}
                                                onChange={(evt) => handleChange(evt, index)}
                                                defaultValue={item.is_screening_test}
                                            >

                                                {getTestScreeing?.map((e, i) => {
                                                    return (<option value={e.value} key={i}>{e.label}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <PartTwo
                                        parentIndex={index}
                                        technologies={technologies}
                                        setFormData={setFormData}
                                        formData={formData}
                                        testTypes={testTypes}
                                        setTestTypes={setTestTypes}
                                        formValues={formValues}
                                        test_type_key={item.test_type_key}
                                        level_key={questionlevel_list?.level_key}
                                        questionType_List={questionType_List}
                                        is_mcq={item.is_mcq}
                                        for_agent={item.is_for_agent_panel}
                                        questionlevel_list={questionlevel_list}
                                        preFormData={preFormData}
                                        setPreFormData={setPreFormData}
                                    />

                                </div>
                            )
                        })
                    }

                    {testTypes?.map((item, i) => {
                        let totalNumberquestions = Number(item.total_no_question)
                        let preQusetion = Number(item.predefined_questions?.already_selected_question?.length)
                        let randomQuestionNo = Number(item?.random_questions?.no_of_random_question)
                        if (totalNumberquestions === (preQusetion + randomQuestionNo)) {
                            flag = false
                        } else if (totalNumberquestions === 0) {
                            flag = true
                        } else if (totalNumberquestions > (preQusetion + randomQuestionNo)) {
                            flag = true
                        }
                    })}

                    <p style={{ color: "red", marginLeft: "36px" }}>{screen_flow_test_count === 0 ? "" : "Already test created"}</p>
                    <div className="add-new-user-form" style={{ display: "flex" }}>
                        <button className="btn btn-submit"
                            disabled={(flag === true || screen_flow_test_count > 0) ? true : false}
                            style={(flag === true || screen_flow_test_count > 0) ? { marginLeft: "35px", width: "unset", backgroundColor: "gray" } : { marginLeft: "35px", width: "unset" }}
                            onClick={() => submitData()}>Submit Candidate Test
                        </button>
                        <button
                            disabled={showFinalBtn === false && screen_flow_test_count === 0 ? true : false}
                            onClick={() => finalSubmit()}
                            className={(showFinalBtn === false && screen_flow_test_count === 0) ? "btn btn-submit" : "btn btn-submit"}
                            style={(showFinalBtn === false && screen_flow_test_count === 0) ? { marginLeft: "20px", marginRight: "20px", backgroundColor: "gray" } : { marginLeft: "20px", marginRight: "20px" }}
                        >
                            Final Submit
                        </button>
                    </div>


                </div>
            </div >
        </div >
    )
}
export default PartOne