const { REACT_APP_ENV } = process.env;
class LocalStorageDataService {

    userDetail() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
    }

    userToken() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).token : "";
    }

    priority_list() {
        return ["High", "Medeium", "Normal"]
    }
    labels_list() {
        return ["API", "UI", "UX"]
    }
    lastInteractionTime() {
        return localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).lastInteractionTime : "";
    }
    regularize_type() {
        return [{ value: '-1', label: "select" }, { value: 1, label: "Regularize Punch In Time" }, { value: 2, label: "Regularize Punch Out Time" }, { value: 0, label: "Regularize Both" }]
    }
    regularize_status_type() {
        return [{ value: 4, label: "PENDING" }, { value: 7, label: "REJECTED" }, { value: 5, label: "APPROVED" }, { value: 6, label: "CANCELLED" }]
    }
    get_default_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_task`)) : "";
    }
    get_default_zoom_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_zoom`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_zoom`)) : "";
    }
    get_default_phoneCallDetails_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_my_phoneCallDetails`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_my_phoneCallDetails`)) : "";
    }
    get_default_AllphoneCallDetails_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_allphoneCallDetails`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_allphoneCallDetails`)) : "";
    }
    get_default_attendence_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_attendence`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_attendence`)) : "";
    }
    get_default_joblist_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_joblist`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_joblist`)) : "";
    }
    get_default_resource_credential_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_resourcecredential`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_resourcecredential`)) : "";
    }
    get_default_resource_list_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_resourcelistcolumn`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_resourcelistcolumn`)) : "";
    }
    get_default_screening_data_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_screeningdata`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_screeningdata`)) : "";
    }
    get_default_allproject_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_allproject_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_allproject_column`)) : "";
    }
    get_default_my_project_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_my_project_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_my_project_column`)) : "";
    }
    get_default_shift_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_shift_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_shift_column`)) : "";
    }
    get_default_assign_shift_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_assign_shift_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_assign_shift_column`)) : "";
    }
    get_default_attendence_report_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_attendence_report_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_attendence_report_column`)) : "";
    }
    get_default_holiday_column() {
        let user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : "";
        return user && localStorage.getItem(`${REACT_APP_ENV}_${user.id}_holiday_column`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_${user.id}_holiday_column`)) : "";
    }

    get_default_work_mode() {
        return ["Work From Home", "Work From Office", "Hybrid"]
    }
    get_default_month_year() {
        return ["Select Anyone", "Months", "Years"]
    }
    get_file_status() {
        return [{ value: 0, label: "Uploaded" }, { value: 1, label: "In Progress" }, { value: 2, label: "Parsed" }, { value: 3, label: "Processed" },
        { value: 4, label: "Invalid" }, { value: 5, label: "Story Creation In Progress" }, { value: 6, label: "Story Creation Failed" }, { value: 7, label: "Name parsing story creation InProgress" }, { value: 9, label: "Story created to show resume" }, { value: 10, label: "Story already created for this resume in same Job" },
        { value: 900, label: "Contact update inprogress" },
        ]
    }
    get_pagination_filter_all_call() {
        return localStorage.getItem(`${REACT_APP_ENV}_all_call_details_pagiation`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_all_call_details_pagiation`)) : "";
    }

    get_default_time_in_mins() {
        return [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
    }
    get_domain_data() {
        let status = false
        let userData = localStorage.getItem(`${REACT_APP_ENV}_rtms`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`)).user.data : ""
        userData?.domains_data && userData.domains_data.length > 0 && userData.domains_data.forEach((item) => {
            if (item.status === true) {
                const domain_name = item.domain?.name && item.domain.name
                let result = domain_name.match("Recruitment") || domain_name.match("recruitment")
                if (result) {
                    status = true
                }
            }
        })
        return status
    }

    ckEditor_config_toolbar() {
        let toolbar = {
            toolbar: [
                ["Source"],
                ["Styles", "Format", "Font", "FontSize"],
                ["Bold", "Italic"],
                ["NumberedList", "BulletedList"],
                ["Undo", "Redo"],
                ["EasyImageUpload"],
                { name: 'insert', items: ['Image', 'Table', 'Smiley', 'PageBreak', 'Iframe'] },
            ],
        }
        return toolbar
    }

    cesCandidateDetails() {
        return localStorage.getItem(`ces_candidate`) ? JSON.parse(localStorage.getItem(`ces_candidate`)) : ""
    }

    get_week_days() {
        return [
            { value: "Sunday", label: "Sunday" }, { value: "Monday", label: "Monday" },
            { value: "Tuesday", label: "Tuesday" }, { value: "Wednesday", label: "Wednesday" },
            { value: "Thursday", label: "Thursday" }, { value: "Friday", label: "Friday" },
            { value: "Saturday", label: "Saturday" },
        ]
        // return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    }

    get_jobpost_status() {
        return [
            { value: 0, label: "Pending" },
            { value: 1, label: "Request For Approval" },
            { value: 2, label: "Approved" },
            { value: 3, label: "Rejected" },
            { value: 4, label: "Live" },
            { value: 5, label: "Hold" },
            { value: 6, label: "Close" },
            { value: 7, label: "Re-approval Request" },
        ]
    }

    get_managed_by_test() {
        return [
            { value: true, label: "Agent" },
            { value: false, label: "Candidate" },
        ]
    }

    get_test_screening() {
        return [
            { value: 0, label: "Pre Interview" },
            { value: 1, label: "Post Interview" },
        ]
    }

    get_default_unit_for_hiring() {
        return ["Hours", "Days", "Weeks", "Months"]
    }

    get_page_item_list() {
        return [
            { label: "10 Items/Page", value: 10 },
            { label: "25 Items/Page", value: 25 },
            { label: "50 Items/Page", value: 50 },
            { label: "100 Items/Page", value: 100 },
        ]
    }

    get_hiring_request_level() {
        return [
            { value: 1, label: "Created By TL" },
            { value: 2, label: "Sent To Manager" },
            { value: 3, label: "Accepted By Manager" },
            { value: 4, label: "Created By Manager" },
            { value: 5, label: "Sent To HR Manager" },
            { value: 6, label: "Accepted By HR Manager" },
            { value: 7, label: "Created by HR Manager" },
        ]
    }

}
// eslint-disable-next-line
export default new LocalStorageDataService();