import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { callCreate } from '../../redux/features/callingSlice';
import { STATUSES } from "../../redux/common-status/constant";
import { toast } from "react-toastify";
import { AddRounded, LocalPhoneRounded, Phone } from '@mui/icons-material';
import { Button, MenuItem, Tooltip} from '@mui/material';

const CallingComponent = ({ setActiveClass, dropdownPhoneClose, dropdownPhoneCloseActive, data, className, title, setIsCallInitiated, setCallId, setCallStatus, style, setBtnStyle, setPhoneDetails, story_id, changeCallActiveStatus, options, setAgentCustomerCallObj, agentCustomerCallObj, setShow, isCallInitiated, activeCallDivs, setActiveCallDivs
    , setShowAddCandidatePhoneBtn, phoneNumberByList,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [callingActive, setCallingActive] = useState(false)

    const { status, call_Create, error } = useSelector(state => state.callingSlice)

    if (loading === true && status === `${STATUSES.SUCCESS}_call_Create` && call_Create && call_Create.message) {
        setLoading(false)
        if (call_Create.status === 200) {
            toast.success(call_Create.message)
            dropdownPhoneCloseActive && dropdownPhoneClose()
            setCallId(call_Create.data.id)
            isCallInitiated === true ? setCallingActive(true) : setCallingActive(false)
        } else {
            setCallingActive(false)
            toast.error(call_Create.message)
            setIsCallInitiated(false)
            options && setPhoneDetails({ destination_number: "", story_id: story_id });
            setBtnStyle({ backgroundColor: "green", border: "1px solid green" })
            setActiveClass(false)
            dropdownPhoneCloseActive && dropdownPhoneClose()
        }
    }


    //error handling
    if (loading === true && status === STATUSES.FAILURE && error) {
        setLoading(false)
        setCallingActive(false)
        toast.error(error.message)
        setIsCallInitiated(false)
        options && setPhoneDetails({ destination_number: "", story_id: story_id });
        setBtnStyle({ backgroundColor: "green", border: "1px solid green" })
        setActiveClass(false)
        dropdownPhoneCloseActive && dropdownPhoneClose()
    }

    const callInitiate = () => {
        setAgentCustomerCallObj({
            ...agentCustomerCallObj,
            candidate_phone_no: data.destination_number,
        });
        setCallingActive(true)
        setShow(true)
        setActiveClass(true)
        setLoading(true)
        setBtnStyle({ display: "none" })
        setIsCallInitiated(true)
        dispatch(callCreate(data))
        changeCallActiveStatus()
        setCallStatus(0)
    }

    const nothing = () => {
        ///no event perform
    }

    if (activeCallDivs && activeCallDivs !== false && loading === false) {
        setActiveCallDivs(false)
        setShow(true)
        setActiveClass(true)
        setLoading(true)
        setBtnStyle({ display: "none" })
        setIsCallInitiated(true)
        setCallStatus(0)
        setAgentCustomerCallObj({
            ...agentCustomerCallObj,
            candidate_phone_no: phoneNumberByList || data.destination_number,
        });
        if (phoneNumberByList) {
            let details = { destination_number: phoneNumberByList, story_id: story_id }
            dispatch(callCreate(details))
        } else {
            dispatch(callCreate(data))
        }
    }

    return <>
        {
            isCallInitiated === true ?
                <div style={{ display: 'none' }}>
                    <MenuItem style={{ backgroundColor: '#808080', border: "1px solid #808080", color: '#fff' }}>
                        <span className="notification-head">
                            <span className="ss-noti"> <LocalPhoneRounded /></span>
                            <span> {data.destination_number}</span>
                        </span>
                    </MenuItem>
                </div> :
                options ?
                    <MenuItem onClick={() => options.id === 'add-new-number' ? setShowAddCandidatePhoneBtn(true) : callInitiate()} key={options.id}>
                        <span className="notification-head">
                            <span className="ss-noti">{options.id === 'add-new-number' ? <AddRounded /> : <LocalPhoneRounded />}</span>
                            <span> {options.phone_number}</span>
                        </span>
                    </MenuItem>

                    :
                    <Tooltip title={`Call - ${data.destination_number}`}>
                        <Button onClick={callingActive === true && isCallInitiated === true ? nothing : callInitiate} className={className}
                            style={callingActive === true && isCallInitiated === true ? { backgroundColor: '#808080', border: "1px solid #808080", color: '#fff' } : style}>
                            <Phone style={{ fontSize: 16 }} /> {title}</Button>
                    </Tooltip>
        }

    </>
}

export default CallingComponent