import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const createSovernCredential = createAsyncThunk("sovern-credential/add", async (data) => {
    const res = await DataService.create_sovern(data);
    return res.data;
});

export const getSovernCredentials = createAsyncThunk("sovern-credential/get", async (data) => {
    const res = await DataService.sovern_credential_list(data);
    return res.data;
});

export const updateSovernCredential = createAsyncThunk("sovern-credential/update", async (data) => {
    const res = await DataService.update_sovern_status(data.ID, data.formValues);
    return res.data;
});

const sovernCredentialSlice = createSlice({
    name: "sovernCredentialSlice",
    initialState: {
        sovernCredentialsList: [],
        create_sovern_credential: [],
        update_sovern_credential: [],
        status: STATUSES.IDLE,
        error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get sovern credential list
            .addCase(getSovernCredentials.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSovernCredentials.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}`;
                state.sovernCredentialsList = action.payload;
            })
            .addCase(getSovernCredentials.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //create sovern credential
            .addCase(createSovernCredential.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createSovernCredential.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_create_sovern_credential`;
                state.create_sovern_credential = action.payload;
            })
            .addCase(createSovernCredential.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///sovern credential Update
            .addCase(updateSovernCredential.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateSovernCredential.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_update_sovern_credential`;
                state.update_sovern_credential = action.payload;
            })
            .addCase(updateSovernCredential.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});
export default sovernCredentialSlice.reducer;