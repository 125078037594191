import { Dialog, DialogContent } from "@mui/material"

const DeleteDialogModal = ({ open, onClose, heading, paragraph, handleArchive, id, moduleName }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogContent>
                    <div className="popup-confirming">
                        <h3>{heading}</h3>
                        <p>{paragraph}</p>
                        <button className="yes" onClick={() => moduleName ? handleArchive(true, id) : handleArchive(id)}>Yes</button>
                        <button className="no" onClick={() => onClose(false)} autoFocus>
                            Cancel
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DeleteDialogModal