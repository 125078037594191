import axios from "axios";

const { REACT_APP_CES_API_BASEURL } = process.env;
const ces = localStorage.getItem(`ces_candidate`) && JSON.parse(localStorage.getItem(`ces_candidate`))

export default axios.create({
  baseURL: REACT_APP_CES_API_BASEURL,
  headers: {
    "Content-type": "application/json",
    "Authorization": ces ? ces.token : '',
  }
});
