import React, { useEffect, useMemo, useRef, useState } from 'react';
import './emailtemplatelist.scss';
import Stack from '@mui/material/Stack';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { DataGridPro, GridActionsCellItem, useGridApiRef, } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { EmailTemplateListing, EmailTemplateListingUpdate } from '../../redux/features/masterSlice';
import { Grid, Switch } from '@mui/material';
import UcActionComponent from '../common-function/UcActionComponent';
import { Box, Skeleton, Tooltip, DialogContent, Dialog, DialogTitle, Button, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify"
import { Close } from '@mui/icons-material';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CreatableSelect from 'react-select/creatable';
import { InputFields } from "../common-function/InputFields";
import { STATUSES } from "../../redux/common-status/constant";
import { emailTemplateById, emailTemplateUpdate, emailTempleteCreate, getAllEmailTemplates, getTemplateCategory } from '../../redux/features/emailTemplateSlice';
import DOMPurify from "dompurify";

const initialFormData = Object.freeze({
    template_category: "",
    template_name: "",
    subject: "",
    body: "",
    fileName: ""
})

const formErrData = Object.freeze({
    template_categoryErr: "Template is required",
    template_nameErr: "Category is required",
    subjectErr: "Subject is required",
    bodyErr: "PLease Upload HTML File.",
});

function EmailTemplateList() {
    const dispatch = useDispatch()
    const [ID, setID] = useState("")
    const [limit, setLimit] = useState(20);
    const apiRef = useGridApiRef();
    const { email_template_list, status, error, email_template_list_update } = useSelector(state => state.master)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`)
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
    let stopOnRowScrollEnd = useRef(false);
    const [loadedRows, setLoadedRows] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [skeleton, setSkeleton] = useState([]);
    const skeletonBase = { Category_Name: '_skeleton_', role: '_skeleton_', subject: '_skeleton_', status: '_skeleton_', action: '_skeleton_' };
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [open, setOpen] = useState(false)
    const [viewdata, setViewdata] = useState()
    const [loading, setLoading] = useState(false)
    const [pinnedCols, setPinnedCols] = useState({ left: ['id'], right: ['action'] })
    const [reload, setReload] = useState(false)
    const [pinnedRowsIds, setPinnedRowsIds] = useState({
        top: [],
        bottom: [],
    });
    const [openCreateTemplete, setOpenCreateTemplete] = useState(false)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const { status: status2, emailTemplateCategory, createEmailTemplete, emailTemplates, emailTemplate_byid, updateEmailTemplate } = useSelector(state => state.emailTemplateSlice)
    const [descriptionValue, setDescriptionValue] = useState("")
    // for skeleton
    useEffect(() => {
        setLoading(true)
        setSkeleton(() => Array((15)).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    }, []);


    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])



    useEffect(() => {
        setLoading(true);
        dispatch(EmailTemplateListing(filterQuery))
    }, [filterQuery]);


    //for view 
    const handleClickView = (params) => {
        setViewdata(params)
        setOpen(true)
    }

    // change status on listing page 
    const changeStatus = (status, data) => {
        let ID = data?.id
        let formValues;
        if (status === true) {
            formValues = { template_name: data?.template_name, template_category: data.template_category?.id, body: data?.body, subject: data?.subject, signature: data?.signature, status: false }
        } else {
            formValues = { template_name: data?.template_name, template_category: data.template_category?.id, body: data?.body, subject: data?.subject, signature: data?.signature, status: true }
        }
        setReload('submit')
        setLoading(true)
        dispatch(EmailTemplateListingUpdate({ ID, addvalues: formValues }))
    }


    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_email_template_list_update` && email_template_list_update && email_template_list_update.message) {
        if (email_template_list_update.status === 200) {
            setLoadedRows([])
            toast.success(email_template_list_update.message)
            setLoading(true)
            dispatch(EmailTemplateListing(`{"order_by":{"column":"-id"},"skip":0, "limit":20}`))
        } else {
            toast.error(email_template_list_update?.message)
        }
        setReload(false)
    }


    // handle load data response
    if (loading === true && status === `${STATUSES.SUCCESS}_email_template_list`) {
        if (email_template_list.status === 200) {
            let fout = [];
            let copyRows = [...loadedRows, ...email_template_list.data.rows];
            copyRows = copyRows.filter(item => {
                if (fout.includes(item.id)) return false
                fout.push(item.id)
                return true
            })
            setLoadedRows([...copyRows].sort(item => item.status ? -1 : 1))
            SET_MAX_ROW_LENGTH(email_template_list?.data?.count || null)
        } else {
            toast.error(email_template_list.message)
        }
        setSkeleton([]);
        setLoading(false)
    }

    const actionColumn = [

        { field: "id", headerName: "ID", width: 300 },
        {
            field: 'pin',
            type: 'actions',
            width: 100,
            getActions: (params) => {
                const isPinnedTop = pinnedRowsIds.top.includes(params.id);
                const isPinnedBottom = pinnedRowsIds.bottom.includes(params.id);
                if (isPinnedTop || isPinnedBottom) {
                    return [
                        <div>
                            <Tooltip title="Unpin">
                                <GridActionsCellItem label="Unpin" icon={
                                    isPinnedTop ? <ArrowDownIcon /> : <ArrowUpIcon />
                                }
                                    onClick={() => setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        top: prevPinnedRowsIds.top.filter(
                                            (rowId) => rowId !== params.id,
                                        ),
                                        bottom: prevPinnedRowsIds.bottom.filter(
                                            (rowId) => rowId !== params.id,),
                                    }))
                                    }
                                />
                            </Tooltip>
                        </div>
                    ];
                }
                return [
                    <div>
                        <Tooltip title="Pin at the top">
                            <GridActionsCellItem icon={
                                <ArrowUpIcon />
                            }
                                label="Pin at the top" onClick={() =>
                                    setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        ...prevPinnedRowsIds,
                                        top: [...prevPinnedRowsIds.top, params.id],
                                    }))}
                            />
                        </Tooltip>
                    </div>,
                    <div>
                        <Tooltip title="Pin at the bottom">
                            <GridActionsCellItem
                                icon={<ArrowDownIcon />}
                                label="Pin at the bottom"
                                onClick={() =>
                                    setPinnedRowsIds((prevPinnedRowsIds) => ({
                                        ...prevPinnedRowsIds,
                                        bottom: [...prevPinnedRowsIds.bottom, params.id],
                                    }))
                                }
                            />
                        </Tooltip>
                    </div>,
                ];
            },
        },
        {
            field: "Category_Name", headerName: "Category Name", width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.subject === "_skeleton_" ? <Box
                        sx={{
                            height: '50px',
                            width: "19em"
                        }} >
                        <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} />
                    </Box> : params?.row?.template_category?.template_category

                );
            }
        },
        {
            field: "template_name", headerName: "Template Name", width: 250,
            renderCell: (params) => {
                return (
                    params.row.subject === "_skeleton_" ? <Box
                        sx={{
                            height: '50px',
                            width: "19em"
                        }} >
                        <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} />
                    </Box> : params?.row?.template_name
                );
            }
        },
        {
            field: "subject", headerName: "Subject", width: 320,
            renderCell: (params) => {
                return (
                    params.row.subject === "_skeleton_" ? <Box
                        sx={{ height: '50px', width: "19em" }} >
                        <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} /> </Box> : params?.row?.role);
            }
        },
        {
            field: "status", headerName: "Status", width: 200, type: "singleSelect",
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.status === "_skeleton_" ? <Box
                        sx={{ height: '50px', width: "19em" }} >
                        <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} />
                    </Box> :
                        <>
                            <div>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    {/* <Typography className={`cellWithStatus ${params.row.status}`}>
                                        {params.row.status === true ? "Active" : "Inactive"}
                                    </Typography> */}
                                    <Switch
                                        checked={params.row.status === true ? true : false}
                                        inputProps={{ 'aria-label': 'ant design' }}
                                        onChange={() => changeStatus(params.row.status, params.row)}
                                    />
                                </Stack>
                            </div></>)
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.action === "_skeleton_" ?
                        <Box sx={{ height: '50px', width: "19em" }} >
                            <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} />
                        </Box> :
                        <>
                            <div className="cellAction">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="source-type"
                                    rowid={params.row.id}
                                    isDelete={false}
                                    editButton={handleClickEdit}
                                    clone={() => handleClone(params.row.id)}
                                    addMoreData={params.row.id}
                                    viewLinkUrl=""
                                    viewButton={() => handleClickView(params.row)}
                                />
                            </div>

                        </>
                );
            },
        },
    ];

    const handleClickEdit = (id) => {
        dispatch(emailTemplateById(id))
        setReload('clone_templete')
        setID(id)
    }

    //   for pinning the row 
    const { pinnedRows } = useMemo(() => {
        const rowsData = [];
        const pinnedRowsData = { top: [], bottom: [] };
        loadedRows.forEach((row) => {
            if (pinnedRowsIds.top.includes(row.id)) {
                pinnedRowsData.top.push(row);
            } else if (pinnedRowsIds.bottom.includes(row.id)) {
                pinnedRowsData.bottom.push(row);
            } else {
                rowsData.push(row);
            }
        });
        return { rows: rowsData, pinnedRows: pinnedRowsData, };
    }, [pinnedRowsIds]);


    const onFilterChange = (props) => {
        stopOnRowScrollEnd.current = false;
        setLimit(20)
        setLoadedRows([])

        if (filterModal?.items?.length > props?.items?.length) {

            setFilterModel(props)
            if (props?.items?.length === 0) {
                setFilterQuery(`{ "skip":0,"limit": 20,"order_by": { "column": "-id" } }`)
            }
            return;
        } else if (filterModal?.items?.length < props?.items?.length && props?.items?.length <= 5) {
            setFilterModel(props)
            return;
        } else if (filterModal?.items?.length === props?.items?.length) {
            setFilterModel(props)
            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" } };
            let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }
            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }))
                }
            }
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            setLoadedRows([])
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            // dispatch(getAllTemplateCategoryList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 20, "order_by": { "column": "-id" } }`)
        }
    }



    const handleOnRowsScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (MAX_ROW_LENGTH === null) return;
        if (loadedRows?.length < MAX_ROW_LENGTH) {
            loadServerRows(params.viewportPageSize);
        }
    };


    const loadServerRows = (viewPortRowLen) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.order_by = { "column": "-id" };
        updatedFilter.skip = loadedRows?.length;
        updatedFilter.limit = loadedRows?.length + viewPortRowLen > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - loadedRows.length : viewPortRowLen;
        setFilterQuery(JSON.stringify(updatedFilter));
        setSkeleton(() => Array((viewPortRowLen)).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        setLimit(loadedRows?.length + viewPortRowLen > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - loadedRows.length : viewPortRowLen)
    }



    const closeViewMode = () => {
        setOpen(false)
    }

    useEffect(() => {
        dispatch(getTemplateCategory())
        dispatch(getAllEmailTemplates())
    }, [])

    const handleClose = () => {
        setOpenCreateTemplete(false);
        setErrStatus(false);
        setID("")
        setTimeout(() => {
            setFormValues(initialFormData)
        }, 350);
    };
    const handleChange = (e) => {
        const { name, value } = e.target || e;
        if (name === "template_category") {
            setDescriptionValue(e.description ? e.description : "")
        }
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const fileInputRef = React.useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFile(files[0]);
        }
    };

    const handleFile = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const fileContent = event.target.result;
                setFormValues({
                    ...formValues,
                    body: fileContent,
                    fileName: file.name
                });
            };
            reader.readAsText(file);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            handleFile(file);
        }
    };

    const triggerFileInput = (e) => {
        e.stopPropagation();
        fileInputRef.current.click();
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formValues.template_category === "" || formValues.template_name === "" || formValues.subject === "" || formValues.body === "") {
            setErrStatus(true)
            return;
        }
        if ((formValues.template_category !== "" || formValues.template_category.value !== "") && formValues.template_name !== "" && formValues.subject !== "" && formValues.body !== "") {
            let addValues = {
                ...formValues,
                template_category: formValues.template_category.value ? formValues.template_category.value : formValues.template_category
            }
            if (ID) {
                dispatch(emailTemplateUpdate({ ID, addValues }));
                setReload("update-template")
            } else {
                dispatch(emailTempleteCreate(addValues));
                setReload("create-template")
            }

        }
    }

    const selectTemplateCategoryValueInLabel = (id) => {
        let data = emailTemplateCategory?.data?.rows;
        let newObject = null;
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.template_category, description: x.description });
        });
        return newObject;
    };


    if (reload === 'clone_templete' && status2 === `${STATUSES.SUCCESS}` && emailTemplate_byid && emailTemplate_byid.data) {
        let editData = emailTemplate_byid.data
        setFormValues({
            id: editData.id,
            template_category: selectTemplateCategoryValueInLabel(editData.template_category_id),
            template_name: editData.template_name,
            subject: editData.subject,
            body: editData.body,
            fileName: editData.template_name
        })
        setDescriptionValue(editData.template_category.description)
        // setID(editData.id)
        setOpenCreateTemplete(true)
        setReload(false)
    }

    const handleClone = (id) => {
        dispatch(emailTemplateById(id))
        setReload("clone_templete")
    }

    if (reload === "create-template" && status2 === `${STATUSES.SUCCESS}_createEmailTemplete`) {
        if (createEmailTemplete.status === 200) {
            setReload(false)
            setErrStatus(false)
            handleClose()
            toast.success(createEmailTemplete.message)
            setFormValues(initialFormData)
            setDescriptionValue("")
            setLoadedRows([])
            setLoading(true);
            dispatch(EmailTemplateListing(`{"order_by":{"column":"-id"},"skip":0, "limit":20}`))
        } else {
            toast.error(createEmailTemplete.message)
            setReload(false)
        }
    }

    if (reload === "update-template" && status2 === `${STATUSES.SUCCESS}_UpdateEmailTemplete`) {
        if (updateEmailTemplate.status === 200) {
            setReload(false)
            setErrStatus(false)
            handleClose()
            toast.success(updateEmailTemplate.message)
            setFormValues(initialFormData)
            setDescriptionValue("")
            setLoadedRows([])
            setLoading(true);
            dispatch(EmailTemplateListing(`{"order_by":{"column":"-id"},"skip":0, "limit":20}`))
        } else {
            toast.error(updateEmailTemplate.message)
            setReload(false)
        }
    }
    return (
        <div className="new">
            <div className='newContainer'>
                <ToastContainer />
                <div className="datatable">

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px", color: "blue" }}>
                            <p>Email Template List</p>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row">
                                <Button onClick={() => setOpenCreateTemplete(true)} variant="outlined">Create template<BorderColorTwoToneIcon /></Button>
                            </Stack>
                        </div>
                    </div>





                    <div>
                        <DataGridPro
                            rows={loadedRows?.concat(skeleton) || []}
                            columns={actionColumn || []}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            onPinnedColumnsChange={(prop) => {
                                setPinnedCols(prop)
                            }}
                            pinnedColumns={pinnedCols || []}
                            apiRef={apiRef}
                            hideFooterPagination
                            sortingMode="server"
                            filterMode="server"
                            onRowsScrollEnd={handleOnRowsScrollEnd}
                            experimentalFeatures={{ lazyLoading: true, rowPinning: true }}

                            filterModel={filterModal}
                            onFilterModelChange={onFilterChange}
                            hideFooter={true}
                            onSortModelChange={(sort) => handleSort(sort)}
                            rowThreshold={.7}
                            pinnedRows={pinnedRows}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                            }
                        />
                        <Dialog
                            fullWidth
                            maxWidth={"md"}
                            open={open}
                            onClose={closeViewMode}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Button
                                edge="start"
                                color="inherit"
                                onClick={closeViewMode}
                                aria-label="close"
                                style={{ position: "absolute", top: "10px", right: "10px" }}>
                                <Close />
                            </Button>
                            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Email Template List'}</ DialogTitle>
                            <DialogContent>
                                <div className='view-details-row' style={{ maxheight: "400px" }}>
                                    <div className="detailItem" >
                                        <span className="itemKey" >Template Name :</span>
                                        <span className="itemKey2">{viewdata?.template_name}</span>
                                    </div>
                                    <div className="detailItem">
                                        <span className="itemKey" >Subject :</span>
                                        <span className="itemKey2">{viewdata?.subject}</span>
                                    </div>
                                    <div className="detailItem">
                                        <span className="itemKey">Category Name :</span>
                                        <span className="itemKey2">{viewdata?.template_category?.template}</span>
                                    </div>

                                    <div className="detailItem">
                                        <span className="itemKey">Body :</span>
                                        <div>
                                            <span className="itemKey2" dangerouslySetInnerHTML={{ __html: viewdata && viewdata?.body }}></span>
                                        </div>

                                    </div>

                                    <div className="detailItem">
                                        <span className="itemKey"> Status :</span>
                                        <span className="itemKey2">{viewdata?.status ? "Active" : "Inactive"}</span>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog >
                        <Dialog
                            open={openCreateTemplete}
                            onClose={handleClose}
                            fullWidth={true}
                            maxWidth="lg"
                            sx={{
                                '& .MuiDialog-paper': {
                                    borderRadius: '16px',
                                },
                            }}
                        >
                            <DialogTitle sx={{ backgroundColor: "whitesmoke", height: "60px", borderBottom: "solid 1px #cccccc", fontFamily: "sans-serif" }}>{ID ? 'Edit' : 'Create'} Template <BorderColorOutlinedIcon style={{ color: "blue" }} /></DialogTitle>
                            <CloseIcon
                                style={{
                                    color: 'blue',
                                    position: 'absolute',
                                    right: 12,
                                    top: 15,
                                    cursor: "pointer"
                                }}
                                onClick={handleClose}
                            />
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <form onSubmit={handleSubmit}>
                                            <div className="add-new-user-form">
                                                <div className="formInput" style={{ flexBasis: "85%" }}>
                                                    <label>Category</label>
                                                    <CreatableSelect
                                                        className="select-a"
                                                        classNamePrefix="select"
                                                        placeholder="Select  category"
                                                        defaultValue={formValues.template_category}
                                                        isValidNewOption={() => false}
                                                        isSearchable={true}
                                                        name={"template_category"}
                                                        options={
                                                            emailTemplateCategory && emailTemplateCategory.data && emailTemplateCategory.data.rows.map((option) => {
                                                                return { label: option.template_category, value: option.id, name: "template_category", description: option.description }
                                                            })
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    {errStatus === true && formValues && formValues.template_category === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.template_categoryErr}</p>}
                                                </div>
                                                <div className="formInput" style={{ marginBottom: "7px", flexBasis: "80%" }}>
                                                    <label>Description</label><br />
                                                    <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>{descriptionValue}</span>

                                                </div>
                                                <div className="formInput" style={{ marginBottom: "7px" }}>
                                                    <label>Template Name</label>
                                                    <InputFields
                                                        name="template_name"
                                                        type="text"
                                                        placeholder="Template Name"
                                                        defaultValue={formValues.template_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errStatus === true && formValues && formValues.template_name === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.template_nameErr}</p>}
                                                </div>
                                                <div className="formInput">
                                                    <label>Subject</label>
                                                    <InputFields
                                                        name="subject"
                                                        type="text"
                                                        placeholder="Subject"
                                                        defaultValue={formValues.subject}
                                                        onChange={handleChange}
                                                    />
                                                    {errStatus === true && formValues && formValues.subject === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.subjectErr}</p>}
                                                </div>
                                                <div className="formInput">
                                                    <label>Body</label>
                                                    <div
                                                        style={{ padding: "0px" }}
                                                        className="drop-area"
                                                        onDrop={handleDrop}
                                                        onDragOver={handleDragOver}
                                                    >
                                                        {
                                                            formValues.fileName &&
                                                            <button style={{
                                                                display: 'flex', justifyContent: 'space-between', padding: '8px',
                                                                width: '50%', border: '2px solid #3892eb', borderRadius: '2px', background: 'white', marginBottom: '12px'
                                                            }} type='button'>
                                                                <span style={{ width: '80%', wordWrap: 'break-word', textAlign: 'left' }}>
                                                                    {formValues.fileName}
                                                                </span>
                                                                <Close sx={{ color: 'white', background: '#3892eb', padding: '1px', borderRadius: '2px' }}
                                                                    onClick={() => {
                                                                        fileInputRef.current.value = '';
                                                                        setFormValues((pre) => ({ ...pre, body: "", fileName: "" }))
                                                                    }}
                                                                />
                                                            </button>
                                                        }
                                                        <button style={{
                                                            width: "100%",
                                                            padding: "10px 10px",
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            border: "1px solid #42526e",
                                                            boxSizing: "border-box",
                                                            backgroundColor: "#fff",
                                                        }}
                                                            type='button'
                                                            onClick={triggerFileInput}
                                                        >
                                                            Drag and drop an HTML file here, or click to select a file
                                                            .</button>
                                                        <input
                                                            type="file"
                                                            accept=".html"
                                                            onChange={handleFileUpload}
                                                            style={{ visibility: "hidden", padding: "0px", height: "0px" }}
                                                            ref={fileInputRef}
                                                        />
                                                        {errStatus === true && formValues && formValues.body === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.bodyErr}</p>}
                                                    </div>
                                                </div>
                                                <div className="formInput">
                                                    <Button
                                                        style={{
                                                            width: "60%",
                                                            padding: "10px 10px",
                                                            backgroundColor: "blue",
                                                            color: "white",
                                                            marginLeft: "161px"
                                                        }}
                                                        type="submit">{ID ? "Update" : "Submit"}</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <span style={{ fontFamily: 'serif', fontSize: "16px" }}>Preview</span>
                                        <div className="right-column" style={{ border: "1px" }}>
                                            <div className="html-preview" style={{ overflowY: 'auto', height: '600px' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formValues.body) }}></div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                    </div>
                </div>
            </div>
        </div>
    )

}
export default EmailTemplateList;
