import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import ArchiveIcon from '@mui/icons-material/Archive';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useEffect, useImperativeHandle, useRef, useState } from "react"
import { toast, ToastContainer } from 'react-toastify';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Skeleton, Stack, Switch, Typography } from "@mui/material";
import {domainCreate, domainList, domainUpdate} from "../../redux/features/domainSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUS, STATUSES } from "../../redux/common-status/constant"
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DataGridPro, gridClasses,getGridStringOperators } from "@mui/x-data-grid-pro";
import { Close, Visibility } from "@mui/icons-material";
import PaperComponent from '../../components/common-function/PaperComponent';
import StatusInputValue from '../../components/common-function/StatusOperator';
const { REACT_APP_ENV } = process.env;


const skeletonBase = {name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', action:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const initialFormData = Object.freeze({
    name: "",
    code: "",
    description: "",
    status: true,
});

const formErrData = Object.freeze({
    nameErr: "name is required",
    codeErr: "code is required",
    descriptionErr: "description is required"
});

const viewLabels = {
    name:'Domain Name',
    code:'Domain Code',
    status:'Status',
    description:'Description',
}

const filterableColumns = ['name', 'code', 'status'];



const Domain = () => {

    const dispatch = useDispatch();
    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;
    const { domain_list, domain_update, domain_create, status, count } = useSelector(state => state.domain)
    const [limit, _] = useState(25);
    const [open, setOpen] = useState(false)
    const [updating, setUpdating] = useState(false);
    const [editable, setEditable] = useState(false);
    const [domainsData, setDomainsData] = useState(initialFormData)
    const [filterQuery, setFilterQuery] = useState({order_by:{column:"-id"},skip:0, limit:limit, columns:DefaultFilterColumns.DOMAIN_LIST_COLUMNS}); 
    const [reload, setReload] = useState(false);
    const [Delopen, setDelOpen] = useState(false)
    const [viewOpen, setViewOpen] = useState(false);
    const [errStatus, setErrStatus] = useState(false);

    //grid states and ref starts
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(15).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [deleteId, setDeleteId] = useState(null)
    const [updateStatus, setUpdateStatus] = useState("")
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [sort,setSort]=useState(false)
    //grid states and ref ends

    let gridTotalRows = useRef(null);
    let stopOnRowScrollEnd = useRef(false);
    let editID = useRef(null);
    let clearGridListing = useRef(false);
    let isAnyChange = useRef({});

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );



    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];

    const domainsColumns = [
        { field: "id", headerName: "ID", width: 70, sortable:false, pinnable:false, hideable:false, filterable:false },
        {
            field: "name",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Name</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && skeletonRows.length === 0 && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE",fontSize:'1rem'}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 400,
            sortable:false,
            pinnable:false,
            renderCell: params =>{
                return params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> : params.row.name
            }
        },
        {
            field: "code",
            filterOperators,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Code</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && skeletonRows.length === 0 && handleSort("code")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE",fontSize:'1rem'}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 300,
            sortable:false,
            pinnable:false,
            renderCell: params =>{
                return params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={300}/> : params.row.code
            }
        },

        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
            </Stack>,
            width: 300,
            sortable:false,
            pinnable:false,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={300}/> 
                    
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                    
                        <Switch name="status" checked={params.row.status} onChange={()=>confirmedDelete(params.row)}/>

                        <div className="actionButton">
                            <UcActionComponent
                                moduleName="dynamic_table"
                                rowid={params.row.id}
                                addMoreData={params.row}
                                // isDelete={!!deleteDomain}
                                editButton={false}
                                viewButton={onView ?? false}
                                deleteButton={() => handleDelete(params.row)}
                                deleteIconName="ArchiveIcon"
                            /> 
                        </div>

                    </div>

                );
            },
        },
    ];

    const onSubmit = (evt) => {

        evt.preventDefault();

        if(domainsData.name === '' || domainsData.code === '' || domainsData.description === ''){
            setErrStatus(true);
            return;
        }

        clearGridListing.current = true;

        const finalData = domainsData.id ? { ...domainsData, updated_by: userId } : { ...domainsData, created_by: userId }
        gridTotalRows.current = gridRows.length;
        setGridRows([]);
        dispatch(domainCreate(finalData))
        setErrStatus(false)
        setReload(true)
        onCloseModal()
        setSort(false)
    }

    const onView = (data) => {
        setViewOpen(true);
        setDomainsData({ ...data });
        setEditable(false);
        setOpen(true);

    }

    // Edit Domain
    const handleEdit = (data) => {        
        setDomainsData({ ...domainsData, ...data, updatedBy: 1 });
        setViewOpen(false);
        setUpdating(true)
        setEditable(true);
        setOpen(true);
    }

    // Delete Domain
    const deleteDomain = async (data) => {

        let addvalues = {
            code: data.code,
            description: data.description,
            name: data.name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
            id: data.id
        }

        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(domainUpdate(addvalues))
    }

    const confirmedDelete = (id) => {
        deleteDomain(id);
        handleClose()
    }

    const handleClose = () => {
        setOpen(false);
        setDelOpen(false);
        setContextMenu(null);
    }

    //  AddDomain Modal
    const onAddDomain = () => {
        setOpen(true)
        setEditable(true);
        setViewOpen(false);
        setDomainsData(initialFormData);
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpen(false)
        setUpdating(false);
        setEditable(false);
        setDomainsData({});
    }

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        setSort(true)
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = {...filterQuery};
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }

        setFilterQuery(newFilterData)
       
        setGridRows([]);
    }

    const handleDelete = (id) => 
    {
        setDeleteId(id)
        if (id.status === true) {
            setUpdateStatus("InActive")
        } else {
            setUpdateStatus("Active")
        }
        setDelOpen(true);
    };

    const onFilterChange = (props) =>
    {
        if(props.items.length > domainsColumns.length-1)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            setFilterQuery({"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":DefaultFilterColumns.DOMAIN_LIST_COLUMNS})
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    if(!newProps.items[index].value){
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            
            if(!(filterModal?.items?.length > props?.items?.length)){
                 // if same col is selected then do not let it select - starts
                 let selectedCols = props.items.map(item=>item.columnField);
                 let prevLen = selectedCols.length;
 
                 if((new Set(selectedCols)).size != prevLen){
                     toast.warn("Please select distinct column fields!")
                     return;
                 }
                 // if same col is selected then do not let it select - ends
 
                setFilterModel(props)
                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
    
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }
            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = {[props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-id"}, columns:DefaultFilterColumns.DOMAIN_LIST_COLUMNS};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartswith',
            endsWith: 'iendswith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                     // if no changes on filter open starts
                     props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}});
                    setSort(false)
                }
            }
            return;
        }
        setSort(false)
    }


    const handleChange = (evt) =>
    {
        const { name, value } = evt.target || evt;
        if(name === 'status')
            setDomainsData((pre)=>({...pre, [name]:evt.target.checked}))
        else
            setDomainsData((pre)=>({...pre, [name]:value}))
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };


    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
        
        switch(actionType)
        {
            // case 'status':
            //         handleDelete(row);
            //         break;

            case 'view':
                    onView(row);
                    break;
        }

        setContextMenu(null);

    }

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = {...filterQuery};
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(updatedFilter);
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };
    if(reload === true && status === STATUSES.SUCCESS && domain_list.message){
        if (domain_list.status === 200) {
            if(clearGridListing.current){
                if(sort){
                    setGridRows([...domain_list.data.rows])
                   
                }
                else{
                    setGridRows([...domain_list.data.rows].sort(item=>item.status ? -1 : 1));
                }
                clearGridListing.current = false;
                SET_MAX_ROW_LENGTH(domain_list.data.count);
                setSkeletonRows([])
            }
            else{
                if(sort){
                    setGridRows((pre)=>[...pre, ...domain_list.data.rows]);
                  
                }
                else{
                    setGridRows((pre)=>[...pre, ...domain_list.data.rows].sort(item=>item.status ? -1 : 1));
                }
               
                SET_MAX_ROW_LENGTH(domain_list.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(domain_list.message)
        }
        setReload(false);
        stopOnRowScrollEnd.current = false;
    };

    if(reload === true && status === `${STATUSES.SUCCESS}_domain_Update` && domain_update.message)
    {
        if (domain_update.status === 200) 
        {
            // setFilterQuery({...filterQuery, skip:0, limit:gridRows.length})
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...domain_update.data }
                else
                    return item
            })
            setGridRows(copyGridRows)
            toast.success(domain_update.message)
        } 
        else
        {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(domain_update.message)
        }
        setReload(false);
    };

    if (reload === true && status === `${STATUSES.SUCCESS}_domain_create` && domain_create && domain_create.message) {
        if (domain_create.status === 200) {
            setFilterQuery({...filterQuery, skip:0, limit:gridTotalRows.current})
            toast.success(domain_create.message)
            // dispatch(getCoreSettingsList(filterQuery))
        } else {
            toast.error(domain_create.message)
        }
        setReload(false)
    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setSkeletonRows(()=>Array(filterQuery.limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        setReload(true);
        dispatch(domainList(filterQuery))
    }, [filterQuery])

    return (
        <div className="new">
            <div className="newContainer">

                <div style={{padding:'20px'}}>
                
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Domains</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={onAddDomain}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={domainList} filter={{...filterQuery, limit: 25, skip:0}} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort}/> 
                            </Stack>
                        </div>
                    </div>
                   
                    <ToastContainer />

                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={ skeletonRows.length === 0 && !!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        sx={{
                            height: 800,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                    display: 'block'
                                    }
                                }
                                }
                        }}
                        
                        rows={gridRows?.concat(skeletonRows)}
                        columns={domainsColumns}
                        filterModel={filterModal}
                        
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        components={{
                            ColumnMenuIcon: ()=>(<MoreHorizIcon sx={{color:"#171c26ba"}}/>),
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                       
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                            }
                          }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{viewOpen ? "Domain Details" : "Add Domain"}</DialogTitle>

                    <DialogContent style={{minHeight:'400px'}}>
                        <form className="regulazation-form" onSubmit={onSubmit}>
                            {
                                viewOpen ?
                                
                                <div>
                                        {
                                            Object.keys(domainsData).map(item=>(
                                                item !== 'id' &&
                                                <div className="table-row-regular">

                                                    <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>{viewLabels[item]}</span></p></div>
                                                    <div className={` ${item === "status" ? STATUS.COMPOFF[domainsData[item]] + " btns statuscard" : ""}`}>
                                                        <p>
                                                            <span>{ item === "status" ? STATUS.COMPOFF[domainsData[item]] : domainsData[item]}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        
                                </div>

                                :

                                <div>
                                    <div className="add-new-user-form">
                                            <div style={{width:'100%'}}>
                                                <InputFields
                                                    label={"Domain Name"}
                                                    labelStyle={{fontWeight:'500'}}
                                                    name="name"
                                                    defaultValue={domainsData.name}
                                                    value={domainsData.name}
                                                    type="text"
                                                    placeholder="Domain Name"
                                                    onChange={handleChange}
                                                    errStatus={errStatus}
                                                    formErr={formErrData.nameErr}
                                                />
                                            </div>

                                            <div style={{width:'100%'}}>
                                                <InputFields
                                                    label={"Domain Code"}
                                                    labelStyle={{fontWeight:'500'}}
                                                    name="code"
                                                    defaultValue={domainsData.code}
                                                    value={domainsData.code}
                                                    type="text"
                                                    onChange={handleChange}
                                                    placeholder="Domain Code"
                                                    errStatus={errStatus}
                                                    formErr={formErrData.codeErr}
                                                />
                                            </div> 

                                            <InputTextareaField

                                                label={"Description"}
                                                labelStyle={{fontWeight:'500'}}
                                                name="description"
                                                defaultValue={domainsData.description}
                                                value={domainsData.description}
                                                type="text"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                errStatus={errStatus}
                                                formErr={formErrData.descriptionErr}
                                            />

                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                            <label style={{marginRight:'20px', fontWeight:'500'}}>
                                                Status
                                            </label>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <p>Inactive</p>
                                                <Switch name="status" checked={domainsData.status} onChange={handleChange}/>
                                                <p>Active</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className={reload === true ? 'btn-disable':'submit-modal'}
                                    disabled={reload === true ? true :false } type="submit" style={{float:'right'}}>Submit</button> 
                                </div>

                            }

                        </form>
                    </DialogContent>
                </Dialog>

                <DeleteDialogModal
                    open={Delopen}
                    onClose={handleClose}
                    heading={updateStatus}
                    paragraph={`Are You Sure To ${updateStatus}`}
                    handleArchive={confirmedDelete}
                    id={deleteId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('view')} sx={{color:'rgba(0,220,0,.8)'}}> <Visibility sx={{marginRight:'8px'}}/> View</MenuItem>
                    {/* <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px'}}/> Active / In Active</MenuItem> */}
                
                </Menu>


                </div>
            </div>

        </div>
    )
}

export default Domain