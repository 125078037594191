import "./master.scss"
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import SyncIcon from '@mui/icons-material/Sync';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Edit from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, IconButton, makeStyles, Menu, MenuItem, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { gridClasses, GridFooterContainer } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getpymenttype, paymenttypeEdit, paymentypeCreat, paymenttypeUpdate } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Add, Close, Sync } from "@mui/icons-material";
import { InputTextareaField } from "../../components/common-function/InputFields";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridFooter } from "@mui/x-data-grid";
import { useRef } from "react";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';

const initialFormData = Object.freeze({
    name: "",
    description: "",
    status: true,
});


const formErrData = Object.freeze({
    nameErr: "Payment name is required",
    descriptionErr: "Payment description is required",
    statusErr: "Status is required"
});

const skeletonBase = { name: '_skeleton_', status: '_skeleton_', description: '_skeleton_' };
const filterableColumns = ['name', 'description', 'status'];
function PaperComponent(props) {
    let [activeDrags, setActiveDrags] = useState(0)
    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };
    let dragHandlers = { onStart: onStart, onStop: onStop };
    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} />
        </Draggable>
    );
}
const PymentType = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, paymenttype_List, paymenttype_Create, paymenttype_Edit, paymenttype_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PAYMENT_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [loadedRows, setLoadedRows] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [skeletonRows, setSkeletonRows] = useState(Array(15).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [colSortingPerform, setColSortingPerform] = useState([]);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === "status") {
            setFormValues({ ...formValues, ["status"]: evt.target.checked })
        }
        else {
            setFormValues({ ...formValues, [name]: value })
        }

    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.description === "" || formValues.name === "" || formValues.status === "") {
            setErrStatus(true)
        }

        clearGridListing.current = true;

        if (formValues.description !== "" && formValues.name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(paymenttypeUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = loadedRows.length;

                dispatch(paymentypeCreat(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_paymenttype_Edit` && paymenttype_Edit && paymenttype_Edit.data) {
        let editData = paymenttype_Edit.data
        setFormValues({
            name: editData.name,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(paymenttypeEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        stopOnRowScrollEnd.current = true;
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = { ...data }
        let copyRows = [...loadedRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setLoadedRows(copyRows);
        let ID = data.id;
        dispatch(paymenttypeUpdate({ ID, addvalues }))
    }

    const handleRefresh = () => {
        dispatch(getpymenttype(filterQuery))
    }

    // sorting

    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {

            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setLoadedRows([]);

    }


    const actionColumn = [
        {
            field: "id",
            headerName: "Id",
            hideable: false,
            filterable: false,
            pinnable: false
        },
        {
            field: "name",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Payment Type</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            minWidth: 350,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.name === "_skeleton_" ? <Skeleton width={350} /> :
                        <div>
                            {params.row.name}
                        </div>
                );
            },
        },
        {
            field: "description",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Payment Description</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("description")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            minWidth: 350,
            minWidth: 350,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.description === "_skeleton_" ? <Skeleton width={350} /> :
                        <div>
                            {params.row.description ? params.row.description : "N/A"}
                        </div>
                );
            },
        },
        {
            field: "status",
            // headerName: "Status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("status")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon />
                    </IconButton>
                </Box>
            </Stack>,
            minWidth: 200,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.status === "_skeleton_" ? <Skeleton width={350} /> :
                        <div className="cellAction" style={{ justifyContent: "space-between", alignItems: "flex-end", width: "100%" }}>
                            <Switch checked={params.row.status === true ? true : false} onChange={() => handleArchive(params.row)} />
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="pyment_type"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                />
                            </div>
                        </div>
                );

            },

        }
    ];


    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (loadedRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };
    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = loadedRows.length;
        updatedFilter.limit = loadedRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - loadedRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handleMenuAction = (actionType) => {
        let row = loadedRows.find(item => item.id === selectedRow);

        switch (actionType) {
            // case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }

    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setLoadedRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.PAYMENT_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            // setFilterModel(props)
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    if (!newProps.items[index].value) {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)

            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {
            if (!(filterModal?.items?.length > props?.items?.length)) {
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }
            }

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-status" }, columns: DefaultFilterColumns.PAYMENT_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartsWith',
                endsWith: 'iendsWith'
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})

                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })

                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setLoadedRows([]);
                    }


                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_paymenttype_Update` && paymenttype_Update && paymenttype_Update.message) {
        if (paymenttype_Update.status === 200) {
            // let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: loadedRows.length }));
            // setFilterQuery(str)
            let copyLoadRows = [...loadedRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...paymenttype_Update.data }
                else
                    return item
            })
            setLoadedRows(copyLoadRows)
            toast.success(paymenttype_Update.message)
        } else {
            let copyLoadedRows = [...loadedRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setLoadedRows(copyLoadedRows);
            toast.error(paymenttype_Update.message)
        }
        setReload(false);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_paymenttype_Create` && paymenttype_Create && paymenttype_Create.message) {
        if (paymenttype_Create.status === 200) {
            toast.success(paymenttype_Create.message)
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
            setFilterQuery(str)
            setLoadedRows([]);
        } else {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridTotalRows.current }));
            setFilterQuery(str)
            setLoadedRows([]);
            toast.error(paymenttype_Create.message)
        }
        setReload(false)

    }

    if (reload === true && status === `${STATUSES.SUCCESS}` && paymenttype_List && paymenttype_List.message) {
        if (paymenttype_List.status === 200) {
            if (clearGridListing.current) {
                setLoadedRows([...paymenttype_List.data.rows].sort(item => item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
            }
            else {
                setLoadedRows((pre) => [...pre, ...paymenttype_List.data.rows].sort(item => item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(paymenttype_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(paymenttype_List.message)
        }

        setReload(false)
        stopOnRowScrollEnd.current = false;

    }

    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true)
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getpymenttype(filterQuery))
    }, [filterQuery])

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    {/* <div className="top-headings">
                        <h3>Payment Type</h3>
                        <div>
                            <Tooltip title="Create Payment Type">
                                <Button endIcon={<BorderColorIcon />} variant="outlined" onClick={handleOpen}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getpymenttype} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: loadedRows.length == 0 ? 25 : loadedRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </div>
                    </div> */}
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography className="headerTitleName" variant="h3">Payment Type</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Country">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getpymenttype} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: loadedRows.length == 0 ? 25 : loadedRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} />
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction={"row"}>
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={loadedRows.concat(skeletonRows)}
                            getRowClassName={(params) => {
                                return params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-odd' : 'Mui-even';
                            }}
                            columns={actionColumn}
                            loading={skeletonRows.length === 0 && !!reload}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onRowClick={() => handleClickEdit}
                            onFilterModelChange={onFilterChange}
                            filterMode="server"
                            disableSelectionOnClick
                            sx={{
                                height: 700,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#438bf969",
                                },
                                ".actionButton": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".actionButton": {
                                            display: 'block'
                                        }
                                    }
                                }
                            }}
                            components={{
                                ColumnMenuIcon: () => (<MoreHorizIcon sx={{ color: "#171c26ba" }} />),
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooterPagination={true}
                        />
                    </Stack>
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}>
                        <Close />
                    </Button>
                    <DialogTitle id="draggable-dialog-title" style={{ backgroundColor: "#438bf9", color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>{ID ? 'Edit' : 'Add'} Payment Type</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">

                                <InputTextareaField
                                    label={"Payment Type"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Payment Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <InputTextareaField
                                    label={"Payment Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Pyment Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />


                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} mb={2} width={'100%'}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" defaultChecked={formValues.status} defaultValue={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} width={'100%'}>
                                    <Button variant="contained" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                                </Stack>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Payment`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >

                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    {/* <MenuItem onClick={()=>handleMenuAction('status')} sx={{color:'rgba(220,0,0,.8)'}} > <ArchiveIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Active / In Active</MenuItem> */}

                </Menu>
            </div>
        </div >
    )
}

export default PymentType;