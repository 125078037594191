import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const getEvaluationQuestSetList = createAsyncThunk("evaluation-quest-set/list", async (filter) => {
    if (filter === 'type') {
        return []
    }
    
    const res = await DataService.evaluation_question_set_list(filter);
    return res.data;
});

export const evaluationQuestSetCreate = createAsyncThunk("evaluation-quest-set/create", async (data) => {
    const res = await DataService.evaluation_question_set_create(data);
    return res.data;
});

export const evaluationQuestSetEdit = createAsyncThunk("evaluation-quest-set/edit", async (id) => {
    const res = await DataService.evaluation_question_set_edit(id);
    return res.data;
});

export const evaluationQuestSetUpdate = createAsyncThunk("evaluation-quest-set/update", async (data) => {
    const res = await DataService.evaluation_question_set_update(data.ID, data.addvalues);
    return res.data;
});

export const evaluationReviewCreate = createAsyncThunk("evaluation-review/create", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await DataService.evaluation_review_create(data);
    return res.data;
});

export const evaluationReviewAttchechmentUpload = createAsyncThunk("evaluation-review-attchement/upload", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await DataService.evaluation_question_attchement_post(data);
    return res.data;
});

const evaluationSlice = createSlice({
    name: "evaluation",
    initialState: {
        evaluationQuest_list: [],
        evaluationQuest_Create: [],
        evaluationQuest_Edit: [],
        evaluationQuest_Update: [],
        evaluationReview_create: [],
        evaluationReviewAttchechment_upload: [],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///evaluationQuest  list
            .addCase(getEvaluationQuestSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getEvaluationQuestSetList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.evaluationQuest_list = action.payload;
            })
            .addCase(getEvaluationQuestSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///evaluationQuest  create
            .addCase(evaluationQuestSetCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(evaluationQuestSetCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_evaluationQuest_Create`;
                state.evaluationQuest_Create = action.payload;
            })
            .addCase(evaluationQuestSetCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///evaluationQuestSet  edit
            .addCase(evaluationQuestSetEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(evaluationQuestSetEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.evaluationQuest_Edit = action.payload;
            })
            .addCase(evaluationQuestSetEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///evaluationQuest Update
            .addCase(evaluationQuestSetUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error= ""
            })
            .addCase(evaluationQuestSetUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_evaluationQuest_Update`;
                state.evaluationQuest_Update = action.payload;
                state.error= ""
            })
            .addCase(evaluationQuestSetUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///evaluation Review Create
            .addCase(evaluationReviewCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error= ""
            })
            .addCase(evaluationReviewCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_evaluationReview_create`;
                state.evaluationReview_create = action.payload;
                state.error= ""
            })
            .addCase(evaluationReviewCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///evaluation Review attechemnt
            .addCase(evaluationReviewAttchechmentUpload.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error= ""
            })
            .addCase(evaluationReviewAttchechmentUpload.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_evaluationReviewAttchechment_upload`;
                state.evaluationReviewAttchechment_upload = action.payload;
                state.error= ""
            })
            .addCase(evaluationReviewAttchechmentUpload.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default evaluationSlice.reducer;