import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Menu, MenuItem, Radio, RadioGroup, Skeleton, Stack, Switch, TablePagination, Tooltip } from "@mui/material";
import Paper from '@mui/material/Paper';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScreeningMasterCreate, ScreeningMasterEdit, ScreeningMasterUpdate, getScreeningMasterList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, BorderColorOutlined, Close, Edit } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import Draggable from "react-draggable";
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";



const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const initialFormData = Object.freeze({
    test_type: "",
    test_type_key: "",
    description: "",
    status: true,
    is_for_agent_panel: false,
    is_mcq: false,
});

const formErrData = Object.freeze({
    testTypeErr: "Name is required",
    descriptionErr: "Description is required",
    keyErr: "Test type key is required",
    statusErr: "Status is required"
});

let flag = 1;


const SubScreeningMaster = ({onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu, clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu}) => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, screeningmaster_List, screeningmaster_Create, screeningmaster_Edit, screeningmaster_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [isAgentPanel, setIsAgentPanel] = useState(false);
    const [isMcq, setIsMcq] = useState(false)
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    // useEffect(() => {
    //     dispatch(getScreeningMasterList(filterQuery))

    // }, [])

    // error handling
    // useEffect(() => {
    //     if (status === STATUSES.FAILURE && error) {
    //         toast.error(error.message)

    //         setReload(false)
    //     }
    // }, [error])
    useEffect(() => {
        if (isAgentPanel) {
            setIsMcq(true);
        } else {
            setIsMcq(false);
        }
    }, [isAgentPanel]);


    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setIsMcq(false);
        setIsAgentPanel(false);
        setContextMenu(null)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningmaster_Update` && screeningmaster_Update && screeningmaster_Update.message) {
        if (screeningmaster_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            setFilterQuery(str)
            toast.success(screeningmaster_Update.message)

        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(screeningmaster_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningmaster_Create` && screeningmaster_Create && screeningmaster_Create.message) {
        if (screeningmaster_Create.status === 200) {
            toast.success(screeningmaster_Create.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
        } else {
            toast.error(screeningmaster_Create.message)
        }
        setReload(false)
    }
    const handleOpen = () => setOpen(true);

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setFormValues({ ...formValues, [name]: name === 'status' ? checked : value })
    }

    const handleChangeForAgentPanel = (evt) => {
        const { name, value, checked } = evt.target || evt;
        setIsAgentPanel(checked);
        if (checked === true) {
            setIsMcq(true);
        }
    };

    const handleChangeForMcq = (evt) => {
        if (!isAgentPanel) {
            setIsMcq(evt.target.checked);
        }
    };


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.test_type === "" || formValues.status === "") {
            setErrStatus(true)
        }
        clearGridListing.current = true;
        if (formValues.test_type !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId =rtm_user?.user?.data.id
            let addvalues = {
                ...formValues, created_by: userId, assigned_by: userId,
                status: formValues.status === true ? 1 : 0,
                is_mcq: isMcq,
                is_for_agent_panel: isAgentPanel
            }
            setErrStatus(false)
            setReload(true)
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(ScreeningMasterUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(ScreeningMasterCreate(addvalues))
            }

            handleClose()

        }
    }

    if (flag ===1  && status === `${STATUSES.SUCCESS}` && screeningmaster_Edit && screeningmaster_Edit.data) {
        if(screeningmaster_Edit.status === 200){
            let editData = screeningmaster_Edit.data
            setFormValues({
                test_type: editData.test_type,
                description: editData.description,
                test_type_key: editData.test_type_key,
                status: editData.status,
                is_mcq: editData.is_mcq,
                is_for_agent_panel: editData.is_mcq
            })
            setIsMcq(editData.is_mcq)
            setIsAgentPanel(editData.is_for_agent_panel)
            setOpen(true)
        } else{
            toast.error(screeningmaster_Edit.message)
        }
        setReload(false)
        flag = 0;
    }

    const handleClickEdit = (id) => {
        setID(id)
        dispatch(ScreeningMasterEdit(id))
        flag = 1;
        setReload('edit_data')

    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === 1) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            test_type: data.test_type,
            description: data.description,
            test_type_key: data.test_type_key,
            status: data.status === 0 ? 1 : 0,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(ScreeningMasterUpdate({ ID, addvalues }))
    }

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
            
        switch(actionType)
        {
            case 'status':
                    handleArchive(row);
                    break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

  
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningMasterList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningMasterList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(screeningmaster_List && screeningmaster_List.data && screeningmaster_List.data.rows && screeningmaster_List.data.row_count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const actionColumn = [
        {
            field: "test_type",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Test Type</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("test_type")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.test_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> :
                    <div>
                        <CustomeTooltip
                            title={params?.row?.test_type || "N/A"}
                            data={params?.row?.test_type || "N/A"}
                        />
                    </div>
                );
            },
        },

        {
            field: "test_type_key",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Test Type Key</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("test_type_key")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.test_type_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280} /> : 
                    <div>
                        
                        <div>
                            <CustomeTooltip title={params?.row?.test_type_key || "N/A"}
                            data={params?.row?.test_type_key || "N/A"} />
                        </div>
                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Description</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("description")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={280}/> : 
                    <div>
                        <CustomeTooltip title={params?.row?.description || "N/A"}
                        data={params?.row?.description || "N/A"} />
                    </div>
                 );
            },
        },
        {
            field: "status",
            // headerName: "Status",
            headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Status</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("status")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
            width: 160,
            type:'singleSelect',
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400}/> : <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                            <Switch  name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)}/>
                            <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="test-type"
                                rowid={params?.row?.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)}
                            />
                            </div>
                        </div>
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: "120",
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="cellAction">
        //                 <UcActionComponent
        //                     deleteIconName="ArchiveIcon"
        //                     moduleName="test-type"
        //                     rowid={params.row.id}
        //                     addMoreData=""
        //                     editLinkUrl=""
        //                     viewLinkUrl=""
        //                     isDelete={true}
        //                     editButton={handleClickEdit}
        //                     viewButton={false}
        //                     deleteButton={() => handleArchive(params.row)} />
        //             </div>
        //         );
        //     },
        // },
    ];

    if (reload === true && status === STATUSES.SUCCESS && screeningmaster_List && screeningmaster_List.message) {
        if (screeningmaster_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...screeningmaster_List.data.rows]);
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(screeningmaster_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...screeningmaster_List.data.rows]);
                SET_MAX_ROW_LENGTH(screeningmaster_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(screeningmaster_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Screening Master</h3>
                        <div>
                                <Stack direction="row">
                                    <Button variant="outlined" onClick={handleOpen}>Create<BorderColorOutlined /></Button>
                                    <RefreshButton api={getScreeningMasterList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length === 0 ? 25 : gridRows.length, skip: 0})} style={{marginTop: 0}}
                                    setReload={setReload}
                                    clearGridListing={clearGridListing} />
                                </Stack>
                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload &&  */}
                        <DataGridPro
                            className="datagrid"
                            filterMode="server"
                            loading={!!reload}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            sx={{
                                height: 700,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#438bf969"
                                },
                                ".actionButton": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".actionButton": {
                                            display: 'block'
                                        }
                                    }
                                }
                            }}
                            rows={gridRows?.concat(skeletonRows)}
                            columns={actionColumn}
                            filterModel={filterModal}
                            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 ? 'Mui-even' : 'Mui-odd'}
                            components={{
                                ColumnMenuIcon: MoreHorizIcon,
                                NoResultsOverlay: CustomNoRowsOverlay
                            }}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu
                                }
                            }}
                            onFilterModelChange={onFilterChange}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooter
                        />
                    {/* } */}
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        // color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Screening Master </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div style={{width: '100%'}}>
                                    <InputFields
                                        label={"Test Type"}
                                        name="test_type"
                                        defaultValue={formValues.test_type}
                                        type="text"
                                        placeholder="Test Type"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.testTypeErr}
                                    />
                                </div>

                                {/* {formValues.id ? <div className="formInput">
                                    <label >
                                        Test Type Key
                                    </label>
                                    <input
                                        readOnly
                                        label={"Test Type Key"}
                                        name="test_type_key"
                                        defaultValue={formValues.test_type_key}
                                        type="text"
                                        placeholder="Test-Type-Key"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""} */}

                                <div style={{width:'100%'}}>
                                    <InputTextareaField
                                        label={"Description"}
                                        name="description"
                                        defaultValue={formValues.description}
                                        type="text"
                                        placeholder="Description"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.descriptionErr}
                                    />
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <label style={{marginRight: '20px'}}>
                                        Status
                                    </label>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <p>InActive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>

                                {/* <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        value={formValues.status}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="InActive" />

                                    </RadioGroup>
                                </FormControl> */}

                                {/* <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is for Agent Panel</FormLabel>
                                    <RadioGroup
                                        value={isAgentPanel}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_for_agent_panel"
                                        onChange={handleChangeForAgentPanel}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />

                                    </RadioGroup>
                                </FormControl> */}
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Is For Agent Panel
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>No</p>
                                        <Switch name="is_for_agent_panel" checked={isAgentPanel} onChange={handleChangeForAgentPanel}></Switch>
                                        <p>Yes</p>
                                    </div>
                                </div>
                                {/* <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is MCQ</FormLabel>
                                    <RadioGroup
                                        value={isMcq.toString()}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_mcq"
                                        onChange={handleChangeForMcq}
                                        disabled={isAgentPanel} // Disable if isAgentPanel is true
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl> */}
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Is MCQ
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>No</p>
                                        <Switch name="is_mcq" checked={isMcq} onChange={handleChangeForMcq}/>
                                        <p>Yes</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Test Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                        ? {top : contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{color: 'rgba(0, 0, 220, 0.8)'}}>
                        <Edit sx={{marginRight:'8px', fontSize: '.98rem'}}/> Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')} sx={{color: 'rgba(220, 0, 0, 0.8)'}}>
                        <ArchiveIcon sx={{marginRight: '8px', fontSize: '.98rem'}} />
                        Active / InActive
                    </MenuItem>
                </Menu>
            </div>
        </div >
    )
}

const ScreeningMaster = DataGridProLazyLoadHOC(SubScreeningMaster, {filterColumns: DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS?.map(item=>item.replaceAll('"',"").trim()), getDispatcherAsyncThunk:getScreeningMasterList, gridColumns:['test_type', 'test_type_key', 'description', 'status']})

export default ScreeningMaster;

