import React, { useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { addIrrelevantComment } from '../../redux/features/callingSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";

export default function Add_Irrelevant_comment({ showIrrlenvent, handleClose, storyId, setReload, reload }) {
    const dispatch = useDispatch()
    const [AddComment, setAddComment] = useState('')
    const submitIrrelevantData = (evt) => {
        evt.preventDefault();
        if (AddComment === "") {
            return toast.error("Irrelevant comment  is required")
        }
        else {
            const addvalues = {
                resources: LocalStorageDataService.userDetail().id,
                comments: AddComment,
                issue: storyId,
                is_irrelevant: true
            }
            dispatch(addIrrelevantComment(addvalues))
            setReload(true)
            handleClose()
        }
    }

    return (
        <div>
            {/* form section */}
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"sm"}
                open={showIrrlenvent}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{
                    left: "68%",
                    height: "340px",
                    width: "500px",
                    top: "25px",
                }}
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Add Irrelevant Comment'}</DialogTitle>
                <DialogContent>
                    <form>
                        <div className="add-new-user-form">
                            <div className='formInput' style={{ flexBasis: "100%" }}>
                               <label> Add the Comment </label>
                              <textarea name="add_comment"
                                            onChange={(evt) => setAddComment(evt.target.value)} rows="3" placeholder="Add the Comment"></textarea>
                            </div>
                        </div>

                        <Tooltip title="Submit">
                            <button
                                style={{
                                    width: "90px",
                                    // padding: "7px 17px",
                                    backgroundColor: "green",
                                    marginBottom: "0px",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    color: "white"
                                }}
                                className="submit-modal"
                                onClick={(e) => submitIrrelevantData(e)}
                            >
                                Submit
                            </button>
                          
                        </Tooltip>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
