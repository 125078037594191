import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import api from '../../redux/APIS/api';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import Select from 'react-select';
const { REACT_APP_ENV } = process.env;


export default function ShiftAssignment({ shifts, resource, toggleModel, loadResources }) {
  const [assingedShift, setAssingedShift] = useState(resource.shift_id || "");
  // const [editingResource, setEditingResource] = useState({})
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const handleSubmit = async () => {
    if(!assingedShift){
      toast.error("Please select a shift to proceed!")
      return;
    }
    const data = { resource: [resource.id], shift: assingedShift }
    const res = 
      await api.post("/resources/shift-resource", { ...data, "created_by": userId })

      if (res && res.data && res.data.status === 200) {
        toast.success(res.data.message)
        toggleModel();
        setTimeout(() => {
          loadResources();
        }, 3000);
      }
      if(res && res.data && res.data.status === 400){
        toast.error(res.data.message)
        setTimeout(() => {
          toggleModel();
          loadResources();
        }, 3000);
      }
  }

  const handleChange = (event) => {
    if (event) {
      const { value } = event;
      setAssingedShift(value);
    } else {
      setAssingedShift("")
    }
  };

  useEffect(() => {
    // loadResource();
  }, [])

  return (
    <div className="assign-shift-modal">
      <ToastContainer />
      <InputLabel id="demo-simple-select-label"> <span>Name :</span> {resource.name}</InputLabel>

      <InputLabel id="demo-simple-select-label"><span>Email :</span> {resource.email}</InputLabel>

      <div className="formInput">
        <InputLabel>Select Shift</InputLabel>
     
       <Select
          className="basic-single"
          classNamePrefix="select"
          placeholder="Select shift"
          defaultValue={
            resource && resource.shift && resource.shift.id &&
            {
              "value": resource && resource.shift && resource.shift.id,
              "label": resource && resource.shift && resource.shift.shift_name + `(${moment("10/10/2022 " + resource.shift.start_time).format('LT')} - ${moment("10/10/2022 " + resource.shift.end_time).format('LT')})`
            }
          }
          isSearchable={true}
          isClearable={true}
          options={
            shifts && shifts.map((option) => {
              return {
                label: `${option.shift_name} (${moment("10/10/2022 " + option.start_time).format('LT')} - ${moment("10/10/2022 " + option.end_time).format('LT')})`,
                value: option.id,
                // name: "tms_role"
              }
            })
          }
          onChange={handleChange}
        />

      </div>

      <Button variant='containedi' onClick={handleSubmit} style={{marginTop: "50px"}}> Assign </Button>
    </div>
  );
}