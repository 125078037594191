import { useEffect, useMemo, useRef, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import "./attendacecalender.scss"
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { gridClasses, Menu, MenuItem, Skeleton, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { toast, ToastContainer } from 'react-toastify';
import { STATUS, STATUSES } from "../../redux/common-status/constant"
import { useNavigate } from "react-router-dom"
import { getGridDateOperators, getGridSingleSelectOperators, getGridStringOperators } from "@mui/x-data-grid"
import Filter from "../../components/Filter"
import { Edit, Refresh } from "@mui/icons-material"
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns"
import { DataGridPro } from "@mui/x-data-grid-pro";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { useDispatch, useSelector } from "react-redux";
import { compoffList } from "../../redux/features/compoffSlice";
import { RefreshButton } from "../../components/common-function/RefreshButton";
const { REACT_APP_ENV } = process.env;

const skeletonBase = { resource_id: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', email: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', for_date: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', extra_duration_minutes: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',approved_minutes: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',resource_manager_id: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ,approved_by: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['resource_id', 'email', 'for_date', 'extra_duration_minutes', 'approved_minutes', 'approved_by', 'status'];


const CompOffListing = ({ resourceId, resourceManagerId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))

  const { status, compoff_List } = useSelector(state => state.compOff)
  const [compOffs, setCompOffs] = useState([])

  // Options for DropDown
  const [resourcesOptions, setResourcesOptions] = useState([])
  const [managerResourcesOptions, setManagerResourcesOptions] = useState([])

  // Pagination and Filter for DynamicTable
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [limit, setLimit] = useState(25)
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  const [compOffDetail, setCompOffDetail] = useState({})
  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [options, setOptions] = useState({})

  //updating 
  const [reload, setReload] = useState(false)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterQuery, setFilterQuery] = useState(resourceId && resourceId ? JSON.stringify({ "filter": { "resource_id": { "value": resourceId, "operation": "eq" } }, "skip": 0, "limit": 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }) : resourceManagerId && resourceManagerId ? JSON.stringify({ "filter": { "resource_manager_id": { "value": resourceManagerId, "operation": "eq" } }, "skip": 0, "limit": 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }) : JSON.stringify({ "skip": 0, "limit": 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }))

  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
  const [colSortingPerform, setColSortingPerform] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);

  let isAnyChange = useRef({});
  let stopOnRowScrollEnd = useRef(false);
  let clearGridListing = useRef(false);

  const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
const filterOperatorsForDate = getGridDateOperators().filter(({ value }) =>
  ['is'].includes(value),
);
const filterOperatorsForSelect = getGridSingleSelectOperators().filter(({ value }) =>
  ['is'].includes(value),
);

  const modalDetail = {
    title: "Comp-off Approval",
    editModalTitle: `Comp-off Approval`,
    formInputs: [
      { id: 1, name: "for_date", label: "For Date", placeholder: "Enter For Date", type: "textWithLabel", required: true, disabled: true, filterOperators:filterOperatorsForDate, },
      {
        id: 2, name: "status", label: "Status", placeholder: "Enter For Date", type: "status_text",
        options: [
          { id: 1, label: "PENDING", value: "4" },
          { id: 2, label: "APPROVE", value: "5" },
          { id: 3, label: "CANCEL", value: "6" },
          { id: 4, label: "REJECT", value: "7" },
        ],
        required: true, disabled: true
      },
      { id: 3, name: "extra_duration_minutes", label: "Extra Minutes", placeholder: "Enter Extra Minutes", type: "text", required: true, disabled: true ,filterOperators},
      { id: 4, name: "approved_minutes", label: "Approve Minutes", placeholder: "Enter Approve Minutes", type: "text", required: false, disabled: false ,filterOperators},
      { id: 2, name: "reason", label: "Remark", placeholder: "Enter Remark", type: "textarea", required: false, disabled: false },
    ],
    additionalActions: [
      { id: 1, name: "approve", label: "Approve", className: "btn-submit", disabled: false },
      { id: 2, name: "reject", label: "Reject", className: "btn-cancel", disabled: false },
    ]
  }

  const compOffColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "resource_id",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && resourceId && handleSort("resource_id")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      type: 'singleSelect',
      filterable: resourceId ? false : true,
      sortable: false ,
      valueOptions: managerResourcesOptions,
      renderCell: (params) => {
        return (
          params.row.resource_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.name} </p>
        )
      },
    },
    {
      field: "email",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>email</p></div>,
      width: 275,
      filterable: false,
      sortable: false ,
      renderCell: (params) => {
        return (
          params.row.email === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.email} </p>
        )
      },
    },
    {
      field: "for_date",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>For Date</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("for_date")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      type: "date",
      sortable: false ,
      width: 230,
      renderCell: (params) => {
        return (
          params.row.for_date === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.for_date} </p>
        )
      },
    },
    {
      field: "extra_duration_minutes",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Extra Minutes</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("extra_duration_minutes")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable: false ,
      renderCell: (params) => {
        return (
          params.row.extra_duration_minutes === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.extra_duration_minutes} </p>
        )
      },
    },
    {
      field: "approved_minutes",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Approved Minutes</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("approved_minutes")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable: false ,
      renderCell: (params) => {
        return (
          params.row.approved_minutes === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.approved_minutes} </p>
        )
      },
    },
    {
      field: "resource_manager_id",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Resource Manager</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("resource_manager_id")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable: false ,
      type: 'singleSelect',
      valueOptions: resourcesOptions,

      renderCell: (params) => {
        return (
          params.row.resource_manager_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          
          <p style={{color:'rgba(23, 28, 38, 1)'}}> {params.row.resource_manager_id} </p>
        )
      }
    },
    {
      field: "approved_by",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Approved by</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("approved_by")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable: false ,
      type: 'singleSelect',
      valueOptions: resourcesOptions,
      renderCell: (params) => {
        return (
          params.row.approved_by === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          <div className={`cellWithStatus `}>        
          
            {
              resourcesOptions.map((resource, index) => {
                return (
                 
                  params.row.approved_by === resource.value && <p key={index} style={{color:'rgba(23, 28, 38, 1)'}}>{resource.label} </p>
                )
              })
            }
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Status</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("status")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 200,
      sortable: false ,
      type: 'singleSelect',
      filterOperators:filterOperatorsForSelect,
      valueOptions: [
        { label: "Approved", value: 5 },
        { label: "Cancelled", value: 6 },
        { label: "Pending", value: 4 },
        { label: "Rejected", value: 7 }
      ],
      renderCell: (params) => {
        return (
          params.row.resource_manager_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
          
          <div className="cellAction" style={params.row.status === 4 ? { color: '#cc9600' } :
            params.row.status === 5 ? { color: 'green' } :
              params.row.status === 6 ? { color: '#808080' } :
                params.row.status === 7 ? { color: 'rgb(151, 15, 15)' } :
                  { color: '#000' }
          }>

             
            <p>{params.row.status === 4 ? "Pending" :
              params.row.status === 5 ? "Approved" :
                params.row.status === 6 ? "Cancelled" :
                  params.row.status === 7 ? "Rejected" : "No Request"}</p>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "90",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="actionButton">
            <UcActionComponent
              moduleName="compoff-list"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={false}
              editButton={false}
              viewButton={viewCompOff}
              deleteButton={false}
              additionalAction={false}
              extraButton={false}
              deleteIconName=""
            />
          </div>
        );
      },
    },
  ];

  // Load CompOff List
  const loadCompOff = async () => {
    const qparam = resourceId ? { ...params, filter: { ...params.filter, "resource_id": { "value": resourceId, "operation": "eq" } }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
      : resourceManagerId ? { ...params, filter: { ...params.filter, "resource_manager_id": { "value": resourceManagerId, "operation": "eq" } }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
        : { ...params, filter: { ...params.filter }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
    setLoading(true)
    const res = await dataService.compoff_list(JSON.stringify(qparam));
    if (res && res.data && res.data.data && res.data.data.rows) {
      setCompOffs(res.data.data.rows.map((row, index) => (
        {
          ...row,
          resource_id: row.resource.id,
          name: row.resource.name,
          email: row.resource.email,
          department: row.resource.department,
          role: row.resource.role,
          extra_working_minutes: row.resource_attendance.extra_working_minutes,
          resource_manager_name: row.resource_manager.name,
          resource_manager_id: row.resource_manager.id,
          status_text: STATUS.COMPOFF
        }
      )
      ));
      setRowCount(res.data.data.count)
    }
    setLoading(false);
  }

  //  LoadResourceList
  const getResources = async () => {
    const res = await dataService.resources_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.name + "(" + e.email + ")" } })
      setResourcesOptions(tempArray)
      tempArray.length > 0 && setOptions({ ...options, resource_manager_id: tempArray, approved_by: tempArray })
    }
  }

  //  Load Manager ResourceList
  const getManagerResources = async () => {
    const res = await dataService.all_resources_by_manager()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.name } })
      setManagerResourcesOptions(tempArray)
      tempArray.length > 0 && setOptions({ ...options, resource_id: tempArray })
    }
  }

  // ON View CompOff
  const viewCompOff = (data) => {
    setCompOffDetail({ ...data, approved_minutes: data.extra_duration_minutes || 0, status_text: STATUS.COMPOFF });
    onOpen();
  }

  // Modal Open
  const onOpen = () => {
    setOpen(true);
  }

  // Modal Close
  const onClose = () => {
    setCompOffDetail({})
    setOpen(false);
    setContextMenu(null);
  }


  // onAdditionalAction 
  const onAdditionalAction = async (data, action) => {
    setFormLoading(true)
    if (action === "approve" && !data.approved_minutes) {
      toast.error("Approved Minutes required!");
      setFormLoading(false)
      return;
    }
    const finalData = {
      "id": data.id,
      "resource": data.resource.id,
      "resource_attendance": data.resource_attendance.id,
      "approved_minutes": parseFloat(data.approved_minutes),
      "approved_by": rtm_user.user.data.id,
      "reason": data.reason,
      "status": action === "approve" ? STATUS.COMPOFF.APPROVED : "reject" ? STATUS.COMPOFF.REJECTED : data.status,
    }
    const res = await dataService.compoff_save(finalData)
    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message);
      onClose();
      loadCompOff()
      setCompOffDetail({})
    } else {
      toast.error(res.data.message)
    }
    setCompOffDetail({})
    setFormLoading(false)
  }

  useEffect(() => {
    loadCompOff()
    // dispatch(compoffList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])
  useEffect(() => {
    getResources()
    getManagerResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      // <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
      fieldName === "resource_id" ?
        <Filter options={managerResourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "resource_manager_id" ?
          <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "approved_by" ?
            <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
            : ""
    );
  }

  const columns = useMemo(() =>
    compOffColumns.map((col) => {
      if (col.field === "resource_id" || col.field === "resource_manager_id" || col.field === "approved_by") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [compOffColumns]
  );

  const onFilterChange = (props) => {
    if (props.items.length > columns.length)
      return;

      let idFilter
      if(resourceId){
        idFilter = {
          "resource_id": {
            "operation": "eq",
            "value": resourceId
          }
        }
      }else if(resourceManagerId){
        idFilter = {
          "resource_manager_id": {
            "operation": "eq",
            "value": resourceManagerId
          }
        }
      }

    if (props.items.length === 0) {
      setFilterModel(props)
      setGridRows([]);
      let str = {"filter":idFilter ,"skip":0, "limit":25,"coulmns":[DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS]};
      setFilterQuery(JSON.stringify(str));
      return;
    }

    if (filterModal?.items?.length < props?.items?.length) {
      if (props.items.length > 1) {
        let selectedCols = filterModal.items.map(item => item.columnField);
        let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
        let newProps = { ...props };
        newProps.items[newProps.items.length - 1].columnField = remainCols[0];
        let index = props.items.findIndex(item => item.columnField === 'status')
        if (newProps.items.some(item => item.columnField === 'status')) {
          newProps.items[index].value = undefined;
          newProps.items[index].operatorValue = 'is';
        }
        setFilterModel(props)
      }
      else
        setFilterModel(props)
      return;
    } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

      if (!(filterModal?.items?.length > props?.items?.length)) {

        // if same col is selected then do not let it select - starts
        let selectedCols = props.items.map(item => item.columnField);
        let prevLen = selectedCols.length;

        if ((new Set(selectedCols)).size != prevLen) {
          toast.warn("Please select distinct column fields!")
          return;
        }
        // if same col is selected then do not let it select - ends

        setFilterModel(props)

        let filterModalCols = [];
        let propsCols = [];
        filterModal.items.map(item => filterModalCols.push(item.columnField));
        props.items.map(item => propsCols.push(item.columnField));

        for (let i = 0; i < filterModalCols.length; i++) {
          if (filterModalCols[i] != propsCols[i])
            return;
        }

      }

      setFilterModel(props)

      let filterItems = props.items;
      let baseFilter = {
        [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: 25, coulmns: DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS
        // .replace("'", "").split(",").map(item => item.replaceAll('"', "").trim()) 
      };


      let operators = {
        contains: 'icontains',
        equals: 'ieq',
        is: 'eq',
        startsWith: 'istartsWith',
        endsWith: 'iendsWith'
      }

      if (filterItems?.length) {
        let valContainers = filterItems.filter(item => item?.value)
        if (valContainers?.length) {

          let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
          filter = {...filter, ...idFilter}
          
          // if no changes on filter open starts
          props.items.map((elem, index) => {

            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
              isAnyChange.current[index] = true;
            else
              isAnyChange.current[index] = false;
          })
          // no change (! reverse by not operator)
          if (!(Object.values(isAnyChange.current).every(item => item === false))) {
            setGridRows([]);
          }

          // if no changes on filter open ends
          setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
        }
      }
      return;
    }
  }
  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0;
    newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
    if(foundElement)
    {
        newFilterData['order_by'] = {
            "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
        }
        copySorter.map(item=>{
            if(item.id===foundElement.id)
                item.order = item.order === "asc" ? "desc" : "asc"
        });
        setColSortingPerform(()=>copySorter)
    }
    else{
      
        newFilterData['order_by'] = {
            "column": `${sortingCol}`
        }
        setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
    }


    setFilterQuery(JSON.stringify(newFilterData))
    clearGridListing.current = true;        
}

  if (reload === true && status === STATUSES.SUCCESS && compoff_List && compoff_List.message) {
    if (compoff_List.status === 200) {
      let array = compoff_List?.data?.rows?.map((row, index) => (
        {
          ...row,
          resource_id: row.resource.id,
          name: row.resource.name,
          email: row.resource.email,
          department: row.resource.department,
          role: row.resource.role,
          extra_working_minutes: row.resource_attendance.extra_working_minutes,
          resource_manager_name: row.resource_manager.name,
          resource_manager_id: row.resource_manager.id,
          status_text: STATUS.COMPOFF
        }
      ))
      if (clearGridListing.current) {
        setGridRows([...array]);
        clearGridListing.current = false;
        setSkeletonRows([])
        SET_MAX_ROW_LENGTH(compoff_List.data.count);
      }
      else {
        setGridRows((pre) => [...pre, ...array]);
        SET_MAX_ROW_LENGTH(compoff_List.data.count);
        setSkeletonRows([])
      }
    } else {
      toast.error(compoff_List.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  useEffect(() => {
    stopOnRowScrollEnd.current = true;
    setReload(true);
    setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    dispatch(compoffList(filterQuery))
  }, [filterQuery])
 
  const fetchFurtherRows = (viewPortRowSize) =>
  {
      let updatedFilter = JSON.parse(filterQuery);
      updatedFilter.skip = gridRows.length;
      updatedFilter.limit = 25;
      setFilterQuery(JSON.stringify(updatedFilter));
  }

  const handleOnRowScrollEnd = (params) =>
  {
      if(stopOnRowScrollEnd.current){
          stopOnRowScrollEnd.current=false;
          return;
      }
      if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
          fetchFurtherRows(params.viewportPageSize);
      }
  };


  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
    contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
};

const handleMenuAction = (actionType) =>
{
    let row = gridRows.find(item=>item.id === selectedRow);        
    switch(actionType)
    {
        case 'view':
          viewCompOff(row);
                break;

    }
    setContextMenu(null);
}
  return (
    <div className="new">
      <div className="newContainer">
        <div className="top-headings">
          <h3 > {!resourceId ? "Team Comp-off" : "My Comp-off"} </h3>
          <div>
            {/* <button onClick={() => loadCompOff()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button> */}
             <RefreshButton api={compoffList} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload}  clearGridListing={clearGridListing}/> 
            <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
            </Tooltip>
          </div>
        </div>
        <ToastContainer />
        <>
          {
            <>
              {/* <DynamicTable
                moduleName="compoff"
                tableColumns={columns}
                tableRows={compOffs || []}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                columnVisibilityModel={{
                  id: false,
                }}
                onViewDetail={viewCompOff}
                setFieldName={setFieldName}
                setDefaultValue={setDefaultValue}
                limit={limit}
                setLimit={setLimit}
              /> */}
              <DataGridPro
                className="datagrid"
                filterMode="server"
                loading={!!reload}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                sx={{
                  height: 700,
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#438bf969",
                    "& .actionButton": {
                      display: 'block'
                    }
                  },
                  ".actionButton": {
                    display: 'none',
                  },
                  ".MuiDataGrid-columnHeader":{
                    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root" : {
                      color: 'rgba(25, 39, 52, 1)'
                    }
                  },
                  [`& .${gridClasses.row}`]: {
                    "&:hover, &.Mui-hovered": {
                      ".actionButton": {
                        display: 'block',
                      }
                    }
                  }
                }}
                rows={gridRows?.concat(skeletonRows)}
                columns={columns}
                filterModel={filterModal}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                }
                components={{
                  ColumnMenuIcon: MoreHorizIcon,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                componentsProps={{
                    row: {
                    onContextMenu: handleContextMenu,
                    }
                  }}
                onFilterModelChange={onFilterChange}
                onRowsScrollEnd={handleOnRowScrollEnd}
                hideFooter
              />
            </>
          }
        </>
        {
          compOffDetail && compOffDetail.id &&
          <RegularizeModal
            modalDetail={modalDetail}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            // onCancel={onCancel}
            // onSubmit={onSubmit}
            hideBackdrop
            fullWidth
            maxWidth
            updating
            editData={compOffDetail}
            editable={compOffDetail.status === 4 && resourceManagerId}
            formLoading={formLoading}
            setFormLoading={setFormLoading}
            additionalActions={modalDetail.additionalActions}
            onAdditionalAction={onAdditionalAction}
          />
        }
        <Menu
                    open={contextMenu !== null}
                    onClose={onClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        onClose();
                        }
                    }
                    }}
                >   
                    <MenuItem onClick={()=>handleMenuAction("view")} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> View</MenuItem>                   
                </Menu>       
      </div>

    </div>
  )
}
export default CompOffListing