import React, { useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./manageProject.scss";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { projectByIdWithResources } from "../../redux/features/projectSlice";
import { useSelector, useDispatch } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import moment from 'moment';
import parse from 'html-react-parser';
const { REACT_APP_ENV } = process.env;

const ViewProject = ({id}) => {

    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const currentUserRole = rtm_user.user.data && rtm_user.user.data.tms_role && rtm_user.user.data.tms_role.role_key;
    const currentUserId=rtm_user.user.data && rtm_user.user.data.id 
    const { project, project_status, resourceName,resourceIdarray } = useSelector(state => state.projects)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let  view_data = useLocation().state;
    view_data = id !== undefined ? {id} : view_data; 
    const title = "View Project Details";
    const dataDetails = [
        { id: "1", name: "Project Name", key: "project_name" },
        { id: "2", name: "Project Key", key: "project_key" },
        { id: "3", name: "Project Type", key: "project_type_name" },
        // { id: "4", name: "Is Jira Project", key: "is_jira_projects", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "4", name: "Is Jira Project", key: "is_jira_project_text", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "8", name: "Is Private", key: "is_private", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "6", name: "Status", key: "status", status: true, statusFalse: "No", statusTrue: "Yes", statusTexts: { 0: "Pending", 1: "Active", 2: "Inactive", 3: "Archive" } },
        { id: "7", name: "Jira Project Id", key: "jira_project_id" },
        { id: "5", name: "Description", key: "description" },
        { id: "9", name: "Created On", key: "created_on" },
        { id: "10", name: "Updated On", key: "updated_on" },
        // { id: "11", name: "Created By", key: "resource?.display_name" },
    ];
    
    // Edit Project
    const editProject = (data) => {
        navigate('/edit-project', { state: data })
    }

    useEffect(() => {
        dispatch(projectByIdWithResources(view_data?.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(id){
        return <>
                   {project_status !== STATUSES.SUCCESS ?
                    <LoaderForm />
                    :
                    project_status === STATUSES.SUCCESS ?
                        <div className="view-details-row" style={{boxShadow:'none', gap:'15px'}}>
                            {
                                dataDetails.map((data, index) => {
                                    return (
                                        (project && project[data.key]) &&
                                        <div className={"detailItem"} key={index} >
                                            <span className="itemKey" style={data.key === 'description'?{float:'left'}:{}}>{data.name} :</span>
                                            <span className="itemKey2 item-value" style={data.key === 'description'?{float:'left'}:{}}>
                                                <span className={parse(project[data.key]).length > 70 ? "para-text" : "text"}>
                                                    {project && parse(data.key === "created_on" || data.key === "updated_on" ? moment(project[data.key]).format('DD-MM-YYYY') : project[data.key])}</span>
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            <div className="detailItem" >
                                <span className="itemKey">{"Created By"} :</span>
                                    <span className="itemKey2 item-value" >{resourceIdarray.filter((elem)=> elem.id === project.created_by).map((element, index) => {
                                        return (
                                            <span key={index}>{element.name}</span>
                                        )
                                    })}</span>
                                    
                                
                            </div>


                            <div className="detailItem" >
                                <span className="itemKey">{"Resources"} :</span>
                                {project && resourceName && resourceName.length ?
                                    <span className="itemKey2" style={{ display: "inline-grid", minHeight: "110px" }}>{resourceName.map((element, index) => {
                                        return (
                                            <span key={index}> {element.name} {(index < resourceName.length - 1) ? ", "
                                                : ""} </span>
                                        )
                                    })}</span>
                                    :
                                    <span className="itemKey2">N/A</span>
                                }
                            </div>
                            
                        </div>
                        :
                        <div>Oops something is wrong.. </div>
                }          
            </>
        
    }

    return (
        <div className="resource">
            <div className="resourceContainer">
                <div className="top-headings">
                    <h3>{title || "View Details"}</h3>
                    <div>
                        <RefreshButton api={projectByIdWithResources} filter={view_data?.id} style={{ marginTop: 5 }} />
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>

                        {(currentUserRole === "super_admin" || currentUserRole === "admin" || currentUserRole === "hr" && currentUserId === project.created_by) && !(project.is_jira_project === true) &&
                            <button onClick={() => { editProject(project) }} className="btn btn-add-new">Edit Project</button>
                        }
                    </div>
                </div>
                {project_status !== STATUSES.SUCCESS ?
                    <LoaderForm />
                    :
                    project_status === STATUSES.SUCCESS ?
                        <div className="view-details-row">
                            {
                                dataDetails.map((data, index) => {
                                    return (
                                        (project && project[data.key]) &&
                                        <div className={"detailItem"} key={index} >
                                            <span className="itemKey" style={data.key === 'description'?{float:'left'}:{}}>{data.name} :</span>
                                            <span className="itemKey2 item-value" style={data.key === 'description'?{float:'left'}:{}}>
                                                <span className={parse(project[data.key]).length > 70 ? "para-text" : "text"}>
                                                    {project && parse(data.key === "created_on" || data.key === "updated_on" ? moment(project[data.key]).format('DD-MM-YYYY') : project[data.key])}</span>
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            <div className="detailItem" >
                                <span className="itemKey">{"Created By"} :</span>
                                    <span className="itemKey2 item-value" >{resourceIdarray.filter((elem)=> elem.id === project.created_by).map((element, index) => {
                                        return (
                                            <span key={index}>{element.name}</span>
                                        )
                                    })}</span>
                                    
                                
                            </div>


                            <div className="detailItem" >
                                <span className="itemKey">{"Resources"} :</span>
                                {project && resourceName && resourceName.length ?
                                    <span className="itemKey2" style={{ display: "inline-grid", minHeight: "110px" }}>{resourceName.map((element, index) => {
                                        return (
                                            <span key={index}> {element.name} {(index < resourceName.length - 1) ? ", "
                                                : ""} </span>
                                        )
                                    })}</span>
                                    :
                                    <span className="itemKey2">N/A</span>
                                }
                            </div>
                            
                        </div>
                        :
                        <div>Oops something is wrong.. </div>
                }
            </div>
        </div >
    )

}

export default ViewProject