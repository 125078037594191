import './phoneCallDetails.scss';
import { ArrowBack } from '@mui/icons-material';
import { TablePagination, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoaderForm from '../../components/common-function/LoaderForm';
import { myPhoneCallDetailsColumn } from '../../components/datatable-coulms/tableCoulmns'
import TablePaginationActions from '../../components/TablePaginationActions';
import { STATUSES } from '../../redux/common-status/constant';
import { getMyCallLogDetails, defaultPhoneCallDetailsColumn } from '../../redux/features/callingSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useLocation, useNavigate, Link, useSearchParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { StoryDetails } from '../task/StoryDetails';
const user = LocalStorageDataService.userDetail();
const userId =user?.id
const default_phoneCallDetails_columns = LocalStorageDataService.get_default_phoneCallDetails_column()

export const MyCallDetails = () => {

    const localState = useLocation().state;
    const [searchParams] = useSearchParams();
    const urlKey = searchParams.get('key');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const date = moment().format('YYYY-MM-DD')

    const { mycallDetails, status, defaultphoneColumns } = useSelector(state => state.callingSlice)
    const { data: list } = mycallDetails

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_phoneCallDetails_columns?.phone_call_details_column ? default_phoneCallDetails_columns.phone_call_details_column : {
        recording_url: false,
        id: false,
    });
    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDives, setActiveDives] = useState("")
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    const setKeyForList = (obj) => {
        let data;
        if (default_phoneCallDetails_columns) {
            data = { 'phone_call_details_column': obj, 'reported_phoneCallDetail_column': default_phoneCallDetails_columns.reported_phoneCallDetail_column, 'my_phone_calldetails_column': default_phoneCallDetails_columns.my_phone_calldetails_column, 'phone_column': default_phoneCallDetails_columns.phone_column }
        } else {
            data = { 'phone_call_details_column': obj }
        }
        if (defaultphoneColumns.reported_phoneCallDetail_column) {
            data['reported_phoneCallDetail_column'] = defaultphoneColumns.reported_phoneCallDetail_column
        }
        if (defaultphoneColumns.my_phone_calldetails_column) {
            data['my_phone_calldetails_column'] = defaultphoneColumns.my_phone_calldetails_column
        }
        if (defaultphoneColumns.phone_column) {
            data['phone_column'] = defaultphoneColumns.phone_column
        }
        dispatch(defaultPhoneCallDetailsColumn(data))
    }


    let callTypeWiseFilter;
    if (urlKey) {
        if (urlKey === "in-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":0,"operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
        } else if (urlKey === "out-bound") {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"},"direction":{"value":1,"operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
        } else {
            callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
        }
    } else {
        callTypeWiseFilter = `{"filter":{"resource":{"value":${userId},"operation": "eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
    }

    useEffect(() => {
        setFilterQuery(callTypeWiseFilter)
        dispatch(getMyCallLogDetails(callTypeWiseFilter))
    }, [localState?.key])

    const getRowHeight = (params) => {
        const projectName = params.model.project_name
        if (projectName) {
            const noOfLines = Math.ceil(projectName?.length / 20)
            const rowHeight = (noOfLines * 30)
            return rowHeight

        }
        return 35 //default row height(if there is no project name)
    }

    const activeStoryDiv = (id) => {
        setActiveDives(`story_${id}`)
        if (storyDiv > 0) { setStoryDiv(id) } else { setStoryDiv(id); }
    }

    const nothing = () => { 
        //nothing
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(list && list.rows && list.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={LocalStorageDataService.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(callTypeWiseFilter)
                dispatch(getMyCallLogDetails(callTypeWiseFilter))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                                filterModel.items[0].operatorValue


            let filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
            if (urlKey) {
                if (urlKey === "in-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":0,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
                } else if (urlKey === "out-bound") {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"direction":{"value":1,"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
                } else {
                    filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"call_date":{"value":"${date}","operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
                }
            } else {
                filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}]}`
            }
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getMyCallLogDetails(filterData))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getMyCallLogDetails(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.MYPHONECAAL_DEATILS_COLUMNS}] }`)
        }
    }

    const actions = [
        {
            field: 'project',
            headerName: 'Project Name',
            width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <Tooltip title="View Project">
                            <Link to={`/view-project`} state={{ id: params.row.project }} style={{ whiteSpace: 'normal' }}>{params.row.project_name}</Link>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: 'story',
            headerName: 'Story Name',
            filterable: false,
            width: 290,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer', color: '#0052cc' }} className="cellWithStatus" onClick={() => params.row.story ? activeStoryDiv(params.row.story) : nothing()}>
                        <Tooltip title="Resume">
                            <p>{params.row.story_name}</p>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            field: 'recording_url',
            headerName: 'Call Recording Link',
            width: 350,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <ReactAudioPlayer
                            src={params.row.recording_url}
                            autoPlay={false}
                            controls
                            style={{ height: '30px' }}
                        />
                    </div>
                )
            }
        },
    ]

    return (
        storyDiv > 0 ?
            <div className="task">
                <div className="taskContainer">
                    <StoryDetails
                        epicId={storyDiv}
                        hideDiv={setStoryDiv}
                        activeDivs={activeDives}
                    />
                </div>
            </div>
            :
            <div className="phoneCallListing">
                <div className="phoneCallListingContainer">
                    <div className="datatable">
                        <div className="datatableTitle">
                            <h3>My Phone Call Details</h3>
                            <div>
                                <RefreshButton api={getMyCallLogDetails} filter={filterQuery} style={{ marginTop: 12, marginRight: 5 }} />
                                <Tooltip title="Back">
                                    <button className='btn-add-new' onClick={() => navigate(-1)}><ArrowBack /></button>
                                </Tooltip>
                            </div>

                        </div>

                        <ToastContainer />
                        <div className="custom-pagination-class">
                            <CustomPagination />
                        </div>
                        {(status === STATUSES.LOADING) && <LoaderForm />}
                        {
                            !reload &&
                            <DataGrid
                                className="datagrid"
                                columnVisibilityModel={defaultphoneColumns?.phone_call_details_column ? defaultphoneColumns.phone_call_details_column : columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                                }
                                rows={list?.rows ? list.rows : []}
                                columns={myPhoneCallDetailsColumn.concat(actions)}
                                pageSize={pageSize}
                                style={{ fontSize: 16 }}
                                onFilterModelChange={onFilterChange}
                                components={{
                                    Pagination: CustomPagination,
                                    NoRowsOverlay: CustomNoRowsOverlay
                                }}
                                onSortModelChange={(sort) => handleSort(sort)
                                }
                                getRowHeight={getRowHeight}
                                hideFooterPagination={true}
                            />
                        }
                    </div>

                </div>
            </div>

    )
}
