import React, { useEffect, useState } from "react";
import "./hiring.scss"
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getHiringRequestList, getAllLocationList, getAllMoreSpecificationList, hiringRequestUpdate, hiringRequestArchive, getAllQualificationList, hiringRequestSoftDelete } from '../../redux/features/masterSlice';
import dataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import CustomeTooltip from "../common-function/CustomeTooltip";
import { skilOption } from "../../redux/features/optionsSlice"
import JobPost from "./AddJobPost";
import GetLocalstorage from "../common-function/GetLocalstorage";

const user = GetLocalstorage.userDetail();
const tmsRole = user?.tms_role
const Approver = user?.is_approver
const requestLevelStatus = GetLocalstorage.get_hiring_request_level();

function AllHiringRequests() {
  const dispatch = useDispatch();

  const { status, error, user, hiring_request_List, location_option_List, specificationOptionList, hiring_request_Update, qualification_AllList } = useSelector(state => state.master)
  // const { data: list } = hiring_request_List

  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"filter":{"status":{"value":"True", "operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterTechnologyList, setFilterTechnologyList] = useState([]);
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [filterLocationList, setFilterLocationList] = useState([]);
  const [filterSpecificationList, setFilterSpecificationList] = useState([]);
  const [options, setOptions] = useState({ status: [{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }] })
  const { skils } = useSelector(state => state.dropdownOptions)
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [statusHiringRequest, setStatusHiringRequest] = useState('');
  const [reload, setReload] = useState(false)
  const [open, setOpen] = useState(false);
  const [, setHiringReq] = useState("")
  const [getJobData, setGetJobData] = useState({})
  const [, setSpecialisationSkills] = useState("")
  const [, setSkillData] = useState("")
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });
  const [softDelete, setsoftDelete] = useState(false)
  const [softdlterror, setSofdlterror] = useState(false)

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
    }
    // eslint-disable-next-line 
    if (tmsRole.role_key === "admin" || Approver === true) {
      setsoftDelete(true)
    }
    else {
      setsoftDelete(false)
    }
  }, [error, softDelete])


  useEffect(() => {
    dispatch(getHiringRequestList(filterQuery))
    dispatch(skilOption())
    dispatch(getAllLocationList())
    dispatch(getAllMoreSpecificationList())
    dispatch(getAllQualificationList())
    // eslint-disable-next-line 
  }, [])

  const loadDataById = async (id) => {
    setReload(true)
    const res = await dataService.hiring_request_edit(id)
    let technologyArray = []
    let addMoreSpecificationArray = []
    let skillDataArray = []

    if (res?.data && res.data.data) {
      const editData = res.data.data

      if (editData?.skills_tech_data) {
        editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
          return technologyArray.push({
            label: e.technology?.name,
            value: e.technology?.id,
            name: "specialistation_id"
          })
        })

        editData.skills_tech_data.length > 0 && editData.skills_tech_data.map(e => {
          return skillDataArray.push({
            technology_id: e.technology?.id,
            skill_data: e.skills.map((item) => {
              return {
                label: item.skill_name,
                value: item.id,
                technology_id: e.technology.id,
                name: "skill_data"
              }
            })
          })
        })
        const result = skillDataArray.reduce((obj, itm) => ({ ...obj, [itm.technology_id]: itm }), {})
        setSpecialisationSkills(technologyArray)
        setSkillData(result)
      }

      if (editData?.add_more_specifications_data) {
        editData.add_more_specifications_data.length > 0 && editData.add_more_specifications_data.map(e => {
          return addMoreSpecificationArray.push({
            label: e.specification_name_key,
            value: e.id
          })
        })
      }

      let array1 = []

      if (editData.education_qualification) {
        let qualification = qualification_AllList?.data && qualification_AllList.data.rows ? qualification_AllList.data.rows : []
        editData.education_qualification.map(e => {
          let filterId = qualification.filter(x => x.id === e);
          if (filterId.length > 0) {
            array1.push(filterId[0])
          }
          return array1
        })
      }


      if (editData) {
        // dispatch(getIndustryList(editData.client_id))
        // dispatch(getDepartmentList({ client_id: editData.client_id, industry_id: editData.industry_id }))
        // dispatch(getSpecialisationList({ client_id: editData.client_id, industry_id: editData.industry_id, department_id: editData.department_id }))

        setGetJobData({
          education_qualification: array1,
          project_id: "",

          // step 1
          client: { label: editData.client?.name, value: editData.client_id },
          industry: { label:  editData.industry?.industry_name, value: editData.industry_id },
          department: { label: editData.department?.name, value: editData.department_id },
          specialistation_id: technologyArray,
          hiring_request: id,


          //step 2
          client_budget_from: editData.client_budget_from,
          client_budget_to: editData.client_budget_to,
          client_budget_from_new: editData.client_budget_from,
          client_budget_to_new: editData.client_budget_to,
          currency: { label: editData.currency?.currency_name, value: editData.currency_id },
          payment_type: { label: editData.payment_type?.name, value: editData.payment_type_id },
          hiring_mode: { label: editData.hiring_mode?.name, value: editData.hiring_mode_id },

          // step 3
          job_title: editData.role,
          number_of_vacancies: editData.number_of_vacancies,
          type_of_vacancy: { label: editData.type_of_vacancy?.vacancy_type, value: editData.type_of_vacancy_id },
          contract_type: { label: editData.contract_type?.name, value: editData.contract_type_id },
          hours_per_day: editData.hours_per_day ? editData.hours_per_day : null,
          expected_duration_in_months: editData.expected_duration_in_months ? editData.expected_duration_in_months : null,

          work_mode: { label: editData.working_mode?.working_mode, value: editData.working_mode_id },
          urgency: { label: editData.urgency?.urgency_type, value: editData.urgency_id },
          job_location: { label: editData.job_location?.job_location, value: editData.job_location_id },
          job_experience: editData?.relevant_experience_expected,

          job_description: editData.job_description,
          add_more_specifications: addMoreSpecificationArray,
          attachment: editData.attachment,
          job_attachments: editData.job_attachments,
          status: editData.status,
          // job_description: editData.job_description,
          hiring_status: editData.hiring_status,

          relevant_experience_expected_from: editData.relevant_experience_expected_from,
          relevant_experience_expected_to: editData.relevant_experience_expected_to,
          total_experience_to: editData.total_experience_to,
          total_experience_from: editData.total_experience_from,
          expected_ETA: editData.expected_ETA,
          eta_of_Arrival: editData.expected_ETA,
          urgency_unit: editData.urgency_unit,
        })
      }
      setOpen(true);
      setReload(false)

    }
  }

  const handleOpen = (hiringReq) => {
    setHiringReq(hiringReq)
    loadDataById(hiringReq.id)
  }
  if (reload === 'edit' && hiring_request_Update && hiring_request_Update.status) {
    if (hiring_request_Update.status === 200) {
      setArchiveOpen(false)
      toast.success(hiring_request_Update.message)
      dispatch(hiringRequestUpdate('type'))
      dispatch(getHiringRequestList(filterQuery))
    } else {
      hiring_request_Update.message ? toast.error(hiring_request_Update.message) : toast.error('Server issue!')
    }
    setReload(false)
  }

  const getUnique = (arr, index) => {

    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  if (filterTechnologyList && filterTechnologyList.length === 0 && skils) {
    let array = []
    skils?.map(e => { return array.push({ value: e.id, label: e.skill_name }) })
    array.length > 0 && setFilterTechnologyList(array)
    array.length > 0 && setOptions({ ...options, technology_id: array })
  }

  if (filterSkillList && filterSkillList.length === 0 && skils) {
    let array = []
    skils?.map(e => { return array.push({ value: e.id, label: e.skill_name }) })
    array.length > 0 && setFilterSkillList(array)
    array.length > 0 && setOptions({ ...options, required_skill_set_id: array })
  }

  if (filterLocationList && filterLocationList.length === 0 && location_option_List) {
    let array = []
    location_option_List?.map(e => { return array.push({ value: e.id, label: e.city_name }) })
    array.length > 0 && setFilterLocationList(array)
    array.length > 0 && setOptions({ ...options, preferred_location_id: array })
  }

  if (filterSpecificationList && filterSpecificationList.length === 0 && location_option_List) {
    let array = []
    specificationOptionList?.map(e => { return array.push({ value: e.id, label: e.specification_name }) })
    array.length > 0 && setFilterSpecificationList(array)
    array.length > 0 && setOptions({ ...options, preferred_location_id: array })
  }

  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    { field: "role", headerName: "Position/Role Name", width: 230 },
    { field: "number_of_vacancies", headerName: "No. of vacancies", width: 230 },
    { field: "type_of_vacancy__vacancy_type", headerName: "Type of vacancy", width: 230 },
    { field: "working_mode__working_mode", headerName: "Working mode", width: 230 },
    {
      field: "technologies", headerName: "Technologies", width: 230, type: "singleSelect", valueOptions: filterTechnologyList,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <CustomeTooltip
              title={params.row.skills_tech_data && params.row.skills_tech_data.length > 0 ? params.row.skills_tech_data.map((element) => element.technology.name).filter((name) => name).join(', ') : "-"}
              data={params.row.skills_tech_data && params.row.skills_tech_data.length > 0 ? params.row.skills_tech_data.map((element) => element.technology.name).filter((name) => name).join(', ') : "-"}
            />
          </div>
        );
      }
    },
    {
      field: "job_description", headerName: "Description", width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">{params.row.job_description ?
            <span dangerouslySetInnerHTML={{ __html: (params.row.job_description).substring(0, 15) + "..." }} /> : "N/A"}
          </div>
        )
      }

    },
    {
      field: "add_more_specifications", headerName: "Specifications", width: 230, type: "singleSelect", valueOptions: filterSpecificationList,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <CustomeTooltip
              title={params.row.add_more_specifications_data && params.row.add_more_specifications_data.length > 0 ? getUnique(params.row.add_more_specifications_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.specification_name).trim()}`) : "-"}
              data={params.row.add_more_specifications_data && params.row.add_more_specifications_data.length > 0 ? getUnique(params.row.add_more_specifications_data, 'id').map((e, index) => `${index ? ', ' : ''}${(e.specification_name).trim()}`) : "-"}
            />
          </div>
        );
      }
    },
    {
      field: "hiring_status", headerName: "Hiring Status", width: 120, type: 'singleSelect',
      valueOptions: [{ label: "Active", value: 1 }, { label: "In Draft", value: 0 }],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.hiring_status}`}>
            {params.row.hiring_status === 1 ? 'Active' : 'In Draft'}
          </div>
        );
      },
    },
    { field: "current_assignee_name", headerName: "Current Assignee", width: 200, },
    {
      field: "request_level", headerName: "Request Level", width: 220, filterable: true,
      type: 'singleSelect', valueOptions: requestLevelStatus,
      renderCell: (params) => {
        const createrequestStatus = Object.freeze({
          1: "Created By TL",
          2: "Sent To Manager",
          3: "Accepted By Manager",
          4: "Created By Manager",
          5: "Sent To HR Manager",
          6: "Accepted By HR Manager",
          7: "Created by HR Manager"
        })
        return (
          <div className={`cellWithStatus`} style={{ cursor: "pointer" }}>
            {/* + params.row.status */}
            {createrequestStatus[params.row.request_level] ? createrequestStatus[params.row.request_level] : "-"}
          </div>
        );
      },
    },
    { field:"job_status", headerName:"Job Status", width:200,sortable:false,filterable:false},
    {field:"total_sourced_candidate",headerName:"Total sourced Candidates ",width:200,sortable:false,filterable:false},
    {field:"selected_candidate",headerName:"Selected Candidates",width:200,sortable:false,filterable:false},
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              extraButton={() => handleOpen(params.row)}
              additionalAction={(params.row.request_level === 7 || params.row.request_level === 6) && (tmsRole && tmsRole.role_key === 'hr' && params.row.job_id === null && params.row.hiring_status === 1) ? [{ title: "Create Job", key: 'epic' }] : ""}
              addMoreData={params.row.id}
              additionalActionIcon1="AddBoxRoundedIcon"
              moduleName="hiring"
              rowid={params.row.id}
              editLinkUrl={(params.row.hiring_status === 0 && params.row.current_assignee === user?.id) ? `/edit-hiring-request/${params.row.id}` : ""}
              viewLinkUrl={`/view-hiring-request/${params.row.id}`}
              isSoftDelete={softDelete}
              editButton={false}
              viewButton={false}
              statusUpdate={true}
              cloneButton={params.row.hiring_status === 1 ? `/clone-hiring-request-view/${params.row.id}` : ""}
              deleteIconName="ArchiveIcon"
              deleteButton={() => handleSoftArchive(params.row)}
            />
          </div>

        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(getHiringRequestList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getHiringRequestList(JSON.stringify(newFilterData)))
  }


  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(hiring_request_List?.data && hiring_request_List.data.rows && hiring_request_List.data.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel, r) => {

    if (filterModel.items.length) {
      setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
    }

    if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
      setReload(true);
      setTimeout(() => {
        setReload(false)
        setCurrentPage(0)
        setFilterQuery(`{"filter":{"status":{"value":"True", "operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`)
        dispatch(getHiringRequestList(`{"filter":{"status":{"value":"True", "operation":"eq"}},"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`))
      }, 0);
    }

    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "is" ? 'eq' :
            filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
              filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                filterModel.items[0].operatorValue
      let filterData;
      if (filterModel.items[0].columnField === 'technologies' || filterModel.items[0].columnField === 'required_skill_set' || filterModel.items[0].columnField === 'preferred_location' || filterModel.items[0].columnField === 'add_more_specifications') {
        filterData = `{"filter":{"status":{"value":"True", "operation":"eq"},"${[filterModel.items[0].columnField]}":{"value":[${filterModel.items[0].value}],"operation": "icontains"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`
      } else {

        filterData = `{"filter":{"status":{"value":"True", "operation":"eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`
      }
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getHiringRequestList(filterData))

    }
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getHiringRequestList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{"filter":{"status":{"value":"True", "operation":"eq"}}, "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" }, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}] }`)
    }
  }


  const handleSoftArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
  }
  const handleClick = (id) => {
    if (id.status === true) {
      dispatch(hiringRequestSoftDelete({
        "key": "hiring",
        "id": id.id
      })).then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.message)

          setSofdlterror(true)
        }
        else {
          toast.error(`${response.payload.status} ${response.payload.message}`)
        }
      })
      setReload(true)
    }
    setArchiveOpen(false)
  }

  if (status === 'success_hiring_request_SoftDelete' && softdlterror === true) {
    dispatch(getHiringRequestList(`{"filter":{"status":{"value":"True", "operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.HIRING_REQUEST_LIST_COLUMNS}]}`))
    setReload(false)
  }


  return (
    <div className="new">
      {open && <JobPost getJobData={getJobData} open={open} setOpen={setOpen}
        filterQuery={'{"skip":0, "limit":25,"order_by":{"column":"-id"}}'} />}
      <div className="newContainer">
        <div className="datatable">
          <div className="top-headings">
            <h3>All Hiring Requests</h3>
            <div>
              <Tooltip title="Add Hiring Request">
                <Link to="/create-hiring-request" className="btn-add-new ">
                  <AddIcon />
                </Link>
              </Tooltip>
              <RefreshButton api={getHiringRequestList} filter={filterQuery} style={{ marginTop: 0 }} />
            </div>
          </div>

          <ToastContainer />
          <div className="custom-pagination-class">
            <CustomPagination />
          </div>
          {(status === STATUSES.LOADING || reload === true || reload === 'edit') && <LoaderForm />}
          {
            !reload &&
            <DataGrid
              filterMode="server"
              className="datagrid"
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              rows={hiring_request_List?.data && hiring_request_List.data.rows ? hiring_request_List.data.rows : []}
              columns={actionColumn}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              onSortModelChange={(sort) => handleSort(sort)}
              hideFooterPagination={true}
            />
          }
        </div>

        {
          <DeleteDialogModal
            open={archiveOpen}
            onClose={setArchiveOpen}
            heading={`${statusHiringRequest}`}
            paragraph={'Are you sure to delete hiring request'}
            handleArchive={handleClick}
            id={archiveId}
          />
        }

      </div>
    </div>
  )
}
export default AllHiringRequests
