import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// role based menu list sidebar
export const getMenuListForSideBar = createAsyncThunk("get/role-menu-list-sidebar", async (data) => {
  const res = await dataService.getRoleMenuNewSideBar(data);
  const resp = res.data;
  return resp;
});

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    status: 'idle',
    error: '',
    sidebarMenuList: []
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMenuListForSideBar.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getMenuListForSideBar.fulfilled, (state, action) => {
        state.status = `${STATUSES.SUCCESS}_roleMenuSidebarList`;

        state.sidebarMenuList = !action.payload.data ? [] : action.payload.data
      })
      .addCase(getMenuListForSideBar.rejected, (state, action) => {
        state.status = STATUSES.SUCCESS;
        state.error = action.error
      })
  },
});


export default sidebarSlice.reducer;