import React from 'react'
import "./setting.scss";
// import { Link } from "react-router-dom";

const Setting = () => {
  return (
    <div className="setting">
        <div className="newContainer">
        <p>This is setting page.</p>
        </div>
        
    </div>
  )
}

export default Setting
