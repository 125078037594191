import React from 'react'
import "./phonecallprogressbar.scss"
import MultiProgress from "react-multi-progress";
import { Link } from 'react-router-dom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import BadgeMark from '@mui/material/Badge';
import { Box, Button, LinearProgress, Popover, Typography } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { Stack } from 'rsuite';

function CustomComponent({
	children,
	element,
	...rest
}) {
	return (
		<div
			{...rest} // adds all styles for rendering the progress bar
		// style={{
		// 	fontWeight: element.isBold ? 900 : 300,
		// }}
		>
			{children}
		</div>
	);
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
	position: 'absolute',
	'&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
		bottom: theme.spacing(1),
		right: theme.spacing(1),
	},
	'&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
		top: theme.spacing(-3),
		left: theme.spacing(1),
	},
}));

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" fontWeight={700} color="text.secondary">{
					props.lableData
				}</Typography>
			</Box>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress sx={{
					[`& .${linearProgressClasses.bar}`]: {
						borderRadius: 5,
						backgroundColor: props.progressColor,
					},
				}} variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box >
	);
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
};

export default function PhonecallProgressbar({ details }) {

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);


	const [progress, setProgress] = React.useState(10);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
		}, 800);
		return () => {
			clearInterval(timer);
		};
	}, []);

	// const actions = [
	// 	{
	// 		icon: <Link style={{ textDecoration: 'none' }} to={"/my-call-details?key=total_call"} state={{ key: "total_call", isProgressBar: true }}>
	// 			<Typography color='#000' fontSize='12px' fontWeight={600} variant="h5" component="h5">
	// 				{Number(details.total_durations_in_and_out_percent)}%
	// 			</Typography>
	// 		</Link>,
	// 		name: `Total Call : ${details.total_durations_in_and_out_hrs}`, fabpropBg: '#008000'
	// 	},
	// 	{
	// 		icon: <Link style={{ textDecoration: 'none' }} to={"/my-call-details?key=in-bound"} state={{ key: "in-bound", isProgressBar: true }}>
	// 			<Typography color='#000' fontSize='12px' fontWeight={600} variant="h5" component="h5">
	// 				{Number(details.total_durations_in_percent)}%
	// 			</Typography>
	// 		</Link>,
	// 		name: `In-bound Call : ${details.total_durations_in_hrs}`, fabpropBg: '#db9c00'
	// 	},
	// 	{
	// 		icon: <Link style={{ textDecoration: 'none' }} to={"/my-call-details?key=out-bound"} state={{ key: "out-bound", isProgressBar: true }}>
	// 			<Typography color='#000' fontSize='12px' fontWeight={600} variant="h5" component="h5">
	// 				{Number(details.total_effective_durations_out_percent)}%
	// 			</Typography>
	// 		</Link>,
	// 		name: `Out-bound Call : ${details.total_effective_durations_out_hrs}`, fabpropBg: 'lightblue'
	// 	},
	// 	{
	// 		icon: <Typography color='#000' fontSize='12px' fontWeight={600} variant="h5" component="h5">
	// 			{Number(details.remain_in_percent)}%
	// 		</Typography>,
	// 		name: `Remain Call : ${details.remaining_total_effective_call_durations_in_and_out_hrs}`, fabpropBg: 'gray'
	// 	},
	// ];
	return (
		<>
			<Typography
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				display={"flex"}
				justifyContent={"space-around"}
				sx={{ cursor: 'pointer', color: '#fff', fontSize: '25px', fontWeight: '700' }}
			>
				<GraphicEqIcon sx={{ color: '#fff', fontSize: '40px' }} />
				{`${Number(details.remain_in_percent)}%`}
			</Typography >
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
					marginTop: '16px'
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Box sx={{ width: '200px', margin: '5px' }}>
					<LinearProgressWithLabel lableData={'IBC'} progressColor={'#72a7ff'} value={Number(details.total_durations_in_and_out_percent)} />
					<LinearProgressWithLabel lableData={'OBC'} progressColor={'#008000'} value={Number(details.total_durations_in_percent)} />
					<LinearProgressWithLabel lableData={'RC'} progressColor={'#ec8282'} value={Number(details.total_effective_durations_out_percent)} />
				</Box>
			</Popover>
			{/* <StyledSpeedDial
				ariaLabel="SpeedDial playground example"
				hidden={false}
				icon={<BadgeMark badgeContent={`${Number(details.remain_in_percent)}%`} color="success"><TrendingUpIcon sx={{ color: "#1675e0", marginTop: '5px' }} /></BadgeMark>}
				direction={'down'}
				FabProps={{
					sx: {
						bgcolor: 'transparent',
						'&:hover': {
							bgcolor: 'transparent',
						},
						boxShadow: 'none',
					}
				}}
			>
				{actions.map((action) => {
					const btnFill = {
						background: `${action.fabpropBg}`
					}
					return (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							TooltipClasses={action.tooltipClass}
							componentsProps={{
								tooltip: {
									sx: {
										bgcolor: `${action.fabpropBg}`,
										color: "#000",
										fontSize: '13px',
										fontWeight: '500'
									}
								}
							}}
							sx={{
								height: '60px',
								width: '60px',
								margin: '2px',
								background: `${action.fabpropBg}`,
								'&:hover': btnFill,
							}}
						/>
					)
				})}
			</StyledSpeedDial> */}
			{/* <div className="call-progess" >
				<MultiProgress
					transitionTime={1.2}
					elements={[
						{
							value: details.total_durations_in_and_out_percent,
							color: "green",
							isBold: false,
							showPercentage: true,
							textColor: "white",
							className: "progess-text",
						},
						{
							value: details.remain_in_percent,
							// color: "#0052cc",
							color: "gray",
							showPercentage: true,
							textColor: "#fff",
							isBold: false,
							className: "progess-text",
						},
					]}
					height={25}
					border="1px solid #ddd"
					className="custom-progressbar"
					component={CustomComponent}
				/> */}

			{/* <div className="progress-overlay">

					<h6 className='progress-bar-hyperlink'>
						<Link to={"/my-call-details?key=total_call"} state={{ key: "total_call", isProgressBar: true }}>
							<span className="inbound">
								Total Call : {details.total_durations_in_and_out_percent}%
								({details.total_durations_in_and_out_hrs})
							</span>
						</Link>
					</h6>
					<h6 className='progress-bar-hyperlink'>
						<Link to={"/my-call-details?key=in-bound"} state={{ key: "in-bound", isProgressBar: true }}>
							<span className="outbound">
								In-bound Call : {details.total_durations_in_percent}%
								({details.total_durations_in_hrs})
							</span>
						</Link>
					</h6>
					<h6 className='progress-bar-hyperlink'>
						<Link to={"/my-call-details?key=out-bound"} state={{ key: "out-bound", isProgressBar: true }}>
							<span className="outbound1">
								Out-bound Call : {details.total_effective_durations_out_percent}%
								({details.total_effective_durations_out_hrs})
							</span>
						</Link>
					</h6>
					<h6> */}
			{/* Remain Call Percent */}
			{/* <span className="totalbound">
							Remain Call : {details.remain_in_percent}%
							({details.remaining_total_effective_call_durations_in_and_out_hrs})
						</span> */}
			{/* </h6>
				</div>
			</div> */}
		</>
	)
}
