import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Skeleton, Switch, TablePagination, Tooltip, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { SkillColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { skillCreate, skillEdit, skillUpdate, getSkillList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { DataGridPro, gridClasses, getGridStringOperators } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

const initialFormData = Object.freeze({
    skill_name: "",
    description: "",
    status: true,

});

const formErrData = Object.freeze({
    descriptionErr: "Description is required",
    skill_keyErr: "Skill key is required",
    skill_nameErr: "Skill name is required",
    statusErr: "Status is required"
});
const PaperComponent = (props) => {
    let [activeDrags, setActiveDrags] = useState(0);
    const onStart = () => {
        setActiveDrags(++activeDrags)
    }

    const onStop = () => {
        setActiveDrags(--activeDrags)
    }

    let dragHandlers = { onStart: onStart, onStop: onStop }

    return (
        <Draggable
            bounds="parent"
            {...dragHandlers}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
const skeletonBase = {skill_name:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', skill_key:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',description:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['skill_name', 'skill_key', 'description', 'status'];

function  uniqueObjArray (data){
    return  [...new Map(data.map((item) => [item["id"], item])).values()];
 }  

const Skill = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, skill_List, skill_Create, skill_Edit, skill_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SKILL_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

    //datagridpro
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
    const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});

    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});



    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getSkillList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [filterQuery])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null)
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_skill_Update` && skill_Update && skill_Update.message) {
        if (skill_Update.status === 200) {
            // let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            // setFilterQuery(str)
            // toast.success(skill_Update.message)
            let copyGridRows = [...gridRows].map(item => {
                if(editID.current.id === item.id)
                    return { ...skill_Update.data };
                else
                    return item;
            })
            setGridRows(copyGridRows)
            handleClose()
            toast.success(skill_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(skill_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_skill_Create` && skill_Create && skill_Create.message) {
        if (skill_Create.status === 200) {
            toast.success(skill_Create.message)
            handleClose()
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
            setFilterQuery(str)
            // dispatch(getSkillList(filterQuery))
        } else {
            toast.error(skill_Create.message)
        }
        setReload(false)
    }


    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        if (name === "status") {
            setFormValues({ ...formValues, [name]: checked })
        }
        else {
            setFormValues({ ...formValues, [name]: value })
        }

    }


    const submitData = (e) => {
        e.preventDefault()
        
        if (formValues.skill_key === "" || formValues.skill_name === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }
        clearGridListing.current = true;
        if (formValues.skill_key !== "" && formValues.skill_name !== "" && formValues.description !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item=>item.id === ID);
                let index = copyRows.findIndex(item=>item.id === ID); 
                copyRows.splice(index, 1, {...skeletonBase, id:ID});
                setGridRows(copyRows);
                dispatch(skillUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(skillCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && skill_Edit && skill_Edit.data) {
        let editData = skill_Edit.data
        setFormValues({
            skill_name: editData.skill_name,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && skill_List.message) {
        if (skill_List.status === 200) {
            if(clearGridListing.current){
                setGridRows(uniqueObjArray([...skill_List.data.rows]));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(skill_List.data.count);
            }
            else{
                setGridRows((pre)=>uniqueObjArray([...pre, ...skill_List.data.rows]));
                SET_MAX_ROW_LENGTH(skill_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(skill_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }
    const handleClickEdit = (id) => {
        dispatch(skillEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            description: data.description,
            skill_name: data.skill_name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = {...data};
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item=>item.id === data.id); 
        copyRows.splice(index, 1, {...skeletonBase, id:data.id});
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(skillUpdate({ ID, addvalues }))
    }

    // //custom pagination
    // const handlePagination = (data) => {
    //     setCurrentPage(data)
    //     let newFilterData = JSON.parse(filterQuery);
    //     newFilterData['skip'] = data * pageSize
    //     newFilterData['limit'] = limit
    //     setFilterQuery(JSON.stringify(newFilterData))
    //     dispatch(getSkillList(JSON.stringify(newFilterData)))
    // }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getSkillList(JSON.stringify(newFilterData)))
    }

    // function CustomPagination() {
    //     return (
    //         <TablePagination
    //             component="div"
    //             count={(skill_List && skill_List.data && skill_List.data.rows && skill_List.data.count) || 0}
    //             page={currentPage}
    //             rowsPerPage={pageSize}
    //             rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
    //             onPageChange={() => setCurrentPage(pageSize)}
    //             onRowsPerPageChange={handlePageSizeChange}
    //             ActionsComponent={(subProps) =>
    //                 <TablePaginationActions {...subProps}
    //                     handlePagination={handlePagination}
    //                 />}
    //         />
    //     );
    // }

    // sorting
    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length>0 && copySorter.find(item=>item.col===sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = 25;
        // gridRows.length
        if(foundElement)
        {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-'+sortingCol : sortingCol}`
            }
            copySorter.map(item=>{
                if(item.id===foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(()=>copySorter)
        }
        else{
          
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre)=>[...pre, {id:pre.slice(0,-1)[0]?.id ? pre.slice(0,-1)[0]?.id+1 : 1, col:sortingCol, order:'asc'}]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);
        
    }
    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
        contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null
        );
    };

    const handleMenuAction = (actionType) =>
    {
        let row = gridRows.find(item=>item.id === selectedRow);
        
        switch(actionType)
        {
            // case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':    
                    handleClickEdit(row.id)
                    break;

        }

        setContextMenu(null);

    }

    const filterOperators = getGridStringOperators().filter(({value}) => ['contains', 'equals', 'startsWith', 'endsWith'].includes(value))

    const actionColumn = [
        {
            field: "skill_name",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Skills</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("skill_name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            minWidth: 230,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.skill_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.skill_name}
                    </div>
                )
            }
        },
        {
            field: "skill_key",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Skill-Key</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("skill_key")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            minWidth: 230,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.skill_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.skill_key}
                    </div>
                )
            }
        },
        {
            field: "description",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ color: '#1B3764' }}>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => !reload && handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1.35rem', color: '#BCC2CE' }} /></button></div>,
            minWidth: 300,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :

                    <div>          
                        {params.row.description}
                    </div>
                )
            }
            
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 160,
            sortable: false,
            type:"singleSelect",
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            renderCell: (params) => {
                return (
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320}/> :
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="skill"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                // isDelete={true}
                                editButton={handleClickEdit}
                                viewButton={false}
                            // deleteButton={() => handleArchive(params.row)} 
                            />
                        </div>
                    </div>
                );
            },
        },
    ];
    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };
    const onFilterChange = (props) =>
    {
        if(props.items.length > actionColumn.length)
            return;

        if(props.items.length === 0)
        {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-status"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.SKILL_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if(filterModal?.items?.length < props?.items?.length){
            if(props.items.length > 1)
            {
                let selectedCols = filterModal.items.map(item=>item.columnField);
                let remainCols = filterableColumns.filter(item=>![...selectedCols].includes(item))
                let newProps ={...props};
                newProps.items[newProps.items.length-1].columnField = remainCols[0];
                let index = props.items.findIndex(item=>item.columnField === 'status')
                if(newProps.items.some(item=>item.columnField==='status')){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        }else if(filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length){

            if(!(filterModal?.items?.length > props?.items?.length)){

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item=>item.columnField);
                let prevLen = selectedCols.length;

                if((new Set(selectedCols)).size != prevLen){
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item=>filterModalCols.push(item.columnField));
                props.items.map(item=>propsCols.push(item.columnField));
                
                for(let i=0; i<filterModalCols.length; i++)
                {
                    if(filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]:{}, skip:0, limit:limit, order_by: {column:"-status"}, columns:DefaultFilterColumns.SKILL_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim())};
            let operators = {
            contains:'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'startswith',
            endsWith: 'endswith'
            }
        
            if(filterItems?.length)
            {
                let valContainers = filterItems.filter(item=>item?.value)
                if(valContainers?.length)
                {
                    let filter = valContainers.reduce((acc, item)=>({...acc, [item.columnField]:{value: item.value, operation:operators[item.operatorValue]}}),{})
                    // if no changes on filter open starts
                      props.items.map((elem, index) => {

                        if((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true; 
                        else
                            isAnyChange.current[index] = false; 
                    })
                    // no change (! reverse by not operator)
                    if(!(Object.values(isAnyChange.current).every(item=>item===false))){
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]:{...filter}}));
                }
            }
            return;
        }
    }
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Skill List</h3>
                        <div>
                            <Tooltip title="Add Skill">
                                {/* <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button> */}
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                            </Tooltip>
                            <RefreshButton api={getSkillList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {/* <div className="custom-pagination-class">
                        <CustomPagination />
                    </div> */}
                    {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload && */}
                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        // loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            ColumnMenuIcon: MoreHorizIcon,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        componentsProps={{
                            row: {
                            onContextMenu: handleContextMenu,
                            }
                          }}
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        onSortModelChange={(sort) => handleSort(sort)}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooterPagination={true}
                    />
                    {/* } */}
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-describedby="child-modal-description"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: "white" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Skill</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Skill"}
                                    name="skill_name"
                                    defaultValue={formValues.skill_name}
                                    type="text"
                                    placeholder="Skill Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.skill_nameErr}
                                />
                                 <InputFields
                                    label={"Skill Key"}
                                    name="skill_key"
                                    defaultValue={formValues.skill_key}
                                    type="text"
                                    placeholder="Skill-Key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.skill_keyErr}
                                />
                                <InputTextareaField
                                    label={"Description"}
                                    defaultValue={formValues.description}
                                    name="description"
                                    onChange={handleChange}
                                    rows="3"
                                    placeholder="Description"
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                {/* <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                /> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <Button variant="contained" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Skill`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                    componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                        e.preventDefault();
                        handleClose();
                        }
                    }
                    }}
                >   

                    <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
            
                </Menu>
            </div>
        </div >
    )
}

export default Skill