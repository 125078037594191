import { AddCircleOutline, Close, FormatListBulleted } from "@mui/icons-material"
import { AppBar, Box, Dialog, DialogContent, DialogTitle, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Button } from "rsuite"
import { InputFields, InputFieldss, InputMultiSelectField, InputSelectField, InputSelectValueField, SingleDatePickerInputField, SingleTimePickerInputField } from "../../components/common-function/InputFields"
import CONSTANTS from "../../components/constant/constantComponents"
import { candidateCreate, createCandidateExamSchedule, getBatchList, getBatchTechnologyList, getQuestionSetList, getScheduleExamBatchSetList, getDepartmentListByIndustryId, getTechnologyListByIndustryDepartmentId } from "../../redux/features/masterSlice"
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm"
import dataService from "../../redux/services/data.service"
import { taskViewById } from "../../redux/features/taskSlice"
import moment from "moment"
import Select from 'react-select';
import GetLocalstorage from "../../components/common-function/GetLocalstorage"

//const { REACT_CES_APP_API_BASEURL } = process.env

const formErrData = Object.freeze({
    candidateErr: "Candidate name is required",
    technologyErr: "Technology is required",
    levelErr: "Question level is required",
    setErr: "Set is required",
    dateErr: "Schedule date  is required",
    timeErr: "Schedule time  is required",
    emailErr: "Email is required",
    contactErr: "Contact is required"
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}


const user = GetLocalstorage.userDetail()

export const ExamSchedulForm = ({ open, handleClose, candidateData, candidateExamList, setCandidateExamList, examCandidateInfo, setExamCandidateInfo, storyId, emails, contacts, setApiLoading, mytasklistapi,
    filters, examTabvalue, setExamTabvalue }) => {
    const dispatch = useDispatch()
    const [selectedTechnology, setSelectedTechnology] = useState("")
    const [selectedLevel, setSelectedLevel] = useState("")
    const [selectedSet, setSelectedSet] = useState("")
    const [selectedBatch, setSelectedBatch] = useState("")
    const [selectedScheduledDate, setSelectedScheduledDate] = useState(null)
    const [selectedScheduledTime, setSelectedScheduledTime] = useState(null)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")
    const [reload, setReload] = useState(false)
    const [activeForm, setActiveForm] = useState(false)
    const [activeCreateCandidate, setActiveCreateCandidate] = useState(false)
    const { questionlevel_List, department_list_by_industryId, technology_list_by_department_industryId, questionset_List, candidateExamScheduleCreate, candidateError, candidateStatus, createCandidate, candidateCreateError, batch_List, batch_technology_filter_List, scheduleExamBatchSetList, industry_List_Ces } = useSelector(state => state.master)
    const [ces] = useState(LocalStorageDataService.cesCandidateDetails())
    const [tabvalue, settabValue] = useState(0);
    const [tabLoading, setTabLoading] = useState(false)
    const [buttonActive, setButtonActive] = useState(false)
    const [startScheduledDateTime, setStartScheduledDateTime] = useState("")
    const [selectedExamSchedule, setSelectedExamSchedule] = useState("")
    // const [endScheduledDateTime, setEndScheduledDateTime] = useState("")

    useEffect(() => {
        // if (candidateExamList && candidateExamList.length > 0) { } else {
        //     if (open === true) {
        //         dispatch(getQuestionLevelList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        //         dispatch(getTechnologyList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        //     }
        // }
        if (open === false) {
            setSelectedSet("")
            setSelectedLevel("")
            setSelectedTechnology("")
            setSelectedScheduledDate(null)
            setSelectedScheduledTime(null)
            setStartScheduledDateTime("")
            setSelectedBatch("")
            setSelectedExamSchedule("")
        }

    }, [open])


    const changeSetLevel = (evt) => {
        const { value } = evt
        //value && selectedTechnology.value && dispatch(getQuestionSetList(`{"filter":{"level_id":{"value":${value}, "operation":"eq"}, "technology_id":{"value":${selectedTechnology.value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
        setSelectedLevel(evt)
        let array = []

        if (selectedTechnology.length > 0) {
            selectedTechnology.map(e => { return array.push(e.id) })
            value && dispatch(getQuestionSetList(`{"filter":{"set__status":{"value":"True", "operation":"eq"},"level_id":{"value":${value}, "operation":"eq"}, "technology_id":{"value":[${array}], "operation":"in"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
        }
        setSelectedSet("")
    }
    const changeSetTechology = (evt) => {
        //const { value } = evt
        //value && selectedLevel.value && dispatch(getQuestionSetList(`{"filter":{"level_id":{"value":${selectedLevel.value}, "operation":"eq"}, "technology_id":{"value":${value}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
        if (evt.length > 0) {
            let array = []
            evt.map(e => { return array.push(e.id) })
            array && array.length > 0 && dispatch(getBatchTechnologyList({ technology_ids: array }))
            selectedLevel.value && dispatch(getQuestionSetList(`{"filter":{"set__status":{"value":"True", "operation":"eq"},"level_id":{"value":${selectedLevel.value}, "operation":"eq"}, "technology_id":{"value":[${array}], "operation":"in"}},"order_by":{"column":"-id"},"no_limit":'True'}`))
        }
        setSelectedTechnology(evt)
        //setSelectedSet("")
        // setStartScheduledDateTime("")
        // setSelectedScheduledDate("")
        //setSelectedScheduledTime("")
    }

    const validate = (email, contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        let pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = true
        }


        return errors
    }

    const submitData = async (e) => {
        e.preventDefault()
        setButtonActive(true)
        let contact_status = false
        if (selectedTechnology === "" || selectedLevel === "" || selectedSet === ""
            || selectedScheduledDate === null || selectedScheduledTime === null || examCandidateInfo.email === "" || examCandidateInfo.contact_no === "") {
            setErrStatus(true)
        }

        if (selectedTechnology && selectedLevel && selectedSet && selectedScheduledDate && selectedScheduledTime && examCandidateInfo.email && examCandidateInfo.contact_no) {
            const isValidate = validate(examCandidateInfo.email, examCandidateInfo.contact_no)

            if (isValidate.email === true && isValidate.contact === true) {
                let id = candidateData.id

                const filterEmail = emails && emails.length > 0 ? emails.filter(x => x.email === examCandidateInfo.email) : []
                const filterContact = contacts && contacts.length > 0 ? contacts.filter(x => x.phone_number === examCandidateInfo.contact_no) : []

                // const resquests = await dataService.get_candidate(`{"filter":{"contact_no":{"value":"${examCandidateInfo.contact_no}", "operation":"eq"}}, "columns":["id", "created_by", "user_id"]}`)
                // const resultss = resquests.data;


                if (filterEmail.length === 0) {
                    const requsetUpdateEmail = await dataService.update_candiate_email_from_story({
                        "story_id": storyId,
                        "email": examCandidateInfo.email
                    });
                    const response = requsetUpdateEmail.data;
                    if (response && response.status === 200) {
                        setApiLoading(true)
                        dispatch(taskViewById(storyId));
                        dispatch(mytasklistapi(filters))
                        contact_status = false
                    } else {
                        contact_status = true
                        setButtonActive(false)
                        toast.error(response.message)
                    }
                }
                if (filterContact.length === 0) {
                    const addvalues = {
                        country_code: "91",
                        phone_number: examCandidateInfo.contact_no,
                        story_id: storyId
                    }

                    const requsetUpdateContact = await dataService.candidate_phone_add(addvalues)
                    const responseContact = requsetUpdateContact.data;
                    if (responseContact && responseContact.status === 200) {
                        setApiLoading(true)
                        dispatch(taskViewById(storyId));
                        dispatch(mytasklistapi(filters))
                        contact_status = false
                    } else {
                        contact_status = true
                        setButtonActive(false)
                        toast.error(responseContact.message)
                    }
                }
                let tempArray = []
                selectedTechnology.map(e => { return tempArray.push(e.id) })
                let addvalues = {
                    candidate: candidateData.id,
                    //technology: selectedTechnology.value,                    
                    technologies: tempArray,
                    // question_level: selectedLevel.value,
                    experience_level: selectedLevel.value,
                    set: selectedSet.value,
                    batch: selectedBatch.value,
                    scheduled_date: selectedScheduledDate,
                    scheduled_time: selectedScheduledTime,
                    created_by: ces.id,
                    exam_schedule: selectedExamSchedule.value,
                    status: true,
                    story_id: storyId,
                    agent_name: user?.name,
                    agent_id: user?.id
                }
                if (contact_status === false) {
                    const resquest = await dataService.get_candidate(`{"filter":{"email":{"value":"${examCandidateInfo.email}", "operation":"eq"}}, "columns":["id", "created_by", "user_id",  "total_experience_in_months"]}`)
                    const results = resquest.data;
                    if (results.status === 200 && results.data && results.data.rows && results.data.rows.length > 0) {
                        setExamCandidateInfo({ ...examCandidateInfo, 'user': results.data.rows[0].user_id })
                        examCandidateInfo['user'] = results.data.rows[0].user_id
                        addvalues['candidate'] = results.data.rows[0].id
                        id = results.data.rows[0].id
                    } else {
                        id = ""
                    }

                    setErrStatus(false)
                    setValidateErr("")
                    if (!addvalues.candidate) {
                        setActiveCreateCandidate('create')
                        dispatch(candidateCreate(examCandidateInfo))

                    } else {
                        if (id) {
                            const req = await dataService.update_candidate(id, examCandidateInfo);
                            const result = req.data;
                            if (result.status === 200) {
                                setReload(true)
                                dispatch(createCandidateExamSchedule(addvalues))
                            } else {
                                toast.error(result.message)
                                setButtonActive(false)
                                setReload(false)
                            }
                        } else {
                            setActiveCreateCandidate('create')
                            dispatch(candidateCreate(examCandidateInfo))

                        }

                    }

                }
            } else {
                setButtonActive(false)
                setErrStatus(true)
                setValidateErr(isValidate)
            }

        }
    }

    const checkExsistNumber = async () => {
        const resquest = await dataService.get_candidate(`{"filter":{"contact_no":{"value":"${examCandidateInfo.contact_no}", "operation":"eq"}}, "columns":["id", "created_by", "user_id", "total_experience_in_months"]}`)
        const results = resquest.data;
        if (results.status === 200 && results.data && results.data.rows && results.data.rows.length > 0) {
            toast.error("candidate registration with this contact no already exists.")
        } else {
            toast.error(candidateCreateError.message)
        }
    }

    if (createCandidate && createCandidate.status && activeCreateCandidate === 'create') {
        setActiveCreateCandidate(false)
        setButtonActive(false)
        setReload(false)
        if (createCandidate.status === 200) {
            let tempArray = []
            selectedTechnology.map(e => { return tempArray.push(e.id) })
            let addvalues = {
                candidate: createCandidate.data.id,
                //technology: selectedTechnology.value,
                technologies: tempArray,
                //question_level: selectedLevel.value,
                experience_level: selectedLevel.value,
                set: selectedSet.value,
                batch: selectedBatch.value,
                scheduled_date: selectedScheduledDate,
                scheduled_time: selectedScheduledTime,
                exam_schedule: selectedExamSchedule.value,
                created_by: ces.id,
                status: true,
                story_id: storyId,
                agent_name: user?.name,
                agent_id: user?.id,
            }
            setExamCandidateInfo({ ...examCandidateInfo, 'user': createCandidate.data.user })
            dispatch(candidateCreate('type'))
            dispatch(createCandidateExamSchedule(addvalues))
            //setTimeout(() => {
            setReload(true)
            // }, 500);
        } else {
            toast.error(createCandidate.message)
        }
    }

    if (candidateCreateError !== '' && activeCreateCandidate === 'create') {
        setActiveCreateCandidate(false)
        setReload(false)
        setButtonActive(false)
        checkExsistNumber()
    }

    const activeTabApiCall = async (active_index) => {
        let schedule_status = active_index === 0 ? 0 : 3;
        setCandidateExamList([])
        let candidate_emails = [];
        emails.length > 0 && emails.map(item => candidate_emails.push(item.email))
        const resquest = await dataService.get_candidate(JSON.stringify({ "filter": { "email": { "value": candidate_emails, "operation": "in" } } }))
        const result = resquest.data
        if (result && result.data && result.data.rows && result.data.rows.length > 0) {
            let candidates = [];
            result.data && result.data.rows && result.data.rows.length > 0 && result.data.rows.map(item => candidates.push(item.id))
            if (schedule_status === 3) {
                const request = await dataService.get_candidate_exam_result_list(`{"filter":{"candidate":{"value":[${candidates}], "operation":"in"}}, "no_limit":True}`)
                const examlist = request.data;
                if (examlist.data && examlist.data.rows && examlist.data.rows.length > 0) {
                    setCandidateExamList(examlist.data.rows)
                } else {
                    setCandidateExamList([])
                }
            } else {
                const request = await dataService.get_candidate_exam_schedule_list(`{"filter":{"candidate":{"value":[${candidates}], "operation":"in"}, "schedule_status":{"value":${schedule_status}, "operation":"eq"}}, "column":{"order_by":"-id"}, "no_limit":True}`)
                const examlist = request.data;
                if (examlist.data && examlist.data.rows && examlist.data.rows.length > 0) {
                    setCandidateExamList(examlist.data.rows)
                } else {
                    setCandidateExamList([])
                }
            }

        }
        setTimeout(() => {
            setTabLoading(false)

        }, 500);

    }

    const loadCreateData = async (id) => {
        const request = await dataService.get_candidate_exam_schedule_list(`{"filter":{"candidate":{"value":"${id}", "operation":"eq"}, "schedule_status":{"value":0, "operation":"eq"}}, "column":{"order_by":"-id"}}`)
        const examlist = request.data;
        setCandidateExamList(examlist.data.rows)
        settabValue(0)
    }

    if (candidateExamScheduleCreate.status && reload === true) {
        setReload(false)
        setButtonActive(false)
        if (candidateExamScheduleCreate.status === 200) {
            setSelectedSet("")
            setSelectedLevel("")
            setSelectedTechnology("")
            setStartScheduledDateTime("")
            setSelectedScheduledDate(null)
            setSelectedScheduledTime(null)
            setCandidateExamList([])
            setActiveForm(false)
            setSelectedBatch("")
            dispatch(getBatchTechnologyList('type'))
            loadCreateData((createCandidate && createCandidate.data && createCandidate.data.id) || candidateExamScheduleCreate.data.candidate)
            //handleClose()
            toast.success(candidateExamScheduleCreate.message)
        } else {
            toast.error(candidateExamScheduleCreate.message)
        }

    }
    if (candidateStatus === 'error' && candidateError.message && reload === true) {
        setReload(false)
        setButtonActive(false)
        toast.error(candidateError.message)
    }

    const closeModal = () => {
        dispatch(getQuestionSetList('type'))
        dispatch(getBatchList('type'))
        dispatch(getBatchTechnologyList('type'))
        dispatch(createCandidateExamSchedule('type'))
        setSelectedSet("")
        setSelectedLevel("")
        setSelectedTechnology("")
        setSelectedScheduledDate(null)
        setSelectedScheduledTime(null)
        setCandidateExamList([])
        setExamCandidateInfo("")
        setActiveForm(false)
        setReload(false)
        setErrStatus(false)
        setValidateErr("")
        settabValue(0)
        handleClose(false)
        setExamTabvalue(false)
        setStartScheduledDateTime("")
        setButtonActive(false)
        setSelectedBatch("")
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'email') {
            setExamCandidateInfo({ ...examCandidateInfo, [name]: value, 'password': value })
            setButtonActive(false)
        }else if (name === "contact_no") {

            setExamCandidateInfo({ ...examCandidateInfo, [name]: value, 'password': value })
            
            if (value && /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(value)) {
                setButtonActive(false)
            }

        }
        else if (name === "industry") {
            const queary = `{"filter":{"industry_id":{"value":${value ?? ""},"operation": "eq"},"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`
            dispatch(getDepartmentListByIndustryId(queary))
            setExamCandidateInfo({ ...examCandidateInfo, [name]: evt, department: "" })
            setSelectedTechnology("")

        } else if (name === "department") {
            let queary = `{"filter":{"industry_id":{"value":${examCandidateInfo.industry.value ?? ""},"operation": "eq"} ,"department_id":{"value":${value ?? ""},"operation": "eq"} ,"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`
            dispatch(getTechnologyListByIndustryDepartmentId(queary))
            setExamCandidateInfo({ ...examCandidateInfo, [name]: evt })
            setSelectedTechnology("")
        }
        else {
            setExamCandidateInfo({ ...examCandidateInfo, [name]: evt })
        }
    }

    const handleForm = () => {
        dispatch(getQuestionSetList('type'))
        dispatch(getBatchList('type'))
        dispatch(getBatchTechnologyList('type'))
        dispatch(createCandidateExamSchedule('type'))
        setSelectedSet("")
        setSelectedLevel("")
        setSelectedTechnology("")
        setSelectedScheduledDate(null)
        setSelectedScheduledTime(null)
        setActiveForm(!activeForm)
        setErrStatus(false)
        setValidateErr("")
        setTabLoading(true)
        activeTabApiCall(tabvalue)
        setStartScheduledDateTime("")
        setSelectedBatch("")
    }

    const handleTabChange = (event, newValue) => {
        setTabLoading(true)
        activeTabApiCall(newValue)
        settabValue(newValue);
    };

    const handleChangedSet = (evt) => {
        setSelectedSet(evt)
        if (evt.value) {
            if (selectedBatch && selectedBatch.value) {
                dispatch(getScheduleExamBatchSetList(`{"filter":{"batch":{"value":${selectedBatch.value}, "operation":"eq"},"set_id":{"value":${evt.value}, "operation":"eq"}}, "no_limit":"True" }`))
            } else {
                dispatch(getScheduleExamBatchSetList('type'))
                setSelectedExamSchedule("")
            }

        }
    }

    const handleBatchChange = (evt) => {
        if (evt.value) {
            let filter = batch_List.data && batch_List.data.rows.filter(x => x.id === evt.value)
            if (filter && filter.length > 0) {
                setStartScheduledDateTime(filter[0])
                filter[0].start_date ? filter[0].start_date >= moment().format('YYYY-MM-DD') ? setSelectedScheduledDate(filter[0].start_date) : setSelectedScheduledDate(moment().format('YYYY-MM-DD')) : setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
                filter[0].start_time ? setSelectedScheduledTime(filter[0].start_time) : setSelectedScheduledTime('00:00')


            } else {
                setSelectedScheduledDate(moment().format('YYYY-MM-DD'))
                setSelectedScheduledTime('00:00')
            }
            if (selectedSet && selectedSet.value) {
                dispatch(getScheduleExamBatchSetList(`{"filter":{"batch":{"value":${evt.value}, "operation":"eq"},"set_id":{"value":${selectedSet.value}, "operation":"eq"}}, "no_limit":"True" }`))

            } else {
                dispatch(getScheduleExamBatchSetList('type'))
                setSelectedExamSchedule("")
            }
        }

        setSelectedBatch(evt)
        // if (evt.start_date) {
        //     if (evt.start_time) {
        //         setStartScheduledDateTime(evt.start_date+" "+evt.start_time)
        //     } else {
        //         setStartScheduledDateTime(evt.start_date)
        //     }
        // }

        // if (evt.end_date) {
        //     if (evt.end_time) {
        //         setEndScheduledDateTime(evt.end_date+" "+evt.end_time)
        //     } else {
        //         setEndScheduledDateTime(evt.end_time)
        //     }
        // }
    }
    // const handleDateChange = (evt) => {
    //     setSelectedScheduledDate(evt)
    //     if (startScheduledDateTime.start_date && evt === startScheduledDateTime.start_date) {
    //         setSelectedScheduledTime(startScheduledDateTime.start_time)
    //     } else if (startScheduledDateTime.end_date && evt === startScheduledDateTime.end_date) {
    //         setSelectedScheduledTime(startScheduledDateTime.end_time)
    //     } else {
    //         setSelectedScheduledTime('00:00')
    //     }
    // }

    // const handleTimeChange = (evt) => {
    //     if (startScheduledDateTime.start_date && selectedScheduledDate === startScheduledDateTime.start_date) {

    //         if (startScheduledDateTime.start_time && evt < startScheduledDateTime.start_time) {
    //             //setSelectedScheduledTime('00:00')
    //             toast.error('Time is greater or equal then batch start time')
    //         } else {
    //             setSelectedScheduledTime(evt)

    //         }
    //     }
    //     else if (startScheduledDateTime.end_date && selectedScheduledDate === startScheduledDateTime.end_date) {
    //         if (startScheduledDateTime.end_time && (evt > startScheduledDateTime.end_time)) {
    //             //setSelectedScheduledTime('00:00')
    //             toast.error('Time is less then batch end time')

    //         } else {
    //             setSelectedScheduledTime(evt)

    //         }
    //     } else {
    //         setSelectedScheduledTime(evt)

    //     }
    // }

    // const technologyNames = (technologies) => {
    //     let tech_list = technology_List && technology_List.data && technology_List.data.rows ? technology_List.data.rows : []
    //     let technology_names_array = []
    //     if (technologies.length > 0) {
    //         technologies.map(e => {
    //             let filter = tech_list.length > 0 && tech_list.filter(x => x.id === e)
    //             if (filter.length > 0) {
    //                 technology_names_array.push(filter[0].name)
    //             }
    //             return technology_names_array
    //         })
    //     }
    //     return technology_names_array

    // }

    return (

        <Dialog
            hideBackdrop
            fullWidth
            maxWidth={"md"}
            open={open === false ? false : true}
            onClose={closeModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Button
                edge="start"
                color="inherit"
                onClick={() => closeModal(false)}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px", background: 'rgb(33, 150, 243)' }}>
                <Close />
            </Button>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                {((candidateExamList && candidateExamList.length > 0 && tabvalue === 0 && activeForm === false) || (activeForm === false && tabvalue === 1) || (tabLoading === true) || (activeForm === false && examTabvalue === true)) ?
                    <p>Already Exam Scheduled <Tooltip title="Schedule Exam">
                        <AddCircleOutline style={{ cursor: 'pointer', marginBottom: '-5px' }} onClick={() => handleForm()} /></Tooltip>
                    </p> :

                    <p>Exam Schedule  {((candidateExamList && candidateExamList.length > 0 && activeForm === true) || (tabvalue === 1) || (examTabvalue === true)) &&
                        <Tooltip title="Exam list">
                            <FormatListBulleted style={{ cursor: 'pointer', marginBottom: '-5px' }} onClick={() => handleForm()} />
                        </Tooltip>}
                    </p>
                }</DialogTitle>

            <DialogContent>
                {(reload === true || open !== true) ?
                    <div style={{ height: '600px' }}>
                        <LoaderForm />

                    </div>
                    :
                    <div style={{ height: '600px' }}>
                        {(tabLoading === true || (candidateExamList && candidateExamList.length > 0 && tabvalue === 0 && activeForm === false) || (activeForm === false && tabvalue === 1) || (activeForm === false && examTabvalue === true)) ?
                            <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                                <AppBar position="sticky" style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}>
                                    <Tabs
                                        value={tabvalue}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab
                                            value={0}
                                            label="Scheduled Exam"
                                        />
                                        <Tab value={1} label={`Appeared Exam `} />

                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Technologies</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Set</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Scheduled Date</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Scheduled Time</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {tabLoading === true ?
                                                <TableBody>
                                                    <LoaderForm />
                                                </TableBody>
                                                :
                                                <TableBody>
                                                    {candidateExamList.length === 0 ?
                                                        <TableRow>
                                                            <TableCell align="center" rowSpan={3} colSpan={3}>Record Not Found!</TableCell>
                                                        </TableRow>
                                                        :
                                                        candidateExamList.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell className="candidate-exam-table-list" align="left" >{item?.technologies_name}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left" >{item.set && item.set.set_name}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left" >{item.scheduled_date}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left" >{item.scheduled_time}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left" >{item.candidate && item.candidate.email}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            }
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Technology</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Set</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Scheduled Date & Time</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Appeared Date & Time</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Result</TableCell>
                                                    <TableCell align="left" style={{ fontWeight: 600, padding: 5 }}>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {tabLoading === true ?
                                                <TableBody>
                                                    <LoaderForm />
                                                </TableBody>
                                                :
                                                <TableBody>
                                                    {candidateExamList.length === 0 ?
                                                        <TableRow>
                                                            <TableCell align="center" rowSpan={3} colSpan={3}>Record Not Found!</TableCell>
                                                        </TableRow>
                                                        :
                                                        candidateExamList.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell className="candidate-exam-table-list" align="left">{item.candidate_exam_schedule && item.candidate_exam_schedule.technologies_name && item.candidate_exam_schedule.technologies_name}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left">{item.candidate_exam_schedule && item.candidate_exam_schedule.set && item.candidate_exam_schedule.set.set_name}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left">{item.candidate_exam_schedule && item.candidate_exam_schedule.scheduled_date} {item.candidate_exam_schedule && item.candidate_exam_schedule.scheduled_time}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left">{moment(item.exam_end_datetime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left">{item.marks_obtained} out of {item.total_marks} </TableCell>
                                                                <TableCell className="candidate-exam-table-list" align="left">{item.candidate_exam_schedule && item.candidate_exam_schedule.candidate && item.candidate_exam_schedule.candidate.email}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            }
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </Box>

                            :
                            <form >
                                <div className="add-new-user-form">
                                    <InputFields
                                        name={'email'}
                                        label={'Email'}
                                        defaultValue={examCandidateInfo.email}
                                        type={'text'}
                                        placeholder={'Email Address'}
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.emailErr}
                                        validateErr={validateErr.email}
                                    />
                                    <InputFields
                                        name={'contact_no'}
                                        label={'Contact No.'}
                                        defaultValue={examCandidateInfo.contact_no}
                                        type={'contact'}
                                        placeholder={'Contact Number'}
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.contactErr}
                                        validateErr={validateErr.contact}
                                    />

                                    <div className="formInput">
                                        <label >
                                            Select Industry
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            divstyle={{ flexBasis: '100%' }}
                                            placeholder={'Select Industry'}
                                            onChange={handleChange}
                                            defaultValue={examCandidateInfo.industry}
                                            value={examCandidateInfo.industry}
                                            isSearchable={true}
                                            name={'industry'}
                                            options={
                                                industry_List_Ces && industry_List_Ces.data && industry_List_Ces.data.rows.map((option) => {
                                                    return {
                                                        label: option.industry_name,
                                                        value: option.id,
                                                        name: 'industry'
                                                    }
                                                })
                                            }
                                        />
                                        {/* {errStatus === true && examCandidateInfo && examCandidateInfo.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>} */}
                                    </div>

                                    {examCandidateInfo.industry ?
                                        <div className="formInput">
                                            <label >
                                                Department
                                            </label>
                                            <Select
                                                // isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Select Department"
                                                defaultValue={examCandidateInfo.department}
                                                value={examCandidateInfo.department}
                                                isSearchable={true}
                                                name={"department"}
                                                options={
                                                    department_list_by_industryId?.data?.rows[0]?.department.map((option) => {
                                                        return { label: option.name, value: option.id, name: "department" }
                                                    })
                                                }
                                                // onChange={handleChangeOnDepartment}
                                                onChange={handleChange}
                                            />
                                            {errStatus === true && examCandidateInfo && examCandidateInfo.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div> : <></>
                                    }

                                    {
                                        examCandidateInfo?.department ?

                                            <InputMultiSelectField
                                                label={'Technology'}
                                                placeholder="Select Technology"
                                                defaultValue={selectedTechnology}
                                                name={"technology_id"}
                                                onRemove={changeSetTechology}
                                                onSelect={changeSetTechology}
                                                optionList={technology_list_by_department_industryId?.data?.rows[0]?.specialization_data ?? []}
                                                optionLevel={['name']}
                                                optionValue={"id"}
                                                errStatus={errStatus}
                                                formErr={formErr.technologyErr}
                                                divstyle={{ flex: '100%' }}
                                            />
                                            : <></>
                                    }

                                    <InputSelectValueField
                                        label={'Experience Level'}
                                        placeholder="Select Experience Level"
                                        defaultValue={selectedLevel}
                                        name={"level_id"}
                                        onChange={changeSetLevel}
                                        optionList={questionlevel_List && questionlevel_List.data && questionlevel_List.data.rows.length > 0 ? questionlevel_List.data.rows : []}
                                        optionLevel={['level_name']}
                                        optionValue={"id"}
                                        errStatus={errStatus}
                                        formErr={formErr.levelErr}
                                    />
                                    {(batch_technology_filter_List && batch_technology_filter_List.data && batch_technology_filter_List.data.batch_data && batch_technology_filter_List.data.batch_data.length > 0) &&
                                        <InputSelectValueField
                                            label={'Batch'}
                                            placeholder="Select Batch"
                                            defaultValue={selectedBatch}
                                            name={"batch_id"}
                                            onChange={handleBatchChange}
                                            optionList={batch_technology_filter_List && batch_technology_filter_List.data && batch_technology_filter_List.data.batch_data && batch_technology_filter_List.data.batch_data.length > 0 ?
                                                [...new Map((batch_technology_filter_List.data.batch_data).map((item) => [item["batch_id"], item])).values()]
                                                : []}
                                            optionLevel={['batch_name']}
                                            optionValue={"batch_id"}
                                            errStatus={errStatus}
                                            formErr={formErr.setErr}
                                        />}
                                    {(startScheduledDateTime && startScheduledDateTime.start_date) &&
                                        <InputFieldss
                                            label={'Batch Start Date and Time'}
                                            name={'start_date_time'}
                                            defaultValue={startScheduledDateTime.start_time ? startScheduledDateTime.start_date + " " + startScheduledDateTime.start_time : startScheduledDateTime.start_date}
                                            type={'text'}
                                            placeholder={'yyyy-mm-dd hh:mm'}
                                            //onChange={onChange}
                                            disabled={true}
                                        />
                                    }

                                    {(startScheduledDateTime && startScheduledDateTime.end_date) &&
                                        <InputFieldss
                                            label={'Batch End Date and Time'}
                                            name={'end_date_time'}
                                            defaultValue={startScheduledDateTime.end_time ? startScheduledDateTime.end_date + " " + startScheduledDateTime.end_time : startScheduledDateTime.end_date}
                                            type={'text'}
                                            placeholder={'yyyy-mm-dd hh:mm'}
                                            //onChange={onChange}
                                            disabled={true}
                                        />
                                    }
                                    {(questionset_List && questionset_List.data && questionset_List.data.rows.length > 0) &&
                                        <InputSelectField
                                            label={'Set'}
                                            placeholder="Select Set"
                                            defaultValue={selectedSet}
                                            name={"set_id"}
                                            onChange={handleChangedSet}
                                            optionList={questionset_List && questionset_List.data && questionset_List.data.rows.length > 0 ? questionset_List.data.rows : []}
                                            optionLevel={['set_name']}
                                            optionValue={"id"}
                                            errStatus={errStatus}
                                            formErr={formErr.setErr}
                                        />}
                                    {(scheduleExamBatchSetList && scheduleExamBatchSetList.data && scheduleExamBatchSetList.data.rows && scheduleExamBatchSetList.data.rows.length > 0) &&
                                        <InputSelectValueField
                                            label={'Schedule'}
                                            placeholder="Select Schedule"
                                            defaultValue={selectedExamSchedule}
                                            name={"exam_schedule"}
                                            onChange={setSelectedExamSchedule}
                                            optionList={scheduleExamBatchSetList && scheduleExamBatchSetList.data && scheduleExamBatchSetList.data.rows && scheduleExamBatchSetList.data.rows.length > 0 ?
                                                [...new Map((scheduleExamBatchSetList.data.rows).map((item) => [item["id"], item])).values()]
                                                : []}
                                            optionLevel={['schedule_name']}
                                            optionValue={"id"}
                                            errStatus={errStatus}
                                            formErr={formErr.setErr}
                                        />}
                                    {(questionset_List && questionset_List.data && questionset_List.data.rows.length > 0) &&
                                        <SingleDatePickerInputField
                                            label={'Scheduled Dated'}
                                            placeholder="Select"
                                            defaultValue={selectedScheduledDate}
                                            name={"scheduled_dated"}
                                            onChange={setSelectedScheduledDate}
                                            format={'yyyy-MM-dd'}
                                            errStatus={errStatus}
                                            formErr={formErr.dateErr}
                                            minDate={moment().format('YYYY-MM-DD')}
                                        />}
                                    {(questionset_List && questionset_List.data && questionset_List.data.rows.length > 0) &&
                                        <SingleTimePickerInputField
                                            label={'Scheduled Time'}
                                            placeholder="Select"
                                            defaultValue={selectedScheduledTime}
                                            name={"scheduled_time"}
                                            onChange={setSelectedScheduledTime}
                                            format={'yyyy-MM-dd'}
                                            errStatus={errStatus}
                                            formErr={formErr.timeErr}
                                        />}

                                    {/* {(selectedTechnology && selectedTechnology.value && selectedLevel && selectedLevel.value && questionset_List && questionset_List.data && questionset_List.data.rows.length === 0) &&
                                        <p style={{ color: "#96332c", fontSize: 13 }}>Question set not available for selected technology and questions level </p>
                                    } */}
                                    {(batch_List && batch_List.data && batch_List.data.rows.length === 0) &&
                                        <p style={{ color: "#96332c", fontSize: 13 }}>Batch not available for selected technology </p>
                                    }
                                    {(questionset_List && questionset_List.data && questionset_List.data.rows.length === 0) &&
                                        <p style={{ color: "#96332c", fontSize: 13 }}>Set is not available for selected technology and experience level </p>
                                    }
                                    {(scheduleExamBatchSetList && scheduleExamBatchSetList.data && scheduleExamBatchSetList.data.rows.length === 0) &&
                                        <p style={{ color: "#96332c", fontSize: 13 }}>Schedule not available for selected Set and Btach </p>
                                    }

                                </div>
                                {(selectedSet === "" || buttonActive === true || selectedLevel === '' || selectedExamSchedule === '') ?
                                    <Button disabled style={{ backgroundColor: '#b9bdc1', color: '#fff' }}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                                    :
                                    reload === false && <button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>}
                            </form>

                        }
                    </div>
                }

            </DialogContent>
        </Dialog >
    )
}