import "./report.scss"
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { getConsolidateMonthlyList } from "../../redux/features/calendarSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import moment from "moment";
import { Button,FormControl, TextField, Autocomplete, TablePagination, Tooltip, Box, ListItem, CardContent, List, Grid, Card, Stack } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowBack, Download } from '@mui/icons-material';
import dayjs from 'dayjs';
import TablePaginationActions from "../../components/TablePaginationActions";
import { toast, ToastContainer } from "react-toastify";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DateRangePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import MoreFilters from "../../components/moreFilters/MoreFIlters";

const { REACT_APP_API_BASEURL } = process.env;

const AttendanceReportStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status, error, consolidateMonthlyList, consolidateHeader } = useSelector(state => state.calender)
    const {resourceListDropDown } = useSelector(state => state.resource)
    const [pageSize, setPageSize] = useState(25)
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(new Date());
    const [downliadFile, setDownloadFile] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [resourceId, setResourceId] = useState('select resource');
    const [resourceLabel, setResourceLabel] = useState(null);
    const [filterQuery, setFilterQuery] = useState(`{"attendance_month":"${moment().format('YYYY-MM')}", "skip":0, "limit":25}`)
    const [limit, setLimit] = useState(25);
    const [dateRange, setDateRange] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1),  new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)])
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoading(false)
        }
    }, [error])

    useEffect(() => {
        dispatch(getConsolidateMonthlyList(filterQuery))
        dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
    }, [])

    const setYearAndMonth = (newValue) => {
        setDate(newValue)
        setDateRange([new Date(newValue),new Date(newValue)])
    }
    const updateDateTime = (evt) => {
        setDateRange(evt)
        if(evt)
            setDate(evt[0])
        else
            setDate(new Date());
    }

    if (loading === true && consolidateMonthlyList.data && consolidateMonthlyList.data) {
        setLoading(false)
    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value, label } = evt.target || evt;
            setResourceId(value)
            setResourceLabel({label, value:label})
        } else {
            setResourceId('select resource');
            setResourceLabel(null)
        }
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)

        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getConsolidateMonthlyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(consolidateMonthlyList.data && consolidateMonthlyList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }


    if (downliadFile === true) {
        let api_url =consolidateMonthlyList?.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
                dispatch(getConsolidateMonthlyList(filterQuery))
                setLoading(true)
            }
        }
    }

    const downloadCsvFile = () => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter;
        if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
            let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"for_download": True, "no_limit":True}`
        } else
            if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"for_download": True, "no_limit":True}`
            } else
                if (resourceId !== 'select resource') {
                    filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "for_download": True, "attendance_month":${id_date}, "no_limit":True}`

                } else {
                    filter = `{"for_download": True, "attendance_month":${id_date}, "no_limit":True}`
                }
        setDownloadFile(true)
        // setFilterQuery(filter)
        dispatch(getConsolidateMonthlyList(filter))
    }

    const onFilterChange = (filterModel, r) => {
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filterData;

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setPageSize(10)
                let filter;

                if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
                    let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]

                    filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}},"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
                } else if (resourceId !== 'select resource') {
                    filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
                } else if (dateRange && dateRange[0]) {
                    let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                    filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
                } else {
                    filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
                }
                setFilterQuery(filter)
                dispatch(getConsolidateMonthlyList(filter))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            filterData = `{"filter" : {"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
            if (resourceId !== 'select resource' && dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filterData = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}, "${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "date_range":${JSON.stringify(date_filter)},"attendance_month":${id_date},"skip":0, "limit":${limit}}`

            }
            else if (resourceId !== 'select resource') {
                filterData = `{"or": {"display_name":{"value":"${resourceId}","operation":"icontains"}, "${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "attendance_month":${id_date}, "skip":0, "limit":${limit}}`

            } else if (dateRange && dateRange[0]) {
                let date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                filterData = `{"filter":{"${[filterModel.items[0].columnField]}" : {"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`

            }
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getConsolidateMonthlyList(filterData))
        }
        setLoading(true)
    }

    const FilterComponents = () => {
        return (
            <>  
                <List>
                    <ListItem>
                        <FormControl style={{width:'100%'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%", marginTop: "8px", marginRight: "10px" }} >
                                <DatePicker
                                    className="date-picker"
                                    views={['month', 'year']}
                                    label="Year and Month"
                                    maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                    value={date}
                                    onChange={(newValue) => {

                                        setYearAndMonth(newValue);
                                    }}
                                    format="YYYY-MM"
                                    renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>

                    <ListItem>
                      
                        <FormControl className="date-range-picker" style={{width:'98%'}}>
                            <DateRangePicker
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                showOneCalendar
                                value={dateRange}
                                disabledDate={date => isAfter(date, new Date())}
                                onChange={(evt) => updateDateTime(evt)}
                                defaultCalendarValue={[new Date(date)]}
                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl style={{ width:'98%' }}>
                            <Autocomplete className="select-resource-drop"
                                id="controllable-states-demo"
                                options={resourceListDropDown && resourceListDropDown.length>0?resourceListDropDown:[]}
                                autoHighlight
                                value={resourceLabel}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                 
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                        {option.display_name} <br/>({option.email})
                                    </Box>
                                   

                                )}
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="select resource" />}
                            />
                        </FormControl>
                    </ListItem>
                </List>
            </>
        )
    }

    // advance search - Searchhandler
    const handleFilterSearch = (drawerOpen) => {
        let date_filter;
        if(dateRange)
            date_filter = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
        else
            date_filter = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        let filter = '';
        const d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;

        if( resourceId !== 'select resource' && dateRange)
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`;
        else if(resourceId === 'select resource' && dateRange)
            filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        else if(resourceId !== 'select resource' && !dateRange)
            filter = `{"filter":{"display_name":{"value":"${resourceId}","operation":"icontains"}}, "date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        else{
            filter = `{"date_range":${JSON.stringify(date_filter)}, "attendance_month":${id_date},"skip":0, "limit":${limit}}`
        }

        setLoading(true);
        dispatch(getConsolidateMonthlyList(filter))
        drawerOpen(false);
    }

    // advance search - resetHandler
    const handleFilterReset = () => {
        const d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let id_date = `"${year}-${month}"`;
        let filter = `{"attendance_month":${id_date}, "skip":0, "limit":${limit}}`
        setLoading(true)
        dispatch(getConsolidateMonthlyList(filter))
        setResourceLabel(null);
    }

    let i = 0
    return (
        <div className="listing">
            <div className="listingContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Attendance Report Status</h3>
                        <div style={{display:'flex', alignItems:'flex-end'}}>
                              {/* advance filter search start */}
                                <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={(status === `${STATUSES.SUCCESS}_consolidateMonthlyList`) ? false : true } inlineStyle={{marginRight:'5px'}}/>
                              {/* advance filter search end */}
                            <Tooltip title={"Download as CSV"}>
                                <button disabled={(status === `${STATUSES.SUCCESS}_consolidateMonthlyList`) ? false : true} className="btn-add-new" style={{ marginRight: "5px", backgrond:'#0052cc' }} onClick={() => downloadCsvFile()}>
                                    <Download />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getConsolidateMonthlyList} filter={filterQuery} style={{ marginRight: "5px" }} />
                            <Tooltip title={"Back"}>
                                <button onClick={() => navigate(-1)} className="btn-add-new ">
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING) && <LoaderForm />}
                    {loading === true && <LoaderForm />}
                    <Card>
                        <CardContent>
                        <Grid
                            container
                            rowSpacing={1}
                            // columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                        >
                            <Grid item md={7}>
                               <CustomPagination />
                            </Grid>
                            <Grid item md={5}>
                            <Stack
                                style={{
                                justifyContent: "flex-end",
                                marginTop: "6px",
                                display:'flex'
                                }}
                                direction={"row"}
                            >
                                <div style={{ marginLeft: "3px" }}>
                                <Button
                                    startIcon={<Download />}
                                    variant='contained'
                                    onClick={() => downloadCsvFile()}
                                    style={status === `${STATUSES.LOADING}_consolidateMonthlyList` ? { backgroundColor: 'grey' } : { background:'#0052cc' }}
                                    disabled={(status === `${STATUSES.SUCCESS}_consolidateMonthlyList`) ? false : true}

                                >
                                    CSV(server)
                                </Button>
                                </div>
                            </Stack>
                            </Grid>
                        </Grid>
                        {
                            !reload && 
                            <DataGrid
                                className="datagrid"
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                                rows={(consolidateMonthlyList?.data && consolidateMonthlyList.data.rows && consolidateMonthlyList.data.rows.length > 0 && consolidateMonthlyList.data.rows) || []}
                                columns={consolidateHeader || []}
                                pageSize={pageSize}
                                getRowId={(row) => row + i++}
                                style={{ fontSize: 16 }}
                                components={{
                                    Pagination: CustomPagination,
                                    NoRowsOverlay: CustomNoRowsOverlay
                                }}
                                onFilterModelChange={onFilterChange}
                                hideFooterPagination={true}
                            />
                        }
                        </CardContent>
                    </Card>
                </div>

            </div>
        </div>
    )
}
export default AttendanceReportStatus