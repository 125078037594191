import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const getAllTemplateCategoryList = createAsyncThunk("template-category/all-list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await DataService.all_template_category(data);
    return res.data;
});

export const templateCategoryCreate = createAsyncThunk("template-category/create", async (data) => {
    const res = await DataService.template_category_create(data);
    return res.data;
});

export const templateCategoryEdit = createAsyncThunk("template-category/edit", async (id) => {
    const res = await DataService.template_category_edit(id);
    return res.data;
});

export const templateCategoryUpdate = createAsyncThunk("template-category/update", async (data) => {
    const res = await DataService.template_category_update(data.ID, data.addvalues);
    return res.data;
});

const templateCategorySlice = createSlice({
    name: " templateCategory",
    initialState: {
        templateCategory_AllList: [],
        templateCategory_List: [],
        templateCategory_Create: [],
        templateCategory_Edit: [],
        templateCategory_Update: [],
        templateCategory_Archive: [],

        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///all template-category list
            .addCase(getAllTemplateCategoryList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllTemplateCategoryList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_template_category_list`;
                state.templateCategory_AllList = action.payload;
            })
            .addCase(getAllTemplateCategoryList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create template-category
            .addCase(templateCategoryCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(templateCategoryCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_templateCategory_Create`;
                state.templateCategory_Create = action.payload;
            })
            .addCase(templateCategoryCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///edit template-category
            .addCase(templateCategoryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(templateCategoryEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.templateCategory_Edit = action.payload;
            })
            .addCase(templateCategoryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update template-category 
            .addCase(templateCategoryUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(templateCategoryUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_templateCategory_Update`;
                state.templateCategory_Update = action.payload;
            })
            .addCase(templateCategoryUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});

export default templateCategorySlice.reducer;