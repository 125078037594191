import { DialogContent, Dialog, DialogTitle, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react'
import Paper from '@mui/material/Paper';
import moment from 'moment';

export default function ViewAvailability({ openModal, setOpenModal, viewData, slotDetails }) {
    return (
        <Dialog
            fullWidth
            maxWidth={"xl"}
            open={openModal}
            onClose={setOpenModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Button
                edge="start"
                color="inherit"
                onClick={setOpenModal}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Close />
            </Button>
            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'View Availability Details'}</DialogTitle>
            <DialogContent>

                <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Interviewer Name :</b> {viewData.interviewer_details && viewData.interviewer_details.name}</TableCell>
                                <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>From Date :</b> {viewData.from_date ? moment(viewData.from_date).format('DD-MM-YYYY') : "N/A"}</TableCell>
                                <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>To Date :</b> {viewData.expiry_date ? moment(viewData.expiry_date).format('DD-MM-YYYY') : "N/A"}</TableCell>
                            </TableRow>
                        </TableHead>
                        {/* <TableBody>
                            <TableCell className="tableCell" align="right">{viewData.interviewer_details && viewData.interviewer_details.name}</TableCell>
                            <TableCell className="tableCell" align="right">{viewData.from_date ? moment(viewData.from_date).format('DD-MM-YYYY') : "N/A"}</TableCell>
                            <TableCell className="tableCell" align="right">{viewData.expiry_date ? moment(viewData.expiry_date).format('DD-MM-YYYY') : "N/A"}</TableCell>
                        </TableBody> */}
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Days</b></TableCell>
                                <TableCell className="tableCell" style={{ fontSize: "17px" }}><b>Available Slots</b></TableCell>
                                <TableCell>  </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slotDetails && slotDetails.length > 0 && slotDetails.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell className="tableCell" align="right" style={{ fontSize: "16px !important" }}>{row.day_name}</TableCell>
                                    <TableCell className="tableCell" style={{ fontSize: "16px !important" }}>
                                        {row && row.availability_fromArr && row.availability_fromArr.length > 0 && row.availability_fromArr.map((slot) => {
                                            return (
                                                <span>{slot.availability_from + "-" + slot.availability_to} , &nbsp; &nbsp;</span>
                                            )
                                        }
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog >
    )
}
