import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getRole = createAsyncThunk(
    "get/roles",
    async (data) => {
        const res = await dataService.roleList(data);
        const resp = res.data;
        return resp;
    }
);

export const createRole = createAsyncThunk("create/role", async (data) => {
    const res = await dataService.addRole(data);
    return res.data;
});

export const updateRole = createAsyncThunk("update/role", async (data) => {
    const res = await dataService.updateRole(data.ID, data.addvalues);
    return res.data;
});

const roleSlice = createSlice({
    name: "role",
    initialState: {
        data: [],
        role_create:[],
        role_update:[],
        status: 'idle',
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_getRole_list`;
                state.data = action.payload;
                state.error = action.error

            })
            .addCase(getRole.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

             //role create
            .addCase(createRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_role_create`;
                state.role_create = action.payload;
            })
            .addCase(createRole.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //role Update
            .addCase(updateRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_role_update`;
                state.role_update = action.payload;
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
    },
});


export default roleSlice.reducer;