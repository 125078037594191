import "./evaluation.scss"
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { Checkbox, Tooltip } from '@mui/material';
import LoaderForm from "../../components/common-function/LoaderForm";
import { getCESQuestionTypeList, getQuestionTypePEList } from "../../redux/features/masterSlice";
import Select from 'react-select';
import { evaluationQuestSetCreate, evaluationQuestSetUpdate, evaluationReviewAttchechmentUpload, getEvaluationQuestSetList } from "../../redux/features/evaluationSlice";
import { allResourcesList } from "../../redux/features/resourceSlice";
import { ArrowBack } from "@mui/icons-material";

const initialFormData = Object.freeze({
    status: true,
});

const formErrData = Object.freeze({
    quesErr: "Question is required",
    project_resourceErr: 'Resource is required',
});

const EvaluationQuestionSet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [uploadedQuestionDocs, setUploadedQuestionDocs] = useState([])
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { questionTypePEList } = useSelector(state => state.master)
    const { status, error, evaluationQuest_Update, evaluationQuest_Create, evaluationQuest_list, evaluationReviewAttchechment_upload } = useSelector(state => state.evaluation)
    const { activeResourceFilter } = useSelector(state => state.resource)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(getCESQuestionTypeList())
        dispatch(getQuestionTypePEList(`{"filter":{"question_type__question_type_key":{"value":"pe","operation": "eq"}, "status":{"value":"True", "operation":"eq"}}}`))
        dispatch(allResourcesList())
        // eslint-disable-next-line
    }, [])

    if (status === STATUSES.FAILURE && error) {
        setReload(false)
        errorHandling(error, navigate)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_evaluationQuest_Create` && evaluationQuest_Create && evaluationQuest_Create.message) {
        if (evaluationQuest_Create.status === 200) {
            toast.success(evaluationQuest_Create.message)
        } else {
            toast.error(evaluationQuest_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_evaluationQuest_Update` && evaluationQuest_Update && evaluationQuest_Update.message) {
        if (evaluationQuest_Update.status === 200) {
            toast.success(evaluationQuest_Update.message)
        } else {
            toast.error(evaluationQuest_Update.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        let resource_list = activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows ? activeResourceFilter.data.rows : []

        if (name > 0) {
            setFormValues({ ...formValues, [name]: checked });
        } else {
            let evalutor_id = name === 'resource_evaluator' ? viewUserRole(resource_list, value, 'id') : formValues.resource_evaluator && resource_list.filter(x => x.id === formValues.resource_evaluator)[0].tms_role__id
            let evalutee_id = name === 'resource_evaluatee' ? viewUserRole(resource_list, value, 'id') : formValues.resource_evaluatee && resource_list.filter(x => x.id === formValues.resource_evaluatee)[0].tms_role__id

            if (name === 'resource_evaluator' || name === 'resource_evaluatee') {
                setFormValues({ ...formValues, [name]: value });
                setID("")
                dispatch(getEvaluationQuestSetList('type'))
            }
            if (evalutor_id > 0 && evalutee_id > 0) {
                setReload("selectedQuesyion")
                //setTimeout(() => {
                dispatch(getEvaluationQuestSetList({
                    "filter": {
                        "evaluators_project_role": { value: evalutor_id, operation: 'eq' },
                        "evaluatees_project_role": { value: evalutee_id, operation: 'eq' }
                    }, "no_limit": "True"
                }))
                //}, 1000);
            } else {
                dispatch(getEvaluationQuestSetList('type'))
            }
        }
    }

    // const uncheckQuestionList = (resource_evaluator, resource_evaluatee) => {
    //     let questionList = questionTypePEList && questionTypePEList.data && questionTypePEList.data.rows ? questionTypePEList.data.rows : [];
    //     let object = {}
    //     object['resource_evaluator'] = resource_evaluator
    //     object['resource_evaluatee'] = resource_evaluatee
    //     object['status'] = true
    //     questionList.length > 0 && questionList.map(e => {
    //         if (formValues[e.id] === true) {
    //             object[e.id] = false;
    //         }
    //         // eslint-disable-next-line
    //     })
    //     setFormValues(object);
    // }

    if (reload === 'selectedQuesyion' && evaluationQuest_list && evaluationQuest_list.status) {
        let resource_evaluator = formValues.resource_evaluator;
        let resource_evaluatee = formValues.resource_evaluatee;
        let object = {}
        if (evaluationQuest_list.data && evaluationQuest_list.data.rows && evaluationQuest_list.data.rows.length > 0) {
            setID(evaluationQuest_list.data.rows[0].id)
            // eslint-disable-next-line
            evaluationQuest_list.data.rows.map(e => {
                if (e.questions && e.questions.length > 0) {
                    // eslint-disable-next-line
                    e.questions.map(itm => {
                        for (let index in itm) {
                            object[index.id] = true;
                        }
                    })
                }
            })
        }
        object['resource_evaluator'] = resource_evaluator
        object['resource_evaluatee'] = resource_evaluatee
        object['status'] = true
        setFormValues(object);
        dispatch(getEvaluationQuestSetList('type'))
        setReload(false)
    }




    const checkQuestionSelected = (datas, formvalue) => {
        let flag = true
        datas.map(e => {
            if (formvalue[e.id] === true) {
                flag = false
            }
            return flag
        })
        return flag
    }

    if (reload > 0 && evaluationReviewAttchechment_upload && evaluationReviewAttchechment_upload.status) {
        setReload(false)
        dispatch(evaluationReviewAttchechmentUpload('type'))

        let newUploadedArray = []
        const uploaded = uploadedQuestionDocs[reload] ? [...uploadedQuestionDocs[reload]] : newUploadedArray;

        if (evaluationReviewAttchechment_upload.data && evaluationReviewAttchechment_upload.data.api_path_for_download) {
            uploaded.push(evaluationReviewAttchechment_upload.data.api_path_for_download)
            setUploadedQuestionDocs({ ...uploadedQuestionDocs, [reload]: uploaded })
        }
        if (evaluationReviewAttchechment_upload.status === 400) {
            toast.error(evaluationReviewAttchechment_upload.message)
        }
    }

    const submitData = (e) => {
        e.preventDefault()
        let newQuestAdd = []
        if (questionTypePEList && questionTypePEList.data && questionTypePEList.data.rows &&
            questionTypePEList.data.rows.length > 0) {
            questionTypePEList.data.rows.map(e => {
                // if (formValues[e.id] && !formValues[`rating_value_${e.id}`]) {
                //     peFlag = true
                //     setErrStatus(true)
                // }
                // if (formValues[e.id] === true) {
                //     newQuestAdd.push({ question: e.title })
                // }
                if (formValues[e.id] === true) {
                    newQuestAdd.push({ question: e.title, id: e.id })
                }
                return newQuestAdd
            })
        }
        if (newQuestAdd.length === 0 && !formValues.resource_evaluator && !formValues.resource_evaluatee) {
            setErrStatus(true)
        }


        if (newQuestAdd.length > 0 && formValues.resource_evaluator && formValues.resource_evaluatee) {
            //let setId = 0
            // let quesObj = {}
            // let questinsArray = newQuestAdd.length > 0 ? addQues.concat(newQuestAdd) : addQues
            // for (let i = 0; i < questinsArray.length; i++) {
            //     quesObj[`Q${i + 1}`] = addQues[i]['question'];
            // }
            let resource_list = activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows ? activeResourceFilter.data.rows : []
            let evaluators_project_role = viewUserRole(resource_list, formValues.resource_evaluator, 'id')
            let evaluatees_project_role = viewUserRole(resource_list, formValues.resource_evaluatee, 'id')
            let questionTableData = {
                evaluators_project_role: evaluators_project_role,
                evaluatees_project_role: evaluatees_project_role,
                status: true,
                questions: newQuestAdd
            }
            // if (evaluationQuest_list.data && evaluationQuest_list.data.rows && evaluationQuest_list.data.rows.length > 0) {
            //     setId = evaluationQuest_list.data.rows[0].id
            // }
            //let addvalues = { ...formValues, created_by: userId }

            //handleClose()
            if (evaluators_project_role && evaluatees_project_role) {
                setErrStatus(false)
                setReload(true)
                if (ID > 0) {
                    dispatch(evaluationQuestSetUpdate({ ID: ID, addvalues: questionTableData }))
                } else {
                    dispatch(evaluationQuestSetCreate(questionTableData))
                }
            } else {
                toast.error(`Please assign role this ${!evaluators_project_role ? 'evaluator' : 'evaluatee'} before submit set`)
            }
        }

    }

    const viewUserRole = (rows, id, type) => {
        let role_name = ""
        let filter = rows.filter(x => x.id === id)

        if (filter.length > 0) {
            if (type === 'key') {
                role_name = filter[0].tms_role__role_key ? filter[0].tms_role__role_key : ""

            } else if (type === 'id') {
                role_name = filter[0].tms_role__id ? filter[0].tms_role__id : ""

            } else {
                role_name = filter[0].tms_role__role_name ? filter[0].tms_role__role_name : ""

            }
        }
        return role_name
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Question Set Creation</h3>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                        </Tooltip>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <form>
                        <div className="add-new-user-form">
                            <div className="formInput">
                                <label >
                                    Resources for evaluator
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={'Select Resource Evaluator'}
                                    defaultValue={formValues.resource_evaluator}
                                    isSearchable={true}
                                    name={'resource_evaluator'}
                                    onChange={handleChange}
                                    options={
                                        activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows.length > 0 ? activeResourceFilter.data.rows.map((option) => {
                                            return { label: option.display_name, value: option.id, name: 'resource_evaluator' }
                                        }) : []
                                    }
                                />

                                {errStatus === true && formValues && !formValues.resource_evaluator && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.project_resourceErr}</p>}
                            </div>
                            <div className="formInput">
                                <label >
                                    Resources for evaluatee
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={'Select Resource Evaluatee'}
                                    defaultValue={formValues.resource_evaluatee}
                                    isSearchable={true}
                                    name={'resource_evaluatee'}
                                    onChange={handleChange}
                                    options={
                                        activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows.length > 0 ? activeResourceFilter.data.rows.map((option) => {
                                            return { label: option.display_name, value: option.id, name: 'resource_evaluatee' }
                                        }) : []
                                    }
                                />

                                {errStatus === true && formValues && !formValues.resource_evaluatee && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.project_resourceErr}</p>}
                            </div>
                            { formValues?.resource_evaluator &&
                                <div className="formInput">
                                    <label >
                                        Resource evaluator role
                                    </label>
                                    <input
                                        name="evaluators_project_role"
                                        value={activeResourceFilter && activeResourceFilter.data && viewUserRole(activeResourceFilter.data.rows, formValues.resource_evaluator)}
                                        type="text"
                                        placeholder="Role Name"
                                        disabled={true}
                                    />
                                </div>
                            }
                            {formValues?.resource_evaluatee &&
                                <div className="formInput">
                                    <label >
                                        Resource evaluatee  Role
                                    </label>
                                    <input
                                        name="evaluatees_project_role"
                                        value={activeResourceFilter && activeResourceFilter.data && viewUserRole(activeResourceFilter.data.rows, formValues.resource_evaluatee)} type="text"
                                        placeholder="Role Name"
                                        disabled={true}
                                    />
                                </div>
                            }

                            {questionTypePEList && questionTypePEList.data && questionTypePEList.data.rows &&
                                <div style={{ flexBasis: '100%' }}>
                                    <br />
                                    {questionTypePEList.data.rows.length > 0 ?
                                        questionTypePEList.data.rows.map((e, i) => {
                                            return (
                                                <div key={i}>
                                                    <p><span style={{ fontWeight: 'bold', marginRight: '5px' }}><Checkbox name={e.id} onChange={handleChange} checked={formValues[e.id] ? true : false} inputProps={{ 'aria-label': 'controlled' }} /> Question {i + 1} :</span>{e.title}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <p style={{ color: "#96332c", fontSize: 13 }}>{'Plaese add questions for programming evaluation in CES Application'}</p>
                                    }

                                    {errStatus === true && checkQuestionSelected(questionTypePEList.data.rows, formValues) === true && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.quesErr}</p>}

                                </div>}

                        </div>
                        <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                    </form>
                </div>


            </div>
        </div >
    )
}

export default EvaluationQuestionSet