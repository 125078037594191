import "./phonecalling.scss";
import CallingWaveimg from '../../images/callingwave.gif'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { Tooltip } from "@mui/material";
import { callDisconnect } from '../../redux/features/callingSlice';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


export const PhoneCalling = ({ callDetail, callStatus, callId, isCallInitiated, setCallDisconnect,phoneNumberByList }) => {
    // call disconnect code start
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { call_disconnect } = useSelector(state => state.callingSlice)

    if (loading === "call_disconnected" && call_disconnect && call_disconnect.status) {
        setLoading(false)
        if (call_disconnect.status === 200) {
            toast.success(call_disconnect.message)
            setCallDisconnect(true)
        } else {
            toast.error(call_disconnect.message)
        }
        // setIsCallInitiated(false)
    }
    const disconnectCall = async (id) => {
        dispatch(callDisconnect(id));
        setLoading("call_disconnected");
    }
    // end

    return (
        <div className="phone-calling-row">
            <div className="phone-calling-colum-1">
                <div className="user-name-and-number-row">
                    <div className="user-short-name">
                        <span><SupportAgentIcon /></span>
                    </div>
                    <div className="user-name-number">
                        <h6>Agent</h6>
                        <p>{callDetail?.agent_name}</p>
                        <p>{callDetail?.agent_phone_no}</p>
                    </div>
                </div>
            </div>
            <div className="phone-calling-colum-2">
                {
                    callStatus === 0 || callStatus === 3 ?
                        <p className="ringing"><PhoneInTalkIcon /> Ringing...</p>
                        : ""
                }
                {
                    callStatus === 6 ? <div>
                        <p className="oncall">On Call</p>
                        <img src={CallingWaveimg} alt={"Calling Wave"} />
                    </div> : ""
                }
            </div>
            <div className="phone-calling-colum-3">
                <div className="user-name-and-number-row">
                    <div className="user-short-name">
                        <span><PersonOutlineIcon /></span>
                    </div>
                    <div className="user-name-number">
                        <h6>Candidate</h6>
                        <Tooltip title={callDetail?.candidate_name}>
                            <p className="ellips-css-data story-epic-summary">{
                                callDetail && callDetail.candidate_name.substring(0, 15) + "..."
                            }</p>
                        </Tooltip>
                        <p>{ callDetail?.candidate_phone_no ? callDetail.candidate_phone_no : phoneNumberByList}</p>
                    </div>
                </div>
            </div>
            {
                isCallInitiated && callId ?
                    <Tooltip title={callStatus === 6 ? 'End Call' : 'Cancel Call'}>
                        <button
                            onClick={() => disconnectCall(callId)}
                            className="call-disconnect-btn"
                        >
                            <span>
                                {callStatus === 6 ? 'End Call' : 'Cancel Call'}
                            </span>
                        </button>
                    </Tooltip>
                    : ""
            }
        </div>
    )
}