import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip,Switch,Skeleton, Typography, IconButton, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getresumeformat, resumeformatEdit, resumeformatCreat, resumeformatUpdate } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { DataGridPro,gridClasses } from "@mui/x-data-grid-pro";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useRef } from "react";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const initialFormData = Object.freeze({
    format_type: "",
    description: "",
    status: true,
});


const formErrData = Object.freeze({
    format_typeErr: "Resume Format name is required",
    descriptionErr: " description is required",
    statusErr: "Status is required"
});

const skeletonBase = {format_type:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', description:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status:'$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['format_type', 'description', 'status'];

const PaperComponent = (props) => {
    return (
        <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}


const ResumeFormat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, resumeformat_type_List, resumeformat_type_creat, resumeformat_type_Edit, resumeformat_type_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.RESUME_FORMAT_TYPE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

     // grid states and ref starts
     const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
     const [gridRows, setGridRows] = useState([]);
     const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})));
     const [filterModal, setFilterModel] = useState({items: [], linkOperator:'and', quickFilterLogicOperator:"and", quickFilterValues:[]});
     const [colSortingPerform, setColSortingPerform] = useState([]);
     const [selectedRow, setSelectedRow] = useState();
     const [contextMenu, setContextMenu] = useState(null);
     let stopOnRowScrollEnd = useRef(false);
     let clearGridListing = useRef(false);
     let gridTotalRows = useRef(null);
     let editID = useRef(null);
     let isAnyChange = useRef({});
     // grid states and ref ends



    if (reload === "resume_update" && status === `${STATUSES.SUCCESS}_resumeformat_type_Update` && resumeformat_type_Update && resumeformat_type_Update.message) {
        if (resumeformat_type_Update.status === 200) {
            toast.success(resumeformat_type_Update.message)
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:JSON.parse(filterQuery).skip ===0 ? 25 : JSON.parse(filterQuery).skip }));
            setFilterQuery(str)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(resumeformat_type_Update.message)
        }
        setReload(false)
    }

    if (reload === "resume_create" && status === `${STATUSES.SUCCESS}_resumeformat_type_creat` && resumeformat_type_creat && resumeformat_type_creat.message) {
        if (resumeformat_type_creat.status === 200) {
            toast.success(resumeformat_type_creat.message)
            setGridRows([]);
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:JSON.parse(filterQuery).skip ===0 ? 25 : JSON.parse(filterQuery).skip}));
            setFilterQuery(str)
        } else {
            toast.error(resumeformat_type_creat.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {
        const { name, value,checked } = evt.target || evt;
        setFormValues({
            ...formValues,
            [name]: name === 'status' ? checked : value,
          });
      

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.format_type === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.description !== "" && formValues.format_type !== "" && formValues.status !== "") {
            let addvalues = { ...formValues, }
            setErrStatus(false)            
            handleClose()
            if (ID) {
                setReload("resume_update")
                dispatch(resumeformatUpdate({ ID, addvalues }))

            } else {
                setReload("resume_create")
                dispatch(resumeformatCreat(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_resumeformat_type_Edit` && resumeformat_type_Edit && resumeformat_type_Edit.data) {
        let editData = resumeformat_type_Edit.data
        setFormValues({
            format_type: editData.format_type,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(resumeformatEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        let addvalues = {
            format_type: data.format_type,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload("resume_update")
        dispatch(resumeformatUpdate({ ID, addvalues }))
    }

 

    const actionColumn = [
        {
            field: "format_type",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Format Type</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("format_type")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 280,
            sortable:false,
            pinnable:false,
            renderCell: (params) => {
                return (
                    params.row.format_type === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360}/> :
                    <div>
                        {params.row.format_type}
                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Description</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("description")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{color:"#BCC2CE"}} />
                    </IconButton>
                </Box>
            </Stack>,
            sortable:false,
            pinnable:false,
            width: 280,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={360}/> :
                    <div>
                        {params.row.description ? params.row.description : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
            </Stack>,
            width: 160,
            sortable:false,
            pinnable:false,
            type:'singleSelect',
            filterable:false,
            valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
            renderCell: (params) => {
                return (
                        params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/>                     
                    :
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Switch name="status" checked={params.row.status} onChange={()=>handleArchive(params.row)} />
                    </div>
                    
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="format_type"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const fetchFurtherRows = (viewPortRowSize) =>
    {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) =>
    {
        if(stopOnRowScrollEnd.current){
            stopOnRowScrollEnd.current=false;
            return;
        }
        if(gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0){
            fetchFurtherRows(params.viewportPageSize);
        }
    };
  
    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length)
          return;
    
        if (props.items.length === 0) {
          setFilterModel(props)
          setGridRows([]);
          let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.RESUME_FORMAT_TYPE_LIST_COLUMNS}]}`);
          setFilterQuery(str);
          return;
        }
    
        if (filterModal?.items?.length < props?.items?.length) {
          if (props.items.length > 1) {
            let selectedCols = filterModal.items.map(item => item.columnField);
            let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
            let newProps = { ...props };
            newProps.items[newProps.items.length - 1].columnField = remainCols[0];
            let index = props.items.findIndex(item => item.columnField === 'status')
            if (newProps.items.some(item => item.columnField === 'status')) {
                if(!newProps.items[index].value){
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                }
            }
            setFilterModel(props)
          }
          else
            setFilterModel(props)
          return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {
    
          if (!(filterModal?.items?.length > props?.items?.length)) {
    
            // if same col is selected then do not let it select - starts
            let selectedCols = props.items.map(item => item.columnField);
            let prevLen = selectedCols.length;
    
            if ((new Set(selectedCols)).size != prevLen) {
              toast.warn("Please select distinct column fields!")
              return;
            }
            // if same col is selected then do not let it select - ends
    
            setFilterModel(props)
    
            let filterModalCols = [];
            let propsCols = [];
            filterModal.items.map(item => filterModalCols.push(item.columnField));
            props.items.map(item => propsCols.push(item.columnField));
    
            for (let i = 0; i < filterModalCols.length; i++) {
              if (filterModalCols[i] != propsCols[i])
                return;
            }
    
          }
    
          setFilterModel(props)
    
          let filterItems = props.items;
          let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: 25, order_by: { column: "-id" }, columns: DefaultFilterColumns.RESUME_FORMAT_TYPE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
    
          let operators = {
            contains: 'icontains',
            equals: 'ieq',
            is: 'eq',
            startsWith: 'istartsWith',
            endsWith: 'iendsWith'
          }
    
          if (filterItems?.length) {
            let valContainers = filterItems.filter(item => item?.value)
            if (valContainers?.length) {
              let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
              // if no changes on filter open starts
              props.items.map((elem, index) => {
    
                if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                  isAnyChange.current[index] = true;
                else
                  isAnyChange.current[index] = false;
              })
              // no change (! reverse by not operator)
              if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                setGridRows([]);
              }
    
              // if no changes on filter open ends
              setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
            }
          }
          return;
        }
      }
      const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if (foundElement) {
          newFilterData['order_by'] = {
            "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
          }
          copySorter.map(item => {
            if (item.id === foundElement.id)
              item.order = item.order === "asc" ? "desc" : "asc"
          });
          setColSortingPerform(() => copySorter)
        }
        else {
    
          newFilterData['order_by'] = {
            "column": `${sortingCol}`
          }
          setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }  
        setFilterQuery(JSON.stringify(newFilterData))
        clearGridListing.current = true;
      }


      if (reload === true && status === STATUSES.SUCCESS && resumeformat_type_List && resumeformat_type_List.message) {
        if (resumeformat_type_List.status === 200) {
            if(clearGridListing.current){
                setGridRows([...resumeformat_type_List.data.rows].sort(item=>item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(resumeformat_type_List.data.count);
            }
            else{
                setGridRows((pre)=>[...pre, ...resumeformat_type_List.data.rows].sort(item=>item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(resumeformat_type_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(resumeformat_type_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }
      
      useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(()=>Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind)=>({..._, id:'skeleton'+ind})))
        dispatch(getresumeformat(filterQuery))
    }, [filterQuery])
    
    


    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Resume Format</h3>
                        <div>
                            <Tooltip title="Add Resume Format Type">
                            <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>            

                            </Tooltip>
                            {/* <RefreshButton api={getresumeformat} filter={filterQuery} style={{ marginTop: 0 }} /> */}

                        </div>
                    </div>                
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                            }
                            sx={{
                                height: 700,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#438bf969",
                                },
                                ".actionButton": {
                                    display: 'none'
                                  },
                                  [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                      ".actionButton": {
                                        display: 'block'
                                      }
                                    }
                                  }
                            }}
                            rows={gridRows?.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            onFilterModelChange={onFilterChange}
                            filterModel={filterModal}
                            disableSelectionOnClick
                            components={{
                                ColumnMenuIcon: ()=>(<MoreHorizIcon sx={{color:"#171c26ba"}}/>),
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooter
                        />                    
                    </div>
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Resume Format</DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Format Type"}
                                    name="format_type"
                                    defaultValue={formValues.format_type}
                                    type="text"
                                    placeholder="Resume Format Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.format_typeErr}
                                />
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Resume Format Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <label style={{marginRight:'20px'}}>
                                        Status
                                    </label>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange}/>
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Resume Format`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}
export default ResumeFormat;