import { Box, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { useImperativeHandle, useRef } from "react";

function StatusInputValue(props) {
    const { item, applyValue, focusElementRef } = props;
    const ratingRef = useRef(null);
    useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Value
                </InputLabel>
                <NativeSelect
                    defaultValue={30}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleFilterChange}
                >
                    <option value={'True'}>Active</option>
                    <option value={'False'}>InActive</option>
                </NativeSelect >
            </FormControl >
        </Box>
    );
}

export default  StatusInputValue;