import CompOffListing from "./CompOffListing"

const TeamCompOff = () => {

  const { REACT_APP_ENV } = process.env;
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))

  return (
    <div>
      <CompOffListing
        resourceManagerId={rtm_user.user.data.id}
      />
    </div>
  )
}

export default TeamCompOff