const CONSTANTS = {
    COMMON_BUTTONS : {
        ADD:'Add',
        EDIT:'Edit',
        VIEW:'View',
        UPDATE:'Update',
        DELETE:'Delete',
        CREATE:'Create',
        SUBMIT:'Submit',
        CLOSE:'Close',
    },
    COUNTRY: {
        LIST: 'Country List',
        ADD: 'Add New',
        EDIT: 'Edit',
        VIEW: 'View',
        DELETE: 'Delete'
    },
    RESOURCE: {
        LIST: 'Resource List',
        ADD: 'Add New',
        EDIT: 'Edit',
        VIEW: 'View',
        DELETE: 'Delete'
    },
    TASK:{
        LIST: ' Task List',
        MYLIST:'My Task List'
    }

}


export default CONSTANTS;