

import { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const  DataGridProLazyLoadHOC = (WrapperComponent, props) => {

    let filterableColumns = [...props.gridColumns];
    const skeletonBase = {};
    filterableColumns.map(item => skeletonBase[item] = '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO');

    const NewComponent = () =>
    {
        const [filterQuery, setFilterQuery] = useState(props?.defaultFilter ? props?.filterColumns ? JSON.stringify({...props.defaultFilter, columns:[...props?.filterColumns]}) : JSON.stringify({...props.defaultFilter}) : props?.filterColumns ? JSON.stringify({order_by:{column:"-id"},skip:0, limit:25,columns:[...props.filterColumns]}) : JSON.stringify({order_by:{column:"-id"},skip:0, limit:25}));
        // grid states and ref starts
        const [reload, setReload] = useState(false);
        const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
        const [gridRows, setGridRows] = useState([]);
        const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
        const [colSortingPerform, setColSortingPerform] = useState([]);
        const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
        const [selectedRow, setSelectedRow] = useState();
        const [contextMenu, setContextMenu] = useState(null);
        let clearGridListing = useRef(false);
        let stopOnRowScrollEnd = useRef(false);
        let isAnyChange = useRef({});
        let gridTotalRows = useRef(null);
        let editID = useRef(null);
        const [sort,setSort]=useState(false)
        // grid states and ref ends

        const dispatch = useDispatch();

        const onFilterChange = (filterProps) => {
            if (props?.maxfilterableColumns)
                if (filterProps.items.length > props?.maxfilterableColumns)
                    return;
                else
                    if (filterProps.items.length > props?.gridColumns.length)
                        return;

            if (filterProps.items.length === 0) {
                setFilterModel(filterProps)
                setGridRows([]);
                let str = new String(props?.filterColumns ? JSON.stringify({ order_by: { column: "-status" }, skip: 0, limit: 25, columns: props.filterColumns }) : JSON.stringify({ order_by: { column: "-status" }, skip: 0, limit: 25 }));
                setFilterQuery(str);
                return;
            }

            if (filterModal?.items?.length < filterProps?.items?.length) {
                // if (filterProps.items.length > 1) {
                //     let selectedCols = filterModal.items.map(item => item.columnField);
                //     let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                //     let newProps = { ...filterProps };
                //     newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                //     let index = filterProps.items.findIndex(item => item.columnField === 'status')
                //     if (newProps.items.some(item => item.columnField === 'status')) {
                //         newProps.items[index].value = undefined;
                //         // newProps.items[index].operatorValue = 'icontains';
                //     }
                //     setFilterModel(filterProps)
                // }
                // else
                setFilterModel(filterProps)
                // return;
            } else if (filterModal?.items?.length > filterProps?.items?.length || filterModal?.items?.length === filterProps?.items?.length) {

                if (!(filterModal?.items?.length > filterProps?.items?.length)) {

                    // if same col is selected then do not let it select - starts
                    let selectedCols = filterProps.items.map(item => item.columnField);
                    let prevLen = selectedCols.length;

                    if ((new Set(selectedCols)).size != prevLen) {
                        toast.warn("Please select distinct column fields!")
                        return;
                    }
                    // if same col is selected then do not let it select - ends

                    setFilterModel(filterProps)

                    let filterModalCols = [];
                    let propsCols = [];
                    filterModal.items.map(item => filterModalCols.push(item.columnField));
                    filterProps.items.map(item => propsCols.push(item.columnField));

                    for (let i = 0; i < filterModalCols.length; i++) {
                        if (filterModalCols[i] != propsCols[i])
                            return;
                    }

                }

                setFilterModel(filterProps)

                let filterItems = filterProps.items;
                let baseFilter = { [filterProps.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: 25, order_by: { column: "-status" } };
                baseFilter = props?.filterColumns ? { ...baseFilter, columns: props.filterColumns.map(item => item.replaceAll('"', "").trim()) } : { ...baseFilter };
                let operators = {
                    contains: 'icontains',
                    equals: 'ieq',
                    is: 'eq',
                    startsWith: 'istartsWith',
                    endsWith: 'iendsWith'
                }

                if (filterItems?.length) {
                    let valContainers = filterItems.filter(item => item?.value)
                    if (valContainers?.length) {
                        let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                        // if no changes on filter open starts
                        filterProps.items.map((elem, index) => {

                            if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                                isAnyChange.current[index] = true;
                            else
                                isAnyChange.current[index] = false;
                        })
                        // no change (! reverse by not operator)
                        if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                            setGridRows([]);
                        }

                        // if no changes on filter open ends
                        setFilterQuery(JSON.stringify({ ...baseFilter, [filterProps.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                    }
                }
                return;
            }
        }

        const handleSort = (sortingCol) => {
            setSort(true)
            stopOnRowScrollEnd.current = true;
            let copySorter = [...colSortingPerform];
            let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
            if (foundElement) {
                newFilterData['order_by'] = {
                    "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
                }
                copySorter.map(item => {
                    if (item.id === foundElement.id)
                        item.order = item.order === "asc" ? "desc" : "asc"
                });
                setColSortingPerform(() => copySorter)
            }
            else {

                newFilterData['order_by'] = {
                    "column": `${sortingCol}`
                }
                setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
            }

            setFilterQuery(JSON.stringify(newFilterData))
            clearGridListing.current = true;
            setGridRows([]);
        }

        const handleContextMenu = (event) => {
            event.preventDefault();
            setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
            setContextMenu(
                contextMenu === null
                    ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                    : null
            );
        };

        const fetchFurtherRows = (viewPortRowSize) => {
            let updatedFilter = JSON.parse(filterQuery);
            updatedFilter.skip = gridRows.length;
            updatedFilter.limit = 25;
            setFilterQuery(JSON.stringify(updatedFilter));
        }

        const handleOnRowScrollEnd = (params) => {
            if (stopOnRowScrollEnd.current) {
                stopOnRowScrollEnd.current = false;
                return;
            }

            if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
                fetchFurtherRows(params.viewportPageSize);
            }
        };

        useEffect(() => {
            stopOnRowScrollEnd.current = true;
            setReload(true);
            setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
            props?.getDispatcherAsyncThunk && dispatch(props?.getDispatcherAsyncThunk(filterQuery))
        }, [filterQuery])

        return (
            <div className="jiraStatus">
                <div className="jiraStatusContainer">
                    <ToastContainer />
                        <WrapperComponent  sort={sort} onFilterChange={onFilterChange} handleSort={handleSort} reload={reload} setReload={setReload} gridRows={gridRows} setGridRows={setGridRows} clearGridListing={clearGridListing} stopOnRowScrollEnd={stopOnRowScrollEnd} SET_MAX_ROW_LENGTH={SET_MAX_ROW_LENGTH} setSkeletonRows={setSkeletonRows} skeletonRows={skeletonRows} filterModal={filterModal} handleContextMenu={handleContextMenu} handleOnRowScrollEnd={handleOnRowScrollEnd} setFilterQuery={setFilterQuery} filterQuery={filterQuery} skeletonBase={skeletonBase} editID={editID} gridTotalRows={gridTotalRows} selectedRow={selectedRow} setSelectedRow={setSelectedRow} contextMenu={contextMenu} setContextMenu={setContextMenu} setSort={setSort}
                    />
                </div>
            </div >
        )
    }

    return NewComponent;

}

export default DataGridProLazyLoadHOC;

