import { TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"
import { useState } from "react";
import { useDispatch } from "react-redux";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay"
import TablePaginationActions from "../../components/TablePaginationActions";
import LoaderForm from "./LoaderForm";

export const DefaultDataGrid = ({ list, count, columns, defaultApi, defaultFilter, loading, rowHeight }) => {
    const dispatch = useDispatch()
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [skip, setSkip] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterQuery, setFilterQuery] = useState(defaultFilter || { "order_by": { "column": "-id" }, "skip": skip, "limit": limit })
    const [defaultFilterQuery] = useState(defaultFilter)
    const [reload, setReload] = useState(false);
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = filterQuery;
        if (typeof filterQuery === 'string') {
            newFilterData = JSON.parse(filterQuery)
        }
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setSkip(data * pageSize)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(defaultApi(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setSkip(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery
        if (typeof filterQuery === 'string') {
            newFilterData = JSON.parse(filterQuery)
        }
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(defaultApi(JSON.stringify(newFilterData)))
    }
    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={count}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = filterQuery
            if (typeof filterQuery === 'string') {
                newFilterData = JSON.parse(filterQuery)
            }
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(defaultApi(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(defaultFilterQuery)
        }
    }

    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                let newFilterData = defaultFilterQuery;
                setCurrentPage(0)
                setSkip(0)
                //setPageSize(10)
                newFilterData['limit'] = limit
                setFilterQuery(newFilterData)
                dispatch(defaultApi(JSON.stringify(newFilterData)))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            let filterData = defaultFilter;
            filterData['filter'][filterModel.items[0].columnField] = { "value": filterModel.items[0].value, "operation": operatorValue }
            //const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(defaultApi(JSON.stringify(filterData)))
        }
    }

    return (
        <>
        {
            reload ? <LoaderForm/> : 
            <DataGrid
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
            }
            rows={list}
            loading={loading}
            columns={columns}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            disableSelectionOnClick
            components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay

            }}
            getRowHeight={() => rowHeight || 'auto'}
            onSortModelChange={(sort) => handleSort(sort)}
            />
        }
        </>
    )
}