
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Stack, TablePagination, Tooltip, Switch,Skeleton,Menu, MenuItem, FormControl, InputLabel, Box, NativeSelect } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceTypeCreate, ServiceTypeEdit, ServiceTypeUpdate, getServiceTypeList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { DataGridPro, gridClasses,getGridStringOperators,	getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { useNavigate } from "react-router-dom";




const initialFormData = Object.freeze({
    name: "",
    description: "",
    service_type_key: "",
    status: true,
});

const formErrData = Object.freeze({
    nameErr: "Name is required",
    descriptionErr: "Description is required",
    keyErr: "Service type key is required",
    statusErr: "Status is required"
});
const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', service_type_key: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',description: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO',status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO'};

const filterableColumns = ['name', 'service_type_key','description','status'];


function PaperComponent(props) {
    return (
        <Draggable
            bounds="body"
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
        <Paper {...props} />
        </Draggable>
    );
}


function StatusInputValue(props) {
    const { item, applyValue, focusElementRef } = props;
    const ratingRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current?.querySelector(`input[value="${Number(item.value) || ""}"]`)
                .focus();
        },
    }));

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Value
                </InputLabel>
                <NativeSelect


                    defaultValue={30}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleFilterChange}
                >
                    <option value={'True'}>Active</option>
                    <option value={'False'}>Inactive</option>
                </NativeSelect>
            </FormControl>
        </Box >
    );
}


const ServiceType = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, servicetype_List, servicetype_Create, servicetype_Edit, servicetype_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SERVICE_TYPE_LIST_COLUMNS}]}`)
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [gridRows, setGridRows] = useState([]);
    let stopOnRowScrollEnd = useRef(false)
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null)
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const navigate = useNavigate();
    let editID = useRef(null)
    let gridTotalRows = useRef(null)
    let isAnyChange = useRef({});
    let clearGridListing = useRef(false);
    const [sort,setSort]=useState(false)


    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );
    // error handling
    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getServiceTypeList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [filterQuery])

    if (reload === true && status === `${STATUSES.SUCCESS}_servicetype_Update` && servicetype_Update && servicetype_Update.message) {
        if (servicetype_Update.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
            // setFilterQuery(str)
            let copyGridRows = [...gridRows].map(item => {
                if (editID.current.id === item.id)
                    return { ...servicetype_Update.data }
                else
                    return item
            })
            setGridRows(copyGridRows);
            toast.success(servicetype_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(servicetype_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_servicetype_Create` && servicetype_Create && servicetype_Create.message) {
        if (servicetype_Create.status === 200) {
            let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.current}));
            setFilterQuery(str)
            setGridRows([]);
            toast.success(servicetype_Create.message)
            // dispatch(getServiceTypeList(filterQuery))
        } else {
            toast.error(servicetype_Create.message)
        }
        setReload(false)
    }
    if (reload === true && status === STATUSES.SUCCESS && servicetype_List && servicetype_List.message) {
        if (servicetype_List.status === 200) {
            if (clearGridListing.current) {
               sort? setGridRows([...servicetype_List.data.rows])  :setGridRows([...servicetype_List.data.rows]?.sort(item=>item.status? -1:1));
                clearGridListing.current = false;
                setSkeletonRows([])
                SET_MAX_ROW_LENGTH(servicetype_List.data.count);
            }
            else {
               sort ? setGridRows((pre) => [...pre, ...servicetype_List.data.rows]): setGridRows((pre) => [...pre, ...servicetype_List.data.rows].sort(item=>item.status? -1:1));
                SET_MAX_ROW_LENGTH(servicetype_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(servicetype_List.message)
        }
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null)

    }

    const handleChange = (evt) => {
        const { name, value, checked } = evt.target || evt;
        // setFormValues({ ...formValues, [name]: value })
        if (name === 'status') {
            setFormValues({ ...formValues, [name]: checked })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.name === "" || formValues.status === "") {
            setErrStatus(true)
        }
        clearGridListing.current = true;
        if (formValues.name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                let copyRows = [...gridRows];
                editID.current = copyRows.find(item => item.id === ID);
                let index = copyRows.findIndex(item => item.id === ID);
                copyRows.splice(index, 1, { ...skeletonBase, id: ID });
                setGridRows(copyRows);
                dispatch(ServiceTypeUpdate({ ID, addvalues }))
            } else {
                gridTotalRows.current = gridRows.length;
                dispatch(ServiceTypeCreate(addvalues))
            }

            setSort(true)
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && servicetype_Edit && servicetype_Edit.data) {
        let editData = servicetype_Edit.data
        setFormValues({
            name: editData.name,
            description: editData.description,
            service_type_key: editData.service_type_key,
            status: editData.status,
            id: editData?.id
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(ServiceTypeEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            description: data.description,
            service_type_key: data.service_type_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        editID.current = { ...data };
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(ServiceTypeUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getServiceTypeList(JSON.stringify(newFilterData)))
    }
    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = 25;
        setFilterQuery(JSON.stringify(updatedFilter));
    }
    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getServiceTypeList(JSON.stringify(newFilterData)))
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(servicetype_List && servicetype_List.data && servicetype_List.data.rows && servicetype_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const handleSort = (sortingCol) => {

        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = gridRows.length;
        if (foundElement) {
            newFilterData['order_by'] = {
                "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
            }
            copySorter.map(item => {
                if (item.id === foundElement.id)
                    item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = {
                "column": `${sortingCol}`
            }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }


        setFilterQuery(JSON.stringify(newFilterData))
        setSort(true)
        setGridRows([]);

    }
    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            //     case 'status':
            //         handleArchive(row);
            //         break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }


    const statusOnlyOperators = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params) => {
                    return params.value === 'is';
                };
            },
            InputComponent: StatusInputValue,
        },
    ];

    const actionColumn = [
      
        {
            field: "name",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color:'#1B3764'}}><p>Service Type Name</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("name")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem' ,color:'#BCC2CE' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.name}
                        </div>
                );
            },
        },
        {
            field: "service_type_key",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color:'#1B3764' }}><p>Service Type Key</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("service_type_key")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem',color:'#BCC2CE' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.service_type_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.service_type_key}
                        </div>
                );
            },
        },
        {
            field: "description",
            filterOperators,
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color:'#1B3764'}}><p>Description</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("description")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem',color:'#BCC2CE' }} /></button></div>,
            minWidth: 280,
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :

                        <div>
                            {params.row.description}
                        </div>
                );
            },
        },
        {
            field: "status",
            headerName: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color:'#1B3764' }}><p>Status</p><button style={{ background: 'white', marginTop: '10px' }} onClick={() => handleSort("status")}><UnfoldMoreIcon sx={{ cursor: 'pointer', fontSize: '1rem',color:'#BCC2CE' }} /></button></div>,
            minWidth: 160,
            sortable: false,
            filterOperators: statusOnlyOperators,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Switch name="status" checked={params.row.status} onChange={() => handleArchive(params.row)} />
                        <div className="actionButton">
                            <UcActionComponent
                                deleteIconName="ArchiveIcon"
                                moduleName="specialization"
                                rowid={params.row.id}
                                addMoreData=""
                                editLinkUrl=""
                                viewLinkUrl=""
                                editButton={handleClickEdit}
                                viewButton={false}
                                deleteButton={() => handleArchive(params.row)}
                            />
                        </div>
                    </div >
                );
            },
        },
    ];

    const onFilterChange = (props) => {
        setSort(true)
        if (props.items.length > actionColumn.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SERVICE_TYPE_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    newProps.items[index].value = undefined;
                    newProps.items[index].operatorValue = 'is';
                    newProps.items[index].operatorsValue='isnot'
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends

                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }

            }

            setFilterModel(props)

           

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.SERVICE_TYPE_LIST_COLUMNS.map(item => item.replaceAll('"', "").trim()) };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartswith',
                endsWith: 'iendswith',
                not:"ieq"
            }

           

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
               
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {

                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else
                            isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }

                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px",paddingTop: "20px" }}>
                        <div style={{ fontSize: "20px"}}>
                            <p>Type of Service</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoToneIcon /></Button>
                                <RefreshButton api={getServiceTypeList} filter={filterQuery} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing} setSort={setSort}/> 
                            </Stack>
                        </div>
                    </div>
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            filterMode="server"
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            // rows={currency_List?.data?.rows ? currency_List.data.rows : []}
                            rows={gridRows?.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            sx={{
                                height: 700,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#438bf969",
                                },
                                ".actionButton": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".actionButton": {
                                            display: 'block'
                                        }
                                    }
                                }
                            }}
                            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnMenuIcon: MoreHorizIcon
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            hideFooterRowCount={true}  
                        />
                    

                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="child-modal-title"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    {/* <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Type of Service </DialogTitle> */}
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Type of Service </DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Service Type Name"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />

                                {formValues.id ? <div className="formInput">
                                    <label >
                                        Service Type Key
                                    </label>
                                    <input
                                        readOnly
                                        label={"Service Type Key"}
                                        name="service_type_key"
                                        defaultValue={formValues.service_type_key}
                                        type="text"
                                        placeholder="Service-Type-Key"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""}

                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '20px' }}>
                                        Status
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>Inactive</p>
                                        <Switch name="status" checked={formValues.status} onChange={handleChange} />
                                        <p>Active</p>
                                    </div>
                                </div>
                            </div>
                            <Button  variant="contained"  onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Service Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                    <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                </Menu>
            </div>
        </div >
    )
}

export default ServiceType;