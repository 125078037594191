import "./task.scss"


export const TaskView = () => {
  return (
    <div className="task">
      <div className="taskContainer">
      </div>

    </div>
  )
}

