import "./roles.scss"
import dataService from "../../redux/services/data.service";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DataGridProLazyLoadHOC from "../../components/common-function/DataGridProLazyLoadHOC";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import AddRole from "./AddRole";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRole, updateRole } from '../../redux/features/roleSlice';
import { toast, ToastContainer } from "react-toastify";
import { Button, Dialog, DialogContent, DialogTitle, Menu, MenuItem, Skeleton, Stack, Switch, Tooltip } from "@mui/material";
import { STATUSES } from "../../redux/common-status/constant";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { Edit, Close } from "@mui/icons-material";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

const PaperComponent = (props) => {
  return (
      <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      >
      <Paper {...props} />
      </Draggable>
  );
}

let flag = 1;

function SubAllRoles(props) {

  let {onFilterChange, handleSort, reload, setReload, gridRows, setGridRows, skeletonRows, setSkeletonRows, filterModal, filterQuery, setFilterQuery, handleContextMenu,  clearGridListing, SET_MAX_ROW_LENGTH, stopOnRowScrollEnd, handleOnRowScrollEnd, editID, skeletonBase, gridTotalRows, selectedRow, setContextMenu, contextMenu} = props;

  const dispatch = useDispatch();

  const { data:roleListData, role_update, role_create, status, error } = useSelector(state => state.role)

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)

  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');
  const [activePopupForm, setActivePopupForm] = useState({active:false, id:null});

  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)

    let copyGridRows = [...gridRows];
    let index = copyGridRows.findIndex(item=>item.id === id);
    let element = copyGridRows.find(item=>item.id === id);
    copyGridRows.splice(index, 1, {...skeletonBase, id:element.id});
    setGridRows(copyGridRows);

    if (open === true) {

      const response = await dataService.deleteRole(id, request)
      if (response && response.data) {
        toast.success(response.data.message);
        setOpen(false)
        setTimeout(() => {
          clearGridListing.current = true;
          setFilterQuery(new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length})));
        }, 800);
      }
    }
  }

  const handleClickOpen = (type, id) => {
    setDeleteId(id)
    if (type === true) {
      handleDelete(id);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setArchiveOpen(false);
    setContextMenu(null);
    setActivePopupForm({active:false, id:null});
    setArchiveId(null);
  }

  const handleEdit = (id) => 
  {
    flag = 1;
    setActivePopupForm({active:'edit', id})
  }

  const handleStatusChange = (data) =>
  {
        setArchiveId(data)
        setArchiveOpen(true)
        if (data.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
  }

  const handleClickStatusChange = (data) => {
    data = archiveId;
    setArchiveOpen(false)
    flag = 1;
    let addvalues = {
        role_name: data.role_name,
        role_key: data.role_key,
        role_description: data.role_description,
        status: !data.status,
        is_for_client: data.is_for_client,
        updated_by: GetLocalstorage.userDetail().id
    }

    clearGridListing.current = true;
    editID.current = {...data};
    let copyRows = [...gridRows];
    let index = copyRows.findIndex(item=>item.id === data.id); 
    copyRows.splice(index, 1, {...skeletonBase, id:data.id});
    setGridRows(copyRows);
    let ID = data.id;
    setReload(true)
    dispatch(updateRole({ ID, addvalues }))
}


  const actionColumn = [
    { field: "id", headerName: "ID", width: 70, filterable: false, sortable: false, pinnable:false},
    { field: "role_name", 
      headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Role Name</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("role_name")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>,
      width: 230,
      sortable:false,
      pinnable:false,
      renderCell: params => (
        params.row.role_name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={230}/> :
        params.row.role_name
      )
    },
    { field: "role_key", headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Role Key</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("role_key")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>, width: 230, sortable:false, pinnable:false,
      renderCell: params => (
        params.row.role_key === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={230}/> :
        params.row.role_key
      )
    },
    { field: "role_description", headerName: <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{color:'#1B3764'}}>Description</p><button style={{background:'white', marginTop:'10px'}} onClick={()=> !reload && handleSort("role_description")}><UnfoldMoreIcon sx={{cursor:'pointer', fontSize:'1.35rem', color:'#BCC2CE'}} /></button></div>, width: 230, sortable:false, pinnable:false,
      renderCell: params => (
        params.row.role_description === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={230}/> :
        params.row.role_description
      )
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      sortable:false,
      type:'singleSelect',
      valueOptions:[{label:'Active', value:'True'}, {label:'InActive', value:'False'}],
      pinnable:false,
      renderCell: (params) => {
        return (
          params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={160}/> :
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
            <Switch  name="status" checked={params.row.status} onChange={()=>handleStatusChange(params.row)}/>
            <div className="actionButton">
              <UcActionComponent
                moduleName="roles" 
                rowid={params.row.id} 
                addMoreData=""
                editLinkUrl="" 
                viewLinkUrl="" 
                isDelete={true} 
                editButton={handleEdit} 
                viewButton={false} 
                deleteButton={handleClickOpen} 
              />
          </div>
      </div>
        );
      },
    },
  ];

  const handleMenuAction = (actionType) =>
  {
      let row = gridRows.find(item=>item.id === selectedRow);
          
      switch(actionType)
      {
          case 'edit':    
                handleEdit(row.id)
                break;

          case 'delete':    
                handleClickOpen(false, row.id)
                break;

          default:
              return;
      }

      setContextMenu(null);
  }

  if (reload === true && status ===  `${STATUSES.SUCCESS}_getRole_list` && roleListData.message) {
    if (roleListData.status === 200) {
        if(clearGridListing.current){
            setGridRows([...roleListData.data.rows]);
            clearGridListing.current = false;
            setSkeletonRows([])
            SET_MAX_ROW_LENGTH(roleListData.data.count);
            gridTotalRows.current = [...roleListData.data.rows].length;
        }
        else{
            gridTotalRows.current = [...gridRows, ...roleListData.data.rows].length;
            setGridRows((pre)=>[...pre, ...roleListData.data.rows]);
            SET_MAX_ROW_LENGTH(roleListData.data.count);
            setSkeletonRows([])
        }
    } else {
        toast.error(roleListData.message)
    }
    setReload(false)
    stopOnRowScrollEnd.current = false;
  }

  if ( flag === 1 && status === `${STATUSES.SUCCESS}_role_update` && role_update.message) {
    if (role_update.status === 200) {
        clearGridListing.current = true;
        let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridRows.length}));
        setFilterQuery(str)
        toast.success(role_update.message)
        handleClose();
    } else {
        let copyLoadedRows = [...gridRows];
        let index = copyLoadedRows.findIndex(item=>item.id === editID.current.id)
        copyLoadedRows.splice(index, 1, editID.current)
        setGridRows(copyLoadedRows);
        toast.error(role_update.message)
    }
    setReload(false)
    flag = 0;
  }

  if (flag === 1 && status === `${STATUSES.SUCCESS}_role_create` && role_create.message) {
    if (role_create.status === 200) {
        clearGridListing.current = true;
        toast.success(role_create.message)
        setGridRows([]);
        let str = new String(JSON.stringify({...JSON.parse(filterQuery), skip:0, limit:gridTotalRows.current}));
        setFilterQuery(str)
        handleClose();
    } else {
        toast.error(role_create.message)
    }
    setReload(false)
    flag = 0;
  }

  return (
    <>
        <div className="datatable">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginBottom: "20px" }}>
                <div style={{ fontSize: "20px"}}>
                    <p>All Roles</p>
                </div>
                <div>
                    <Stack direction="row">
                        <Button variant="outlined" onClick={()=>setActivePopupForm({active:'add', id:null})}>Create<BorderColorTwoToneIcon /></Button>
                        <RefreshButton api={getRole} filter={JSON.stringify({...JSON.parse(filterQuery), limit: gridRows.length==0 ? 25 : gridRows.length, skip:0})} style={{ marginTop: 0 }} setReload={setReload} clearGridListing={clearGridListing}/> 
                    </Stack>
                </div>
              </div>

          <ToastContainer />
            <DataGridPro
              className="datagrid"
              filterMode="server"
              loading={!!reload}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
              }
              sx={{
                  height: 700,
                  "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#438bf969",
                  },
                  ".actionButton": {
                      display: 'none'
                  },
                  [`& .${gridClasses.row}`]: {
                      "&:hover, &.Mui-hovered": {
                      ".actionButton": {
                          display: 'block'
                      }
                      }
                  }
              }}
              rows={gridRows?.concat(skeletonRows)}
              columns={actionColumn}
              filterModel={filterModal}
              getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
              }
              components={{
                  ColumnMenuIcon: MoreHorizIcon,
                  NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                  row: {
                  onContextMenu: handleContextMenu,
                  }
              }}
              onFilterModelChange={onFilterChange}
              onRowsScrollEnd={handleOnRowScrollEnd}
              hideFooter
            />
        </div>
        
        <Dialog
              hideBackdrop
              fullWidth
              maxWidth={"md"}
              open={!!activePopupForm.active}
              onClose={handleClose}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              PaperProps={{ sx: { borderRadius: "10px" } }}
          >
              <Button
                  edge="start"
                  onClick={handleClose}
                  aria-label="close"
                  style={{ position: "absolute", top: "10px", right: "10px", color:'white' }}>
                  <Close />
              </Button>
              <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{(activePopupForm.active === 'add' && 'Add New Role') || (activePopupForm.active === 'edit' &&  'Edit Role')} </DialogTitle>

              <DialogContent>
                <AddRole ID={activePopupForm.active === 'add' ? undefined : activePopupForm.id} activePopupForm={activePopupForm.active} setReload={setReload} flag={flag}/>
              </DialogContent>
          </Dialog>

        {/* for delete popup */}
        <DeleteDialogModal
          open={open || archiveOpen}
          onClose={ handleClose}
          heading={ archiveOpen ? changeStatus : 'Delete ?'}
          paragraph={ archiveOpen ? `Are you sure to ${changeStatus} this role` : 'Are You Sure To Remove This Role'}
          handleArchive={ archiveOpen ? handleClickStatusChange : handleClickOpen}
          id={ archiveOpen ? archiveId : deleteId}
          moduleName={'AllRoles'}
        />

        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
            root: {
                onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
                }
            }
            }}
        >   

            <MenuItem onClick={()=>handleMenuAction('edit')} sx={{color:'rgba(0,0,220,.8)'}}> <Edit sx={{marginRight:'8px', fontSize:'.98rem'}}/> Edit</MenuItem>
            <MenuItem onClick={()=>handleMenuAction('delete')} sx={{color:'rgba(220,0,0,.8)'}} > <DeleteIcon sx={{marginRight:'8px', fontSize:'.98rem'}}/> Delete</MenuItem>
    
        </Menu>
        </>
  )
}

const  AllRoles = DataGridProLazyLoadHOC(SubAllRoles, {filterColumns: DefaultFilterColumns.ROLES_LIST_COLUMNS.map(item=>item.replaceAll('"',"").trim()), getDispatcherAsyncThunk:getRole, gridColumns:['role_name', 'role_key', 'role_description', 'status']});

export default AllRoles