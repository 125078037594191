import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Box, Dialog, DialogContent, IconButton, LinearProgress, Menu, MenuItem, Paper, Skeleton, Stack, Switch, Typography } from "@mui/material";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getresumeSource, resumeSourceEdit, resumeSourceCreat, resumeSourceUpdate } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { BorderColorTwoTone, Close, Edit, MoreHoriz, UnfoldMore } from "@mui/icons-material";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { InputFields } from "../../components/common-function/InputFields";
import CONSTANTS from "../../components/constant/constantComponents";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Draggable from "react-draggable";
import PaperComponent from "../../components/common-function/PaperComponent";

const initialFormData = Object.freeze({
    source_type: "",
    status: true,
});


const formErrData = Object.freeze({
    nameErr: "Source Type is required",
    statusErr: "Status is required"
});

// const PaperComponent = (props) => {
//     return (
//         <Draggable
//             handle="#draggable-dialog-title"
//             cancel={'[class*="MuiDialogContent-root"]'}
//         >
//             <Paper {...props} />
//         </Draggable>
//     );
// }

const ResumeSource = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, resume_source_List, resume_source_create, resume_source_Edit, resume_source_Update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":20,"columns":[${DefaultFilterColumns.RESUME_SOURCE_LIST_COLUMNS}]}`)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    // grid states starts
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });

    const skeletonBase = { source_type: '_skeleton_', status: '_skeleton_' };
    const filterableColumns = ['source_type', 'status'];

    const [loading, setLoading] = useState(false)
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(20).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    const [colSortingPerform, setColSortingPerform] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [limit, setLimit] = useState(20);

    const [contextMenu, setContextMenu] = useState(null);
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    let gridTotalRows = useRef(null);
    let editID = useRef(null);
    let isAnyChange = useRef({});
    // grid states ends

    useEffect(() => {
        setLoading(true);
        setReload(true);
        dispatch(getresumeSource(filterQuery))
    }, [filterQuery]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_resume_source_Update` && resume_source_Update && resume_source_Update.message) {
        if (resume_source_Update.status === 200) {
            setGridRows([])
            setLoading(true)
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: 20 }));
            setFilterQuery(str)
            toast.success(resume_source_Update.message)
            handleClose()
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(resume_source_Update.message)
        }
        setReload(false)
    }

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_resume_source_create` && resume_source_create && resume_source_create.message) {
        if (resume_source_create.status === 200) {
            setGridRows([])
            setLoading(true)
            toast.success(resume_source_create.message)
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: 20 }));
            setFilterQuery(str)
            handleClose()
        } else {
            toast.error(resume_source_create.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const handleStatus = (evt) => {
        const { checked } = evt.target || evt;
        setFormValues({ ...formValues, 'status': checked })
    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.source_type === "") {
            setErrStatus(true)
        }

        if (formValues.source_type !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            setReload('submit')
            // handleClose()
            if (ID) {
                dispatch(resumeSourceUpdate({ ID, addvalues }))
            } else {
                dispatch(resumeSourceCreat(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_resume_source_Edit` && resume_source_Edit && resume_source_Edit.data) {
        let editData = resume_source_Edit.data
        setFormValues({
            source_type: editData.source_type,
            key: editData.key,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(resumeSourceEdit(id))
        setReload('edit_data')
    }

    // sorting
    const handleSort = (sortingCol) => {
        stopOnRowScrollEnd.current = true;
        let copySorter = [...colSortingPerform];
        let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0;
        newFilterData['limit'] = newFilterData['skip'] === 0 ? 25 : newFilterData['skip'];
        if (foundElement) {
            newFilterData['order_by'] = { "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}` }
            copySorter.map(item => {
                if (item.id === foundElement.id) item.order = item.order === "asc" ? "desc" : "asc"
            });
            setColSortingPerform(() => copySorter)
        }
        else {
            newFilterData['order_by'] = { "column": `${sortingCol}` }
            setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
        }
        setFilterQuery(JSON.stringify(newFilterData))
        setGridRows([]);
    }

    const actionColumn = [
        {
            field: "source_type",
            sortable: false,
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Source Type</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => !reload && handleSort("source_type")} size="small" aria-label="unfoldmoreicon">
                        <UnfoldMoreIcon sx={{ color: "#BCC2CE" }} />
                    </IconButton>
                </Box>
            </Stack>,
            width: 280,
            sortable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    params?.row?.source_type === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.source_type}</Typography>
                )
            },
        },
        {
            field: "status",
            headerName: <Stack alignItems={'center'} direction={'row'}>
                <Box justifyContent={"center"}>
                    <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
                </Box>
            </Stack>,
            sortable: false, pinnable: false, width: 200, type: 'singleSelect', valueOptions: [{ label: "Active", value: 'True' }, { label: "In-Active", value: 'False' }],
            renderCell: (params) => {
                return (
                    params?.row?.status === "_skeleton_" ? <Skeleton width={"300px"} />
                        : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Switch
                                    checked={params.row.status === true ? true : false}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    onChange={() => changeStatus(params.row.status, params.row)}
                                />
                            </Stack>
                            <div className="actionButton">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="source_type"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={false}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={false} />
                            </div>
                        </div>

                );
            },
        },

    ];


    const onFilterChange = (props) => {
        if (props.items.length > actionColumn.length - 1)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            let str = new String(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.RESUME_SOURCE_LIST_COLUMNS}]}`);
            setFilterQuery(str);
            return;
        }

        if (filterModal?.items?.length < props?.items?.length) {
            if (props.items.length > 1) {
                let selectedCols = filterModal.items.map(item => item.columnField);
                let remainCols = filterableColumns.filter(item => ![...selectedCols].includes(item))
                let newProps = { ...props };
                newProps.items[newProps.items.length - 1].columnField = remainCols[0];
                let index = props.items.findIndex(item => item.columnField === 'status')
                if (newProps.items.some(item => item.columnField === 'status')) {
                    if (!newProps.items[index].value) {
                        newProps.items[index].value = undefined;
                        newProps.items[index].operatorValue = 'is';
                    }
                }
                setFilterModel(props)
            }
            else
                setFilterModel(props)
            return;
        } else if (filterModal?.items?.length > props?.items?.length || filterModal?.items?.length === props?.items?.length) {

            if (!(filterModal?.items?.length > props?.items?.length)) {

                // if same col is selected then do not let it select - starts
                let selectedCols = props.items.map(item => item.columnField);
                let prevLen = selectedCols.length;

                if ((new Set(selectedCols)).size != prevLen) {
                    toast.warn("Please select distinct column fields!")
                    return;
                }
                // if same col is selected then do not let it select - ends
                setFilterModel(props)

                let filterModalCols = [];
                let propsCols = [];
                filterModal.items.map(item => filterModalCols.push(item.columnField));
                props.items.map(item => propsCols.push(item.columnField));

                for (let i = 0; i < filterModalCols.length; i++) {
                    if (filterModalCols[i] != propsCols[i])
                        return;
                }
            }
            setFilterModel(props)

            let filterItems = props.items;
            let baseFilter = { [props.linkOperator === "and" ? "filter" : "or"]: {}, skip: 0, limit: limit, order_by: { column: "-id" }, columns: DefaultFilterColumns.RESUME_SOURCE_LIST_COLUMNS_JSON };
            let operators = {
                contains: 'icontains',
                equals: 'ieq',
                is: 'eq',
                startsWith: 'istartsWith',
                endsWith: 'iendsWith'
            }

            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {
                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            isAnyChange.current[index] = true;
                        else isAnyChange.current[index] = false;
                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }
                    // if no changes on filter open ends
                    setFilterQuery(JSON.stringify({ ...baseFilter, [props.linkOperator === "and" ? "filter" : "or"]: { ...filter } }));
                }
            }
            return;
        }
    }

    const loadServerRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            loadServerRows(params.viewportPageSize);
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };


    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            case 'status':
                // handleArchive(row);
                break;

            case 'edit':
                handleClickEdit(row.id)
                break;

        }

        setContextMenu(null);

    }


    // change status on listing page 
    const changeStatus = (status, data) => {
        const ID = data?.id
        let formValues = {
            source_type: data.source_type,
            key: data.key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        setReload('submit')
        dispatch(resumeSourceUpdate({ ID, addvalues: formValues }))
    }

    // handle load data response
    if (loading === true && status === `${STATUSES.SUCCESS}_resume_source_List`) {
        if (resume_source_List.status === 200) {
            if (clearGridListing.current) {
                setGridRows([...resume_source_List.data.rows].sort(item => item.status ? -1 : 1));
                clearGridListing.current = false;
                setSkeletonRows([])
            } else {
                setGridRows((pre) => [...pre, ...resume_source_List.data.rows].sort(item => item.status ? -1 : 1));
                SET_MAX_ROW_LENGTH(resume_source_List.data.count);
                setSkeletonRows([])
            }
        } else {
            toast.error(resume_source_List.message)
        }
        setLoading(false)
        setReload(false)
        stopOnRowScrollEnd.current = false;
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "20px" }}>
                        <div style={{ fontSize: "20px" }}>
                            <p>Resume Source</p>
                        </div>
                        <div>
                            <Stack direction="row">
                                <Button variant="outlined" onClick={handleOpen}>Create<BorderColorTwoTone /></Button>
                                <RefreshButton api={getresumeSource} filter={JSON.stringify({ ...JSON.parse(filterQuery), limit: gridRows.length == 0 ? 20 : gridRows.length, skip: 0 })} style={{ marginTop: 0 }} setReload={setLoading} clearGridListing={clearGridListing} />
                            </Stack>
                        </div>
                    </div>

                    <DataGridPro
                        className="datagrid"
                        filterMode="server"
                        loading={!!reload}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={actionColumn}
                        filterModel={filterModal}
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                        components={{
                            ColumnMenuIcon: () => (<MoreHorizIcon sx={{ color: "#171c26ba" }} />),
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={{
                            row: { onContextMenu: handleContextMenu, }
                        }}
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        onFilterModelChange={onFilterChange}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px", color: 'white' }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px", cursor: 'move' }} id="draggable-dialog-title">{ID ? 'Edit' : 'Add'} Source Type</DialogTitle>
                    {(reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Source Type"}
                                    name="source_type"
                                    defaultValue={formValues.source_type}
                                    type="text"
                                    placeholder="Source Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className={`cellWithStatus false`}>
                                        Inactive
                                    </Typography>
                                    <Switch
                                        checked={formValues.status === true ? true : false}
                                        inputProps={{ 'aria-label': 'ant design' }}
                                        onChange={handleStatus}
                                    />
                                    <Typography className={`cellWithStatus true`}>
                                        Active
                                    </Typography>
                                </Stack>

                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>


                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                </Menu>
            </div>
        </div >
    )
}

export default ResumeSource;