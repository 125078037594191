import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";
const { REACT_APP_ENV } = process.env;
const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))

export const allResources = createAsyncThunk("resources/list", async (data) => {
    const res = await dataService.resource_list(data);
    const resp = res.data;
    return resp.data;
});

export const allShift = createAsyncThunk("shifts/list", async (data) => {
    const res = await dataService.shiftList(data);
    const resp = res.data;
    return resp.data;
});

export const allReport = createAsyncThunk("reports/list", async (data) => {
    const res = await dataService.resource_list(data);
    const resp = res.data;
    return resp.data;
});

export const allProjects = createAsyncThunk("projects/list", async (filter) => {
    const res = await dataService.project_list(filter);
    const resp = res.data;
    return resp.data;
});

export const allTask = createAsyncThunk("tasks/list", async () => {
    const res = await dataService.tasksList(rtm_user);
    const resp = res.data;
    return resp.data;
});

export const myProjects = createAsyncThunk("myprojects/list", async () => {
    const res = await dataService.myProjects_list(rtm_user);
    const resp = res.data;
    return resp.data;
});

export const myProjectResources = createAsyncThunk("myprojects-resource/list", async (filter) => {
    const res = await dataService.myEvaluationByProjectId_list(filter);
    const resp = res.data;
    return resp.data;
});

export const checkIsInterviewerAval = createAsyncThunk("checkIsInterviewerAval", async () => {
    return true
});

//DailyWorkFlowSummary
export const dailyWorkFlowSummary = createAsyncThunk("dailyWorkflowSummary/list", async (filter) => {
    if (filter === 'type') {
        return []
    }
    const res = await dataService.daily_worklog_summary_list(filter);
    const resp = res.data;
    return resp
    // return resp.data;
});
//ActiityList
export const activity = createAsyncThunk("activity/list", async (filter) => {
    const res = await dataService.activity_list(filter);
    const resp = res.data;
    return resp.data;
});
//Add New Wroklog Summary
export const worklogAddNew = createAsyncThunk("resources/daily-work-summary", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.add_worklog_summary(data);
    return res.data;
});

//DailyWorkFlowSummary edit
export const dailyWorkFlowSummaryEdit = createAsyncThunk("dailyWorkflowSummary/edit", async (filter) => {
    const res = await dataService.worklog_summary_edit(filter);
    return res.data;
});

//DailyWorkFlowSummary update
export const dailyWorkFlowSummaryUpdate = createAsyncThunk("dailyWorkflowSummary/update", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.worklog_summary_update(data.ID, data.addvalues);
    return res.data;
});

//DailyWorkFlowSummary delete
export const dailyWorkFlowSummaryDelete = createAsyncThunk("dailyWorkflowSummary/delete", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.worklog_summary_delete(data);
    return res.data;
});

const dashboardsSlice = createSlice({
    name: "dashboardData",
    initialState: {

        resourceList: [],
        projectList: [],
        shiftList: [],
        reportList: [],
        taskList: [],
        myProjectList: [],
        status: STATUSES.IDLE,
        error: '',
        interviewerAval: [],
        dailyWorkFlowSummaryList: [],
        activityList: [],
        worklog_create: "",
        daily_work_log_edit: [],
        daily_work_log_update: [],
        daily_work_log_delete: [],
        update_status: STATUSES.IDLE,
        activityDropDownList: [],
        projectDropDownList: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get all Resources
            .addCase(allResources.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allResources.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.resourceList = action.payload;
                state.error = action.error
            })
            .addCase(allResources.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all shift
            .addCase(allShift.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allShift.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.shiftList = action.payload;
                state.error = action.error
            })
            .addCase(allShift.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all report
            .addCase(allReport.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allReport.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.reportList = action.payload;
                state.error = action.error
            })
            .addCase(allReport.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get all project
            .addCase(allProjects.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allProjects.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.projectList = action.payload;
                state.projectDropDownList = !action.payload?.rows ? [] : action.payload?.rows?.map((e) => ({ ...e, label: e?.project_name?.substring(0, 25) + "...", value: e.id }));
                state.error = action.error
            })
            .addCase(allProjects.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            // get all task
            .addCase(allTask.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(allTask.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.taskList = action.payload;
                state.error = action.error
            })
            .addCase(allTask.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get my Project
            .addCase(myProjects.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(myProjects.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.myProjectList = action.payload;
                state.error = action.error
            })
            .addCase(myProjects.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get check availablity of interviewer
            .addCase(checkIsInterviewerAval.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interviewerAval = action.payload;
            })

            //get my Project
            .addCase(myProjectResources.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(myProjectResources.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.myProjectList = action.payload;
                state.error = action.error
            })
            .addCase(myProjectResources.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

            //get Daily Work flow summary
            .addCase(dailyWorkFlowSummary.pending, (state, action) => {
                state.loading = `${STATUSES.LOADING}_dailyWorkFlowSummaryList`;
            })
            .addCase(dailyWorkFlowSummary.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_dailyWorkFlowSummaryList`;
                    state.dailyWorkFlowSummaryList = action.payload;
                    state.error = ""
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                    state.dailyWorkFlowSummaryList = [];
                }
            })
            .addCase(dailyWorkFlowSummary.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
            //get Activity List
            .addCase(activity.pending, (state, action) => {
                state.loading = STATUSES.LOADING;
            })
            .addCase(activity.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.activityList = action.payload;
                state.activityDropDownList = !action.payload?.rows ? [] : action.payload?.rows?.map((e) => ({ ...e, label: e.activity_title, value: e.id }));
                state.error = action.error
            })
            .addCase(activity.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })
            //Add New Worklog Summary
            .addCase(worklogAddNew.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(worklogAddNew.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.worklog_create = action.payload;
            })
            .addCase(worklogAddNew.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //edit Worklog Summary
            .addCase(dailyWorkFlowSummaryEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(dailyWorkFlowSummaryEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_daily_work_log_edit`;
                state.daily_work_log_edit = action.payload;
            })
            .addCase(dailyWorkFlowSummaryEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //update Worklog Summary
            .addCase(dailyWorkFlowSummaryUpdate.pending, (state, action) => {
                state.update_status = STATUSES.LOADING;
            })
            .addCase(dailyWorkFlowSummaryUpdate.fulfilled, (state, action) => {
                state.update_status = `${STATUSES.SUCCESS}_daily_work_log_update`;
                state.daily_work_log_update = action.payload;
            })
            .addCase(dailyWorkFlowSummaryUpdate.rejected, (state, action) => {
                state.update_status = STATUSES.FAILURE
                state.error = action.error
            })

            //delete Worklog Summary
            .addCase(dailyWorkFlowSummaryDelete.pending, (state, action) => {
                state.update_status = STATUSES.LOADING;
            })
            .addCase(dailyWorkFlowSummaryDelete.fulfilled, (state, action) => {
                state.update_status = `${STATUSES.SUCCESS}_daily_work_log_delete`;
                state.daily_work_log_delete = action.payload;
            })
            .addCase(dailyWorkFlowSummaryDelete.rejected, (state, action) => {
                state.update_status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});


export default dashboardsSlice.reducer;