import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { ToastContainer } from "react-toastify";
import { Dialog, Stack, TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientcrud } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { Close } from "@mui/icons-material";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import "./manage-client.scss"
import * as React from 'react';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const Allclient = () => {
    const { REACT_APP_API_BASEURL } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open] = useState(false);
    const { status, error, clientCrud_List, } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});
    useEffect(() => {
        dispatch(getClientcrud(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {}

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        //setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getClientcrud(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getClientcrud(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(clientCrud_List && clientCrud_List.data && clientCrud_List.data.rows && clientCrud_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getClientcrud(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}] }`)
        }
    }

    const ClientLogo = (params) =>{
        let lettername = "";
        if (params && params.row.name) {
          let displayName = params.row.name;
    
          let arr = displayName.split(" ");
          return lettername = arr && arr.length > 0 ? arr.length >= 2 ? arr[0].charAt(0) + arr[1].charAt(0) : arr[0].charAt(0) : '';
        }
    }

    const actionColumn = [
        {
            field: "logo",
            headerNane: "Logo",
            width: 150,
            renderCell: (params) => {
                return(
                    <div>
                        {params.row.logo === null ?
                        <h4 className="avatar"> 
                                {/* {params.row.name===""?"":ClientLogo(params)} */}
                                {/* {params.row.logo === null ? ClientLogo(params) : <img src = {params.row.logo.includes("blob:")?params.row.logo: params.row.logo} onLoad={()=> URL.revokeObjectURL(params.row.logo)}/>} */}
                                 {ClientLogo(params) }
                            </h4>
                            : <img style={{height:'35px', width: '35px', borderRadius: '50%', padding: 'none'}} src = {`${REACT_APP_API_BASEURL.slice(0,-1) + params.row.logo}`}/>}
                    </div>
                )
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.name}
                    </div>
                );
            },
        },
        {
            field: "address",
            headerName: "Address",
            width: 150,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.address}
                    </div>
                );
            },
        },
        {
            field: "country__name",
            headerName: "Country",
            width: 80,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.country?.name ?? params.row.country__name}
                    </div>
                );
            },
        },
        {
            field: "state__name",
            headerName: "State",
            width: 150,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row?.state?.name ?? params.row?.state__name}
                    </div>
                );
            },
        },
        {
            field: "zip",
            headerName: "Zip Code",
            width: 90,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.zip ? params.row.zip : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "client_status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.client_status}`}>
                        {params.row.client_status === 1 ? 'Active' : params.row.client_status === 0 ? 'Inactive' : "Defualter"}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="client"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl={`/add-new-client/${params.row.id}`}
                            editButton={false}
                            viewLinkUrl=""
                            isDelete={false}
                            viewButton={false}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                //setPageSize(10)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}]}`)
                dispatch(getClientcrud(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getClientcrud(filterData))
        }
        // if (filterModel.items && filterModel.items.length === 0) {
        //     setCurrentPage(0)
        //     //setPageSize(10)
        //     setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}]}`)
        //     dispatch(getClientcrud(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.CLIENT_LIST_COLUMNS}]}`))
        // }
    }
    let i = 0;
    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>All Client</h3>
                        <div>
                        <Stack direction="row">
                            <Button variant="outlined" onClick={()=>navigate('/add-new-client')}>Create<BorderColorTwoToneIcon /></Button>
                            <RefreshButton api={getClientcrud} filter={filterQuery} style={{ marginTop: 0 }} />
                        </Stack>

                        </div>
                    </div>
                    <div className="custom-pagination-class">
                       <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload &&
                        <DataGrid
                            getRowId={(row) => row + i++}
                            
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={clientCrud_List && clientCrud_List.data && clientCrud_List.data.rows ? clientCrud_List.data.rows : []}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                        />
                    }
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Purchase Type</DialogTitle>

                </Dialog>
            </div>
        </div >
    )
}

export default Allclient;