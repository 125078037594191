import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const GlobalBreadcrumbs = ({ }) => {
  const { data, subdata, thirdleveldata } = useSelector((state) => state.breadcrumbsSlice);
  
  let subMenuCrumbs = data?.main?.submenu?.filter((item) => {
    if (item.menu_id === data.active)
      return item.menu.title
  })

  // let coreMenuCrumbs = subMenuCrumbs?.map(subItem => {
  //   return subItem?.submenu?.filter(coreSubItem => {
  //     if (coreSubItem?.submenu?.length > 0) {
  //       return coreSubItem
  //     }
  //   })
  // })

  return (
    <Stack m={2} direction={'row'}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label={data?.main?.menu?.title ? data?.main?.menu?.title : 'Dashboard'}
            icon={!data?.main?.menu?.title ? <HomeIcon fontSize="small" /> : <></>}
          />
          {subMenuCrumbs?.length > 0 ? <StyledBreadcrumb component="b" href="#" label={subMenuCrumbs[0]?.menu?.title} /> : <></>}
          {subdata?.active ? <StyledBreadcrumb component="b" href="#" label={subdata?.main?.menu?.title} /> : '>'}
          {thirdleveldata?.active ? <StyledBreadcrumb component="b" href="#" label={thirdleveldata?.main?.menu?.title} /> : '>'}
          {/* <StyledBreadcrumb
            label="Accessories"
          icon={<ExpandMoreIcon />}
          deleteIcon={<ExpandMoreIcon />}
          onDelete={handleClick}
          /> */}
        </Breadcrumbs>
      </div>
    </Stack>
  )
}

export default GlobalBreadcrumbs


/* eslint-disable jsx-a11y/anchor-is-valid */