import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
const { REACT_APP_ENV } = process.env;
const user = LocalStorageDataService.userDetail()

export const createZoomMeeting = createAsyncThunk("create_zoom", async (data) => {
    if (data.ID === '--1') {
        return [];
    } else {
        const res = await DataService.create_zoom_meeting(data.ID, data.addvalues);
        return res.data;
    }
});

export const getZoomAccountList = createAsyncThunk("get/zoom-account", async (data) => {
    const res = await DataService.get_mst_zoom_account(data);
    return res.data;
});

export const getZoomCallLogDetails = createAsyncThunk("zoom-call-log/details", async (data) => {
    const res = await DataService.get_zoom_meeting(data);
    return res.data;
})

export const defaultZoomColumn = createAsyncThunk("set-zoom-column", async (data) => {
    return data;
});

const zoomSlice = createSlice({
    name: "zoomSlice",
    initialState: {
        create_zoom_meeting: [],
        zoomAccountList: [],
        status: STATUSES.IDLE,
        error: '',
        zoomCallDetails: [],
        defaultZoomColumns:[],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //create Zoom Meeting
            .addCase(createZoomMeeting.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createZoomMeeting.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_create_zoom_meeting`;
                state.create_zoom_meeting = action.payload;
            })
            .addCase(createZoomMeeting.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get zoom account list
            .addCase(getZoomAccountList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getZoomAccountList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_zoomAccountList`;
                state.zoomAccountList = action.payload;
            })
            .addCase(getZoomAccountList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get Zoom call details
            .addCase(getZoomCallLogDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getZoomCallLogDetails.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_zoomCallDetails`;
                state.zoomCallDetails = action.payload
            })
            .addCase(getZoomCallLogDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            .addCase(defaultZoomColumn.fulfilled, (state, action) => {
                localStorage.setItem(`${REACT_APP_ENV}_${user.id}_zoom`, JSON.stringify(action.payload))
                state.defaultZoomColumns = action.payload
            }) 
    },
});
export default zoomSlice.reducer;