import { Box, Button, Menu, MenuItem, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import api from '../../redux/APIS/api'
import ShiftAssignment from './ShiftAssignment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import dataService from '../../redux/services/data.service';
import Select from 'react-select';
import Confirmation from '../../components/popup/Confirmation';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import Filter from '../../components/Filter';
import { Refresh } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import { useDispatch, useSelector } from 'react-redux';
import { defaultassignshiftColumn } from '../../redux/features/projectSlice';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay';
import Draggable from 'react-draggable';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddBoxIcon from '@mui/icons-material/AddBox';



const default_assign_shift_Columns = LocalStorageDataService.get_default_assign_shift_column()
const { REACT_APP_ENV } = process.env;

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', email: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', contact_no: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', jira_account: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', department_id: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', tms_role_id: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', shift_id: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status_value: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-modal"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper sx={{ borderRadius: '25px', boxShadow: "1px 4px 4px -78px rgba(0,0,0,0.2), 0px 0px 26px 7px rgba(0,0,0,0.1), 0px 30px 69px -22px rgba(0,0,0,0.1) !important" }} {...props} />
    </Draggable>
  );
}

const AssignShift = () => {

  const dispatch = useDispatch();
  const [resources, setResources] = useState([])
  let stopOnRowScrollEnd = useRef(false);
  const [shifts, setShifts] = useState([]);
  const [shiftsOptions, setShiftsOptions] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [params, setParams] = useState({})
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(25)
  const [shiftAssignmentOpen, setshiftAssignmentOpen] = useState(false);
  const [assigningOneResource, setAssigningOneResource] = useState({})

  const [resourcesArray, setResourcesArray] = useState([])
  const [bulkModelOpen, setBulkModelOpen] = useState(false)
  const [assigningBulkResource, setAssigningBulkResource] = useState([])
  const [assigningShift, setAssigningShift] = useState("")
  const [assigningResourceArray, setAssigningResourceArray] = useState([])

  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false)

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [options, setOptions] = useState({})
  const [deleteId, setDeleteId] = useState(null)
  const [updateStatus, setUpdateStatus] = useState("")
  const [open, setOpen] = useState(false);
  const [gridRows, setGridRows] = useState([]);
  const [reload, setReload] = useState(false)
  const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
  const [colSortingPerform, setColSortingPerform] = useState([]);
  let clearGridListing = useRef(false);



  // let filterQuery = useRef({});


  const { defaultassignshiftColumns } = useSelector(state => state.projects)
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });


  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_assign_shift_Columns && default_assign_shift_Columns?.all_assign_shift_column ? default_assign_shift_Columns?.all_assign_shift_column : {
    id: false,
    tms_role_id: false,
    department_id: false,

  });
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25,"columns":"[${default_assign_shift_Columns?.columnVisibilityModel}]"}`)

  const handleMenuAction = (actionType) => {
    let row = resources.find(item => item.id === selectedRow);
    switch (actionType) {
      case 'assign_shift':
        onAdditionalAction(row, 'assign_shift')
        break;
      default:
        return;
    }
    setContextMenu(null);
  }
  const setKeyForList = (obj) => {
    let data;
    if (default_assign_shift_Columns) {
      data = { 'all_assign_shift_column': obj, 'reported_assign_shift_column': default_assign_shift_Columns.reported_assign_shift_column, 'all_projectlist_column': default_assign_shift_Columns.all_projectlist_column, 'task_column': default_assign_shift_Columns.task_column }
    } else {
      data = { 'all_assign_shift_column': obj }
    }
    if (defaultassignshiftColumns.reported_assign_shift_column) {
      data['reported_assign_shift_column'] = defaultassignshiftColumns.reported_assign_shift_column
    }
    if (defaultassignshiftColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultassignshiftColumns.all_projectlist_column
    }
    if (defaultassignshiftColumns.task_column) {
      data['task_column'] = defaultassignshiftColumns.task_column
    }
    dispatch(defaultassignshiftColumn(data))
  }

  const resourcesColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "name",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Name</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("name")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 250, minWidth: 200, maxWidth: 300, sortable: false,
      renderCell: (params) => {
        return (
          params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={250} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.name}</Typography>
            </div>
        );
      },
    },
    {
      field: "email",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Email</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("email")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 350, minWidth: 200, maxWidth: 400, sortable: false,
      renderCell: (params) => {
        return (
          params.row.email === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={350} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.email}</Typography>
            </div>
        );
      },
    },
    {
      field: "contact_no",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Contact No</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("contact_no")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 150, minWidth: 200, maxWidth: 200, sortable: false,
      renderCell: (params) => {
        return (
          params.row.contact_no === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={150} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.contact_no}</Typography>
            </div>
        );
      },
    },
    {
      field: "jira_account",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Jira Account</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("jira_account")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 400, minWidth: 200, maxWidth: 400, sortable: false,
      renderCell: (params) => {
        return (
          params.row.jira_account === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={400} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.jira_account}</Typography>
            </div>
        );
      },
    },
    {
      field: "department_id",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Department</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("department_id")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 350, minWidth: 200, maxWidth: 400, sortable: false,
      // hideable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: departments,
      renderCell: (params) => {
        return (
          params.row.department_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={350} /> :
            <div className="cellWithImg">
              <Typography variant="p">{params.row.department_id}</Typography>
            </div>
        );
      },
    },
    {
      field: "tms_role_id",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Role</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("tms_role_id")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 350, minWidth: 200, maxWidth: 400, sortable: false,

      type: 'singleSelect',
      valueOptions: roles,
      // renderCell: (params) => {
      //   return (
      //     <div className="cellWithImg">
      //       <p style={{ padding: "5px" }}> {params.row.role_name} </p>
      //     </div>
      //   );
      // },
      renderCell: (params) => {
        return (
          params.row.tms_role_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={350} /> :
            <div className={`cellWithStatus`}>
              <Typography variant="p">{params.row.tms_role_id}</Typography>
            </div>
        );
      },
    },
    {
      field: "shift_id",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Shift</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("shift_id")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      width: 350, minWidth: 200, maxWidth: 400, sortable: false,

      type: 'singleSelect',
      valueOptions: shiftsOptions,
      renderCell: (params) => {
        return (
          params.row.shift_id === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={350} /> :
            <div className="cellWithImg">
              {
                params.row.assigned_shifts.map((shift, index) => {
                  return (
                    <Typography variant="p">{shift} ( {moment("10/10/2022 " + params.row.assigned_shifts_array[0].start_time).format('LT')}<span> - {moment("10/10/2022 " + params.row.assigned_shifts_array[0].end_time).format('LT')} )</span>  </Typography>
                  )
                })
              }
            </div>
        );
      },
    },
    {
      field: "status_value",
      headerName: <Stack alignItems={'center'} direction={'row'}>
        <Box justifyContent={"center"}>
          <Typography color={'#1B3764'} fontWeight={'bolder'} fontSize={18} variant="subtitle1">Status</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => !reload && handleSort("status_value")} size="small" aria-label="unfoldmoreicon">
            <UnfoldMoreIcon />
          </IconButton>
        </Box>
      </Stack>,
      type: 'singleSelect',
      valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
      width: 170, minWidth: 200, maxWidth: 180, sortable: false,

      renderCell: (params) => {
        return (
          params.row.status_value === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={170} /> :
            <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} >
              <div className={`cellWithStatus ${params.row.status}`}>
                <Typography variant="p">{params.row.status === true ? "Active" : "Inactive"}</Typography>
              </div>
              <div className="cellAction">
                <UcActionComponent
                  moduleName="dynamic_table"
                  rowid={params.row.id}
                  addMoreData={params.row}
                  editLinkUrl=""
                  viewLinkUrl=""
                  // isDelete={!!deleteRow}
                  editButton={false}
                  viewButton={false}
                  deleteButton={() => handleDelete(params.row)}
                  additionalAction={[{ id: 1, key: "assign_shift", title: "Assign Shift" }]}
                  extraButton={onAdditionalAction}
                  deleteIconName="ArchiveIcon"
                />
              </div>
            </Stack>
        );
      },
    },
  ];

  // const [resourceColumns, setResourseColumns] = useState(resourcesColumns)
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;


  const getShiftName = async (resourceId, shiftId) => {
    const qParams = { "filter": { "shift_id": { "value": shiftId, "operation": "eq" }, "resource_id": { "value": resourceId, "operation": "eq" }, "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }
    const res = await api.get(`/resources/shift-resource?where=${JSON.stringify(qParams)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      return res.data.data.rows.length ? res.data.data.rows[0].id : undefined;
    } else
      if (res) {
        return false;
      }
  }

  const loadResources = async () => {
    setLoading(true);
    const qParams = { ...params, filter: { "status": { "value": "True", "operation": "eq" }, ...params.filter }, "skip": skip, "limit": pageSize || 25, "columns": DefaultFilterColumns.RESOURCE_SHIFT_LIST_COLUMNS }
    const res = await api.get(`/resources/main?where=${JSON.stringify(qParams)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      setRowCount(res.data.data.count)
      SET_MAX_ROW_LENGTH(res.data.data.count);
      setSkeletonRows([])
      const formattedResponseData = res.data.data.rows.map((data) => {
        return {
          ...data,
          department_name: data.department ? data.department.name ? data.department.name : "" : data.department__name,
          department_code: data.department ? data.department.code ? data.department.code : "" : data.department__code,
          role_name: data.tms_role ? data.tms_role.role_name ? data.tms_role.role_name : "" : data.tms_role__role_name,
          role_key: data.tms_role ? data.tms_role.role_key ? data.tms_role.role_key : "" : data.tms_role__role_key,
          assigned_shifts: data.shift ? data.shift.shift_name ? [data.shift.shift_name] : [] : [data.shift__shift_name],
          shift_id: data.shift ? data.shift.id ? data.shift.id : "" : data.shift__id,
          status_value: data.status ? "True" : "False",
          assigned_shifts_array: data.shift ? [data.shift] : [{ end_time: data.shift__end_time, start_time: data.shift__start_time }],
        }
      })

      setResources(formattedResponseData)

    }
    setLoading(false);
  }

  const fetchFurtherRows = (viewPortRowSize) => {
    let updatedFilter = JSON.parse(filterQuery);
    updatedFilter.skip = gridRows?.length;
    updatedFilter.limit = 25;
    setFilterQuery(JSON.stringify(updatedFilter));
  }


  const handleOnRowScrollEnd = (params) => {
    if (stopOnRowScrollEnd.current) {
      stopOnRowScrollEnd.current = false;
      return;
    }
    if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
      fetchFurtherRows(params.viewportPageSize);
    }
  };

  const handleChange = (event) => {
    if (event) {
      setAssigningShift(event.value);
    }
    else {
      setAssigningShift("")
    }
  };

  const handleChangeMulti = (event) => {
    const optArray = event.map((e, i) => e.id);
    setAssigningResourceArray(optArray);
  };

  const onSelectionChange = (selectionArray) => {
    setResourcesArray(selectionArray);
    const data = resources.filter((element) => selectionArray.includes(element.id))
    setAssigningBulkResource(data)
    setAssigningResourceArray(data.map((e) => e.id))

  }

  const onAdditionalAction = async (data, key) => {
    if (key === "assign_shift") {
      if (data.shift && data.shift.id) {
        let resourceShift = await getShiftName(data.id, data.shift.id)
        if (resourceShift) {
          setAssigningOneResource(Object.assign({ ...data, resourceShift: resourceShift }))
          toggleAssignmentOpen()
        } else {
          setAssigningOneResource(data)
          toggleAssignmentOpen();
        }
      } else {
        setAssigningOneResource(data)
        toggleAssignmentOpen();
      }
    }
  }

  // Bulk Shift Assignment 
  const bulkAssignShift = async () => {
    const data = { resource: assigningResourceArray, shift: assigningShift, created_by: userId }
    if (!data.resource.length || !data.shift) {
      toast.error("Please fill the all details!")
      return;
    }
    let res = {}
    await api.post("/resources/shift-resource", data)
      .then(response => res = response)
      .catch(error => console.log(error, "Some error occoured!"))

    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message)
      loadResources();
      toggleBulkAssignment();
      toggleConfirm();
    }

    if (res && res.data && res.data.status === 400) {
      toast.error(res.data.message)
      loadResources();
      toggleBulkAssignment();
      toggleConfirm();
    }
  }

  const toggleAssignmentOpen = () => {
    setshiftAssignmentOpen(!shiftAssignmentOpen)
  }

  const handleClose1 = () => {
    setshiftAssignmentOpen(false);
  };

  const toggleBulkAssignment = () => {
    setBulkModelOpen(!bulkModelOpen)
    // setAssigningShift("")
  }

  const handleClose2 = () => {
    setBulkModelOpen(false);
  };

  // Confirmation toggle
  const toggleConfirm = () => {
    setDialogOpen(!dialogOpen);
  }

  // Get Department-List
  const getDepartments = async () => {
    const res = await dataService.departments_dropDown()
    let optionsDept = []
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows;
      tempArray.map(e => { return optionsDept.push({ value: e.id, label: e.name }) })
    }
    setDepartments(optionsDept)
    setOptions({ ...options, department_id: optionsDept })
  }


  // Get Roles-List
  const getRoles = async () => {
    const res = await dataService.roles_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.role_name } })
      setRoles(tempArray)
      tempArray.length > 0 && setOptions({ ...options, tms_role_id: tempArray })
    }
  }
  // Get Shifts-List
  const getShifts = async () => {
    const res = await dataService.shifts_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { id: e.id, value: e.id, label: e.shift_name, shift_name: e.shift_name, start_time: e.start_time, end_time: e.end_time } })
      setShifts(tempArray)
      setShiftsOptions(res.data.data.rows.map(e => { return { value: e.id, label: e.shift_name } }))
      tempArray.length > 0 && setOptions({ ...options, shift_id: tempArray })
    }
  }

  const handleDelete = (id) => {
    setDeleteId(id)
    if (id.status === true) {
      setUpdateStatus("InActive")
    } else {
      setUpdateStatus("Active")
    }
    setOpen(true);
  };

  useEffect(() => {
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    loadResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])

  useEffect(() => {
    getDepartments();
    getRoles();
    getShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      fieldName === "tms_role_id" ?
        <Filter options={roles} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "department_id" ?
          <Filter options={departments} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "shift_id" ?
            <Filter options={shifts} onChange={handleFilterChange} defaultValue={defaultValue} />
            : ""
    );
  }

  const handleFilter = (filterParamsArray, r) => {
    if (filterParamsArray.items.length) {
      setFilterClosingFields(() => ({ columnField: filterParamsArray.items[0].columnField, operatorValue: filterParamsArray.items[0].operatorValue }))
    }
  }
  // const handleSort = (sort) => {
  //   if ("server") {
  //     if (sort.length) {
  //       setParams({
  //         ...params, order_by: {
  //           "column": sort[0].sort === "desc" ?
  //             `-${sort[0].field === "status_value" ? "status" : sort[0].field}` :
  //             (sort[0].field === "status_value") ? "status" : sort[0].field
  //         }
  //       })
  //     }
  //     else {
  //       setParams({})
  //     }
  //     setGridRows([]);
  //     setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
  //   }
  // }

  const handleSort = (sortingCol) => {
    stopOnRowScrollEnd.current = true;
    let copySorter = [...colSortingPerform];
    let foundElement = copySorter.length > 0 && copySorter.find(item => item.col === sortingCol);
    let newFilterData = {};
    newFilterData['skip'] = 0;
    newFilterData['limit'] = gridRows.length;
    if (foundElement) {
      newFilterData['order_by'] = {
        "column": `${foundElement.order === "asc" ? '-' + sortingCol : sortingCol}`
      }
      copySorter.map(item => {
        if (item.id === foundElement.id)
          item.order = item.order === "asc" ? "desc" : "asc"
      });
      setColSortingPerform(() => copySorter)
    }
    else {

      newFilterData['order_by'] = {
        "column": `${sortingCol}`
      }
      setColSortingPerform((pre) => [...pre, { id: pre.slice(0, -1)[0]?.id ? pre.slice(0, -1)[0]?.id + 1 : 1, col: sortingCol, order: 'asc' }]);
    }

    setGridRows([]);
    setSkeletonRows(() => Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
    // loadProjects(newFilterData, false, true)
    loadResources()
    clearGridListing.current = true;
  }


  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const columns = useMemo(() =>
    resourcesColumns.map((col) => {
      if (col.field === "tms_role_id" || col.field === "department_id" || col.field === "shift_id") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [resourcesColumns]
  );

  const handleClose = () => {
    setOpen(false);
    setContextMenu(null)
  }

  const confirmedDelete = (id) => {
    // deleteRow(id);
    handleClose()
  }

  return (
    <div className="new">
      <div className="newContainer">
        <div className="datatable">

          {/* <div className="top-headings">
            <h3> {true ? "Assign Shift" : ""} </h3>
            <div>
              <button className={resourcesArray.length === 0 ? "btn-add-disabled" : "btn-add-new"} onClick={toggleBulkAssignment} disabled={resourcesArray.length === 0} >Bulk Shift Assignment</button>
              <button onClick={() => loadResources()} className="btn-add-new ">
                <Tooltip title="Reload">
                  <Refresh />
                </Tooltip>
              </button>
            </div>
          </div> */}
          <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
            <Stack direction={'row'}>
              <Typography className="headerTitleName" variant="h3">Assign Shift</Typography>
            </Stack>
            <Stack direction={'row'}>
              <Tooltip title="Add Party List">
                <Button disabled={resourcesArray.length === 0} size="small" variant="contained" sx={{ fontWeight: '600' }} onClick={toggleBulkAssignment} >Bulk Shift Assignment</Button>
              </Tooltip>
              <IconButton color="primary" aria-label="Sync Project" onClick={() => loadResources()}>
                <Tooltip title="Sync">
                  <AutorenewIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>
          <ToastContainer />
          {
            <>
              {
                // <DynamicTable
                //   tableColumns={columns}
                //   tableRows={!loading && resources && resources.length ? resources : []}
                //   paginationMode={"server"}
                //   rowCount={rowCount}
                //   loading={loading}
                //   currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                //   setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                //   onSelectionChange={onSelectionChange}
                //   additionalAction={[{ id: 1, key: "assign_shift", title: "Assign Shift" }]}
                //   onAdditionalAction={onAdditionalAction}
                //   checkboxSelection
                //   // columnVisibilityModel={{
                //   //   id: false,
                //   // }}
                //   columnVisibilityModel={defaultassignshiftColumns && defaultassignshiftColumns.all_assign_shift_column ? defaultassignshiftColumns.all_assign_shift_column : columnVisibilityModel}
                //   onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                //   }
                //   setFieldName={setFieldName}
                //   setDefaultValue={setDefaultValue}
                //   limit={limit}
                //   setLimit={setLimit}
                // />
                <Stack direction={'row'} mt={2}>
                  <DataGridPro
                    className="datagrid"
                    rows={(!loading && resources && resources.length) ? resources : skeletonRows}
                    loading={loading}
                    columns={columns}
                    filterMode="server"
                    onFilterModelChange={(filter, r) => handleFilter(filter, r)}
                    onSortModelChange={(sort) => handleSort(sort)}
                    pageSize={pageSize}
                    onSelectionModelChange={onSelectionChange}
                    checkboxSelection
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => {
                      setColumnVisibilityModel(newModel);
                      setKeyForList(newModel);
                    }
                    }
                    componentsProps={{
                      row: {
                        onContextMenu: handleContextMenu,
                      }
                    }}
                    // filterModel={filterModel}
                    experimentalFeatures={{ lazyLoading: true }}
                    style={{ fontSize: 16 }}
                    components={{
                      // Pagination: CustomPagination,
                      NoRowsOverlay: CustomNoRowsOverlay
                    }}
                    // getRowHeight={getRowHeight}
                    // {...restProps}
                    hideFooterPagination={true}
                    onRowsScrollEnd={handleOnRowScrollEnd}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    sx={{
                      boxShadow: '1px 13px 31px -15px gray',
                      borderRadius: '15px',
                      height: '700px',
                      '& .MuiDataGrid-cell:hover': {
                        color: '#000',
                      },
                      '& .MuiDataGrid-columnHeaderTitle': {
                        color: '#1B3764',
                        fontWeight: '650'
                      },
                      '& .even.MuiDataGrid-row': {
                        backgroundColor: 'white'
                      },
                      '& .odd.MuiDataGrid-row': {
                        backgroundColor: '#F2F5FA'
                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#b8d3fd',
                      },
                      "& ::-webkit-scrollbar": {
                        width: "8px",
                        height: "8px",
                      },
                      "& ::-webkit-scrollbar-track": {
                        backgroundColor: "#f5f5f5",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                        backgroundColor: "#f5f5f5",
                      },
                      '& .MuiDataGrid-menuIconButton': {
                        rotate: '90deg',
                        marginRight: '20px'
                      },
                      '& .MuiDataGrid-iconButtonContainer': {
                        marginLeft: '10px'
                      },
                      ".cellAction": {
                        display: 'none'
                      },
                      [`& .${gridClasses.row}`]: {
                        "&:hover, &.Mui-hovered": {
                          ".cellAction": {
                            display: 'block'
                          }
                        }
                      }

                    }}

                  />
                </Stack>
              }
            </>
          }
          <div>
            <Dialog
              hideBackdrop
              fullWidth={"md"}
              maxWidth={"md"}
              open={shiftAssignmentOpen}
              onClose={toggleAssignmentOpen}
              sx={{ '& .MuiDialog-paper': { height: "400px" } }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-modal"
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose1}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "30px", fontWeight: '700', cursor: "grabbing" }}>Assign Shift</DialogTitle>
              <DialogContent>
                <ShiftAssignment shifts={shifts} resource={assigningOneResource} toggleModel={toggleAssignmentOpen} loadResources={loadResources} />
              </DialogContent>
            </Dialog>

            <Dialog
              hideBackdrop
              fullWidth={"md"}
              maxWidth={"md"}
              open={bulkModelOpen}
              onClose={toggleBulkAssignment}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-modal"
              aria-describedby="Bulk Assignment of Shift to resources"
              sx={{ '& .MuiDialog-paper': { height: "400px" } }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose2}
                aria-label="close"
                style={{ position: "absolute", top: "10px", right: "10px", color: '#fff' }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px", fontWeight: '700', cursor: "grabbing" }}>Bulk shift assignment</DialogTitle>
              <DialogContent >
                <div className="assign-shift-modal">
                  <InputLabel>Select Resources</InputLabel>
                  {
                    <Multiselect
                      isObject={true}
                      onRemove={handleChangeMulti}
                      onSelect={handleChangeMulti}
                      displayValue={"name"}
                      selectedValues={assigningBulkResource}
                      options={resources}
                    />
                  }
                  <div className="formInput">
                    <InputLabel>Select Shift</InputLabel>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select shift"
                      defaultValue={assigningShift}
                      isSearchable={true}
                      isClearable={true}
                      options={
                        shifts && shifts.map((option) => {
                          return {
                            label: `${option.shift_name} (${moment("10/10/2022 " + option.start_time).format('LT')} - ${moment("10/10/2022 " + option.end_time).format('LT')})`,
                            value: option.id,
                          }
                        })
                      }
                      onChange={handleChange}
                    />
                  </div>

                  <Button variant='contained' onClick={toggleConfirm} style={{ marginTop: "50px" }}> Bulk Assign Shift </Button>
                </div>

              </DialogContent>

              <Confirmation
                dialogTitle={"Bulk Assignment ?"}
                dialogQuestion={"Are you sure to do Bulk Assignment?"}
                open={dialogOpen}
                handleCancel={toggleConfirm}
                handleClose={toggleConfirm}
                onConfirm={bulkAssignShift}
              />
            </Dialog>
            <DeleteDialogModal
              open={open}
              onClose={handleClose}
              heading={updateStatus}
              paragraph={`Are You Sure To ${updateStatus}`}
              handleArchive={confirmedDelete}
              id={deleteId}
            />
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
              componentsProps={{
                root: {
                  onContextMenu: (e) => {
                    e.preventDefault();
                    handleClose();
                  }
                }
              }}
            >
              <MenuItem onClick={() => handleMenuAction('assign_shift')} sx={{ color: '#0052cc' }}><AddBoxIcon /> Assign Shift</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignShift
